import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-customer-unit-header',
  templateUrl: './customer-unit-header.component.html',
  styleUrls: ['./customer-unit-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class CustomerUnitHeaderComponent {
  @Input() isOrderSubmission: boolean;
  @Input() hasPunchedOutCarts: boolean;

  get ngClassForWidth(): { [key: string]: boolean } {
    const isLargeWidth = !this.isOrderSubmission && !this.hasPunchedOutCarts;
    if (isLargeWidth) {
      return {
        'large-width': isLargeWidth,
      };
    }
    return {
      'medium-width': !this.isOrderSubmission || !this.hasPunchedOutCarts,
    };
  }
}
