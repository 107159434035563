import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SessionFacade } from '../../core/store/session/session.facade';
import { debounceTime, map } from 'rxjs/operators';
import { CurrentSystem } from '../../core/services/session/models/session-record';
import { MaterialPriceFacade } from '../../core/store/material-price/material-price.facade';
import { AsyncPipe } from '@angular/common';
import { CartCouponContentComponent } from './cart-coupon-content/cart-coupon-content.component';
import isMygfsOrSap = CurrentSystem.isMygfsOrSap;

@Component({
  selector: 'naoo-cart-coupon-container',
  template: `
    @if (isMygfsOrSap$ | async) {
      <naoo-cart-coupon-content
        [cartCoupons]="cartCoupons$ | async"
        (addCoupon)="addCoupon($event)"
        (removeCoupon)="removeCoupon($event)"
      >
      </naoo-cart-coupon-content>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, CartCouponContentComponent],
})
export class CartCouponContainerComponent {
  private readonly getCartCouponsDebounceMs = 800;

  cartCoupons$ = this.priceFacade
    .getCartCoupons()
    .pipe(debounceTime(this.getCartCouponsDebounceMs));

  isMygfsOrSap$ = this.sessionFacade
    .getLoadedCurrentSystem()
    .pipe(map((currentSystem) => isMygfsOrSap(currentSystem)));

  constructor(
    private priceFacade: MaterialPriceFacade,
    private sessionFacade: SessionFacade,
  ) {}

  addCoupon(coupon: string): void {
    this.priceFacade.addCoupon(coupon);
  }

  removeCoupon(coupon: string): void {
    this.priceFacade.removeCoupon(coupon);
  }
}
