<div class="data-filtering">
  <naoo-date-range-selector
    (dateRangeEmitter)="onChangeDate($event)"
  ></naoo-date-range-selector>
  <div class="invoice-search">
    <naoo-search-field
      #invoiceSearch
      [value]="searchText$.getValue()"
      [searchHintText]="'ORDERS.INVOICES_CREDITS.SEARCH_HINT' | translate"
      [searchPlaceholderText]="'SEARCH.TITLE' | translate"
      (searchTextEmitter)="updateSearchText($event)"
    >
    </naoo-search-field>
  </div>
</div>
@if (filteredInvoices$ | async; as invoicesCreditsList) {
  <div>
    @if (invoicesCreditsList.length > 0) {
      <div>
        <div class="invoices-credits-row invoices-credits-header">
          <div class="invoice-cell">
            {{ 'ORDERS.INVOICES_CREDITS.DATE' | translate }}
          </div>
          <div class="invoice-cell">
            {{ 'ORDERS.INVOICES_CREDITS.TYPE' | translate }}
          </div>
          <div class="invoice-cell">
            {{ 'ORDERS.INVOICES_CREDITS.INVOICE_CREDIT_NUMBER' | translate }}
          </div>
          <div class="invoice-cell">
            {{ 'ORDERS.INVOICES_CREDITS.PO_NUMBER' | translate }}
          </div>
          <div class="invoice-cell transaction-total">
            {{ 'ORDERS.INVOICES_CREDITS.TOTAL' | translate }}
          </div>
          <div class="invoice-cell invoice-details"></div>
        </div>
        <div class="invoices-credits-body">
          @for (
            invoiceCredit of invoicesCreditsList;
            track invoiceCredit.transactionNumber
          ) {
            <div class="invoices-credits-row">
              <div class="invoice-cell transaction-date">
                <span class="transaction-date-label">{{
                  'ORDERS.INVOICES_CREDITS.DATE' | translate
                }}</span>
                <span class="transaction-date-value">{{
                  invoiceCredit.transactionDate | naooDate: 'shortDate' : ''
                }}</span>
              </div>
              <div class="invoice-cell invoice-type">
                <mat-icon
                  svgIcon="{{
                    invoiceCredit.creditOrder || invoiceCredit.debitInvoice
                      ? 'credit-order-icon'
                      : 'invoiced-icon'
                  }}"
                ></mat-icon>
                <span>{{ getTypeLabel(invoiceCredit) | translate }} </span>
              </div>
              <div class="invoice-cell transaction-number">
                <span class="transaction-number-label">{{
                  'ORDERS.INVOICES_CREDITS.INVOICE_CREDIT_NUMBER' | translate
                }}</span>
                <span class="transaction-number-value">{{
                  invoiceCredit.transactionNumber
                }}</span>
              </div>
              <div class="invoice-cell po-number">
                <span class="po-number-label">{{
                  'ORDERS.INVOICES_CREDITS.PO_NUMBER' | translate
                }}</span>
                <span class="po-number-value">{{
                  invoiceCredit.poNumber
                }}</span>
              </div>
              <div class="invoice-cell transaction-total invoice-amount">
                <span class="invoice-amount-label">{{
                  'ORDERS.INVOICES_CREDITS.TOTAL' | translate
                }}</span>
                <span class="invoice-amount-value">{{
                  invoiceCredit.invoiceAmount | naooprice
                }}</span>
              </div>
              <div class="invoice-cell invoice-details">
                <div class="invoice-links">
                  <a
                    (click)="
                      sendAnalytics(invoiceCredit, ANALYTICS_VIEW_ACTION)
                    "
                    class="invoice-details-link pdf-link"
                    [routerLink]="[
                      INVOICES_PATH,
                      invoiceCredit.transactionNumber,
                    ]"
                    [queryParams]="{
                      date: invoiceCredit.transactionDate,
                      creditOrder: invoiceCredit.creditOrder,
                      debitInvoice: invoiceCredit.debitInvoice,
                    }"
                    target="_blank"
                  >
                    <mat-icon class="pdf-icon" svgIcon="pdf_icon"></mat-icon>
                    {{ 'ORDERS.INVOICES_CREDITS.VIEW_PDF' | translate }}
                  </a>
                  @if (invoiceCredit.isExportable) {
                    <a
                      (click)="handleExportAction(invoiceCredit)"
                      (keydown.enter)="handleExportAction(invoiceCredit)"
                      [tabindex]="0"
                      class="invoice-details-link export-link"
                    >
                      <mat-icon
                        class="pdf-icon"
                        svgIcon="download_v2"
                      ></mat-icon>
                      {{ 'ORDERS.INVOICES_CREDITS.EXPORT' | translate }}
                    </a>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    } @else if (!searchText$.getValue()) {
      <div class="invoice-empty-message">
        <mat-icon class="invoice-icon" svgIcon="empty-icon"></mat-icon>
        <h2 class="invoice-title">
          {{ 'ORDERS.INVOICES_CREDITS.EMPTY_STATE.TITLE' | translate }}
        </h2>
        <div class="invoice-subtitle">
          {{ 'ORDERS.INVOICES_CREDITS.EMPTY_STATE.SUBTITLE' | translate }}
        </div>
      </div>
    } @else {
      <naoo-no-results
        [isInvoiceSearch]="true"
        [searchText]="searchText$.getValue()"
        [isMobile]="isMobile$ | async"
      >
      </naoo-no-results>
    }
  </div>
}
@if (hasInvoicesCreditsError) {
  <div class="invoice-error-message">
    <naoo-error-message
      [title]="'ORDERS.INVOICES_CREDITS.ERROR_STATE.TITLE'"
      [subtitle]="'ORDERS.INVOICES_CREDITS.ERROR_STATE.SUBTITLE'"
    >
    </naoo-error-message>
  </div>
}
