import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DefaultDialogService } from '../../../../../shared/services/dialog/default-dialog/default-dialog.service';
import { CartFacade } from '../../../../../core/store/cart/cart.facade';
import {
  MenuAction,
  MenuActionInfo,
} from '../../../../../product-row/product-menu/menu-action';
import {
  IconState,
  ActionIconComponent,
} from '../../../../../shared/action-icon/action-icon.component';
import { PrintService } from '../../../../../shared/services/print/print.service';
import { MultipleCartsFacade } from '../../../../../core/store/multiple-carts/multiple-carts.facade';
import { CartActionBarButton } from '../model/cart-action-bar';
import { ActiveCartSummary } from '../../shared/models/active-cart-summary';
import { CartSortType } from '../../../../../core/store/active-cart/active-cart-summary.state';
import { ActiveCartSummaryFacade } from '../../../../../core/store/active-cart/active-cart-summary.facade';
import { CustomGuideFacade } from '../../../../../core/store/custom-guide/custom-guide.facade';
import { CustomDialogService } from '../../../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { AnalyticsEventInfo } from '../../../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../../../shared/analytics/NaooAnalyticsManager';
import {
  CustomGuideCreationSourceAnalyticLabel,
  ListsAnalyticsConstants,
} from '../../../../../lists/lists-analytics.constants';
import { Language } from 'src/app/core/services/session/models/session-record';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { CartActionBarMenuComponent } from '../cart-action-bar-menu/cart-action-bar-menu.component';
import { AddToCustomGuideComponent } from '../../../../../shared/add-to-custom-guide/add-to-custom-guide.component';
import { MatIcon } from '@angular/material/icon';
import { CartSortByComponent } from '../../cart-sort-by/cart-sort-by.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-cart-action-bar-content',
  templateUrl: './cart-action-bar-content.component.html',
  styleUrls: ['./cart-action-bar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    CartActionBarMenuComponent,
    AddToCustomGuideComponent,
    ActionIconComponent,
    NgClass,
    MatIcon,
    CartSortByComponent,
    TranslateModule,
  ],
})
export class CartActionBarContentComponent {
  @Input() activeCartSummary: ActiveCartSummary;
  @Input() visibleButtons: CartActionBarButton[];
  @Input() hiddenButtons: CartActionBarButton[];
  @Input() currentLanguage: Language;
  @Input() disablePrint: boolean;

  @ViewChild('actionBarContainer', { static: true })
  actionBarContainer: ElementRef;
  @ViewChild('menuButton', { read: ElementRef })
  menuButton: ElementRef;
  @ViewChild('addToCustomGuideButton', { read: ElementRef })
  addToCustomGuideButton: ElementRef;
  @ViewChild('printButton', { read: ElementRef })
  printButton: ElementRef;
  @ViewChild('saveCartButton')
  saveCartButton: ElementRef;
  @ViewChild('emptyButton')
  emptyButton: ElementRef;
  @ViewChild('sortByButton', { read: ElementRef })
  sortByButton: ElementRef;

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private customDialogService: CustomDialogService,
    private dialogService: DefaultDialogService,
    private printService: PrintService,
    private cartFacade: CartFacade,
    private activeCartSummaryFacade: ActiveCartSummaryFacade,
    private multipleCartsFacade: MultipleCartsFacade,
    private analytics: NaooAnalyticsManager,
  ) {}

  get printState(): IconState {
    return !this.disablePrint && this.activeCartSummary.hasOrderableMaterials
      ? IconState.Enabled
      : IconState.Disabled;
  }

  get hasNoMaterialNumbers(): boolean {
    return this.activeCartSummary.materialNumbers.length === 0;
  }

  get isActionBarButtonDisabled(): boolean {
    return (
      this.activeCartSummary.isOffline ||
      !this.activeCartSummary.hasOrderableMaterials
    );
  }

  get isHidingButtons(): boolean {
    return this.hiddenButtons.length !== 0;
  }

  visibleTemplates(templates: TemplateRef<any>[]): TemplateRef<any>[] {
    return this.visibleButtons
      .map((id) => this.getTemplate(id, templates))
      .filter((template) => !!template);
  }

  onPrintIconClick(): void {
    if (!this.disablePrint && this.activeCartSummary.hasOrderableMaterials) {
      this.printService.print();
    }
  }

  performMenuAction(subMenuItem: MenuActionInfo) {
    switch (subMenuItem.action) {
      case MenuAction.AddToExistingCustomGuide:
        this.addCartToGuide(subMenuItem.id);
        break;
      case MenuAction.AddToNewCustomGuide:
        this.openCreateCustomGuideModal();
        break;
    }
  }

  emptyCart() {
    this.dialogService.twoButtonModal(
      'empty-cart',
      'CART.EMPTY_CART_MODAL.TITLE',
      'CART.EMPTY_CART_MODAL.CONFIRM',
      'CART.EMPTY_CART_MODAL.CANCEL',
      () => {
        this.cartFacade.clearCartMaterials();
      },
      () => {},
      true,
      true,
    );
  }

  moveToSavedCarts() {
    this.multipleCartsFacade.saveActiveCart();
  }

  sortCartBy(sortType: CartSortType) {
    this.activeCartSummaryFacade.updateSortBy(sortType);
  }

  private getTemplate(
    id: CartActionBarButton,
    templates: TemplateRef<any>[],
  ): TemplateRef<any> {
    switch (id) {
      case CartActionBarButton.AddToCustomGuide:
        return templates[0];
      case CartActionBarButton.Print:
        return templates[1];
      case CartActionBarButton.SaveCart:
        return templates[2];
      case CartActionBarButton.EmptyCart:
        return templates[3];
      case CartActionBarButton.SortBy:
        return templates[4];
    }
  }

  private openCreateCustomGuideModal() {
    this.customDialogService.openCreateCustomGuideModal(
      CustomGuideCreationSourceAnalyticLabel.Cart,
      this.getCartMaterialNumbers(),
    );
  }

  private addCartToGuide(customGuideId: string) {
    this.customGuideFacade.addCustomGuideMaterials(
      customGuideId,
      this.getCartMaterialNumbers(),
    );
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'add to guide',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private getCartMaterialNumbers(): string[] {
    return !!this.activeCartSummary && !!this.activeCartSummary.materialNumbers
      ? this.activeCartSummary.materialNumbers.map(
          (listRow) => listRow.value as string,
        )
      : [];
  }
}
