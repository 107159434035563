<div class="page-header">
  <div class="container-title">
    @if (!isMobile || options.showTitle) {
      <h1>
        {{ 'MULTI_CUSTOMER_SELECTION.LOCATIONS' | translate }}
      </h1>
    }
    <section class="total-locations-selected">
      ({{ chosenCustomers.length }}/{{ customerUnits.length }}
      {{ 'MULTI_CUSTOMER_SELECTION.SELECTED' | translate }})
    </section>
  </div>
  <naoo-search-field
    (searchTextEmitter)="updateFilter($event)"
    [searchPlaceholderText]="'CUSTOMER_UNIT.SEARCH'"
    [searchHintText]="'CUSTOMER_UNIT.SEARCH_HINT'"
  ></naoo-search-field>
</div>
@if ((searchableCustomers | filterBy: customerFilter)?.length > 0) {
  <form [formGroup]="customersForm">
    <header>
      <mat-checkbox
        class="select-all"
        formControlName="selectAll"
        name="selectAll"
        (change)="toggleSelectAll($event)"
        >{{
          customerFilter.searchableCustomerAttributes.length === 0
            ? ('MULTI_CUSTOMER_SELECTION.SELECT_ALL'
              | translate: { totalLocations: customerUnits.length })
            : ('MULTI_CUSTOMER_SELECTION.SELECT_ALL_RESULTS'
              | translate: { totalLocations: customerUnits.length })
        }}</mat-checkbox
      >
      <button
        class="clear-button"
        (click)="clear()"
        [disabled]="chosenCustomers.length === 0"
      >
        {{ 'MULTI_CUSTOMER_SELECTION.CLEAR' | translate }}
      </button>
    </header>
    <section
      formArrayName="customerUnits"
      [tabIndex]="-1"
      class="locations-section"
      [style.height]="options.cardContainerHeight"
    >
      <mat-grid-list
        [cols]="numberOfColumns | async"
        gutterSize="1px"
        [rowHeight]="options.rowHeight"
        [style.overflowY]="options.overflowY"
        class="card-grid"
      >
        @for (
          searchCustomer of searchableCustomers | filterBy: customerFilter;
          track searchCustomer.customer.customerDisplayId;
          let i = $index
        ) {
          <mat-grid-tile>
            <mat-checkbox
              [formControlName]="i"
              class="select-customer-box"
              [checked]="searchCustomer.isChecked"
              (change)="
                valueChange(searchableCustomers, i, searchCustomer, $event)
              "
            >
              <div class="customer-info">
                <div class="customer-name">
                  {{ searchCustomer.customer.name }}
                </div>
                <div class="customer-number">
                  #{{ searchCustomer.customer.customerDisplayId }}
                </div>
                <div class="customer-address">
                  {{ searchCustomer.customer.address.line2 }}
                </div>
              </div>
            </mat-checkbox>
          </mat-grid-tile>
        }
      </mat-grid-list>
    </section>
  </form>
}
@if ((searchableCustomers | filterBy: customerFilter)?.length === 0) {
  <naoo-no-results
    [searchText]="searchText"
    [isMobile]="isMobile"
  ></naoo-no-results>
}
