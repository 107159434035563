import { first, switchMap, take, takeUntil } from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  OrderDetailsLineViewModel,
  OrderDetailsOrderStatusDetails,
  OrderDetailsSubmissionDetails,
  OrderDetailsViewModel,
} from '../../shared/models/order-details';
import { OrderStatus } from '../../shared/models/order-info';
import { RouterExtrasService } from '../../shared/services/router-extras/router-extras.service';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { NavigationLink } from '../../shared/models/navigation-link';
import {
  ActionIcon,
  ActionIconComponent,
  IconState,
} from '../../shared/action-icon/action-icon.component';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { StatusSeverity } from '../../shared/models/status-severity';
import {
  EditOrderRequest,
  EditOrderService,
  EditStatus,
} from '../../shared/services/edit-order/edit-order.service';
import {
  EditDetails,
  EditOrderModalResult,
} from './edit-order-modal/edit-order-modal.component';
import { isPrintEvent } from '../../shared/utilities/keyboard-event-utilities';
import {
  Language,
  Locale,
} from '../../core/services/session/models/session-record';
import { LocalizationService } from '../../shared/services/translation/localization.service';
import { OrderDetailsService } from '../../shared/services/order/order-details.service';
import { ExportOrderDetailsService } from '../../shared/services/export-order-details/export-order-details.service';
import { OrderType } from '../../shared/models/order-type';
import { EnumUtilities } from '../../shared/utilities/enum-utilities';
import { NaooConstants } from '../../shared/NaooConstants';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import {
  CancelOrderRecord,
  OrderCancellationStatus,
} from '../../shared/services/edit-order/models/edit-order-record';
import { MatDialog } from '@angular/material/dialog';
import { EntitlementFacade } from '../../core/store/entitlement/entitlement.facade';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from '../../shared/error-handler/NaooErrorUtils';
import { NaooError } from '../../shared/models/naoo-error';
import { BackButtonComponent } from '../../shared/back-button/back-button.component';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { StoreDisplayComponent } from '../../shared/store-display/store-display.component';
import { OrderDetailsMaterialRowContainerComponent } from './order-details-material-row-container.component';
import { ErrorStateComponent } from '../../shared/error-state/error-state/error-state.component';
import { NaooPricePipe } from '../../shared/pipes/naoo-price.pipe';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { NaooDecimalPipe } from '../../shared/pipes/naoo-decimal.pipe';
import { Naoo24to12HrPipe } from '../../shared/pipes/naoo-24to12-hr.pipe';
import { NaooTimeRangePipe } from '../../shared/pipes/naoo-time-range.pipe';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NaooBrandPipe } from '../../shared/pipes/naoo-brand.pipe';
import { NaooCurrencyPipe } from '../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  standalone: true,
  imports: [
    BackButtonComponent,
    ActionIconComponent,
    NgClass,
    MatIcon,
    NgTemplateOutlet,
    StoreDisplayComponent,
    OrderDetailsMaterialRowContainerComponent,
    ErrorStateComponent,
    AsyncPipe,
    NaooPricePipe,
    NaooDatePipe,
    NaooDecimalPipe,
    Naoo24to12HrPipe,
    NaooTimeRangePipe,
    NaooStringDefaulterPipe,
    TranslateModule,
    NaooBrandPipe,
    NaooCurrencyPipe,
  ],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  static readonly ANALYTICS_CATEGORY = 'order history details';
  static readonly ANALYTICS_ACTION_DISPLAYED = 'displayed';
  static readonly ANALYTICS_LABEL_SERVICE_ERROR = 'order service error';
  static readonly ANALYTICS_LABEL_EMPTY_ORDER_ERROR = 'empty order state error';

  static readonly PRINT_ANALYTICS_CATEGORY = 'general';
  static readonly PRINT_ANALYTICS_ACTION = 'print';
  static readonly PRINT_ICON_ANALYTICS_LABEL = 'print icon';
  static readonly PRINT_ANALYTICS_LABEL = 'pdf';
  static readonly CTRLP_PRINT_ANALYTICS_LABEL = 'ctrlp';

  private static readonly RE_ORDER_ANALYTICS_CATEGORY = 'Reorder From History';
  private static readonly RE_ORDER_ANALYTICS_LABEL =
    'Reorder All Items - Order Details Page';
  private static readonly RE_ORDER_ANALYTICS_ACTION = 'Click';

  readonly backNavigationLink: NavigationLink = {
    name: 'ORDERS.DETAILS.BACK',
  };

  currentLocale: Locale;
  orderServiceError = false;
  locationPath: string;
  printIconState: IconState;
  exportIconState$ = new BehaviorSubject<IconState>(IconState.Disabled);
  isMobile$: Observable<boolean>;
  orderDetailsViewModel: OrderDetailsViewModel;
  orderDetailsSubscription$: Subscription;

  private destroyed$ = new Subject<void>();
  // eslint-disable-next-line max-params
  constructor(
    private route: ActivatedRoute,
    private orderDetailsService: OrderDetailsService,
    private localizationService: LocalizationService,
    private routerExtrasService: RouterExtrasService,
    private analytics: NaooAnalyticsManager,
    private deviceIdentifierService: DeviceIdentifierService,
    private customDialogService: CustomDialogService,
    private defaultDialogService: DefaultDialogService,
    private exportOrderDetailsService: ExportOrderDetailsService,
    private editOrderService: EditOrderService,
    private entitlementFacade: EntitlementFacade,
    private _window: Window,
    private router: Router,
    private matDialog: MatDialog,
  ) {
    this.setAppDeviceType();
  }

  ngOnInit(): void {
    this.printIconState = IconState.Disabled;
    this.locationPath = this._window.location.href;
    this.currentLocale = this.localizationService.currentLocale;

    this.fetchOrderDetails();

    this._window.onafterprint = () => {
      this.trackPrintEvent(OrderDetailsComponent.PRINT_ANALYTICS_LABEL);
    };
  }

  ngOnDestroy(): void {
    this._window.onafterprint = () => {};
    this.unsubscribeOrderDetailsSubscription();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.defaultDialogService.closeLoadingModal();
  }

  showEditOrderItemModal(orderItem: OrderDetailsLineViewModel): void {
    this.customDialogService
      .editOrderModal(orderItem)
      .afterClosed()
      .pipe(first())
      .subscribe((result: EditOrderModalResult) => {
        if (result?.editDetails) {
          this.saveEditOrder(result.editDetails, orderItem.isCommodityItem);
        }
      });
  }

  showCancelOrderModal(): void {
    const notEditableLineCount = this.orderDetailsViewModel?.lines?.filter(
      (line) => line.isEditDisabled,
    ).length;

    let shouldOpenLoadingModal = false;

    this.defaultDialogService
      .twoButtonModal(
        'order-details-cancel-order',
        notEditableLineCount
          ? {
              messageKey: 'ORDERS.DETAILS.CANCEL_ORDER.MESSAGE_WARNING',
              parameters: { value: notEditableLineCount },
            }
          : 'ORDERS.DETAILS.CANCEL_ORDER.MESSAGE_DEFAULT',
        'ORDERS.DETAILS.CANCEL_ORDER.CONFIRM',
        'ORDERS.DETAILS.CANCEL_ORDER.ABORT',
        () => {
          shouldOpenLoadingModal = true;
          this.cancelOrder();
        },
        () => {},
        true,
        true,
      )
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        if (shouldOpenLoadingModal) {
          this.defaultDialogService.openLoadingModal();
        }
      });
  }

  showCancelOrderItemModal(orderItem: OrderDetailsLineViewModel): void {
    let shouldOpenLoadingModal = false;

    this.defaultDialogService
      .twoButtonModal(
        'order-details-cancel-item',
        {
          messageKey: 'ORDERS.DETAILS.CANCEL_ORDER_LINE.MESSAGE',
          parameters: { value: orderItem.materialNumber },
        },
        'ORDERS.DETAILS.CANCEL_ORDER_LINE.CONFIRM',
        'ORDERS.DETAILS.CANCEL_ORDER_LINE.ABORT',
        () => {
          shouldOpenLoadingModal = true;
          this.cancelOrderItem(orderItem);
        },
        () => {},
        true,
        true,
      )
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        if (shouldOpenLoadingModal) {
          this.defaultDialogService.openLoadingModal();
        }
      });
  }

  onAddItemsToCart(): void {
    this.customDialogService.reOrderChecker(
      this.orderDetailsViewModel?.reOrderValidationResult,
    );
    this.trackAddItemsToCart();
  }

  isInvoiced(orderStatus: string): boolean {
    return orderStatus === OrderStatus.Invoiced;
  }

  isErrorSeverity(severity: StatusSeverity): boolean {
    return severity === StatusSeverity.Error;
  }

  onPrintIconClick(): void {
    this.trackPrintEvent(OrderDetailsComponent.PRINT_ICON_ANALYTICS_LABEL);
    this.printOrderDetails();
  }

  get currentLanguage(): Language {
    return this.localizationService.currentLanguage;
  }

  export(): void {
    this.exportOrderDetailsService
      .openExportDialog(
        this.orderDetailsViewModel.orderNumber,
        this.orderDetailsViewModel.orderType,
        this.orderDetailsViewModel.groupNumber,
        this.exportIconState$,
      )
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (isPrintEvent(event)) {
      this.trackPrintEvent(OrderDetailsComponent.CTRLP_PRINT_ANALYTICS_LABEL);
      this.printOrderDetails();
    }
  }

  get ActionIcon(): typeof ActionIcon {
    return ActionIcon;
  }

  get lines(): OrderDetailsLineViewModel[] {
    return (
      this.orderDetailsViewModel?.lines?.filter((line) => !!line?.material) ??
      []
    );
  }

  get status(): OrderDetailsOrderStatusDetails {
    return this.orderDetailsViewModel?.statusDetails;
  }

  get submissionDetails(): OrderDetailsSubmissionDetails {
    return this.orderDetailsViewModel?.submissionDetails;
  }

  restoreScrollPosition(): void {
    if (this.routerExtrasService.getRestoreScrollPosition(this.locationPath)) {
      this.routerExtrasService.restoreScrollPosition(this.locationPath);
    }
  }

  private printOrderDetails() {
    this._window.print();
  }

  private saveEditOrder(details: EditDetails, isCommodityItem: boolean): void {
    this.defaultDialogService.openLoadingModal();

    const editOrderRequest: EditOrderRequest = {
      orderNumber: this.orderDetailsViewModel.orderNumber,
      groupNumber: this.orderDetailsViewModel.groupNumber,
      orderType: this.orderDetailsViewModel.orderType,
      materials: details.changes.map((changeItem) => ({
        materialNumber: details.materialNumber,
        quantity: changeItem.qty,
        orderLineId: changeItem.salesDocumentItem,
      })),
    };

    this.processEditOrder(editOrderRequest, isCommodityItem);
  }

  private cancelOrder(): void {
    this.editOrderService
      .cancelOrder({
        orderId: this.orderDetailsViewModel?.orderNumber,
        groupNumber: this.orderDetailsViewModel?.groupNumber,
      })
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (cancelOrderRecord) =>
          this.handleCancelOrderRecord(cancelOrderRecord),
        error: () => {
          this.defaultDialogService.closeLoadingModal();
          this.showOneButtonModal(
            'order-details-cancellation-error',
            'ORDERS.DETAILS.CANCEL_ORDER.CANCELLATION_ERROR',
            'ORDERS.DETAILS.CANCEL_ORDER.OK',
          );
        },
      });
  }

  private showOneButtonModal(
    id: string,
    message: string,
    buttonText: string,
  ): void {
    const openModal = () =>
      this.defaultDialogService.oneButtonModal(
        id,
        message,
        buttonText,
        () => this.fetchOrderDetails(),
        true,
      );
    if (this.matDialog.openDialogs?.length) {
      this.matDialog.afterAllClosed.pipe(first()).subscribe(() => openModal());
    } else {
      openModal();
    }
  }

  private handleCancelOrderRecord(cancelOrderRecord: CancelOrderRecord): void {
    switch (cancelOrderRecord.cancellationStatus) {
      case OrderCancellationStatus.Success:
        this.fetchOrderDetails();
        break;
      case OrderCancellationStatus.Partial:
        this.showOneButtonModal(
          'order-details-cancellation-partial',
          'ORDERS.DETAILS.CANCEL_ORDER.CANCELLATION_PARTIAL',
          'ORDERS.DETAILS.CANCEL_ORDER.VIEW_ORDER',
        );
        break;
      case OrderCancellationStatus.Failed:
      default:
        this.showOneButtonModal(
          'order-details-cancellation-error',
          'ORDERS.DETAILS.CANCEL_ORDER.CANCELLATION_ERROR',
          'ORDERS.DETAILS.CANCEL_ORDER.OK',
        );
        break;
    }
  }

  private cancelOrderItem(orderItem: OrderDetailsLineViewModel): void {
    const editOrderRequest: EditOrderRequest = {
      orderNumber: this.orderDetailsViewModel.orderNumber,
      groupNumber: this.orderDetailsViewModel.groupNumber,
      orderType: this.orderDetailsViewModel.orderType,
      materials: orderItem.units.map((uomLine) => ({
        materialNumber: orderItem.materialNumber,
        quantity: 0,
        orderLineId: uomLine.salesDocumentItem,
      })),
    };

    this.processEditOrder(editOrderRequest);
  }

  private processEditOrder(
    editOrderRequest: EditOrderRequest,
    isCommodityItem?: boolean,
  ): void {
    this.editOrderService
      .saveEditOrder(editOrderRequest)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (order) => {
          const erroredOrderLines = order.details.filter(
            (orderLineDetails) =>
              orderLineDetails.editStatus === EditStatus.ERROR,
          );
          if (erroredOrderLines.length > 0) {
            this.displayErrorModal();
          } else {
            this.fetchOrderDetails();
          }
        },
        error: (error) => {
          this.defaultDialogService.closeLoadingModal();
          this.displayErrorModal(NaooErrorUtils.getNaooError(error));
        },
        complete: () => {
          if (isCommodityItem) {
            this.entitlementFacade.refreshEntitlement();
          }
        },
      });
  }

  private displayErrorModal(error?: NaooError): void {
    this.defaultDialogService.closeLoadingModal();

    const message =
      NAOOErrorCode.ORDER_EDIT_SERVICE_TIMEOUT === error?.code
        ? 'ORDERS.DETAILS.LINE_ERROR_MESSAGE_TIMEOUT'
        : 'ORDERS.DETAILS.LINE_ERROR_MESSAGE';

    this.showOneButtonModal(
      'order-details-error',
      message,
      'ORDERS.DETAILS.VIEW_ORDER',
    );
  }

  private fetchOrderDetails(): void {
    this.unsubscribeOrderDetailsSubscription();
    this.orderDetailsSubscription$ = this.getOrderDetailsViewModel$().subscribe(
      {
        next: (orderDetailsViewModel) => {
          this.orderDetailsViewModel = orderDetailsViewModel;
          this.printIconState = IconState.Enabled;
          this.exportIconState$.next(IconState.Enabled);
          this.orderServiceError = false;
          this.restoreScrollPosition();
          this.trackAnalytics(orderDetailsViewModel.lines);
          this.defaultDialogService.closeLoadingModal();
        },
        error: () => {
          this.orderServiceError = true;
          this.trackOrderDetailsComplete(
            OrderDetailsComponent.ANALYTICS_LABEL_SERVICE_ERROR,
          );
          this.defaultDialogService.closeLoadingModal();
        },
      },
    );
  }

  private getOrderDetailsViewModel$(): Observable<OrderDetailsViewModel> {
    return this.route.params.pipe(
      switchMap((params) => {
        const orderNumber: string = params.id + '' || null;
        const groupNumber: string | undefined = params.groupNumber;
        const orderType: string = params.orderType + '';
        const orderTypeEnum: OrderType = EnumUtilities.enumFromValue(
          orderType.toUpperCase(),
          OrderType,
        );
        if (!orderTypeEnum) {
          this.router.navigate([NaooConstants.ORDERS_PATH], {
            replaceUrl: true,
          });
          return EMPTY;
        }
        return this.orderDetailsService.getOrderDetailsViewModel(
          orderNumber,
          orderTypeEnum,
          groupNumber,
        );
      }),
      takeUntil(this.destroyed$),
    );
  }

  private setAppDeviceType(): void {
    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
  }

  private trackAnalytics(lines: OrderDetailsLineViewModel[]) {
    if (lines && lines.length === 0) {
      this.trackOrderDetailsComplete(
        OrderDetailsComponent.ANALYTICS_LABEL_EMPTY_ORDER_ERROR,
      );
    }
  }

  private trackPrintEvent(label: string): void {
    const eventInfo: AnalyticsEventInfo = {
      action: OrderDetailsComponent.PRINT_ANALYTICS_ACTION,
      category: OrderDetailsComponent.PRINT_ANALYTICS_CATEGORY,
      label: label,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackAddItemsToCart(): void {
    this.analytics.trackAnalyticsEvent({
      action: OrderDetailsComponent.RE_ORDER_ANALYTICS_ACTION,
      category: OrderDetailsComponent.RE_ORDER_ANALYTICS_CATEGORY,
      label: OrderDetailsComponent.RE_ORDER_ANALYTICS_LABEL,
    });
  }

  private trackOrderDetailsComplete(eventLabel: string): void {
    this.analytics.trackAnalyticsEvent({
      action: OrderDetailsComponent.ANALYTICS_ACTION_DISPLAYED,
      category: OrderDetailsComponent.ANALYTICS_CATEGORY,
      label: eventLabel,
    });
  }

  private unsubscribeOrderDetailsSubscription(): void {
    if (this.orderDetailsSubscription$) {
      this.orderDetailsSubscription$.unsubscribe();
    }
  }
}
