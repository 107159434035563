@if (!isFallbackImage) {
  <button class="image-modal-button" (tap)="openImageModal()">
    <div
      class="main-image-slider"
      [ngClass]="{ 'single-image': images?.length === 1 }"
    >
      <div
        #sliderElement
        class="slider"
        (pan)="handlePanEvent($event)"
        [style.touch-action]="'pan-y'"
      >
        @for (image of images; track image.largeImageUrl.src) {
          <naoo-product-image
            [alt]="imageAlt"
            [src]="image?.largeImageUrl?.src"
            [srcSet]="image?.largeImageUrl?.srcSet"
          ></naoo-product-image>
        }
      </div>
    </div>
  </button>
} @else if (thereIsAnImage) {
  <button
    [ngClass]="{ disabled: !isFallbackImage }"
    class="image-modal-button"
    (click)="openImageModal()"
  >
    @for (image of images; track image.largeImageUrl.src) {
      <naoo-product-image
        [ngClass]="{ 'fallback-image': isFallbackImage }"
        class="fill-container"
        [alt]="imageAlt"
        [src]="image?.largeImageUrl?.src"
        [srcSet]="image?.largeImageUrl?.srcSet"
      ></naoo-product-image>
    }
  </button>
} @else {
  @for (image of images; track image.largeImageUrl.src) {
    <naoo-product-image
      [ngClass]="{ 'fallback-image': isFallbackImage }"
      class="fill-container"
      [alt]="imageAlt"
      [src]="image?.largeImageUrl?.src"
      [srcSet]="image?.largeImageUrl?.srcSet"
    ></naoo-product-image>
  }
}

@if (images?.length > 1) {
  <div class="button-row">
    <button
      class="arrow-left"
      [ngClass]="{ 'disabled-arrow': leftArrowDisabled }"
      [attr.aria-label]="'PRODUCT_DETAILS.IMAGE_ARROW.BACK' | translate"
      (click)="arrowClick($event, arrowDirectionBack)"
      (contextmenu)="suppressContextMenu($event)"
    >
      <mat-icon [svgIcon]="'arrow-icon'"></mat-icon>
    </button>
    <div
      class="thumbnail-row"
      (swipeleft)="adjustThumbnailIndex(arrowDirectionNext)"
      (swiperight)="adjustThumbnailIndex(arrowDirectionBack)"
      [style.touch-action]="'pan-y'"
    >
      @for (thumbnail of thumbnails; track thumbnail.largeImageUrl.src) {
        <button
          [ngClass]="{ selected: isThumbnailSelected(thumbnail) }"
          [attr.aria-label]="
            'PRODUCT_DETAILS.IMAGE_INDEX'
              | translate
                : { index: images.indexOf(thumbnail) + 1, count: images.length }
          "
          (click)="selectImage(thumbnail)"
        >
          <naoo-product-image
            class="thumbnail-image"
            [src]="thumbnail?.thumbnailImageUrl?.src"
            [srcSet]="thumbnail?.thumbnailImageUrl?.srcSet"
          ></naoo-product-image>
        </button>
      }
    </div>
    <button
      class="arrow-right"
      [ngClass]="{ 'disabled-arrow': rightArrowDisabled }"
      [attr.aria-label]="'PRODUCT_DETAILS.IMAGE_ARROW.FORWARD' | translate"
      (click)="arrowClick($event, arrowDirectionNext)"
      (contextmenu)="suppressContextMenu($event)"
    >
      <mat-icon [svgIcon]="'arrow-icon'"></mat-icon>
    </button>
  </div>
}
