import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CriticalItemsGuide } from '../../../core/store/critical-items/models/critical-items-guide';
import {
  MaterialListStyle,
  MaterialRowContext,
} from 'src/app/core/store/material-row/models/material-row';
import { MaterialListComponent } from 'src/app/material-list/material-list.component';
import { SortByType } from '../../shared/guides';
import { MaterialListRowType } from '../../../material-list/models/material-list';
import { HeaderHeightService } from '../../../shared/services/header-height/header-height.service';
import { Observable } from 'rxjs';
import { CriticalItemsFacade } from '../../../core/store/critical-items/critical-items.facade';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { NaooLocalStorage } from '../../../shared/storage/local-storage/local-storage.service';
import { NaooConstants } from '../../../shared/NaooConstants';
import { GuidesMobileActionsListComponent } from '../../shared/guides-mobile-actions-list/guides-mobile-actions-list.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GuidesSideBarComponent } from '../../shared/guides-side-bar/guides-side-bar.component';
import { MatIcon } from '@angular/material/icon';
import { GuidesHeaderComponent } from '../../shared/guides-header/guides-header.component';
import { NoResultsComponent } from '../../../shared/no-results/no-results.component';
import { GuidesHeaderBreadcrumbComponent } from '../../shared/guides-header/guides-header-breadcrumb/guides-header-breadcrumb.component';
import { CriticalItemsGuideEmptyComponent } from '../critical-items-guide-empty/critical-items-guide-empty.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-critical-items-guide-content',
  templateUrl: './critical-items-guide-content.component.html',
  styleUrls: ['./critical-items-guide-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuidesMobileActionsListComponent,
    NgClass,
    MatSidenavModule,
    GuidesSideBarComponent,
    MatIcon,
    GuidesHeaderComponent,
    MaterialListComponent,
    NoResultsComponent,
    GuidesHeaderBreadcrumbComponent,
    CriticalItemsGuideEmptyComponent,
    AsyncPipe,
    TranslateModule,
    NaooStringDefaulterPipe,
  ],
})
export class CriticalItemsGuideContentComponent implements OnInit {
  @Input() criticalItemsGuide: CriticalItemsGuide;
  @Input() isMobile: boolean;

  sideNavOpen = true;
  // changes after the drawer completes an open/closed animation
  drawerClosedAndFinishedAnimating = true;
  isShowingMobileActions = false;
  headerHeight$: Observable<number>;
  printInput$: Observable<ExportMaterialsInput>;
  exportInput$: Observable<ExportMaterialsInput>;

  readonly listContext = MaterialRowContext.CriticalItemsGuide;

  @ViewChild('materialList')
  materialList: MaterialListComponent;

  constructor(
    private criticalItemsGuideFacade: CriticalItemsFacade,
    private headerHeightService: HeaderHeightService,
    private analytics: NaooAnalyticsManager,
    private localStorage: NaooLocalStorage,
  ) {}

  ngOnInit() {
    this.headerHeight$ = this.headerHeightService.observeHeaderHeight();
    this.printInput$ =
      this.criticalItemsGuideFacade.getExportMaterialsInput(true);
    this.exportInput$ =
      this.criticalItemsGuideFacade.getExportMaterialsInput(false);
    this.sideNavOpen = this.getSavedMenuState();
  }

  get isFiltering() {
    return this.criticalItemsGuide.header.actionBar.searchText.length !== 0;
  }

  get shouldRenderList(): boolean {
    return (
      (this.isFiltering &&
        this.criticalItemsGuide.materialListRows.length > 0) ||
      this.criticalItemsGuide.materialListRows.length > 0
    );
  }

  get shouldRenderGuideContent(): boolean {
    return (
      this.isFiltering ||
      this.criticalItemsGuide.materialListRows.filter(
        (row) => row.type === MaterialListRowType.MaterialRow,
      ).length > 0
    );
  }

  toggleDrawer() {
    this.sideNavOpen = !this.sideNavOpen;
    this.localStorage.setItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
      '' + this.sideNavOpen,
    );
  }

  drawerStateChangeCompleted() {
    this.drawerClosedAndFinishedAnimating = !this.sideNavOpen;
  }

  toggleMobileActions() {
    this.isShowingMobileActions = !this.isShowingMobileActions;
  }

  handleScrollToCategory(vsIndex: number) {
    this.materialList.scrollToIndex(vsIndex);
  }

  handleUpdateSearchText(searchText: string) {
    this.criticalItemsGuideFacade.updateSearchText(searchText);
  }

  handleUpdateMaterialView(listStyle: MaterialListStyle) {
    const eventInfo: AnalyticsEventInfo = {
      category: ListsAnalyticsConstants.criticalItemsCategory,
      action: ListsAnalyticsConstants.clickAction,
      label: `${listStyle} view`,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  handleSortByChanged(sortByType: SortByType) {
    this.criticalItemsGuideFacade.updateSortBy(sortByType);

    this.analytics.trackAnalyticsEvent({
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.criticalItemsCategory,
      label: `sort by ${sortByType}`,
    });
  }

  get expansionAriaText(): string {
    return this.sideNavOpen
      ? 'LISTS.FILTERS.COLLAPSE_MENU'
      : 'LISTS.FILTERS.EXPAND_MENU';
  }

  private getSavedMenuState(): boolean {
    const savedState = this.localStorage.getItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
    );
    return savedState == null ? true : savedState === 'true';
  }
}
