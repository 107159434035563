import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  DefaultFilterGroup,
  Taxonomy,
  ActiveDefaultFilter,
} from '../../../../core/store/search/shared/search-results';
import { FilterChipsComponent } from './filter-chips/filter-chips.component';
import { TaxonomyFilterGroupComponent } from './taxonomy-filter-group/taxonomy-filter-group.component';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FilterChipsComponent,
    TaxonomyFilterGroupComponent,
    FilterGroupComponent,
    TranslateModule,
  ],
})
export class SearchFiltersComponent {
  @Input() defaultFilters: DefaultFilterGroup[];
  @Input() taxonomy: Taxonomy;
  @Input() activeDefaultFilters: ActiveDefaultFilter[];
}
