import { map, takeUntil, tap } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, filter, Subject } from 'rxjs';
import { NaooConstants } from '../../shared/NaooConstants';
import { ProductDirection } from '../../product-row/product-guide-edit/product-direction';
import { ToastMessageService } from '../../shared/services/toast-message/toast-message.service';
import {
  ProductAction,
  ProductActionInfo,
} from '../../product-row/product-guide-edit/product-action';
import { RenameCategoryAction } from '../category-edit-menu/rename-category-modal/rename-category-action';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { CreateCategoryModalComponent } from '../create-category-modal/create-category-modal.component';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { DOCUMENT, NgClass } from '@angular/common';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NavigationLink } from '../../shared/models/navigation-link';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import {
  CategorizedCustomGuide,
  CategorizedMaterials,
  sortByBrand,
  sortByCustom,
  sortByDescription,
  sortByItemCode,
  sortByLastOrderDate,
} from '../../shared/models/categorized-materials';
import { MaterialInfo } from '../../shared/models/material-info';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { CustomGuideRecord } from '../../core/services/custom-guide/model/custom-guide-record';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { getIndexForDirection } from './shared/edit-custom-guide.util';
import { CustomGuideMaterialParOrder } from '../../core/store/custom-guide/custom-guide.selectors';
import { getMaterialNumbersForCategories } from '../../core/store/custom-guide/custom-guide.util';
import { LastOrderedFacade } from '../../core/store/last-ordered/last-ordered.facade';
import { ListsAnalyticsConstants } from '../lists-analytics.constants';
import { GroupByType, SortByType } from 'src/app/guides/shared/guides';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Language } from '../../core/services/session/models/session-record';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { BackButtonComponent } from '../../shared/back-button/back-button.component';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { EditCustomGuideCategoryComponent } from './edit-custom-guide-category/edit-custom-guide-category.component';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-edit-custom-guide',
  templateUrl: './edit-custom-guide.component.html',
  styleUrls: ['./edit-custom-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BackButtonComponent,
    BreadcrumbComponent,
    MatIcon,
    MatTabsModule,
    MatProgressBar,
    DragDropModule,
    NgClass,
    MatIconButton,
    MatFormField,
    MatSelect,
    MatOption,
    EditCustomGuideCategoryComponent,
    NaooStringDefaulterPipe,
    TranslateModule,
  ],
})
export class EditCustomGuideComponent implements OnInit, OnDestroy {
  customGuideId: string;
  customGuideName: string;
  isParOrderEnabled: boolean;
  sortBy = SortByType.Custom;
  groupBy = GroupByType.Custom;
  materialParOrders: Map<string, CustomGuideMaterialParOrder> = new Map();
  categorizedMaterials: CategorizedMaterials[] = [];
  materials: MaterialInfo[] = [];

  groupByOptions = NaooConstants.GROUP_BY_OPTIONS;
  selectedGroupByTabIndex = 0;

  hasLoaded: Map<GroupByType, boolean> = new Map();

  breadcrumbs: NavigationLink[];
  backNavigationLink: NavigationLink;

  currentLanguage: Language;
  destroyed$ = new Subject();

  isMobile = false;
  groupIsChanging = false;

  private readonly redirectTimerDelay = 5000;
  private redirectTimerForCustomGuide: number;
  private redirectTimerForCategorizedCustomGuide: number;

  // eslint-disable-next-line max-params
  constructor(
    public dialog: MatDialog,
    @Inject(DOCUMENT) private _document: Document,
    private activatedRoute: ActivatedRoute,
    private analytics: NaooAnalyticsManager,
    private customDialogService: CustomDialogService,
    private customGuideFacade: CustomGuideFacade,
    private lastOrderedFacade: LastOrderedFacade,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private deviceIdentifierService: DeviceIdentifierService,
    private changeDetectorRef: ChangeDetectorRef,
    private _window: Window,
  ) {}

  get isLoading(): boolean {
    return this.groupIsChanging || this.selectedGroupIsLoading();
  }

  get canCustomizeCategories() {
    return this.groupBy === GroupByType.Custom;
  }

  get totalMaterials() {
    return this.categorizedMaterials.reduce(
      (totalMaterials, categorizedMaterialRecord) => {
        return totalMaterials + categorizedMaterialRecord.items.length;
      },
      0,
    );
  }

  get sortByOptions(): { display: string; name: SortByType }[] {
    switch (this.groupBy) {
      case GroupByType.Taxonomy:
      case GroupByType.Storage:
        return NaooConstants.SORT_BY_OPTIONS.filter(
          (sortOption) => sortOption.name !== SortByType.Custom,
        );
      case GroupByType.Custom:
      default:
        return NaooConstants.SORT_BY_OPTIONS;
    }
  }

  get isCustom(): boolean {
    return this.groupBy === 'custom';
  }

  ngOnInit() {
    this.groupByOptions.forEach((groupBy) => {
      this.hasLoaded.set(groupBy.name, false);
    });
    this.changeDetectorRef.markForCheck();

    this.setupSubscriptions();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.customGuideFacade.clearCategorizedCustomGuide(this.customGuideId);
  }

  isFirstUserCategory(index: number) {
    return index === 1;
  }

  moveUp(categorizedMaterial: CategorizedMaterials) {
    this.moveCategory({
      categoryName: categorizedMaterial.categoryName.en,
      direction: ProductDirection.up,
    });
    this.trackCategoryMoved('up');
  }

  moveDown(categorizedMaterial: CategorizedMaterials) {
    this.moveCategory({
      categoryName: categorizedMaterial.categoryName.en,
      direction: ProductDirection.down,
    });
    this.trackCategoryMoved('down');
  }

  moveCategory(event: { categoryName: string; direction: ProductDirection }) {
    if (this.canCustomizeCategories) {
      this.moveCustomGuideCategoryWithDirection(
        this.categorizedMaterials,
        event.categoryName,
        event.direction,
      );
    }
  }

  actOnMaterialInACategory(event: ProductActionInfo) {
    if (event.action === ProductAction.remove) {
      this.customGuideFacade.removeCustomGuideMaterials(this.customGuideId, [
        event.materialNumber,
      ]);
      this.trackRemoveMaterialFromGuide();
    } else if (
      event.action === ProductAction.move &&
      this.groupBy === GroupByType.Custom
    ) {
      const customCategorizedMaterialRecord = this.categorizedMaterials;
      const newCategoryIndex = this.getCategoryIndexFromCategorizedMaterials(
        event.categoryName,
        customCategorizedMaterialRecord,
      );
      const newMaterialIndex = 0; // move to the beginning of the category
      this.customGuideFacade.moveCustomGuideMaterials(
        this.customGuideId,
        event.materialNumber,
        newCategoryIndex,
        newMaterialIndex,
      );
      this.trackMoveMaterialToDifferentCategory();
      this.toastMessageService.showMovedToCategoryMessage(
        event.categoryName,
        true,
      );
    }
  }

  openCreateCategoryModal() {
    if (this.canCustomizeCategories) {
      this.dialog
        .open(CreateCategoryModalComponent, {
          id: NaooConstants.CREATE_CATEGORY_MODAL_ID,
          panelClass: 'custom-guide-modal',
          disableClose: true,
          data: {
            customGuideId: this.customGuideId,
          },
        })
        .afterClosed()
        .subscribe((categoryName) => {
          if (categoryName === undefined) {
            return;
          }

          this.customGuideFacade.createCustomGuideCategory(
            this.customGuideId,
            categoryName,
          );
          this.trackCreateNewCategory();
        });
    }
  }

  openRenameModal() {
    this.customDialogService.openRenameCustomGuideModal(
      this.customGuideId,
      this.customGuideName,
    );
  }

  actOnDeleteCategory(event: CategorizedMaterials) {
    const categoryIndex = this.getCategoryIndexFromCategorizedMaterials(
      event.categoryName.en,
      this.categorizedMaterials,
    );
    this.customGuideFacade.removeCustomGuideCategory(
      this.customGuideId,
      categoryIndex,
    );
    this.toastMessageService.showDeletedCategoryMessage(
      event.categoryName.en,
      this.customGuideName,
    );
  }

  actOnRenameCategory(event: RenameCategoryAction) {
    if (this.canCustomizeCategories) {
      const categoryIndex = this.getCategoryIndexFromCategorizedMaterials(
        event.oldCategoryName,
        this.categorizedMaterials,
      );
      this.customGuideFacade.renameCustomGuideCategory(
        this.customGuideId,
        categoryIndex,
        event.newCategoryName,
      );
    }
  }

  drop(event: CdkDragDrop<string, any>): void {
    this.customGuideFacade.moveCustomGuideMaterials(
      this.customGuideId,
      event.item.data,
      +event.container.data,
      0,
    );
  }

  onGroupByChange(groupByTabIndex: number) {
    this.groupIsChanging = true;
    this.changeDetectorRef.markForCheck();

    const groupBy = this.groupByOptions[groupByTabIndex].name;
    this.trackGroupByChange(groupBy);

    setTimeout(() => {
      this.customGuideFacade.updateGroupBy(this.customGuideId, groupBy);
    });
  }

  onSortByChange(event: MatSelectChange) {
    const sortBy = <SortByType>event.value;
    this.trackSortByDropdown(sortBy);
    this.customGuideFacade.updateSortBy(this.customGuideId, sortBy);
  }

  doneEditing() {
    this.router.navigate(['guides', 'custom-guide', this.customGuideId]);
  }

  isUpArrowActive(index: number): boolean {
    return !this.isFirstUserCategory(index);
  }

  isDownArrowActive(isLast: boolean): boolean {
    return !isLast;
  }

  private selectedGroupIsLoading(): boolean {
    return !this.hasLoaded.get(this.groupBy);
  }

  private setupSubscriptions() {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((routeParams) => {
        this.customGuideId = decodeURI(routeParams.id);
        this.changeDetectorRef.markForCheck();

        this.customGuideFacade
          .getCustomGuideRecord(this.customGuideId)
          .pipe(
            tap((customGuideRecord) => {
              if (customGuideRecord) {
                if (this.redirectTimerForCustomGuide) {
                  this._window.clearTimeout(this.redirectTimerForCustomGuide);
                }
              } else {
                this.redirectTimerForCustomGuide = this._window.setTimeout(
                  () => this.router.navigate([NaooConstants.GUIDES_PATH]),
                  this.redirectTimerDelay,
                );
              }
            }),
            filter((customGuideRecord) => !!customGuideRecord),
            takeUntil(this.destroyed$),
          )
          .subscribe((customGuideRecord) => {
            this.updateGroupAndSort(customGuideRecord);
            this.isParOrderEnabled = customGuideRecord.parOrderingEnabled;
            this.customGuideName = customGuideRecord.name;
            this.setBreadcrumbs();
            this.preloadSupportingData(customGuideRecord);
            this.changeDetectorRef.markForCheck();
          });

        this.customGuideFacade
          .getCustomGuideMaterialParOrders(this.customGuideId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((materialParOrders) => {
            this.materialParOrders = materialParOrders;
            this.changeDetectorRef.markForCheck();
          });

        setTimeout(() => {
          const language$ = this.localizationService.language().pipe(
            map((language) => {
              this.currentLanguage = language;
              this.setBreadcrumbs();
              this.changeDetectorRef.markForCheck();
            }),
          );

          const getCategorizedCustomGuide$ = this.customGuideFacade
            .getCategorizedCustomGuide(this.customGuideId)
            .pipe(
              tap((categorizedCustomGuide) => {
                if (categorizedCustomGuide) {
                  if (this.redirectTimerForCategorizedCustomGuide) {
                    this._window.clearTimeout(
                      this.redirectTimerForCategorizedCustomGuide,
                    );
                  }
                } else {
                  this.redirectTimerForCategorizedCustomGuide =
                    this._window.setTimeout(
                      () => this.router.navigate([NaooConstants.GUIDES_PATH]),
                      this.redirectTimerDelay,
                    );
                }
              }),
              filter((categorizedCustomGuide) => !!categorizedCustomGuide),
              takeUntil(this.destroyed$),
            );

          combineLatest([getCategorizedCustomGuide$, language$])
            .pipe(
              map(
                ([categorizedCustomGuide, _]) =>
                  <CategorizedCustomGuide>{
                    ...categorizedCustomGuide,
                    categorizedMaterials: this.sortCategories(
                      categorizedCustomGuide.categorizedMaterials,
                      categorizedCustomGuide.sortBy,
                    ),
                  },
              ),
              tap((categorizedCustomGuide) => {
                this.updateGroupAndSort(categorizedCustomGuide);
                this.setSortOnFirstCustomLoad();
                this.categorizedMaterials =
                  categorizedCustomGuide.categorizedMaterials;
                this.hasLoaded.set(categorizedCustomGuide.groupBy, true);
                this.groupIsChanging = false;
                this.changeDetectorRef.markForCheck();
              }),
              takeUntil(this.destroyed$),
            )
            .subscribe();
        });
      });
  }

  private isFirstLoad(): boolean {
    return Array.from(this.hasLoaded.values()).every((loaded) => !loaded);
  }

  private preloadSupportingData(customGuideRecord: CustomGuideRecord) {
    if (this.isFirstLoad()) {
      const materialNumbers = getMaterialNumbersForCategories(
        customGuideRecord.categories,
      );
      this.lastOrderedFacade.loadLastOrdered(materialNumbers);
    }
    if (this.groupBy !== GroupByType.Custom && this.selectedGroupIsLoading()) {
      this.customGuideFacade.loadCategorizedCustomGuide(
        this.customGuideId,
        this.groupBy,
      );
    }
  }

  private updateGroupAndSort({
    groupBy,
    sortBy,
  }: CategorizedCustomGuide | CustomGuideRecord): void {
    this.groupBy = groupBy;
    this.sortBy = sortBy;
    // If the current sortBy option isn't in our new list of sortOptions grab the first option for display
    if (!this.sortByOptions.map((option) => option.name).includes(sortBy)) {
      this.sortBy = this.sortByOptions[0].name;
    }
    this.selectedGroupByTabIndex = this.groupByOptions.findIndex(
      (option) => option.name === groupBy,
    );
    this.changeDetectorRef.markForCheck();
  }

  private getCategoryIndexFromCategorizedMaterials(
    categoryName: string,
    categorizedMaterials: CategorizedMaterials[],
  ): number {
    return categorizedMaterials.findIndex(
      (categorizedMaterial) =>
        categorizedMaterial.categoryName.en === categoryName,
    );
  }

  private setBreadcrumbs() {
    this.backNavigationLink = {
      name: `${this.localizationService.instant('LISTS.BACK_TO')} ${
        this.customGuideName
      }`,
      link: `${NaooConstants.CUSTOM_GUIDE_PATH}/${this.customGuideId}`,
    };
    const root: NavigationLink = {
      name: this.localizationService.instant('MENU.GUIDES'),
      link: NaooConstants.GUIDES_PATH,
    };
    const current: NavigationLink = {
      name: this.customGuideName,
      link: `${NaooConstants.CUSTOM_GUIDE_PATH}/${this.customGuideId}`,
    };
    const editCurrent: NavigationLink = {
      name: `${this.localizationService.instant('LISTS.EDIT')} ${
        this.customGuideName
      }`,
      link: null,
    };
    this.breadcrumbs = [root, current, editCurrent];
  }

  private moveCustomGuideCategoryWithDirection(
    categorizedMaterialRecords: CategorizedMaterials[],
    categoryName: string,
    direction: ProductDirection,
  ): void {
    const originalCategoryIndex = this.getCategoryIndexFromCategorizedMaterials(
      categoryName,
      categorizedMaterialRecords,
    );
    const newCategoryIndex = getIndexForDirection(
      originalCategoryIndex,
      categorizedMaterialRecords.length,
      direction,
    );
    if (newCategoryIndex !== -1) {
      this.customGuideFacade.moveCustomGuideCategory(
        this.customGuideId,
        originalCategoryIndex,
        newCategoryIndex,
      );
    }
  }

  private sortCategories(
    categorizedMaterials: CategorizedMaterials[],
    sortBy: SortByType,
  ): CategorizedMaterials[] {
    switch (sortBy) {
      case SortByType.Custom: {
        return categorizedMaterials.map((material) => sortByCustom(material));
      }
      case SortByType.Description: {
        return categorizedMaterials.map((material) =>
          sortByDescription(material, this.currentLanguage),
        );
      }
      case SortByType.Brand: {
        return categorizedMaterials.map((material) =>
          sortByBrand(material, this.currentLanguage),
        );
      }
      case SortByType.ItemCode: {
        return categorizedMaterials.map((material) => sortByItemCode(material));
      }
      case SortByType.OrderDate:
        return categorizedMaterials.map((material) =>
          sortByLastOrderDate(material, this.currentLanguage),
        );
    }
  }

  private setSortOnFirstCustomLoad() {
    const isFirstCustomLoad =
      this.canCustomizeCategories && this.selectedGroupIsLoading();
    if (isFirstCustomLoad && this.sortBy !== SortByType.Custom) {
      this.customGuideFacade.updateSortBy(
        this.customGuideId,
        SortByType.Custom,
      );
    }
  }

  private trackGroupByChange(groupBy: string) {
    const selectedGroupByOption = this.groupByOptions.find(
      (groupByOption) => groupByOption.name === groupBy,
    );
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `organize - group by ${selectedGroupByOption.analyticsLabel}`,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackSortByDropdown(sortBy: string) {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `organize - sort by ${sortBy}`,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackRemoveMaterialFromGuide() {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'remove from guide',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackMoveMaterialToDifferentCategory() {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'move to category',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackCreateNewCategory() {
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'new category',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private trackCategoryMoved(direction: string) {
    const event: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `${direction} arrow - category`,
    };
    this.analytics.trackAnalyticsEvent(event);
  }
}
