import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { StorePurchaseDetailsFacade } from '../../../../core/store/store-purchase-details/store-purchase-details.facade';
import { getStorePurchaseKeyFromParams } from '../../../../shared/models/store-purchase-key';
import { StorePurchaseDetailsViewModel } from '../../../../core/store/store-purchase-details/models/store-purchase-details-view-model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PrintService } from '../../../../shared/services/print/print.service';
import { StorePurchaseDetailsContentComponent } from './content/store-purchase-details-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-store-purchase-details-container',
  template: `<naoo-store-purchase-details-content
    [viewModel]="viewModel$ | async"
    [isMobileWidth]="
      (breakPointState$ | async)?.breakpoints[this.mobileBreakpoint]
    "
    [isTabletWidth]="
      (breakPointState$ | async)?.breakpoints[this.tabletBreakpoint]
    "
    [shouldRenderPrint]="shouldRenderPrint$ | async"
    (printEmitter)="printClicked()"
  >
  </naoo-store-purchase-details-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StorePurchaseDetailsContentComponent, AsyncPipe],
})
export class StorePurchaseDetailsContainerComponent
  implements OnDestroy, OnInit
{
  readonly mobileBreakpoint = '(max-width: 600px)';
  readonly tabletBreakpoint = '(max-width: 900px)';

  constructor(
    private activatedRoute: ActivatedRoute,
    private storePurchaseDetailsFacade: StorePurchaseDetailsFacade,
    private breakpointObserver: BreakpointObserver,
    private printService: PrintService,
  ) {}

  viewModel$: Observable<StorePurchaseDetailsViewModel>;
  breakPointState$: Observable<BreakpointState> =
    this.breakpointObserver.observe([
      this.mobileBreakpoint,
      this.tabletBreakpoint,
    ]);
  shouldRenderPrint$: Subject<boolean> = new BehaviorSubject(false);

  private destroyed$ = new Subject();

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        const storePurchaseKey = getStorePurchaseKeyFromParams(params);
        if (storePurchaseKey) {
          this.storePurchaseDetailsFacade.loadStorePurchaseDetails(
            storePurchaseKey,
          );
          this.viewModel$ =
            this.storePurchaseDetailsFacade.getStorePurchaseDetailsViewModel(
              storePurchaseKey,
            );
        }
      });

    this.printService.setUp(
      () => {
        this.shouldRenderPrint$.next(true);
        return of(true);
      },
      () => {
        this.shouldRenderPrint$.next(false);
      },
    );
  }

  ngOnDestroy(): void {
    this.printService.tearDown();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  printClicked(): void {
    this.printService.print();
  }
}
