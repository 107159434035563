import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { forkJoin, Observable } from 'rxjs';
import { PunchoutOrderMessage } from '../../models/punchout-order-message';
import { SplitOrderRequest } from '../../../core/services/cart-order/models/cart-order';
import { CustomDialogService } from '../dialog/custom-dialog/custom-dialog.service';
import { CartFacade } from '../../../core/store/cart/cart.facade';
import { NaooAnalyticsManager } from '../../analytics/NaooAnalyticsManager';
import { Router } from '@angular/router';
import { DefaultDialogService } from '../dialog/default-dialog/default-dialog.service';
import { MultipleCartsFacade } from '../../../core/store/multiple-carts/multiple-carts.facade';
import { ModalFacade } from '../../../core/store/modal/modal.facade';
import { filter, first } from 'rxjs/operators';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from '../../error-handler/NaooErrorUtils';
import { NaooConstants } from '../../NaooConstants';

@Injectable({
  providedIn: 'root',
})
export class PunchoutService {
  private static readonly PUNCHOUT_CART_ENDPOINT = '/api/v1/cart/';
  private static readonly PUNCHOUT = '/punchout';
  punchOutCartUrl = `${
    this.webBffService.getBff() + PunchoutService.PUNCHOUT_CART_ENDPOINT
  }`;

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
    private customDialogService: CustomDialogService,
    private cartFacade: CartFacade,
    private analytics: NaooAnalyticsManager,
    private router: Router,
    private defaultDialogService: DefaultDialogService,
    private multipleCartsFacade: MultipleCartsFacade,
    private modalFacade: ModalFacade,
  ) {}

  public punchOutCart(cartTotal: number): void {
    this.customDialogService.openSubmitLoadingModal();

    forkJoin([
      this.cartFacade.getLoadedCart().pipe(first()),
      this.cartFacade.isDoneUpdating().pipe(
        filter((isDoneUpdating) => isDoneUpdating),
        first(),
      ),
    ]).subscribe(([cart, _]) => {
      this.punchOutCartRequest(cart.id, {
        splitOrders: cart.splitOrders,
      }).subscribe(
        (punchOutOrderMessage: PunchoutOrderMessage) => {
          this.defaultDialogService.closeLoadingModal();
          this.trackPunchoutEvent(cartTotal);
          this.navigateToPunchOutCxmlOutput(punchOutOrderMessage);
        },
        (error) => this.handlePunchOutCartError(error),
      );
    });
  }

  private punchOutCartRequest(
    cartId: string,
    splitOrderRequest: SplitOrderRequest,
  ): Observable<PunchoutOrderMessage> {
    return this.httpClient.post<PunchoutOrderMessage>(
      this.punchOutCartUrl + cartId + PunchoutService.PUNCHOUT,
      splitOrderRequest,
    );
  }

  private trackPunchoutEvent(cartTotal: number): void {
    this.analytics.trackAnalyticsEvent({
      action: 'click',
      category: 'punchout',
      label: 'Punchout Cart - ',
      value: Math.ceil(cartTotal),
    });
  }

  private handlePunchOutCartError(error: HttpErrorResponse): void {
    this.navigateToCartPage().then(() => this.displayModalForError(error));
  }

  private displayModalForError(httpError: HttpErrorResponse): void {
    const { code, message } = NaooErrorUtils.getNaooError(httpError);
    switch (code) {
      case NAOOErrorCode.COMMODITY_OVER_ALLOCATED:
        this.customDialogService.openMaterialOverallocationModal(message);
        break;
      case NAOOErrorCode.INACTIVE_CART:
        this.promptOneButtonErrorModal('CART.ERROR.INACTIVE_CART');
        break;
      case NAOOErrorCode.CART_ALREADY_SUBMITTED:
        this.promptOneButtonErrorModal('CART.ERROR.ALREADY_PUNCHED_OUT');
        break;
      case NAOOErrorCode.COMMODITY_SERVICE_UNAVAILABLE:
        this.promptOneButtonErrorModal(
          'ENTITLEMENT.COMMODITY_SERVICE_DOWN.MESSAGE',
          'ENTITLEMENT.COMMODITY_SERVICE_DOWN.CONFIRM',
        );
        break;
      default:
        this.defaultDialogService.closeLoadingModal();
    }
  }

  private promptOneButtonErrorModal(
    messageData: string,
    buttonText: string = 'SHARED.MODALS.OK_BUTTON_TEXT',
  ): void {
    this.modalFacade.promptOneButtonModal(
      'submit-button-cart-error-refresh',
      messageData,
      buttonText,
      () => {
        this.cartFacade.refreshCart();
        this.multipleCartsFacade.refreshSavedCarts();
      },
      false,
    );
  }

  private navigateToCartPage(): Promise<boolean> {
    return this.router.navigate([NaooConstants.CART_PATH]);
  }

  private navigateToPunchOutCxmlOutput(
    punchOutOrderMessage: PunchoutOrderMessage,
  ): void {
    this.router.navigate([NaooConstants.PUNCH_OUT_URL], {
      state: { punchOutOrderMessage },
    });
  }
}
