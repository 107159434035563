import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ImageInfo,
  RecommendationBanner,
  SponsoredRecommendationsState,
} from '../../../../core/store/search/search.state';
import { Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ScrollListenerService } from '../../../../shared/services/scroll-listener/scroll-listener.service';
import { EcommerceAnalyticsFacade } from '../../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { PromotionEventData } from '../../../../core/services/ecommerce-analytics/models/google-events';
import { SearchRecommendationsContentComponent } from './search-recommendations-content/search-recommendations-content.component';

@Component({
  selector: 'naoo-search-recommendations-container',
  template: `<naoo-search-recommendations-content
    [currentImage]="currentImage"
    [altText]="altText"
    (navigate)="navigateToUrl()"
  >
  </naoo-search-recommendations-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchRecommendationsContentComponent],
})
export class SearchRecommendationsContainerComponent
  implements OnInit, OnDestroy
{
  @Input() sponsoredRecommendations: SponsoredRecommendationsState;

  private readonly sponsoredBannersContentSelector: string =
    'naoo-search-recommendations-content';
  readonly mobileBreakpoint = '(max-width: 600px)';
  readonly tabletBreakpoint = '(max-width: 900px)';

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private readonly scrollListenerService: ScrollListenerService,
    private readonly ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  private readonly destroyed$ = new Subject();
  private device: string;
  trackPromotionViewEventSent: boolean;

  ngOnInit(): void {
    this.checkIfAdsAreVisibleAfterLoading();
    this.breakpointObserver
      .observe([this.tabletBreakpoint, this.mobileBreakpoint])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        const previousDevice = this.device;
        if (result.breakpoints[this.mobileBreakpoint]) {
          this.device = 'MOBILE';
        } else if (result.breakpoints[this.tabletBreakpoint]) {
          this.device = 'TABLET';
        } else {
          this.device = 'DESKTOP';
        }
        if (previousDevice !== this.device) {
          this.changeDetector.markForCheck();
        }
      });

    this.scrollListenerService
      .getEvents()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.detectAdImpressions());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get sponsoredBanner(): RecommendationBanner {
    return this.sponsoredRecommendations?.banners[0];
  }

  get currentImage(): ImageInfo {
    return this.sponsoredBanner.imageVersions?.find(
      (type) => this.device === type.device,
    );
  }

  get altText(): string {
    return this.sponsoredBanner.imageAltText;
  }

  navigateToUrl() {
    this.ecommerceAnalyticsFacade.trackPromotionClickEvent(
      this.buildPromotionEventData(),
    );
    this.router.navigateByUrl(this.sponsoredBanner.destinationUrl);
  }

  private checkIfAdsAreVisibleAfterLoading() {
    setTimeout(() => {
      this.detectAdImpressions();
    }, 500);
  }

  private detectAdImpressions() {
    const recommendationBannerElement = document.querySelector(
      this.sponsoredBannersContentSelector,
    );
    if (
      !!recommendationBannerElement &&
      !this.trackPromotionViewEventSent &&
      this.isHalfVisible(recommendationBannerElement)
    ) {
      this.trackPromotionViewEventSent = true;
      this.ecommerceAnalyticsFacade.trackPromotionViewEvent(
        this.buildPromotionEventData(),
      );
    }
  }

  private buildPromotionEventData(): PromotionEventData {
    return {
      recommendationBanner: this.sponsoredBanner,
      materials: Array.from(
        this.sponsoredRecommendations.productRecommendations.keys(),
      ),
    };
  }

  private isHalfVisible(elm: Element): boolean {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight,
    );
    return viewHeight - rect.top >= rect.height / 2;
  }
}
