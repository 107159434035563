@if (isMobileHeader) {
  <span class="mobile-display">
    {{ storeRecord?.name }}
    <ng-container [ngTemplateOutlet]="infoButton"></ng-container>
  </span>
} @else {
  <div class="store-location">
    <span class="store-header">
      <div
        class="store-name"
        [ngClass]="{ 'non-bolded': isNonBoldedStoreName }"
      >
        {{ storeRecord?.name }}
      </div>
      @if (!isMobileWidth) {
        {{ formattedPhoneNumber }}
      } @else {
        <a href="{{ 'tel:' + formattedPhoneNumber }}">
          {{ formattedPhoneNumber }}
        </a>
      }
    </span>
    <div class="address-line-one">{{ storeRecord?.address.address1 }}</div>
    <span class="address-line-two">
      {{ addressLineTwo }}
      <ng-container [ngTemplateOutlet]="infoButton"></ng-container>
    </span>
  </div>
}

<ng-template #infoButton>
  <button
    #tooltip="matTooltip"
    mat-icon-button
    class="info-tooltip-button"
    matTooltipClass="line-broken-tooltip"
    matTooltipPosition="right"
    [matTooltip]="tooltipText"
    (click)="manualTooltip.toggle()"
  >
    <mat-icon svgIcon="filled_info_icon"></mat-icon>
  </button>
</ng-template>
