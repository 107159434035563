import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { CategoryLink } from '../../../../core/store/search/shared/search-results';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-categories-breadcrumbs',
  templateUrl: './categories-breadcrumbs.component.html',
  styleUrls: ['./categories-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule, NaooStringDefaulterPipe],
})
export class CategoriesBreadcrumbsComponent {
  @Input() categoryLinks: CategoryLink[];

  constructor(
    private router: Router,
    private searchFacade: SearchFacade,
  ) {}

  onCategoryClicked(categoryLink: CategoryLink, isLast: boolean = false) {
    if (!isLast) {
      if (categoryLink.link) {
        this.router.navigate([categoryLink.link]);
      } else {
        this.searchFacade.newCatalogSearch(categoryLink.categoryKey);
      }
    }
  }

  get mobileBreadCrumb(): CategoryLink {
    return this.categoryLinks
      .filter((categoryLink) => categoryLink.depth === 1)
      .pop();
  }
}
