@if (isDisabled) {
  <div [class]="'disabled ' + className">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
} @else {
  <a
    class="links"
    tabindex="0"
    [class]="className"
    [routerLink]="routingUrl"
    (click)="clickEvent.emit()"
    (keyup.enter)="clickEvent.emit()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
