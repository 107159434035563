import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { of, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { CustomGuideCategoryRecord } from '../../../core/services/custom-guide/model/custom-guide-record';

const unassignedValues = ['unassigned', 'non-assignés'];

@Injectable({
  providedIn: 'root',
})
export class CustomGuideCategoryNameExistsValidator {
  constructor(private customGuideFacade: CustomGuideFacade) {}

  validate(
    customGuideId: string,
    initialCustomGuideCategoryName?: string,
  ): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
      const initialCustomGuideCategoryValue =
        initialCustomGuideCategoryName?.toLowerCase();
      const newCustomGuideCategoryName = ctrl.value.toLowerCase().trim();
      if (initialCustomGuideCategoryValue === newCustomGuideCategoryName) {
        return of(null);
      }
      if (unassignedValues.includes(newCustomGuideCategoryName)) {
        return of({ duplicateCategoryName: ctrl.value });
      }
      return this.customGuideFacade.getCustomGuideRecord(customGuideId).pipe(
        first(),
        map((customGuide) => {
          const existingNamedCategory = customGuide?.categories?.find(
            (categories: CustomGuideCategoryRecord) =>
              categories.name?.en?.toLowerCase() === newCustomGuideCategoryName,
          );
          return existingNamedCategory
            ? { duplicateCategoryName: ctrl.value }
            : null;
        }),
        catchError(() => of(null)),
      );
    };
  }
}
