import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  CurrentSystem,
  Locale,
} from '../../core/services/session/models/session-record';
import { SessionFacade } from '../../core/store/session/session.facade';
import { Observable, of, Subject } from 'rxjs';
import { CustomerNotificationPreferencesFacade } from '../../core/store/customer-notification-preferences/customer-notification-preferences.facade';
import {
  CustomerNotification,
  NotificationMethodCode,
  Notifications,
} from './shared/models/customer-notifications';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from '../../shared/services/translation/localization.service';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { NaooConstants } from '../../shared/NaooConstants';
import { getLanguageTag } from '../../shared/utilities/locale-utilities';
import {
  MatTabGroup,
  MatTab,
  MatTabContent,
  MatTabLabel,
} from '@angular/material/tabs';
import { PreferencesContentComponent } from './preferences-content/preferences-content.component';
import { MatIcon } from '@angular/material/icon';
import { ErrorMessageComponent } from '../../shared/error-message/error-message.component';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerPreferencesFacade } from 'src/app/core/store/customer-preferences/customer-preferences.facade';

@Component({
  selector: 'naoo-preferences-container',
  templateUrl: './preferences-container.component.html',
  styleUrls: ['./preferences-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabContent,
    PreferencesContentComponent,
    MatTabLabel,
    MatIcon,
    ErrorMessageComponent,
    AsyncPipe,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class PreferencesContainerComponent implements OnDestroy, OnInit {
  private readonly destroyed$ = new Subject<void>();
  private readonly ietfSpanishISO = 'es-419';

  shouldDisplayNotificationsErrorPage$ =
    this.customerNotificationPreferencesFacade.getShouldDisplayErrorPage();

  shouldDisplayPreferencesErrorPage$ =
    this.customerPreferencesFacade.getCustomerPreferencesHasError();

  emailNotifications$ = this.customerNotificationPreferencesFacade
    .getEmailNotifications()
    .pipe(
      distinctUntilChanged(
        (x: Notifications, y: Notifications) =>
          JSON.stringify(x) === JSON.stringify(y),
      ),
    );

  smsNotifications$ = this.customerNotificationPreferencesFacade
    .getSmsNotifications()
    .pipe(
      distinctUntilChanged(
        (x: Notifications, y: Notifications) =>
          JSON.stringify(x) === JSON.stringify(y),
      ),
    );

  language$ = this.localizationService.language();

  isRetalix: boolean;
  locale: Locale;
  selectedIndex = 0;
  tabs: string[];
  unsavedChanges = false;

  constructor(
    private readonly sessionFacade: SessionFacade,
    private readonly customerNotificationPreferencesFacade: CustomerNotificationPreferencesFacade,
    private readonly customerPreferencesFacade: CustomerPreferencesFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly localizationService: LocalizationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dialogService: DefaultDialogService,
    private readonly _window: Window,
  ) {}

  ngOnInit(): void {
    this.localizationService
      .locale()
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((locale) => {
        this.locale = locale;
        this.changeDetectorRef.markForCheck();
      });

    this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentSystem) => {
        this.isRetalix = currentSystem === CurrentSystem.Retalix;
        this.tabs = this.isRetalix
          ? ['email', 'privacy']
          : ['email', 'sms', 'privacy'];

        const urlParts = this.router.url.split('/');
        this.selectedIndex = this.tabs.indexOf(urlParts[urlParts.length - 1]);
        if (this.selectedIndex < 0) {
          this.selectedIndex = 0;
        }
        if (this.isPrivacyTab()) {
          this.redirectToPrivacyPage();
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get NotificationMethodCode(): typeof NotificationMethodCode {
    return NotificationMethodCode;
  }

  handleActiveTabChanged(tabIndex: number): void {
    const index = tabIndex >= 0 && tabIndex < this.tabs.length ? tabIndex : 0;
    this.selectedIndex = index;
    this.router.navigate([this.tabs[index]], {
      relativeTo: this.activatedRoute,
      replaceUrl: false,
    });
    if (this.isPrivacyTab()) {
      this.redirectToPrivacyPage();
    }
  }

  processAdd(methodCode: NotificationMethodCode): void {
    if (NotificationMethodCode.Email === methodCode) {
      this.customerNotificationPreferencesFacade.addEmailNotification();
      return;
    }
    this.customerNotificationPreferencesFacade.addSmsNotification();
  }

  processEdit(notification: CustomerNotification): void {
    if (NotificationMethodCode.Email === notification.methodCode) {
      this.customerNotificationPreferencesFacade.editEmailNotification(
        notification.ordinal,
      );
      return;
    }
    this.customerNotificationPreferencesFacade.editSmsNotification(
      notification.ordinal,
    );
  }

  processDelete(notification: CustomerNotification): void {
    if (NotificationMethodCode.Email === notification.methodCode) {
      this.customerNotificationPreferencesFacade.deleteEmailNotification(
        notification.ordinal,
      );
      return;
    }
    this.customerNotificationPreferencesFacade.deleteSmsNotification(
      notification.ordinal,
    );
  }

  processSave(notification: CustomerNotification): void {
    if (NotificationMethodCode.Email === notification.methodCode) {
      this.customerNotificationPreferencesFacade.saveEmailNotification(
        notification,
      );
      return;
    }
    this.customerNotificationPreferencesFacade.saveSmsNotification(
      notification,
    );
  }

  populateUnsavedChanges(value: boolean): void {
    this.unsavedChanges = value;
  }

  processCancel(methodCode: NotificationMethodCode): void {
    if (NotificationMethodCode.Email === methodCode) {
      this.customerNotificationPreferencesFacade.cancelEditEmailNotification();
      return;
    }
    this.customerNotificationPreferencesFacade.cancelEditSmsNotification();
  }

  canDeactivate(): Observable<boolean> {
    if (this.unsavedChanges) {
      return new Observable<boolean>((observer: any) => {
        this.dialogService
          .twoButtonModal(
            'preferences-unsaved-dialog',
            'PREFERENCES.UNSAVED_CHANGE_MODAL.TITLE',
            'PREFERENCES.UNSAVED_CHANGE_MODAL.CONFIRM',
            'PREFERENCES.UNSAVED_CHANGE_MODAL.CANCEL',
            () => {
              this.unsavedChanges = false;
            },
            () => {},
            true,
            true,
            undefined,
            undefined,
            true,
          )
          .afterClosed()
          .subscribe(() => {
            observer.next(!this.unsavedChanges);
            observer.complete();
          });
      });
    }
    return of(true);
  }

  private isPrivacyTab(): boolean {
    return this.selectedIndex === this.tabs.indexOf('privacy');
  }

  private redirectToPrivacyPage(): void {
    const { protocol, hostname, port } = this._window.location;

    let ketchLanguageTag: string;
    if (Locale.es_US === this.locale) {
      ketchLanguageTag = this.ietfSpanishISO;
    } else {
      ketchLanguageTag = getLanguageTag(this.locale);
    }

    this._window.location.href = port
      ? `${protocol}//${hostname}:${port}${NaooConstants.PRIVACY_URL}?ketch_lang=${ketchLanguageTag}`
      : `${protocol}//${hostname}${NaooConstants.PRIVACY_URL}?ketch_lang=${ketchLanguageTag}`;
  }
}
