@if (isAccordionView) {
  <mat-accordion>
    @for (section of viewModel.materialAdditionalInfoList; track section) {
      <div class="product-info-section">
        @if (
          ProductDetailsUtilities.isBasic(section) &&
          ProductDetailsUtilities.hasDataForSection(viewModel, section)
        ) {
          <div>
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{
                    ProductDetailsUtilities.formatSectionText(section)
                      | translate
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span class="product-details-section-info">{{
                viewModel?.materialAdditionalInfo[section]
                  | stringdefaulter: true
              }}</span>
            </mat-expansion-panel>
          </div>
        }
        @if (
          section === MaterialDetailsSection.MaterialDimensions &&
          ProductDetailsUtilities.hasMaterialDimensions(viewModel)
        ) {
          <div class="dimensions-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.TITLE' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span>
                <div>
                  @if (!!viewModel.materialInfo?.baseUomWeight) {
                    <div class="attribute-dictionary">
                      <div class="dictionary-key packaging-field-header">
                        {{
                          'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.WEIGHT'
                            | translate
                        }}
                      </div>
                      <div
                        class="dictionary-value"
                        [innerHTML]="
                          viewModel.materialInfo.baseUomWeight
                            | naooweight: '<br>'
                        "
                      ></div>
                    </div>
                  }
                  @if (
                    !!viewModel.displayableDimensionsIn &&
                    !!viewModel.displayableDimensionsCm
                  ) {
                    <div class="attribute-dictionary">
                      <div class="dictionary-key packaging-field-header">
                        {{
                          'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS'
                            | translate
                        }}
                      </div>
                      <div class="dictionary-value">
                        <span>{{
                          'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_IN'
                            | translate
                              : { dimension: viewModel.displayableDimensionsIn }
                        }}</span>
                        <span class="dimensions-separator">
                          <br />
                        </span>
                        <span>{{
                          'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_CM'
                            | translate
                              : { dimension: viewModel.displayableDimensionsCm }
                        }}</span>
                      </div>
                    </div>
                  }
                </div>
                @if (packaging) {
                  <div class="packaging-storage">
                    <div class="packaging-field-header">
                      {{
                        'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.PACKAGING'
                          | translate
                      }}
                    </div>
                    <span>{{ packaging }}</span>
                  </div>
                }
              </span>
            </mat-expansion-panel>
          </div>
        }

        @if (
          section === MaterialDetailsSection.VendorInfo &&
          ProductDetailsUtilities.hasVendorInfo(viewModel)
        ) {
          <div class="vendor-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_DETAILS.VENDOR.TITLE' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span>
                @if (
                  !!viewModel.materialAdditionalInfo?.vendorInfo?.displayName
                ) {
                  <div class="attribute-dictionary">
                    <span class="dictionary-key"
                      >{{
                        'PRODUCT_DETAILS.VENDOR.NAME' | translate
                      }}:&nbsp;</span
                    >
                    <span class="dictionary-value">{{
                      viewModel.materialAdditionalInfo.vendorInfo.displayName
                    }}</span>
                  </div>
                }
                @if (
                  !!viewModel.materialAdditionalInfo?.vendorInfo
                    ?.vendorMaterialNumber
                ) {
                  <div class="attribute-dictionary">
                    <span class="dictionary-key">
                      {{
                        'PRODUCT_DETAILS.VENDOR.ITEM_NUMBER' | translate
                      }}:&nbsp;
                    </span>
                    <span class="dictionary-value">
                      {{
                        viewModel.materialAdditionalInfo.vendorInfo
                          .vendorMaterialNumber
                      }}
                    </span>
                  </div>
                }
              </span>
            </mat-expansion-panel>
          </div>
        }

        @if (
          section === MaterialDetailsSection.Allergens &&
          !viewModel.isGfsUsCustomerBrand &&
          ProductDetailsUtilities.hasAllergenInfo(viewModel)
        ) {
          <div class="allergen-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_DETAILS.ALLERGENS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="allergen-tables-container">
                <naoo-allergen-details
                  class="allergen-details"
                  [allergenInfo]="viewModel.materialAdditionalInfo.allergenInfo"
                ></naoo-allergen-details>
              </div>
            </mat-expansion-panel>
          </div>
        }

        @if (
          section === MaterialDetailsSection.NutritionFacts &&
          ProductDetailsUtilities.hasNutritionInformation(viewModel)
        ) {
          <div class="nutrition-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_DETAILS.NUTRITION_FACTS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              @if (viewModel.isGfsUsCustomerBrand) {
                <span>
                  <naoo-nutrition-facts-container
                    [materialNumber]="viewModel.materialInfo.materialNumber"
                    [nutritionLabel]="viewModel.nutritionLabel"
                  ></naoo-nutrition-facts-container>
                </span>
              } @else {
                <div class="nutrition-tables-container">
                  <naoo-nutrition-details
                    class="nutrition-details"
                    [nutritionDetails]="
                      viewModel.materialAdditionalInfo.nutritionalInfo
                    "
                  ></naoo-nutrition-details>
                </div>
              }
            </mat-expansion-panel>
          </div>
        }

        @if (
          section === MaterialDetailsSection.SchoolEquivalents &&
          ProductDetailsUtilities.hasSchoolEquivalents(viewModel)
        ) {
          <div class="school-equivalents-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_DETAILS.SCHOOL_EQUIVALENTS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span>
                <naoo-nutrition-container
                  [section]="section"
                  [nutritionLabel]="viewModel.nutritionLabel"
                ></naoo-nutrition-container>
              </span>
            </mat-expansion-panel>
          </div>
        }

        @if (
          section === MaterialDetailsSection.VitaminsAndMinerals &&
          ProductDetailsUtilities.hasVitaminsAndMinerals(viewModel)
        ) {
          <div class="vitamins-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_DETAILS.VITAMINS_AND_MINERALS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span>
                <naoo-nutrition-container
                  [section]="section"
                  [nutritionLabel]="viewModel.nutritionLabel"
                ></naoo-nutrition-container>
              </span>
            </mat-expansion-panel>
          </div>
        }

        @if (
          section === MaterialDetailsSection.Ingredients &&
          ProductDetailsUtilities.hasIngredients(viewModel)
        ) {
          <div class="ingredients-section">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'PRODUCT_ATTRIBUTES.INGREDIENTS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span>
                <naoo-ingredient-list
                  [materialNumber]="viewModel.materialInfo.materialNumber"
                  [nutritionLabel]="viewModel.nutritionLabel"
                ></naoo-ingredient-list>
              </span>
            </mat-expansion-panel>
          </div>
        }

        @if (section === MaterialDetailsSection.MaterialMovement) {
          <div class="material-movement">
            <mat-expansion-panel class="product-details-expansion">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{
                    ProductDetailsUtilities.formatSectionText(section) +
                      '.HEADER' | translate
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span>
                <span class="material-movement-subsection">{{
                  ProductDetailsUtilities.formatMaterialMovementSubSectionText(
                    viewModel
                  ) | translate
                }}</span>
                @if (ProductDetailsUtilities.hasMaterialMovement(viewModel)) {
                  <naoo-material-movement
                    [materialMovementDetails]="
                      viewModel.materialMovementDetails
                    "
                    [materialInfo]="viewModel.materialInfo"
                  ></naoo-material-movement>
                } @else if (!!viewModel.materialInfo?.lastOrderDate) {
                  <div>
                    <span class="last-order-date-label">
                      {{
                        'PRODUCT_DETAILS.MATERIAL_MOVEMENT.LAST_ORDER_DATE'
                          | translate
                      }}
                    </span>
                    <span class="last-order-date-value">
                      {{
                        viewModel.materialInfo.lastOrderDate
                          | naooDate: 'shortYearDate' : ''
                      }}
                    </span>
                  </div>
                }
              </span>
            </mat-expansion-panel>
          </div>
        }
      </div>
    }
  </mat-accordion>
} @else {
  <div class="print-only">
    <div class="attributes-section">
      @if (viewModel.materialAttributes?.length) {
        <div>
          <div class="attributes-header-section">
            <div class="section-header attributes-header">
              {{ 'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES' | translate }}
            </div>
          </div>
          <ul class="attribute-item-container">
            @for (attr of viewModel.materialAttributes; track attr.name) {
              <li class="attribute-item">
                {{
                  ProductDetailsUtilities.getLocalized(
                    viewModel?.currentLanguage,
                    attr.name
                  )
                }}
              </li>
            }
          </ul>
        </div>
      }
    </div>
  </div>

  @for (section of viewModel.materialAdditionalInfoList; track section) {
    <div class="product-info-section">
      @if (
        section === MaterialDetailsSection.MaterialDimensions &&
        ProductDetailsUtilities.hasMaterialDimensions(viewModel)
      ) {
        <div class="dimensions-section">
          <div class="dimensions-header-section">
            <div class="section-header dimensions-header">
              {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.TITLE' | translate }}
            </div>
          </div>

          <div>
            @if (!!viewModel.materialInfo?.baseUomWeight) {
              <div class="dimension-dictionary">
                <div class="dictionary-key packaging-field-header">
                  {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.WEIGHT' | translate }}
                </div>
                <div class="dictionary-value">
                  {{ viewModel.materialInfo.baseUomWeight | naooweight }}
                </div>
              </div>
            }
            @if (viewModel.displayableDimensionsIn) {
              <div class="dimension-dictionary">
                <div class="dictionary-key packaging-field-header">
                  {{
                    'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS' | translate
                  }}
                </div>
                <div class="dictionary-value">
                  <span>{{
                    'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_IN'
                      | translate
                        : { dimension: viewModel.displayableDimensionsIn }
                  }}</span>
                  <span class="dimensions-separator"> / </span>
                  <span>{{
                    'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_CM'
                      | translate
                        : { dimension: viewModel.displayableDimensionsCm }
                  }}</span>
                </div>
              </div>
            }
          </div>
          @if (packaging) {
            <div>
              <div class="packaging-field-header">
                {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.PACKAGING' | translate }}
              </div>
              <span class="packaging-storage">{{ packaging }}</span>
            </div>
          }
        </div>
      }

      @if (
        section === MaterialDetailsSection.VendorInfo &&
        ProductDetailsUtilities.hasVendorInfo(viewModel)
      ) {
        <div class="vendor-section">
          <div class="vendor-header-section">
            <div class="section-header vendor-header">
              {{ 'PRODUCT_DETAILS.VENDOR.TITLE' | translate }}
            </div>
          </div>
          <div>
            @if (!!viewModel.materialAdditionalInfo?.vendorInfo?.displayName) {
              <div class="vendor-dictionary">
                <span class="dictionary-key"
                  >{{ 'PRODUCT_DETAILS.VENDOR.NAME' | translate }}:&nbsp;</span
                >
                <span class="dictionary-value">{{
                  viewModel.materialAdditionalInfo.vendorInfo.displayName
                }}</span>
              </div>
            }

            @if (
              !!viewModel.materialAdditionalInfo?.vendorInfo
                ?.vendorMaterialNumber
            ) {
              <div class="vendor-dictionary">
                <span class="dictionary-key"
                  >{{
                    'PRODUCT_DETAILS.VENDOR.ITEM_NUMBER' | translate
                  }}:&nbsp;</span
                >
                <span class="dictionary-value">{{
                  viewModel.materialAdditionalInfo.vendorInfo
                    .vendorMaterialNumber
                }}</span>
              </div>
            }
          </div>
        </div>
      }

      @if (section === MaterialDetailsSection.NutritionFacts) {
        <div class="nutrition-section">
          @if (
            ProductDetailsUtilities.hasAllergenInfo(viewModel) &&
            !viewModel.isGfsUsCustomerBrand
          ) {
            <ng-container class="retalix-nutrition-section">
              <div class="nutrition-tables-container">
                <naoo-allergen-details
                  class="allergen-details"
                  [allergenInfo]="viewModel.materialAdditionalInfo.allergenInfo"
                ></naoo-allergen-details>
              </div>
            </ng-container>
          }
          @if (
            viewModel.isGfsUsCustomerBrand &&
            ProductDetailsUtilities.hasNutritionInformation(viewModel)
          ) {
            <div class="mygfs-nutrition-section">
              <naoo-nutrition-container
                [nutritionLabel]="viewModel.nutritionLabel"
                [section]="section"
              ></naoo-nutrition-container>
            </div>
          }
        </div>
      }

      @if (
        section === MaterialDetailsSection.Ingredients &&
        ProductDetailsUtilities.hasIngredients(viewModel)
      ) {
        <div class="ingredients-section">
          <div class="ingredients-header-section">
            <div class="section-header ingredients-header">
              {{ 'PRODUCT_ATTRIBUTES.INGREDIENTS' | translate }}
            </div>
          </div>

          <naoo-ingredient-list
            [materialNumber]="viewModel.materialInfo.materialNumber"
            [nutritionLabel]="viewModel.nutritionLabel"
          ></naoo-ingredient-list>
        </div>
      }

      @if (section === MaterialDetailsSection.MaterialMovement) {
        <div class="material-movement">
          <div class="section-header">
            {{
              ProductDetailsUtilities.formatSectionText(section) + '.HEADER'
                | translate
            }}
          </div>
          <span class="material-movement-subsection">{{
            ProductDetailsUtilities.formatMaterialMovementSubSectionText(
              viewModel
            ) | translate
          }}</span>
          @if (ProductDetailsUtilities.hasMaterialMovement(viewModel)) {
            <naoo-material-movement
              [materialMovementDetails]="viewModel.materialMovementDetails"
              [materialInfo]="viewModel.materialInfo"
            ></naoo-material-movement>
          } @else if (!!viewModel.materialInfo?.lastOrderDate) {
            <div>
              <span class="last-order-date-label">
                {{
                  'PRODUCT_DETAILS.MATERIAL_MOVEMENT.LAST_ORDER_DATE'
                    | translate
                }}
              </span>
              <span class="last-order-date-value">
                {{
                  viewModel.materialInfo.lastOrderDate
                    | naooDate: 'shortYearDate' : ''
                }}
              </span>
            </div>
          }
        </div>
      }

      @if (section === MaterialDetailsSection.NutritionFactsPrint) {
        <div class="print-only">
          @if (viewModel.isGfsUsCustomerBrand) {
            <naoo-nutrition-facts-container
              [materialNumber]="viewModel.materialInfo.materialNumber"
              [nutritionLabel]="viewModel.nutritionLabel"
            ></naoo-nutrition-facts-container>
          } @else if (
            !viewModel.isGfsUsCustomerBrand &&
            ProductDetailsUtilities.hasNutritionInformation(viewModel)
          ) {
            <div class="nutrition-tables-container">
              <naoo-nutrition-details
                class="nutrition-details"
                [nutritionDetails]="
                  viewModel.materialAdditionalInfo.nutritionalInfo
                "
              ></naoo-nutrition-details>
            </div>
          }
        </div>
      }

      @if (
        ProductDetailsUtilities.isBasic(section) &&
        ProductDetailsUtilities.hasDataForSection(viewModel, section)
      ) {
        <div class="basic-section">
          <div class="section-header">
            {{ ProductDetailsUtilities.formatSectionText(section) | translate }}
          </div>
          <span class="product-details-section-info">
            {{
              viewModel?.materialAdditionalInfo[section] | stringdefaulter: true
            }}</span
          ><br />
        </div>
      }
    </div>
  }
}
