import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-ordering-info-header',
  templateUrl: './material-ordering-info-header.component.html',
  styleUrls: ['./material-ordering-info-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class MaterialOrderingInfoHeaderComponent {}
