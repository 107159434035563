import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { of, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { OrderGuideFacade } from '../../../core/store/order-guide/order-guide.facade';

const unassignedValues = ['unassigned', 'non-assignés'];

@Injectable({
  providedIn: 'root',
})
export class OrderGuideCategoryNameExistsValidator {
  constructor(private orderGuideFacade: OrderGuideFacade) {}

  validate(initialOrderGuideCategoryName: string): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
      const initialOrderGuideCategoryValue =
        initialOrderGuideCategoryName &&
        initialOrderGuideCategoryName.toLowerCase();
      const newOrderGuideCategoryName = ctrl.value.toLowerCase().trim();
      if (initialOrderGuideCategoryValue === newOrderGuideCategoryName) {
        return of(null);
      }
      if (unassignedValues.includes(newOrderGuideCategoryName)) {
        return of({ duplicateCategoryName: ctrl.value });
      }

      return this.orderGuideFacade.getLoadedCategorizedOrderGuide().pipe(
        first(),
        map((orderGuideV1) => {
          const existingNamedCategory = orderGuideV1.categorizedMaterials.find(
            (category) =>
              !!category.categoryName &&
              category.categoryName.en &&
              category.categoryName.en.toLowerCase() ===
                newOrderGuideCategoryName,
          );

          return existingNamedCategory
            ? { duplicateCategoryName: ctrl.value }
            : null;
        }),
        catchError(() => of(null)),
      );
    };
  }
}
