import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CostSummary,
  CostSummaryContext,
  OrderSummary,
  SubmitOrderButtonState,
} from '../shared/cost-summary';
import { NaooConstants } from '../../shared/NaooConstants';
import { SplitOrderType } from '../../core/services/cart-order/models/cart-order';
import { Router } from '@angular/router';
import { CreditWidgetLoadingState } from '../../core/store/cart-review/cart-review.state';
import { CostSummaryContentFloatingFooterComponent } from './cost-summary-content-floating-footer/cost-summary-content-floating-footer.component';
import { GoPointsLogoComponent } from '../../shared/go-points-logo/go-points-logo.component';
import { CartCouponContainerComponent } from '../../cart/cart-coupon/cart-coupon-container.component';
import { OrderSubmitButtonContainerComponent } from '../order-submit-button/order-submit-button-container/order-submit-button-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { NaooCurrencyPipe } from '../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-cost-summary-content',
  templateUrl: './cost-summary-content.component.html',
  styleUrls: ['./cost-summary-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CostSummaryContentFloatingFooterComponent,
    GoPointsLogoComponent,
    CartCouponContainerComponent,
    OrderSubmitButtonContainerComponent,
    TranslateModule,
    NaooDatePipe,
    NaooCurrencyPipe,
  ],
})
export class CostSummaryContentComponent {
  @Input() isQuickAddEnabled: boolean;
  @Input() shouldShowFloatingFooter: boolean;
  @Input() costSummary: CostSummary;
  @Input() isMobile: boolean;
  @Input() paymentReview: boolean;
  @Input() isPoNumberInvalid: boolean;
  @Input() hasOnlySuccessSection: boolean;
  @Output() submitButtonClick = new EventEmitter<void>();
  @Output() backLinkClicked = new EventEmitter<void>();

  @ViewChild('costSummaryWrapper', { static: true })
  costSummaryRef: ElementRef;

  readonly catalogPath = NaooConstants.CATEGORIES_PATH;
  readonly cartPath = NaooConstants.CART_PATH;

  get quantities(): OrderSummary[] {
    const ispuOrderSummaries: OrderSummary[] =
      this.orderSummaryQuantities.filter(
        (order: OrderSummary) => order.orderType === SplitOrderType.PICKUP,
      );
    return ispuOrderSummaries.length
      ? ispuOrderSummaries
      : this.orderSummaryQuantities;
  }

  get isCartSummaryContext(): boolean {
    return this.costSummary?.context === CostSummaryContext.CartSummary;
  }

  get isCartReviewContext(): boolean {
    return this.costSummary?.context === CostSummaryContext.CartReview;
  }

  get isAddPaymentContext(): boolean {
    return this.costSummary?.context == CostSummaryContext.AddPayment;
  }

  get standardOrderTotals(): OrderSummary {
    return this.costSummary?.quantities?.find(
      (summary) => summary.orderType == null,
    );
  }

  get isCreditPaymentLoading(): boolean {
    return (
      this.costSummary?.payWithCreditSelected &&
      CreditWidgetLoadingState.LOADING ===
        this.costSummary?.creditWidgetLoadingState
    );
  }

  get buttonState(): SubmitOrderButtonState {
    return this.costSummary.submitButtonState;
  }

  constructor(private router: Router) {}

  private get orderSummaryQuantities() {
    return this.costSummary.quantities;
  }

  backLinkAction() {
    if (!this.isAddPaymentContext) {
      const url = this.isCartSummaryContext ? this.catalogPath : this.cartPath;
      this.router.navigate([url]);
    } else {
      this.backLinkClicked.emit();
    }
  }

  get backLinkText(): string {
    if (this.isAddPaymentContext) {
      return 'CART_REVIEW.ADD_PAYMENT.BACK_TO_ORDER_REVIEW';
    }
    return this.isCartSummaryContext
      ? 'CART.CONTINUE_SHOPPING'
      : 'CART_REVIEW.BACK_TO_SHOPPING_CART';
  }

  trackByOrderSummary(orderSummary: OrderSummary) {
    return (
      orderSummary.title +
      orderSummary.placeholder +
      orderSummary.lines +
      orderSummary.totalQuantity +
      orderSummary.orderType
    );
  }
}
