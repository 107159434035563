import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  MaterialImageSources,
  MaterialImageSourcesService,
} from '../services/images/material-image-sources.service';
import { LocalizedUtilities } from '../utilities/localized-utilities';
import { LocalizationService } from '../services/translation/localization.service';

@Pipe({
  name: 'naooimage',
  pure: false,
  standalone: true,
})
export class NaooImagePipe implements PipeTransform, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private materialImageService: MaterialImageSourcesService,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    materialNumber: string,
    dimension: number,
  ): MaterialImageSources | undefined {
    const image = this.materialImageService.getImageSources(
      materialNumber,
      dimension,
    );
    return LocalizedUtilities.getLocalizedValue(
      image,
      this.localizationService.currentLanguage,
    );
  }
}
