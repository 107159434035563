<div class="modal-container">
  <div class="create-modal-container">
    <div class="modal-title">
      {{ 'LISTS.CREATE_CATEGORY.TITLE' | translate }}
    </div>
    <mat-form-field>
      <mat-label>{{
        'LISTS.CREATE_CATEGORY.HELPER_TEXT' | translate
      }}</mat-label>
      <input
        class="custom-category-name"
        matInput
        maxlength="{{ customCategoryMaxLength }}"
        [formControl]="customCategoryNameFormControl"
        [errorStateMatcher]="matcher"
        autocomplete="off"
        (keyup.enter)="createCategory()"
        cdkFocusInitial
      />
      <mat-hint align="end"
        >{{ customCategoryNameFormControl.value.length }} /
        {{ customCategoryMaxLength }}</mat-hint
      >
      @if (customCategoryNameFormControl.hasError('duplicateCategoryName')) {
        <mat-error>
          {{ 'LISTS.CREATE_CATEGORY.DUPLICATE_ERROR' | translate }}
        </mat-error>
      }
      @if (customCategoryNameFormControl.errors?.pattern) {
        <mat-error>
          {{ 'INVALID_CHARACTERS' | translate }}
        </mat-error>
      }
    </mat-form-field>
    <div class="modal-button-bar">
      <button
        attr.aria-label="{{ 'CANCEL.TEXT' | translate }}"
        class="secondary-button large-button"
        (click)="closeModal()"
      >
        {{ 'CANCEL.TEXT' | translate }}
      </button>
      <button
        attr.aria-label="{{
          !isFormValid()
            ? ('LISTS.CREATE_CATEGORY.CREATE_BUTTON_DISABLED' | translate)
            : ('LISTS.CREATE_CATEGORY.CREATE_BUTTON' | translate)
        }}"
        class="primary-button large-button"
        (click)="createCategory()"
        [disabled]="!isFormValid()"
      >
        {{ 'LISTS.CREATE_CATEGORY.CREATE' | translate }}
      </button>
    </div>
  </div>
  <button
    attr.aria-label="{{ 'CLOSE' | translate }}"
    class="close-button"
    (click)="closeModal()"
  >
    <img
      class="close-button-img"
      src="assets/images/close-icon.svg"
      alt="{{ 'CLOSE' | translate }}"
    />
  </button>
</div>
