<naoo-generic-navigation-menu
  parentMenuName="{{
    isString(parentMenuName)
      ? (parentMenuName | translate)
      : (parentMenuName | stringdefaulter)
  }}"
  [menuNavigation]="navigation"
  (closeModalEvent)="closeModal()"
  (backNavigationEvent)="closeModal()"
></naoo-generic-navigation-menu>
