import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GordonNowChatService } from '../gordon-now/gordon-now-chat.service';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { combineLatest, map, Observable, of } from 'rxjs';
import { FulfillmentType } from 'src/app/core/services/cart/models/cart-record';
import { AsyncPipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'naoo-no-results-expo',
  templateUrl: './no-results-expo.component.html',
  styleUrls: ['./no-results-expo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, MatIcon, NgClass, TranslateModule],
})
export class NoResultsExpoComponent {
  @Input() isZeroWithOG: boolean;
  @Input() searchText: string;

  private readonly actionType = 'SEARCH_MATERIALS';

  constructor(
    private readonly gnChatService: GordonNowChatService,
    private readonly cartFacade: CartFacade,
  ) {}

  handleExpoClick(): void {
    this.gnChatService.executeAction(this.actionType, this.searchText);
  }

  showExpo(): Observable<boolean> {
    const expoAvailable$ = of(this.gnChatService.isChatbotAvailable());
    const truckSelected$ = this.cartFacade.getSelectedFulfillmentType();

    return combineLatest([expoAvailable$, truckSelected$]).pipe(
      map(([isExpoAvailable, isTruckSelected]) => {
        return isExpoAvailable && isTruckSelected === FulfillmentType.TRUCK;
      }),
    );
  }
}
