import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GroupByType, GuideSideBar, SortByType } from '../guides';
import { GuidesGroupByComponent } from './guides-group-by/guides-group-by.component';
import { GuidesSortByComponent } from './guides-sort-by/guides-sort-by.component';
import { GuidesCategoryLinkComponent } from './guides-category-link/guides-category-link.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-side-bar',
  templateUrl: './guides-side-bar.component.html',
  styleUrls: ['./guides-side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuidesGroupByComponent,
    GuidesSortByComponent,
    GuidesCategoryLinkComponent,
    TranslateModule,
  ],
})
export class GuidesSideBarComponent {
  @Input() sideBar: GuideSideBar;
  @Input() isOffline: boolean;
  @Input() sortByDisabled: boolean;

  @Output() scrollToCategoryClick = new EventEmitter<number>();
  @Output() groupByChange = new EventEmitter<GroupByType>();
  @Output() sortByChange = new EventEmitter<SortByType>();

  get shouldRenderGroupBy(): boolean {
    return !!this.sideBar.groupBy && !!this.sideBar.groupByOptions;
  }

  handleScrollToCategory(vsIndex: number) {
    this.scrollToCategoryClick.emit(vsIndex);
  }

  handleGroupByChanged(groupBy: GroupByType) {
    this.groupByChange.emit(groupBy);
  }

  handleSortByChanged(sortBy: SortByType) {
    this.sortByChange.emit(sortBy);
  }
}
