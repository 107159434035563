<div class="label-container">
  <div class="header">Nutrition Facts</div>
  @if (servingInfo) {
    <div class="divider-line small"></div>
    <div class="nutrition-line">
      <span class="line-label">Serving Size</span>
      <span class="line-value">{{ servingInfo }}</span>
    </div>
  }
  <div class="divider-line large"></div>
  <div class="line-label small">Amount Per Serving</div>
  <div class="nutrition-line">
    <span class="line-label large">Calories</span>
    <span class="line-value large">{{
      roundedAmount(nutritionInfo.calorieInformation)
    }}</span>
  </div>
  <div class="divider-line"></div>
  <div class="daily-value">% Daily Value*</div>
  @for (nutrient of nutritionInfo.nutrients; track nutrient) {
    <div class="nutrient">
      <div
        class="divider-line small"
        [ngClass]="{ indented: isAddedSugars(nutrient.label) }"
      ></div>
      <div class="nutrition-line" [ngClass]="'level' + nutrient.level">
        <span>
          <span class="line-label" [ngClass]="'level' + nutrient.level">
            {{ getNutrientLabelText(nutrient.label) }}
          </span>
          {{ parsedAmount(roundedAmount(nutrient), nutrient.uom) }}
          @if (isAddedSugars(nutrient.label)) {
            <span>{{ nutrient.label }}</span>
          }
        </span>
        @if (nutrient.shouldReferenceDailyIntake) {
          <span class="line-value">
            {{ percentageAmount(nutrient.referenceDailyIntakeAmount) }}
          </span>
        }
      </div>
    </div>
  }
  <div class="divider-line large"></div>
  @for (
    focusedNutrient of nutritionInfo.focusedNutrients;
    track focusedNutrient;
    let isFirst = $first
  ) {
    <div class="focused-nutrient">
      @if (!isFirst) {
        <div class="divider-line small"></div>
      }
      <div class="nutrition-line">
        <span>
          <span class="line-label">{{ focusedNutrient.label }}</span>
          {{ parsedAmount(focusedNutrient.amount, focusedNutrient.uom) }}
        </span>
        @if (focusedNutrient.shouldReferenceDailyIntake) {
          <span class="line-value">
            {{ percentageAmount(focusedNutrient.referenceDailyIntakeAmount) }}
          </span>
        }
      </div>
    </div>
  }
  <div class="divider-line"></div>
  <div class="footer">
    * The % Daily Value (DV) tells you how much a nutrient in a serving of food
    contributes to a daily diet. 2,000 calories a day is used for general
    nutrition advice.
  </div>
</div>
