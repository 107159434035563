import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderItem } from '../shared/models/order-confirmation';
import { NgClass } from '@angular/common';
import { ConfirmationMaterialDetailsComponent } from '../shared/confirmation-material-details-component/confirmation-material-details.component';
import { NaooPricePipe } from '../../shared/pipes/naoo-price.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-order-item-row',
  templateUrl: './order-item-row.component.html',
  styleUrls: ['./order-item-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ConfirmationMaterialDetailsComponent,
    NaooPricePipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class OrderItemRowComponent {
  @Input() orderItem: OrderItem;
  @Input() isLastRow: boolean;
  @Input() isProcessing = false;
  @Input() hideBottomSpace = false;
}
