import { Injectable } from '@angular/core';
import { ExportMaterialsPrintService } from './export-materials-print.service';
import { ExportMaterialsFileService } from './export-materials-file.service';
import { CustomDialogService } from '../dialog/custom-dialog/custom-dialog.service';
import { ExportMaterialsFacade } from '../../../core/store/export-materials/export-materials.facade';
import { ExportFeatureType } from '../../models/export/export-properties';
import { ExportModalResponse } from '../../modals/export-modal/export-modal.component';
import {
  ExportModalDataPoint,
  getFileFormats,
  getModalDataPoints,
} from '../../models/export-modal-data-point';
import { GroupByType } from '../../../guides/shared/guides';
import { finalize, first, switchMap } from 'rxjs/operators';
import {
  additionalInfoValidation,
  customGuideValidation,
  LoadingValidation,
  priceValidation,
} from '../../../core/store/export-materials/models/export-materials-validation';
import { MaterialAdditionalInfoFacade } from '../../../core/store/material-additional-info/material-additional-info.facade';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { Observable, of } from 'rxjs';
import {
  ExportMaterialsData,
  ExportMaterialsInput,
} from './models/export-materials';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { LocalizationService } from '../translation/localization.service';
import { MaterialPriceFacade } from '../../../core/store/material-price/material-price.facade';

@Injectable({
  providedIn: 'root',
})
export class ExportMaterialsService {
  private readonly toastMessageTranslationKey = 'EXPORT.MESSAGE';
  private readonly toastActionText = '\u2573';

  constructor(
    private exportMaterialsFacade: ExportMaterialsFacade,
    private exportMaterialsPrintService: ExportMaterialsPrintService,
    private exportMaterialsFileService: ExportMaterialsFileService,
    private toastMessageService: ToastMessageService,
    private customDialogService: CustomDialogService,
    private localizationService: LocalizationService,
    private priceFacade: MaterialPriceFacade,
    private additionalInfoFacade: MaterialAdditionalInfoFacade,
    private customGuideFacade: CustomGuideFacade,
  ) {}

  public exportMaterials(
    exportMaterialsInput: ExportMaterialsInput,
  ): Observable<void> {
    const isPrint = [
      ExportFeatureType.ORDER_GUIDE_PRINT,
      ExportFeatureType.CUSTOM_GUIDE_PRINT,
      ExportFeatureType.CRITICAL_ITEM_GUIDE_PRINT,
      ExportFeatureType.MARKETING_GUIDE_PRINT,
    ].includes(exportMaterialsInput.featureType);

    return this.customDialogService
      .exportModal(
        this.createModalId(exportMaterialsInput.featureType),
        exportMaterialsInput.titleTranslationKey,
        getFileFormats(isPrint),
        exportMaterialsInput.analyticsCategory,
        getModalDataPoints(
          exportMaterialsInput.featureType,
          isPrint,
          exportMaterialsInput.hasCustomerMaterial,
        ),
        exportMaterialsInput.featureType,
        isPrint,
      )
      .pipe(
        switchMap((dialog) => dialog.afterClosed()),
        first(),
        switchMap((modalResponse: ExportModalResponse) =>
          modalResponse.closedByCancel
            ? of<void>()
            : this.transformAndExport(
                exportMaterialsInput,
                modalResponse,
                isPrint,
              ),
        ),
      );
  }

  private transformAndExport(
    exportMaterialsInput: ExportMaterialsInput,
    modalResponse: ExportModalResponse,
    isPrint: boolean,
  ): Observable<void> {
    this.toastMessageService.showActionableToastMessage(
      this.localizationService.instant(this.toastMessageTranslationKey),
      this.toastActionText,
    );

    this.loadDataForDataPoints(
      modalResponse.dataPoints,
      exportMaterialsInput.materialNumbers,
      exportMaterialsInput.customGuideId,
    );

    const headerDataPoints = modalResponse.dataPoints.map(
      (modalDataPoint) => modalDataPoint.name,
    );

    return this.exportMaterialsFacade
      .getLoadedExportMaterialsData({
        fileName: exportMaterialsInput.fileName,
        fileType: modalResponse.fileFormat,
        materialNumbers: exportMaterialsInput.materialNumbers,
        dataPoints: headerDataPoints,
        customGuideId: exportMaterialsInput.customGuideId,
        categoryGroupings: exportMaterialsInput.categoryGroupings,
      })
      .pipe(
        first(),
        switchMap((exportMaterialsData: ExportMaterialsData) => {
          if (isPrint) {
            return this.exportMaterialsPrintService.printFile(
              exportMaterialsData,
              headerDataPoints,
            );
          } else {
            return this.exportMaterialsFileService.exportFile(
              exportMaterialsData,
              headerDataPoints,
            );
          }
        }),
        finalize(() => this.toastMessageService.hideToastMessage()),
      );
  }

  private loadDataForDataPoints(
    dataPoints: ExportModalDataPoint[],
    materialNumbers: string[],
    customGuideId?: string,
  ) {
    if (this.shouldLoadData(dataPoints, priceValidation)) {
      this.priceFacade.loadMaterialPrices(materialNumbers);
    }

    if (this.shouldLoadData(dataPoints, additionalInfoValidation)) {
      this.additionalInfoFacade.loadMaterialAdditionalInfos(materialNumbers);
    }

    if (
      !!customGuideId &&
      this.shouldLoadData(dataPoints, customGuideValidation)
    ) {
      this.customGuideFacade.loadCategorizedCustomGuide(
        customGuideId,
        GroupByType.Storage,
      );
      this.customGuideFacade.loadCategorizedCustomGuide(
        customGuideId,
        GroupByType.Taxonomy,
      );
    }
  }

  private shouldLoadData(
    dataPoints: ExportModalDataPoint[],
    validation: LoadingValidation<unknown>,
  ): boolean {
    return dataPoints.some((selectedDataPoint) =>
      validation.requiredDataPoints.includes(selectedDataPoint.name),
    );
  }

  private createModalId(exportMaterialsInput: ExportFeatureType): string {
    return exportMaterialsInput.toLowerCase().replace(/_/g, '-');
  }
}
