<div class="modal-container">
  <div class="create-modal-container">
    <div class="modal-title">{{ 'LISTS.CREATE_MODAL.TITLE' | translate }}</div>
    <mat-form-field>
      <mat-label>{{ 'LISTS.CREATE_MODAL.HELPER_TEXT' | translate }}</mat-label>
      <input
        class="custom-guide-name"
        matInput
        maxlength="{{ customGuideNameMaxLength }}"
        [formControl]="customGuideNameFormControl"
        [errorStateMatcher]="matcher"
        autocomplete="off"
        (keyup.enter)="createCustomGuide()"
        cdkFocusInitial
      />
      <mat-hint align="end"
        >{{ customGuideNameFormControl.value.length }} /
        {{ customGuideNameMaxLength }}</mat-hint
      >
      @if (
        customGuideNameFormControl.hasError('alreadyExistingCustomGuideName')
      ) {
        <mat-error>
          {{ 'LISTS.CREATE_MODAL.DUPLICATE_ERROR' | translate }}
        </mat-error>
      }
      @if (customGuideNameFormControl.errors?.pattern) {
        <mat-error>
          {{ 'INVALID_CHARACTERS' | translate }}
        </mat-error>
      }
    </mat-form-field>
    <label id="group-by" class="group-by-label">{{
      'LISTS.CREATE_MODAL.GROUP_BY_LABEL' | translate
    }}</label>
    <mat-radio-group
      aria-labelledby="group-by"
      [formControl]="groupByFormControl"
    >
      @for (grouping of groupByOptions; track grouping.display) {
        <mat-radio-button [value]="grouping.name">
          {{ grouping.display | translate }}
        </mat-radio-button>
      }
    </mat-radio-group>
    <div class="modal-button-bar">
      <button class="secondary-button large-button" (click)="closeModal()">
        {{ 'CANCEL.TEXT' | translate }}
      </button>
      <button
        class="primary-button large-button"
        (click)="createCustomGuide()"
        [disabled]="!isFormValid()"
      >
        {{
          (shouldDuplicateCustomGuide
            ? 'LISTS.DUPLICATE_CUSTOM_GUIDE_MODAL.DUPLICATE'
            : 'LISTS.CREATE_MODAL.CREATE'
          ) | translate
        }}
      </button>
    </div>
  </div>
  <button
    class="close-button"
    attr.aria-label="{{ 'CLOSE' | translate }}"
    (click)="closeModal()"
  >
    <img
      class="close-button-img"
      src="assets/images/close-icon.svg"
      alt="{{ 'CLOSE' | translate }}"
    />
  </button>
</div>
