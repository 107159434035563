import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { Router } from '@angular/router';
import { NaooConstants } from '../../../shared/NaooConstants';
import { ActiveCartSummaryFacade } from '../../../core/store/active-cart/active-cart-summary.facade';
import { BulkAddWorkflow } from '../../../shared/bulk-add-modal/bulk-add-modal.component';
import { NgClass } from '@angular/common';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-order-tools',
  templateUrl: './order-tools.component.html',
  styleUrls: ['./order-tools.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderToolsComponent {
  @Input() isDisabled: boolean;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private activeCartSummaryFacade: ActiveCartSummaryFacade,
    private customDialogService: CustomDialogService,
    private analytics: NaooAnalyticsManager,
    private router: Router,
  ) {}

  openImportOrderModal() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'import order',
      label: 'import order button',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
    this.customDialogService.bulkAddImportChecker(BulkAddWorkflow.IMPORT);
  }

  navigateToQuickAdd() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'quick add',
      label: 'header link',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);

    this.activeCartSummaryFacade.enableQuickAdd();

    this.router.navigateByUrl(NaooConstants.CART_PATH);
  }
}
