import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NaooConstants } from '../NaooConstants';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MatFormField,
  MatPrefix,
  MatLabel,
  MatSuffix,
  MatHint,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatIconButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormField,
    MatIcon,
    MatPrefix,
    MatLabel,
    MatInput,
    MatIconButton,
    MatSuffix,
    MatHint,
    TranslateModule,
  ],
})
export class SearchFieldComponent {
  MAX_INPUT_LENGTH = NaooConstants.MAX_INPUT_LENGTH;
  @Input() value: string;

  @Input() searchPlaceholderText: string;
  @Input() searchHintText: string;

  @Output()
  searchTextEmitter = new EventEmitter<string>();

  @Output()
  blurEmitter = new EventEmitter<string>();

  search() {
    this.searchTextEmitter.emit(this.value);
  }

  blur() {
    this.blurEmitter.emit(this.value);
  }

  clear() {
    this.value = '';
    this.search();
  }
}
