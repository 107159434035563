import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-category-jump-buttons',
  templateUrl: './material-category-jump-buttons.component.html',
  styleUrls: ['./material-category-jump-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class MaterialCategoryJumpButtonsComponent {
  @Input() nextCategoryIndex: number;
  @Input() prevCategoryIndex: number;
  @Input() categoryCount: number;

  @Output() categoryJump: EventEmitter<number> = new EventEmitter<number>();

  jumpToCategory(index: number) {
    this.categoryJump.emit(index);
  }
}
