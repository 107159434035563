import {
  CostSummary,
  CostSummaryContext,
  CostSummaryCustomerInfo,
  CostSummaryShippingInfo,
  OrderSummary,
  SubmitOrderButtonState,
} from './cost-summary';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from 'src/app/core/store/material-info/material-info.state';
import {
  CustomerBrand,
  CustomerPermission,
  SessionActiveCustomer,
  SessionRecord,
} from '../../core/services/session/models/session-record';
import { DeliveryScheduleEntryRecord } from '../../core/services/delivery-schedule/models/delivery-schedules-record';
import {
  MaterialAvailabilityRecordState,
  MaterialAvailabilityRecordStatus,
} from '../../core/store/material-availability/material-availability.state';
import { StockType } from '../../core/services/material-availability/model/material-availabilities-record';
import { CartContext } from './cost-summary.selectors';
import { CartCounts } from '../../core/store/cart/cart.selectors';
import {
  SplitOrder,
  SplitOrderType,
} from '../../core/services/cart-order/models/cart-order';
import { CartPriceTotals } from '../../core/store/material-price/material-price.selectors';
import { StoreCountsContext } from '../../core/store/cart-review/cart-review.selectors';
import { NaooConstants } from '../../shared/NaooConstants';
import { CartReviewSectionName } from '../../core/store/cart-review/cart-review.state';

const storeCustomerPermissions = new Set([
  CustomerPermission.ExpressDelivery,
  CustomerPermission.InStorePickup,
]);

export class CostSummaryTransformer {
  public static transformCostSummary(
    context: CostSummaryContext,
    session: SessionRecord,
    deliverySchedule: DeliveryScheduleEntryRecord,
    cartContext: CartContext,
    isOffline: boolean,
    splitOrders: SplitOrder[],
    dropShipTotals: CartCounts[],
    storeCountsContext: StoreCountsContext,
  ): CostSummary {
    const storeFulfillment = cartContext?.storeFulfillment;
    const activeCustomer = session.activeCustomer;
    const displayCouponContainer =
      CostSummaryContext.CartReview === context &&
      !storeFulfillment &&
      activeCustomer.showDigitalCoupon;
    return {
      context,
      isPunchOut: session.isPunchOut,
      customerInfo: this.buildCustomerInfo(
        activeCustomer.name,
        activeCustomer.customerDisplayId,
        activeCustomer.brand,
      ),
      shippingInfo: this.buildShippingInfo(
        cartContext.poNumber,
        activeCustomer.permissions.includes(CustomerPermission.PoRequired),
        deliverySchedule,
      ),
      quantities: this.buildQuantities(
        cartContext.cartPriceTotals,
        splitOrders,
        dropShipTotals,
        storeCountsContext,
      ),
      submitButtonState: this.getButtonState(
        context,
        activeCustomer,
        cartContext.availabilities,
        cartContext.cartPriceTotals.cartCounts.totalQuantity,
        cartContext.isSelectedFulfillmentValidForSubmission,
        session.isPunchOut,
        storeCountsContext?.dateHasBeenResolved,
      ),
      hasStoresPermissions: activeCustomer?.permissions?.some((permission) =>
        storeCustomerPermissions.has(permission),
      ),
      warning: this.getWarning(
        context,
        cartContext.isSelectedFulfillmentLoaded,
        cartContext.isSelectedFulfillmentValidForSubmission,
        cartContext.productInfos,
        cartContext.availabilities,
      ),
      isOffline,
      displayCouponContainer,
      isLoyaltyProgramEligible: activeCustomer.isLoyaltyProgramEligible,
      creditWidgetLoadingState: cartContext.creditWidgetLoadingState,
      payWithCreditSelected: cartContext.payWithCreditSelected,
    };
  }

  private static buildCustomerInfo(
    customerName: string,
    customerNumber: string,
    customerBrand: CustomerBrand,
  ): CostSummaryCustomerInfo {
    return {
      customerName,
      customerNumber,
      customerBrand,
    };
  }

  private static buildShippingInfo(
    poNumber: string,
    isPoRequired: boolean,
    deliverySchedule: DeliveryScheduleEntryRecord,
  ): CostSummaryShippingInfo {
    return {
      poNumber,
      isPoRequired,
      routeDate: deliverySchedule ? deliverySchedule.routeDate : undefined,
    };
  }

  private static buildQuantities(
    totals: CartPriceTotals,
    splitOrders: SplitOrder[],
    dropShipTotals: CartCounts[],
    storeCountsContext: StoreCountsContext,
  ): OrderSummary[] {
    return [
      ...CostSummaryTransformer.dropShipTotalsToOrderSummary(
        totals,
        dropShipTotals,
        splitOrders,
      ),
      ...CostSummaryTransformer.storeCartCountsContextToOrderSummary(
        storeCountsContext,
      ),
    ];
  }

  private static storeCartCountsContextToOrderSummary(
    storeCountsContext: StoreCountsContext,
  ): OrderSummary[] {
    if (!storeCountsContext?.cartCounts?.length) {
      return [];
    }
    const storeCartCounts = storeCountsContext.cartCounts;
    const dateHasBeenResolved = storeCountsContext.dateHasBeenResolved;
    const orderType = SplitOrderType.PICKUP;
    const placeholder = NaooConstants.NDASH;
    const title =
      CartReviewSectionName.ExpressItemsSection === storeCountsContext.name
        ? 'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.EXPRESS_ORDER_QUANTITY'
        : 'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.ISPU_ORDER_QUANTITY';

    const estimatedDeliveryDates = storeCartCounts
      .map((cartCount) => cartCount.estimatedDeliveryDate)
      .filter((date) => !!date);

    const isSinglePickup = new Set(estimatedDeliveryDates).size === 1;
    if (isSinglePickup) {
      return [
        storeCartCounts
          .map(
            (counts) =>
              <OrderSummary>{
                lines: counts.cartLineCount,
                totalQuantity: counts.totalQuantity,
              },
          )
          .reduce(
            (prv: OrderSummary, cur: OrderSummary) => {
              prv.lines += cur.lines;
              prv.totalQuantity += cur.totalQuantity;
              return prv;
            },
            {
              lines: 0,
              totalQuantity: 0,
              placeholder,
              title,
              orderType,
              estimatedDeliveryDate: dateHasBeenResolved
                ? estimatedDeliveryDates[0]
                : undefined,
            },
          ),
      ];
    }

    // split pickup
    return storeCartCounts.map(
      (counts) =>
        <OrderSummary>{
          lines: counts.cartLineCount,
          totalQuantity: counts.totalQuantity,
          placeholder,
          title,
          orderType,
          estimatedDeliveryDate: counts.estimatedDeliveryDate,
        },
    );
  }

  private static getWarning(
    context: CostSummaryContext,
    isSelectedFulfillmentLoaded: boolean,
    isSelectedFulfillmentValidForSubmission: boolean,
    infos: MaterialInfoRecordState[],
    availabilities: MaterialAvailabilityRecordState[],
  ): string {
    if (
      context === CostSummaryContext.CartReview &&
      isSelectedFulfillmentLoaded &&
      !isSelectedFulfillmentValidForSubmission &&
      this.hasItemThatRequiresRouteDate(availabilities, false)
    ) {
      return 'CART_REVIEW.COST_SUMMARY.NO_ROUTE_DATE_WARNING';
    }

    if (
      infos.some(this.isUnavailable) ||
      availabilities.some(this.isUnorderable)
    ) {
      return 'CART.UNAVAILABLE_ITEMS';
    }

    return undefined;
  }

  private static getButtonState(
    context: CostSummaryContext,
    customer: SessionActiveCustomer,
    availabilities: MaterialAvailabilityRecordState[],
    totalQuantity: number,
    isSelectedFulfillmentValidForSubmission: boolean,
    isPunchOut: boolean,
    ispuHasResolvedDate?: boolean,
  ): SubmitOrderButtonState {
    if (totalQuantity === 0) {
      return customer.permissions.includes(CustomerPermission.OrderSubmission)
        ? SubmitOrderButtonState.EmptyCart
        : SubmitOrderButtonState.NoOrderSubmissionPermissionEmptyCart;
    }

    if (
      ispuHasResolvedDate === false ||
      (!isSelectedFulfillmentValidForSubmission &&
        this.hasItemThatRequiresRouteDate(availabilities, true))
    ) {
      return SubmitOrderButtonState.NoRouteDate;
    }

    if (isPunchOut && CostSummaryContext.CartReview == context) {
      return SubmitOrderButtonState.TransferCart;
    }

    if (customer.permissions.includes(CustomerPermission.OrderSubmission)) {
      if (context == CostSummaryContext.CartReviewPaymentRequired) {
        return SubmitOrderButtonState.AddPayment;
      }
      return SubmitOrderButtonState.Ok;
    } else {
      return SubmitOrderButtonState.NoOrderSubmissionPermission;
    }
  }

  private static isNotSpecialOrderOrDropShip(
    availability: MaterialAvailabilityRecordState,
  ) {
    return (
      !!availability &&
      !!availability.record &&
      !(
        availability.record.stockType === StockType.DropShip ||
        availability.record.stockType === StockType.SpecialOrder ||
        availability.record.stockType === StockType.SpecialOrderSAP
      )
    );
  }

  private static hasItemThatRequiresRouteDate(
    availabilities: MaterialAvailabilityRecordState[],
    assumesLoadingAsRequiresRouteDate: boolean,
  ): boolean {
    return availabilities.some(
      (availability) =>
        (assumesLoadingAsRequiresRouteDate && this.isLoading(availability)) ||
        this.isNotSpecialOrderOrDropShip(availability),
    );
  }

  private static isUnavailable(infoState: MaterialInfoRecordState): boolean {
    return (
      !!infoState && infoState.status === MaterialInfoRecordStatus.Unavailable
    );
  }

  private static isUnorderable(
    availability: MaterialAvailabilityRecordState,
  ): boolean {
    return (
      !!availability &&
      !!availability.record &&
      !availability.record.isOrderable
    );
  }

  private static isLoading(availability: MaterialAvailabilityRecordState) {
    return (
      !availability ||
      [
        MaterialAvailabilityRecordStatus.Queued,
        MaterialAvailabilityRecordStatus.Requested,
      ].includes(availability.status)
    );
  }

  private static dropShipTotalsToOrderSummary(
    cartPriceTotals: CartPriceTotals,
    dropShipTotals: CartCounts[],
    splitOrders: SplitOrder[],
  ): OrderSummary[] {
    const dropShipResult: OrderSummary[] = [];
    const dropShipTitle = `CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.DROP_SHIP_ORDER_QUANTITY${
      dropShipTotals?.length > 1 ? '_WITH_COUNT' : ''
    }`;
    const placeholder = '';

    let {
      totalQuantity: standardOrderTotalQuantity,
      cartLineCount: standardOrderTotalLines,
    } = cartPriceTotals.cartCounts;

    dropShipTotals?.forEach((dropShipOrder) => {
      if (dropShipOrder.totalQuantity > 0) {
        standardOrderTotalQuantity -= dropShipOrder.totalQuantity;
      }
      if (dropShipOrder.cartLineCount > 0) {
        standardOrderTotalLines -= dropShipOrder.cartLineCount;
      }
      const matchedOrderData: SplitOrder = splitOrders.find(
        (order) =>
          order?.carrierFulfillment?.shipmentId === dropShipOrder.shipmentId,
      );
      dropShipResult.push({
        lines: dropShipOrder.cartLineCount,
        totalQuantity: dropShipOrder.totalQuantity,
        placeholder,
        title: dropShipTitle,
        orderType: SplitOrderType.DROP_SHIP,
        estimatedDeliveryDate:
          matchedOrderData?.carrierFulfillment?.estimatedCustomerArrivalDate,
      });
    });

    dropShipResult.push({
      lines: standardOrderTotalLines,
      totalQuantity: standardOrderTotalQuantity,
      placeholder,
      title: 'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.STANDARD_ORDER_QUANTITY',
      estimatedCost:
        cartPriceTotals.estimatedCost + cartPriceTotals.shippingCost,
      estimatedSavings: cartPriceTotals.estimatedSavings,
      totalLoyaltyPoints: cartPriceTotals.cartLoyaltyPoints,
    });

    return dropShipResult;
  }
}
