import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import moment from 'moment-timezone';
import { DateRange } from './date-range';
import {
  MatFormField,
  MatLabel,
  MatPrefix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatIcon,
    MatPrefix,
    MatSelect,
    MatOption,
    TranslateModule,
  ],
})
export class DateRangeSelectorComponent implements OnInit {
  @Output() dateRangeEmitter = new EventEmitter<DateRange>();
  @Input() selectedStartDate?: string;

  private static readonly MAX_QUARTER_HISTORY = 12;
  private static readonly LABELS = [
    'DATE_SELECTOR.JAN_MAR',
    'DATE_SELECTOR.APR_JUN',
    'DATE_SELECTOR.JUL_SEP',
    'DATE_SELECTOR.OCT_DEC',
  ];
  private static readonly ARIA_LABELS = [
    'DATE_SELECTOR.JANUARY_MARCH',
    'DATE_SELECTOR.APRIL_JUNE',
    'DATE_SELECTOR.JULY_SEPTEMBER',
    'DATE_SELECTOR.OCTOBER_DECEMBER',
  ];

  dateRanges: DateRange[];
  selectedDateRange: DateRange;

  ngOnInit() {
    this.dateRanges = this.buildDateRanges();
    const existingDate = this.dateRanges.find(
      (dateRange) => dateRange.startDate === this.selectedStartDate,
    );
    if (existingDate) {
      this.selectedDateRange = existingDate;
    } else {
      this.selectedDateRange = this.dateRanges[0];
      this.dateRangeEmitter.emit(this.selectedDateRange);
    }
  }

  trackByDateRange(dateRange: DateRange) {
    return dateRange.label + dateRange.year;
  }

  private buildDateRanges(): DateRange[] {
    const now = moment();
    const options: DateRange[] = [
      {
        startDate: moment(now)
          .subtract(100, 'days')
          .format(moment.HTML5_FMT.DATE),
        endDate: now.format(moment.HTML5_FMT.DATE),
        label: 'DATE_SELECTOR.LAST_100_DAYS',
        ariaLabel: 'DATE_SELECTOR.LAST_100_DAYS',
      },
    ];

    const lastCompletedQuarter: number = now.quarter() - 1;
    for (let i = 0; i < DateRangeSelectorComponent.MAX_QUARTER_HISTORY; i++) {
      const quarterMoment = moment(now).quarter(lastCompletedQuarter - i);
      options.push(this.dateRangeFactory(quarterMoment));
    }

    return options;
  }

  private dateRangeFactory(quarterMoment: moment.Moment): DateRange {
    const startDate = quarterMoment
      .startOf('quarter')
      .format(moment.HTML5_FMT.DATE);
    const endDate = quarterMoment
      .endOf('quarter')
      .format(moment.HTML5_FMT.DATE);
    const year = quarterMoment.get('year');

    // moment.quarter() is 1-indexed
    const quarter = quarterMoment.quarter() - 1;

    return {
      startDate,
      endDate,
      year,
      label: DateRangeSelectorComponent.LABELS[quarter],
      ariaLabel: DateRangeSelectorComponent.ARIA_LABELS[quarter],
    };
  }
}
