<header [ngClass]="{ sticky: stickyHeader, mobile: isMobile }">
  @if (showBackArrow) {
    <button [attr.aria-label]="title" (click)="backActionModal()" class="title">
      <mat-icon svgIcon="arrow-v2" class="back-arrow"></mat-icon>
      {{ title }}
    </button>
  } @else {
    <div class="title">{{ title }}</div>
  }
  @if (showCloseButton) {
    <button
      class="close-button"
      (click)="closeModal()"
      [attr.aria-label]="'CLOSE' | translate"
      #focusableButton
    >
      <img
        class="close-button-image"
        src="assets/images/close-icon.svg"
        alt="{{ 'CLOSE' | translate }}"
      />
    </button>
  }
</header>
