import { Component, OnInit } from '@angular/core';
import { NaooConstants } from '../../shared/NaooConstants';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateCustomGuideErrorStateMatcher } from '../create-custom-guide-modal/create-custom-guide-error-state-matcher';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { NotOnlyWhitespaceValidator } from '../../shared/services/validators/not-only-whitespace-validator.service';
import { OrderGuideCategoryNameExistsValidator } from '../../shared/services/validators/order-guide-category-name-exists-validator.service';
import {
  MatFormField,
  MatLabel,
  MatHint,
  MatError,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-create-order-guide-category-modal',
  templateUrl: './create-order-guide-category-modal.component.html',
  styleUrls: ['./create-order-guide-category-modal.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    MatHint,
    MatError,
    TranslateModule,
  ],
})
export class CreateOrderGuideCategoryModalComponent implements OnInit {
  orderGuideCategoryNameFormControl: FormControl;
  matcher = new CreateCustomGuideErrorStateMatcher();
  customCategoryMaxLength = NaooConstants.ORDERGUIDE_CUSTOM_CATEGORY_MAX_LENGTH;

  constructor(
    private dialogRef: MatDialogRef<CreateOrderGuideCategoryModalComponent>,
    private notOnlyWhitespaceValidator: NotOnlyWhitespaceValidator,
    private orderGuideCategoryNameExistsValidator: OrderGuideCategoryNameExistsValidator,
  ) {}

  get newCustomGuideCategoryName() {
    return this.orderGuideCategoryNameFormControl.value.toString().trim();
  }

  ngOnInit(): void {
    this.orderGuideCategoryNameFormControl = new FormControl(
      '',
      [
        Validators.maxLength(this.customCategoryMaxLength),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
        this.notOnlyWhitespaceValidator.validate(),
      ],
      this.orderGuideCategoryNameExistsValidator
        .validate('')
        .bind(this.orderGuideCategoryNameExistsValidator),
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  createCategory() {
    if (this.isFormValid()) {
      this.dialogRef.close(this.newCustomGuideCategoryName);
    }
  }

  isFormValid(): boolean {
    return this.orderGuideCategoryNameFormControl.valid;
  }
}
