import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  SelectableButton,
  SelectableButtonComponent,
} from '../selectable-button/selectable-button.component';

@Component({
  selector: 'naoo-selectable-button-list',
  templateUrl: './selectable-button-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SelectableButtonComponent],
})
export class SelectableButtonListComponent {
  @ViewChildren('selectableButton')
  selectableButtons: QueryList<SelectableButtonComponent>;

  @Input() set buttons(buttons: SelectableButton[]) {
    this._selectedIndex = buttons?.findIndex((button) => !button.disabled);
    this._buttons = buttons;
    this.didSelectButton(this.selectedIndex);
  }

  get buttons(): SelectableButton[] {
    return this._buttons;
  }

  @Input() buttonSpacing = 0;
  @Output() selectionChanged = new EventEmitter<number>();

  private _buttons: SelectableButton[];
  private _selectedIndex = 0;

  @Output() get selectedIndex(): number {
    return this._selectedIndex;
  }

  didSelectButton(index: number) {
    this._selectedIndex = index;
    this.selectionChanged.emit(index);
  }
}
