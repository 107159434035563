import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/shared/services/loading-service/loading.service';
import { finalize, tap } from 'rxjs/operators';
import { SearchResults } from '../../core/store/search/shared/search-results';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { RouterExtrasService } from 'src/app/shared/services/router-extras/router-extras.service';
import { SearchResultsContentComponent } from './search-results-content/search-results-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-search-results-container',
  template: `
    <naoo-search-results-content
      [searchResult]="searchResult$ | async"
      [isMobile]="isMobile$ | async"
      (fetchMoreMaterials)="fetchMoreMaterials()"
    ></naoo-search-results-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchResultsContentComponent, AsyncPipe],
})
export class SearchResultsContainerComponent implements OnInit {
  searchResult$: Observable<SearchResults>;
  isMobile$: Observable<boolean>;

  constructor(
    private searchFacade: SearchFacade,
    private loadingService: LoadingService,
    private deviceIdentifierService: DeviceIdentifierService,
    private routerExtras: RouterExtrasService,
  ) {}

  ngOnInit() {
    this.searchResult$ = this.searchFacade.getSearchResults().pipe(
      tap((searchResult) => {
        if (searchResult.hasLoaded) {
          this.loadingService.stop();
          if (
            this.routerExtras.getRestoreScrollPosition(window.location.href)
          ) {
            this.routerExtras.restoreScrollPosition(window.location.href);
          }
        } else {
          this.loadingService.start();
        }
      }),
      finalize(() => this.loadingService.stop()),
    );

    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
  }

  fetchMoreMaterials() {
    this.searchFacade.loadMoreResults();
  }
}
