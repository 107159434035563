@if (hasSavedCarts) {
  @if (savedCarts.submittedForApproval.length > 0) {
    <mat-expansion-panel class="saved-carts-expansion-panel" expanded="true">
      <mat-expansion-panel-header
        class="saved-carts-expansion-panel-header carts-submitted"
        expandedHeight="100%"
        collapsedHeight="100%"
      >
        <div class="banner-header">
          <h3>
            {{
              'CART.SUBMITTED_CARTS.SUBMITTED_CARTS_HEADER'
                | translate
                  : {
                      submittedCartsCount:
                        savedCarts.submittedForApproval.length,
                    }
            }}
          </h3>
        </div>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <span>
          {{ 'CART.SUBMITTED_CARTS.SUBMITTED_CARTS_HINT_TEXT_1' | translate }}
        </span>
        <span>
          {{
            'CART.SUBMITTED_CARTS.SUBMITTED_CARTS_HINT_TEXT_2_START' | translate
          }}
          @if (savedCarts.isOffline) {
            <span class="help-button disabled">
              {{
                'CART.SUBMITTED_CARTS.SUBMITTED_CARTS_HINT_TEXT_2_END'
                  | translate
              }}
            </span>
          } @else {
            <a class="help-button" [routerLink]="ordersPath">
              {{
                'CART.SUBMITTED_CARTS.SUBMITTED_CARTS_HINT_TEXT_2_END'
                  | translate
              }}
            </a>
          }
        </span>
      </div>
      @for (
        submittedCart of savedCarts.submittedForApproval;
        track submittedCart.id
      ) {
        <naoo-submitted-cart-row
          [submittedCart]="submittedCart"
          [shouldPromptActivateModal]="savedCarts.shouldPromptActivateModal"
          [isOffline]="savedCarts.isOffline"
          [isSelectedCart]="selectedCartId === submittedCart?.id"
          [isPreviewOpen]="isPreviewOpen"
        >
        </naoo-submitted-cart-row>
      }
    </mat-expansion-panel>
  }
  @if (savedCarts.saved.length > 0) {
    <mat-expansion-panel class="saved-carts-expansion-panel" expanded="true">
      <mat-expansion-panel-header
        class="saved-carts-expansion-panel-header carts-saved"
        expandedHeight="100%"
        collapsedHeight="100%"
      >
        <div class="banner-header">
          <h3>
            {{
              'CART.SAVED_CARTS.SAVED_CARTS_HEADER'
                | translate: { savedCartsCount: savedCarts.saved.length }
            }}
          </h3>
        </div>
      </mat-expansion-panel-header>
      <div class="sub-header">
        <span>
          {{ 'CART.SAVED_CARTS.SAVED_CARTS_HINT_TEXT' | translate }}
          <a
            title="{{
              'CART.EMPTY_SAVED_CARTS.HOW_TO_SAVE_DESCRIPTION_ANCHOR_TEXT_ALT'
                | translate
            }}"
            href="javascript:void(0)"
            (click)="openHelperModal()"
            class="help-button"
            id="help-modal"
          >
            {{ 'CART.SAVED_CARTS.SAVED_CARTS_HINT_ANCHOR_TEXT' | translate }}
          </a>
        </span>
      </div>
      @for (savedCart of savedCarts.saved; track savedCart.id) {
        <naoo-saved-cart-row
          [savedCart]="savedCart"
          [shouldPromptActivateModal]="savedCarts.shouldPromptActivateModal"
          [isOffline]="savedCarts.isOffline"
          [isSelectedCart]="selectedCartId === savedCart?.id"
        >
        </naoo-saved-cart-row>
      }
    </mat-expansion-panel>
  }
} @else if (!savedCartsLoading) {
  <naoo-empty-saved-carts></naoo-empty-saved-carts>
}
