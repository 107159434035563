import { Component, OnInit } from '@angular/core';
import { SessionFacade } from '../../core/store/session/session.facade';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { HeroImagesComponent } from '../../shared/hero-images/hero-images.component';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-scheduled-maintenance',
  templateUrl: './scheduled-maintenance.component.html',
  styleUrls: ['./scheduled-maintenance.component.scss'],
  standalone: true,
  imports: [HeroImagesComponent, AsyncPipe, UpperCasePipe, TranslateModule],
})
export class ScheduledMaintenanceComponent implements OnInit {
  isMultiCustomerUnit$: Observable<boolean>;

  private MAINTENANCE_ANALYTICS_EVENT: AnalyticsEventInfo = {
    category: 'SAP Migration Maintenance',
    action: 'Displayed',
    label: 'Blocked During Migration',
  };
  constructor(
    private sessionFacade: SessionFacade,
    private analyticsManager: NaooAnalyticsManager,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isMultiCustomerUnit$ = this.sessionFacade.isMultiCustomerUnit();
    this.analyticsManager.trackAnalyticsEvent(this.MAINTENANCE_ANALYTICS_EVENT);
  }

  backToCustomerUnitSelector(): void {
    this.router.navigateByUrl(NaooConstants.CUSTOMER_UNIT_SELECTION_PATH);
  }
}
