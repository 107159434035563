@if (materialRow$ | async) {
  <div class="material-wrapper">
    @if (isUnavailable((materialRow$ | async)?.status)) {
      <naoo-material-unavailable
        [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
        [isMobile]="true"
        [isDeleteHidden]="true"
      >
      </naoo-material-unavailable>
    } @else {
      <naoo-material-image
        [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
        [isMobile]="true"
      ></naoo-material-image>
      <naoo-material-info
        [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
        [materialInfo]="(materialRow$ | async)?.materialRowInfo"
      ></naoo-material-info>
    }
  </div>
}
