import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ResizeEventService } from '../../../shared/services/resize-event/resize-event.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MaterialListRow } from '../../models/material-list';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../core/store/material-row/models/material-row';
import { ScrollListenerService } from '../../../shared/services/scroll-listener/scroll-listener.service';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { DOCUMENT, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MaterialRowContainerComponent } from '../../material-row-container/material-row-container.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-carousel',
  templateUrl: './material-carousel.component.html',
  styleUrls: ['./material-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NgClass, MaterialRowContainerComponent, TranslateModule],
})
export class MaterialCarouselComponent implements OnInit, OnDestroy, OnChanges {
  @Input() materialListRows: MaterialListRow[];
  @Input() materialListStyle: MaterialListStyle = MaterialListStyle.Grid;
  @Input() recommendationsAnalyticsEnabled: boolean = true;
  @Input() materialContext = MaterialRowContext.Recommendation;
  @Input() recommendationEngineName?: string;
  @ViewChild('materialRowContainer') materialRowContainer: ElementRef;

  @Output() visibleRowsDidChange = new EventEmitter<MaterialListRow[]>();

  private _visibleMaterialRows: MaterialListRow[] = [];

  get visibleMaterialRows(): MaterialListRow[] {
    return this._visibleMaterialRows;
  }

  set visibleMaterialRows(rows: MaterialListRow[]) {
    this._visibleMaterialRows = rows;
    this.visibleRowsDidChange.emit(rows);
  }

  get showCount(): boolean {
    return MaterialRowContext.Comparison === this.materialContext;
  }

  get carouselContentWidth(): number {
    return this.materialRowContainer?.nativeElement?.offsetWidth;
  }

  setVisibleMaterialRows(rows: MaterialListRow[]) {
    this._visibleMaterialRows = rows;
    this.changeDetector.markForCheck();
  }

  isMaterialComparisons(): boolean {
    return this.materialContext === MaterialRowContext.Comparison;
  }

  get dynamicWidth(): string {
    if (!this.isMaterialComparisons()) {
      return null;
    }
    if (this.cardsToDisplay > 0) {
      const windowWidth = Math.min(this.window.innerWidth, 1456);
      const cardSpacing = windowWidth <= 900 ? 100 : 180;
      return `${(windowWidth - cardSpacing) / this.cardsToDisplay}px`;
    }

    return '100%';
  }

  cardIndex(materialRow: MaterialListRow): number {
    return this.materialListRows.indexOf(materialRow) + 1;
  }

  // number of material rows which will be displayed in the main page
  cardsToDisplay = 0;

  private _maxVisibleMaterials = 4;

  get maxVisibleMaterials(): number {
    return this._maxVisibleMaterials;
  }

  @Input() set maxVisibleMaterials(count: number) {
    this._maxVisibleMaterials = count;
    this.cardSizeBreakpoints = this.createCardBreakpoints(count);
  }

  // number of cards shown when screen width is below these values
  private readonly singleCardWidth = 740;
  private readonly additionalCardWidth = 340;

  private cardSizeBreakpoints = this.createCardBreakpoints(
    this.maxVisibleMaterials,
  );

  private destroyed$ = new Subject<void>();

  private impressionAnalyticsSent: string[] = [];

  constructor(
    private elementRef: ElementRef,
    private scrollListenerService: ScrollListenerService,
    private changeDetector: ChangeDetectorRef,
    private resizeEventService: ResizeEventService,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    if (this.recommendationsAnalyticsEnabled) {
      this.scrollListenerService
        .getEvents()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          if (this.recommendationsFullyVisible(this.elementRef.nativeElement)) {
            this.trackAnalyticsForVisibleMaterials();
          }
        });
    }

    this.resizeEventService
      .getEvents()
      .pipe(
        filter((val) => !!val),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        const windowWidth = this.window.innerWidth;
        const screenFitsCardCount =
          this.cardSizeBreakpoints.filter((size) => windowWidth >= size)
            .length + 1;
        // if the number of cards we can fit is larger than how many cards we have
        // use the smaller number
        this.cardsToDisplay = Math.min(
          screenFitsCardCount,
          this.materialListRows.length,
        );
        this.adjustMaterialIndex(0, true);
      });

    this.adjustMaterialIndex(0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.materialListRows &&
      changes.materialListRows.previousValue !==
        changes.materialListRows.currentValue
    ) {
      this.adjustMaterialIndex(0);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private createCardBreakpoints(count: number): number[] {
    const breakpoints: number[] = [];
    for (let i = 1; i < count; ++i) {
      breakpoints.push(
        this.singleCardWidth + this.additionalCardWidth * (i - 1),
      );
    }
    return breakpoints;
  }

  private currentVisibleStartingIndex(): number {
    if (!this.materialListRows || !this.visibleMaterialRows) {
      return 0;
    }

    const firstVisibleRow = this.visibleMaterialRows[0];
    return this.materialListRows.indexOf(firstVisibleRow);
  }

  arrowNext() {
    this.adjustMaterialIndex(1);
  }

  arrowPrevious() {
    this.adjustMaterialIndex(-1);
  }

  handleMaterialClicked(materialRecord: MaterialListRow): void {
    this.ecommerceAnalyticsFacade.trackRecommendationClickEvent(
      materialRecord.value as string,
    );
  }

  get arrowVisibility(): string {
    return this.materialListRows?.length <= this.cardsToDisplay
      ? 'hidden'
      : 'visible';
  }

  get arrowNextDisabled(): boolean {
    if (!this.visibleMaterialRows || !this.materialListRows) {
      return true;
    }

    return (
      this.visibleMaterialRows[this.visibleMaterialRows.length - 1] ==
      this.materialListRows[this.materialListRows.length - 1]
    );
  }

  get arrowPreviousDisabled(): boolean {
    if (!this.visibleMaterialRows || !this.materialListRows) {
      return true;
    }

    return this.visibleMaterialRows[0] == this.materialListRows[0];
  }

  private adjustMaterialIndex(adjustBy: number, resizing = false) {
    if (!this.materialListRows) {
      this.visibleMaterialRows = [];
      return;
    }

    const currentVisibleIndex = this.currentVisibleStartingIndex();

    let adjustedIndex = currentVisibleIndex + adjustBy;

    // find the maximum number of materials that can be displayed in the screen
    const maxDisplay = this.getMaxMaterialsDisplayed(adjustBy);
    if (adjustedIndex > this.materialListRows.length - maxDisplay) {
      adjustedIndex = this.materialListRows.length - maxDisplay;
    }
    if (adjustedIndex <= 0) {
      adjustedIndex = 0;
    }

    // If the screen is stretched to reveal more materials while at the end of the list,
    // we need to shift the current index back 1 to fill the empty space created by stretching.
    if (resizing && this.isScreenStretch(maxDisplay, adjustedIndex)) {
      adjustedIndex -= 1;
    }
    this.visibleMaterialRows = this.getCurrentItemsAggregation(adjustedIndex);
    this.changeDetector.markForCheck();
    if (
      this.recommendationsAnalyticsEnabled &&
      this.recommendationsFullyVisible(this.elementRef.nativeElement)
    ) {
      this.trackAnalyticsForVisibleMaterials();
    }
  }

  private trackAnalyticsForVisibleMaterials() {
    this.visibleMaterialRows.forEach((materialListRow) => {
      const materialNumber = materialListRow.value as string;
      if (!this.impressionAnalyticsSent.includes(materialNumber)) {
        this.impressionAnalyticsSent.push(materialNumber);
        this.ecommerceAnalyticsFacade.trackRecommendationViewEvent(
          materialNumber,
        );
      }
    });
  }

  private isScreenStretch(
    maxMaterialsToDisplay: number,
    currentVisibleIndex: number,
  ): boolean {
    return (
      this.visibleMaterialRows.length !== this.materialListRows.length &&
      currentVisibleIndex + (maxMaterialsToDisplay - 1) >=
        this.materialListRows.length
    );
  }

  private getMaxMaterialsDisplayed(adjustBy: number): number {
    return adjustBy === 0
      ? Math.max(this.cardsToDisplay, this.visibleMaterialRows.length)
      : Math.min(this.cardsToDisplay, this.visibleMaterialRows.length);
  }

  private getCurrentItemsAggregation(
    currentVisibleIndex: number,
  ): MaterialListRow[] {
    return this.materialListRows.slice(
      currentVisibleIndex,
      currentVisibleIndex + this.cardsToDisplay,
    );
  }

  private recommendationsFullyVisible(element: Element): boolean {
    // offset the bottom of the view calculation to trigger event
    // slightly before the entire component is on screen.
    const viewBaselineOffset = 100;

    const rect = element.getBoundingClientRect();
    const viewHeight = Math.max(
      this.document.documentElement.clientHeight,
      this.window.innerHeight,
    );

    return viewHeight - rect.top - viewBaselineOffset >= rect.height;
  }
}
