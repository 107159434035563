import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FulfillmentType } from '../../../../../core/services/cart/models/cart-record';
import {
  ExpressDeliveryWindow,
  ExpressScheduleRecord,
} from '../../../../../core/services/express-schedules/models/express-schedule-record';
import { Moment } from 'moment';
import { StoreDetailsViewModel } from '../../../../../core/store/order-method-modal/order-method-modal-view-model';
import { SelectDateStepTruckComponent } from './select-date-step/select-date-step-truck/select-date-step-truck.component';
import { SelectDateStepExpressComponent } from './select-date-step/select-date-step-express/select-date-step-express.component';
import { SelectDateStepIspuComponent } from './select-date-step/select-date-step-ispu/select-date-step-ispu.component';

@Component({
  selector: 'naoo-select-date-container',
  templateUrl: './select-date-container.component.html',
  styleUrls: ['./select-date-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SelectDateStepTruckComponent,
    SelectDateStepExpressComponent,
    SelectDateStepIspuComponent,
  ],
})
export class SelectDateContainerComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() selectedDate: Moment;
  @Input() availableDates: string[];
  @Input() cutoffDateTime: string;
  @Input() customerTimeZone: string;
  @Input() selectedExpressDeliveryWindow: ExpressDeliveryWindow;
  @Input() selectedExpressScheduleRecord: ExpressScheduleRecord;
  @Input() storeDetailsViewModel: StoreDetailsViewModel;
  @Input() isMobileWidth: boolean;
  @Input() isRouteDateExpired: boolean;

  @Output() dateChangeEmitter = new EventEmitter<Moment>();
  @Output()
  expressDeliveryWindowChangeEmitter =
    new EventEmitter<ExpressDeliveryWindow>();

  readonly truckFulfillment = FulfillmentType.TRUCK;
  readonly expressFulfillment = FulfillmentType.EXPRESS;
  readonly pickupFulfillment = FulfillmentType.PICKUP;

  isFulfillmentType(fulfillmentType: FulfillmentType): boolean {
    return this.fulfillmentType === fulfillmentType;
  }
}
