import { CanMatchFn } from '@angular/router';
import { FeatureFlag } from '../../core/services/session/models/session-record';
import { inject } from '@angular/core';
import { SessionFacade } from '../../core/store/session/session.facade';

export const featureFlagEnabledGuard = (feature: FeatureFlag): CanMatchFn => {
  return () => {
    const sessionFacade = inject(SessionFacade);
    return sessionFacade.isFeatureEnabled(feature);
  };
};
