<a #scrollAnchor></a>
<mat-expansion-panel
  #expansionPanel
  class="generic-expansion-panel"
  [ngClass]="{
    'mat-elevation-z0 custom-border': isLargerThanMaxHeight,
    'red-border': hasRedBorder,
  }"
  [expanded]="startExpanded"
  (afterExpand)="scrollToContent()"
  (afterCollapse)="afterCollapse()"
  [hideToggle]="hideToggleIcon"
>
  <!-- 'auto' height needed to maintain a static height when expanding/collapsing -->
  <mat-expansion-panel-header
    class="generic-expansion-header"
    [ngClass]="colorScheme"
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'"
  >
    @if (icon) {
      <mat-panel-title>
        <mat-icon [ngClass]="icon.color" svgIcon="{{ icon.name }}"></mat-icon>
      </mat-panel-title>
    }
    <mat-panel-description class="header-title" [ngClass]="colorScheme">
      {{ headerTitle | translate | uppercase }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <!-- Body content provided from parent component -->
  <ng-content></ng-content>
</mat-expansion-panel>
