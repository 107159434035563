<div class="info-content" [ngClass]="{ mobile: isMobile }">
  <naoo-material-image
    [materialRowOptions]="materialRowOptions"
    [isUnorderable]="true"
    [isMobile]="isMobile"
  ></naoo-material-image>

  <div class="unavailable-info">
    <div class="description">
      {{ 'PRODUCT.UNAVAILABLE_ITEM' | translate }}
    </div>
    <div class="material-number">
      @if (!!materialRowOptions.customerMaterialNumber) {
        {{ materialRowOptions.customerMaterialNumber }} :
      }
      #{{ materialRowOptions.materialNumber }}
    </div>
  </div>
</div>

@if (!isDeleteHidden) {
  <div class="trash-button" [ngClass]="{ mobile: isMobile }">
    <naoo-material-trash-button [materialRowOptions]="materialRowOptions">
    </naoo-material-trash-button>
  </div>
}
