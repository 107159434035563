@if (displayMobileHeader) {
  <naoo-material-ordering-info-header></naoo-material-ordering-info-header>
}
@if (!!currentOrderingInfo) {
  <div class="ordering-info-content" [ngClass]="contentStyleClass">
    @if (!isComplimentaryProduct) {
      @if (displayMaxAllocation) {
        <div class="commodity-available">
          <button (click)="viewCommodityDetailsClicked.emit()">
            {{ materialRowOptions.materialCommodityInfo.maxAllocationDisplay }}
          </button>
        </div>
      }
      @if (
        !shouldUseInlineUom &&
        !isProductDetails &&
        !isMobile &&
        !currentOrderingInfo.pricingPortion
      ) {
        <ng-container [ngTemplateOutlet]="loyaltyPoints"></ng-container>
        <div class="size info-section non-drop-down">
          {{ { uomCode: currentOrderingInfo.displayCode } | materialunits }}
        </div>
      }
      @if (
        !shouldUseInlineUom && !isMobile && !!currentOrderingInfo.pricingPortion
      ) {
        <ng-container [ngTemplateOutlet]="loyaltyPoints"></ng-container>
        <div [ngClass]="{ 'info-price-section-grid': isPortionPricingGrid }">
          <div
            class="info-section non-drop-down expanded-size"
            [ngClass]="isPortionPricingGrid ? 'info-section-grid' : 'size'"
          >
            @if (!isProductDetails) {
              {{ { uomCode: currentOrderingInfo.displayCode } | materialunits }}
            }
            @if (isPortionPricingGrid) {
              {{
                currentOrderingInfo.price
                  | naooprice: currentOrderingInfo.catchWeightUom
              }}
            }
          </div>
          @if (isPortionPricingGrid) {
            <div class="portion-pricing-grid">
              {{
                currentOrderingInfo.pricingPortion
                  | naooprice: currentOrderingInfo.pricingPortionUom
              }}
            </div>
          }
        </div>
      }
      @if (shouldUseInlineUom) {
        <div class="naoo-material-uom-select uom-button-container size">
          <mat-form-field class="uom-select-field">
            <mat-select
              [value]="materialOrderingTabs[selectedIndex].uom"
              (selectionChange)="changeTab($event)"
              [ngClass]="{ 'highlight-quantity': hasHiddenQuantity }"
            >
              <mat-select-trigger class="uom-select-trigger">
                {{
                  { uomCode: materialOrderingTabs[selectedIndex].displayCode }
                    | materialunits
                }}
              </mat-select-trigger>
              @for (
                tab of materialOrderingTabs;
                track tab;
                let index = $index
              ) {
                <mat-option class="naoo-material-uom-option" [value]="tab.uom">
                  {{ { uomCode: tab.displayCode } | materialunits }}
                  @if (index !== selectedIndex && tab.quantity > 0) {
                    ({{ tab.quantity }})
                  }
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @if (!isPortionPricingGrid || !currentOrderingInfo.pricingPortion) {
        <div class="price-container" [ngClass]="{ mobile: isMobile }">
          @if (!shouldHidePortionPrice) {
            <div class="portion-pricing info-section">
              @if (!!currentOrderingInfo.pricingPortion) {
                {{
                  currentOrderingInfo.pricingPortion
                    | naooprice: currentOrderingInfo.pricingPortionUom
                }}
              }
            </div>
          }
          <div class="price info-section" [ngClass]="{ mobile: isMobile }">
            {{
              currentOrderingInfo.price
                | naooprice: currentOrderingInfo.catchWeightUom
            }}
          </div>
        </div>
      }
    }
    <div class="quantity-container">
      @if (materialRowOptions.isParEnabled) {
        <div class="par-quantity" [ngClass]="{ mobile: isMobile }">
          @if (!!currentOrderingInfo.parLine) {
            <naoo-material-par-quantity-input
              [currentOrderingInfo]="currentOrderingInfo"
              [materialRowOptions]="materialRowOptions"
              [isMobile]="isMobile"
            ></naoo-material-par-quantity-input>
          }
        </div>
      }
      @if (!isNonEditableItem) {
        <naoo-material-quantity-input
          [orderingInfos]="orderingInfos"
          [currentOrderingInfo]="currentOrderingInfo"
          [materialRowOptions]="materialRowOptions"
          [materialWarning]="materialWarning"
          [isMobile]="isMobile"
          (quantityInputChanged)="handleQuantityInputChanged($event)"
        >
        </naoo-material-quantity-input>
      } @else {
        <span class="non-editable-quantity-content">
          {{
            !!currentOrderingInfo.line.quantity
              ? currentOrderingInfo.line.quantity
              : ''
          }}
        </span>
      }
    </div>
    @if (!isComplimentaryProduct) {
      <div class="total info-section" [ngClass]="{ mobile: isMobile }">
        {{ totalEstimatedCost | naooprice }}
      </div>
    }
  </div>
}

<ng-template #loyaltyPoints>
  @if (shouldDisplayLoyaltyPoints) {
    <span class="points info-section">
      {{ currentOrderingInfo.loyaltyPoints | naooloyalty }}
    </span>
  }
</ng-template>
