import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NaooAnalyticsManager } from 'src/app/shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from 'src/app/shared/analytics/analytics-event-info';
import { CustomerBrand } from '../../../core/services/session/models/session-record';
import { CartReview } from '../../../core/store/cart-review/cart-review.selectors';
import {
  CartReviewSection,
  CartReviewSectionGroup,
  CartReviewSectionName,
} from '../../../core/store/cart-review/cart-review.state';
import { Subject } from 'rxjs';
import { CartCounts } from '../../../core/store/cart/cart.selectors';
import { CartReviewStoreContainerComponent } from './cart-review-store-container/cart-review-store-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { EmailNotificationOptInComponent } from './email-notification-opt-in/email-notification-opt-in.component';
import { CartReviewTruckContainerComponent } from './cart-review-truck-container/cart-review-truck-container.component';

@Component({
  selector: 'naoo-cart-review-content',
  templateUrl: './cart-review-content.component.html',
  styleUrls: ['./cart-review-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CartReviewStoreContainerComponent,
    TranslateModule,
    EmailNotificationOptInComponent,
    CartReviewTruckContainerComponent,
  ],
})
export class CartReviewContentComponent implements OnDestroy {
  @Input() customerBrand: CustomerBrand;
  @Input() cartReview: CartReview;
  @Input() isLoading: boolean;

  @Input() cartReviewCartCounts: Map<CartReviewSectionName, CartCounts[]>;
  @Output() updateFooterVisibility = new EventEmitter<void>();
  @ViewChildren('expansionSections')
  expansionElements: QueryList<CartReviewTruckContainerComponent>;
  submitButtonClicked = false;
  destroyed$ = new Subject();

  constructor(private analyticsManager: NaooAnalyticsManager) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get hasOnlySuccessSection(): boolean {
    return (
      this.cartReview?.groups?.length === 1 &&
      this.cartReview.groups[0].name ===
        CartReviewSectionName.StandardItemsSection
    );
  }

  get truckGroups(): CartReviewSectionGroup[] {
    return this.cartReview?.groups.filter(
      (group) =>
        ![
          CartReviewSectionName.ExpressItemsSection,
          CartReviewSectionName.PickupItemsSection,
        ].includes(group.name),
    );
  }

  get storeGroups(): CartReviewSectionGroup[] {
    return this.cartReview?.groups.filter((group) =>
      [
        CartReviewSectionName.ExpressItemsSection,
        CartReviewSectionName.PickupItemsSection,
      ].includes(group.name),
    );
  }

  isSectionInvalid(section: CartReviewSection): boolean {
    return (
      CartReviewSectionName.DropShipItemsSection === section.name &&
      this.cartReview?.invalidDropShipOrders?.some(
        (ds) =>
          ds?.carrierFulfillment?.shipmentId ===
          section?.fulfillmentOrderSplit?.shipmentId,
      )
    );
  }

  handleSubmitButton() {
    this.submitButtonClicked = true;
    const firstElement = this.expandInvalidSectionsAndReturnFirst();
    firstElement?.scrollToContent();
  }

  private expandInvalidSectionsAndReturnFirst(): CartReviewTruckContainerComponent {
    let firstElement: CartReviewTruckContainerComponent = null;
    this.expansionElements?.forEach((element) => {
      if (this.isSectionInvalid(element.section)) {
        element.expandSection();
        if (!firstElement) {
          firstElement = element;
        }
      }
    });
    return firstElement;
  }

  trackLogoClick() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'general',
      label: 'logo',
    };

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  trackBySection(cartReviewSection: CartReviewSection): string {
    let sectionId = cartReviewSection.name + cartReviewSection.sectionCounter;
    if (CartReviewSectionName.DropShipItemsSection === cartReviewSection.name) {
      sectionId = sectionId + cartReviewSection.selectedSiteId;
    }
    return sectionId;
  }

  emitUpdateFooterVisibility() {
    this.updateFooterVisibility.emit();
  }

  getCartCounts(
    name: CartReviewSectionName,
    idx: number,
  ): CartCounts | undefined {
    return this.cartReviewCartCounts.get(name)?.[idx];
  }
}
