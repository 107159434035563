<ng-container>
  <div>
    <mat-label>{{ 'SHARED.LANGUAGE' | translate }}</mat-label>
    <mat-select
      class="language-dropdown"
      [(ngModel)]="selectedLanguage"
      (selectionChange)="onSelectionChange($event)"
    >
      @for (language of availableLanguages; track language) {
        <mat-option [value]="language">
          {{ language | translate }}
        </mat-option>
      }
    </mat-select>
  </div>
</ng-container>
