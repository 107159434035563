import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../../core/store/material-row/models/material-row';
import { MaterialListRow } from '../../../../material-list/models/material-list';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { ModalHeaderComponent } from '../../../../shared/modal-header/modal-header.component';
import { MaterialListComponent } from '../../../../material-list/material-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-overallocation-content',
  templateUrl: './material-overallocation-content.component.html',
  styleUrls: ['./material-overallocation-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalHeaderComponent, MaterialListComponent, TranslateModule],
})
export class MaterialOverallocationContentComponent
  implements OnDestroy, OnInit
{
  @Input() materialListRows: MaterialListRow[];
  @Input() hasOverallocatedCartMaterials: boolean;
  @Output() closeModalEmitter = new EventEmitter();

  readonly context = MaterialRowContext.OverallocationModal;
  readonly listStyle = MaterialListStyle.List;

  private destroyed$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(100), startWith(undefined), takeUntil(this.destroyed$))
      .subscribe(() => {
        document.documentElement.style.setProperty(
          '--vh',
          `${window.innerHeight}px`,
        );
      });
  }

  closeModal(): void {
    this.closeModalEmitter.emit();
  }
}
