import { LocalizationService } from '../../../../shared/services/translation/localization.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  signal,
} from '@angular/core';
import {
  convertLanguageToNotificationLanguage,
  CustomerNotification,
  NotificationLanguage,
  NotificationMethodCode,
  ReminderDetails,
  ReminderPreference,
} from '../../shared/models/customer-notifications';
import { ToastMessageService } from '../../../../shared/services/toast-message/toast-message.service';
import { Language } from '../../../../core/services/session/models/session-record';
import { NotificationSubscriptionType } from '../../../../core/services/customer-notification-preferences/models/customer-notification-preferences-record';
import { MatIcon } from '@angular/material/icon';
import { TelephoneFormControlComponent } from './telephone-form-control/telephone-form-control.component';
import { SubscriptionsFormComponent } from '../subscriptions-form/subscriptions-form.component';
import { ReminderPreferencesComponent } from './reminder-preferences/reminder-preferences.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationLanguageDropdownComponent } from '../../shared/notification-language-dropdown/notification-language-dropdown.component';

@Component({
  selector: 'naoo-preferences-row-editing-phone',
  templateUrl: './preferences-row-editing-phone.component.html',
  styleUrls: ['./preferences-row-editing-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    TelephoneFormControlComponent,
    SubscriptionsFormComponent,
    ReminderPreferencesComponent,
    NotificationLanguageDropdownComponent,
    TranslateModule,
  ],
})
export class PreferencesRowEditingPhoneComponent implements OnInit, OnChanges {
  @Input() notification: CustomerNotification;
  @Input() isRetalix: boolean;
  @Input() defaultLanguage: Language;
  @Input() methodCode: NotificationMethodCode;
  @Input() allValidNotificationSubscriptionTypes: string[];
  @Input() reminderDetails?: ReminderDetails;
  @Output() cancelRequest = new EventEmitter();
  @Output() saveRequest = new EventEmitter<CustomerNotification>();
  @Output() hasUnsavedChanges = new EventEmitter<boolean>();

  isPhoneValid: boolean;
  phoneInput: string;
  languageSelected = signal<NotificationLanguage>(null);
  subscriptionTypes: NotificationSubscriptionType[];
  currentCustomerReminders: ReminderPreference[];

  constructor(
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private changeDetectRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscriptionTypes = this.notification?.subscriptionTypes;
    this.currentCustomerReminders = this.notification?.customerReminders;
    this.languageSelected.set(
      this.notification?.language ||
        convertLanguageToNotificationLanguage(this.defaultLanguage),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultLanguage'] && !this.notification?.language) {
      this.languageSelected.set(
        convertLanguageToNotificationLanguage(this.defaultLanguage),
      );
    }
  }

  get isValid(): boolean {
    return this.isPhoneValid && (this.hasSubscriptions || this.hasReminders);
  }

  get hasSubscriptions(): boolean {
    return this.subscriptionTypes?.length > 0;
  }
  get hasReminders(): boolean {
    return this.currentCustomerReminders?.length > 0;
  }

  isValidHandler(event: boolean) {
    this.isPhoneValid = event;
    this.changeDetectRef.markForCheck();
  }

  valueHandler(event: string) {
    this.hasPendingChanges(!!this.phoneInput && this.phoneInput !== event);
    this.phoneInput = event;
  }

  subscriptionChanged(event: NotificationSubscriptionType[]) {
    this.hasPendingChanges(true);
    this.subscriptionTypes = event;
  }

  updateCustomerReminders(event: ReminderPreference[]) {
    this.hasPendingChanges(true);
    this.currentCustomerReminders = event;
  }

  setUserLanguage(language: NotificationLanguage): void {
    this.languageSelected.set(language);
  }

  cancel() {
    this.cancelRequest.emit();
    this.hasPendingChanges(false);
  }

  hasPendingChanges(value: boolean) {
    this.hasUnsavedChanges.emit(value);
  }

  save() {
    if (!this.isValid) {
      return;
    }
    const updatedNotification: CustomerNotification = {
      ordinal: this.notification.ordinal,
      contactInfo: this.phoneInput,
      name: '',
      language:
        this.languageSelected() ||
        convertLanguageToNotificationLanguage(this.defaultLanguage),
      editableStatus: this.notification.editableStatus,
      methodCode: this.methodCode,
      subscriptionTypes: this.subscriptionTypes,
      customerReminders: this.currentCustomerReminders,
    };
    this.saveRequest.emit(updatedNotification);
    this.hasPendingChanges(false);
    this.toastMessageService.showToastMessage(
      this.localizationService.instant(
        'PREFERENCES.SMS_NOTIFICATIONS.TOAST_MESSAGES.SAVE_SUCCESS_MESSAGE',
      ),
    );
  }
}
