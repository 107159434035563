<div class="main-container">
  <h2 class="title">{{ 'ROW_ERROR.TITLE' | translate }}</h2>
  <div class="info-container">
    <div class="error-description">
      {{
        (canAddInvalidRows
          ? 'ROW_ERROR.DESCRIPTION_WITH_INVALID'
          : 'ROW_ERROR.DESCRIPTION'
        ) | translate
      }}
    </div>
  </div>
  <ng-content></ng-content>
  @if (canAddInvalidRows) {
    <div class="invalid-rows-checkbox">
      <mat-checkbox
        class="naoo-checkbox"
        (change)="onIncludeInvalidRows($event.checked)"
      >
        {{ 'ROW_ERROR.INCLUDE_INVALID' | translate }}
      </mat-checkbox>
    </div>
  }
  <div class="button-row">
    <button
      data-test-id="import-row-error-start-over-button"
      class="secondary-button large-button start-over-button"
      (click)="onStartOver()"
    >
      {{ 'ROW_ERROR.START_OVER' | translate }}
    </button>
    <button
      data-test-id="import-row-error-import-button"
      class="primary-button large-button"
      (click)="onImport()"
      [disabled]="disableImportButton"
    >
      {{
        'ROW_ERROR.IMPORT_VALID_ORDERS'
          | translate
            : {
                valid_lines: validImportRowsCount,
                total_lines: totalImportRowsCount,
              }
      }}
    </button>
  </div>
</div>
