<div class="sort-by-wrapper naoo-material-cart-sort-by">
  <span class="sort-by-label" [ngClass]="{ disabled: disabled }">
    {{ 'SORT.BY' | translate }}:
  </span>
  <mat-form-field class="sort-by-form">
    <mat-select
      class="sort-by-select"
      [disabled]="disabled"
      [value]="selectedOption"
      (selectionChange)="changeSort($event)"
      [aria-label]="'SORT.BY' | translate"
    >
      @for (option of sortByOptions; track option.name) {
        <mat-option
          [value]="option.value"
          class="naoo-material-cart-sort-by-option"
        >
          {{ option.name | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
