import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SavedCartService } from '../shared/saved-cart.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-empty-saved-carts',
  templateUrl: './empty-saved-carts.component.html',
  styleUrls: ['./empty-saved-carts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class EmptySavedCartsComponent {
  constructor(private savedCartService: SavedCartService) {}

  openHelperModal(): void {
    this.savedCartService.helperModal();
  }
}
