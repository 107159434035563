import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OrderGuide } from 'src/app/core/store/order-guide/models/order-guide';
import { Observable } from 'rxjs';
import { OrderGuideFacade } from 'src/app/core/store/order-guide/order-guide.facade';
import { DeviceIdentifierService } from 'src/app/shared/services/device-identifier/device-identifier.service';
import { tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading-service/loading.service';
import { RouterExtrasService } from 'src/app/shared/services/router-extras/router-extras.service';
import { MaterialListRowType } from '../../../material-list/models/material-list';
import { LastOrderedFacade } from '../../../core/store/last-ordered/last-ordered.facade';
import { InventoryAvailabilityFacade } from '../../../core/store/inventory-availability/inventory-availability.facade';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { MaterialRowContext } from '../../../core/store/material-row/models/material-row';
import { OrderGuideContentComponent } from '../order-guide-content/order-guide-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-order-guide-container',
  template: `
    <naoo-order-guide-content
      [orderGuide]="orderGuide$ | async"
      [isMobile]="isMobile$ | async"
    >
    </naoo-order-guide-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrderGuideContentComponent, AsyncPipe],
})
export class OrderGuideContainerComponent implements OnInit, OnDestroy {
  orderGuide$: Observable<OrderGuide>;
  isMobile$: Observable<boolean>;

  private needsLoad = true;

  constructor(
    private orderGuideFacade: OrderGuideFacade,
    private loadingService: LoadingService,
    private deviceIdentifierService: DeviceIdentifierService,
    private routerExtras: RouterExtrasService,
    private lastOrderedFacade: LastOrderedFacade,
    private inventoryAvailabilityFacade: InventoryAvailabilityFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnInit() {
    this.loadingService.start();
    this.orderGuide$ = this.orderGuideFacade.getOrderGuide().pipe(
      tap((orderGuide) => {
        if (orderGuide) {
          this.loadingService.stop();

          if (
            this.routerExtras.getRestoreScrollPosition(window.location.href)
          ) {
            this.routerExtras.restoreScrollPosition(window.location.href);
          }

          this.loadData(orderGuide);
        }
      }),
    );

    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
  }

  ngOnDestroy() {
    this.loadingService.stop();
  }

  private loadData(guide: OrderGuide) {
    if (this.needsLoad) {
      this.needsLoad = false;
      const materialNumbers = guide.materialListRows
        .filter((row) => row.type === MaterialListRowType.MaterialRow)
        .map((row) => row.value as string);

      this.lastOrderedFacade.loadLastOrdered(materialNumbers);
      this.inventoryAvailabilityFacade.loadCartInventoryAvailability(
        materialNumbers,
      );
      this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
        materialNumbers,
        MaterialRowContext.OrderGuide,
      );
    }
  }
}
