import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { ActiveDefaultFilter } from 'src/app/core/store/search/shared/search-results';
import { MatIcon } from '@angular/material/icon';
import { NaooStringDefaulterPipe } from '../../../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-filter-chip',
  templateUrl: './filter-chip.component.html',
  styleUrls: ['./filter-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NaooStringDefaulterPipe],
})
export class FilterChipComponent {
  @Input() defaultFilter: ActiveDefaultFilter;

  constructor(private searchFacade: SearchFacade) {}

  clearFilter() {
    this.searchFacade.toggleDefaultFilter(
      this.defaultFilter.parentFilterGroupName,
      this.defaultFilter.queryParameterKey,
      this.defaultFilter.filter.name.en,
      this.defaultFilter.filter.queryParameterValue,
      false,
    );
  }
}
