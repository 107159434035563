import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '../../shared/models/order-confirmation';
import { NgTemplateOutlet } from '@angular/common';
import { OrderItemRowComponent } from '../../order-item-row/order-item-row.component';
import { NaooDatePipe } from '../../../shared/pipes/naoo-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NaooCurrencyPipe } from '../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-drop-ship-orders',
  templateUrl: './drop-ship-orders.component.html',
  styleUrls: ['./drop-ship-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    OrderItemRowComponent,
    NaooDatePipe,
    TranslateModule,
    NaooCurrencyPipe,
  ],
})
export class DropShipOrdersComponent {
  @Input() dropShipOrder: Order;
}
