@if (shouldShowFloatingFooter && !isQuickAddEnabled) {
  <naoo-cost-summary-content-floating-footer
    (submitButtonClick)="submitButtonClick.emit()"
    [costSummary]="costSummary"
    [isCreditPaymentLoading]="isCreditPaymentLoading"
    [isPoNumberInvalid]="isPoNumberInvalid"
  >
  </naoo-cost-summary-content-floating-footer>
}

<div class="cost-summary">
  <div #costSummaryWrapper class="cost-summary-wrapper">
    <div class="order-summary-header">
      {{ 'CART.ORDER_SUMMARY' | translate }}
    </div>
    @if (!!costSummary && !!costSummary.warning && isMobile) {
      <div class="cost-summary-warning-row" id="mobile-warning-row">
        <div class="cost-summary-warning-row-message">
          {{ costSummary.warning | translate }}
        </div>
      </div>
    }

    <div class="cost-summary-body">
      @if (!!costSummary) {
        <div class="cost-summary-info">
          @for (
            orderTotals of quantities;
            track trackByOrderSummary(orderTotals);
            let index = $index
          ) {
            <div class="cost-summary-quantity">
              @if (orderTotals.lines > 0) {
                <div class="cost-summary-quantity-title">
                  <div>
                    {{ orderTotals.title | translate: { count: index + 1 } }}
                  </div>
                  @if (!paymentReview && !!orderTotals.estimatedDeliveryDate) {
                    <div>
                      {{
                        orderTotals.estimatedDeliveryDate
                          | naooDate: 'monthDay' : ''
                      }}
                    </div>
                  }
                  @if (paymentReview && !!orderTotals.estimatedDeliveryDate) {
                    <div class="pickup-date">
                      {{
                        orderTotals.estimatedDeliveryDate
                          | naooDate: 'shortDayMonthDate' : ''
                      }}
                    </div>
                  }
                  @if (!orderTotals.estimatedDeliveryDate) {
                    <div>
                      <span class="no-date-placeholder">{{
                        orderTotals.placeholder
                      }}</span>
                    </div>
                  }
                </div>
              }
              @if (orderTotals.lines > 0) {
                <div class="cost-summary-item-counts">
                  <span>
                    {{
                      'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.TOTAL_LINES'
                        | translate
                          : {
                              lines: !!orderTotals ? orderTotals.lines : 0,
                            }
                    }},
                  </span>
                  <span>
                    {{
                      'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.TOTAL_ITEMS'
                        | translate
                          : {
                              items: !!orderTotals
                                ? orderTotals.totalQuantity
                                : 0,
                            }
                    }}
                  </span>
                </div>
              }
            </div>
          }
          <div class="cost-summary-cost">
            <div class="cost-summary-cost-title">
              {{ 'CART.ESTIMATED_COST' | translate }}
            </div>
            @if (!!costSummary) {
              <div class="cost-summary-quantity-cost">
                {{
                  standardOrderTotals.estimatedCost
                    | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
                }}
              </div>
            }
            @if (!!standardOrderTotals?.estimatedSavings) {
              <div class="cost-summary-savings-content">
                <div class="cost-summary-savings-title">
                  {{ 'CART_COUPON.YOU_SAVED' | translate }}
                </div>
                <div class="cost-summary-quantity-savings">
                  {{
                    standardOrderTotals.estimatedSavings
                      | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
                  }}
                </div>
              </div>
            }
          </div>
          @if (costSummary?.isLoyaltyProgramEligible) {
            <div class="cost-summary-points">
              <div class="cost-summary-points-title">
                {{ 'CART.HEADER.TOTAL' | translate }}
                <naoo-go-points-logo
                  [context]="'cost-summary-content'"
                ></naoo-go-points-logo>
              </div>
              <div class="cost-summary-points-content">
                {{ standardOrderTotals.totalLoyaltyPoints || '–' }}
              </div>
            </div>
          }
        </div>
      }

      @if (!!costSummary?.displayCouponContainer) {
        <naoo-cart-coupon-container></naoo-cart-coupon-container>
      }

      <div class="cost-summary-actions">
        @if (!!costSummary && !!costSummary.warning && !isMobile) {
          <div class="cost-summary-warning-row" id="warning-row">
            <div class="cost-summary-warning-row-message">
              {{ costSummary.warning | translate }}
            </div>
          </div>
        }
        <div class="buttons-container">
          <div class="step-button-container">
            @if (!!costSummary) {
              <naoo-order-submit-button-container
                (submitButtonClick)="submitButtonClick.emit()"
                [buttonState]="buttonState"
                [cartTotal]="standardOrderTotals.estimatedCost"
                [context]="costSummary.context"
                [isCreditPaymentLoading]="isCreditPaymentLoading"
                [isPoInvalid]="isPoNumberInvalid"
                [isPoRequired]="costSummary.shippingInfo.isPoRequired"
                [isPunchOut]="costSummary.isPunchOut"
                [hasIspuOrExpressEnabled]="costSummary.hasStoresPermissions"
                class="order-submit-button"
              >
              </naoo-order-submit-button-container>
            }
          </div>

          <button
            [disabled]="isCartSummaryContext && costSummary.isOffline"
            class="continue-shopping-link"
            id="continue-shopping-link-fixed"
            role="link"
            (click)="backLinkAction()"
          >
            {{ backLinkText | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
