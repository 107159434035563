import { Component, Input } from '@angular/core';
import { FilterRecordState } from 'src/app/core/store/search/search.state';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { DefaultFilterGroup } from '../../../../../core/store/search/shared/search-results';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgClass } from '@angular/common';
import { FilterComponent } from './filter/filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    NgClass,
    FilterComponent,
    TranslateModule,
    NaooStringDefaulterPipe,
  ],
})
export class FilterGroupComponent {
  @Input() filterGroup: DefaultFilterGroup;

  constructor(private searchFacade: SearchFacade) {}

  get filters(): FilterRecordState[] {
    const showAllFilters =
      !this.filterGroup.viewMoreThreshold || this.filterGroup.isViewingMore;

    const filters = showAllFilters
      ? this.filterGroup.filters
      : Array.from(Array(this.filterGroup.viewMoreThreshold))
          .map((_, index) => this.filterGroup.filters[index])
          .filter((i) => !!i);

    return filters.filter((group) => group.count > 0);
  }

  get canViewMore(): boolean {
    return (
      !!this.filterGroup.viewMoreThreshold &&
      this.filterGroup.filters.length > this.filterGroup.viewMoreThreshold
    );
  }

  viewMore() {
    this.searchFacade.viewMoreDefaultFilter(
      this.filterGroup.name.en,
      !this.filterGroup.isViewingMore,
    );
  }

  afterCollapse() {
    this.searchFacade.collapseDefaultFilter(this.filterGroup.name.en, true);
  }

  afterExpand() {
    this.searchFacade.collapseDefaultFilter(this.filterGroup.name.en, false);
  }
}
