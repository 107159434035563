<div class="add-to-guide-container">
  <div class="add-to-guide-text">
    {{ message | translate }}
    @if (!!customGuideName) {
      <strong>{{ customGuideName }}</strong>
    }
  </div>
  <button class="toast-button" (click)="navigate()">
    {{ 'LISTS.TOAST_GO' | translate }}
  </button>
</div>
