<div class="docket-header">
  <div class="docket-group">
    <ng-content></ng-content>
    <div class="docket-title-info">
      <span class="docket-title" tabindex="0">{{ title | translate }}</span>
      @if (!!tooltipKey) {
        <button
          class="info-button"
          mat-icon-button
          #tooltip="matTooltip"
          [matTooltip]="tooltipKey | translate"
          [matTooltipClass]="'docket-tooltip'"
          [matTooltipShowDelay]="50"
          (click)="manualTooltip.toggle()"
          (mouseover)="manualTooltip.show()"
        >
          <mat-icon class="docket-info">info</mat-icon>
        </button>
      }
    </div>
  </div>
  <button (click)="closeDocketEmitter.emit()" class="docket-close">
    <mat-icon [svgIcon]="'clear_icon'"></mat-icon>
  </button>
</div>
