<div class="main-container">
  <h2 class="title">{{ 'FILE_ERROR.TITLE' | translate }}</h2>
  <div class="info-container">
    <div class="description-container">
      <div class="error-info">
        <div class="error-description">
          {{ 'FILE_ERROR.DESCRIPTION' | translate }}
        </div>
        <span class="file-name">{{ fileName }}</span>
      </div>
      <div class="error-container">
        <div class="error-icon">
          <mat-icon svgIcon="warning-icon-v1"></mat-icon>
        </div>
        <span class="error-text">{{ fileError | translate }}</span>
      </div>
      <div class="requirements-container">
        <div class="requirements-text">
          {{ 'FILE_ERROR.DETAILS' | translate }}
        </div>
        <div class="requirements-information">
          <span class="requirements-title">{{
            'FILE_ERROR.REQUIREMENTS_TITLE' | translate
          }}</span>
          <ul class="requirements-list">
            @for (requirement of fileRequirements; track requirement) {
              <li class="requirement-description">
                {{ requirement | translate }}
              </li>
            }
          </ul>
          <div class="templates-container">
            <a
              id="csv-template-link"
              href="{{ csvTemplate | translate }}"
              download
              (click)="onTemplateDownload('csv')"
              >{{ 'FILE_ERROR.CSV_TEMPLATE_TEXT' | translate }}</a
            >
            <a
              id="excel-template-link"
              href="{{ excelTemplate | translate }}"
              download
              (click)="onTemplateDownload('excel')"
              >{{ 'FILE_ERROR.EXCEL_TEMPLATE_TEXT' | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="image-container">
      <img src="{{ errorImage | translate }}" alt="" />
    </div>
  </div>
  <div class="button-container">
    <button
      data-test-id="import-file-error-start-over-button"
      class="start-over-button primary-button large-button"
      (click)="onStartOver()"
    >
      {{ 'FILE_ERROR.START_OVER' | translate }}
    </button>
  </div>
</div>
