import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilterRecordState } from 'src/app/core/store/search/search.state';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCheckbox, TranslateModule, NaooStringDefaulterPipe],
})
export class FilterComponent {
  @Input() filterGroupName: string;
  @Input() filterGroupParam: string;
  @Input() filter: FilterRecordState;

  constructor(private searchFacade: SearchFacade) {}

  toggleCheckBox($event?: MatCheckboxChange) {
    this.searchFacade.toggleDefaultFilter(
      this.filterGroupName,
      this.filterGroupParam,
      this.filter.name.en,
      this.filter.queryParameterValue,
      $event ? $event.checked : !this.filter.isSelected,
    );
  }
}
