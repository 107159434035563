import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NutritionLabel } from 'src/app/core/store/nutrition/models/nutrition';
import { IngredientContentComponent } from './ingredient-content/ingredient-content.component';

@Component({
  selector: 'naoo-ingredient-list',
  template: ` <naoo-ingredient-content [nutritionLabel]="nutritionLabel">
  </naoo-ingredient-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IngredientContentComponent],
})
export class IngredientListComponent {
  @Input() materialNumber: string;
  @Input() nutritionLabel: NutritionLabel;
}
