import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProductInfo } from '../../shared/models/product-info';
import { NaooConstants } from '../../shared/NaooConstants';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { ProductMenuComponent } from '../product-menu/product-menu.component';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { CustomerMaterialFacade } from '../../core/store/customer-material/customer-material.facade';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { getCustomerMaterialNumberFromCustomerMaterialInfo } from '../../core/store/customer-material/utilities/customer-material.utilities';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import {
  MaterialInfo,
  MaterialUnitInfo,
} from '../../shared/models/material-info';
import { NaooStringDefaulter } from '../../shared/string-defaulter/naoo-string-defaulter';
import { LocalizationService } from '../../shared/services/translation/localization.service';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { ProductImageComponent } from '../../shared/product-image/product-image.component';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { PackSizePipe } from '../../shared/pipes/pack-size.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-product-details',
  templateUrl: './product-row-details.component.html',
  styleUrls: ['./product-row-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    NgTemplateOutlet,
    ProductMenuComponent,
    MatIcon,
    ProductImageComponent,
    NaooStringDefaulterPipe,
    PackSizePipe,
    TranslateModule,
  ],
})
export class ProductRowDetailsComponent implements OnInit, OnDestroy {
  static readonly productAnalyticsCategory = 'product details page';
  static readonly productAnalyticsAction = 'click';
  static readonly productImageLabel = 'product image link click';
  static readonly productDescriptionLabel = 'product description link click';

  @Input() isListView = true;
  @Input() product: ProductInfo;
  @Input() substituteMaterial: MaterialInfo;
  @Input() units: MaterialUnitInfo[];
  @Input() isCollapsed = true;
  @Input() isReviewOrderPage = false;
  @Input() isEditGuidePage = false;
  @Input() isCartReviewPage = false;
  @Input() isMobile = false;
  @ViewChild(ProductMenuComponent)
  gridViewMenu: ProductMenuComponent;

  @Output() productDetailClickEmitter = new EventEmitter<ProductInfo>();

  get imageDimension(): number {
    return this.isListView ? this.listImageSize : this.gridImageSize;
  }

  readonly image = 'IMAGE';
  readonly description = 'DESCRIPTION';
  readonly productDetailsPath = NaooConstants.PRODUCT_DETAILS_PATH;

  private readonly listImageSize = 35;
  private readonly gridImageSize = 135;

  private destroyed$ = new Subject<void>();

  customerMaterialNumber: string;
  isOffline = false;

  constructor(
    private analyticsManager: NaooAnalyticsManager,
    private customerMaterialFacade: CustomerMaterialFacade,
    private changeDetector: ChangeDetectorRef,
    private offlineModeFacade: OfflineModeFacade,
    private stringDefaulter: NaooStringDefaulter,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit() {
    this.customerMaterialFacade
      .getLoadedCustomerMaterialInfo(this.product.offeringId)
      .pipe(
        map((customerMaterialInfo) =>
          getCustomerMaterialNumberFromCustomerMaterialInfo(
            customerMaterialInfo,
          ),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((customerMaterialNumber) => {
        this.customerMaterialNumber = customerMaterialNumber;
        this.changeDetector.markForCheck();
      });

    this.offlineModeFacade
      .getIsOffline()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isOffline) => {
        this.isOffline = isOffline;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getSubstitutedItemDescription(): string {
    const description = this.stringDefaulter.getString(
      this.substituteMaterial.description,
      this.localizationService.currentLanguage,
    );
    return `${description} | #${this.substituteMaterial.materialNumber}`;
  }

  sendClickAnalytics(source: string) {
    let label: string;
    if (source === this.image) {
      label = ProductRowDetailsComponent.productImageLabel;
    } else if (source === this.description) {
      label = ProductRowDetailsComponent.productDescriptionLabel;
    }

    const eventInfo: AnalyticsEventInfo = {
      action: ProductRowDetailsComponent.productAnalyticsAction,
      category: ProductRowDetailsComponent.productAnalyticsCategory,
      label: label,
    };

    this.productDetailClickEmitter.emit(this.product);
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }
}
