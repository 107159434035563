<div class="standard-orders">
  <div class="standard-orders-header">
    <div class="standard-orders-header-title">
      <span class="standard-order-items">
        @if (isSplitOrder) {
          <span>
            {{ stockOrder.labelsAndDates.splitOrderTitle | translate }}&nbsp;
            {{ orderIndexNumber }}&nbsp;
          </span>
        } @else {
          <span>
            {{ stockOrder.labelsAndDates?.orderTitle | translate }}
          </span>
        }
      </span>
      <span class="items-in-order">({{ stockOrder.totalQuantity }})</span>
    </div>
    <div class="standard-orders-header-details">
      <div class="header-detail">
        <div class="header-detail-label">
          {{ 'ORDER_CONFIRMATION.HEADER.ORDER_NUMBER' | translate }}&nbsp;
        </div>
        <div class="header-detail-data">{{ stockOrder.orderNumber }}</div>
      </div>
      @if (!!stockOrder.poNumber) {
        <div class="header-detail">
          <div class="header-detail-label">
            {{ 'ORDER_CONFIRMATION.HEADER.PO_NUMBER' | translate }}:&nbsp;
          </div>
          <div class="header-detail-data order-po-number">
            {{ stockOrder.poNumber }}
          </div>
        </div>
      }
      <div class="header-detail">
        <div class="header-detail-label">
          {{ stockOrder.labelsAndDates?.deliveryDateLabel | translate }}&nbsp;
        </div>
        <div>
          <div class="header-detail-data date-split-line">
            {{ stockOrder.labelsAndDates?.deliveryDate | stringdefaulter }}
          </div>
          <div class="header-detail-data time-split-line">
            {{ stockOrder.labelsAndDates?.deliveryTime | stringdefaulter }}
          </div>
          <div class="header-detail-data date-single-line">
            {{ stockOrder.labelsAndDates?.deliveryDate | stringdefaulter }}
            {{ stockOrder.labelsAndDates?.deliveryTime | stringdefaulter }}
          </div>
        </div>
      </div>
      <div class="header-detail">
        <div class="header-detail-label">
          {{ 'ORDER_CONFIRMATION.ESTIMATED_TOTAL' | translate }}&nbsp;
        </div>
        <div class="header-detail-data">
          {{
            stockOrder.estimatedTotal
              | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
          }}
        </div>
      </div>
    </div>
  </div>

  <div class="table-headers">
    <div class="left-header">
      {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.ITEM' | translate }}
    </div>
    <div class="right-headers">
      <div class="right-header text-left short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.SIZE' | translate }}
      </div>
      <div class="right-header text-right">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.PRICE' | translate }}
      </div>
      <div class="right-header text-center short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.QUANTITY' | translate }}
      </div>
      <div class="right-header short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
      </div>
    </div>
  </div>
  @for (item of stockOrder.items; track item; let isLast = $last) {
    <naoo-order-item-row
      class="order-item-row"
      [orderItem]="item"
      [isLastRow]="isLast"
    ></naoo-order-item-row>
  }
</div>
