<div
  class="order-item-container"
  [ngClass]="{ 'savings-line': !!orderItem.itemDetail.totalDiscountAmount }"
>
  @if (!!orderItem.itemDetail.totalDiscountAmount) {
    <div class="line-item-savings">
      {{ 'ORDERS.DETAILS.SAVED' | translate }}
      {{ orderItem.itemDetail.totalDiscountAmount | naooprice }}
    </div>
  }
  <div class="order-item-wrapper">
    <naoo-confirmation-material-details
      class="material-details-info"
      [itemDetail]="orderItem.itemDetail"
    >
    </naoo-confirmation-material-details>

    @if (orderItem) {
      <div class="order-details">
        @for (
          orderLine of orderItem.orderLines;
          track orderLine;
          let isLast = $last
        ) {
          <div class="order-line-row">
            @if (isExpandedView) {
              <div class="column align-left order-issues">
                @for (
                  error of orderLine.lineStatusDetails;
                  track error;
                  let isLast = $last
                ) {
                  <div
                    class="order-issue-line"
                    [ngClass]="{ 'last-exception': isLast }"
                  >
                    <div
                      class="line-status tooltip"
                      [attr.tooltip]="getToolTipText(error)"
                      [attr.aria-label]="getToolTipText(error)"
                      [attr.tabindex]="getToolTipTabIndex(error)"
                    >
                      <mat-icon
                        class="severity-icon"
                        [ngClass]="error.lineStatusClass"
                        [svgIcon]="error.icon"
                      ></mat-icon>
                      <div
                        class="order-issue-text-container"
                        [ngClass]="error.lineStatusClass"
                      >
                        @if (error.primaryLineStatus) {
                          <div class="order-issue-primary-text">
                            {{ error.primaryLineStatus | stringdefaulter }}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            <div class="column align-left short-value">
              {{ { uomCode: orderLine.displayCode } | materialunits }}
            </div>
            <div class="column align-center order-quantity">
              {{ orderLine.quantityOrdered }}
            </div>
            <div
              class="quantity-shipped"
              [ngClass]="orderLine.quantityConfirmedClass"
            >
              <mat-icon
                class="severity-icon"
                [ngClass]="orderLine.quantityConfirmedClass"
                [svgIcon]="orderLine.quantityConfirmedIcon"
              ></mat-icon>
              {{ orderLine.quantityConfirmed }}
            </div>
            <div class="column align-right short-value">
              {{ orderLine.totalLinePrice | naooprice }}
            </div>
          </div>
        }
      </div>
    }
    @if (isExpandedView) {
      <div class="column find-related">
        <button
          class="find-substitutes-button"
          [attr.tabindex]="(hasRelated$ | async) ? 0 : null"
          [disabled]="!(hasRelated$ | async)"
          [ngClass]="{ disabled: !(hasRelated$ | async) }"
          (click)="openMaterialSubstitutesModal()"
        >
          <mat-icon class="find-replace-icon" svgIcon="find_replace"></mat-icon>
          <span>{{ 'PRODUCT.SUBSTITUTES.BUTTON' | translate }}</span>
        </button>
      </div>
    }

    <div class="order-details-mobile">
      @if (isExpandedView) {
        <div
          class="order-details-row order-issue-row"
          [ngClass]="{
            'single-order-issue-row': orderItem.orderLines.length === 1,
          }"
        >
          <div
            class="order-details-row-label"
            [ngClass]="{
              'multi-line-label': orderItem.orderLines.length > 1,
            }"
          >
            {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.STATUS' | translate }}
          </div>
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="order-details-row-data">
              <div
                class="order-issues-container"
                [ngClass]="{
                  'order-issue-vertically-padded': !isLast,
                }"
              >
                @if (orderItem.orderLines.length > 1) {
                  <div class="order-details-row-label uom-label">
                    {{
                      {
                        uomCode: orderLine.displayCode,
                        quantity: orderLine.quantityOrdered,
                      } | materialunits: false
                    }}
                  </div>
                }
                <div class="order-issue-lines">
                  @for (
                    issueItem of orderLine.lineStatusDetails;
                    track $index
                  ) {
                    <div class="right-column">
                      <div
                        class="order-issue-line"
                        [ngClass]="{
                          'multi-order-issue-line':
                            orderLine.lineStatusDetails.length > 1,
                        }"
                        [attr.tooltip]="getToolTipText(issueItem)"
                        [attr.aria-label]="getToolTipText(issueItem)"
                        [attr.tabindex]="getToolTipTabIndex(issueItem)"
                      >
                        <mat-icon
                          class="severity-icon"
                          [ngClass]="issueItem.lineStatusClass"
                          [svgIcon]="issueItem.icon"
                        ></mat-icon>
                        <div
                          class="order-issue-text-container"
                          [ngClass]="issueItem.lineStatusClass"
                        >
                          @if (issueItem.primaryLineStatus) {
                            <div class="order-issue-primary-text">
                              {{
                                issueItem.primaryLineStatus | stringdefaulter
                              }}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }
      <div class="order-details-row">
        <div class="order-details-row-label">
          {{
            'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.QUANTITY_ORDERED' | translate
          }}
        </div>
        <div class="order-details-row-data">
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="right-column">
              <span
                >{{
                  {
                    uomCode: orderLine.displayCode,
                    quantity: orderLine.quantityOrdered,
                  } | materialunits
                }}
              </span>
              @if (!isLast) {
                <span class="quantity-separator"> | </span>
              }
            </div>
          }
        </div>
      </div>
      <div class="order-details-row icon-row">
        <div class="order-details-row-label">
          {{
            'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.QUANTITY_SHIPPED' | translate
          }}
        </div>
        <div class="order-details-row-data quantity-shipped">
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="right-column">
              <span
                class="quantity-row"
                [ngClass]="orderLine.quantityConfirmedClass"
              >
                <mat-icon
                  class="severity-icon"
                  [ngClass]="orderLine.quantityConfirmedClass"
                  [svgIcon]="orderLine.quantityConfirmedIcon"
                ></mat-icon>
                <span class="order-issue-text">
                  {{
                    {
                      uomCode: orderLine.displayCode,
                      quantity: orderLine.quantityConfirmed,
                    } | materialunits
                  }}
                </span>
                @if (!isLast) {
                  <span class="quantity-separator"> | </span>
                }
              </span>
            </div>
          }
        </div>
      </div>
      <div class="order-details-row" [ngClass]="{ 'last-row': isExpandedView }">
        <div class="order-details-row-label">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
        </div>
        <div class="order-details-row-data">
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="right-column">
              <span class="total-price-row"
                >{{
                  {
                    uomCode: orderLine.displayCode,
                    quantity: orderLine.quantityConfirmed,
                  } | materialunits: false
                }}:
                {{ orderLine.totalLinePrice | naooprice }}
              </span>
              @if (!isLast) {
                <span class="quantity-separator"> | </span>
              }
            </div>
          }
        </div>
      </div>
      @if (isExpandedView) {
        <div class="last-row order-details-row find-related">
          <button
            class="find-substitutes-button"
            [attr.tabindex]="(hasRelated$ | async) ? 0 : null"
            [disabled]="!(hasRelated$ | async)"
            [ngClass]="{ disabled: !(hasRelated$ | async) }"
            (click)="openMaterialSubstitutesModal()"
            role="button"
          >
            <mat-icon
              class="find-replace-icon"
              svgIcon="find_replace"
            ></mat-icon>
            <span>{{ 'PRODUCT.SUBSTITUTES.BUTTON' | translate }}</span>
          </button>
        </div>
      }
    </div>
  </div>
</div>
