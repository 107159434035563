import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-info-unavailable',
  templateUrl: './info-unavailable.component.html',
  styleUrls: ['./info-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class InfoUnavailableComponent {
  constructor(private router: Router) {}

  onViewGuides() {
    this.router.navigate(['guides']);
  }

  onViewOrderGuide() {
    this.router.navigate(['guides', 'order-guide']);
  }
}
