<div [ngClass]="{ 'grid-details': !isListView, 'list-details': isListView }">
  <div
    class="product"
    [ngClass]="{
      'clickable-item': !product.isEmpty && !isEditGuidePage && !isOffline,
    }"
  >
    @if (!product.isEmpty && !isReviewOrderPage && !isEditGuidePage) {
      @if (!isOffline) {
        <a
          class="product-image"
          [routerLink]="[productDetailsPath, product.offeringId]"
          (click)="sendClickAnalytics(image)"
          [attr.aria-label]="product.description | stringdefaulter"
        >
          <ng-container [ngTemplateOutlet]="productImage"></ng-container>
        </a>
      } @else {
        <div
          class="product-image disabled-link"
          [attr.aria-label]="product.description | stringdefaulter"
        >
          <ng-template [ngTemplateOutlet]="productImage"></ng-template>
        </div>
      }
    } @else {
      <div
        class="product-image"
        [ngClass]="{ draggable: isEditGuidePage && !isMobile }"
        [attr.aria-label]="product.description | stringdefaulter"
      >
        <ng-container [ngTemplateOutlet]="productImage"></ng-container>
      </div>
    }

    <div class="product-details">
      <div [ngClass]="{ 'row-flex': !isListView }">
        @if (!product.isEmpty && !isReviewOrderPage && !isEditGuidePage) {
          @if (!isOffline) {
            <a
              [ngClass]="{ 'desc-width': !isListView }"
              [attr.aria-label]="product.description | stringdefaulter"
              role="link"
              [routerLink]="[productDetailsPath, product.offeringId]"
              (click)="sendClickAnalytics(description)"
            >
              <ng-template
                [ngTemplateOutlet]="productDescription"
              ></ng-template>
            </a>
          } @else {
            <div
              class="disabled-link"
              [ngClass]="{ 'desc-width': !isListView }"
              [attr.aria-label]="product.description | stringdefaulter"
            >
              <ng-template
                [ngTemplateOutlet]="emptyProductDescription"
              ></ng-template>
            </div>
          }
        } @else {
          <div
            [ngClass]="{
              draggable: isEditGuidePage && !isMobile,
              'col-md-11': !isListView,
            }"
            [attr.aria-label]="product.description | stringdefaulter"
          >
            <ng-template [ngTemplateOutlet]="productDescription"></ng-template>
          </div>
        }

        <div [ngClass]="{ 'grid-padding': !isListView }">
          @if (!isListView) {
            <naoo-product-menu [product]="product"></naoo-product-menu>
          }
        </div>
      </div>
      @if (substituteMaterial) {
        <div
          tabindex="0"
          class="substituted-message tooltip"
          [attr.tooltip]="getSubstitutedItemDescription()"
          [attr.aria-label]="getSubstitutedItemDescription()"
        >
          <mat-icon svgIcon="change_circle" class="substitute-icon"></mat-icon>
          <div class="order-issue-text-container">
            {{ 'ORDERS.DETAILS.SUBSTITUTE_MATERIAL' | translate }}
          </div>
        </div>
      }
    </div>
  </div>
</div>

<ng-template #productImage>
  <naoo-product-image
    [materialNumber]="product?.offeringId"
    [dimension]="imageDimension"
    [alt]="product.description | stringdefaulter"
    [isDraggable]="!isMobile && isEditGuidePage"
  ></naoo-product-image>
</ng-template>

<ng-template #emptyProductDescription>
  <div
    [ngClass]="{
      draggable: isEditGuidePage && !isMobile,
      'col-md-11': !isListView,
    }"
    [attr.aria-label]="product.description | stringdefaulter"
  >
    <ng-template [ngTemplateOutlet]="productDescription"></ng-template>
  </div>
</ng-template>

<ng-template #productDescription>
  <div
    class="description"
    [ngClass]="{
      'description-hover': !product.isEmpty && !isEditGuidePage,
      clamp: isCollapsed,
      'truncate-text': !isListView,
      'empty-product': product.isEmpty,
      draggable: isEditGuidePage && !isMobile,
      'adjust-line-height': isCartReviewPage,
      'disabled-link': isOffline,
    }"
  >
    {{ product.description | stringdefaulter }}
  </div>
  <div class="details" [ngClass]="{ clamp: isCollapsed }">
    <span class="sub-text">
      <span
        class="offering-id"
        [ngClass]="{ draggable: isEditGuidePage && !isMobile }"
      >
        @if (customerMaterialNumber) {
          {{ customerMaterialNumber }} :
        }
        #{{ product.offeringId }}
      </span>
      @if (product.brand | stringdefaulter) {
        <span
          class="brand"
          [ngClass]="{ draggable: isEditGuidePage && !isMobile }"
        >
          | {{ product.brand | stringdefaulter }}
        </span>
      }
    </span>
    @if (units) {
      <span
        class="pack-size"
        [ngClass]="{ draggable: isEditGuidePage && !isMobile }"
      >
        | {{ units | packsize: product.innerPackSize }}
      </span>
    }
  </div>
</ng-template>
