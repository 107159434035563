import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SearchResults } from '../../../../core/store/search/shared/search-results';
import { MaterialListStyle } from '../../../../core/store/material-row/models/material-row';
import { AnalyticsEventInfo } from '../../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../../shared/analytics/NaooAnalyticsManager';
import { CartFacade } from '../../../../core/store/cart/cart.facade';
import { FulfillmentType } from '../../../../core/services/cart/models/cart-record';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoriesBreadcrumbsComponent } from '../categories-breadcrumbs/categories-breadcrumbs.component';
import { SearchTextComponent } from '../search-text/search-text.component';
import { SearchRecommendationsContainerComponent } from '../search-recommendations-container/search-recommendations-container.component';
import { MaterialViewSelectComponent } from '../../../../shared/material-view-select/material-view-select.component';
import { AvailableTodayFilterComponent } from '../available-today-filter/available-today-filter.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CategoriesBreadcrumbsComponent,
    SearchTextComponent,
    SearchRecommendationsContainerComponent,
    MaterialViewSelectComponent,
    AvailableTodayFilterComponent,
    TranslateModule,
  ],
})
export class SearchHeaderComponent implements OnDestroy, OnInit {
  @Input() searchResult: SearchResults;
  @Input() isMobile: boolean;
  @Output() openFilters = new EventEmitter<boolean>();
  @Input() isOrderGuideTab: boolean;

  private destroyed$ = new Subject<void>();
  private fulfillmentType: FulfillmentType;

  readonly viewOptions = [
    MaterialListStyle.Slim,
    MaterialListStyle.List,
    MaterialListStyle.Grid,
  ];

  constructor(
    private naooAnalyticsManager: NaooAnalyticsManager,
    private cartFacade: CartFacade,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.cartFacade
      .getSelectedFulfillmentType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((cartFulfillment) => {
        this.fulfillmentType = cartFulfillment;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openFilterModal() {
    this.openFilters.emit(true);
  }

  get shouldShowBreadcrumbs() {
    return this.hasResults && !!this.searchResult.resultSet.categoryLinks;
  }

  get shouldShowSearchText() {
    return (
      this.hasResultSet() &&
      !!this.searchResult.searchText &&
      !this.searchResult.hideSearchText
    );
  }

  get hasResults(): boolean {
    return (
      this.hasResultSet() && this.searchResult.resultSet.totalResults !== 0
    );
  }

  get isAvailableFulfillment(): boolean {
    return (
      [FulfillmentType.PICKUP, FulfillmentType.EXPRESS].includes(
        this.fulfillmentType,
      ) && !!this.searchResult.availableTodayFilter
    );
  }

  get shouldRenderRecommendationBanner(): boolean {
    return (
      this.hasResultSet() &&
      this.searchResult.resultSet.sponsoredRecommendations?.banners.length > 0
    );
  }

  materialViewChange(viewType: MaterialListStyle) {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'search results',
      label: `${viewType} view`,
    };
    this.naooAnalyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private hasResultSet(): boolean {
    return !!this.searchResult && !!this.searchResult.resultSet;
  }
}
