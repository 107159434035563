import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorePurchaseDetailsActions } from './store-purchase-details.actions';
import { StorePurchaseKey } from '../../../shared/models/store-purchase-key';
import { selectStorePurchaseDetailsViewModel } from './store-purchase-details.selectors';
import { Observable } from 'rxjs';
import { StorePurchaseDetailsViewModel } from './models/store-purchase-details-view-model';

@Injectable({ providedIn: 'root' })
export class StorePurchaseDetailsFacade {
  constructor(private store: Store) {}

  loadStorePurchaseDetails(storePurchaseHistoryKey: StorePurchaseKey): void {
    this.store.dispatch(
      StorePurchaseDetailsActions.loadStorePurchaseDetails(
        storePurchaseHistoryKey,
      ),
    );
  }

  getStorePurchaseDetailsViewModel(
    storePurchaseHistoryKey: StorePurchaseKey,
  ): Observable<StorePurchaseDetailsViewModel> {
    return this.store.select(
      selectStorePurchaseDetailsViewModel(storePurchaseHistoryKey),
    );
  }
}
