import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CostSummary, OrderSummary } from '../shared/cost-summary';
import { PrintHeaderComponent } from '../../shared/print-header/print-header.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { NaooCurrencyPipe } from '../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-cost-summary-print-content',
  templateUrl: './cost-summary-print-content.component.html',
  styleUrls: ['./cost-summary-print-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PrintHeaderComponent,
    NgTemplateOutlet,
    NgClass,
    MatIcon,
    TranslateModule,
    NaooDatePipe,
    NaooCurrencyPipe,
  ],
})
export class CostSummaryPrintContentComponent {
  @Input() costSummary: CostSummary;

  get standardOrderTotals(): OrderSummary {
    return this.costSummary?.quantities?.find(
      (summary) => summary.orderType == null,
    );
  }
}
