import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SearchResults } from '../../../core/store/search/shared/search-results';
import { MaterialRowContext } from '../../../core/store/material-row/models/material-row';
import { MaterialComparisonFacade } from 'src/app/core/store/material-comparison/material-comparison.facade';
import { HeaderHeightService } from '../../../shared/services/header-height/header-height.service';
import { Observable } from 'rxjs';
import { NaooLocalStorage } from '../../../shared/storage/local-storage/local-storage.service';
import { NaooConstants } from '../../../shared/NaooConstants';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { SearchFacade } from '../../../core/store/search/search.facade';
import { OrderGuideFilterOption } from '../../../core/services/search/models/search-record';
import { SearchConstants } from '../../../shared/models/search/SearchConstants';
import { MobileFilterListComponent } from './search-filters/mobile-filter-list/mobile-filter-list.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AsyncPipe, NgClass } from '@angular/common';
import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { SearchContentComponent } from './search-content/search-content.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-search-results-content',
  templateUrl: './search-results-content.component.html',
  styleUrls: ['./search-results-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MobileFilterListComponent,
    MatSidenavModule,
    NgClass,
    SearchFiltersComponent,
    MatIcon,
    MatTabsModule,
    SearchContentComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SearchResultsContentComponent implements OnInit, OnDestroy {
  @Input() searchResult: SearchResults;
  @Input() isMobile: boolean;

  @Output() fetchMoreMaterials: EventEmitter<boolean> = new EventEmitter();

  readonly context = MaterialRowContext.Browse;
  // changes after the drawer completes an open/closed animation
  drawerClosedAndFinishedAnimating = true;
  sideNavOpen = true;
  isDrawerAnimationEnabled = false;
  isShowingMobileFilters = false;
  headerHeight$: Observable<number>;

  activeTabIndex$ = this.activatedRoute.queryParams.pipe(
    map((routerParams) => {
      return routerParams.onGuide ? 1 : 0;
    }),
  );

  constructor(
    private materialComparisonFacade: MaterialComparisonFacade,
    private headerHeightService: HeaderHeightService,
    private localStorage: NaooLocalStorage,
    private searchFacade: SearchFacade,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.headerHeight$ = this.headerHeightService.observeHeaderHeight();
    this.sideNavOpen = this.getSavedMenuState();
  }

  ngOnDestroy() {
    this.materialComparisonFacade.forceHideMaterialComparisonDocket(false);
  }

  get allResultsCount(): number | undefined {
    return this.getOrderGuideFilterOption(
      SearchConstants.orderGuideFilterAllResultsId,
    )?.count;
  }

  get orderGuideCount(): number | undefined {
    return this.getOrderGuideFilterOption(
      SearchConstants.orderGuideFilterOrderGuideId,
    )?.count;
  }

  toggleDrawer() {
    this.enableDrawerAnimation(true);
    this.sideNavOpen = !this.sideNavOpen;
    this.localStorage.setItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
      '' + this.sideNavOpen,
    );
    this.enableDrawerAnimation(false);
  }

  enableDrawerAnimation(isEnabled: boolean) {
    if (isEnabled) {
      this.isDrawerAnimationEnabled = true;
    } else {
      setTimeout(() => (this.isDrawerAnimationEnabled = false), 100);
    }
  }

  drawerStateChangeCompleted() {
    this.drawerClosedAndFinishedAnimating = !this.sideNavOpen;
  }

  handleEndOfList() {
    if (!!this.searchResult && this.searchResult.hasLoaded) {
      this.fetchMoreMaterials.emit(true);
    }
  }

  openFilterModal() {
    this.materialComparisonFacade.forceHideMaterialComparisonDocket(true);
    this.isShowingMobileFilters = true;
  }

  closeMobileFilters() {
    this.materialComparisonFacade.forceHideMaterialComparisonDocket(false);
    this.isShowingMobileFilters = false;
  }

  selectedIndexChanged(index: number): void {
    this.searchFacade.toggleOrderGuideFilter(!!index);
  }

  get hasResults(): boolean {
    return (
      this.hasResultSet() && this.searchResult.resultSet.totalResults !== 0
    );
  }

  get shouldRenderSearchList(): boolean {
    return (
      this.hasResults && this.searchResult.resultSet.materialNumbers?.length > 0
    );
  }

  get isOrderGuideOptionSelected(): boolean {
    return !!this.searchResult?.orderGuideFilter?.isOrderGuideOptionSelected;
  }

  get expansionAriaText(): string {
    return this.sideNavOpen
      ? 'LISTS.FILTERS.COLLAPSE_MENU'
      : 'LISTS.FILTERS.EXPAND_MENU';
  }

  private getSavedMenuState(): boolean {
    const savedState = this.localStorage.getItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
    );
    return savedState == null || savedState === 'true';
  }

  private hasResultSet(): boolean {
    return !!this.searchResult && !!this.searchResult.resultSet;
  }

  private getOrderGuideFilterOption(
    id: number,
  ): OrderGuideFilterOption | undefined {
    return this.searchResult?.orderGuideFilter?.record?.options?.find(
      (option) => option.id === id,
    );
  }
}
