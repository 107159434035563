<div class="image-container">
  <mat-icon class="error-icon" svgIcon="full-error"></mat-icon>
</div>
<div class="title-container" id="offlineError">
  <h2 class="main-title">{{ 'OFFLINE_ERROR.TITLE' | translate }}</h2>
</div>
@if (isCustomerUnitSelected$ | async) {
  <div class="info-container">
    <div class="info-block">
      <div class="block-marker"></div>
      <div class="content-block">
        <p
          class="offline-description"
          [innerHTML]="'OFFLINE_ERROR.DESCRIPTION' | translate"
        ></p>
        <div class="offline-navigation">
          <a [routerLink]="'/guides'" role="button" class="nav-button">
            <span>
              {{ 'OFFLINE_ERROR.GUIDES' | translate }}
            </span>
          </a>
          <span class="link-separator"></span>
          <a [routerLink]="'/cart'" role="button" class="nav-button">
            <span>
              {{ 'OFFLINE_ERROR.VIEW_ORDER' | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
}
