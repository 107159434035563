import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-move-to-category-toast',
  templateUrl: './move-to-category-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class MoveToCategoryToastComponent implements OnInit {
  protected categoryName: string;
  protected productSuccessfullyMoved: boolean;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private readonly data: { categoryName: string; success: boolean },
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.categoryName = this.data.categoryName;
    this.productSuccessfullyMoved = this.data.success;
    this.changeDetectorRef.markForCheck();
  }
}
