import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ProductDirection } from './product-direction';
import { ProductActionInfo } from './product-action';
import { naooAnimations } from '../../shared/animations/animations';
import { GuideAnimations } from '../../shared/models/guide-animations';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  ProductFlag,
  ProductFlagComponent,
} from '../product-flag/product-flag.component';
import { ProductFlagService } from '../../shared/services/product-flag/product-flag.service';
import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { GuideItem } from '../../shared/models/guide-item';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { MaterialAvailabilityFacade } from '../../core/store/material-availability/material-availability.facade';
import { MaterialAvailability } from '../../shared/models/material-availability';
import { CustomGuideMaterialParOrder } from '../../core/store/custom-guide/custom-guide.selectors';
import { ListsAnalyticsConstants } from '../../lists/lists-analytics.constants';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import {
  MaterialInfo,
  MaterialUnitInfo,
} from '../../shared/models/material-info';
import { NgClass, AsyncPipe } from '@angular/common';
import { ProductRowDetailsComponent } from '../product-row-details/product-row-details.component';
import { ProductParOrderQuantityBoxComponent } from '../product-par-order-quantity-box/product-par-order-quantity-box.component';
import { ProductEditMenuComponent } from '../product-edit-menu/product-edit-menu.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-product-guide-edit',
  templateUrl: './product-guide-edit.component.html',
  styleUrls: ['./product-guide-edit.component.scss'],
  animations: [naooAnimations.moveRow, naooAnimations.highlightRow],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ProductRowDetailsComponent,
    ProductFlagComponent,
    ProductParOrderQuantityBoxComponent,
    ProductEditMenuComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class ProductGuideEditComponent implements OnInit, OnDestroy {
  @Input() customGuideId?: string;
  @Input() isOrderGuide = false;
  @Input() sortBy: string;
  @Input() groupBy: string;
  @Input() guideItem: GuideItem;
  @Input() isUpActive: boolean;
  @Input() isDownActive: boolean;
  @Input() enableParOrdering: boolean;
  @Input() materialParOrder: CustomGuideMaterialParOrder;
  @Input() isGfsCategory: boolean;
  @Output()
  moveMaterial = new EventEmitter<{
    materialNumber: string;
    direction: ProductDirection;
  }>();

  @Output() actOnProduct = new EventEmitter<ProductActionInfo>();

  readonly ORDER_GUIDE_FLAG = 'Order Guide';

  get shouldDisplayPar(): boolean {
    return !this.isOrderGuide;
  }

  moveRowState: string;
  highlightedRowState: string;
  isMobile: boolean;
  materialAvailability: MaterialAvailability;
  primaryMaterialUnitInfo: MaterialUnitInfo;
  secondaryMaterialUnitInfo: MaterialUnitInfo;

  destroyed$ = new Subject<void>();
  productFlags$: Observable<ProductFlag[]>;
  private isOrderGuideItem = false;
  private materialInfo: MaterialInfo;

  constructor(
    private deviceIdentifierService: DeviceIdentifierService,
    private orderGuideFacade: OrderGuideFacade,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private productFlagService: ProductFlagService,
    private localizationService: LocalizationService,
    private naooAnalyticsManager: NaooAnalyticsManager,
    private materialInfoFacade: MaterialInfoFacade,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      });

    if (
      this.guideItem &&
      this.guideItem.product &&
      !this.guideItem.product.isEmpty
    ) {
      this.orderGuideFacade
        .isInOrderGuide(this.guideItem.product.offeringId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((isInOrderGuide) => {
          this.isOrderGuideItem = isInOrderGuide;
          this.loadProductFlags();
          this.changeDetectorRef.markForCheck();
        });

      this.materialAvailabilityFacade
        .getLoadedMaterialAvailability(this.guideItem.product.offeringId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((availability: MaterialAvailability) => {
          this.materialAvailability = availability;
          this.loadProductFlags();
          this.setMaterialUnitInfo();
          this.changeDetectorRef.markForCheck();
        });

      this.materialInfoFacade
        .getLoadedMaterialInfos([this.guideItem.product.offeringId])
        .pipe(takeUntil(this.destroyed$))
        .subscribe((infos: MaterialInfo[]) => {
          this.materialInfo = infos?.[0];
          this.setMaterialUnitInfo();
          this.changeDetectorRef.markForCheck();
        });

      this.localizationService
        .locale()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.loadProductFlags();
          this.changeDetectorRef.markForCheck();
        });

      this.productFlags$ = this.productFlagService
        .observeProductFlags(this.guideItem.product.offeringId)
        .pipe(
          map((flags) => {
            return !this.isOrderGuide
              ? flags
              : flags.filter((flag) => flag.title !== this.ORDER_GUIDE_FLAG);
          }),
        );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  moveUp(): void {
    if (!this.isUpActive) {
      return;
    }

    this.moveRowState = GuideAnimations.MoveUp;

    this.moveMaterial.emit({
      materialNumber: this.guideItem.material.materialNumber,
      direction: ProductDirection.up,
    });
    this.trackProductMoved('up');
    this.changeDetectorRef.markForCheck();
  }

  moveDown(): void {
    if (!this.isDownActive) {
      return;
    }

    this.moveRowState = GuideAnimations.MoveDown;

    this.moveMaterial.emit({
      materialNumber: this.guideItem.material.materialNumber,
      direction: ProductDirection.down,
    });
    this.trackProductMoved('down');
    this.changeDetectorRef.markForCheck();
  }

  actOnProductInEditableRow(event: ProductActionInfo): void {
    this.actOnProduct.emit(event);
  }

  animationComplete(): void {
    this.moveRowState = GuideAnimations.Default;
    this.highlightedRowState = GuideAnimations.Highlighted;
    this.changeDetectorRef.markForCheck();
  }

  highlightComplete(): void {
    this.highlightedRowState = GuideAnimations.Default;
    this.changeDetectorRef.markForCheck();
  }

  loadProductFlags(): void {
    this.productFlagService.loadProductFlags(
      this.guideItem.product.offeringId,
      this.materialAvailability,
      this.isOrderGuideItem,
    );
  }

  private trackProductMoved(direction: string): void {
    const event: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `${direction} arrow - product`,
    };
    this.naooAnalyticsManager.trackAnalyticsEvent(event);
  }

  private setMaterialUnitInfo(): void {
    const infoUnits = this.materialInfo?.units;

    if (infoUnits && this.materialAvailability?.isOrderable) {
      const availabilityUnits = this.materialAvailability?.units;
      const primaryUom = availabilityUnits?.[0]?.uom;
      const secondaryUom = availabilityUnits?.[1]?.uom;

      this.primaryMaterialUnitInfo = primaryUom
        ? infoUnits.find((unit) => primaryUom === unit.uom)
        : undefined;
      this.secondaryMaterialUnitInfo = secondaryUom
        ? infoUnits.find((unit) => secondaryUom === unit.uom)
        : undefined;
    }
  }
}
