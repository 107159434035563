import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  ReOrderMaterial,
  ReOrderValidationResult,
} from '../../../re-order-validation-result';
import { DOCUMENT, NgClass, NgTemplateOutlet } from '@angular/common';
import { NaooAnalyticsManager } from '../../../../shared/analytics/NaooAnalyticsManager';
import { Subject } from 'rxjs';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { LocalizedUtilities } from '../../../../shared/utilities/localized-utilities';
import { MatIcon } from '@angular/material/icon';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-unavailable-products',
  templateUrl: './unavailable-products.component.html',
  styleUrls: ['./unavailable-products.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    NgTemplateOutlet,
    NgClass,
    NaooStringDefaulterPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class UnavailableProductsComponent implements OnInit, OnDestroy {
  private static readonly ANALYTICS_CATEGORY = 'Reorder From History';
  private static readonly ANALYTICS_ACTION_CLICK = 'Click';
  private static readonly ANALYTICS_LABEL_COPY_TO_CLIPBOARD =
    'Copy Items to Clipboard';

  @Input() validationResult: ReOrderValidationResult;
  @Output() addItemsToCart = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  orderableCount: number;
  nonOrderableItems: ReOrderMaterial[][];
  expandedRowId: string;

  constructor(
    private localizationService: LocalizationService,
    private analyticsManager: NaooAnalyticsManager,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ngOnInit() {
    this.orderableCount = Object.keys(this.validationResult.orderable).length;
    this.nonOrderableItems = Object.keys(
      this.validationResult.nonOrderable,
    ).map(
      (materialNumber) => this.validationResult.nonOrderable[materialNumber],
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSelect(rowId: string): void {
    this.expandedRowId = rowId === this.expandedRowId ? undefined : rowId;
  }

  rowId(orderItems: ReOrderMaterial[]): string {
    return orderItems.length > 0 ? orderItems[0].materialNumber : '';
  }

  onCopyToClipboard(): void {
    const selBox = this.renderer2.createElement('textarea');
    selBox.value = this.buildTextToCopy(
      this.nonOrderableItems.map((items) => items[0]),
    );
    this.renderer2.appendChild(this._document.body, selBox);
    selBox.select();
    this._document.execCommand('copy');
    this.renderer2.removeChild(this._document.body, selBox);

    this.trackClickEvent(
      UnavailableProductsComponent.ANALYTICS_LABEL_COPY_TO_CLIPBOARD,
    );
  }

  private buildTextToCopy(items: ReOrderMaterial[]): string {
    return items
      .map((item) => {
        const localizedDescription = LocalizedUtilities.getLocalizedStringValue(
          item.description,
          this.localizationService.currentLanguage,
        );
        return `${item.materialNumber},"${localizedDescription}"`;
      })
      .join('\n');
  }

  onAddItemsToCart(): void {
    this.addItemsToCart.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  private trackClickEvent(label: string): void {
    this.analyticsManager.trackAnalyticsEvent({
      action: UnavailableProductsComponent.ANALYTICS_ACTION_CLICK,
      category: UnavailableProductsComponent.ANALYTICS_CATEGORY,
      label,
    });
  }
}
