import moment from 'moment-timezone';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderConfirmationHeader } from '../shared/models/order-confirmation';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NaooCurrencyPipe } from '../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-order-confirmation-header',
  templateUrl: './order-confirmation-header.component.html',
  styleUrls: ['./order-confirmation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NaooDatePipe, TranslateModule, NaooCurrencyPipe],
})
export class OrderConfirmationHeaderComponent {
  @Input() header: OrderConfirmationHeader;

  get timeZone(): string {
    return this.localizationService.instant('TIMEZONES.' + this.timeZoneCode);
  }

  get timeZoneCode(): string {
    return moment.tz(this.header.customerTimeZone).zoneAbbr();
  }

  constructor(private localizationService: LocalizationService) {}
}
