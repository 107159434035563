<div class="drop-ship-orders">
  <div class="drop-ship-orders-header">
    <div class="drop-ship-orders-header-title">
      <span class="special-order-items">
        {{ 'ORDER_CONFIRMATION.DROP_SHIP_ORDERS.TITLE' | translate }}
      </span>
      <span class="items-in-order">({{ dropShipOrder.totalQuantity }})</span>
    </div>
    <div class="drop-ship-orders-header-details">
      @if (!!dropShipOrder.orderNumber) {
        <div class="header-detail">
          <div class="header-detail-label">
            {{ 'ORDER_CONFIRMATION.HEADER.ORDER_NUMBER' | translate }}&nbsp;
          </div>
          <div class="header-detail-data">{{ dropShipOrder.orderNumber }}</div>
        </div>
      }
      @if (!!dropShipOrder.poNumber) {
        <div class="header-detail">
          <div class="header-detail-label">
            {{ 'ORDER_CONFIRMATION.HEADER.PO_NUMBER' | translate }}:&nbsp;
          </div>
          <div class="header-detail-data order-po-number">
            {{ dropShipOrder.poNumber }}
          </div>
        </div>
      }
      <div class="header-detail">
        <div class="header-detail-label">
          {{ 'ORDER_CONFIRMATION.SHIP_DATE' | translate }}&nbsp;
        </div>
        <div class="header-detail-data">
          {{ 'ORDER_CONFIRMATION.DROP_SHIP_ORDERS.3_5_DAYS' | translate }}
        </div>
      </div>
      <div class="header-detail">
        <div class="header-detail-label">
          {{ 'ORDER_CONFIRMATION.ESTIMATED_TOTAL' | translate }}&nbsp;
        </div>
        <div class="header-detail-data">
          {{
            dropShipOrder.estimatedTotal
              | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
          }}
        </div>
      </div>
    </div>
  </div>

  <div class="table-headers">
    <div class="left-header">
      {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.ITEM' | translate }}
    </div>
    <div class="right-headers">
      <div class="right-header text-left short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.SIZE' | translate }}
      </div>
      <div class="right-header text-right">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.PRICE' | translate }}
      </div>
      <div class="right-header text-center short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.QUANTITY' | translate }}
      </div>
      <div class="right-header short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
      </div>
    </div>
  </div>

  @for (item of dropShipOrder.items; track item; let isLast = $last) {
    <naoo-order-item-row
      class="order-item-row"
      [orderItem]="item"
      [isLastRow]="isLast"
    >
    </naoo-order-item-row>
  }
</div>
