import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderItem } from '../../shared/models/order-confirmation';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { WarningMaterialsHeaderComponent } from './warning-materials-header/warning-materials-header.component';
import { OrderIssueRowComponent } from '../order-issue-row/order-issue-row.component';
import { UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-warning-materials',
  templateUrl: './warning-materials.component.html',
  styleUrls: ['./warning-materials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    WarningMaterialsHeaderComponent,
    OrderIssueRowComponent,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class WarningMaterialsComponent {
  @Input() warningMaterials: OrderItem[];
  @Input() materialRelatedRouteDate?: Date;
}
