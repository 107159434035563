import { DefaultDialogService } from '../../../../shared/services/dialog/default-dialog/default-dialog.service';
import { MultipleCartsFacade } from '../../../../core/store/multiple-carts/multiple-carts.facade';
import { Injectable } from '@angular/core';
import { HelpModalValues } from '../../../../shared/modals/generic-helper-modal/generic-help-modal.component';
import { CustomDialogService } from '../../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { BulkAddWorkflow } from '../../../../shared/bulk-add-modal/bulk-add-modal.component';
import { SavedCart } from '../../../../core/store/multiple-carts/multiple-carts.state';
import { BehaviorSubject, Observable } from 'rxjs';

const helpModalValues: HelpModalValues = {
  title: 'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.TITLE',
  expansions: [
    {
      title: 'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_1.TITLE',
      paragraphs: [
        'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_1.PARAGRAPH_1',
      ],
    },
    {
      title: 'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_2.TITLE',
      paragraphs: [],
      imageDetails: {
        listItems: [
          'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_2.LINE_1',
          'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_2.LINE_2',
          'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_2.LINE_3',
        ],
        image: {
          altText:
            'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_2.IMAGE.IMAGE_ALT',
          src: 'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_2.IMAGE.SRC',
          width: 226,
          height: 228,
        },
      },
    },
    {
      title: 'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_3.TITLE',
      paragraphs: [
        'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_3.PARAGRAPH_1',
        'CART.SAVED_CARTS.SAVED_CARTS_HELP_MODAL.PANEL_3.PARAGRAPH_2',
      ],
    },
  ],
};

@Injectable({
  providedIn: 'root',
})
export class SavedCartService {
  private cartPreviewStateSubject$ = new BehaviorSubject<SavedCart>(null);
  private previewOpenSubject$ = new BehaviorSubject<boolean>(false);

  constructor(
    private dialogService: DefaultDialogService,
    private multipleCartsFacade: MultipleCartsFacade,
    private customDialogService: CustomDialogService,
  ) {}

  public cartPreviewState$(): Observable<SavedCart> {
    return this.cartPreviewStateSubject$.asObservable();
  }

  public previewOpen$(): Observable<boolean> {
    return this.previewOpenSubject$.asObservable();
  }

  activateCart(savedCart: SavedCart, shouldPromptActivateModal: boolean) {
    if (shouldPromptActivateModal) {
      this.customDialogService.bulkAddModal(
        BulkAddWorkflow.ACTIVATE_CART,
        undefined,
        undefined,
        savedCart,
      );
    } else {
      this.multipleCartsFacade.activateSavedCart(savedCart, false);
    }
  }

  setCartPreviewState(savedCart: SavedCart): void {
    this.cartPreviewStateSubject$.next(savedCart);
  }

  togglePreview(state: boolean): void {
    this.previewOpenSubject$.next(state);
  }

  deleteCart(cartId: string): void {
    this.dialogService.twoButtonModal(
      'delete-saved-cart',
      'CART.SAVED_CARTS.DELETE_CART_MODAL.TITLE',
      'CART.SAVED_CARTS.DELETE_CART_MODAL.DELETE',
      'CART.SAVED_CARTS.DELETE_CART_MODAL.CANCEL',
      () => {
        this.multipleCartsFacade.deleteSavedCart(cartId);
      },
      () => {},
      true,
      true,
    );
  }

  helperModal(): void {
    this.dialogService.helperModal('saved-cart-helper', helpModalValues);
  }
}
