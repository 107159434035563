@for (
  recommendedMaterial of recommendedMaterials;
  track recommendedMaterial.name.en
) {
  <div class="recommended-material-container">
    @if (
      getRecommendationEngineName(recommendedMaterial) &&
      recommendedMaterial.materialListRows.length > 0
    ) {
      <div class="title">
        {{ recommendedMaterial.title | stringdefaulter | decodeHtmlEntity }}
      </div>
      @if (!isMobile) {
        <hr />
      }
      <div class="centered-carousel">
        <naoo-material-carousel
          [materialListRows]="recommendedMaterial.materialListRows"
          [recommendationEngineName]="
            getRecommendationEngineName(recommendedMaterial)
          "
        ></naoo-material-carousel>
      </div>
    }
  </div>
}
