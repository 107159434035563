import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { NaooAnalyticsManager } from '../../../../shared/analytics/NaooAnalyticsManager';
import { CartSortType } from '../../../../core/store/active-cart/active-cart-summary.state';
import { NgClass } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

interface SortByOption {
  name: string;
  value: string;
}

@Component({
  selector: 'naoo-cart-sort-by',
  templateUrl: './cart-sort-by.component.html',
  styleUrls: ['./cart-sort-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatFormField, MatSelect, MatOption, TranslateModule],
})
export class CartSortByComponent {
  @Input() selectedOption: CartSortType;
  @Input() disabled: boolean;
  @Output() sortChange = new EventEmitter<CartSortType>();

  readonly sortByOptions: SortByOption[] = [
    { name: 'CART.ACTIVE_CART.SORT_BY.AS_ADDED', value: CartSortType.AsAdded },
    {
      name: 'CART.ACTIVE_CART.SORT_BY.DESCRIPTION',
      value: CartSortType.Description,
    },
    { name: 'CART.ACTIVE_CART.SORT_BY.BRAND', value: CartSortType.Brand },
    {
      name: 'CART.ACTIVE_CART.SORT_BY.ITEM_CODE',
      value: CartSortType.ItemCode,
    },
  ];

  constructor(private analytics: NaooAnalyticsManager) {}

  changeSort($event: MatSelectChange) {
    this.sortChange.emit($event.value);
    this.trackAnalytic($event.value);
  }

  private trackAnalytic(sortBy: CartSortType) {
    this.analytics.trackAnalyticsEvent({
      action: 'shopping cart',
      category: 'click',
      label: `sort by ${sortBy}`,
    });
  }
}
