<naoo-banner-offline-container
  (visibilityChanged)="setOfflineBannerVisible($event)"
  class="offline-banner"
></naoo-banner-offline-container>
@if (!cartReview || (isLoading$ | async)) {
  <mat-progress-bar [mode]="'indeterminate'"> </mat-progress-bar>
}
<div
  id="headerDiv"
  [ngClass]="isOfflineBannerVisible ? 'offline-header' : 'header'"
>
  <a class="logo" [routerLink]="guidesPath" (click)="trackLogoClick()">
    <img
      src="{{ 'LOGO.URL' | naoobrand: (customerBrand$ | async) | translate }}"
      alt="{{ 'LOGO.TEXT' | naoobrand: (customerBrand$ | async) | translate }}"
    />
  </a>
</div>
<mat-divider class="header-divider"></mat-divider>
<h1 class="title">
  {{ titleText | translate }}
  @if (submitButtonClicked && !!cartReview?.invalidDropShipOrders?.length) {
    <span class="cart-alert">
      {{
        'CART_REVIEW.TITLE_ALERT'
          | translate: { count: cartReview.invalidDropShipOrders.length }
      }}
    </span>
  }
</h1>
<div class="main-container">
  <div class="content-container">
    @if (canAddPayment) {
      <mat-stepper
        (selectionChange)="handleStepperChange($event)"
        class="stepper naoo-cart-review-stepper"
        disableRipple
        linear
      >
        <mat-step [completed]="dateHasBeenResolved$ | async" class="step">
          <ng-template matStepLabel>
            <span
              [ngClass]="{ 'highlighted-text': isAddPaymentStep }"
              class="step-header"
              >{{ 'CART_REVIEW.STEP.REVIEW_ORDER' | translate }}</span
            >
          </ng-template>
          <ng-template matStepContent>
            <ng-container
              [ngTemplateOutlet]="cartReviewTemplate"
            ></ng-container>
          </ng-template>
        </mat-step>
        <mat-step
          [aria-labelledby]="(dateHasBeenResolved$ | async) && 'clickable'"
          [completed]="isAddPaymentStep"
          class="step"
        >
          <ng-template matStepLabel>{{
            'CART_REVIEW.STEP.ADD_PAYMENT' | translate
          }}</ng-template>
          <ng-template matStepContent>
            <naoo-add-payment-content
              [paymentDetails]="cartReview?.storeOrderPaymentDetails"
            ></naoo-add-payment-content>
          </ng-template>
        </mat-step>
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>
      </mat-stepper>
    } @else {
      <naoo-cart-review-content
        #cartReviewContentComponent
        (updateFooterVisibility)="updateFooterVisibility()"
        [cartReviewCartCounts]="cartReviewCartCounts$ | async"
        [cartReview]="cartReview"
        [customerBrand]="customerBrand$ | async"
        [isLoading]="isLoading$ | async"
      >
      </naoo-cart-review-content>
    }
  </div>
  <div class="cost-summary-container">
    <naoo-cost-summary-container
      #costSummaryContainer
      [cartReview]="cartReview"
      [context]="summaryContext"
      [hasOnlySuccessSection]="hasOnlySuccessSection"
      (submitButtonClick)="handleSubmitButton()"
      (backLinkClicked)="backLinkClicked()"
    ></naoo-cost-summary-container>
  </div>
</div>

<ng-template #cartReviewTemplate>
  <naoo-cart-review-content
    #cartReviewContentComponent
    (updateFooterVisibility)="updateFooterVisibility()"
    [cartReviewCartCounts]="cartReviewCartCounts$ | async"
    [cartReview]="cartReview"
    [customerBrand]="customerBrand$ | async"
    [isLoading]="isLoading$ | async"
  >
  </naoo-cart-review-content>
</ng-template>
