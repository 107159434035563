@if (!!focusedMaterialRow) {
  <naoo-generic-docket (closeDocketEmitter)="handleClose()">
    <naoo-generic-docket-header
      [title]="'ENTITLEMENT.BALANCES'"
      [tooltipKey]="tooltip"
      (closeDocketEmitter)="handleClose()"
    >
      <naoo-material-flag [materialFlag]="entitlementFlag"></naoo-material-flag>
    </naoo-generic-docket-header>
    <div class="docket-content">
      <naoo-material-info
        [materialRowOptions]="focusedMaterialRow.materialRowOptions"
        [materialInfo]="focusedMaterialRow.materialRowInfo"
        (descriptionClicked)="handleClose()"
        class="material-info"
      >
      </naoo-material-info>
      <div class="processor-info">
        <span>
          <span class="processor-bold">{{
            'ENTITLEMENT.PROCESSOR' | translate
          }}</span>
          {{ vendorInfo?.displayName }}
        </span>
        <span>
          <span class="processor-bold">{{
            'ENTITLEMENT.PROCESSOR_NUMBER' | translate
          }}</span>
          {{ vendorInfo?.vendorMaterialNumber }}
        </span>
        <span>
          <span class="processor-bold">{{
            'ENTITLEMENT.VALUE' | translate
          }}</span>
          {{ materialCommodityInfo?.passThroughMethod }}
        </span>
      </div>
      <naoo-commodity-docket-carousel
        [entitlementMappings]="materialCommodityInfo?.mappings"
      >
      </naoo-commodity-docket-carousel>
    </div>
  </naoo-generic-docket>
}
