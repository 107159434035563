<div class="back-button-container">
  <div class="breadcrumb-container">
    @if (isMobile) {
      <naoo-back-button
        [navigationLink]="backNavigationLink"
      ></naoo-back-button>
    } @else {
      <naoo-breadcrumb [breadcrumbs]="breadcrumbs"></naoo-breadcrumb>
    }
  </div>
</div>
<div class="page-header">
  <div class="title-container">
    <span class="page-title">
      {{ 'LISTS.EDIT' | translate }}
      <strong>{{ customGuideName }}</strong></span
    >
    <button
      class="rename-custom-guide"
      [disabled]="isLoading"
      attr.aria-label="{{ 'LISTS.RENAME' | translate }}"
      (click)="openRenameModal()"
    >
      <mat-icon class="icon" svgIcon="edit-icon"></mat-icon>
    </button>
  </div>
  @if (totalMaterials > 0) {
    <span class="product-count"
      >({{ totalMaterials }}
      {{
        (totalMaterials !== 1 ? 'ORDER_GUIDE.PRODUCTS' : 'ORDER_GUIDE.PRODUCT')
          | translate
      }})</span
    >
  }
  <div class="exit-edit-mode">
    <button
      class="primary-button large-button exit-guide-button"
      [disabled]="isLoading"
      attr.aria-label="{{ 'LISTS.EXIT_EDIT_MODE' | translate }}"
      (click)="doneEditing()"
    >
      {{ 'LISTS.EXIT_EDIT_MODE' | translate }}
    </button>
  </div>
  @if (isMobile) {
    <div class="new-category-mobile">
      <button
        [disabled]="!canCustomizeCategories"
        attr.aria-label="{{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}"
        class="secondary-button large-button new-category-button"
        (click)="openCreateCategoryModal()"
      >
        + {{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}
      </button>
    </div>
  }
</div>
<mat-tab-group
  [disableRipple]="true"
  [selectedIndex]="selectedGroupByTabIndex"
  [mat-stretch-tabs]="false"
  (selectedIndexChange)="onGroupByChange($event)"
  class="group-by-tabs"
>
  @for (option of groupByOptions; track option.display) {
    <mat-tab
      label="{{ option.display | translate }}"
      aria-label="{{ option.display | translate }}"
      [disabled]="isLoading"
    >
    </mat-tab>
  }
</mat-tab-group>
@if (isLoading) {
  <div class="loading-container">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    <div class="header">{{ 'LOADING' | translate }}</div>
  </div>
}
<div class="guide-content" [ngClass]="{ hide: isLoading }" cdkDropListGroup>
  <div class="guide-navigation-container">
    <div class="new-category">
      <button
        [disabled]="!canCustomizeCategories"
        attr.aria-label="{{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}"
        class="secondary-button large-button new-category-button"
        (click)="openCreateCategoryModal()"
      >
        + {{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}
      </button>
    </div>
    <div class="guide-navigation" id="category-sidebar">
      <ul>
        @if (canCustomizeCategories) {
          <div class="category-title-drag">
            @for (
              categorizedMaterial of categorizedMaterials;
              track categorizedMaterial.categoryName.en;
              let index = $index;
              let isLast = $last
            ) {
              <div>
                <li>
                  <div
                    class="category-element"
                    [ngClass]="{ 'add-margin': isLast }"
                  >
                    <div class="category-title-container">
                      <div class="category-text-container">
                        <span
                          >{{
                            categorizedMaterial.categoryName.en ||
                              ('ORDER_GUIDE.OTHER' | translate)
                          }}
                          - ({{ categorizedMaterial.items?.length || 0 }})</span
                        >
                      </div>
                      @if (categorizedMaterial.categoryName.en) {
                        <div class="edit-category-buttons">
                          <button
                            tabindex="0"
                            attr.aria-label="{{
                              isUpArrowActive(index)
                                ? ('LISTS.MOVE_CATEGORY_UP_BUTTON' | translate)
                                : ('LISTS.MOVE_CATEGORY_UP_BUTTON_DISABLED'
                                  | translate)
                            }}"
                            mat-icon-button
                            class="mat-icon-button up-arrow"
                            [ngClass]="{
                              'active-button':
                                isUpArrowActive(index) && isCustom,
                            }"
                            [disabled]="!isUpArrowActive(index) || !isCustom"
                            (click)="moveUp(categorizedMaterial)"
                          >
                            <mat-icon
                              svgIcon="category_up_active"
                              [ngClass]="{
                                'inactive-svg':
                                  !isUpArrowActive(index) || !isCustom,
                              }"
                            ></mat-icon>
                          </button>
                          <button
                            tabindex="0"
                            attr.aria-label="{{
                              isDownArrowActive(isLast)
                                ? ('LISTS.MOVE_CATEGORY_DOWN_BUTTON'
                                  | translate)
                                : ('LISTS.MOVE_CATEGORY_DOWN_BUTTON_DISABLED'
                                  | translate)
                            }}"
                            mat-icon-button
                            class="mat-icon-button down-arrow"
                            [ngClass]="{
                              'active-button':
                                isDownArrowActive(isLast) && isCustom,
                            }"
                            [disabled]="!isDownArrowActive(isLast) || !isCustom"
                            (click)="moveDown(categorizedMaterial)"
                          >
                            <mat-icon
                              svgIcon="category_down_active"
                              [ngClass]="{
                                'inactive-svg':
                                  !isDownArrowActive(isLast) || !isCustom,
                              }"
                            >
                            </mat-icon>
                          </button>
                        </div>
                      }
                    </div>
                    <div class="products-container">
                      <div
                        class="item-drag item-bucket"
                        cdkDropList
                        [cdkDropListData]="index.toString()"
                        (cdkDropListDropped)="drop($event)"
                      >
                        <div class="help-text no-drag">
                          {{ 'LISTS.DROP_PRODUCT_HERE' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            }
          </div>
        } @else {
          <div>
            <li>
              @for (
                categorizedMaterialRecord of categorizedMaterials;
                track categorizedMaterialRecord.categoryName.en;
                let isLast = $last
              ) {
                <div class="category-element-preset-group">
                  <div
                    class="category-title-container-preset-group"
                    [ngClass]="{ 'preset-group-bottom-border': isLast }"
                  >
                    <div class="category-text-container">
                      <span>{{
                        (categorizedMaterialRecord.categoryName
                          | stringdefaulter) ||
                          ('ORDER_GUIDE.OTHER' | translate)
                      }}</span>
                    </div>
                    <span class="item-total"
                      >({{ categorizedMaterialRecord.items?.length || 0 }})
                    </span>
                  </div>
                </div>
              }
            </li>
          </div>
        }
      </ul>
    </div>
  </div>
  <div class="guide-products-container">
    <div class="guide-actions">
      <div class="sort-by-action">
        <span class="sort-by-label">{{
          'LISTS.SORT_ITEMS_BY.LABEL' | translate
        }}</span>
        <mat-form-field class="sort-by-field">
          <mat-select
            [value]="sortBy"
            (selectionChange)="onSortByChange($event)"
            aria-label="{{ 'LISTS.SORT_ITEMS_BY.LABEL' | translate }}"
          >
            @for (option of sortByOptions; track option.display) {
              <mat-option [value]="option.name">
                {{ option.display | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (isParOrderEnabled) {
        <div class="par-order-label">
          {{ 'PRODUCT.PAR' | translate }}
        </div>
      }
    </div>
    <div class="product-list-container">
      @for (
        categorizedMaterialRecord of categorizedMaterials;
        track categorizedMaterialRecord.categoryName.en;
        let index = $index;
        let isLast = $last
      ) {
        <div class="categorized-products">
          <naoo-edit-custom-guide-category
            [sortBy]="sortBy"
            [groupBy]="groupBy"
            [categoryIndex]="index"
            [customGuideId]="customGuideId"
            [categorizedMaterial]="categorizedMaterialRecord"
            [isUpArrowActive]="isUpArrowActive(index)"
            [isDownArrowActive]="isDownArrowActive(isLast)"
            [shouldDisableButtons]="!canCustomizeCategories"
            [enableParOrdering]="isParOrderEnabled"
            [materialParOrders]="materialParOrders"
            [isMobile]="isMobile"
            (moveCategorizedMaterial)="moveCategory($event)"
            (actOnMaterialInCategorizedMaterial)="
              actOnMaterialInACategory($event)
            "
            (renameCategory)="actOnRenameCategory($event)"
            (deleteCategory)="actOnDeleteCategory($event)"
          >
          </naoo-edit-custom-guide-category>
        </div>
      }
    </div>
  </div>
</div>
