<div class="nutrition-table" [ngClass]="{ 'hide-border': hideBorder }">
  <div class="title">{{ 'NUTRITION.NUTRITION_FACTS' | translate }}</div>
  @if (getCalculationSize() !== '') {
    <div class="main-value header no-underline calculation-size">
      {{ 'NUTRITION.PER_SERVING' | translate }} {{ getCalculationSize() }}
    </div>
  }
  <div class="main-value header heavy-underline">
    {{ 'NUTRITION.CALORIES' | translate }} {{ getCalories() }}
  </div>
  <div class="daily-value-header">
    % {{ 'NUTRITION.DAILY_VALUE' | translate }}
  </div>

  @for (
    tableItem of nutritionTableStructure;
    track tableItem.key;
    let last = $last
  ) {
    <div class="nutrition-items">
      <div
        class="main-value nutrition-item"
        [ngClass]="{ 'heavy-underline': last }"
      >
        {{ tableItem.displayLabel | translate }}
        {{ measurableValueForKey(tableItem.key) }}
        <div class="daily-value">{{ dailyValueForKey(tableItem.key) }}</div>
      </div>
      @for (subItem of tableItem.subsections; track subItem.key) {
        <div class="sub-value nutrition-item">
          {{ subItem.displayLabel | translate }}
          {{ measurableValueForKey(subItem.key) }}
          <div class="daily-value">{{ dailyValueForKey(subItem.key) }}</div>
        </div>
      }
    </div>
  }
  @for (
    tableItem of vitaminsTableStructure;
    track tableItem.key;
    let last = $last
  ) {
    <div class="main-value vitamin-item" [ngClass]="{ 'no-underline': last }">
      {{ tableItem.displayLabel | translate }}
      {{ measurableValueForKey(tableItem.key) }}
      <div class="daily-value">{{ dailyValueForKey(tableItem.key) }}</div>
    </div>
  }
</div>
