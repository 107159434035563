import { map, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { SearchableCustomer } from '../../shared/models/searchable-customer';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { FaviconService } from '../../shared/favicon/favicon.service';
import { UserCartTotalsService } from '../../shared/services/user-cart-totals/user-cart-totals.service';
import { SessionFacade } from '../../core/store/session/session.facade';
import { Dictionary } from '@ngrx/entity';
import { FilterPipe, FilterPipeModule } from 'ngx-filter-pipe';
import { CustomerCartCountRecord } from '../../shared/services/user-cart-totals/user-cart-totals';
import {
  CustomerPermission,
  SessionCustomer,
  SessionRecord,
} from '../../core/services/session/models/session-record';
import { CONTENT_SCROLL } from '../../shared/services/scrollable-content/scrollable-content.service';
import { SearchFieldComponent } from '../../shared/search-bar/search-field.component';
import { CustomerUnitHeaderComponent } from '../customer-unit-header/customer-unit-header.component';
import { VirtualScrollerComponent } from '../../vendor/ngx-virtual-scroller/virtual-scroller';
import { CustomerUnitCardComponent } from '../customer-unit-card/customer-unit-card.component';
import { NoResultsComponent } from '../../shared/no-results/no-results.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-customer-unit-selection',
  templateUrl: './customer-unit-selection.component.html',
  styleUrls: ['./customer-unit-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchFieldComponent,
    CustomerUnitHeaderComponent,
    VirtualScrollerComponent,
    CustomerUnitCardComponent,
    NoResultsComponent,
    TranslateModule,
    FilterPipeModule,
  ],
})
export class CustomerUnitSelectionComponent implements OnInit, OnDestroy {
  sessionInfo: SessionRecord;
  activeCustomerCompositeId: string;
  searchableCustomers: SearchableCustomer[] = [];
  customerCartCountRecords: Dictionary<CustomerCartCountRecord> = {};
  searchText: string;
  previousSearchText: string;
  isMobile = false;
  filteredResults: SearchableCustomer[] = [];

  destroyed$ = new Subject();

  customerFilter = { searchableCustomerAttributes: '' };

  constructor(
    private sessionFacade: SessionFacade,
    private deviceIdentifierService: DeviceIdentifierService,
    private faviconService: FaviconService,
    private userCartTotalsService: UserCartTotalsService,
    private filterPipe: FilterPipe,
    private changeDetector: ChangeDetectorRef,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  ngOnInit() {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetector.markForCheck();
      });
    this.getSessionInfo();
    this.getCartTotals();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  updateFilter(searchText: string) {
    this.searchText = searchText;
    this.customerFilter.searchableCustomerAttributes = searchText;
  }

  get filteredCustomers(): SearchableCustomer[] {
    if (this.customerFilter.searchableCustomerAttributes === '') {
      this.filteredResults = this.searchableCustomers;
      return this.filteredResults;
    } else {
      if (this.previousSearchText !== this.searchText) {
        this.filteredResults = this.filterPipe.transform(
          this.searchableCustomers,
          this.customerFilter,
        );
        this.previousSearchText = this.searchText;
      }
      return this.filteredResults;
    }
  }

  get hasCustomersResults() {
    return this.filteredCustomers.length > 0;
  }

  get isOrderSubmission(): boolean {
    return this.searchableCustomers.some((searchableCustomer) =>
      searchableCustomer.customer.permissions.includes(
        CustomerPermission.OrderSubmission,
      ),
    );
  }

  get hasPunchedOutCarts(): boolean {
    return Object.values(this.customerCartCountRecords).some(
      (cartCounts) => cartCounts.punchedOutCartsCount > 0,
    );
  }

  get hasOnlyOneCustomer(): boolean {
    return this.sessionInfo?.customers?.length === 1;
  }

  isActiveCustomer(customerCompositeId: string) {
    return customerCompositeId === this.activeCustomerCompositeId;
  }

  private getSessionInfo() {
    this.sessionFacade
      .getLoadedSession()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sessionInfo) => {
        this.sessionInfo = sessionInfo;
        this.activeCustomerCompositeId =
          sessionInfo?.activeCustomer?.naooCustomerId;

        this.setSearchableCustomers([...sessionInfo.customers]);
        this.faviconService.useFavicon(sessionInfo?.activeCustomer?.brand);
        this.changeDetector.markForCheck();
      });
  }

  private setSearchableCustomers(customers: SessionCustomer[]) {
    this.searchableCustomers = this.sortCustomersByCustomerId(customers).map(
      (customer) => new SearchableCustomer(customer),
    );
  }

  private getCartTotals() {
    this.userCartTotalsService
      .getUserCartTotals()
      .pipe(
        map((cartsTotalsRecord) => {
          const mapCartsTotals: Dictionary<CustomerCartCountRecord> = {};
          cartsTotalsRecord.cartTotals.forEach(
            (cartRecord: CustomerCartCountRecord) => {
              mapCartsTotals[cartRecord.naooCustomerId] = cartRecord;
            },
          );
          return mapCartsTotals;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((cartTotals) => {
        this.customerCartCountRecords = cartTotals;
        this.changeDetector.markForCheck();
      });
  }

  private sortCustomersByCustomerId(customers: SessionCustomer[]) {
    return customers.sort((firstCustomer, secondCustomer) => {
      if (this.isActiveCustomer(firstCustomer.naooCustomerId)) {
        return -1;
      } else if (this.isActiveCustomer(secondCustomer.naooCustomerId)) {
        return 1;
      } else if (
        Number(firstCustomer.customerDisplayId) >
        Number(secondCustomer.customerDisplayId)
      ) {
        return 1;
      } else if (
        Number(secondCustomer.customerDisplayId) >
        Number(firstCustomer.customerDisplayId)
      ) {
        return -1;
      }
      return 0;
    });
  }
}
