<div class="card">
  <button
    role="link"
    class="card-button"
    attr.aria-label="{{ listTitle }} {{ totalListProducts }} {{
      productString() | translate
    }}"
    (click)="handleClick()"
  >
    @if (isCriticalItemsGuide) {
      <mat-icon
        class="critical-item-star"
        svgIcon="critical_item_star"
      ></mat-icon>
    }
    <div class="title-wrapper">
      <div class="list-title">
        {{
          isCriticalItemImported
            ? ('LISTS.CRITICAL_ITEMS.IMPORTED' | translate)
            : listTitle
        }}
      </div>
      @if (totalListProducts > -1) {
        <div class="product-count">
          ({{ totalListProducts }} {{ productString() | translate }})
        </div>
      }
    </div>
  </button>
  @if (customGuideId) {
    <naoo-custom-guide-edit-menu
      class="menu-wrapper"
      [isUserOffline]="isUserOffline"
      [customGuideId]="customGuideId"
      [customGuideName]="
        isCriticalItemImported
          ? ('LISTS.CRITICAL_ITEMS.IMPORTED' | translate)
          : listTitle
      "
      [showCopyGuide]="showCopyGuide"
    >
    </naoo-custom-guide-edit-menu>
  }
</div>
