import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatMenuItem, MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { Subject } from 'rxjs';
import {
  MenuAction,
  MenuActionInfo,
} from '../../product-row/product-menu/menu-action';
import { takeUntil } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { naooAnimations } from '../animations/animations';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { Language } from 'src/app/core/services/session/models/session-record';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-add-to-custom-guide',
  templateUrl: './add-to-custom-guide.component.html',
  styleUrls: ['./add-to-custom-guide.component.scss'],
  animations: [naooAnimations.rotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenuTrigger,
    NgClass,
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class AddToCustomGuideComponent implements OnDestroy, AfterViewInit {
  @Input() disabled = false;
  @Input() addToGuideLabel: string;
  @Input() currentLanguage: Language;
  @Output() menuActionSelected = new EventEmitter<MenuActionInfo>();

  private MENU_ITEM_HEIGHT = 50;
  private MENU_ITEM_COUNT_BEFORE_SCROLL = 5;
  private MENU_MAX_HEIGHT =
    this.MENU_ITEM_HEIGHT * this.MENU_ITEM_COUNT_BEFORE_SCROLL;

  customGuidesElementArray: MatMenuItem[];

  isPanelExpanded = false;
  shouldShowSubMenu = false;
  private destroyed$ = new Subject<void>();
  rotationState = 'default';
  expandedMenuHeight = 0;
  createNewGuideMenuItem = {
    name: 'LISTS.CREATE_GUIDE',
    action: MenuAction.AddToNewCustomGuide,
  };
  submenuItems: MenuActionInfo[];

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.loadSubmenuItems();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  performMenuAction(menuItem: MenuActionInfo) {
    this.menuActionSelected.emit(menuItem);
  }

  expandAddToGuide(event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isPanelExpanded) {
      this.closeExpansionItem();
    } else {
      this.openExpansionItem();
    }
    this.changeDetector.markForCheck();
  }

  onMenuClosed() {
    this.closeExpansionItem();
  }

  animationComplete(event: AnimationEvent) {
    if (this.rotationState === 'default' && event.fromState === '180') {
      this.shouldShowSubMenu = false;
    }
  }

  get isInFrench(): boolean {
    return this.currentLanguage === Language.fr;
  }

  private closeExpansionItem() {
    this.isPanelExpanded = false;
    this.shouldShowSubMenu = false;
    this.rotationState = 'default';
    this.expandedMenuHeight = 0;
  }

  private openExpansionItem() {
    this.isPanelExpanded = true;
    this.rotationState = '180';
    const itemCount = this.submenuItems ? this.submenuItems.length : 0;
    this.expandedMenuHeight = this.computeMenuHeightForItemCount(itemCount);
  }

  private loadSubmenuItems() {
    this.customGuideFacade
      .getAllLoadedSortedCustomGuides()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customGuides) => {
        const addSubmenuItems: MenuActionInfo[] = customGuides.map((guide) => ({
          id: guide.id,
          name: guide.name,
          action: MenuAction.AddToExistingCustomGuide,
        }));
        this.setSubMenuItems(addSubmenuItems);
      });
  }

  private computeMenuHeightForItemCount(itemCount: number): number {
    // add one to account for default + Create New Guide option
    const count = 1 + itemCount;
    return count > this.MENU_ITEM_COUNT_BEFORE_SCROLL
      ? this.MENU_MAX_HEIGHT
      : this.MENU_ITEM_HEIGHT * count;
  }

  private setSubMenuItems(newItems: MenuActionInfo[]) {
    this.submenuItems = newItems;
    if (this.isPanelExpanded) {
      const count = newItems ? newItems.length : 0;
      this.expandedMenuHeight = this.computeMenuHeightForItemCount(count);
      this.changeDetector.markForCheck();
    }
  }
}
