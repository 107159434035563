import { Observable } from 'rxjs';
import { PreferencesContainerComponent } from './preferences-container.component';
import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';

export const unsavedPreferencesGuard: CanDeactivateFn<any> = (
  component: PreferencesContainerComponent,
  _currentRoute: ActivatedRouteSnapshot,
  _currentState: RouterStateSnapshot,
  _nextState?: RouterStateSnapshot,
): Observable<boolean> | boolean => {
  return component ? component.canDeactivate() : true;
};
