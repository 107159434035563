@if (isFulfillmentType(truckFulfillment)) {
  <naoo-select-date-step-truck
    [fulfillmentType]="fulfillmentType"
    [availableDates]="availableDates"
    [customerTimeZone]="customerTimeZone"
    [cutoffDateTime]="cutoffDateTime"
    [isRouteDateExpired]="isRouteDateExpired"
    [isMobileWidth]="isMobileWidth"
    [selectedDate]="selectedDate"
    (dateChangeEmitter)="dateChangeEmitter.emit($event)"
  >
  </naoo-select-date-step-truck>
}
@if (isFulfillmentType(expressFulfillment)) {
  <naoo-select-date-step-express
    [fulfillmentType]="fulfillmentType"
    [availableDates]="availableDates"
    [customerTimeZone]="customerTimeZone"
    [selectedDate]="selectedDate"
    [availableExpressDeliveryWindows]="
      selectedExpressScheduleRecord?.deliveryWindows
    "
    [selectedExpressDeliveryWindow]="selectedExpressDeliveryWindow"
    [isMobileWidth]="isMobileWidth"
    [isRouteDateExpired]="isRouteDateExpired"
    (dateChangeEmitter)="dateChangeEmitter.emit($event)"
    (expressDeliveryWindowChangeEmitter)="
      expressDeliveryWindowChangeEmitter.emit($event)
    "
  >
  </naoo-select-date-step-express>
}
@if (isFulfillmentType(pickupFulfillment)) {
  <naoo-select-date-step-ispu
    [fulfillmentType]="fulfillmentType"
    [availableDates]="availableDates"
    [customerTimeZone]="customerTimeZone"
    [selectedDate]="selectedDate"
    [storeDetailsViewModel]="storeDetailsViewModel"
    [isRouteDateExpired]="isRouteDateExpired"
    [isMobileWidth]="isMobileWidth"
    (dateChangeEmitter)="dateChangeEmitter.emit($event)"
  ></naoo-select-date-step-ispu>
}
