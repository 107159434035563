import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogContent,
} from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export interface MessageData {
  messageKey: string;
  parameters?: object;
}

export interface ImageData {
  imageLocation: string;
  altText: string;
}

export interface GenericModalComponentData {
  messageData: string | MessageData;
  helperMessageData: string | MessageData;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  closeAction: () => void;
  isDestructive: boolean;
  image: ImageData;
  title: string;
}

@Component({
  selector: 'naoo-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    NgTemplateOutlet,
    MatButton,
    MatIcon,
    NgClass,
    TranslateModule,
  ],
})
export class GenericModalComponent {
  messageData: MessageData | null;
  helperMessageData: MessageData | null;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  closeAction: () => void;
  isDestructive: boolean;
  image: ImageData;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: GenericModalComponentData,
  ) {
    this.messageData = this.parseMessageData(data.messageData);
    this.helperMessageData = this.parseMessageData(data.helperMessageData);
    this.primaryButtonText = data.primaryButtonText;
    this.secondaryButtonText = data.secondaryButtonText;
    this.primaryButtonAction = data.primaryButtonAction;
    this.secondaryButtonAction = data.secondaryButtonAction;
    this.closeAction = data.closeAction;
    this.isDestructive = data.isDestructive;
    this.image = data.image;
    this.title = data.title;
  }

  private parseMessageData(
    messageData: string | MessageData,
  ): MessageData | null {
    if (messageData && typeof messageData === 'object') {
      return messageData;
    } else if (typeof messageData === 'string') {
      return {
        messageKey: messageData,
      };
    }
    return null;
  }

  get hasVideoData(): boolean {
    return this.image?.imageLocation.endsWith('.mp4');
  }
}
