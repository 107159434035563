<naoo-modal-header
  class="mobile"
  [showCloseButton]="true"
  [stickyHeader]="true"
  [isMobile]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>

<naoo-modal-header
  class="desktop"
  [title]="
    'ORDER_CONFIRMATION.MATERIAL_WARNING.MODAL.QUANTITY_ERROR_TITLE' | translate
  "
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>

@if (!salesCriticalItemsRows) {
  <div class="loading-container">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
} @else {
  <span class="material-warning-mobile-title mobile">
    {{
      'ORDER_CONFIRMATION.MATERIAL_WARNING.MODAL.QUANTITY_ERROR_TITLE'
        | translate
    }}
  </span>
  <span class="material-warning-message">
    {{
      'ORDER_CONFIRMATION.MATERIAL_WARNING.MODAL.QUANTITY_ERROR_MESSAGE'
        | translate
    }}
  </span>
  <span class="material-warning-number">
    {{
      (numberOfSalesCriticalItemsWarnings === 1
        ? 'ORDER_CONFIRMATION.MATERIAL_WARNING.MODAL.QUANTITY_ERROR_NUMBER'
        : 'ORDER_CONFIRMATION.MATERIAL_WARNING.MODAL.QUANTITY_ERROR_NUMBER_PLURAL'
      ) | translate: { totalErrors: numberOfSalesCriticalItemsWarnings }
    }}
  </span>
  <naoo-material-list
    class="material-list-container"
    [materialListItems]="salesCriticalItemsRows"
    [listStyle]="listStyle"
    [context]="context"
  ></naoo-material-list>
  <div class="buttons-container">
    <button
      (click)="closeModal()"
      class="done-button"
      [disabled]="numberOfSalesCriticalItemsWarnings > 0"
    >
      {{ 'DONE' | translate }}
    </button>
  </div>
}
