<div
  [ngClass]="{
    'last-row': isLastRow,
    'processing-last-row': isProcessing && isLastRow,
    'hide-bottom-space': hideBottomSpace,
    'savings-line': !!orderItem.itemDetail.totalDiscountAmount,
  }"
  class="order-item-container"
>
  @if (!!orderItem.itemDetail.totalDiscountAmount) {
    <div class="line-item-savings">
      {{ 'ORDERS.DETAILS.SAVED' | translate }}
      {{ orderItem.itemDetail.totalDiscountAmount | naooprice }}
    </div>
  }
  <div class="order-item-content">
    <naoo-confirmation-material-details
      class="material-details-info"
      [itemDetail]="orderItem.itemDetail"
    ></naoo-confirmation-material-details>

    <div class="order-details">
      @for (
        orderLine of orderItem.orderLines;
        track orderLine;
        let isLast = $last
      ) {
        <div class="order-line" [ngClass]="{ 'bottom-padded': !isLast }">
          <div class="column align-left short-value">
            <div>{{ { uomCode: orderLine.displayCode } | materialunits }}</div>
          </div>
          <div class="column align-right">
            <div>
              {{ orderLine.price | naooprice: orderLine.catchWeightUom }}
            </div>
          </div>
          <div class="column align-center short-value">
            <div>{{ orderLine.quantityOrdered }}</div>
          </div>
          <div class="column align-right short-value">
            <div>
              {{ orderLine.totalLinePrice | naooprice }}
            </div>
          </div>
        </div>
      }
    </div>

    <div class="order-details-mobile">
      <div class="order-details-row">
        <div class="order-details-row-label">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.PRICE' | translate }}
        </div>

        <div class="order-details-row-data">
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="right-column">
              <span>
                {{ { uomCode: orderLine.displayCode } | materialunits }}:
                {{ orderLine.price | naooprice: orderLine.catchWeightUom }}
              </span>
              @if (!isLast) {
                <span> | </span>
              }
            </div>
          }
        </div>
      </div>

      <div class="order-details-row">
        <div class="order-details-row-label">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.QUANTITY' | translate }}
        </div>

        <div class="order-details-row-data">
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="right-column">
              <span
                >{{
                  {
                    uomCode: orderLine.displayCode,
                    quantity: orderLine.quantityOrdered,
                  } | materialunits
                }}
              </span>
              @if (!isLast) {
                <span> | </span>
              }
            </div>
          }
        </div>
      </div>
      <div class="last-row order-details-row">
        <div class="order-details-row-label">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
        </div>
        <div class="order-details-row-data">
          @for (
            orderLine of orderItem.orderLines;
            track orderLine;
            let isLast = $last
          ) {
            <div class="right-column">
              <span
                >{{
                  {
                    uomCode: orderLine.displayCode,
                    quantity: orderLine.quantityOrdered,
                  } | materialunits: false
                }}:
                {{ orderLine.totalLinePrice | naooprice }}
              </span>
              @if (!isLast) {
                <span> | </span>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
