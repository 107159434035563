<form [formGroup]="createGuideForm" (ngSubmit)="nextStep()">
  <section class="main-container">
    <article class="info-and-input-container">
      <h2>{{ 'IMPORT_GUIDE.CREATE_GUIDE.TITLE' | translate }}</h2>
      <p>{{ 'IMPORT_GUIDE.CREATE_GUIDE.BEGIN_IMPORTING' | translate }}</p>
      <mat-form-field>
        <mat-label>{{
          'IMPORT_GUIDE.CREATE_GUIDE.ENTER_CUSTOM_GUIDE_NAME' | translate
        }}</mat-label>
        <input
          #customGuideInput
          class="import-guide-name-field"
          matInput
          type="text"
          formControlName="customGuideName"
          [errorStateMatcher]="customGuideNameErrorStateMatcher"
          [maxlength]="VALIDATION.CUSTOM_GUIDE_NAME.MAX_LENGTH"
          [attr.aria-label]="
            'IMPORT_GUIDE.CREATE_GUIDE.ENTER_CUSTOM_GUIDE_NAME' | translate
          "
          autocomplete="off"
          cdkFocusInitial
        />
        <mat-hint class="custom-guide-name__character-counter" align="end">
          {{ createGuideForm.value['customGuideName'].length }} /
          {{ VALIDATION.CUSTOM_GUIDE_NAME.MAX_LENGTH }}
        </mat-hint>
        @if (
          createGuideForm.controls['customGuideName'].hasError(
            'alreadyExistingCustomGuideName'
          )
        ) {
          <mat-error class="custom-guide-name__already-exists">
            {{ 'IMPORT_GUIDE.CREATE_GUIDE.ALREADY_EXISTS' | translate }}
          </mat-error>
        }
        @if (createGuideForm.controls['customGuideName'].hasError('pattern')) {
          <mat-error class="custom-guide-name__invalid-characters">
            {{ 'INVALID_CHARACTERS' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </article>
    <article class="image-container">
      <mat-icon
        class="import-guide-document"
        svgIcon="import-guide-document"
      ></mat-icon>
    </article>
  </section>

  <section class="buttons-container">
    <button
      class="cancel-button"
      type="button"
      [attr.aria-label]="'IMPORT_GUIDE.CREATE_GUIDE.CANCEL_BUTTON' | translate"
      (click)="closeModal()"
    >
      {{ 'IMPORT_GUIDE.CREATE_GUIDE.CANCEL_BUTTON' | translate }}
    </button>
    <button
      class="continue-button"
      type="submit"
      [attr.aria-label]="
        'IMPORT_GUIDE.CREATE_GUIDE.CONTINUE_BUTTON' | translate
      "
      [disabled]="!createGuideForm.valid"
    >
      {{ 'IMPORT_GUIDE.CREATE_GUIDE.CONTINUE_BUTTON' | translate }}
    </button>
  </section>
</form>
