import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SavedCartService } from '../shared/saved-cart.service';
import { MaterialInfoFacade } from '../../../../core/store/material-info/material-info.facade';
import { MaterialPriceFacade } from '../../../../core/store/material-price/material-price.facade';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  CartPreviewViewModel,
  MultipleCartsTransformer,
  ViewModelMaterial,
} from '../../../../core/store/multiple-carts/transformer/multiple-carts.transformer';
import { combineLatest } from 'rxjs';
import { HeaderHeightService } from '../../../../shared/services/header-height/header-height.service';
import { DOCUMENT } from '@angular/common';
import {
  SavedCart,
  SavedCarts,
} from '../../../../core/store/multiple-carts/multiple-carts.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemTitle } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { NaooPricePipe } from '../../../../shared/pipes/naoo-price.pipe';
import { NaooDatePipe } from '../../../../shared/pipes/naoo-date.pipe';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { MaterialUnitsPipe } from '../../../../shared/pipes/material-units.pipe';
import { NaooCurrencyPipe } from '../../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-preview-cart',
  templateUrl: './preview-cart.component.html',
  styleUrls: ['./preview-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatList,
    MatListItem,
    MatListItemTitle,
    TranslateModule,
    NaooPricePipe,
    NaooDatePipe,
    NaooStringDefaulterPipe,
    MaterialUnitsPipe,
    NaooCurrencyPipe,
  ],
})
export class PreviewCartComponent implements OnInit {
  @Input() isMobile = false;
  @Input() savedCarts: SavedCarts;
  @ViewChild('searchText', { static: true }) searchBox: ElementRef;
  searchText: string;

  viewModel: CartPreviewViewModel;
  savedCart: SavedCart;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private savedCartService: SavedCartService,
    private materialInfoFacade: MaterialInfoFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private headerHeightService: HeaderHeightService,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ngOnInit(): void {
    this.savedCartService
      .cartPreviewState$()
      .pipe(distinctUntilChanged())
      .subscribe((savedCart) => {
        this.savedCart = savedCart;
        const materialNumbers = savedCart.materials.map(
          (cartMaterialRecord) => cartMaterialRecord.materialNumber,
        );

        this.materialInfoFacade.loadMaterialInfos(materialNumbers);
        this.materialPriceFacade.loadMaterialPrices(materialNumbers);

        combineLatest([
          this.materialPriceFacade.getLoadedCombinedPrices(materialNumbers),
          this.materialInfoFacade.getLoadedMaterialInfos(materialNumbers),
        ]).subscribe(([prices, infos]) => {
          this.viewModel = MultipleCartsTransformer.toCartPreviewViewModel(
            prices,
            infos,
            savedCart,
          );
          this.changeDetectorRef.markForCheck();
        });
      });

    this.setMatDrawerTopMargin();
  }

  activateCart(): void {
    this.savedCartService.activateCart(
      this.savedCart,
      this.savedCarts.shouldPromptActivateModal,
    );
  }

  updateSearchText(event: Event): void {
    this.searchText = (event.target as HTMLInputElement).value;
  }

  resetSearch(): void {
    this.searchText = '';
  }

  closeCartPreview(): void {
    this.savedCartService.togglePreview(false);
  }

  get materials(): ViewModelMaterial[] {
    return this.searchText
      ? this.viewModel.materials.filter((viewModelMaterial) =>
          this.includesSearchText([
            viewModelMaterial.materialNumber,
            viewModelMaterial.description?.en,
            viewModelMaterial.description?.fr,
            viewModelMaterial.description?.es,
          ]),
        )
      : this.viewModel.materials;
  }

  private includesSearchText(values: string[]): boolean {
    return values
      .filter((value) => !!value)
      .some((value) =>
        value.toLowerCase().includes(this.searchText.toLowerCase()),
      );
  }

  private setMatDrawerTopMargin(): void {
    const matDrawer = this._document.getElementById('mat-drawer');
    const headerHeight = this.headerHeightService.getHeaderHeight();

    matDrawer.style.top = headerHeight.toString().concat('px');
  }
}
