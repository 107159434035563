import {
  ElementRef,
  Inject,
  Injectable,
  NgZone,
  Renderer2,
} from '@angular/core';
import { EnvironmentSpecificService } from '../../../../shared/services/environment-specific/environment-specific.service';
import { DOCUMENT } from '@angular/common';
import { SessionFacade } from '../../../../core/store/session/session.facade';
import { first } from 'rxjs/operators';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { getLanguageTag } from '../../../../shared/utilities/locale-utilities';
import { Locale } from '../../../../core/services/session/models/session-record';

export interface PaymentWidgetDelegate {
  widgetBeganLoading(): void;
  widgetFinishedLoading(): void;
  widgetDidSaveTransactionData(): void;
  widgetSubmissionError(error: any): void;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentWidgetInjectionService {
  private wpwlOptionsElement: HTMLElement;
  private paymentScriptElement: HTMLElement;

  private readonly aciScriptTagPattern = new RegExp(
    /.*\.oppwa\.com.*\/static\.min\.js/,
  );
  private readonly aciSuccessPattern = new RegExp(
    /^(000\.000\.|000\.100\.1|000\.[36])|^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)|^(000\.200)|^(800\.400\.5|100\.400\.500)/,
  );
  constructor(
    private readonly environmentSpecificService: EnvironmentSpecificService,
    private readonly _window: Window,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly sessionFacade: SessionFacade,
    private readonly ngZone: NgZone,
  ) {}

  public initPaymentElement(
    checkoutId: string,
    parentElement: ElementRef,
    anchorElement: ElementRef,
    renderer2: Renderer2,
    delegate?: PaymentWidgetDelegate,
  ) {
    if (!checkoutId) {
      return;
    }
    this.removeCreditPaymentElements(renderer2);
    this.sessionFacade
      .getLoadedLocale()
      .pipe(first())
      .subscribe((locale) => {
        const wpwlOptionsElement = this.createWpwlConfigurationElement(
          locale,
          renderer2,
        );
        const paymentScriptElement = this.createCreditPaymentElement(
          checkoutId,
          renderer2,
        );

        delegate?.widgetBeganLoading();

        this.prependPaymentForm(parentElement, anchorElement, renderer2);
        renderer2.appendChild(this._document.head, wpwlOptionsElement);
        renderer2.appendChild(this._document.head, paymentScriptElement);

        this.wpwlOptionsElement = wpwlOptionsElement;
        this.paymentScriptElement = paymentScriptElement;

        this._window.wpwlOptions.onSaveTransactionData = (response: any) => {
          this.ngZone.run(() => {
            const successResponse = this.aciSuccessPattern.test(
              response.result?.code,
            );

            if (successResponse) {
              delegate?.widgetDidSaveTransactionData();
            } else {
              delegate?.widgetSubmissionError(response);
              this.unloadWidget();
              this.initPaymentElement(
                checkoutId,
                parentElement,
                anchorElement,
                renderer2,
                delegate,
              );
            }
          });
        };

        // onReady is defined in the element we insert into document.head
        // want to extend it here - and not overwrite it.
        const originalOnReady: () => void = this._window.wpwlOptions?.onReady;
        this._window.wpwlOptions.onReady = () => {
          if (originalOnReady) {
            originalOnReady();
          }
          this.ngZone.run(() => {
            delegate?.widgetFinishedLoading();
          });
        };

        this._window.wpwlOptions.onError = (error: any) => {
          this.ngZone.run(() => {
            delegate?.widgetSubmissionError(error);
            this.unloadWidget();
          });
        };
      });
  }

  public unloadWidget() {
    if (this._window.wpwl?.unload) {
      this._window.wpwl.unload();
    }

    document.querySelectorAll('script').forEach((tag) => {
      if (this.aciScriptTagPattern.test(tag.src)) {
        tag.remove();
      }
    });
  }

  public removeCreditPaymentElements(renderer2: Renderer2) {
    if (this.paymentScriptElement) {
      renderer2.removeChild(this._document.head, this.paymentScriptElement);
      this.paymentScriptElement = null;
    }

    if (this.wpwlOptionsElement) {
      if (this._window.wpwlOptions?.onError) {
        this._window.wpwlOptions.onError = null;
      }

      if (this._window.wpwlOptions?.onReady) {
        this._window.wpwlOptions.onReady = null;
      }

      if (this._window.wpwlOptions?.onSaveTransactionData) {
        this._window.wpwlOptions.onSaveTransactionData = null;
      }
      renderer2.removeChild(this._document.head, this.wpwlOptionsElement);
      this.wpwlOptionsElement = null;
    }
  }

  private prependPaymentForm(
    parentElement: ElementRef,
    anchorElement: ElementRef,
    renderer2: Renderer2,
  ) {
    const paymentForm = renderer2.createElement('form');
    renderer2.setAttribute(paymentForm, 'class', 'paymentWidgets');
    renderer2.setAttribute(
      paymentForm,
      'data-brands',
      'AMEX DINERS DISCOVER JCB MASTER VISA UNIONPAY',
    );
    renderer2.setAttribute(
      paymentForm,
      'action',
      NaooConstants.CART_REVIEW_URL,
    );
    renderer2.insertBefore(
      parentElement.nativeElement,
      paymentForm,
      anchorElement.nativeElement,
    );
  }

  private createCreditPaymentElement(
    checkoutId: string,
    renderer2: Renderer2,
  ): any {
    const element = renderer2.createElement('script');
    element.id = 'wpwlCreditPaymentElement';
    element.type = 'text/javascript';
    element.async = true;
    element.src = `${this.environmentSpecificService.getAciUrl()}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
    return element;
  }

  private createWpwlConfigurationElement(
    locale: Locale,
    renderer2: Renderer2,
  ): any {
    const element = renderer2.createElement('script');
    element.type = 'text/javascript';
    element.async = false;
    element.id = 'wpwlCreditPaymentOptions';
    element.text = this.createWpwlOptionsScriptContents(locale);
    return element;
  }

  private createWpwlOptionsScriptContents(locale: Locale) {
    const language = locale.substring(0, 2);

    return `
            function changeLabel(targetClasses, newLabel) {
              const target = document.querySelector(targetClasses);
              target.innerHTML = newLabel;
            }

            function addLabel(targetClasses, classesToAdd, labelInnerText) {
              const labelToAdd = document.createElement('div');
              labelToAdd.classList.add(classesToAdd);
              labelToAdd.innerHTML = labelInnerText;
              const parent = document.querySelector(targetClasses);
              parent.insertBefore(labelToAdd, parent.children[0]);
            }

            function validateHolder(e){
              var holder = document.querySelector('.wpwl-control-cardHolder');
              if (holder.value.trim().length < 2){
                const labelToAdd = document.createElement('div');
                const wrapper = document.querySelector('.wpwl-wrapper-cardHolder');
                labelToAdd.classList.add('wpwl-has-error');
                labelToAdd.innerHTML = translations['${language}'].CARDHOLDER_NAME_ERROR;
                holder.classList.add('wpwl-has-error');
                wrapper.appendChild(labelToAdd);
                return false;
              }
              return true;
            }

            function resetBrand(allBrands){
                allBrands.forEach(brand =>
                    document.querySelector('.wpwl-brand').classList.remove('wpwl-brand-svg-' + brand)
                );
                document.querySelector('.wpwl-brand').classList.add('wpwl-brand-svg-unknown');
            }

            var translations = {
              fr: {
                CARDHOLDER_NAME_ERROR: 'Titulaire de la carte invalide',
                CARD_NUMBER: 'Numéro de carte',
                SECURITY_CODE: 'Code de sécurité',
                POSTAL_CODE: 'Code postal',
                EXPIRATION_DATE: "Date d'expiration",
              },
              en: {
                CARDHOLDER_NAME_ERROR: 'Invalid cardholder name',
                CARD_NUMBER: 'Card number',
                SECURITY_CODE: 'Security code',
                POSTAL_CODE: 'Postal code',
                EXPIRATION_DATE: 'Expiration date',
              },
            };

            var brands = ['VISA','MASTER','AMEX','DISCOVER','JCB','DINERS','UNIONPAY'];

            var wpwlOptions = {
              brandDetection: true,
              brandDetectionPriority: brands,
              disableSubmitOnEnter: true,
              locale: '${getLanguageTag(locale)}',
              style: 'card',
              useSummaryPage: true,
              imageStyle: 'svg',
              billingAddress: {},
              mandatoryBillingFields: {
                country: false,
                state: false,
                city: false,
                postcode: true,
                street1: false,
                street2: false,
              },
              onReady: function() {
                resetBrand(brands);
                changeLabel(
                  '.wpwl-label-cardNumber',
                  translations['${language}'].CARD_NUMBER);
                changeLabel(
                  '.wpwl-label-cvv',
                  translations['${language}'].SECURITY_CODE);
                changeLabel(
                  '.wpwl-label-expiry',
                  translations['${language}'].EXPIRATION_DATE);
                addLabel(
                  '.wpwl-sup-wrapper-postcode',
                  'wpwl-group-postalCode',
                  translations['${language}'].POSTAL_CODE);
              },
              onBeforeSubmitCard: function(e){
                return validateHolder(e);
              },
              onDetectBrand: function(detectedBrands){
                document.querySelector('.wpwl-brand').classList.remove('wpwl-brand-svg-unknown');
                if(!detectedBrands?.length) {
                  resetBrand(brands);
                }
              },
            };`;
  }
}
