<div class="select-date-step">
  <div class="calendar-wrapper">
    @if (isMobileWidth && isRouteDateExpired) {
      <ng-container [ngTemplateOutlet]="expiredRoute"></ng-container>
    }
    <naoo-select-date-step-calendar
      [fulfillmentType]="fulfillmentType"
      [availableDates]="availableDates"
      [selectedDate]="selectedDate"
      (dateChangeEmitter)="dateChangeEmitter.emit($event)"
    >
    </naoo-select-date-step-calendar>
  </div>
  <div class="calendar-details">
    @if (!isMobileWidth && isRouteDateExpired) {
      <ng-container [ngTemplateOutlet]="expiredRoute"></ng-container>
    }
    @if (selectedDate && !isMobileWidth) {
      <div class="selected-date">
        {{ 'ORDER_METHOD_MODAL.DATE.FORM.DELIVERY_ON' | translate }}
        <span class="selected-value">
          {{ selectedDate | naooDate: 'shortYearDate' }}
        </span>
      </div>
    }
    @if (cutoffDateTime) {
      <div class="cutoff-datetime">
        {{ 'ORDER_METHOD_MODAL.DATE.FORM.DEADLINE' | translate }}
        <span class="selected-value">
          {{
            cutoffDateTime | naooDate: 'shortYearDateTime' : timeZoneCodeCutOff
          }}
          {{ timeZone }}
        </span>
      </div>
    }
  </div>
</div>

<ng-template #expiredRoute>
  <div class="invalid-date-text">
    {{ 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_MESSAGE' | translate }}
  </div>
</ng-template>
