@if (materialMovementDetails?.materialMovementEntries.length > 0) {
  <div class="top-line"></div>
  <div class="material-movement-row material-movement-header">
    <div class="order-cell order-week-of">
      {{ 'MATERIAL_MOVEMENT.WEEK_OF' | translate }}
    </div>
    <div class="order-container order-row">
      <div class="order-cell order-ship-date">
        {{ 'MATERIAL_MOVEMENT.DATE' | translate }}
      </div>
      @for (unit of availableUnits; track unit) {
        <div class="order-cell order-uom">
          {{ { uomCode: unit.displayCode } | materialunits: false }}
        </div>
      }
    </div>
  </div>
  @for (
    materialMovement of materialMovementDetails?.materialMovementEntries
      | slice: 0 : numberOfWeeks;
    track materialMovement.week
  ) {
    <div class="material-movement-row order-main">
      <div class="order-cell order-week-of">
        <span class="order-week-of-label">{{
          'MATERIAL_MOVEMENT.WEEK_OF' | translate
        }}</span>
        <span class="order-week-of-value">{{
          materialMovement.week | naooDate: 'shortDate' : ''
        }}</span>
      </div>
      <div class="order-container">
        @if (materialMovement.orders?.length) {
          @for (order of materialMovement.orders; track order) {
            <div class="order-row order-main">
              <div class="order-cell order-delivery-date">
                <span class="order-ship-date-label">
                  {{ 'MATERIAL_MOVEMENT.DATE' | translate }}
                </span>
                <span class="order-movement-date-value">{{
                  order.shipDate | naooDate: 'mediumDate' : ''
                }}</span>
              </div>
              @for (unit of availableUnits; track unit) {
                <div class="order-cell">
                  <div class="order-cell order-uom">
                    <span class="order-uom-label">{{
                      { uomCode: unit.displayCode } | materialunits: false
                    }}</span>
                    <span class="order-uom-value">{{
                      findOrderLineForUom(order, unit.uom)?.quantity
                    }}</span>
                  </div>
                </div>
              }
            </div>
          }
        } @else {
          <div class="order-row order-main empty-order">
            <div class="order-cell order-delivery-date">
              <span class="order-ship-date-label">
                {{ 'MATERIAL_MOVEMENT.DATE' | translate }}
              </span>
              <span class="order-movement-date-value"></span>
            </div>
            @for (unit of availableUnits; track unit) {
              <div class="order-cell order-uom">
                <span class="order-uom-label">{{
                  { uomCode: unit.displayCode } | materialunits
                }}</span>
                <span class="order-uom-value">0</span>
              </div>
            }
          </div>
        }
      </div>
    </div>
  }
  <div class="load-more-container">
    @if (hasMoreData) {
      <button class="secondary-button load-more-button" (click)="loadMore()">
        {{ 'MATERIAL_MOVEMENT.LOAD_MORE' | translate }}
      </button>
    } @else {
      <div class="no-more-data">
        {{ 'MATERIAL_MOVEMENT.NO_MORE_DATA' | translate }}
      </div>
    }
  </div>
}
