<div class="guides-action-bar">
  <div class="search-bar">
    <div class="search-icon">
      <mat-icon svgIcon="search_icon_v2"></mat-icon>
    </div>
    <div class="search-box">
      <input
        type="text"
        id="guide-search-input"
        name="searchText"
        autocomplete="off"
        #searchText
        [placeholder]="'ORDER_GUIDE.FILTER_PLACEHOLDER' | translate"
        [(ngModel)]="searchTextModel"
        (ngModelChange)="handleUpdateSearchText($event)"
      />

      @if (searchTextModel?.length) {
        <button
          class="clear-search-button"
          [attr.aria-label]="'SEARCH.CANCEL' | translate"
          (click)="clearAndFocus()"
        >
          <mat-icon svgIcon="clear_icon"></mat-icon>
        </button>
      }
    </div>
  </div>

  <div class="list-style-select">
    <naoo-material-view-select
      [selectedViewStyle]="selectedView"
      [viewStyleOptions]="selectableViews"
      [shouldHideLabel]="true"
      (onMaterialViewChange)="handleUpdateMaterialView($event)"
    ></naoo-material-view-select>
  </div>

  @if (shouldRenderParContainer) {
    <div class="par-container">
      <naoo-guides-par-actions
        [isParEnabled]="parEnabled"
        [isOffline]="isOffline"
        (clearInventoryQuantities)="handleClearInventoryQuantities()"
        (toggleParOrdering)="handleToggleParOrdering($event)"
      ></naoo-guides-par-actions>
    </div>
  }
</div>
