import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialRow } from '../../../../../../../core/store/material-row/models/material-row';
import { PurchaseMaterial } from '../../../../../../../core/store/store-purchase-details/models/store-purchase-details-view-model';
import { FulfillmentType } from '../../../../../../../core/services/cart/models/cart-record';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MaterialImageComponent } from '../../../../../../../material-list/material-row-container/shared/material-image/material-image.component';
import { MaterialStoreStockComponent } from '../../../../../../../material-list/material-row-container/shared/material-store-stock/material-store-stock.component';
import { MaterialInfoComponent } from '../../../../../../../material-list/material-row-container/shared/material-info/material-info.component';
import { GoPointsLogoComponent } from '../../../../../../../shared/go-points-logo/go-points-logo.component';
import { MaterialOptionsMenuComponent } from '../../../../../../../material-list/material-row-container/shared/material-options-menu/material-options-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../../../../../../shared/pipes/material-units.pipe';
import { NaooCurrencyPipe } from '../../../../../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-store-purchase-row-content',
  templateUrl: './store-purchase-details-row-content.component.html',
  styleUrls: ['./store-purchase-details-row-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    MaterialImageComponent,
    MaterialStoreStockComponent,
    MaterialInfoComponent,
    NgTemplateOutlet,
    GoPointsLogoComponent,
    MaterialOptionsMenuComponent,
    TranslateModule,
    MaterialUnitsPipe,
    NaooCurrencyPipe,
  ],
})
export class StorePurchaseDetailsRowContentComponent {
  @Input() purchaseMaterial: PurchaseMaterial;
  @Input() fulfillmentType: FulfillmentType;
  @Input() materialRow: MaterialRow;
  @Input() isMobileWidth: boolean;
  @Input() isTabletWidth: boolean;
  @Input() isLast: boolean;
}
