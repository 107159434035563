<mat-radio-group
  aria-label="guide-group-by-label"
  class="group-by-radio-group"
  [value]="selectedGroupBy"
  (change)="changeGroup($event)"
>
  @for (option of groupByOptions; track option) {
    <mat-radio-button
      class="group-by-radio-button"
      [value]="option.toString()"
      [disableRipple]="true"
      [disabled]="shouldDisableButton(option)"
    >
      {{ displayKeyForGroupBy(option) | translate }}
    </mat-radio-button>
  }
</mat-radio-group>
