<div
  class="edit-custom-guide-category"
  [@.disabled]="disableAnimations"
  [@highlightCategory]="highlightCategoryState"
  [@moveRow]="moveState"
  (@moveRow.done)="animationComplete()"
  (@highlightCategory.done)="highlightComplete()"
>
  <div
    [attr.aria-label]="
      localizedHeaderAriaLabel
        | translate
          : {
              productCount: categorizedMaterial.items?.length || 0,
              productCategory:
                (categorizedMaterial.categoryName | stringdefaulter) ||
                ('ORDER_GUIDE.OTHER' | translate),
            }
    "
    class="category-header"
    id="{{
      categorizedMaterial.categoryName.en || ('ORDER_GUIDE.OTHER' | translate)
    }}"
  >
    <span class="clamp">{{
      (categorizedMaterial.categoryName | stringdefaulter) ||
        ('ORDER_GUIDE.OTHER' | translate)
    }}</span>
    <span class="item-total"
      >&nbsp;({{ categorizedMaterial.items?.length || 0 }})</span
    >
    @if (hasCategoryName) {
      <div class="edit-category">
        <button
          tabindex="0"
          attr.aria-label="{{
            isUpArrowActive
              ? ('LISTS.MOVE_CATEGORY_UP_BUTTON' | translate)
              : ('LISTS.MOVE_CATEGORY_UP_BUTTON_DISABLED' | translate)
          }}"
          mat-icon-button
          class="mat-icon-button up-arrow"
          [ngClass]="{
            'active-button': isUpArrowActive && groupBy === 'custom',
          }"
          [disabled]="!isUpArrowActive || groupBy !== 'custom'"
          (click)="moveUp()"
        >
          <mat-icon
            svgIcon="category_up_active"
            [ngClass]="{
              'inactive-svg': !isUpArrowActive || groupBy !== 'custom',
            }"
          ></mat-icon>
        </button>
        <button
          tabindex="0"
          attr.aria-label="{{
            isDownArrowActive
              ? ('LISTS.MOVE_CATEGORY_DOWN_BUTTON' | translate)
              : ('LISTS.MOVE_CATEGORY_DOWN_BUTTON_DISABLED' | translate)
          }}"
          mat-icon-button
          class="mat-icon-button down-arrow"
          [ngClass]="{
            'active-button': isDownArrowActive && groupBy === 'custom',
          }"
          [disabled]="!isDownArrowActive || groupBy !== 'custom'"
          (click)="moveDown()"
        >
          <mat-icon
            svgIcon="category_down_active"
            [ngClass]="{
              'inactive-svg': !isDownArrowActive || groupBy !== 'custom',
            }"
          >
          </mat-icon>
        </button>
        <naoo-category-edit-menu
          [isDisabled]="groupBy !== 'custom'"
          [customGuideId]="customGuideId"
          [categorizedMaterials]="categorizedMaterial"
          (renameCategory)="actOnRenameCategory($event)"
          (deleteCategory)="actOnDeleteCategory($event)"
        >
        </naoo-category-edit-menu>
      </div>
    }
  </div>

  <div
    class="item-drag"
    cdkDropList
    [cdkDropListAutoScrollStep]="10"
    (cdkDropListDropped)="drop($event)"
  >
    @if (categorizedMaterial.items.length < 1) {
      <naoo-product-guide-edit
        class="no-drag"
        [customGuideId]="customGuideId"
        [guideItem]="placeHolderItem"
        [isUpActive]="false"
        [isDownActive]="false"
        (moveMaterial)="updateProductPosition($event)"
        (actOnProduct)="actOnMaterialInCategory($event)"
      >
      </naoo-product-guide-edit>
    } @else {
      @for (
        item of categorizedMaterial.items;
        track item?.material?.materialNumber;
        let isFirst = $first;
        let isLast = $last
      ) {
        <div
          class="drag"
          cdkDrag
          [cdkDragData]="item.material.materialNumber"
          [cdkDragDisabled]="disableDrag()"
        >
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <naoo-product-guide-edit
            [sortBy]="sortBy"
            [groupBy]="groupBy"
            [customGuideId]="customGuideId"
            [guideItem]="item"
            [isUpActive]="!isFirst && !shouldDisableButtons"
            [isDownActive]="!isLast && !shouldDisableButtons"
            [enableParOrdering]="enableParOrdering"
            [materialParOrder]="
              getMaterialParOrder(item.material.materialNumber)
            "
            (moveMaterial)="updateProductPosition($event)"
            (actOnProduct)="actOnMaterialInCategory($event)"
          >
          </naoo-product-guide-edit>
        </div>
      }
    }
  </div>
</div>
