import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  NotificationMethodCode,
  Notifications,
  NotificationsCustomer,
} from '../../shared/models/customer-notifications';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-preferences-header',
  templateUrl: './preferences-header.component.html',
  styleUrls: ['./preferences-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconButton, MatIcon, TitleCasePipe, TranslateModule],
})
export class PreferencesHeaderComponent {
  @Input() customer: NotificationsCustomer;
  @Input() notifications?: Notifications;
  @Input() methodCode: NotificationMethodCode;
  @Output() addRequest = new EventEmitter();

  get customerDisplay(): string {
    return `${this.customer.name}, ${this.customer.address}`;
  }

  get isSms(): boolean {
    return NotificationMethodCode.Sms === this.methodCode;
  }

  processAdd() {
    this.addRequest.emit();
  }

  getNotificationsText(key: string): string {
    return `PREFERENCES.${this.methodCode}_NOTIFICATIONS.${key}`;
  }
}
