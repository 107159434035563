@if (shouldShowWarning) {
  <div
    class="warning-container"
    (click)="toggleWarningOpen()"
    tabindex="0"
    (keyup.enter)="toggleWarningOpen()"
    [attr.aria-label]="warning.message | translate: warning.messageParams"
  >
    <div
      class="icon-wrapper"
      [ngClass]="[warningActiveClass, warningTooltipClass, warningIconClass]"
    >
      @if (this.warning.isOpen) {
        <mat-icon
          [svgIcon]="'cancel_icon'"
          class="warning-tooltip"
          [ngClass]="[
            warningActiveClass,
            warningTooltipClass,
            tooltipIconClass,
            tooltipClassForTwoLine,
          ]"
          [attr.tooltip]="warning.message | translate: warning.messageParams"
          @fadeInAndOut
        >
        </mat-icon>
      } @else {
        <mat-icon [svgIcon]="'bang_icon'" @fadeInAndOut> </mat-icon>
      }
    </div>
  </div>
}
