<div class="mobile-actions-header">
  {{ 'ORDER_GUIDE.OPTIONS' | translate }}
  <button autofocus class="close-button" (click)="closeMobileActionModal()">
    <mat-icon svgIcon="cancel_icon"></mat-icon>
  </button>
</div>

<naoo-guides-side-bar
  [sideBar]="sideBar"
  [isOffline]="isOffline"
  [sortByDisabled]="isSortByDisabled"
  (groupByChange)="handleGroupByChanged($event)"
  (sortByChange)="handleSortByChanged($event)"
></naoo-guides-side-bar>

<div class="view-select-container">
  <h2>{{ 'MATERIAL_VIEW_TOGGLE.VIEW_ITEMS' | translate }}</h2>
  <naoo-material-view-select
    [shouldHideLabel]="true"
    [selectedViewStyle]="preferredView"
    [viewStyleOptions]="selectableViews"
  >
  </naoo-material-view-select>
</div>

@if (shouldRenderParContainer) {
  <div class="par-container">
    <h2>{{ 'LISTS.GUIDE_ACTIONS_TITLE' | translate }}</h2>
    <naoo-guides-par-actions
      [isParEnabled]="isParEnabled"
      [isOffline]="isOffline"
      (clearInventoryQuantities)="handleClearInventoryQuantities()"
      (toggleParOrdering)="handleToggleParOrdering($event)"
    ></naoo-guides-par-actions>
  </div>
}
