import { Component, Input } from '@angular/core';
import { HeaderError } from '../shared/models/order-confirmation';
import { MatIcon } from '@angular/material/icon';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-order-confirmation-header-error',
  templateUrl: './order-confirmation-header-error.component.html',
  styleUrls: ['./order-confirmation-header-error.component.scss'],
  standalone: true,
  imports: [MatIcon, NaooStringDefaulterPipe],
})
export class OrderConfirmationHeaderErrorComponent {
  @Input() headerError: HeaderError;
}
