import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaxonomyFilter } from 'src/app/core/store/search/shared/search-results';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-taxonomy-filter',
  templateUrl: './taxonomy-filter.component.html',
  styleUrls: ['./taxonomy-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule, NaooStringDefaulterPipe],
})
export class TaxonomyFilterComponent {
  @Input() taxonomyFilter: TaxonomyFilter;
  @Input() isFirst: boolean;

  private readonly baseMargin = 16;
  private readonly bracketedMargin = 8;
  private readonly bracketedDepths = [2, 3];

  constructor(private searchFacade: SearchFacade) {}

  toggleTaxonomyFilter() {
    if (this.taxonomyFilter.canToggle) {
      this.searchFacade.toggleTaxonomyFilter(
        this.taxonomyFilter.categoryKey,
        this.taxonomyFilter.name.en,
      );
    }
  }

  get isTopLevelFilter(): boolean {
    return this.taxonomyFilter.depth === 0;
  }

  get depthBasedLeftMargin(): number {
    return (
      this.taxonomyFilter.depth * this.baseMargin +
      (this.shouldShowTaxonomyBracket ? this.bracketedMargin : this.baseMargin)
    );
  }

  get shouldShowTaxonomyBracket(): boolean {
    return (
      !this.isTopLevelFilter &&
      (this.bracketedDepths.includes(this.taxonomyFilter.depth) ||
        this.taxonomyFilter.isSelected)
    );
  }
}
