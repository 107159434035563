import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-edit-order-guide-gfs-icon',
  templateUrl: './edit-order-guide-gfs-icon.component.html',
  styleUrls: ['./edit-order-guide-gfs-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class EditOrderGuideGfsIconComponent {}
