@if (productSuccessfullyMoved) {
  <div class="success">
    {{ 'LISTS.PRODUCT_ADDED_TOAST' | translate }}
    <strong>{{ categoryName || ('ORDER_GUIDE.OTHER' | translate) }}</strong>
  </div>
} @else {
  <div class="fail">
    {{ 'LISTS.PRODUCT_ALREADY_FOUND_TOAST' | translate }}
    <strong>{{ categoryName || ('ORDER_GUIDE.OTHER' | translate) }}</strong>
  </div>
}
