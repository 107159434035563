import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MaterialDetailsPageViewModel,
  MaterialDetailsSection,
} from '../../core/store/material-details/models/material-details.model';
import { ProductDetailsUtilities } from '../../shared/utilities/product-details-utilities';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { AllergenDetailsComponent } from '../allergen-details/allergen-details.component';
import { NutritionFactsComponent } from '../nutrition/nutrition-facts/nutrition-facts.component';
import { NutritionDetailsComponent } from '../nutrition-details/nutrition-details.component';
import { NutritionContainerComponent } from '../nutrition/nutrition-container/nutrition-container.component';
import { IngredientListComponent } from '../nutrition/ingredients/ingredient-list.component';
import { MaterialMovementComponent } from '../material-movement/material-movement.component';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../shared/pipes/pipe.module';
import { NaooWeightPipe } from '../../shared/pipes/naoo-weight.pipe';

@Component({
  selector: 'naoo-product-secondary-details',
  templateUrl: './product-secondary-details.component.html',
  styleUrls: ['./product-secondary-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatAccordion,
    MatExpansionModule,
    AllergenDetailsComponent,
    NutritionFactsComponent,
    NutritionDetailsComponent,
    NutritionContainerComponent,
    IngredientListComponent,
    MaterialMovementComponent,
    PipeModule,
    NaooDatePipe,
    NaooStringDefaulterPipe,
    TranslateModule,
    NaooWeightPipe,
  ],
})
export class ProductSecondaryDetailsComponent {
  @Input() viewModel: MaterialDetailsPageViewModel;
  @Input() isAccordionView: boolean;

  get MaterialDetailsSection(): typeof MaterialDetailsSection {
    return MaterialDetailsSection;
  }

  get ProductDetailsUtilities(): typeof ProductDetailsUtilities {
    return ProductDetailsUtilities;
  }

  get packaging(): string | undefined {
    return this.viewModel.materialAdditionalInfo?.packagingStorage?.[
      this.viewModel.currentLanguage
    ];
  }
}
