@if (isCustomerSelected$ | async) {
  <button
    id="account-menu"
    attr.aria-label="{{ 'MENU.ACCOUNT' | translate }}"
    class="account-menu-button"
    [ngClass]="{ disabled: isDisabled }"
    [matMenuTriggerFor]="menu"
    (menuClosed)="(!isMenuOpen)"
    (menuOpened)="(isMenuOpen)"
    [disabled]="isDisabled"
  >
    <span>{{ 'MENU.ACCOUNT' | translate }}</span>
    <mat-icon
      class="dropdown-arrow-icon"
      [ngClass]="{ 'rotate-arrow': isMenuOpen }"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button
      mat-menu-item
      class="menu-item"
      attr.aria-label="{{ 'MENU.LANGUAGE_MENU' | translate }}"
      (keydown.enter)="languageMenuButtonPressed($event)"
      (keydown.space)="languageMenuButtonPressed($event)"
      (click)="languageMenuButtonPressed($event)"
    >
      <naoo-language-menu
        class="naoo-language-menu sub-header-action"
        [isDisabled]="isOffline"
        [ngClass]="{
          disabled: isOffline,
        }"
      >
      </naoo-language-menu>
    </button>
    <button
      class="menu-item"
      mat-menu-item
      attr.aria-label="{{ 'MENU.PREFERENCES' | translate }}"
      (click)="navigateToPreferences()"
    >
      <mat-icon class="menu-item-icon gear" svgIcon="gear_icon"></mat-icon>
      {{ 'MENU.PREFERENCES' | translate }}
    </button>
    <button
      class="menu-item"
      mat-menu-item
      attr.aria-label="{{ 'MENU.SUPPORT' | translate }}"
      (click)="showSupportDialog()"
    >
      <mat-icon
        class="menu-item-icon info-filled"
        svgIcon="info_filled_icon"
      ></mat-icon>
      {{ 'MENU.SUPPORT' | translate }}
    </button>
    <button
      id="logout-button"
      class="menu-item"
      mat-menu-item
      attr.aria-label="{{ 'MENU.LOGOUT' | translate }}"
      (click)="logOut()"
    >
      <mat-icon class="menu-item-icon" svgIcon="unavailable"></mat-icon>
      {{ 'MENU.LOGOUT' | translate }}
    </button>
  </mat-menu>
} @else {
  <button
    class="logout-button"
    [ngClass]="{ disabled: isDisabled }"
    attr.aria-label="{{ 'MENU.LOGOUT' | translate }}"
    (click)="logOut()"
    [disabled]="isDisabled"
  >
    {{ 'MENU.LOGOUT' | translate }}
  </button>
}
