<div class="parent-container" [ngClass]="{ 'red-border': shouldShowRedBorder }">
  @if (isOK) {
    <div class="content material-content" @fadeInOnly>
      <div class="image-container">
        <naoo-material-image
          [materialRowOptions]="materialRow.materialRowOptions"
          [isUnorderable]="false"
          [isMobile]="isMobile"
        ></naoo-material-image>
        <naoo-material-store-stored-button
          [fulfillmentType]="fulfillmentType"
          [materialAvailability]="materialAvailability"
          [materialRowOptions]="materialRow.materialRowOptions"
        >
        </naoo-material-store-stored-button>
      </div>
      <div class="divider"></div>
      <div [ngClass]="{ 'full-size': fullSize }">
        <div class="row-details">
          <div class="details-container">
            <div class="details-content">
              <naoo-material-info
                [materialRowOptions]="materialRow.materialRowOptions"
                [materialInfo]="materialRow.materialRowInfo"
                [isMobile]="isMobile"
              ></naoo-material-info>
              <div class="triple-dot">
                <naoo-material-options-menu
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [isMobile]="isMobile"
                >
                </naoo-material-options-menu>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
          </div>
          <div class="divider"></div>
          <div class="ordering-content">
            @if (!!materialRow.materialOrdering) {
              <naoo-material-ordering
                [materialOrdering]="materialRow.materialOrdering"
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
                (quantityInputChanged)="quantityInputChanged.emit($event)"
                (viewCommodityDetailsClicked)="
                  viewCommodityDetailsClicked.emit()
                "
              ></naoo-material-ordering>
            }
          </div>
        </div>
        <div class="footer-content">
          @if (!!materialRow.materialRowFooter) {
            <naoo-material-row-footer
              [materialRowFooter]="materialRow.materialRowFooter"
              [materialRowOptions]="materialRow.materialRowOptions"
            ></naoo-material-row-footer>
          }
        </div>
      </div>
    </div>
  }

  @if (isUnorderable) {
    <div class="content unorderable-content" @fadeInOnly>
      <div class="image-container">
        <!-- TODO: NAOO-32596 update isUnorderable input for MaterialImageComponent -->
        <naoo-material-image
          [materialRowOptions]="materialRow.materialRowOptions"
          [isUnorderable]="false"
          [isMobile]="isMobile"
        ></naoo-material-image>
      </div>
      <div class="divider"></div>
      <div class="row-details">
        <div class="details-container">
          <div class="details-content">
            <naoo-material-info
              [materialRowOptions]="materialRow.materialRowOptions"
              [materialInfo]="materialRow.materialRowInfo"
              [isMobile]="isMobile"
            ></naoo-material-info>
            <div class="triple-dot">
              <naoo-material-options-menu
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
              >
              </naoo-material-options-menu>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
        </div>
        <div class="divider"></div>
        <div class="ordering-content">
          <naoo-material-unorderable
            [materialRowOptions]="materialRow.materialRowOptions"
            [isMobile]="isMobile"
            [materialAvailability]="materialAvailability"
          >
          </naoo-material-unorderable>
        </div>
        <div>
          <naoo-material-similar-items
            class="gridView"
            [isMobile]="isMobile"
            [materialWithSubstitutes]="materialRow"
          >
          </naoo-material-similar-items>
        </div>
        <div class="footer-content">
          @if (!!materialRow.materialRowFooter) {
            <naoo-material-row-footer
              [materialRowFooter]="materialRow.materialRowFooter"
              [materialRowOptions]="materialRow.materialRowOptions"
            ></naoo-material-row-footer>
          }
        </div>
      </div>
    </div>
  }

  @if (isLoading) {
    <div class="content loading-content" @fadeInAndOut>
      <naoo-material-skeleton
        [isMobile]="isMobile"
        [materialListStyle]="materialRow.materialRowOptions.materialListStyle"
      >
      </naoo-material-skeleton>
    </div>
  }
</div>

<ng-template #materialFlag>
  <div class="flags">
    <naoo-material-flag-container
      [flags]="materialRow.flags"
      [maximumNumberOfFlags]="6"
    ></naoo-material-flag-container>
  </div>
</ng-template>
