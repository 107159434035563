import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationLink } from '../models/navigation-link';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'naoo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, MatIcon],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: NavigationLink[];
}
