import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-order-method-button-container',
  templateUrl: './order-method-button-container.component.html',
  styleUrls: ['./order-method-button-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatButton, MatStepperModule, TranslateModule],
})
export class OrderMethodButtonContainerComponent {
  @Input() isFirst: boolean = false;
  @Input() isLast: boolean = false;
  @Input() primaryButtonAction: () => void;
  @Input() isPrimaryButtonDisabled: boolean = false;
  @Input() isPrimaryButtonHidden: boolean = false;
  @Input() secondaryButtonAction: () => void;
  @Input() isSecondaryButtonDisabled: boolean = false;
  @Input() isSecondaryButtonHidden: boolean;

  handlePrimaryButtonClick(): void {
    if (this.primaryButtonAction) {
      this.primaryButtonAction();
    }
  }

  handleSecondaryButtonClick(): void {
    if (this.secondaryButtonAction) {
      this.secondaryButtonAction();
    }
  }
}
