import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialRowSharedOptions } from '../../../../core/store/material-row/models/material-row';
import { NgClass } from '@angular/common';
import { MaterialImageComponent } from '../material-image/material-image.component';
import { MaterialTrashButtonComponent } from '../material-trash-button/material-trash-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-unavailable',
  templateUrl: './material-unavailable.component.html',
  styleUrls: ['./material-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    MaterialImageComponent,
    MaterialTrashButtonComponent,
    TranslateModule,
  ],
})
export class MaterialUnavailableComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;
  @Input() isDeleteHidden: boolean;
}
