import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderItem } from '../../shared/models/order-confirmation';
import { CustomerBrand } from '../../../core/services/session/models/session-record';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { OrderItemRowComponent } from '../../order-item-row/order-item-row.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-still-processing-materials',
  templateUrl: './still-processing-materials.component.html',
  styleUrls: ['./still-processing-materials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    OrderItemRowComponent,
    TranslateModule,
  ],
})
export class StillProcessingMaterialsComponent {
  @Input() processingOrders: OrderItem[];
  @Input() customerBrand: CustomerBrand;

  getCustomerBrand() {
    return (
      'ORDER_CONFIRMATION.PROCESSING_ORDERS.HELP_DESK.' + this.customerBrand
    );
  }
}
