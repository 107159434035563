<div
  class="product-flag"
  role="tooltip"
  [ngClass]="flagStyle"
  [attr.tooltip]="productFlag?.tooltipText"
  [attr.tabindex]="productFlag?.tooltipText ? 0 : null"
  (mouseenter)="hovered()"
>
  <span class="product-flag-title">{{ productFlag?.title }}</span>
</div>
