<mat-form-field class="po-number-field" hideRequiredMarker>
  <mat-icon
    class="number-icon"
    [ngClass]="{ 'disabled-icon': isOffline }"
    matPrefix
    svgIcon="number-icon"
    aria-hidden="true"
  ></mat-icon>
  <mat-label>{{ 'CART.PO_NUMBER' | translate }}</mat-label>
  <input
    matInput
    class="po-number-input"
    type="text"
    maxLength="{{ poNumberMaxLength }}"
    autocomplete="off"
    value="{{ formPoNumber.value }}"
    [formControl]="formPoNumber"
    [errorStateMatcher]="instantErrorState"
    [readonly]="isOffline"
    (keydown)="onKeyDown($event)"
    (keyup)="updatePoNumber()"
    (paste)="onPaste($event)"
  />
  <mat-error>{{ errorText() | translate }}</mat-error>
</mat-form-field>
