import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HelpModalExpansionSection } from '../generic-help-modal.component';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-generic-help-expansion-panel',
  templateUrl: './generic-help-expansion-panel.component.html',
  styleUrls: ['./generic-help-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionPanel,
    NgClass,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    TranslateModule,
  ],
})
export class GenericHelpExpansionPanelComponent {
  @Input() section: HelpModalExpansionSection;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
}
