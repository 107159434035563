<mat-expansion-panel
  [expanded]="!filterGroup.isCollapsed"
  (afterCollapse)="afterCollapse()"
  (afterExpand)="afterExpand()"
>
  <mat-expansion-panel-header
    expandedHeight="100%"
    collapsedHeight="100%"
    [ngClass]="{ collapsed: filterGroup.isCollapsed }"
  >
    <mat-panel-title>
      {{ filterGroup.name | stringdefaulter }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  @for (filter of filters; track filter.name.en) {
    <div class="filter-checkbox">
      <naoo-filter
        [filter]="filter"
        [filterGroupParam]="filterGroup.queryParameterName"
        [filterGroupName]="filterGroup.name.en"
      >
      </naoo-filter>
    </div>
  }
  @if (canViewMore) {
    <div
      class="view-more"
      (click)="viewMore()"
      (keyup.enter)="viewMore()"
      tabindex="0"
      [ngClass]="{ 'view-less': filterGroup.isViewingMore }"
    >
      {{ (filterGroup.isViewingMore ? 'VIEW.LESS' : 'VIEW.MORE') | translate }}
    </div>
  }
</mat-expansion-panel>
<div class="divider" [ngClass]="{ collapsed: filterGroup.isCollapsed }"></div>
