import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NaooConstants } from '../../../shared/NaooConstants';
import { CustomerBrand } from '../../../core/services/session/models/session-record';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { OrderGuide } from '../../../core/store/order-guide/models/order-guide';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { ListsAnalyticsConstants } from '../../lists-analytics.constants';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { NaooBrandPipe } from '../../../shared/pipes/naoo-brand.pipe';

@Component({
  selector: 'naoo-main-guide-card',
  templateUrl: './main-guide-card.component.html',
  styleUrls: ['./main-guide-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, MatDivider, TranslateModule, NaooBrandPipe],
})
export class MainGuideCardComponent {
  @Input() listTitle: string;
  @Input() totalListProducts: number;
  @Input() isCriticalItemsGuide: boolean;
  @Input() customerBrand: CustomerBrand;
  @Input() orderGuide: OrderGuide;

  constructor(
    private router: Router,
    private analytics: NaooAnalyticsManager,
    private customDialogService: CustomDialogService,
  ) {}

  productString(): string {
    return this.totalListProducts !== 1 ? 'LISTS.PRODUCTS' : 'LISTS.PRODUCT';
  }

  get shouldRenderProductCount(): boolean {
    return this.totalListProducts > -1;
  }

  get shouldRenderOrderGuideChanges(): boolean {
    return this.orderGuide?.changes?.length > 0;
  }

  get isCriticalItemImported(): boolean {
    return 'CRITICAL_ITEMS.IMPORTED' === this.listTitle;
  }

  orderGuideClicked() {
    this.router.navigate([NaooConstants.GUIDES_PATH, 'order-guide']);
  }

  criticalItemsClicked() {
    this.router.navigate([NaooConstants.GUIDES_PATH, 'critical-items-guide']);
  }

  openGuideChangesModal() {
    this.customDialogService.orderGuideChangesModal(this.orderGuide.changes);
    const event: AnalyticsEventInfo = {
      action: 'Click',
      category: 'Order Guide',
      label: 'View changes',
    };
    this.analytics.trackAnalyticsEvent(event);
  }

  openCriticalItemHelperModal() {
    this.customDialogService.openCriticalItemHelperModal();
    const event: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.criticalItemsCategory,
      label: 'Learn how',
    };
    this.analytics.trackAnalyticsEvent(event);
  }
}
