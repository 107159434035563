import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'naoo-generic-disableable-link',
  templateUrl: './generic-disableable-link.component.html',
  styleUrls: ['./generic-disableable-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, RouterLink],
})
export class GenericDisableableLinkComponent {
  @Input() routingUrl: string = null;
  @Input() isDisabled = false;
  @Input() className = '';
  @Output() clickEvent = new EventEmitter();
}
