import { Component, Input, OnInit } from '@angular/core';
import { CatalogResponse } from '../../shared/models/catalog/catalog-response';
import { CatalogService } from '../../shared/services/catalog/catalog.service';
import { SearchFacade } from '../../core/store/search/search.facade';
import { NaooConstants } from '../../shared/NaooConstants';
import { ProductSubclassComponent } from '../product-subclass/product-subclass.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-product-class',
  templateUrl: './product-class.component.html',
  styleUrls: ['./product-class.component.scss'],
  standalone: true,
  imports: [ProductSubclassComponent, TranslateModule, NaooStringDefaulterPipe],
})
export class ProductClassComponent implements OnInit {
  @Input() category: CatalogResponse;

  catalogImageSrc: string;
  catalogImageSrcSet: string;

  constructor(
    private catalogService: CatalogService,
    private searchFacade: SearchFacade,
  ) {}

  ngOnInit() {
    const imageSources = this.catalogService.buildImageSrcUrls(
      this.category,
      'png',
    );

    this.catalogImageSrc = imageSources.src;
    this.catalogImageSrcSet = imageSources.srcset;
  }

  noImageAvailableURL(event: UIEvent): void {
    (<HTMLImageElement>event.target).src = NaooConstants.placeHolderImagePath;
    (<HTMLImageElement>event.target).srcset =
      NaooConstants.placeHolderImagePath;
  }

  navigateToCategories(categoryKey: string) {
    this.searchFacade.newCatalogSearch(categoryKey);
  }
}
