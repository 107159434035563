import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CartReviewFacade } from '../../core/store/cart-review/cart-review.facade';
import { first, takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading-service/loading.service';
import { SessionFacade } from 'src/app/core/store/session/session.facade';
import { CustomerBrand } from '../../core/services/session/models/session-record';
import { CartReview } from '../../core/store/cart-review/cart-review.selectors';
import { CartReviewSectionName } from '../../core/store/cart-review/cart-review.state';
import { CartCounts } from '../../core/store/cart/cart.selectors';
import { NaooConstants } from '../../shared/NaooConstants';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { CartReviewContentComponent } from './cart-review-content/cart-review-content.component';
import { CostSummaryContainerComponent } from '../../cost-summary/cost-summary-container.component';
import { CostSummaryContext } from '../../cost-summary/shared/cost-summary';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BannerOfflineContainerComponent } from '../../shared/banner-offline-container/banner-offline-container.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatDivider } from '@angular/material/divider';
import { AddPaymentContentComponent } from './add-payment/add-payment-content/add-payment-content.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NaooBrandPipe } from '../../shared/pipes/naoo-brand.pipe';

@Component({
  selector: 'naoo-cart-review',
  styleUrls: ['./cart-review-container.component.scss'],
  templateUrl: './cart-review-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BannerOfflineContainerComponent,
    MatProgressBar,
    NgClass,
    RouterLink,
    MatDivider,
    MatStepperModule,
    NgTemplateOutlet,
    AddPaymentContentComponent,
    MatIcon,
    CartReviewContentComponent,
    CostSummaryContainerComponent,
    TranslateModule,
    AsyncPipe,
    NaooBrandPipe,
  ],
})
export class CartReviewContainerComponent implements OnInit, OnDestroy {
  cartReview: CartReview;
  cartReviewCartCounts$: Observable<Map<CartReviewSectionName, CartCounts[]>>;
  dateHasBeenResolved$: Observable<boolean> =
    this.cartReviewFacade.getDateHasBeenResolved();
  customerBrand$: Observable<CustomerBrand>;
  isLoading$: Observable<boolean>;
  private destroyed$ = new Subject<void>();
  @ViewChild('cartReviewContentComponent')
  cartReviewContentComponent: CartReviewContentComponent;
  @ViewChild('costSummaryContainer', { static: true })
  costSummaryContainerComponent: CostSummaryContainerComponent;
  @ViewChild(MatStepper) stepper: MatStepper;

  readonly guidesPath = NaooConstants.GUIDES_PATH;

  private didClickAddPayment = false;
  private paymentAvailable = false;
  isOfflineBannerVisible: boolean;

  submitButtonClicked = false;

  constructor(
    private analyticsManager: NaooAnalyticsManager,
    private cartReviewFacade: CartReviewFacade,
    private loadingService: LoadingService,
    private sessionFacade: SessionFacade,
    private changeDetector: ChangeDetectorRef,
  ) {}

  get canAddPayment(): boolean {
    const isStore = this.cartReview?.groups?.some((group) =>
      [
        CartReviewSectionName.PickupItemsSection,
        CartReviewSectionName.ExpressItemsSection,
      ].includes(group.name),
    );

    return (
      isStore && this.cartReview?.storeOrderPaymentDetails?.paymentAvailable
    );
  }

  get summaryContext(): CostSummaryContext {
    if (this.paymentAvailable) {
      if (this.isAddPaymentStep) {
        return CostSummaryContext.AddPayment;
      }
      return CostSummaryContext.CartReviewPaymentRequired;
    } else {
      return CostSummaryContext.CartReview;
    }
  }

  get isAddPaymentStep(): boolean {
    return this.didClickAddPayment;
  }

  get titleText(): string {
    return `CART_REVIEW.${this.isAddPaymentStep ? 'ADD_PAYMENT.' : ''}TITLE`;
  }

  get hasOnlySuccessSection(): boolean {
    return this.cartReviewContentComponent?.hasOnlySuccessSection;
  }

  ngOnInit(): void {
    this.cartReviewFacade.loadCartReview();
    this.isLoading$ = this.loadingService.loadingState;

    this.cartReviewFacade
      .getLoadedCartReview()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((cartReview) => {
        this.cartReview = cartReview;
        this.paymentAvailable =
          cartReview?.storeOrderPaymentDetails?.paymentAvailable;
        this.changeDetector.markForCheck();
      });

    this.cartReviewCartCounts$ =
      this.cartReviewFacade.getCartCountsForCartReview();

    this.customerBrand$ = this.sessionFacade
      .getLoadedCustomerBrand()
      .pipe(first());
  }

  ngOnDestroy(): void {
    this.loadingService.stop();
    this.cartReviewFacade.clearCartReview();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackLogoClick() {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'general',
      label: 'logo',
    };

    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  handleSubmitButton() {
    if (this.summaryContext == CostSummaryContext.CartReviewPaymentRequired) {
      this.didClickAddPayment = true;
      this.stepper.next();
    } else {
      this.submitButtonClicked = true;
      this.cartReviewContentComponent?.handleSubmitButton();
    }
    this.changeDetector.markForCheck();
  }

  backLinkClicked() {
    if (this.isAddPaymentStep) {
      this.didClickAddPayment = false;
      this.stepper.previous();
    }
  }

  updateFooterVisibility(): void {
    this.costSummaryContainerComponent?.updateFooterVisibility();
  }

  handleStepperChange($event: StepperSelectionEvent) {
    this.didClickAddPayment = $event.selectedIndex === 1;
  }

  setOfflineBannerVisible(isVisible: boolean) {
    this.isOfflineBannerVisible = isVisible;
    this.changeDetector.markForCheck();
  }
}
