import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { Subject } from 'rxjs';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { CustomGuideCreationSourceAnalyticLabel } from '../../lists-analytics.constants';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-custom-guide-edit-menu',
  templateUrl: './custom-guide-edit-menu.component.html',
  styleUrls: ['./custom-guide-edit-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    NgClass,
    TranslateModule,
  ],
})
export class CustomGuideEditMenuComponent implements OnDestroy {
  @Input() customGuideName: string;
  @Input() showCopyGuide: boolean;
  @Input() customGuideId: string;
  @Input() isUserOffline = false;

  menuAction: () => void;
  private destroyed$ = new Subject<void>();

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private dialogService: DefaultDialogService,
    private customDialogService: CustomDialogService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openRenameModal() {
    this.customDialogService.openRenameCustomGuideModal(
      this.customGuideId,
      this.customGuideName,
    );
  }

  openDuplicateModal() {
    this.customDialogService.openCreateCustomGuideModal(
      CustomGuideCreationSourceAnalyticLabel.Duplicate,
      [],
      this.customGuideId,
    );
  }

  openDeleteModal() {
    this.dialogService.twoButtonModal(
      'delete-custom-guide',
      {
        messageKey: 'LISTS.DELETE_CUSTOM_GUIDE_MODAL.TITLE',
        parameters: {
          value: this.customGuideName,
        },
      },
      'LISTS.DELETE_CUSTOM_GUIDE_MODAL.DELETE',
      'CANCEL.TEXT',
      () => {
        this.customGuideFacade.deleteCustomGuide(this.customGuideId);
      },
      () => {},
      true,
      true,
    );
  }

  openCopyModal() {
    this.customDialogService.copyGuideModal({
      customGuideName: this.customGuideName,
      customGuideId: this.customGuideId,
    });
  }

  menuClosed() {
    // if an action needs to be performed based on user selecting a menu option
    // it needs to be done after the menu closes and not before
    // otherwise events like focus can execute unpredictably.
    if (this.menuAction) {
      this.menuAction();
      this.menuAction = null;
    }
  }
}
