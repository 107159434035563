@if (isParEnabled) {
  <button
    class="clear-inventory-button"
    attr.aria-label="{{ 'LISTS.CLEAR_INVENTORY' | translate }}"
    [ngClass]="{ disabled: isOffline }"
    [disabled]="isOffline"
    (click)="clearInventory()"
  >
    <mat-icon svgIcon="clear_filter_icon" class="clear-inventory-icon">
    </mat-icon>
    <span class="clear-inventory-label">
      {{ 'LISTS.CLEAR_INVENTORY' | translate }}
    </span>
  </button>
}

<div class="slide-toggle-container">
  <naoo-slide-toggle
    class="par-toggle"
    [name]="'LISTS.PAR' | translate"
    [isToggled]="isParEnabled"
    [isDisabled]="isOffline"
    (onToggle)="toggleParEnabled($event)"
  ></naoo-slide-toggle>
</div>
