import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CustomDialogService } from '../../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { DOCUMENT } from '@angular/common';
import { ActiveCartSummaryFacade } from '../../../../core/store/active-cart/active-cart-summary.facade';
import { HeaderHeightService } from '../../../../shared/services/header-height/header-height.service';
import { CustomerBrand } from '../../../../core/services/session/models/session-record';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class QuickAddComponent implements AfterViewInit, OnInit {
  @Input() customerBrand: CustomerBrand;

  readonly maxLengthCaseQuantity = 3;
  readonly materialInput = 'material';
  readonly quantityInput = 'quantity';
  readonly caseQuantityInputRegexp = new RegExp(
    `^[0-9]{0,${this.maxLengthCaseQuantity}}$`,
  );
  private readonly minLengthMaterialNumber = 6;
  private readonly defaultMaxLengthMaterialNumber = 7;
  private readonly usCustomerMaxLengthMaterialNumber = 6;
  private isMaterialNumberValid = false;
  private isCaseQuantityValid = true;
  private materialInputRegexp: RegExp;

  maxLengthMaterialNumber: number;

  @ViewChild('materialNumber', { static: true }) materialNumber: ElementRef;
  @ViewChild('caseQuantity', { static: true }) caseQuantity: ElementRef;
  @ViewChild('container', { static: true }) container: ElementRef;

  constructor(
    private activeCartSummaryFacade: ActiveCartSummaryFacade,
    private customDialogService: CustomDialogService,
    private headerHeightService: HeaderHeightService,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ngOnInit(): void {
    this.maxLengthMaterialNumber =
      CustomerBrand.GFS_US.valueOf() === this.customerBrand?.valueOf()
        ? this.usCustomerMaxLengthMaterialNumber
        : this.defaultMaxLengthMaterialNumber;
    this.materialInputRegexp = new RegExp(
      `^[0-9]{0,${this.maxLengthMaterialNumber}}$`,
    );
  }

  ngAfterViewInit(): void {
    // timeout is used here to make sure we focus the input box when going
    // from the Order Tools menu, since the menu focuses its activator upon selection.
    // Also it makes sure quick add top offset is good for scroll calculation
    setTimeout(() => {
      this.scrollIntoViewPort();
      this.focusMaterialBox();
    });
  }

  onInput(): void {
    const materialNumberLength = this.materialNumberElement.value.length;

    if (materialNumberLength === this.maxLengthMaterialNumber) {
      this.focusCaseQuantityBox();
      this.isMaterialNumberValid = true;
    } else if (materialNumberLength === this.minLengthMaterialNumber) {
      this.isMaterialNumberValid = true;
    } else {
      this.isMaterialNumberValid = false;
    }
  }

  onCaseInput(): void {
    const quantityValueLength = this.caseQuantityElement.value.length;
    this.isCaseQuantityValid = quantityValueLength > 0;
  }

  get isButtonDisabled(): boolean {
    return !(this.isCaseQuantityValid && this.isMaterialNumberValid);
  }

  addMaterial(): void {
    this.sanitizeCaseQuantity();
    if (!this.isButtonDisabled) {
      this.addMaterialToCart();
      this.scrollIntoViewPort();
    }
  }

  sanitizeCaseQuantity() {
    this.caseQuantityElement.value = this.caseQuantityElement.value.replace(
      /^0+/,
      '',
    );
    if (this.caseQuantityElement.value.length === 0) {
      this.resetCaseQuantityInput();
    }
  }

  onKeyPress(event: KeyboardEvent, inputType: string): boolean {
    /**
     * Character code of 0 gets a pass here for keys like arrows and backspace
     * on browsers like FireFox that don't fire TextEvents.
     */
    if (event.charCode === 0) {
      return;
    }
    const charCode = String.fromCharCode(event.charCode);
    const isPaste = event.charCode === 118 && event.metaKey;
    if (!this.shouldAccept(charCode, inputType) && !isPaste) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * This gives us full coverage of event types for browser/OS coverage
   * @param {Event} event
   * @return {boolean}
   */
  onTextInput(event: InputEvent, inputType: string): boolean {
    if (!this.shouldAccept(event.data, inputType)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  focusCaseQuantityBox() {
    this.caseQuantityElement.focus();
    this.caseQuantityElement.select();
  }

  focusMaterialBox() {
    this.materialNumberElement.focus();
    this.materialNumberElement.select();
  }

  openLearnMoreHelperModal() {
    this.customDialogService.quickAddLearnMoreModal();
  }

  suppressContextMenu($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  scrollIntoViewPort() {
    const quickAddBounds = this.container.nativeElement;
    const scrollingView = this._document.getElementById('scrolling-content');
    const headerHeight = this.headerHeightService.getHeaderHeight();

    // timeout is used here to make sure the scrolling content div scrolls after the row is added
    setTimeout(() => {
      scrollingView?.scrollTo({
        top: quickAddBounds.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    });
  }

  disableQuickAdd() {
    this.activeCartSummaryFacade.disableQuickAdd();
  }

  private addMaterialToCart() {
    this.activeCartSummaryFacade.updateCaseQuantityQuickAdd(
      this.materialNumberValue,
      this.caseQuantityValue,
    );
    this.resetMaterialNumberInput();
    this.resetCaseQuantityInput();
    this.focusMaterialBox();
  }

  private get materialNumberValue(): string {
    return this.materialNumberElement.value;
  }

  private get caseQuantityValue(): number {
    return this.parseValue(this.caseQuantityElement.value);
  }

  private get materialNumberElement(): HTMLInputElement {
    return <HTMLInputElement>this.materialNumber.nativeElement;
  }

  private get caseQuantityElement(): HTMLInputElement {
    return <HTMLInputElement>this.caseQuantity.nativeElement;
  }

  private resetMaterialNumberInput() {
    this.isMaterialNumberValid = false;
    this.materialNumberElement.value = '';
  }

  private resetCaseQuantityInput() {
    this.isCaseQuantityValid = true;
    this.caseQuantityElement.value = '1';
  }

  private shouldAccept(charCode: string, inputType: string): boolean {
    return this.materialInput === inputType
      ? this.materialInputRegexp.test(charCode)
      : this.caseQuantityInputRegexp.test(charCode);
  }

  private parseValue(value: string): number {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? 0 : parsed;
  }
}
