<div class="main-container">
  <div class="product-container">
    @if (!!cartReview) {
      <div class="review-sections">
        <naoo-email-notification-opt-in
          [orderConfirmationEmailOptIn]="cartReview.emailOptIn"
        >
        </naoo-email-notification-opt-in>
        @if (hasOnlySuccessSection) {
          <h2 tabindex="0">
            {{ 'CART_REVIEW.PLACE_YOUR_ORDER' | translate }}
          </h2>
        }
        @for (group of truckGroups; track group.name) {
          @for (
            section of group.sections;
            track trackBySection(section);
            let idx = $index
          ) {
            <naoo-cart-review-truck-container
              #expansionSections
              [section]="section"
              [cartCounts]="getCartCounts(group.name, idx)"
              (expansionToggled)="emitUpdateFooterVisibility()"
              [submitAttempted]="submitButtonClicked"
              [isSectionInvalid]="isSectionInvalid(section)"
              [isOffline]="cartReview.isOffline"
            >
            </naoo-cart-review-truck-container>
          }
        }
        @for (group of storeGroups; track group.name) {
          <naoo-cart-review-store-container
            [isOffline]="cartReview.isOffline"
            [storeCartCounts]="cartReviewCartCounts.get(group.name)"
            [storeSectionGroup]="group"
            (expansionToggled)="emitUpdateFooterVisibility()"
          >
          </naoo-cart-review-store-container>
        }
      </div>
    } @else {
      <div class="loading-container">
        <span class="review-text">{{
          'CART_REVIEW.LOADING.TEXT' | translate
        }}</span>
      </div>
    }
  </div>
</div>
