import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NaooConstants } from '../NaooConstants';
import { Observable } from 'rxjs';
import { NaooLocalStorage } from '../storage/local-storage/local-storage.service';

export const cartTransferGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
  const router = inject(Router);
  const storage = inject(NaooLocalStorage);
  const data = storage.getItem(NaooConstants.CART_TRANSFER_CONTENT);

  if (!data) {
    router.navigate([NaooConstants.CART_PATH]);
    return false;
  }
  return true;
};
