<div class="naoo-material-view-select" [ngClass]="{ disabled: disabled }">
  @if (!shouldHideLabel) {
    <span class="material-view-label">
      {{ 'MATERIAL_VIEW_TOGGLE.VIEW_ITEMS' | translate }}:
    </span>
  }
  <mat-form-field class="material-view-field">
    <mat-select
      class="material-view-select"
      [disabled]="disabled"
      [value]="preferredView?.materialListStyle"
      (selectionChange)="onPreferredViewChange($event)"
      [aria-label]="'MATERIAL_VIEW_TOGGLE.VIEW_ITEMS' | translate"
    >
      <mat-select-trigger class="view-select-trigger">
        <mat-icon [svgIcon]="preferredView.icon"></mat-icon>
        {{ preferredView.title | translate }}
      </mat-select-trigger>
      @for (
        materialViewOption of materialViewOptions;
        track materialViewOption.title
      ) {
        <mat-option
          class="naoo-material-view-option"
          [value]="materialViewOption.materialListStyle"
        >
          <mat-icon [svgIcon]="materialViewOption.icon"></mat-icon>
          {{ materialViewOption.title | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
