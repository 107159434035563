import { Component, OnInit } from '@angular/core';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { BulkAddWorkflow } from '../../shared/bulk-add-modal/bulk-add-modal.component';

@Component({
  selector: 'naoo-cart-transfer',
  template: '',
  standalone: true,
})
export class CartTransferComponent implements OnInit {
  constructor(private customDialogService: CustomDialogService) {}

  ngOnInit(): void {
    this.customDialogService.bulkAddImportChecker(
      BulkAddWorkflow.TRANSFER_CART,
    );
  }
}
