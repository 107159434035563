<div class="result-text">
  @if (!isMobile || !resultSet.totalResults) {
    <h1
      class="result-text-title"
      [ngClass]="{
        'no-margin-bottom': !!resultSet.suggestedText,
      }"
    >
      {{ resultSet.totalResults }} {{ titleHeaderCopy | translate }}
      <strong>&quot;{{ searchName | stringdefaulter }}&quot;</strong>
    </h1>
  }
  @if (isOrderGuideTab) {
    <div class="result-text-context">
      @if (!isMobile) {
        {{ 'SEARCH.IN_ORDER_GUIDE' | translate }}
      }
      @if (!resultSet.totalResults) {
        <button class="result-text-link" (click)="handleRedirectToAllSearch()">
          {{ 'SEARCH.EVERYWHERE' | translate }}
        </button>
      }
    </div>
  }
</div>
@if (!isMobile && !!resultSet.suggestedText) {
  <h2 class="result-text-subtitle">
    {{ 'SEARCH.ZERO_RESULTS_FOR' | translate }}
    &quot;{{ searchText | stringdefaulter }}&quot;
  </h2>
}
