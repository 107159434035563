<div class="empty-cart-box">
  <div class="empty-cart-icon">
    <img
      class="empty-cart-img"
      alt="{{ 'CART.EMPTY_CART.IMAGE_ALT' | translate }}"
      src="assets/images/empty-cart.svg"
    />
  </div>
  <div class="empty-cart-content">
    <h2 class="empty-cart-title">
      {{ 'CART.EMPTY_CART.TITLE' | translate }}
    </h2>
    <div class="empty-cart-message">
      {{ 'CART.EMPTY_CART.MESSAGE' | translate }}
    </div>

    <div class="empty-cart-links">
      <div class="top-links">
        <a [routerLink]="guidesPath" class="empty-cart-link">{{
          'CART.EMPTY_CART.GUIDES_LINK' | translate
        }}</a>

        <naoo-generic-disableable-link
          [routingUrl]="catalogPath"
          [isDisabled]="isOffline"
          class="empty-cart-link"
        >
          {{ 'CART.EMPTY_CART.CATEGORIES_LINK' | translate }}
        </naoo-generic-disableable-link>
      </div>
      <div class="bottom-links">
        <naoo-generic-disableable-link
          (clickEvent)="beginQuack()"
          [isDisabled]="isOffline"
          class="quick-add-link empty-cart-link"
        >
          {{ 'CART.EMPTY_CART.QUICK_ADD_LINK' | translate }}
        </naoo-generic-disableable-link>

        <naoo-generic-disableable-link
          (clickEvent)="openImportOrderModal()"
          [isDisabled]="isOffline"
          class="import-link empty-cart-link"
        >
          {{ 'CART.EMPTY_CART.IMPORT_ORDER_LINK' | translate }}
        </naoo-generic-disableable-link>
      </div>
    </div>
  </div>
</div>
