import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MaterialRowContext,
  MaterialRowSharedOptions,
} from '../../../../core/store/material-row/models/material-row';
import { MaterialAvailability } from '../../../../shared/models/material-availability';
import { Language } from '../../../../core/services/session/models/session-record';
import { getLanguage } from '../../../../shared/utilities/locale-utilities';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-unorderable',
  templateUrl: './material-unorderable.component.html',
  styleUrls: ['./material-unorderable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class MaterialUnorderableComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;
  @Input() materialAvailability: MaterialAvailability;

  get unorderableText(): string {
    if (
      this.materialRowOptions.context === MaterialRowContext.ErrorProcessingItem
    ) {
      return 'PRODUCT.PROCESSING_ERROR';
    } else if (
      this.materialAvailability?.salesStatus &&
      this.materialAvailability?.salesStatus[this.getLanguage()]
    ) {
      return this.materialAvailability.salesStatus[this.getLanguage()];
    } else {
      return 'PRODUCT.NOT_ORDERABLE';
    }
  }

  getLanguage(): Language {
    return getLanguage(this.materialRowOptions.locale);
  }
}
