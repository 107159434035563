<div
  class="material-content"
  [ngClass]="{ tablet: isTabletWidth, 'last-record': isLast }"
>
  <div class="purchase-detail-cell item">
    <div class="item-wrapper">
      <div class="image-wrapper">
        <naoo-material-image
          [materialRowOptions]="materialRow.materialRowOptions"
          [isMobile]="isMobileWidth"
        ></naoo-material-image>
        <naoo-material-store-stored-button
          [fulfillmentType]="fulfillmentType"
          [materialAvailability]="
            purchaseMaterial.materialAvailabilityRecordState?.record
          "
          [materialRowOptions]="materialRow.materialRowOptions"
        >
        </naoo-material-store-stored-button>
      </div>
      <naoo-material-info
        [materialRowOptions]="materialRow.materialRowOptions"
        [materialInfo]="materialRow.materialRowInfo"
        [isMobile]="isMobileWidth"
      ></naoo-material-info>
    </div>
    @if (isTabletWidth) {
      <ng-container [ngTemplateOutlet]="tripleDot"></ng-container>
    }
  </div>
  <div class="purchase-detail-cell points">
    <div class="cell-label points-label">
      <naoo-go-points-logo
        [context]="'purchase-history-detail'"
      ></naoo-go-points-logo>
    </div>
    {{ purchaseMaterial.pointsEarned }}
  </div>
  <div class="purchase-detail-cell unit-size">
    <div class="cell-label">
      {{ 'PURCHASE_DETAILS.UNIT_SIZE' | translate }}
    </div>
    {{ { uomCode: purchaseMaterial.uomCode } | materialunits }}
  </div>
  <div class="purchase-detail-cell quantity">
    <div class="cell-label">
      {{ 'PURCHASE_DETAILS.QUANTITY' | translate }}
    </div>
    {{ purchaseMaterial.quantitySold }}
  </div>
  <div class="purchase-detail-cell total">
    <div class="cell-label">
      {{ 'PURCHASE_DETAILS.TOTAL' | translate }}
    </div>
    {{ purchaseMaterial.netExtendedAmount | naoocurrency }}
    @if (!isTabletWidth) {
      <ng-container [ngTemplateOutlet]="tripleDot"></ng-container>
    }
  </div>
</div>

<ng-template #tripleDot>
  <div class="triple-dot">
    <naoo-material-options-menu
      [materialRowOptions]="materialRow.materialRowOptions"
      [isMobile]="isMobileWidth"
    >
    </naoo-material-options-menu>
  </div>
</ng-template>
