<button
  mat-icon-button
  class="triple-dot-menu-button"
  [matMenuTriggerFor]="appMenu"
  [attr.aria-label]="'CART.ACTIVE_CART.MENU_OPTIONS' | translate"
>
  <mat-icon svgIcon="horizontal_triple_dot_v2"></mat-icon>
</button>

<mat-menu
  #appMenu="matMenu"
  class="cart-action-bar-menu"
  yPosition="below"
  xPosition="before"
>
  @for (button of viewButtons; track button.id) {
    <button
      mat-menu-item
      id="{{ button.id }}"
      class="menu-button"
      [disabled]="button.disabled"
      [attr.aria-label]="button.ariaLabel | translate"
      (click)="button.clicked($event)"
    >
      <mat-icon svgIcon="{{ button.icon }}"></mat-icon>
      <span>{{ button.text | translate }}</span>
    </button>
  }
</mat-menu>
