<div class="main-container" #container>
  <div class="title-container">
    <mat-icon class="quick-add-icon" svgIcon="quick_add_icon"></mat-icon>
    <div class="title-heading">
      <div class="title">
        {{ 'CART_SUMMARY.QUICK_ADD.TITLE' | translate }}
      </div>
      <button
        role="link"
        class="learn-more"
        (click)="openLearnMoreHelperModal()"
        [attr.aria-label]="
          'CART_SUMMARY.QUICK_ADD.LEARN_MORE_ALT_TEXT' | translate
        "
      >
        {{ 'CART_SUMMARY.QUICK_ADD.LEARN_MORE' | translate }}
      </button>
    </div>
  </div>
  <div class="quick-add-container">
    <div class="input-container" id="item-input">
      <div class="label">
        {{ 'CART_SUMMARY.QUICK_ADD.ITEM_NUMBER' | translate }}
      </div>
      <input
        #materialNumber
        class="material-box"
        type="tel"
        [attr.maxlength]="maxLengthMaterialNumber"
        (focus)="focusMaterialBox()"
        (input)="onInput()"
        (keydown.enter)="addMaterial()"
        (keypress)="onKeyPress($event, materialInput)"
        (textInput)="onTextInput($event, materialInput)"
      />
    </div>
    <div class="input-container" id="case-qty-input">
      <div class="label">
        {{ 'CART_SUMMARY.QUICK_ADD.CASE_QTY' | translate }}
      </div>
      <input
        #caseQuantity
        class="quantity-box"
        type="tel"
        value="1"
        [attr.maxlength]="maxLengthCaseQuantity"
        (blur)="sanitizeCaseQuantity()"
        (contextmenu)="suppressContextMenu($event)"
        (focus)="focusCaseQuantityBox()"
        (input)="onCaseInput()"
        (keydown.enter)="addMaterial()"
        (keypress)="onKeyPress($event, quantityInput)"
        (textInput)="onTextInput($event, quantityInput)"
      />
    </div>
    <button
      [disabled]="isButtonDisabled"
      class="primary-button"
      (click)="addMaterial()"
      attr.aria-label="{{ 'CART_SUMMARY.QUICK_ADD.ADD_ITEM' | translate }}"
    >
      {{ 'CART_SUMMARY.QUICK_ADD.ADD' | translate }}
    </button>
  </div>
  <button
    class="close-button"
    (click)="disableQuickAdd()"
    attr.aria-label="{{ 'CART_SUMMARY.QUICK_ADD.CLOSE_ALT_TEXT' | translate }}"
  >
    <mat-icon svgIcon="clear_icon"></mat-icon>
  </button>
</div>
