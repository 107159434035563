import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-duplicate-custom-guide-toast',
  templateUrl: './duplicate-custom-guide-toast.component.html',
  styleUrls: ['./duplicate-custom-guide-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class DuplicateCustomGuideToastComponent implements OnInit {
  newGuideId: string;
  newGuideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      customGuideId: string;
      customGuideName: string;
    },
    private router: Router,
  ) {}

  ngOnInit() {
    this.newGuideId = this.data.customGuideId;
    this.newGuideName = this.data.customGuideName;
  }

  navigate() {
    this.router.navigate(['guides', 'custom-guide', this.newGuideId]);
  }
}
