<naoo-generic-expansion-panel
  #genericExpansionPanel
  (expanded)="expandEvent($event)"
  [headerTitle]="
    section.headerKey
      | translate
        : {
            productCount: section.materialNumbers.length,
            sectionCount: sectionCount,
          }
  "
  [startExpanded]="true"
  [colorScheme]="colorScheme"
  [icon]="iconAsset"
  [hasRedBorder]="redBorderActive"
>
  <div class="panel-content">
    <div class="content-header">
      @switch (section.headerType) {
        @case (headerType.Success) {
          <naoo-cart-review-success-header [section]="section">
          </naoo-cart-review-success-header>
        }
        @case (headerType.DropShip) {
          <naoo-cart-review-drop-ship-header
            [isOffline]="isOffline"
            [section]="section"
          >
          </naoo-cart-review-drop-ship-header>
        }
        @case (headerType.StoreError) {
          <naoo-cart-review-store-error-header
            [customerTimeZone]="customerTimeZone$ | async"
            [isOffline]="isOffline"
            [storeSection]="section"
            [storeSectionCartCounts]="cartCounts"
            [storeScenario]="storeScenario"
            (warningIcon)="isAPartialSubmit($event)"
          >
          </naoo-cart-review-store-error-header>
        }
        @case (headerType.StoreSuccess) {
          <naoo-cart-review-store-success-header
            [customerTimeZone]="customerTimeZone$ | async"
            [shouldShowDate]="true"
            [storeSection]="section"
            [storeSectionCartCounts]="cartCounts"
          >
          </naoo-cart-review-store-success-header>
        }
        @case (headerType.Default) {
          <naoo-cart-review-default-header [section]="section">
          </naoo-cart-review-default-header>
        }
        @case (headerType.MissedCutoff) {
          <naoo-cart-review-missed-cutoff-header
            [isOffline]="isOffline"
            [section]="section"
          >
          </naoo-cart-review-missed-cutoff-header>
        }
      }
    </div>
    <naoo-material-list
      [context]="section.context"
      [listStyle]="listStyle"
      [materialListItems]="section.materialNumbers"
    ></naoo-material-list>
  </div>
</naoo-generic-expansion-panel>
