<img
  class="unavailable-img"
  alt="{{ 'INFORMATION_UNAVAILABLE.IMAGE.ALT_TEXT' | translate }}"
  src="{{ 'INFORMATION_UNAVAILABLE.IMAGE.1X_URL' | translate }}"
  srcset="{{'INFORMATION_UNAVAILABLE.IMAGE.1X_URL' | translate }} 1x, {{'INFORMATION_UNAVAILABLE.IMAGE.2X_URL' | translate }} 2x, {{'INFORMATION_UNAVAILABLE.IMAGE.3X_URL' | translate }} 3x"
/>
<div class="unavailable-title">
  {{ 'INFORMATION_UNAVAILABLE.TITLE' | translate }}
</div>
<div class="unavailable-subtitle">
  {{ 'INFORMATION_UNAVAILABLE.SUBTITLE' | translate }}
</div>
<div class="unavailable-buttons">
  <button
    class="naoo-button secondary-button large-button view-guides"
    (click)="onViewGuides()"
  >
    {{ 'INFORMATION_UNAVAILABLE.VIEW_GUIDES_BUTTON' | translate }}
  </button>
  <button
    class="naoo-button primary-button large-button view-order-guide"
    (click)="onViewOrderGuide()"
  >
    {{ 'INFORMATION_UNAVAILABLE.VIEW_ORDER_GUIDE_BUTTON' | translate }}
  </button>
</div>
