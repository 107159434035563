<section class="order-details-container">
  <div class="print-header">
    <div class="logo">
      <img
        src="{{
          'LOGO.URL'
            | naoobrand: orderDetailsViewModel?.customerDetails.customerBrand
            | translate
        }}"
        alt="{{
          'LOGO.TEXT'
            | naoobrand: orderDetailsViewModel?.customerDetails.customerBrand
            | translate
        }}"
      />
    </div>
    <div class="title">
      <div class="page">
        {{ 'ORDERS.DETAILS.PRINT.TITLE' | translate }} #{{
          orderDetailsViewModel?.combinedOrderAndGroupNumber
        }}
      </div>
    </div>
  </div>
  <div class="order-details-header">
    <div class="order-details-menu">
      <naoo-back-button
        [navigationLink]="backNavigationLink"
      ></naoo-back-button>
      <div class="right-side-menu">
        <div class="action-item-container">
          <naoo-action-icon
            [actionIcon]="ActionIcon.Print"
            [iconCurrentState]="printIconState"
            (clickAction)="onPrintIconClick()"
          ></naoo-action-icon>
        </div>
        @if (orderDetailsViewModel?.isExportOrderEnabled) {
          <div class="action-item-container">
            <naoo-action-icon
              [actionIcon]="ActionIcon.Export"
              [iconCurrentState]="exportIconState$ | async"
              (clickAction)="export()"
            ></naoo-action-icon>
          </div>
        }
      </div>
    </div>
    <hr />
    <div class="order-details-heading">
      <div class="heading-container">
        <h1 class="heading-title">{{ 'ORDERS.DETAILS.TITLE' | translate }}</h1>
        @if (orderDetailsViewModel) {
          <div class="sub-heading">
            <div class="order-number">
              <span class="label">{{
                'ORDERS.SUMMARY.ORDER_NUMBER' | translate
              }}</span>
              <span class="value"
                >&nbsp;{{
                  orderDetailsViewModel.combinedOrderAndGroupNumber
                }}</span
              >
            </div>
            @if (
              !!submissionDetails.submittedTimeZone &&
              !!submissionDetails.submittedDateTime &&
              !!submissionDetails.submittedByUsername
            ) {
              <div class="submitted-by">
                <span class="label"
                  >{{ 'ORDERS.SUMMARY.SUBMITTED_ON' | translate }}:
                </span>
                <span
                  class="value"
                  [innerHTML]="
                    'ORDERS.SUMMARY.SUBMITTED_INFO'
                      | translate
                        : {
                            time:
                              submissionDetails.submittedDateTime
                              | naooDate
                                : 'mediumTimeNoBreak'
                                : submissionDetails.submittedTimeZone,
                            date:
                              submissionDetails.submittedDateTime
                              | naooDate
                                : 'longDateNoDay'
                                : submissionDetails.submittedTimeZone,
                            timeZone:
                              'TIMEZONES.' + submissionDetails.submittedTimeZone
                              | translate,
                            submittedBy: submissionDetails.submittedByUsername,
                          }
                  "
                ></span>
              </div>
            }
            <div class="order-status">
              <span class="label"
                >{{ 'ORDERS.SUMMARY.STATUS' | translate }}:
              </span>
              <span
                class="value"
                [ngClass]="{
                  'ship-error': isErrorSeverity(status.orderStatusSeverity),
                }"
              >
                <mat-icon
                  class="status-icon"
                  [svgIcon]="status.statusIcon"
                  [ngClass]="status.iconColor"
                  [attr.aria-label]="status.iconAltTextKey | translate"
                  [attr.aria-hidden]="false"
                ></mat-icon>
                <span class="order-status-value">
                  {{ status.orderStatusDescription | stringdefaulter }}
                  @if (!!status.orderBlockDescription) {
                    <span class="order-status-note">
                      :
                      {{ status.orderBlockDescription | stringdefaulter }}</span
                    >
                  }
                </span>
              </span>
            </div>
            @if (!!orderDetailsViewModel?.customerPurchaseOrder) {
              <div class="po-number">
                <span class="label">{{
                  'ORDERS.DETAILS.PO_NUMBER' | translate
                }}</span>
                <span class="value"
                  >&nbsp;{{ orderDetailsViewModel.customerPurchaseOrder }}</span
                >
              </div>
            }
          </div>
        }
      </div>
      <div class="button-container">
        @if (orderDetailsViewModel?.isEditOrderEnabled) {
          <button
            (click)="showCancelOrderModal()"
            [disabled]="orderDetailsViewModel?.isCancelOrderDisabled"
            class="secondary-button large-button cancel-order-button"
          >
            {{ 'ORDERS.DETAILS.CANCEL_ORDER.BUTTON' | translate }}
          </button>
        }
        @if (lines?.length) {
          <button
            (click)="onAddItemsToCart()"
            [disabled]="!orderDetailsViewModel?.isReOrderable"
            [ngClass]="
              this.orderDetailsViewModel?.isEditOrderEnabled
                ? 'primary-button'
                : 'secondary-button'
            "
            class="large-button reorder-button"
          >
            {{ 'ORDERS.DETAILS.REORDER_ALL_ITEMS' | translate }}
          </button>
        }
      </div>
    </div>
  </div>
  @if (orderDetailsViewModel || orderServiceError) {
    <ng-container
      *ngTemplateOutlet="
        orderServiceError
          ? errorOrderDetails
          : lines?.length
            ? orderDetails
            : emptyOrderError
      "
    ></ng-container>
  }
</section>

<ng-template #orderDetails>
  <div class="order-details-summary">
    <div class="col-1">
      <div class="row row-1">
        <div class="cell-flex">
          @if (orderDetailsViewModel.isDropShip) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: orderDetailsViewModel.deliveryDateLabel | translate,
                message: status.shippingMessageKey | translate,
                value: status.calculatedDate
                  ? (status.calculatedDate
                    | naooDate
                      : 'weekdayMonthDay'
                      : orderDetailsViewModel.userTimeZone)
                  : '',
                icon: 'calendar-icon',
                class: 'delivery-date',
              }"
            ></ng-container>
          } @else {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: orderDetailsViewModel.deliveryDateLabel | translate,
                message: status.shippingMessageKey | translate,
                value: status.isCanceled
                  ? ''
                  : status.calculatedDate
                    ? (status.calculatedDate
                      | naooDate
                        : 'weekdayMonthDay'
                        : orderDetailsViewModel.userTimeZone)
                    : ('ORDERS.SUMMARY.TBD' | translate),
                icon: 'calendar-icon',
                class: 'delivery-date',
              }"
            ></ng-container>
          }
        </div>
        <div class="time">
          @if (orderDetailsViewModel.isExpressOrder && status.showDeliveryEta) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: orderDetailsViewModel.deliveryTimeLabel | translate,
                value:
                  status.startTime && status.untilTime
                    ? (status?.startTime
                      | naooTimeRange
                        : status.untilTime
                        : orderDetailsViewModel?.userTimeZone)
                    : ('ORDERS.SUMMARY.TBD' | translate),
                icon: 'clock-icon',
                class: 'delivery-eta',
              }"
            ></ng-container>
          }
          @if (orderDetailsViewModel?.isIspuOrder && status.showDeliveryEta) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: orderDetailsViewModel.deliveryTimeLabel | translate,
                value:
                  (status.calculatedTime | Naoo24to12Hr)
                    ? ('ORDERS.SUMMARY.AFTER_TIME_WITH_TIMEZONE'
                      | translate
                        : {
                            time: status.calculatedTime | Naoo24to12Hr,
                            timeZone:
                              'TIMEZONES.' + status.customerArrivalTimeZone
                              | translate,
                          })
                    : ('ORDERS.SUMMARY.TBD' | translate),
                icon: 'clock-icon',
                class: 'delivery-eta',
              }"
            ></ng-container>
          }
          @if (
            status.showDeliveryEta &&
            !orderDetailsViewModel.isIspuOrder &&
            !orderDetailsViewModel.isExpressOrder
          ) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: orderDetailsViewModel.deliveryTimeLabel | translate,
                value:
                  (status?.calculatedTime | Naoo24to12Hr)
                    ? ('ORDERS.SUMMARY.TIME_WITH_TIMEZONE'
                      | translate
                        : {
                            time: status.calculatedTime | Naoo24to12Hr,
                            timeZone:
                              'TIMEZONES.' + status.customerArrivalTimeZone
                              | translate,
                          })
                    : ('ORDERS.SUMMARY.TBD' | translate),
                icon: 'clock-icon',
                class: 'delivery-eta',
              }"
            ></ng-container>
          }
        </div>
      </div>
      <div class="row row-2">
        <ng-container
          [ngTemplateOutlet]="summaryCell"
          [ngTemplateOutletContext]="{
            label: 'ORDERS.SUMMARY.CUSTOMER' | translate,
            value:
              orderDetailsViewModel?.customerDetails?.customerName +
              '<br/>' +
              ('ORDERS.SUMMARY.CUSTOMER_ID'
                | translate
                  : {
                      customerId:
                        orderDetailsViewModel?.customerDetails.customerId,
                    }),
            icon: 'address-icon',
            class: 'customer',
          }"
        ></ng-container>
        <div class="cell-flex">
          @if (status?.isDropShipOrder) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: 'ORDERS.SUMMARY.TRACKING_IDS' | translate,
                value: status.showTrackingIdsLink
                  ? ''
                  : ('ORDERS.SUMMARY.TBD' | translate),
                linkLabel: 'ORDERS.SUMMARY.VIEW_TRACKING' | translate,
                linkUrls: status.trackingUrls,
                linkTitle: 'ORDERS.SUMMARY.TRACKING_ALT_TEXT' | translate,
                icon: 'delivered-icon',
                class: 'tracking-ids',
              }"
            ></ng-container>
          }
          @if (orderDetailsViewModel?.isExpressOrder) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: 'ORDERS.DETAILS.DELIVERED_FROM' | translate,
                icon: 'delivered-icon',
                class: 'store-location',
              }"
            >
            </ng-container>
            <naoo-store-display
              class="store-display"
              [storeRecord]="orderDetailsViewModel?.storeRecord"
              [customerTimeZone]="orderDetailsViewModel?.userTimeZone"
            ></naoo-store-display>
          }
          @if (orderDetailsViewModel?.isIspuOrder) {
            <ng-container
              [ngTemplateOutlet]="summaryCell"
              [ngTemplateOutletContext]="{
                label: 'ORDERS.DETAILS.STORE_LOCATION' | translate,
                value: orderDetailsViewModel?.storeNameAndLocation
                  ? orderDetailsViewModel?.storeNameAndLocation
                  : ('ORDERS.SUMMARY.TBD' | translate),
                icon: 'store-icon',
                class: 'store-location',
              }"
            ></ng-container>
          }
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="row row-1">
        <div class="cell item-totals">
          <div class="icon-spacer"></div>
          <div class="content">
            <div class="label">
              {{ 'ORDERS.SUMMARY.ITEM_TOTALS' | translate }}
            </div>
            <div class="value">
              {{
                'ORDERS.SUMMARY.TOTAL_LINES'
                  | translate
                    : { lines: orderDetailsViewModel?.totalDetails.totalLines }
              }},
              {{
                'ORDERS.SUMMARY.TOTAL_ITEMS'
                  | translate
                    : {
                        items:
                          orderDetailsViewModel?.totalDetails
                            .totalQuantityOrdered,
                      }
              }}
            </div>
          </div>
        </div>
        @if (!!orderDetailsViewModel?.totalDetails?.totalWeight) {
          <ng-container
            [ngTemplateOutlet]="summaryCell"
            [ngTemplateOutletContext]="{
              label: 'ORDERS.SUMMARY.TOTAL_WEIGHT' | translate,
              value:
                (orderDetailsViewModel?.totalDetails.totalWeight
                  | naoodecimal: '1.0-2') +
                ' ' +
                orderDetailsViewModel.totalDetails.totalWeightUom,
              class: 'total-weight',
            }"
          ></ng-container>
        }
      </div>
      <div class="row row-2">
        <ng-container
          [ngTemplateOutlet]="summaryCell"
          [ngTemplateOutletContext]="{
            label: 'ORDERS.SUMMARY.QTY_ORDERED' | translate,
            value:
              orderDetailsViewModel?.totalDetails.totalQuantityOrdered
              | naoodecimal: '1.0-0',
            class: 'qty-ordered',
          }"
        ></ng-container>
        @if (!orderDetailsViewModel?.isIspuOrder) {
          <ng-container
            [ngTemplateOutlet]="summaryCell"
            [ngTemplateOutletContext]="{
              label:
                (isInvoiced(status.orderStatus)
                  ? 'ORDERS.SUMMARY.QTY_SHIPPED'
                  : 'ORDERS.SUMMARY.QTY_SHIPPED_EST'
                ) | translate,
              value: !(
                orderDetailsViewModel?.totalDetails.totalQuantityShipped == null
              )
                ? (orderDetailsViewModel?.totalDetails.totalQuantityShipped
                  | naoodecimal: '1.0-0')
                : ('ORDERS.SUMMARY.TBD' | translate),
              class: isErrorSeverity(status.orderStatusSeverity)
                ? 'qty-shipped ship-error'
                : 'qty-shipped',
            }"
          ></ng-container>
        }
      </div>
    </div>
    <div class="col-3">
      @if (status?.isDropShipOrder) {
        <div class="row row-1">
          <ng-container
            [ngTemplateOutlet]="summaryCell"
            [ngTemplateOutletContext]="{
              label:
                (isInvoiced(status.orderStatus)
                  ? 'ORDERS.SUMMARY.TOTAL'
                  : 'ORDERS.SUMMARY.TOTAL_EST'
                ) | translate,
              subTotal:
                orderDetailsViewModel?.totalDetails?.subTotal | naooprice,
              shippingTotal:
                orderDetailsViewModel?.totalDetails?.totalShipping
                | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2',
              showPriceSplit: true,
              class: 'total',
            }"
          ></ng-container>
        </div>
      }
      <div class="row row-2">
        <ng-container
          [ngTemplateOutlet]="summaryCell"
          [ngTemplateOutletContext]="{
            label: status?.isDropShipOrder
              ? ''
              : ((isInvoiced(status.orderStatus)
                  ? 'ORDERS.SUMMARY.TOTAL'
                  : 'ORDERS.SUMMARY.TOTAL_EST'
                ) | translate),
            value: orderDetailsViewModel?.totalDetails.totalPrice | naooprice,
            class: 'total total-row-2',
          }"
        ></ng-container>
      </div>
      @if (!!orderDetailsViewModel?.totalDiscountAmount) {
        <div class="row row-3">
          <ng-container
            [ngTemplateOutlet]="summaryCell"
            [ngTemplateOutletContext]="{
              label: 'ORDERS.SUMMARY.SAVINGS_TOTAL' | translate,
              value: orderDetailsViewModel.totalDiscountAmount | naooprice,
              class: 'savings',
            }"
          ></ng-container>
        </div>
      }
      @if (isInvoiced(status.orderStatus)) {
        <div class="total-note">
          <span>*</span>
          <span class="note">{{ 'ORDERS.SUMMARY.NOTE' | translate }}</span>
        </div>
      }
    </div>

    <ng-template
      #summaryCell
      let-label="label"
      let-value="value"
      let-shippingTotal="shippingTotal"
      let-subTotal="subTotal"
      let-message="message"
      let-linkLabel="linkLabel"
      let-linkUrls="linkUrls"
      let-linkTitle="linkTitle"
      let-icon="icon"
      let-cls="class"
      let-iconClass="iconClass"
      let-showPriceSplit="showPriceSplit"
    >
      <div class="cell {{ cls }}">
        <div [ngClass]="{ 'icon-spacer': !icon }">
          @if (icon) {
            <mat-icon
              [svgIcon]="icon"
              [ngClass]="iconClass ? iconClass : 'gray'"
            ></mat-icon>
          }
        </div>
        <div class="content">
          <div class="label">{{ label }}</div>
          @if (message) {
            <div class="value-message">{{ message }}</div>
          }
          @if (showPriceSplit) {
            <div>
              <div class="price-total value-message">
                <span>{{ 'ORDERS.SUMMARY.SUB_TOTAL' | translate }}:</span
                >&nbsp;<span>{{ subTotal }}</span>
              </div>
              <div class="price-total value-message">
                <span>{{ 'ORDERS.SUMMARY.SHIPPING' | translate }}:</span
                >&nbsp;<span>{{ shippingTotal }}</span>
              </div>
            </div>
          }
          @if (value) {
            <div
              class="value"
              [ngClass]="{ 'no-margin': !!message }"
              [innerHTML]="value"
            ></div>
          }
          @for (linkUrl of linkUrls; track linkUrl) {
            @if (linkUrl) {
              <a
                [href]="linkUrl"
                class="value link"
                target="_blank"
                [attr.aria-hidden]="true"
                [attr.title]="linkTitle"
                >{{ linkLabel }}
                <mat-icon
                  class="new-window-icon"
                  svgIcon="new-window-icon"
                  aria-hidden="true"
                >
                </mat-icon>
              </a>
              <div class="value link print-url">
                {{ linkUrl }}
              </div>
            }
          }
        </div>
      </div>
    </ng-template>
  </div>

  <div class="order-details-table">
    <div class="naoo-table-header order-details-table-header">
      <div class="order-details-table-cell product">
        {{ 'ORDERS.DETAILS.ITEM' | translate }}
      </div>
      <div class="order-details-table-cell details-header">
        <div class="order-details-table-cell status">
          <div class="header-spacer">
            {{ 'ORDERS.DETAILS.STATUS' | translate }}
          </div>
        </div>
        <div class="order-details-table-cell unit">
          {{ 'ORDERS.DETAILS.UNIT' | translate }}
        </div>
        <div class="order-details-table-cell price">
          {{ 'ORDERS.DETAILS.PRICE' | translate }}
        </div>
        <div class="order-details-table-cell qty-order">
          {{ 'ORDERS.DETAILS.QTY_ORDER' | translate }}
        </div>
        <div class="order-details-table-cell qty-ship">
          {{ 'ORDERS.DETAILS.QTY_SHIP' | translate }}
        </div>
        <div class="order-details-table-cell total">
          {{ 'ORDERS.DETAILS.TOTAL' | translate }}
        </div>
        <div class="order-details-table-cell received">
          {{ 'ORDERS.DETAILS.RECEIVED' | translate }}
        </div>
      </div>
      <div
        class="order-details-table-cell order-options"
        [ngClass]="{
          'contains-order-edit': this.orderDetailsViewModel?.isEditOrderEnabled,
        }"
      ></div>
    </div>
    @for (
      orderLine of lines;
      track orderLine.materialNumber;
      let isLast = $last
    ) {
      <div
        class="order-details-table-row-wrapper"
        [ngClass]="{
          'savings-line': !!orderLine.discountAmount,
          'is-last': isLast,
        }"
      >
        <naoo-order-details-material-row-container
          [materialNumber]="orderLine.material.materialNumber"
          [orderLine]="orderLine"
          [isEditOrderEnabled]="orderDetailsViewModel?.isEditOrderEnabled"
          [currentLanguage]="currentLanguage"
          [isMobile]="isMobile$ | async"
          (editClick)="showEditOrderItemModal(orderLine)"
          (cancelClick)="showCancelOrderItemModal(orderLine)"
        >
        </naoo-order-details-material-row-container>
      </div>
    }
  </div>
</ng-template>

<ng-template #errorOrderDetails>
  <naoo-error-state
    class="order-details-error"
    errorImageSource="assets/images/full-error.svg"
    alt="{{ 'ORDERS.DETAILS.ERROR.ALT' | translate }}"
    errorTitle="{{ 'ERROR.TITLE' | translate }}"
    errorSubtitle="{{ 'ORDERS.DETAILS.ERROR.SUBTITLE' | translate }}"
    errorButtonTitle="{{ 'RETURN_TO_PREVIOUS_PAGE' | translate }}"
  >
  </naoo-error-state>
</ng-template>

<ng-template #emptyOrderError>
  <naoo-error-state
    class="empty-order-error"
    errorImageSource="assets/images/no_products.svg"
    alt="{{ 'ORDERS.DETAILS.EMPTY_ORDER_ERROR.ALT' | translate }}"
    errorTitle="{{ 'ORDERS.DETAILS.EMPTY_ORDER_ERROR.TITLE' | translate }}"
    errorSubtitle="{{
      'ORDERS.DETAILS.EMPTY_ORDER_ERROR.SUBTITLE' | translate
    }}"
    errorButtonTitle="{{ 'RETURN_TO_PREVIOUS_PAGE' | translate }}"
  >
  </naoo-error-state>
</ng-template>
