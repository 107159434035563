<div class="available-today">
  <mat-slide-toggle
    aria-labelledby="availableTodayFilterLabel"
    (change)="toggledAvailableTodayFilter($event)"
    [checked]="availableTodayFilter.isEnabled"
    class="available-today-toggle"
  >
    <span class="filter-label">
      {{ 'SEARCH.AVAILABLE_TODAY_ONLY' | translate }}
      ({{ availableTodayFilter.count }})
    </span>
  </mat-slide-toggle>
</div>
