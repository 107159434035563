<div class="carousel">
  <button
    class="arrow previous"
    [ngClass]="{ invisible: shouldHideArrows }"
    [disabled]="isPreviousDisabled"
    (click)="previousCard()"
  >
    <mat-icon
      class="arrow"
      [ngClass]="{ disabled: isPreviousDisabled }"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
  @for (
    mapping of getVisibleEntitlementMappings;
    track mapping.fullDescription;
    let isFirst = $first
  ) {
    <div
      class="carousel-cell"
      [ngClass]="{ first: isFirst }"
      [style.touch-action]="'pan-y'"
      (swipeRight)="previousCard()"
      (swipeLeft)="nextCard()"
    >
      <naoo-commodity-allocation [mapping]="mapping">
      </naoo-commodity-allocation>
    </div>
  }
  <button
    class="arrow next"
    [ngClass]="{ invisible: shouldHideArrows }"
    [disabled]="isNextDisabled"
    (click)="nextCard()"
  >
    <mat-icon
      class="arrow"
      [ngClass]="{ disabled: isNextDisabled }"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>
</div>
