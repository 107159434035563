import { ChangeDetectionStrategy, Component } from '@angular/core';
import JsBarcode from 'jsbarcode';
import { map, tap } from 'rxjs/operators';
import { SessionFacade } from '../../core/store/session/session.facade';
import { HeroImagesComponent } from '../../shared/hero-images/hero-images.component';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-my-id-card',
  templateUrl: './my-id-card.component.html',
  styleUrls: ['./my-id-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeroImagesComponent, MatIcon, AsyncPipe, TranslateModule],
})
export class MyIdCardComponent {
  fullName$ = this.sessionFacade.getLoadedSession().pipe(
    tap((session) =>
      JsBarcode('#barcode', session.activeCustomer.naooCustomerId, {
        lineColor: '#505050',
        width: 3,
        height: 150,
        fontOptions: 'bold',
        font: 'roboto',
      }),
    ),
    map((session) => [session.firstName, session.lastName].join(' ')),
  );

  constructor(private sessionFacade: SessionFacade) {}
}
