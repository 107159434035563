import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CustomGuide } from '../../../core/store/custom-guide/models/custom-guide';
import { MaterialListRowType } from '../../../material-list/models/material-list';
import { Observable } from 'rxjs';
import { HeaderHeightService } from '../../../shared/services/header-height/header-height.service';
import { MaterialListComponent } from '../../../material-list/material-list.component';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../core/store/material-row/models/material-row';
import { GroupByType, SortByType } from '../../shared/guides';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { customGuideHelpModal } from '../custom-guide-help-modal';
import { Router } from '@angular/router';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { NaooConstants } from '../../../shared/NaooConstants';
import { NaooLocalStorage } from '../../../shared/storage/local-storage/local-storage.service';
import { GuidesMobileActionsListComponent } from '../../shared/guides-mobile-actions-list/guides-mobile-actions-list.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { GuidesHeaderBreadcrumbComponent } from '../../shared/guides-header/guides-header-breadcrumb/guides-header-breadcrumb.component';
import { CustomGuideEmptyComponent } from '../custom-guide-empty/custom-guide-empty.component';
import { MatDrawerContent, MatSidenavModule } from '@angular/material/sidenav';
import { GuidesSideBarComponent } from '../../shared/guides-side-bar/guides-side-bar.component';
import { MatIcon } from '@angular/material/icon';
import { GuidesHeaderComponent } from '../../shared/guides-header/guides-header.component';
import { NoResultsComponent } from '../../../shared/no-results/no-results.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-custom-guide-content',
  templateUrl: './custom-guide-content.component.html',
  styleUrls: ['./custom-guide-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuidesMobileActionsListComponent,
    NgClass,
    GuidesHeaderBreadcrumbComponent,
    CustomGuideEmptyComponent,
    MatSidenavModule,
    GuidesSideBarComponent,
    MatDrawerContent,
    MatIcon,
    GuidesHeaderComponent,
    MaterialListComponent,
    NoResultsComponent,
    AsyncPipe,
    TranslateModule,
    NaooStringDefaulterPipe,
  ],
})
export class CustomGuideContentComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() set customGuide(customGuide: CustomGuide) {
    this._customGuide = customGuide;
    if (customGuide) {
      this.printInput$ = this.customGuideFacade.getExportMaterialsInput(
        customGuide.id,
        true,
      );
      this.exportInput$ = this.customGuideFacade.getExportMaterialsInput(
        customGuide.id,
        false,
      );
    }
  }

  get customGuide() {
    return this._customGuide;
  }

  sideNavOpen = true;
  // changes after the drawer completes an open/closed animation
  drawerClosedAndFinishedAnimating = true;
  isShowingMobileActions = false;
  headerHeight$: Observable<number>;
  printInput$: Observable<ExportMaterialsInput>;
  exportInput$: Observable<ExportMaterialsInput>;

  readonly listContext = MaterialRowContext.CustomGuide;
  private _customGuide: CustomGuide;

  @ViewChild('materialList')
  materialList: MaterialListComponent;

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private headerHeightService: HeaderHeightService,
    private analytics: NaooAnalyticsManager,
    private customDialogService: CustomDialogService,
    private dialogService: DefaultDialogService,
    private router: Router,
    private localStorage: NaooLocalStorage,
  ) {}

  ngOnInit() {
    this.headerHeight$ = this.headerHeightService.observeHeaderHeight();
    this.sideNavOpen = this.getSavedMenuState();
  }

  get isFiltering() {
    return this.customGuide.header.actionBar.searchText.length !== 0;
  }

  get shouldRenderList(): boolean {
    return (
      this.customGuide.hasLoaded &&
      ((this.isFiltering && this.customGuide.materialListRows.length > 0) ||
        this.customGuide.materialListRows.length > 0)
    );
  }

  get shouldRenderEmptyGuide(): boolean {
    return this.customGuide.hasLoaded && !this.hasMaterialRows();
  }

  get shouldRenderMobileActionsList(): boolean {
    return this.isShowingMobileActions && this.isMobile;
  }

  toggleDrawer() {
    this.sideNavOpen = !this.sideNavOpen;
    this.localStorage.setItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
      '' + this.sideNavOpen,
    );
  }

  drawerStateChangeCompleted() {
    this.drawerClosedAndFinishedAnimating = !this.sideNavOpen;
  }

  openLearnMoreModal() {
    this.dialogService.helperModal(
      'custom-guide-learn-more',
      customGuideHelpModal,
    );
  }

  openRenameGuideModal() {
    this.customDialogService.openRenameCustomGuideModal(
      this.customGuide.id,
      this.customGuide.header.name.en,
    );
  }

  editCustomGuide() {
    this.router.navigate([
      'guides',
      'custom-guide',
      this.customGuide.id,
      'organize',
    ]);
  }

  toggleMobileActions() {
    this.isShowingMobileActions = !this.isShowingMobileActions;
  }

  handleCategoryScroll(vsIndex: number) {
    this.materialList.scrollToIndex(vsIndex);
  }

  handleUpdateSearchText(searchText: string) {
    this.customGuideFacade.updateSearchText(this.customGuide.id, searchText);
  }

  handleUpdateMaterialView(listStyle: MaterialListStyle) {
    const eventInfo: AnalyticsEventInfo = {
      category: ListsAnalyticsConstants.customGuideCategory,
      action: ListsAnalyticsConstants.clickAction,
      label: `${listStyle} view`,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  handleGroupByChanged(groupByType: GroupByType) {
    this.customGuideFacade.updateGroupBy(this.customGuide.id, groupByType);

    this.analytics.trackAnalyticsEvent({
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `custom guide - group by ${groupByType}`,
    });
  }

  handleSortByChanged(sortByType: SortByType) {
    this.customGuideFacade.updateSortBy(this.customGuide.id, sortByType);

    this.analytics.trackAnalyticsEvent({
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `custom guide - sort by ${sortByType}`,
    });
  }

  handleClearInventoryQuantities() {
    this.customGuideFacade.clearInventoryQuantities(this.customGuide.id);

    this.analytics.trackAnalyticsEvent({
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'Clear on hand',
    });
  }

  handleToggleParOrdering(isEnabled: boolean) {
    this.customGuideFacade.toggleParOrdering(this.customGuide.id, isEnabled);

    const analyticsLabel = isEnabled
      ? 'Turn PAR ordering on'
      : 'Turn PAR ordering off';
    this.analytics.trackAnalyticsEvent({
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: analyticsLabel,
    });
  }

  get expansionAriaText(): string {
    return this.sideNavOpen
      ? 'LISTS.FILTERS.COLLAPSE_MENU'
      : 'LISTS.FILTERS.EXPAND_MENU';
  }

  private getSavedMenuState(): boolean {
    const savedState = this.localStorage.getItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
    );
    return savedState == null ? true : savedState === 'true';
  }

  private hasMaterialRows(): boolean {
    const hasMaterialRows =
      this.customGuide.materialListRows.filter(
        (row) => row.type === MaterialListRowType.MaterialRow,
      ).length > 0;
    return this.isFiltering || hasMaterialRows;
  }
}
