<naoo-modal-header
  [title]="modalTitle | translate"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeModal()"
>
</naoo-modal-header>

@if (isLoading) {
  <div class="loading-container">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
} @else {
  @if (itemException) {
    <span class="material-list-header">
      {{ materials?.length }}
      {{
        'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.MODAL.NUMBER_OF_SUBSTITUTES'
          | translate
      }}
    </span>
    <div class="exceptions-container">
      <naoo-error-materials-header [canFindRelated]="false">
      </naoo-error-materials-header>
      <naoo-order-issue-row
        [orderItem]="itemException"
        [isExpandedView]="false"
      >
      </naoo-order-issue-row>
    </div>
  }
  @if (materialWithSubstitutes) {
    <div class="materials-container">
      @if (!isMobile) {
        <div class="materials-header-container">
          <naoo-material-list-header
            [listStyle]="listStyle"
            [context]="context"
            [displayGoPointsLogo]="false"
            [isLoyaltyProgramEligible]="isLoyaltyProgramEligible$ | async"
          ></naoo-material-list-header>
        </div>
      }
      @if (!itemException) {
        <naoo-material-row-container
          class="base-material"
          [ngClass]="{
            'has-results': materials?.length,
          }"
          [materialNumber]="
            materialWithSubstitutes.materialRowOptions.materialNumber
          "
          [context]="context"
          [listStyle]="listStyle"
          [isMobile]="isMobile"
          [isLast]="false"
          [displayGoPointsLogo]="false"
        ></naoo-material-row-container>
      }
      @if (materials?.length) {
        @for (i of materials; track i; let isLast = $last) {
          <naoo-material-row-container
            class="related-material"
            [materialNumber]="i"
            [context]="context"
            [listStyle]="listStyle"
            [isMobile]="isMobile"
            [isLast]="isLast"
            [displayGoPointsLogo]="false"
          ></naoo-material-row-container>
        }
      } @else {
        <div class="empty-container"></div>
      }
    </div>
    <div class="buttons-container">
      <button (click)="closeModal()" class="done-button">
        {{ 'DONE' | translate }}
      </button>
    </div>
  }
}
