import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '../../shared/models/order-confirmation';
import { OrderItemRowComponent } from '../../order-item-row/order-item-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooCurrencyPipe } from '../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-special-orders',
  templateUrl: './non-stock-orders.component.html',
  styleUrls: ['./non-stock-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrderItemRowComponent, TranslateModule, NaooCurrencyPipe],
})
export class NonStockOrdersComponent {
  @Input() nonStockOrder: Order;
}
