import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { selectBannerAds } from './banner-ads.selectors';
import { BannerAdCollection } from './banner-ads.state';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BannerAdsFacade {
  constructor(private store: Store) {}

  getLoadedBannerAds(): Observable<BannerAdCollection> {
    return this.store
      .select(selectBannerAds)
      .pipe(filter((bannerAds) => !!bannerAds));
  }
}
