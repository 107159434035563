import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MaterialRecommendations } from '../../shared/services/material-recommendations/models/material-recommendations';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { MaterialRecommendationComponent } from '../../material-list/material-recommendation/material-recommendation.component';
import { MaterialRecommendationsFacade } from '../../core/store/material-recommendations/material-recommendations.facade';
import { Page } from '../../core/store/material-recommendations/material-recommendations.state';
import { CutoffMessageComponent } from '../cutoff-message/cutoff-message.component';
import { TopRecommendationComponent } from '../top-recommendation/top-recommendation.component';
import { BannerAdComponent } from '../../shared/banner-ad/banner-ad.component';

@Component({
  selector: 'naoo-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CutoffMessageComponent,
    TopRecommendationComponent,
    BannerAdComponent,
    MaterialRecommendationComponent,
  ],
})
export class HomePageComponent implements OnInit, OnDestroy {
  recommendedMaterials: MaterialRecommendations[] = [];
  topRecommendation: MaterialRecommendations;
  bannersLoaded = false;
  isTopRecommendationLoaded = false;
  isViewAllButtonVisible = true;
  isSingleListWithSingleItem = false;

  @ViewChild(MaterialRecommendationComponent, {
    read: ElementRef,
  })
  recommendationComponentElement: ElementRef;

  private destroyed$ = new Subject<void>();

  constructor(
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private materialRecommendationsFacade: MaterialRecommendationsFacade,
    public changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.loadRecommendations();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get hasMultipleRecommendedProducts(): boolean {
    const hasRecommendedCategory = this.recommendedMaterials?.length > 0;
    const hasOneRecommendedProduct =
      this.recommendedMaterials[0]?.materialListRows?.length === 1;

    return hasRecommendedCategory && !hasOneRecommendedProduct;
  }

  handleMaterialClick(materialNumber: string): void {
    this.ecommerceAnalyticsFacade.trackRecommendationClickEvent(materialNumber);
  }

  handleScrollToRecommendations(): void {
    this.recommendationComponentElement.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  bannerLoaded(loaded: boolean): void {
    this.bannersLoaded = loaded;
    this.changeDetector.detectChanges();
  }

  private setFirstValidRecommendationList(): void {
    if (this.recommendedMaterials.length) {
      this.topRecommendation = this.recommendedMaterials[0];
      this.isTopRecommendationLoaded = true;
    }
  }

  private toggleViewAllButtonVisibility(): void {
    if (this.recommendedMaterials[0]?.materialListRows.length === 1) {
      this.isViewAllButtonVisible = false;
      this.isSingleListWithSingleItem = true;
    }
  }

  private loadRecommendations(): void {
    this.materialRecommendationsFacade
      .getLoadedMaterialRecommendations(Page.HOMEPAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialRecommendations) => {
        this.recommendedMaterials = materialRecommendations;
        this.setFirstValidRecommendationList();
        this.toggleViewAllButtonVisibility();
        this.changeDetector.markForCheck();
      });
  }
}
