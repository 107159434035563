<div class="preferences-container">
  <div class="preferences-header">
    {{ 'PREFERENCES.SMS_NOTIFICATIONS.MANAGE_REMINDERS' | translate }}
  </div>
  <div class="customer-reminders-container">
    <div class="customer-reminders-message">
      <mat-icon
        class="bell-icon"
        matPrefix
        svgIcon="bell-icon"
        aria-hidden="true"
      ></mat-icon>
      <mat-form-field appearance="fill">
        <mat-label class="message-mat-label">{{
          'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_SELECT' | translate
        }}</mat-label>
        <mat-select
          disableRipple
          ngDefaultControl
          (selectionChange)="changeSelectedMessage($event)"
          [(ngModel)]="selectedMessage"
          [disabled]="!isPhoneValid"
        >
          @for (
            customerReminderMessage of reminderDetails.supportedReminderMessages;
            track customerReminderMessage
          ) {
            <mat-option [value]="customerReminderMessage">
              <span class="message-options">
                {{
                  'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_MESSAGES.' +
                    customerReminderMessage | translate
                }}
              </span>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="customer-reminders-rest-components">
      <div class="customer-reminders-date-time">
        <mat-form-field class="customer-reminders-day" appearance="fill">
          <mat-label>{{
            'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_DAY' | translate
          }}</mat-label>
          <mat-select
            disableRipple
            ngDefaultControl
            (selectionChange)="changeSelectedDay($event)"
            [(ngModel)]="selectedDay"
            [disabled]="!isPhoneValid"
          >
            @for (day of DayOfTheWeek; track day) {
              <mat-option [value]="day">
                {{ 'DAY_OF_THE_WEEK.' + day | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="customer-reminders-hour" appearance="fill">
          <mat-label>{{
            'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_TIME' | translate
          }}</mat-label>
          <mat-select
            disableRipple
            ngDefaultControl
            (selectionChange)="changeSelectedHour($event)"
            [(ngModel)]="selectedHour"
            [disabled]="!isPhoneValid"
          >
            @for (hour of HourOfTheDay; track hour) {
              <mat-option [value]="HourOfTheDay.indexOf(hour)">
                {{
                  'HOUR_OF_THE_DAY.' + HourOfTheDay.indexOf(hour) | translate
                }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <label class="customer-reminders-timezone">{{
          getTimezoneTranslation()
        }}</label>
      </div>
      <button
        class="primary-button"
        type="submit"
        [disabled]="disableAddCustomerReminder"
        (click)="addCustomerReminder()"
      >
        {{
          (editingReminderIndex != undefined
            ? 'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_UPDATE'
            : 'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_ADD'
          ) | translate
        }}
      </button>
    </div>
  </div>

  <div class="preferences-row">
    @for (
      customerReminder of currentCustomerReminders;
      track customerReminder.messageId;
      let reminderIndex = $index
    ) {
      <mat-accordion>
        <mat-expansion-panel class="preference-card">
          <mat-expansion-panel-header class="preference-card-header">
            <mat-panel-title
              class="preference-card-header-title"
              matTooltip="{{
                'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_MESSAGES.' +
                  customerReminder.messageId | translate
              }}"
            >
              <span class="title-text">
                {{ getTitleDate(customerReminder) }}
              </span>
              <div class="title-reminder">
                {{ getTitleCustomerReminder(customerReminder) }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="preference-card-body">
            <p>
              {{
                'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_MESSAGES.' +
                  customerReminder.messageId | translate
              }}
            </p>
            <div class="button-container">
              <button (click)="editCustomerReminder(reminderIndex)">
                {{ 'PREFERENCES.TOOLTIPS.EDIT' | translate }}
              </button>
              <button (click)="deleteCustomerReminder(reminderIndex)">
                {{ 'PREFERENCES.TOOLTIPS.DELETE' | translate }}
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    }
  </div>
</div>
