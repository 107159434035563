<form class="coupon-input-container" (ngSubmit)="addCouponCode()">
  <mat-form-field class="add-coupon-input-box">
    <mat-label>{{ 'CART_COUPON.ENTER_COUPON' | translate }}</mat-label>
    <input
      class="coupon-entry"
      matInput
      autocomplete="off"
      [formControl]="couponNameFormControl"
      [attr.maxlength]="maximumLength"
    />
    @if (cartCoupons.isMaxCoupons) {
      <mat-hint>
        {{ 'CART_COUPON.LIMIT_REACHED' | translate }}
      </mat-hint>
    }
    @if (couponNameFormControl.hasError('alreadyExistingCouponCode')) {
      <mat-error class="name-already-exists">
        {{ 'CART_COUPON.COUPON_ALREADY_EXISTS' | translate }}
      </mat-error>
    }
    @if (couponNameFormControl.errors?.pattern) {
      <mat-error class="invalid-characters">
        {{ 'INVALID_CHARACTERS' | translate }}
      </mat-error>
    }
  </mat-form-field>

  <button
    class="primary-button add-button"
    type="submit"
    [disabled]="isAddButtonDisabled || (loadingState$ | async)"
  >
    {{ 'CART_COUPON.ADD' | translate }}
  </button>
</form>
