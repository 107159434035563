@if (standardOrderTotals?.lines !== 0 && costSummary.customerInfo) {
  <div class="cart-print-container">
    <naoo-print-header [customerBrand]="costSummary.customerInfo.customerBrand">
    </naoo-print-header>
    <div class="print-page-title">
      {{ 'CART.ORDER_SUMMARY' | translate }}
    </div>
    <div class="print-cost-summary">
      <div class="col-1">
        <ng-container
          [ngTemplateOutletContext]="{
            label: 'CART.PRINT.CUSTOMER' | translate,
            value:
              costSummary.customerInfo.customerName +
              '<br/>' +
              ('CART.PRINT.CUSTOMER_ID'
                | translate
                  : { customerId: costSummary.customerInfo.customerNumber }),
            icon: 'store-icon',
            class: 'customer',
          }"
          [ngTemplateOutlet]="summaryCell"
        ></ng-container>
        <ng-container
          [ngTemplateOutletContext]="{
            label: 'CART.PRINT.SHIP_DATE' | translate,
            value: costSummary.shippingInfo?.routeDate
              ? (costSummary.shippingInfo?.routeDate | naooDate: 'shortDate')
              : ('CART.PRINT.TBD' | translate),
            icon: 'calendar-icon',
            class: 'ship-date',
          }"
          [ngTemplateOutlet]="summaryCell"
        ></ng-container>
        <ng-container
          [ngTemplateOutletContext]="{
            label: 'CART.PRINT.PO' | translate,
            value: costSummary.shippingInfo.poNumber || '\u00A0',
            icon: 'number-icon',
            class: 'po',
          }"
          [ngTemplateOutlet]="summaryCell"
        ></ng-container>
      </div>
      <div class="col-2">
        <ng-container
          [ngTemplateOutletContext]="{
            label: 'CART.LINES' | translate,
            value: standardOrderTotals?.lines,
            class: 'lines',
          }"
          [ngTemplateOutlet]="summaryCell"
        ></ng-container>
        <ng-container
          [ngTemplateOutletContext]="{
            label: 'CART.TOTAL_QUANTITY' | translate,
            value: standardOrderTotals?.totalQuantity,
            class: 'item-totals',
          }"
          [ngTemplateOutlet]="summaryCell"
        ></ng-container>
      </div>
      <div class="col-3">
        <ng-container
          [ngTemplateOutletContext]="{
            label: 'CART.ESTIMATED_COST' | translate,
            value:
              standardOrderTotals?.estimatedCost
              | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2',
            class: 'total',
          }"
          [ngTemplateOutlet]="summaryCell"
        ></ng-container>
      </div>
      <ng-template
        #summaryCell
        let-cls="class"
        let-icon="icon"
        let-label="label"
        let-value="value"
      >
        <div [ngClass]="{ 'icon-spacer': !icon }" class="cell {{ cls }}">
          @if (icon) {
            <mat-icon [svgIcon]="icon"></mat-icon>
          }
          <div class="content">
            <div class="label">{{ label }}</div>
            <div [innerHTML]="value" class="value"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
}
