@if (isProductSuccessfullyRemoved) {
  <div class="success">
    {{ 'LISTS.TOAST_PRODUCT_REMOVED_FROM_SUCCESS' | translate }}
    <strong>{{ categoryName || ('ORDER_GUIDE.OTHER' | translate) }}</strong>
  </div>
} @else {
  <div class="fail">
    {{ 'LISTS.TOAST_FAIL' | translate }}
    <strong>{{ categoryName || ('ORDER_GUIDE.OTHER' | translate) }}</strong>
  </div>
}
