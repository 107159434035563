<div
  tabindex="0"
  #docketContainer
  class="docket"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  @if (!!title) {
    <naoo-generic-docket-header
      [title]="title"
      [tooltipKey]="tooltipKey"
      (close)="onClose()"
    ></naoo-generic-docket-header>
  }
  <ng-content></ng-content>
</div>
