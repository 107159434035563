@if (StoreScenario.SplitPickup === scenario) {
  @for (section of storeSectionGroup.sections; track idx; let idx = $index) {
    <naoo-cart-review-expansion
      [cartCounts]="storeCartCounts[idx]"
      [section]="section"
      [isOffline]="isOffline"
      [isSectionInvalid]="isSectionInvalid(section, idx)"
      [submitAttempted]="false"
      [storeScenario]="scenario"
      (expansionToggled)="expansionToggled.emit()"
    >
    </naoo-cart-review-expansion>
  }
}

@if (StoreScenario.SameDayPickup === scenario) {
  <naoo-cart-review-expansion
    [cartCounts]="storeCartCounts[0]"
    [section]="storeSectionGroup.sections[0]"
    [isOffline]="isOffline"
    [isSectionInvalid]="false"
    [submitAttempted]="false"
    [storeScenario]="scenario"
    (expansionToggled)="expansionToggled.emit()"
  >
  </naoo-cart-review-expansion>
}

@if (StoreScenario.FutureDayPickup === scenario) {
  <naoo-cart-review-expansion
    [cartCounts]="storeCartCounts[0]"
    [section]="storeSectionGroup.sections[0]"
    [isOffline]="isOffline"
    [isSectionInvalid]="isSectionInvalid(storeSectionGroup.sections[0], 0)"
    [submitAttempted]="false"
    [storeScenario]="scenario"
    (expansionToggled)="expansionToggled.emit()"
  >
  </naoo-cart-review-expansion>
}
