<ng-container>
  <button
    id="order-tools"
    attr.aria-label="{{ 'MENU.ORDER_TOOLS' | translate }}"
    class="order-tools-button"
    [ngClass]="{ disabled: isDisabled }"
    [matMenuTriggerFor]="menu"
    [disabled]="isDisabled"
  >
    <mat-icon
      [ngClass]="{ 'rotate-arrow': trigger?.menuOpen }"
      class="cart-icon"
      svgIcon="add_cart_icon"
    ></mat-icon>
    <span>{{ 'MENU.ORDER_TOOLS' | translate }}</span>
    <mat-icon
      [ngClass]="{ 'rotate-arrow': trigger?.menuOpen }"
      class="dropdown-arrow-icon"
      svgIcon="arrow-v2"
    ></mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before">
    <button
      id="import-order-button"
      class="menu-item"
      mat-menu-item
      attr.aria-label="{{ 'MENU.IMPORT_ORDER' | translate }}"
      (click)="openImportOrderModal()"
    >
      {{ 'MENU.IMPORT_ORDER' | translate }}
    </button>

    <button
      id="quick-add-button"
      class="menu-item"
      mat-menu-item
      attr.aria-label="{{ 'MENU.QUICK_ADD' | translate }}"
      (click)="navigateToQuickAdd()"
    >
      {{ 'MENU.QUICK_ADD' | translate }}
    </button>
  </mat-menu>
</ng-container>
