@if (activeCartSummary) {
  <div #mainContainer class="main-container">
    <naoo-cost-summary-container
      class="print-only"
      [context]="costSummaryContext"
      [isQuickAddEnabled]="activeCartSummary.isQuickAddEnabled"
      [isPrintOnly]="true"
    >
    </naoo-cost-summary-container>
    <div class="product-container">
      <naoo-active-cart-header
        [activeCartSummary]="activeCartSummary"
        [hasIspuOrExpressPermissions]="hasIspuOrExpressPermissions$ | async"
        (updatedPoNumber)="updatePoNumber($event)"
      >
      </naoo-active-cart-header>
      @if (activeCartSummary.criticalItems?.length) {
        <naoo-critical-items-content
          [criticalItems]="activeCartSummary.criticalItems"
        >
        </naoo-critical-items-content>
      }
      <naoo-cart-action-bar-container
        [activeCartSummary]="activeCartSummary"
        [activeCartContentWidth]="mainContainer.scrollWidth"
        [isMobile]="isMobile$ | async"
        [disablePrint]="shouldRenderPrint"
      ></naoo-cart-action-bar-container>
      @if (activeCartSummary.materialNumbers.length > 0) {
        <naoo-material-list
          [materialListItems]="activeCartSummary.materialNumbers"
          [context]="materialRowContext"
          [listStyle]="materialListStyle"
          [shouldRenderPrint]="shouldRenderPrint"
        >
        </naoo-material-list>
      }
      @if (
        activeCartSummary.materialNumbers.length === 0 &&
        !activeCartSummary.isQuickAddEnabled
      ) {
        <naoo-empty-cart
          class="empty-cart"
          [isOffline]="activeCartSummary.isOffline"
        >
        </naoo-empty-cart>
      }
      @if (activeCartSummary.isQuickAddEnabled) {
        <naoo-quick-add
          [customerBrand]="customerBrand$ | async"
        ></naoo-quick-add>
      }
    </div>
    <div [ngClass]="{ 'side-bar': !(isMobile$ | async) }">
      <naoo-cost-summary-container
        #costSummaryContainer
        class="not-print"
        [context]="costSummaryContext"
        [isQuickAddEnabled]="activeCartSummary.isQuickAddEnabled"
      >
      </naoo-cost-summary-container>
      <naoo-smart-cart-recommendations
        class="smart-cart-recommendations"
      ></naoo-smart-cart-recommendations>
    </div>
  </div>
}
