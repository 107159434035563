import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SmartCartRecommendation } from '../../../../core/store/smart-cart-recommendations/smart-cart-recommendations.state';
import { SmartCartRecommendationsFacade } from '../../../../core/store/smart-cart-recommendations/smart-cart-recommendations.facade';
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { MaterialRowContext } from '../../../../core/store/material-row/models/material-row';
import { EcommerceAnalyticsFacade } from '../../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { AuxiliaryAnalyticsData } from '../../../../core/services/ecommerce-analytics/models/google-events';
import { SmartCartRecordComponent } from './smart-cart-record/smart-cart-record.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-smart-cart-recommendations',
  templateUrl: './smart-cart-recommendations.component.html',
  styleUrl: './smart-cart-recommendations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SmartCartRecordComponent, AsyncPipe, TranslateModule],
})
export class SmartCartRecommendationsComponent implements OnInit {
  private readonly debounceTimeMs = 1000;
  private readonly maxNumberOfRecords = 5;

  readonly analytics: AuxiliaryAnalyticsData = {
    recommendationEngineName: MaterialRowContext.HandPickedForYou.toString(),
  };
  readonly context = MaterialRowContext.Recommendation;

  orderableSmartCartRecommendationRecords$ = this.smartCartRecommendationsFacade
    .getOrderableSmartCartRecommendationRecords(this.maxNumberOfRecords)
    .pipe(
      debounceTime(this.debounceTimeMs),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap((recommendations) => this.trackGoogleViewItemList(recommendations)),
      shareReplay(),
    );

  constructor(
    private smartCartRecommendationsFacade: SmartCartRecommendationsFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnInit(): void {
    this.smartCartRecommendationsFacade.loadSmartCartRecommendations();
  }

  trackGoogleViewItemList(recommendations: SmartCartRecommendation[]): void {
    if (!recommendations?.length) {
      return;
    }
    this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
      recommendations.map((recommendation) => recommendation.product),
      this.context,
      {
        analytics: this.analytics,
      },
    );
  }
}
