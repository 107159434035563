import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CostSummary, CostSummaryContext } from './cost-summary';
import { selectCostSummary } from './cost-summary.selectors';

@Injectable({ providedIn: 'root' })
export class CostSummaryFacade {
  constructor(private store: Store) {}

  getCostSummary(context: CostSummaryContext): Observable<CostSummary> {
    return this.store.select(selectCostSummary(context));
  }
}
