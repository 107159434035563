import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { StorePurchaseKey } from '../../../../../shared/models/store-purchase-key';
import {
  StorePurchase,
  StorePurchaseHistoryViewModel,
} from '../../../../../core/store/store-purchase-history/models/store-purchase-history-view-model';
import { CONTENT_SCROLL } from '../../../../../shared/services/scrollable-content/scrollable-content.service';
import { SearchFieldComponent } from '../../../../../shared/search-bar/search-field.component';
import { RouterExtrasService } from '../../../../../shared/services/router-extras/router-extras.service';
import { StoreDisplayContext } from '../../../../../shared/store-display/store-display';
import { DateRange } from '../../../../../shared/date-range-selector/date-range';
import { VirtualScrollerComponent } from '../../../../../vendor/ngx-virtual-scroller/virtual-scroller';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DateRangeSelectorComponent } from '../../../../../shared/date-range-selector/date-range-selector.component';
import { NgTemplateOutlet } from '@angular/common';
import { GoPointsLogoComponent } from '../../../../../shared/go-points-logo/go-points-logo.component';
import { StoreDisplayComponent } from '../../../../../shared/store-display/store-display.component';
import { ErrorStateComponent } from '../../../../../shared/error-state/error-state/error-state.component';
import { NaooPricePipe } from '../../../../../shared/pipes/naoo-price.pipe';
import { NaooDatePipe } from '../../../../../shared/pipes/naoo-date.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-store-purchase-history-content',
  templateUrl: './store-purchase-history-content.component.html',
  styleUrls: ['./store-purchase-history-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatProgressBar,
    DateRangeSelectorComponent,
    SearchFieldComponent,
    NgTemplateOutlet,
    GoPointsLogoComponent,
    VirtualScrollerComponent,
    StoreDisplayComponent,
    ErrorStateComponent,
    NaooPricePipe,
    NaooDatePipe,
    TranslateModule,
  ],
})
export class StorePurchaseHistoryContentComponent {
  @Output() storePurchaseKeyEmitter = new EventEmitter<StorePurchaseKey>();
  @Output() searchValueEmitter = new EventEmitter<string>();
  @Output() dateRangeEmitter = new EventEmitter<DateRange>();

  @Input() searchTerm: string;
  @Input() isTabletWidth: boolean;
  @Input() set filteredViewModel(viewModel: StorePurchaseHistoryViewModel) {
    this._viewModel = viewModel;
    setTimeout(() => {
      if (
        this.routerExtrasService.getRestoreScrollPosition(window.location.href)
      ) {
        this.routerExtrasService.restoreScrollPosition(window.location.href);
      }
    });
  }

  get filteredViewModel(): StorePurchaseHistoryViewModel {
    return this._viewModel;
  }

  readonly storeDisplayContext = StoreDisplayContext.PurchaseHistory;

  @ViewChild('scroll')
  virtualScroll: VirtualScrollerComponent<StorePurchaseHistoryViewModel>;

  @ViewChild('searchField', {
    static: true,
  })
  searchField: SearchFieldComponent;

  constructor(
    private routerExtrasService: RouterExtrasService,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  private _viewModel: StorePurchaseHistoryViewModel;

  compareViewModels(
    storePurchase: StorePurchase,
    otherStorePurchase: StorePurchase,
  ): boolean {
    return storePurchase?.purchaseKey === otherStorePurchase?.purchaseKey;
  }

  selectStorePurchaseDetails(storePurchase: StorePurchase): void {
    if (!this.isTabletWidth) {
      this.storePurchaseKeyEmitter.emit(storePurchase);
    }
  }
}
