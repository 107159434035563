<naoo-hero-images [hostComponent]="'my-guides-v4'"></naoo-hero-images>
<div class="naoo-hero-page-container">
  <h1 class="naoo-heading">{{ 'MENU.GUIDES' | translate }}</h1>
  <div class="guide-difference-message">
    {{ 'LISTS.HELP.GUIDES_DIFFERENCE.TITLE' | translate }}
    <button
      class="guide-difference-link"
      (click)="openGuideDifferenceHelpModal()"
    >
      {{ 'LISTS.HELP.CLICK' | translate }}
    </button>
  </div>
  <div class="main-guides-section">
    <naoo-main-guide-card
      class="order-guide-list-card"
      [listTitle]="'LISTS.ORDER_GUIDE' | translate"
      [totalListProducts]="(orderGuideCount$ | async) || 0"
      [customerBrand]="customerBrand"
      [orderGuide]="orderGuide$ | async"
    >
    </naoo-main-guide-card>
    <naoo-main-guide-card
      class="critical-item-list-card"
      [listTitle]="'LISTS.CRITICAL_ITEMS_GUIDE' | translate"
      [totalListProducts]="(criticalItemsCount$ | async) || 0"
      [isCriticalItemsGuide]="true"
    >
    </naoo-main-guide-card>
  </div>
  <span class="custom-guides-header">
    <h2 class="naoo-heading">{{ 'LISTS.CUSTOM_GUIDE' | translate }}</h2>
    <button
      class="create-guide-button custom-guides-header-button"
      (click)="openCreateCustomGuideModal()"
    >
      <mat-icon
        class="create-guide-icon"
        svgIcon="create_custom_guide_icon"
      ></mat-icon>
      {{ 'LISTS.CREATE_CUSTOM_GUIDE' | translate }}
    </button>
    @if (!isMobile) {
      <button
        class="import-guide-button custom-guides-header-button"
        (click)="openImportGuideModal()"
      >
        <mat-icon class="import-guide-icon" svgIcon="import_icon"></mat-icon>
        {{ 'LISTS.IMPORT_CUSTOM_GUIDE_BUTTON' | translate }}
      </button>
    }
  </span>
  @if ((customGuideInfos$ | async)?.length > 0) {
    <div class="custom-guides-lists">
      @for (customGuide of customGuideInfos$ | async; track customGuide.id) {
        <naoo-list-card
          [customGuideId]="customGuide.id"
          [listTitle]="customGuide.name"
          [totalListProducts]="customGuide.totalCustomGuideProducts"
          [showCopyGuide]="showCopyGuide"
        >
        </naoo-list-card>
      }
    </div>
  } @else {
    <div class="naoo-empty-custom-guide">
      <div class="card empty-guide-left-column">
        <div class="text-head">
          {{ 'LISTS.EMPTY_GUIDE.MESSAGE.TITLE' | translate }}
        </div>
        <div class="text-body">
          {{ 'LISTS.EMPTY_GUIDE.MESSAGE.DESCRIPTION' | translate }}
        </div>
      </div>
      @if (!isMobile) {
        <div class="empty-guide-right-column">
          <div class="card link-card">
            <button
              id="empty-create-guide-button"
              class="link-button"
              (click)="openCreateCustomGuideModal()"
            >
              <mat-icon
                class="create-guide-icon"
                svgIcon="create_custom_guide_icon"
              ></mat-icon>
              <div class="link-text">
                {{ 'LISTS.CREATE_CUSTOM_GUIDE' | translate }}
              </div>
            </button>
          </div>
          <div class="div-16px-filler"></div>
          <div class="card link-card">
            <button
              id="empty-import-guide-button"
              class="link-button"
              (click)="openImportGuideModal()"
            >
              <mat-icon
                class="import-guide-icon"
                svgIcon="import_icon"
              ></mat-icon>
              <div class="link-text">
                {{ 'LISTS.IMPORT_CUSTOM_GUIDE_BUTTON' | translate }}
              </div>
            </button>
          </div>
        </div>
      }
    </div>
  }
</div>
