@if (isDisabled) {
  <div class="customer-unit-selector disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
} @else {
  <a [routerLink]="customerUnitRoute" class="customer-unit-selector">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

<ng-template #content>
  <div class="selected-customer-unit">
    <mat-icon class="address-icon" svgIcon="address-icon"></mat-icon>
    <naoo-customer-unit-info></naoo-customer-unit-info>
  </div>
  @if (showArrowIcon) {
    <mat-icon svgIcon="arrow-v2" class="arrow"></mat-icon>
  }
</ng-template>
