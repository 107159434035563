import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  LineStatusDetail,
  OrderItem,
} from '../../shared/models/order-confirmation';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { Observable, Subject } from 'rxjs';
import { MaterialRelatedFacade } from '../../../core/store/material-related/material-related.facade';
import { LocalizationService } from '../../../shared/services/translation/localization.service';
import { takeUntil } from 'rxjs/operators';
import { DeviceIdentifierService } from '../../../shared/services/device-identifier/device-identifier.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { ConfirmationMaterialDetailsComponent } from '../../shared/confirmation-material-details-component/confirmation-material-details.component';
import { MatIcon } from '@angular/material/icon';
import { NaooPricePipe } from '../../../shared/pipes/naoo-price.pipe';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-order-issue-row',
  templateUrl: './order-issue-row.component.html',
  styleUrls: ['./order-issue-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ConfirmationMaterialDetailsComponent,
    MatIcon,
    AsyncPipe,
    NaooPricePipe,
    NaooStringDefaulterPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class OrderIssueRowComponent implements OnInit, OnDestroy {
  @Input() orderItem: OrderItem;
  @Input() isExpandedView: boolean;
  @Input() materialRelatedRouteDate?: Date;

  hasRelated$: Observable<boolean>;
  private destroyed$ = new Subject<void>();
  private isMobile = false;

  constructor(
    private materialRelatedFacade: MaterialRelatedFacade,
    private changeDetectionRef: ChangeDetectorRef,
    private customDialogService: CustomDialogService,
    private localizationService: LocalizationService,
    private deviceIdentifierService: DeviceIdentifierService,
  ) {}

  ngOnInit() {
    this.hasRelated$ = this.materialRelatedFacade.hasMaterialRelated(
      this.orderItem.itemDetail.id,
    );

    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    this.changeDetectionRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openMaterialSubstitutesModal(): void {
    let hasRelated: boolean = false;
    this.hasRelated$.subscribe((value) => (hasRelated = value));
    if (!hasRelated) {
      return;
    }

    this.customDialogService.materialRelatedModal(
      this.orderItem,
      undefined,
      this.isMobile,
    );
  }

  getToolTipText(lineStatusDetail: LineStatusDetail): string | undefined {
    return lineStatusDetail?.secondaryLineStatus?.[
      this.localizationService.currentLanguage
    ];
  }

  getToolTipTabIndex(lineStatusDetail: LineStatusDetail): string {
    return lineStatusDetail?.secondaryLineStatus?.[
      this.localizationService.currentLanguage
    ]
      ? '0'
      : '';
  }
}
