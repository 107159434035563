<div class="active-cart-header-container">
  @if (!!activeCartSummary && !!activeCartSummary.customerHeaderInfo) {
    <div class="active-cart-header-content" id="cart-header">
      <div class="customer-info">
        <span class="header-title">{{
          'CART.ACTIVE_CART.HEADER.CUSTOMER' | translate
        }}</span>
        <div class="header-content">
          <span class="customer-name">{{
            activeCartSummary.customerHeaderInfo.customerName
          }}</span>
          <span id="customer-number"
            >#{{ activeCartSummary.customerHeaderInfo.customerId }}</span
          >
        </div>
      </div>
      <div class="quantity-info">
        <span class="header-title">{{
          'CART.ACTIVE_CART.HEADER.ORDER_QUANTITY' | translate
        }}</span>
        <div class="header-content">
          <span id="quantity-lines">
            {{
              'CART.ACTIVE_CART.HEADER.TOTAL_LINES'
                | translate
                  : {
                      lines: activeCartSummary.orderQuantity.lines,
                    }
            }},
          </span>
          <span id="quantity-items">{{
            'CART.ACTIVE_CART.HEADER.TOTAL_ITEMS'
              | translate
                : {
                    items: activeCartSummary.orderQuantity.items,
                  }
          }}</span>
        </div>
      </div>
    </div>
  }
  <div class="active-cart-header-content secondary-header-content">
    <naoo-po-input
      [isOffline]="activeCartSummary?.isOffline"
      [isPoRequired]="activeCartSummary?.isPoRequired"
      [poNumber]="activeCartSummary?.poNumber"
      (updatedPoNumber)="updatedPoNumber.emit($event)"
    >
    </naoo-po-input>
    <div class="delivery-schedule-content">
      @if (hasIspuOrExpressPermissions) {
        <naoo-select-order-method-widget
          [disabled]="!!activeCartSummary && activeCartSummary.isOffline"
          [wideLayout]="true"
        ></naoo-select-order-method-widget>
      } @else {
        <naoo-delivery-schedule-widget
          class="delivery-schedule-widget"
          customAnalyticsCategory="shopping cart"
          [wideLayout]="true"
          [disabled]="!!activeCartSummary && activeCartSummary.isOffline"
        ></naoo-delivery-schedule-widget>
      }
    </div>
  </div>
</div>
