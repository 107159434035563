import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderMethodButtonContainerComponent } from '../order-method-button-container/order-method-button-container.component';

@Component({
  selector: 'naoo-order-method-step-container',
  templateUrl: './order-method-step-container.component.html',
  styleUrls: ['./order-method-step-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrderMethodButtonContainerComponent],
})
export class OrderMethodStepContainerComponent {
  @Input() isFirst: boolean = false;
  @Input() isLast: boolean = false;
  @Input() primaryButtonAction: () => void;
  @Input() isPrimaryButtonDisabled: boolean = false;
  @Input() isPrimaryButtonHidden: boolean = false;
  @Input() secondaryButtonAction: () => void;
  @Input() isSecondaryButtonDisabled: boolean = false;
  @Input() isSecondaryButtonHidden: boolean = false;
}
