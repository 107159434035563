<input
  #box
  class="quantity-box-decimal"
  type="text"
  maxlength="6"
  (focus)="focus()"
  pattern="[0-9,.]*"
  (blur)="focusWasLost()"
  (contextmenu)="suppressContextMenu($event)"
  (keydown.enter)="goToNextBox()"
  (keydown.shift.enter)="goToPreviousBox()"
  (keydown.arrowdown)="goToNextBox()"
  (keydown.arrowup)="goToPreviousBox()"
  (keyup.arrowdown)="focus()"
  (keyup.arrowup)="focus()"
  (keypress)="onKeyPress($event)"
  (textInput)="onTextInput($event)"
  (mouseup)="selectAllText()"
  [attr.data-qty-id]="quantityBoxId"
  [attr.aria-label]="qtyBoxLabel"
/>
