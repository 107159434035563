import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NaooConstants } from '../../NaooConstants';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-add-to-critical-items-guide-toast',
  templateUrl: './add-to-critical-items-guide-toast.component.html',
  styleUrls: ['./add-to-critical-items-guide-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class AddToCriticalItemsGuideToastComponent implements OnInit {
  protected message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private readonly data: {
      message: string;
    },
    private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.message = this.data.message;
    this.changeDetectorRef.markForCheck();
  }

  navigate(): void {
    this.router.navigate([NaooConstants.GUIDES_PATH, 'critical-items-guide']);
  }
}
