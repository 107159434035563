import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import {
  DefaultFilterGroup,
  Taxonomy,
} from '../../../../../core/store/search/shared/search-results';
import { SearchFiltersComponent } from '../search-filters.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-mobile-filter-list',
  templateUrl: './mobile-filter-list.component.html',
  styleUrls: ['./mobile-filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchFiltersComponent, MatIcon, TranslateModule],
})
export class MobileFilterListComponent {
  @Input() defaultFilters: DefaultFilterGroup[];
  @Input() taxonomy: Taxonomy;

  @Output()
  closeFilterList: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private searchFacade: SearchFacade) {}

  close() {
    this.closeFilterList.emit(true);
  }

  clearAll() {
    this.searchFacade.clearAllFilters();
  }
}
