import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { CartQuantityUpdate } from 'src/app/core/store/cart/cart.actions';
import { DOCUMENT, NgClass } from '@angular/common';
import {
  MaterialWarning,
  MaterialWarningType,
} from 'src/app/core/store/material-warning/material-warning';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import {
  MaterialLine,
  MaterialOrderingInfo,
  MaterialRowContext,
  MaterialRowSharedOptions,
} from '../../../../../../core/store/material-row/models/material-row';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-quantity-input',
  templateUrl: './material-quantity-input.component.html',
  styleUrls: ['./material-quantity-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NgClass, TranslateModule],
})
export class MaterialQuantityInputComponent implements AfterViewInit {
  @Input() currentOrderingInfo: MaterialOrderingInfo;
  @Input() orderingInfos: MaterialOrderingInfo[];
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() materialWarning: MaterialWarning;
  @Input() isMobile: boolean;
  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();

  @ViewChild('quantityInput', { static: true }) quantityInput: ElementRef;
  @ViewChild('incrementButton', { static: false }) incrementButton: ElementRef;
  @ViewChild('decrementButton', { static: false }) decrementButton: ElementRef;

  private readonly primaryUomBasedWarnings = [
    MaterialWarningType.UnitCaseConversion,
    MaterialWarningType.PartialStockCase,
    MaterialWarningType.QuantityThresholdExceededCase,
    MaterialWarningType.RepeatingDigitCase,
    MaterialWarningType.PartialStockCaseUnit,
    MaterialWarningType.NoStock,
    MaterialWarningType.MinimumQuantitySoftStop,
    MaterialWarningType.MaximumQuantitySoftStop,
  ];

  private readonly primaryUomBasedHardWarnings = [
    MaterialWarningType.MaximumQuantityHardStop,
    MaterialWarningType.OverAllocation,
  ];

  private readonly secondaryUomBasedWarnings = [
    MaterialWarningType.UnitCaseConversion,
    MaterialWarningType.PartialStockCaseUnit,
    MaterialWarningType.QuantityThresholdExceededUnit,
    MaterialWarningType.RepeatingDigitUnit,
    MaterialWarningType.NoStock,
  ];

  constructor(
    private cartFacade: CartFacade,
    private searchFacade: SearchFacade,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ngAfterViewInit(): void {
    this.emitQuantityInputChanged();
  }

  get input(): HTMLInputElement {
    return this.quantityInput.nativeElement as HTMLInputElement;
  }

  get shouldDisableInput(): boolean {
    const isContextOrderDetailsEditModal =
      MaterialRowContext.OrderDetailsEditModal ===
      this.materialRowOptions.context;

    if (!isContextOrderDetailsEditModal) {
      return !this.materialRowOptions.isCartLoaded;
    }
    return (
      isContextOrderDetailsEditModal && !this.currentOrderingInfo.line?.quantity
    );
  }

  get shouldRenderButtons(): boolean {
    const isContextProductDetails =
      MaterialRowContext.ProductDetails === this.materialRowOptions.context;

    const isComplimentaryProduct =
      MaterialRowContext.ComplimentaryProduct ===
      this.materialRowOptions.context;

    if (isContextProductDetails || isComplimentaryProduct) {
      return true;
    }
    return this.isMobile && !this.materialRowOptions.isParEnabled;
  }

  get isDecrementDisabled(): boolean {
    return (
      !this.materialRowOptions.isCartLoaded ||
      this.currentOrderingInfo.line.quantity < 1
    );
  }

  get isIncrementDisabled(): boolean {
    return (
      !this.materialRowOptions.isCartLoaded ||
      this.currentOrderingInfo.line.quantity > 998
    );
  }

  get inputBorder(): string {
    const isCase = NaooConstants.Uom.Case === this.currentOrderingInfo.uom;
    const warningType = this.materialWarning?.type;
    if (!warningType) {
      return '';
    } else if (
      isCase &&
      this.primaryUomBasedHardWarnings.includes(warningType)
    ) {
      return 'hard-warning-border';
    } else if (isCase && this.primaryUomBasedWarnings.includes(warningType)) {
      return 'warning-border';
    } else if (
      !isCase &&
      this.secondaryUomBasedWarnings.includes(warningType)
    ) {
      return 'warning-border';
    }
    return '';
  }

  updateQuantity(): void {
    const { analytics, context, materialNumber } = this.materialRowOptions;

    const inputValue = this.getInputValue();
    const inactiveOrderingInfo = this.inactiveOrderingInfo();
    if (
      !inputValue &&
      [MaterialRowContext.CartSummary, MaterialRowContext.CartReview].includes(
        context,
      ) &&
      (!inactiveOrderingInfo || inactiveOrderingInfo.line.quantity === 0)
    ) {
      this.cartFacade.deleteCartMaterial(
        materialNumber,
        analytics?.recommendationEngineName,
      );
      return;
    }
    if (!this.isContext(MaterialRowContext.OrderDetailsEditModal)) {
      this.cartFacade.updateCartQuantities(
        [this.buildCartUpdate(inputValue)],
        analytics,
      );
    }
    if (this.isContext(MaterialRowContext.Browse)) {
      this.searchFacade.searchMaterialClick(materialNumber);
    }
    this.emitQuantityInputChanged();
  }

  decrementQuantity($event: MouseEvent) {
    const currentInput = this.input;
    const currentInputValue = parseInt(currentInput.value, 10);
    if (currentInputValue > 0) {
      currentInput.value = `${currentInputValue - 1}`;
      this.updateQuantity();
    }

    const isClickSourcedEvent = $event.clientX + $event.clientY > 0;
    if (this.decrementButton && isClickSourcedEvent) {
      this.decrementButton.nativeElement.blur();
    }
  }

  incrementQuantity($event: MouseEvent) {
    const currentInput = this.input;
    let currentInputValue = parseInt(currentInput.value, 10);
    if (currentInputValue < 999 || currentInput.value === '') {
      if (currentInput.value === '') {
        currentInputValue = 0;
      }
      currentInput.value = `${currentInputValue + 1}`;

      this.updateQuantity();
      this.onBlur();
    }

    const isClickSourcedEvent = $event.clientX + $event.clientY > 0;
    if (this.incrementButton && isClickSourcedEvent) {
      this.incrementButton.nativeElement.blur();
    }
  }

  onBlur(): void {
    if (this.isContext(MaterialRowContext.OrderDetailsEditModal)) {
      return;
    }
    this.cartFacade.blurCartQuantity(
      this.materialRowOptions.materialNumber,
      this.currentOrderingInfo.uom,
      this.materialRowOptions.context,
    );
  }

  onFocus() {
    this.cartFacade.focusCartQuantity(this.materialRowOptions.materialNumber);
    this.selectAll();
  }

  selectAll() {
    this.input.select();
    this.input.setSelectionRange(0, this.input.value.length);
  }

  onKeyPress(event: KeyboardEvent): boolean {
    if (event.charCode === 0) {
      return;
    }
    const charCode = String.fromCharCode(event.charCode);
    if (!this.shouldAccept(charCode)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  onTextInput(event: InputEvent): boolean {
    if (!this.shouldAccept(event.data)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  suppressContextMenu($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  nextInput() {
    const allInputs = this.queryForAllInputs();
    const selfIndex = allInputs.findIndex(
      (x) => x === this.quantityInput.nativeElement,
    );
    const nextElement = allInputs[selfIndex + 1];
    if (nextElement) {
      nextElement.focus();
    }
  }

  previousInput() {
    const allInputs = this.queryForAllInputs();
    const selfIndex = allInputs.findIndex(
      (x) => x === this.quantityInput.nativeElement,
    );
    const previousElement = allInputs[selfIndex - 1];
    if (previousElement) {
      previousElement.focus();
    }
  }

  private queryForAllInputs(): HTMLInputElement[] {
    return Array.from(
      this._document.getElementsByClassName('qty-input'),
    ).filter((x) => {
      return x.getBoundingClientRect().height > 0;
    }) as HTMLInputElement[];
  }

  private shouldAccept(input: string): boolean {
    return /^[0-9]{0,3}$/.test(input);
  }

  private buildCartUpdate(value: number): CartQuantityUpdate {
    const isAddedFromCriticalItem = this.isContext(
      MaterialRowContext.CriticalItem,
    );
    return {
      materialNumber: this.materialRowOptions.materialNumber,
      lines: [{ uom: this.currentOrderingInfo.uom, quantity: value }],
      isAddedFromCriticalItem: isAddedFromCriticalItem,
      context: this.materialRowOptions.context,
    };
  }

  private inactiveOrderingInfo(): MaterialOrderingInfo {
    return this.orderingInfos
      .filter(
        (orderingInfo) => orderingInfo.uom !== this.currentOrderingInfo.uom,
      )
      .pop();
  }

  private isContext(context: MaterialRowContext): boolean {
    return this.materialRowOptions.context === context;
  }

  private getInputValue(): number {
    this.quantityInput.nativeElement.value =
      this.quantityInput.nativeElement.value !== ''
        ? parseInt(this.quantityInput.nativeElement.value, 10)
        : '';

    return this.quantityInput.nativeElement.value !== ''
      ? parseInt(this.quantityInput.nativeElement.value, 10)
      : 0;
  }

  private emitQuantityInputChanged(): void {
    this.quantityInputChanged.emit({
      uom: this.currentOrderingInfo.uom,
      displayCode: this.currentOrderingInfo.displayCode,
      quantity: this.getInputValue(),
    });
  }
}
