<div class="search-header">
  <div class="top-header">
    <div>
      @if (shouldShowBreadcrumbs) {
        <naoo-categories-breadcrumbs
          [categoryLinks]="searchResult.resultSet.categoryLinks"
        >
        </naoo-categories-breadcrumbs>
      }
    </div>
  </div>
  <div class="bottom-header">
    <div class="bottom-header-left-content">
      @if (shouldShowSearchText) {
        <naoo-search-text
          [resultSet]="searchResult.resultSet"
          [searchText]="searchResult.searchText"
          [isOrderGuideTab]="isOrderGuideTab"
          [isMobile]="isMobile"
          class="search-text"
        >
        </naoo-search-text>
      }
      @if (hasResults) {
        <button class="mobile-filter-button" (click)="openFilterModal()">
          <span>{{ 'SORT.FILTER' | translate }}</span>
          @if (!!searchResult && searchResult.activeFilterCount > 0) {
            <div class="active-filter-badge">
              {{ searchResult.activeFilterCount }}
            </div>
          }
        </button>
      }
    </div>
    @if (shouldRenderRecommendationBanner) {
      <div class="search-recommendations">
        <naoo-search-recommendations-container
          [sponsoredRecommendations]="
            searchResult.resultSet.sponsoredRecommendations
          "
        ></naoo-search-recommendations-container>
      </div>
    }
    @if (searchResult?.resultSet?.totalResults) {
      <div class="search-options">
        <naoo-material-view-select
          [disabled]="searchResult.resultSet.totalResults === 0"
          class="view-select"
          [selectedViewStyle]="searchResult.preferredView"
          [viewStyleOptions]="viewOptions"
          (onMaterialViewChange)="materialViewChange($event)"
        ></naoo-material-view-select>
        <div class="toggle-filters">
          @if (isAvailableFulfillment) {
            <naoo-available-today-filter
              [availableTodayFilter]="searchResult.availableTodayFilter"
              class="available-today-filter"
            >
            </naoo-available-today-filter>
          }
        </div>
      </div>
    }
  </div>
</div>
