@if (hasSpecialOrdersError) {
  <div class="special-orders-error">
    <naoo-error-state
      alt="{{ 'ORDERS.SPECIAL.ERROR.ALT' | translate }}"
      errorImageSource="assets/images/full-error.svg"
      errorTitle="{{ 'ERROR.TITLE' | translate }}"
      errorSubtitle="{{ 'ORDERS.SPECIAL.ERROR.SUBTITLE' | translate }}"
      errorButtonTitle="{{ 'RETURN_TO_PREVIOUS_PAGE' | translate }}"
      [showErrorButton]="false"
    >
    </naoo-error-state>
  </div>
}

@if (!hasSpecialOrdersError && specialOrderInfo?.rowViewModels.length === 0) {
  <div class="empty-special-order">
    <mat-icon class="empty-special-order-icon" svgIcon="empty-icon"></mat-icon>
    <div class="empty-special-order-title">
      {{ 'ORDERS.SPECIAL.NO_SPECIAL_ORDERS.TITLE' | translate }}
    </div>
  </div>
}

@if (!hasSpecialOrdersError && specialOrderInfo?.rowViewModels.length > 0) {
  <div class="print-header">
    <div class="logo">
      <img
        src="{{
          'LOGO.URL' | naoobrand: specialOrderInfo.customerBrand | translate
        }}"
        alt="{{
          'LOGO.TEXT' | naoobrand: specialOrderInfo.customerBrand | translate
        }}"
      />
    </div>
    <div class="title">
      <div class="page">
        {{ 'ORDERS.SPECIAL.TAB_TITLE' | translate }}
      </div>
    </div>
  </div>

  <div class="special-order-header-search">
    <div class="empty-column"></div>
    <naoo-search-field
      class="search-field"
      #searchField
      (searchTextEmitter)="filterOrders($event)"
      (blurEmitter)="updateSearchParam($event)"
      [value]="searchTerm"
      [searchPlaceholderText]="'ORDERS.SPECIAL.SEARCH'"
      [searchHintText]="'ORDERS.SPECIAL.SEARCH_HINT'"
    ></naoo-search-field>
  </div>
  <div class="special-order-header">
    <div class="header-label">
      {{ 'ORDERS.SPECIAL.HEADER_LABEL' | translate }}
    </div>
    <div class="empty-column"></div>
    <div>
      <naoo-action-icon
        actionIcon="print_v2"
        [iconCurrentState]="iconState"
        (clickAction)="onPrintIconClick()"
        [attr.aria-hidden]="false"
        [attr.aria-label]="'ORDERS.SPECIAL.PRINT_ARIA_LABEL' | translate"
      ></naoo-action-icon>
    </div>
  </div>
  <div class="special-order-row special-order-table-header">
    <div class="special-order-cell special-order-item">
      {{ 'ORDERS.SPECIAL.ITEM' | translate }}
    </div>
    <div class="special-order-cell special-order-qty">
      {{ 'ATTRIBUTES.QTY' | translate }}
    </div>
    <div class="special-order-cell special-order-po-number">
      {{ 'ORDERS.SPECIAL.PO_NUMBER' | translate }}
    </div>
    <div class="special-order-cell special-order-ord-number">
      {{ 'ORDERS.SPECIAL.ORDER_NUMBER' | translate }}
    </div>
    <div class="special-order-cell special-order-status">
      {{ 'ORDERS.SPECIAL.STATUS' | translate }}
    </div>
    <div class="special-order-cell special-order-arrives-at-warehouse">
      {{ 'ORDERS.SPECIAL.ARRIVES_AT_WAREHOUSE' | translate }}
    </div>
  </div>
  @if (!(shouldRenderPrint$ | async)) {
    <virtual-scroller
      #scroll
      [scrollAnimationTime]="0"
      [compareItems]="compareViewModels"
      [items]="filteredSpecialOrderRowViewModels"
      [parentScroll]="parentScrollElement"
      [enableUnequalChildrenSizes]="true"
    >
      <ng-template
        [ngTemplateOutlet]="specialOrdersView"
        [ngTemplateOutletContext]="{
          rowItems: scroll.viewPortItems,
          isPrintView: false,
        }"
      ></ng-template>
    </virtual-scroller>
  }
  @if (shouldRenderPrint$ | async) {
    <ng-template
      [ngTemplateOutlet]="specialOrdersView"
      [ngTemplateOutletContext]="{
        rowItems: filteredSpecialOrderRowViewModels,
        isPrintView: true,
      }"
    ></ng-template>
  }
}
<ng-template
  #specialOrdersView
  let-rowItems="rowItems"
  let-isPrintView="isPrintView"
>
  @for (
    rowItem of rowItems;
    track trackByRowViewModel(rowItem);
    let isFirst = $first
  ) {
    <div
      class="special-order-row special-order-main"
      [ngClass]="{
        'special-order-first-item': isFirst,
        'print-list': isPrintView,
      }"
    >
      <div class="special-order-cell special-order-index">
        <span class="special-order-item-label">{{
          'ORDERS.SPECIAL.ITEM' | translate
        }}</span>
        <naoo-special-order-material-row-container
          [materialNumber]="rowItem.materialNumber"
        ></naoo-special-order-material-row-container>
      </div>
      <div class="special-order-cell special-order-qty">
        <span class="special-order-qty-label">{{
          'ATTRIBUTES.QTY' | translate
        }}</span>
        <span class="special-order-qty-value">{{
          rowItem.requestedQuantity
        }}</span>
      </div>
      <div class="special-order-cell special-order-po-number">
        <span class="special-order-po-number-label">{{
          'ORDERS.SPECIAL.PO_NUMBER' | translate
        }}</span>
        <span class="special-order-po-number-value">{{
          rowItem.poNumber
        }}</span>
      </div>
      <div class="special-order-cell special-order-ord-number">
        <span class="special-order-ord-number-label">{{
          'ORDERS.SPECIAL.ORDER_NUMBER' | translate
        }}</span>
        <span class="special-order-ord-number-value">
          <div class="order-cell order-details">
            <a
              href="javascript:void(0)"
              (click)="goToOrderDetails(rowItem.routeParams)"
              >{{ rowItem.salesOrderNumber }}</a
            >
          </div>
        </span>
      </div>

      <div
        class="special-order-cell special-order-status"
        [ngClass]="{ 'vertical-layout': !rowItem.hasError }"
      >
        <span
          class="special-order-status-label"
          [ngClass]="{
            'top-aligned':
              rowItem.hasError && rowItem.statusSecondaryDescription,
          }"
          >{{ 'ORDERS.SPECIAL.STATUS' | translate }}
        </span>
        <div class="status-bar-info">
          @if (!rowItem.hasError) {
            <div class="status-bar-container">
              @for (bar of rowItem.statusBars; track bar) {
                <div
                  class="status-bar"
                  [ngClass]="{ highlighted: bar.highlighted }"
                  [attr.tooltip]="bar.status | translate"
                ></div>
              }
            </div>
          }
          <div
            class="special-order-status-value"
            [ngClass]="{ 'error-status': rowItem.hasError }"
          >
            @if (rowItem?.severityIconInfo) {
              <mat-icon
                class="order-icon"
                svgIcon="{{ rowItem.severityIconInfo.severityIcon }}"
                [ngClass]="rowItem.severityIconInfo.severityIconColor"
              ></mat-icon>
            }
            @if (!rowItem.hasError) {
              <div class="status-description">
                {{ rowItem.statusDescription | stringdefaulter }}
              </div>
            } @else {
              <div class="status-description">
                <div
                  class="status-primary-description"
                  [ngClass]="rowItem.severityIconInfo?.severityIconColor"
                >
                  {{ rowItem.statusDescription | stringdefaulter }}
                </div>
                @if (rowItem.statusSecondaryDescription) {
                  <div
                    class="status-secondary-description"
                    [ngClass]="rowItem.severityIconInfo?.severityIconColor"
                  >
                    {{ rowItem.statusSecondaryDescription | stringdefaulter }}
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
      <div class="special-order-cell special-order-arrives-at-warehouse">
        <span class="special-order-delivery-label">{{
          'ORDERS.SPECIAL.ARRIVES_AT_WAREHOUSE' | translate
        }}</span>
        <span class="special-order-delivery-value">{{
          rowItem.deliveryDate
            | naooDate: 'shortDate' : specialOrderInfo.timeZone
        }}</span>
      </div>
    </div>
  }
</ng-template>
