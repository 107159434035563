import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NgClass } from '@angular/common';

export interface ProductFlag {
  title: string;
  style: ProductFlagStyle;
  tooltipText?: string;
  analyticsEvent?: AnalyticsEventInfo;
}

export enum ProductFlagStyle {
  Red,
  Green,
  Yellow,
  Blue,
  LtGreen,
  OrderveOrange,
}

@Component({
  selector: 'naoo-product-flag',
  templateUrl: './product-flag.component.html',
  styleUrls: ['./product-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class ProductFlagComponent implements OnChanges {
  @Input() productFlag: ProductFlag;
  flagStyle: string;

  constructor(private analytics: NaooAnalyticsManager) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes['productFlag'] &&
      changes['productFlag'].currentValue
    ) {
      this.updateProductFlag(changes['productFlag'].currentValue);
    }
  }

  hovered() {
    if (this.productFlag.analyticsEvent) {
      this.analytics.trackAnalyticsEvent(this.productFlag.analyticsEvent);
    }
  }

  private updateProductFlag(newFlag: ProductFlag) {
    this.flagStyle = this.tooltipClass(newFlag);
    this.productFlag = newFlag;
  }

  private tooltipClass(flag: ProductFlag): string {
    if (!flag) {
      return '';
    }

    switch (flag.style) {
      case ProductFlagStyle.Red:
        return 'red';
      case ProductFlagStyle.Green:
        return 'green';
      case ProductFlagStyle.Yellow:
        return 'yellow';
      case ProductFlagStyle.Blue:
        return 'blue';
      case ProductFlagStyle.LtGreen:
        return 'light-green';
      case ProductFlagStyle.OrderveOrange:
        return 'orderve-orange';
    }
  }
}
