import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StorePurchaseDetailsViewModel } from '../../../../../../core/store/store-purchase-details/models/store-purchase-details-view-model';
import { KeyValue, NgClass, NgTemplateOutlet } from '@angular/common';
import { StoreDisplayContext } from '../../../../../../shared/store-display/store-display';
import { timeZoneCode } from '../../../../../../shared/utilities/date-utilities';
import { StoreDisplayComponent } from '../../../../../../shared/store-display/store-display.component';
import { MatIcon } from '@angular/material/icon';
import { NaooDatePipe } from '../../../../../../shared/pipes/naoo-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NaooCurrencyPipe } from '../../../../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-store-purchase-details-header',
  templateUrl: './store-purchase-details-header.component.html',
  styleUrls: ['./store-purchase-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    StoreDisplayComponent,
    MatIcon,
    NgClass,
    NaooDatePipe,
    TranslateModule,
    NaooCurrencyPipe,
  ],
})
export class StorePurchaseDetailsHeaderComponent {
  @Input() viewModel: StorePurchaseDetailsViewModel;
  @Input() isMobileWidth: boolean;

  readonly storeDisplayContext = StoreDisplayContext.PurchaseHistory;

  get timezoneAbbrTranslationKey(): string {
    return `TIMEZONES.${timeZoneCode(
      this.viewModel.purchaseDetails.saleTimestamp,
      this.viewModel.customerTimezone,
    )}`;
  }

  trackTotalByKeyValue(
    _index: number,
    totalByUom: KeyValue<string, number>,
  ): string {
    return totalByUom.key;
  }
}
