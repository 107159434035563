import { inject } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CanDeactivateFn } from '@angular/router';
import { CartFacade } from '../../core/store/cart/cart.facade';

export const cartRedirectGuard: CanDeactivateFn<
  any
> = (): Observable<boolean> => {
  const cartFacade = inject(CartFacade);

  return of(true).pipe(
    tap(() => cartFacade.clearAddedFromCriticalItemsSectionFlag()),
  );
};
