import {
  Pipe,
  PipeTransform,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { LocalizationService } from '../services/translation/localization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Locale } from 'src/app/core/services/session/models/session-record';

@Pipe({
  name: 'Naoo24to12Hr',
  pure: false,
  standalone: true,
})
export class Naoo24to12HrPipe implements PipeTransform, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(time: string): string {
    const is24HourFormat =
      this.localizationService.currentLocale === Locale.fr_CA;
    const timeParts = time ? time.split(':') : '';
    if (timeParts.length !== 2) {
      return '';
    }
    let hour = parseInt(timeParts[0], 10);
    const min = parseInt(timeParts[1], 10);

    if (this.isTimeValid(hour, min)) {
      if (is24HourFormat) {
        return time;
      }
      let part: string;
      if (hour === 0) {
        hour = 12;
        part = 'AM';
      } else if (hour < 12) {
        part = 'AM';
      } else if (hour === 12) {
        part = 'PM';
      } else if (hour > 12) {
        hour = hour - 12;
        part = hour === 12 ? 'AM' : 'PM';
      }
      const formattedMin = min < 10 ? `0${min}` : min;

      return `${hour}:${formattedMin}${part}`;
    }

    return '';
  }

  private isTimeValid(hour: number, minute: number): boolean {
    const isHourWithinBounds = hour >= 0 && hour < 24;
    const isMinuteWithinBounds = minute >= 0 && minute < 60;
    const isTimeWithinBounds = isHourWithinBounds && isMinuteWithinBounds;
    const isMidnight =
      (hour === 24 && minute === 0) || (hour === 0 && isMinuteWithinBounds);

    return isTimeWithinBounds || isMidnight;
  }
}
