import { takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MenuAction, MenuActionInfo } from './menu-action';
import { Subject } from 'rxjs';
import { AddToGuideMenuComponent } from '../../shared/add-to-guide-menu/add-to-guide-menu.component';
import { naooAnimations } from '../../shared/animations/animations';
import { ProductInfo } from '../../shared/models/product-info';
import {
  ProductAction,
  ProductActionInfo,
} from '../product-guide-edit/product-action';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { CriticalItemsFacade } from '../../core/store/critical-items/critical-items.facade';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import {
  CustomGuideCreationSourceAnalyticLabel,
  ListsAnalyticsConstants,
} from '../../lists/lists-analytics.constants';
import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss'],
  animations: [naooAnimations.rotate],
  standalone: true,
  imports: [
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    AddToGuideMenuComponent,
    TranslateModule,
  ],
})
export class ProductMenuComponent implements OnDestroy, OnInit {
  constructor(
    private customGuideFacade: CustomGuideFacade,
    private customDialogService: CustomDialogService,
    private dialogService: DefaultDialogService,
    private criticalItemsFacade: CriticalItemsFacade,
    private offlineModeFacade: OfflineModeFacade,
    private analytics: NaooAnalyticsManager,
    private orderGuideFacade: OrderGuideFacade,
  ) {}

  static readonly INVERT_MENU_DIRECTION_THRESHOLD = 4;
  @ViewChild('addToGuideMenu', { static: true })
  addToGuideMenu: AddToGuideMenuComponent;
  @ViewChild('matMenuButton', { static: true }) menuButton: ElementRef;
  @Input() product: ProductInfo;
  @Input() canDelete: boolean;
  @Input() canRemove: boolean;
  @Input() isCriticalItemsGuide: boolean;
  @Input() isCommodityItem: boolean;
  @Input() isOrderGuideItem: boolean;
  @Input() listLength: number;
  @Input() currentIndex: number;

  @Output() removeFromCart = new EventEmitter<ProductInfo>();
  @Output() actOnProductInMenu = new EventEmitter<ProductActionInfo>();
  @Output() productInteractionEmitter = new EventEmitter();

  menuItems: MenuActionInfo[] = [];
  openDirection: string;

  private destroyed$ = new Subject<void>();

  ngOnInit() {
    this.offlineModeFacade
      .getIsOffline()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.createMenuItems.bind(this));
    this.setMenuPosition();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setOptionsMenuFocused() {
    setTimeout(() => {
      if (this.menuButton && this.menuButton.nativeElement) {
        this.menuButton.nativeElement.focus();
      }
    });
  }

  performMenuAction(subMenuItem: MenuActionInfo) {
    this.productInteractionEmitter.emit();
    switch (subMenuItem.action) {
      case MenuAction.AddToExistingCustomGuide:
        this.addItemToGuide(subMenuItem.id);
        break;
      case MenuAction.AddToNewCustomGuide:
        this.openCreateCustomGuideModal();
        break;
      case MenuAction.RemoveItemFromCart:
        this.removeFromCart.emit(this.product);
        break;
      case MenuAction.RemoveItemFromCustomGuide:
        this.openRemoveModal();
        break;
      case MenuAction.RemoveItemFromCriticalItemGuide:
        this.openRemoveModal();
        break;
      case MenuAction.AddToCriticalItemsGuide:
        this.criticalItemsFacade.addCriticalItem(this.product.offeringId);
        break;
      case MenuAction.AddToOrderGuide:
        this.orderGuideFacade.addItemToOrderGuide(this.product.offeringId);
        break;
      case MenuAction.RemoveFromOrderGuide:
        this.orderGuideFacade.removeItemFromOrderGuide(this.product.offeringId);
        break;
    }
  }

  openCreateCustomGuideModal() {
    this.customDialogService.openCreateCustomGuideModal(
      CustomGuideCreationSourceAnalyticLabel.MaterialMenu,
      [this.product.offeringId],
    );
  }

  openRemoveModal() {
    this.dialogService.twoButtonModal(
      'remove-product-from-guide-legacy',
      'LISTS.REMOVE_PRODUCT_MODAL.TITLE',
      'LISTS.REMOVE_PRODUCT_MODAL.REMOVE',
      'CANCEL.TEXT',
      () => {
        this.removeItem();
      },
      () => {},
      true,
      true,
      null,
    );
  }

  private removeItem() {
    this.actOnProductInMenu.emit({
      materialNumber: this.product.offeringId,
      categoryName: null,
      action: ProductAction.remove,
    });
  }

  addItemToGuide(customGuideId: string) {
    this.customGuideFacade.addCustomGuideMaterials(customGuideId, [
      this.product.offeringId,
    ]);
    const eventInfo: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: 'add to guide',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  setMenuPosition(): void {
    this.openDirection =
      this.listLength > ProductMenuComponent.INVERT_MENU_DIRECTION_THRESHOLD
        ? this.listLength - this.currentIndex >
          ProductMenuComponent.INVERT_MENU_DIRECTION_THRESHOLD
          ? 'below'
          : 'above'
        : 'below';
  }

  private createMenuItems(isOffline: boolean) {
    if (this.canDelete) {
      this.menuItems = [
        {
          name: 'CART.REMOVE_ITEM_FROM_CART',
          action: MenuAction.RemoveItemFromCart,
        },
      ];
    }
    if (this.isCriticalItemsGuide) {
      this.menuItems = [];
    } else if (this.canRemove) {
      this.menuItems = [
        {
          name: 'LISTS.REMOVE_PRODUCT_FROM_GUIDE',
          action: MenuAction.RemoveItemFromCustomGuide,
          disabled: isOffline,
        },
      ];
    }
  }
}
