<div
  class="parent-container"
  [ngClass]="{
    'red-border': shouldShowRedBorder,
    'green-border': shouldShowGreenBorder,
    'gray-border': shouldShowGrayBorder,
    'no-side-borders': isInsideContainer,
    'last-row': shouldHideBottomBorder,
    'hide-shadow': shouldHideShadow,
    mobile: isMobile,
    'extended-height': hasMaterialFooter,
  }"
>
  @if (shouldShowGreenBorder) {
    <div class="material-row-savings">
      {{ 'CART_COUPON.SAVED' | translate }}
      {{ getSavingsTotal | naooprice }}
    </div>
  }

  @if (isOK) {
    <div
      class="content material-content consistent-height"
      [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
      @fadeInOnly
    >
      @if (hasMaterialFooter && !isMobile) {
        <div class="image-wrapper">
          <naoo-material-image
            [materialRowOptions]="materialRow.materialRowOptions"
            [isUnorderable]="false"
            [isMobile]="isMobile"
            [ngClass]="{ 'right-border': hasMaterialFooter }"
          ></naoo-material-image>
          <naoo-material-store-stored-button
            [fulfillmentType]="fulfillmentType"
            [materialAvailability]="materialAvailability"
            [materialRowOptions]="materialRow.materialRowOptions"
          >
          </naoo-material-store-stored-button>
        </div>
      }
      <div
        class="details-container"
        [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
      >
        <div class="row-details" [ngClass]="{ mobile: isMobile }">
          <div
            class="info-content"
            [ngClass]="{
              mobile: isMobile,
              'par-enabled': isParEnabled,
              'loyalty-enabled':
                materialRow.materialRowOptions.isLoyaltyProgramEligible,
            }"
          >
            <div
              class="info-content-wrapper"
              [ngClass]="{ 'display-max-allocation': displayMaxAllocation }"
            >
              @if (!hasMaterialFooter || isMobile) {
                <div class="image-wrapper">
                  <naoo-material-image
                    [materialRowOptions]="materialRow.materialRowOptions"
                    [isUnorderable]="false"
                    [isMobile]="isMobile"
                  ></naoo-material-image>
                  <naoo-material-store-stored-button
                    [fulfillmentType]="fulfillmentType"
                    [materialAvailability]="materialAvailability"
                    [materialRowOptions]="materialRow.materialRowOptions"
                  >
                  </naoo-material-store-stored-button>
                </div>
              }
              <div
                class="details-content"
                [ngClass]="{
                  mobile: isMobile,
                  'full-width': isInsideContainer,
                  'extended-image': hasMaterialFooter,
                }"
              >
                <naoo-material-info
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [materialInfo]="materialRow.materialRowInfo"
                  [isMobile]="isMobile"
                ></naoo-material-info>
                @if (!isInsideContainer && isMobile) {
                  <div class="mobile-triple-dot">
                    <naoo-material-options-menu
                      [materialRowOptions]="materialRow.materialRowOptions"
                      [isMobile]="isMobile"
                    >
                    </naoo-material-options-menu>
                  </div>
                }
                @if (!isMobile) {
                  <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
                }
              </div>
            </div>
            @if (displayMaxAllocation) {
              <span class="commodity-available">
                <button (click)="viewCommodityDetailsClicked.emit()">
                  {{
                    materialRow.materialRowOptions.materialCommodityInfo
                      .maxAllocationDisplayKey
                      | translate
                        : {
                            count:
                              materialRow.materialRowOptions
                                .materialCommodityInfo.maxAllocationDisplay,
                          }
                  }}
                </button>
              </span>
            }
          </div>
          @if (isMobile) {
            <div
              class="mobile-content mobile"
              [ngClass]="{ empty: hasNoFlags }"
            >
              <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
            </div>
          }
          <div
            class="ordering-content"
            [ngClass]="{
              mobile: isMobile,
              'par-enabled': isParEnabled,
              'loyalty-enabled':
                materialRow.materialRowOptions.isLoyaltyProgramEligible,
            }"
          >
            @if (!!materialRow.materialOrdering) {
              <naoo-material-ordering
                [materialOrdering]="materialRow.materialOrdering"
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
                [ngClass]="{ mobile: isMobile }"
                [displayGoPointsLogo]="displayGoPointsLogo"
                (quantityInputChanged)="quantityInputChanged.emit($event)"
                (viewCommodityDetailsClicked)="
                  viewCommodityDetailsClicked.emit()
                "
              ></naoo-material-ordering>
            }
            @if (!isInsideContainer && !isMobile) {
              <div class="triple-dot">
                <naoo-material-options-menu
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [isMobile]="isMobile"
                >
                </naoo-material-options-menu>
              </div>
            }
            @if (
              MaterialRowContext.PdpLinkItem ===
                this.materialRow?.materialRowOptions?.context && !isMobile
            ) {
              <div class="triple-dot"></div>
            }
          </div>
        </div>
        @if (hasMaterialFooter) {
          <div class="footer-content" [ngClass]="{ mobile: isMobile }">
            @if (!!materialRow.materialRowFooter) {
              <naoo-material-row-footer
                [materialRowFooter]="materialRow.materialRowFooter"
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
                (viewCommodityDetailsClicked)="
                  viewCommodityDetailsClicked.emit()
                "
              ></naoo-material-row-footer>
            }
          </div>
        }
      </div>
    </div>
  }

  @if (isUnorderable) {
    <div
      class="content unorderable-content consistent-height"
      [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
      @fadeInOnly
    >
      @if (hasMaterialFooter && !isMobile) {
        <naoo-material-image
          [materialRowOptions]="materialRow.materialRowOptions"
          [isUnorderable]="true"
          [isMobile]="isMobile"
          [ngClass]="{ 'right-border': hasMaterialFooter && !isMobile }"
        ></naoo-material-image>
      }
      <div
        class="details-container"
        [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
      >
        <div class="row-details" [ngClass]="{ mobile: isMobile }">
          <div
            class="info-content"
            [ngClass]="{
              mobile: isMobile,
              'par-enabled': isParEnabled,
              'loyalty-enabled':
                materialRow.materialRowOptions.isLoyaltyProgramEligible,
            }"
          >
            @if (!hasMaterialFooter || isMobile) {
              <naoo-material-image
                [materialRowOptions]="materialRow.materialRowOptions"
                [isUnorderable]="true"
                [isMobile]="isMobile"
                [ngClass]="{ 'right-border': hasMaterialFooter && !isMobile }"
              ></naoo-material-image>
            }
            <div
              class="details-content"
              [ngClass]="{
                mobile: isMobile,
                'full-width': isInsideContainer,
                'extended-image': hasMaterialFooter,
              }"
            >
              <naoo-material-info
                [materialRowOptions]="materialRow.materialRowOptions"
                [materialInfo]="materialRow.materialRowInfo"
                [isMobile]="isMobile"
              ></naoo-material-info>
              @if (!isInsideContainer && isMobile) {
                <div class="mobile-triple-dot">
                  <naoo-material-options-menu
                    [materialRowOptions]="materialRow.materialRowOptions"
                    [isMobile]="isMobile"
                  >
                  </naoo-material-options-menu>
                </div>
              }
              @if (!isMobile) {
                <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
              }
            </div>
          </div>
          @if (isMobile) {
            <div
              class="mobile-content mobile"
              [ngClass]="{ empty: hasNoFlags }"
            >
              <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
            </div>
          }
          <div
            class="ordering-content-unorderable-item"
            [ngClass]="{
              mobile: isMobile,
              'par-enabled': isParEnabled,
              'loyalty-enabled':
                materialRow.materialRowOptions.isLoyaltyProgramEligible,
            }"
          >
            <ul
              class="unorderable-list-content"
              [ngClass]="{ mobile: isMobile }"
            >
              <li class="unorderable-content" [ngClass]="{ mobile: isMobile }">
                <naoo-material-unorderable
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [isMobile]="isMobile"
                  [materialAvailability]="materialAvailability"
                >
                </naoo-material-unorderable>
              </li>
              <li class="similar-items">
                @if (!isSubstitutesContext) {
                  <naoo-material-similar-items
                    [ngClass]="{ mobile: isMobile }"
                    [isMobile]="isMobile"
                    [materialWithSubstitutes]="materialRow"
                  >
                  </naoo-material-similar-items>
                }
              </li>
            </ul>
          </div>
          @if (!isInsideContainer && !isMobile && !isErrorProcessing) {
            <div class="triple-dot">
              <naoo-material-options-menu
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
              >
              </naoo-material-options-menu>
            </div>
          }
        </div>
        @if (hasMaterialFooter) {
          <div class="footer-content" [ngClass]="{ mobile: isMobile }">
            @if (!!materialRow.materialRowFooter) {
              <naoo-material-row-footer
                [materialRowFooter]="materialRow.materialRowFooter"
                [materialRowOptions]="materialRow.materialRowOptions"
                (viewCommodityDetailsClicked)="
                  viewCommodityDetailsClicked.emit()
                "
              ></naoo-material-row-footer>
            }
          </div>
        }
      </div>
    </div>
  }

  @if (isUnavailable) {
    <div
      class="content consistent-height unavailable-content"
      [ngClass]="{ mobile: isMobile }"
      @fadeInOnly
    >
      <naoo-material-unavailable
        [materialRowOptions]="materialRow.materialRowOptions"
        [isMobile]="isMobile"
        [isDeleteHidden]="shouldHideTrashCan"
      >
      </naoo-material-unavailable>
    </div>
  }

  @if (isDeleted) {
    <div
      class="content deleted-content consistent-height"
      [ngClass]="{ mobile: isMobile }"
      @fadeInOnly
    >
      <naoo-material-deleted
        [materialInfo]="materialRow.materialRowInfo"
        [status]="materialRow.status"
        [materialRowOptions]="materialRow.materialRowOptions"
        [isMobile]="isMobile"
      >
      </naoo-material-deleted>
    </div>
  }

  @if (isLoading) {
    <div
      class="content loading-content consistent-height"
      [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
      @fadeInAndOut
    >
      <naoo-material-skeleton
        [isMobile]="isMobile"
        [materialListStyle]="materialRow.materialRowOptions.materialListStyle"
      >
      </naoo-material-skeleton>
    </div>
  }
</div>

<ng-template #materialFlag>
  <div class="flags" [ngClass]="{ mobile: isMobile }">
    <naoo-material-flag-container
      [flags]="materialRow.flags"
      [maximumNumberOfFlags]="6"
    ></naoo-material-flag-container>
  </div>
</ng-template>
