import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ImageInfo } from '../../../../../core/store/search/search.state';

@Component({
  selector: 'naoo-search-recommendations-content',
  templateUrl: './search-recommendations-content.component.html',
  styleUrls: ['./search-recommendations-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchRecommendationsContentComponent {
  @Input() currentImage: ImageInfo;
  @Input() altText: string;

  @Output() navigate = new EventEmitter<void>();
}
