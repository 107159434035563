import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { SalesCriticalItem } from '../../core/services/sales-critical-items/model/sales-critical-items';
import { SalesCriticalItemsFacade } from '../../core/store/sales-critical-items/sales-critical-items.facade';
import { createMaterialListRow } from '../../core/store/material-row/models/material-row';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaterialListRow } from '../../material-list/models/material-list';
import { MaterialWarningContentComponent } from './material-warning-content/material-warning-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-material-warning-container',
  template: `
    <naoo-material-warning-content
      [numberOfSalesCriticalItemsWarnings]="
        (salesCriticalItems$ | async).length
      "
      [salesCriticalItemsRows]="salesCriticalItemsRows"
      (closeModalEmitter)="closeModal()"
    >
    </naoo-material-warning-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MaterialWarningContentComponent, AsyncPipe],
})
export class MaterialWarningContainerComponent implements OnInit, OnDestroy {
  private readonly openAnalyticsLabel = 'open material warning modal';
  private readonly closeAnalyticsLabel = 'close material warning modal';

  salesCriticalItemsRows: MaterialListRow[];
  materialNumbers: string[];
  salesCriticalItems$: Observable<SalesCriticalItem[]>;
  private destroyed$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<MaterialWarningContainerComponent>,
    private salesCriticalItemsFacade: SalesCriticalItemsFacade,
    private analytics: NaooAnalyticsManager,
  ) {}

  ngOnInit(): void {
    this.salesCriticalItems$ =
      this.salesCriticalItemsFacade.getSalesCriticalItemsWithExceededHardStopQuantity();

    this.salesCriticalItems$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((salesCriticalItems) => {
        this.materialNumbers = salesCriticalItems.map(
          (salesCriticalItem) => salesCriticalItem.materialNumber,
        );
        this.trackAnalytics(this.openAnalyticsLabel);
        if (!this.salesCriticalItemsRows) {
          this.salesCriticalItemsRows =
            this.transformSalesCriticalItems(salesCriticalItems);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeModal(): void {
    this.trackAnalytics(this.closeAnalyticsLabel);
    this.dialogRef.close();
  }

  private trackAnalytics(labelPrefix: string): void {
    this.analytics.trackAnalyticsEvent({
      action: 'click',
      category: 'Ecommerce',
      label: `${labelPrefix} - ${this.materialNumbers}`,
    });
  }

  private transformSalesCriticalItems(
    salesCriticalItems: SalesCriticalItem[],
  ): MaterialListRow[] {
    return salesCriticalItems.map((salesCriticalItem) =>
      createMaterialListRow(salesCriticalItem.materialNumber),
    );
  }
}
