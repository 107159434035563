<div class="naoo-guide-sort-by-select">
  <mat-form-field class="guide-sort-by-form">
    <mat-select
      id="guide-sort-by-select"
      [value]="selectedSortBy"
      (selectionChange)="changeSort($event)"
      [aria-label]="'LISTS.SORT_ITEMS_BY.LABEL' | translate"
    >
      @for (option of sortByOptions; track option) {
        <mat-option class="sort-by-option" [value]="option.toString()">
          {{ displayKeyForSortBy(option) | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
