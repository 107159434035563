@if (isTabView) {
  <naoo-material-ordering-tab
    [materialOrdering]="materialOrdering"
    [materialRowOptions]="materialRowOptions"
    [isMobile]="isMobile"
    [displayGoPointsLogo]="displayGoPointsLogo"
    (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
  >
  </naoo-material-ordering-tab>
} @else {
  @if (isGrid) {
    <div class="warning-grid-content-wrapper">
      @if (displayMaxAllocation) {
        <span class="commodity-available">
          <button (click)="viewCommodityDetailsClicked.emit()">
            {{
              materialRowOptions.materialCommodityInfo.maxAllocationDisplayKey
                | translate
                  : {
                      count:
                        materialRowOptions.materialCommodityInfo
                          .maxAllocationDisplay,
                    }
            }}
          </button>
        </span>
      }
      <div class="size-placeholder"></div>
      <div class="price-placeholder"></div>
      <div class="warning-content grid">
        @if (materialOrdering.materialWarning) {
          <naoo-material-warning
            [warning]="materialOrdering.materialWarning"
            [materialRowOptions]="materialRowOptions"
            [openDirection]="warningOpenDirection"
            [isInventoryLoaded]="materialOrdering.isInventoryLoaded"
            [selectedOrderingInfoIndex]="selectedOrderingInfoIndex"
            [isMobile]="false"
            class="material-warning"
          ></naoo-material-warning>
        }
      </div>
      <div class="total-price-placeholder"></div>
    </div>
  } @else {
    <div class="warning-content">
      @if (materialOrdering.materialWarning) {
        <naoo-material-warning
          [warning]="materialOrdering.materialWarning"
          [materialRowOptions]="materialRowOptions"
          [openDirection]="warningOpenDirection"
          [isInventoryLoaded]="materialOrdering.isInventoryLoaded"
          [selectedOrderingInfoIndex]="selectedOrderingInfoIndex"
          [isMobile]="isMobile"
        ></naoo-material-warning>
      }
    </div>
  }
  <div class="ordering-content" [ngClass]="{ grid: isGrid }">
    @if (!shouldUseDropdownForUoms) {
      @for (
        uom of uoms;
        track uom;
        let index = $index;
        let isFirst = $first;
        let isLast = $last
      ) {
        <naoo-material-ordering-info
          [currentOrderingInfo]="materialOrdering.materialOrderingInfos[index]"
          [orderingInfos]="materialOrdering.materialOrderingInfos"
          [materialRowOptions]="materialRowOptions"
          [materialWarning]="materialOrdering.materialWarning"
          [isMobile]="false"
          [ngClass]="{ first: isFirst, last: isLast }"
          (quantityInputChanged)="quantityInputChanged.emit($event)"
          (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
        >
        </naoo-material-ordering-info>
      }
    } @else {
      <naoo-material-ordering-info
        class="inline-uom-info"
        [currentOrderingInfo]="
          materialOrdering.materialOrderingInfos[selectedOrderingInfoIndex]
        "
        [orderingInfos]="materialOrdering.materialOrderingInfos"
        [materialRowOptions]="materialRowOptions"
        [materialWarning]="materialOrdering.materialWarning"
        [materialOrderingTabs]="materialOrdering.tabs"
        [isMobile]="false"
        (tabChange)="changeTab($event)"
      >
      </naoo-material-ordering-info>
    }
  </div>
}
