<div class="super-header" [ngClass]="{ 'no-breadcrumb': isBreadcrumbDisabled }">
  @if (!isBreadcrumbDisabled) {
    <naoo-guides-header-breadcrumb
      [name]="guideHeader.name"
    ></naoo-guides-header-breadcrumb>
  }

  <div class="guide-actions">
    <naoo-action-icon
      actionIcon="print_v2"
      class="print-icon"
      [iconCurrentState]="
        isOffline || (printInput$ | async).materialNumbers.length === 0
          ? disabledIconState
          : (printIconState$ | async)
      "
      (clickAction)="printGuide()"
    ></naoo-action-icon>
    <naoo-action-icon
      class="download-icon"
      actionIcon="download"
      [iconCurrentState]="
        isOffline || (exportInput$ | async).materialNumbers.length === 0
          ? disabledIconState
          : (exportIconState$ | async)
      "
      (clickAction)="exportGuide()"
    ></naoo-action-icon>
  </div>
</div>

<div class="header-container">
  <ng-content></ng-content>
</div>

<div class="action-bar">
  <div class="mobile-action-bar">
    <mat-form-field class="mobile-category-select-form" floatLabel="never">
      <mat-label>
        {{ 'ORDER_GUIDE.SELECT_CATEGORY' | translate }}
      </mat-label>
      <mat-select
        class="mobile-category-jump"
        (selectionChange)="handleScrollToCategory($event)"
      >
        @for (categoryHeader of categoryHeaders; track categoryHeader.name.en) {
          <mat-option [value]="categoryHeader.virtualScrollIndex">
            {{ categoryHeader.name | stringdefaulter }} ({{
              categoryHeader.count
            }})
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button class="options-button" (click)="handleOpenMobileActionsModal()">
      <mat-icon svgIcon="filter_icon"></mat-icon>
      {{ 'ORDER_GUIDE.OPTIONS' | translate }}
    </button>
  </div>
  <naoo-guides-action-bar
    [actionBar]="guideHeader.actionBar"
    [selectedView]="preferredView"
    [isOffline]="isOffline"
    (updateSearchText)="handleUpdateSearchText($event)"
    (updateMaterialView)="handleUpdateMaterialView($event)"
    (clearInventoryQuantities)="handleClearInventoryQuantities()"
    (toggleParOrdering)="handleToggleParOrdering($event)"
  ></naoo-guides-action-bar>
</div>
