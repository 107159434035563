<naoo-hero-images
  hostComponent="scheduled-maintenance"
  height="300px"
></naoo-hero-images>
<div class="maintenance-message">
  {{ 'SCHEDULED_MAINTENANCE' | translate }}
</div>
@if (isMultiCustomerUnit$ | async) {
  <button class="back-button" (click)="backToCustomerUnitSelector()">
    {{ 'CUSTOMER_UNIT.SELECT_A_LOCATION' | translate | uppercase }}
  </button>
}
