import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-add-to-guide-toast',
  templateUrl: './add-to-guide-toast.component.html',
  styleUrls: ['./add-to-guide-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class AddToGuideToastComponent implements OnInit {
  protected message: string;
  protected customGuideId: string;
  protected customGuideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private readonly data: {
      message: string;
      customGuideId?: string;
      customGuideName?: string;
    },
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.message = this.data.message;
    this.customGuideId = this.data.customGuideId;
    this.customGuideName = this.data.customGuideName;
    this.changeDetectorRef.markForCheck();
  }

  navigate(): void {
    if (this.customGuideId) {
      this.router.navigate(['guides', 'custom-guide', this.customGuideId]);
    } else {
      this.router.navigate(['guides', 'order-guide']);
    }
  }
}
