import { MaterialInfo, MaterialUnitInfo } from '../../models/material-info';
import {
  OrderDetailLineStatus,
  OrderDetails,
  OrderDetailsExportModel,
  OrderDetailsLineExportModel,
  OrderDetailsLineViewModel,
  OrderDetailsViewModel,
  OrderItem,
  UomExportLine,
  UomLine,
} from '../../models/order-details';
import { MaterialFlagType } from '../../../material-flag/material-flag';
import { FreightInformation, OrderStatus } from '../../models/order-info';
import { OrderType, RequestedDeliveryType } from '../../models/order-type';
import moment from 'moment-timezone';
import {
  isAvailableByUom,
  MaterialAvailability,
} from '../../models/material-availability';
import {
  ReOrderMaterial,
  ReOrderValidationResult,
} from '../../../re-order/re-order-validation-result';
import {
  CurrentSystem,
  CustomerBrand,
  CustomerPermission,
  SessionActiveCustomer,
} from '../../../core/services/session/models/session-record';
import { StatusSeverity } from '../../models/status-severity';
import { OrderDetailsConstants } from '../../../orders/order-details/order-details-constants';
import { MaterialAdditionalInfo } from '../../models/material-additional-info';
import { EntitlementMaterialDetail } from '../../../core/services/entitlement/models/entitlement';
import { CombinedPricingRecord } from '../../../core/store/material-price/material-price.util';
import { SapDateTimeFormat } from '../../utilities/date-utilities';
import { collectTrackingNumbers } from './order-details-util';
import { DateService } from '../date/date.service';
import { Injectable } from '@angular/core';
import { StoreRecord } from '../../../core/services/store/model/store-record';

export enum FreightCarrier {
  FEDEX = 'FEDEX',
  UPS = 'UPS',
}

interface OrderDetailsLineViewModelMap {
  ids: string[];
  details: {
    [materialNumber: string]: OrderDetailsLineViewModel;
  };
}

interface OrderDetailsLineExportModelMap {
  ids: string[];
  details: {
    [materialNumber: string]: OrderDetailsLineExportModel;
  };
}

@Injectable({ providedIn: 'root' })
export class OrderDetailsTransformationService {
  private readonly DASH: string = '-';

  constructor(private dateService: DateService) {}

  public static getDedupedMaterialNumbers(
    orderDetails: OrderDetails,
  ): string[] {
    const materialNumbers: string[] =
      orderDetails?.orderLines.map((orderItem) => orderItem.materialNumber) ??
      [];

    return materialNumbers.filter((materialNumber, index) => {
      return materialNumbers.indexOf(materialNumber) === index;
    });
  }

  // eslint-disable-next-line max-params
  public transformOrderDetailsViewModel(
    orderDetails: OrderDetails,
    materialInfoMap: Map<string, MaterialInfo>,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialAvailabilityMap: Map<string, MaterialAvailability>,
    entitlementMap: Map<string, EntitlementMaterialDetail>,
    customerTimeZone: string,
    customerBrand: CustomerBrand,
    isEditOrderEnabled: boolean,
    currentSystem: CurrentSystem,
    isOnline: boolean,
    storeRecord: StoreRecord,
    activeCustomer: SessionActiveCustomer,
  ): OrderDetailsViewModel {
    const submittedTimeZone: string | null = orderDetails.submittedDateTime
      ? moment.tz(orderDetails.submittedDateTime, customerTimeZone).zoneAbbr()
      : null;

    const reOrderValidationResult = this.buildReOrderValidationResult(
      orderDetails.orderLines,
      materialAvailabilityMap,
      materialInfoMap,
    );

    const lines: OrderDetailsLineViewModel[] =
      this.transformOrderDetailsLineViewModel(
        orderDetails,
        materialInfoMap,
        combinedPriceMap,
        isEditOrderEnabled,
        entitlementMap,
        isOnline,
      );

    const calculatedDate = this.getCalculatedDate(orderDetails);
    const customerArrivalTimeZone: string | null = calculatedDate
      ? moment.tz(calculatedDate, customerTimeZone).zoneAbbr()
      : null;

    const calculatedTime = this.getCalculatedTime(
      orderDetails,
      customerTimeZone,
    );

    let deliveryDateLabel = 'ORDERS.SUMMARY.DELIVERY_DATE';
    let deliveryTimeLabel = 'ORDERS.SUMMARY.DELIVERY_ETA';
    let shippingMessage = this.getOrderStatusShippingMessageKey(orderDetails);
    if (this.isDropShip(orderDetails.orderType)) {
      deliveryDateLabel = 'ORDERS.DETAILS.EST_DELIVERY_DATE';
      shippingMessage = calculatedDate
        ? ''
        : 'ORDERS.HISTORY.ON_ORDER_SHIPPING_MESSAGE';
    } else if (this.isISPU(orderDetails)) {
      deliveryDateLabel = 'ORDERS.DETAILS.PICKUP_DATE';
      deliveryTimeLabel = 'ORDERS.DETAILS.AVAILABILITY';
    } else if (this.isExpress(orderDetails)) {
      deliveryTimeLabel = 'ORDERS.DETAILS.DELIVERY_WINDOW';
    }
    return {
      userTimeZone: customerTimeZone,
      orderNumber: this.getOrderNumber(orderDetails, currentSystem),
      groupNumber: orderDetails.groupNumber,
      combinedOrderAndGroupNumber: this.getCombinedOrderAndGroupNumber(
        orderDetails,
        currentSystem,
      ),
      customerPurchaseOrder: orderDetails.customerPurchaseOrder,
      orderType: orderDetails.orderType,
      isIspuOrder: this.isISPU(orderDetails),
      isExpressOrder: this.isExpress(orderDetails),
      isEditOrderEnabled:
        isEditOrderEnabled &&
        activeCustomer.permissions.includes(CustomerPermission.OrderSubmission),
      isExportOrderEnabled: currentSystem !== CurrentSystem.Mygfs,
      isReOrderable: Object.keys(reOrderValidationResult.orderable).length > 0,
      isDropShipOrSpecialOrderType: this.isDropShipOrSpecialOrderType(
        orderDetails.orderType,
      ),
      isDropShip: this.isDropShip(orderDetails.orderType),
      storeNameAndLocation: this.getStoreNameAndLocation(
        storeRecord,
        orderDetails,
      ),
      storeRecord,
      deliveryDateLabel,
      deliveryTimeLabel,
      isCancelOrderDisabled: !isOnline || !orderDetails.cancelable,
      reOrderValidationResult,
      customerDetails: {
        customerBrand: customerBrand,
        customerName: orderDetails.customerName,
        customerId: orderDetails.customerId,
      },
      submissionDetails: {
        submittedDateTime: orderDetails.submittedDateTime,
        submittedTimeZone,
        submittedByUsername: orderDetails.submittedByUsername,
      },
      statusDetails: {
        orderStatus: orderDetails.orderStatus,
        statusIcon: this.getOrderStatusIcon(orderDetails),
        iconColor: this.getIconColor(orderDetails),
        iconAltTextKey: this.getIconAltTextKey(
          orderDetails.orderStatusSeverity,
        ),
        shippingMessageKey: shippingMessage,
        orderStatusSeverity: orderDetails.orderStatusSeverity,
        orderStatusDescription: orderDetails.orderStatusDescription,
        orderBlockDescription: orderDetails.orderBlockDescription,
        isCanceled: this.isCanceled(orderDetails.orderStatus),
        customerArrivalTimeZone,
        estimatedDeliveryTime: this.getEstimatedDeliveryTime(orderDetails),
        showDeliveryEta: !this.shouldHideDeliveryEta(orderDetails),
        isDropShipOrder: this.isDropShip(orderDetails.orderType),
        showTrackingIdsLink: this.showTrackingIdsLink(
          orderDetails,
          currentSystem,
        ),
        trackingUrls: this.getTrackingUrls(orderDetails, currentSystem),
        startTime: orderDetails.storeFulfillment?.deliveryWindowStartTimestamp,
        untilTime: orderDetails.storeFulfillment?.deliveryWindowEndTimestamp,
        calculatedDate,
        calculatedTime,
      },
      totalDetails: {
        totalExtendedPrice: orderDetails.totalExtendedPrice,
        subTotal:
          currentSystem === CurrentSystem.Sap
            ? this.getSapSubTotal(orderDetails)
            : orderDetails.totalExtendedPrice,
        totalLines: orderDetails.totalLines,
        totalPrice:
          currentSystem === CurrentSystem.Sap
            ? orderDetails.totalNetPrice
            : this.getTotalPrice(orderDetails),
        totalQuantityOrdered: orderDetails.totalQuantityOrdered,
        totalWeight: orderDetails.totalWeight,
        totalWeightUom: orderDetails.totalWeightUom ?? '',
        totalQuantityShipped: orderDetails.totalQuantityShipped,
        totalShipping: orderDetails.totalShipping || 0,
      },
      lines: lines,
      totalDiscountAmount: orderDetails.totalDiscountAmount,
    };
  }

  private getStoreNameAndLocation(
    storeRecord: StoreRecord,
    orderDetails: OrderDetails,
  ): string | undefined {
    if (
      orderDetails.orderType !== OrderType.StoreFulfillment ||
      !storeRecord ||
      (!storeRecord.name && !storeRecord.address)
    ) {
      return undefined;
    }
    const storeName = storeRecord.name;

    let storeLocation;
    if (storeRecord.address) {
      storeLocation =
        storeRecord.address.address1 +
        '<br/>' +
        storeRecord.address.city +
        ', ' +
        storeRecord.address.state +
        ' ' +
        storeRecord.address.postalCode;
    }

    if (!storeName) {
      return storeLocation;
    } else if (!storeLocation) {
      return storeName;
    }

    return storeName + '<br/>' + storeLocation;
  }

  private getSapSubTotal(orderDetails: OrderDetails) {
    return orderDetails.totalNetPrice
      ? orderDetails.totalNetPrice - orderDetails.totalShipping
      : undefined;
  }

  public transformOrderDetailsExportModel(
    orderDetails: OrderDetails,
    materialInfoMap: Map<string, MaterialInfo>,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialAdditionalInfoMap: Map<string, MaterialAdditionalInfo>,
    currentSystem: CurrentSystem,
  ): OrderDetailsExportModel {
    return {
      orderNumber: this.getOrderNumber(orderDetails, currentSystem),
      groupNumber: orderDetails.groupNumber,
      customerPurchaseOrder: orderDetails.customerPurchaseOrder,
      orderType: orderDetails.orderType,
      customerArrivalDate: orderDetails.estimatedDeliveryDate,
      lines: this.transformOrderDetailsLineExportModel(
        orderDetails,
        materialInfoMap,
        combinedPriceMap,
        materialAdditionalInfoMap,
      ),
    };
  }

  private buildReOrderValidationResult(
    orderItems: OrderItem[],
    materialAvailabilityMap: Map<string, MaterialAvailability>,
    materialInfoMap: Map<string, MaterialInfo>,
  ): ReOrderValidationResult {
    const orderable: { [materialNumber: string]: ReOrderMaterial[] } = {};
    const nonOrderable: { [materialNumber: string]: ReOrderMaterial[] } = {};
    const isOrderable = (uom: string, availability: MaterialAvailability) =>
      availability && isAvailableByUom(availability, uom);

    orderItems.forEach((item: OrderItem) => {
      const { materialNumber } = item;
      const availability = materialAvailabilityMap.get(materialNumber);
      const materialInfo = materialInfoMap.get(materialNumber);
      const target = isOrderable(item.uom, availability)
        ? orderable
        : nonOrderable;
      if (!target[materialNumber]) {
        target[materialNumber] = [];
      }

      if (
        !target[materialNumber].find(
          (existingItem) => existingItem.uom === item.uom,
        )
      ) {
        target[materialNumber].push(
          this.createReOrderMaterial(item, materialInfo),
        );
      }
    });
    return { orderable, nonOrderable };
  }

  private getOrderNumber(
    orderDetails: OrderDetails,
    currentSystem: CurrentSystem,
  ): string {
    let orderNumber = orderDetails?.orderNumber ?? this.DASH;
    if (
      currentSystem === CurrentSystem.Retalix &&
      this.isDropShipOrSpecialOrderType(orderDetails.orderType)
    ) {
      orderNumber = this.DASH;
    }
    return orderNumber;
  }

  private getCombinedOrderAndGroupNumber(
    orderDetails: OrderDetails,
    currentSystem: CurrentSystem,
  ) {
    const orderNumber = this.getOrderNumber(orderDetails, currentSystem);
    if (orderNumber !== this.DASH && orderDetails.groupNumber) {
      return `${orderNumber}${this.DASH}${orderDetails.groupNumber}`;
    }
    return orderNumber;
  }

  private getTotalPrice(orderDetails: OrderDetails) {
    return orderDetails.totalShipping
      ? orderDetails.totalShipping + orderDetails.totalExtendedPrice
      : orderDetails.totalExtendedPrice;
  }

  private createReOrderMaterial(
    orderItem: OrderItem,
    materialInfo?: MaterialInfo,
  ): ReOrderMaterial {
    const { uom, materialNumber, quantityOrdered } = orderItem;
    const description = materialInfo?.description;
    return {
      uom,
      materialNumber,
      quantityOrdered,
      description,
    };
  }

  private transformOrderDetailsLineViewModel(
    orderDetails: OrderDetails,
    materialInfoMap: Map<string, MaterialInfo>,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    isEditOrderEnabled: boolean,
    entitlementMap: Map<string, EntitlementMaterialDetail>,
    isOnline: boolean,
  ): OrderDetailsLineViewModel[] {
    const initialMap: OrderDetailsLineViewModelMap = {
      ids: [],
      details: {},
    };
    const intermediate =
      orderDetails.orderLines &&
      orderDetails.orderLines
        .filter((item) => item.materialNumber)
        .reduce(
          (itemMap: OrderDetailsLineViewModelMap, orderItem: OrderItem) => {
            const materialNumber = orderItem.materialNumber;
            const materialInfo = materialInfoMap.get(materialNumber);
            const substituteMaterial = materialInfoMap.get(
              orderItem.substituteForMaterial,
            );
            const isCatchWeight =
              !!orderItem.productCatchWeightPrice ||
              materialInfo?.isCatchWeight;
            if (!itemMap.details[materialNumber]) {
              // Keep track of ids to preserve order of items to display
              itemMap.ids.push(materialNumber);
              itemMap.details[materialNumber] = {
                materialNumber: materialNumber,
                material: materialInfo,
                substituteMaterial,
                isCatchWeight: isCatchWeight,
                units: [],
                isEditDisabled: true,
                isCancelDisabled: true,
                isCommodityItem: entitlementMap.has(materialNumber),
                discountAmount: orderItem.totalLineDiscountAmount,
              };
            }

            itemMap.details[materialNumber].units.push(
              this.createUomLine(
                materialInfoMap.get(materialNumber),
                orderDetails.orderType,
                orderItem,
                isCatchWeight,
                isCatchWeight
                  ? combinedPriceMap.get(materialNumber)?.weightUom
                  : undefined,
              ),
            );

            const hasEditableUnit: boolean =
              !!isEditOrderEnabled &&
              itemMap.details[materialNumber].units.some(
                (unit) => unit.editable,
              );
            const hasCancelableUnit: boolean =
              !!isEditOrderEnabled &&
              itemMap.details[materialNumber].units.some(
                (unit) => unit.lineCancelable,
              );
            itemMap.details[materialNumber].isEditDisabled =
              !hasEditableUnit || !isOnline;
            itemMap.details[materialNumber].isCancelDisabled =
              !hasCancelableUnit || !isOnline;

            return itemMap;
          },
          initialMap,
        );
    return intermediate.ids.map((id) => intermediate.details[id]);
  }

  private createUomLine(
    materialInfo: MaterialInfo,
    orderType: OrderType,
    orderItem: OrderItem,
    isCatchWeight: boolean,
    catchWeightUom?: string,
  ): UomLine {
    const displayCode = materialInfo?.units.find(
      (unit) => unit.uom === orderItem.uom,
    )?.displayCode;
    return {
      uom: orderItem.uom,
      displayCode: displayCode,
      catchWeightUom,
      productPrice: isCatchWeight
        ? orderItem.productCatchWeightPrice
        : orderItem.productPrice,
      quantityOrdered: orderItem.quantityOrdered,
      quantityShipped: orderItem.quantityShipped,
      total: orderItem.total,
      partialShipment:
        !!orderItem.quantityShipped &&
        orderItem.quantityOrdered !== orderItem.quantityShipped,
      lineStatusSeverity: orderItem.lineStatusSeverity,
      salesDocumentItem: orderItem.salesDocumentItem,
      editableUntilDateTime: orderItem.editableUntilDateTime,
      editable: orderItem.editable,
      statusIcon: this.getLineStatusIcon(
        orderType,
        orderItem.lineStatus,
        orderItem.lineStatusSeverity,
      ),
      iconAltTextKey: this.getIconAltTextKey(orderItem.lineStatusSeverity),
      severityIcon: this.getSeverityIcon(orderItem),
      severityIconColor: this.getSeverityIconColor(
        orderItem.lineStatusSeverity,
      ),
      lineStatusDetails: this.getLineStatusDetails(orderItem, orderType),
      shouldShowLineStatus: this.shouldShowLineStatus(
        this.isDropShipOrSpecialOrderType(orderType),
        orderItem.lineStatusSeverity,
      ),
      lineCancelable: orderItem.lineCancelable,
    };
  }

  private getLineStatusDetails(
    orderItem: OrderItem,
    orderType: OrderType,
  ): OrderDetailLineStatus[] {
    if (orderItem?.lineWarnings?.length > 0) {
      const uniqueWarning = new Map();
      orderItem?.lineWarnings?.forEach((lineWarning) => {
        uniqueWarning.set(JSON.stringify(lineWarning.shortDescription), {
          lineStatusClass: this.getSeverityIconColor(lineWarning.severity),
          lineStatusIcon: this.getLineStatusIcon(
            orderType,
            orderItem.lineStatus,
            lineWarning.severity,
          ),
          lineStatusIconAltTextKey: this.getIconAltTextKey(
            lineWarning.severity,
          ),
          primaryLineStatus: lineWarning.shortDescription,
          secondaryLineStatus: orderItem.lineStatusErrorDescription,
        });
      });
      return Array.from(uniqueWarning.values());
    }

    return [
      {
        lineStatusClass: this.getSeverityIconColor(
          orderItem.lineStatusSeverity,
        ),
        lineStatusIcon: this.getLineStatusIcon(
          orderType,
          orderItem.lineStatus,
          orderItem.lineStatusSeverity,
        ),
        lineStatusIconAltTextKey: this.getIconAltTextKey(
          orderItem.lineStatusSeverity,
        ),
        primaryLineStatus: orderItem.lineStatusDescription,
        secondaryLineStatus: orderItem.lineStatusErrorDescription,
      },
    ];
  }

  private transformOrderDetailsLineExportModel(
    orderDetails: OrderDetails,
    materialInfoMap: Map<string, MaterialInfo>,
    combinedPriceMap: Map<string, CombinedPricingRecord>,
    materialAdditionalInfoMap: Map<string, MaterialAdditionalInfo>,
  ): OrderDetailsLineExportModel[] {
    const initialMap: OrderDetailsLineExportModelMap = {
      ids: [],
      details: {},
    };
    const intermediate =
      orderDetails.orderLines &&
      orderDetails.orderLines
        .filter((item) => item.materialNumber)
        .reduce(
          (itemMap: OrderDetailsLineExportModelMap, orderItem: OrderItem) => {
            const { nonStock, ...details } = orderItem;
            const materialNumber = orderItem.materialNumber;
            const materialInfo = materialInfoMap.get(materialNumber);
            const isCatchWeight =
              !!details.productCatchWeightPrice || materialInfo?.isCatchWeight;

            if (!materialInfo?.innerPackSize && !!materialInfo?.units?.length) {
              const smallestUnit: MaterialUnitInfo =
                materialInfo.units[materialInfo.units.length - 1];
              const qtyInParent = smallestUnit.qtyInParent || 1;
              materialInfo.innerPackSize = qtyInParent + ' ' + smallestUnit.uom;
            }

            if (!itemMap.details[materialNumber]) {
              // Keep track of ids to preserve order of items to display
              itemMap.ids.push(materialNumber);
              itemMap.details[materialNumber] = {
                materialNumber: materialNumber,
                material: materialInfo,
                category:
                  materialAdditionalInfoMap.get(materialNumber)?.categoryLevel1,
                materialFlags:
                  nonStock === 'S'
                    ? [{ type: MaterialFlagType.SpecialOrder }]
                    : [],
                isCatchWeight: isCatchWeight,
                units: [],
              };
            }

            itemMap.details[materialNumber].units.push(
              this.createUomExportLine(
                orderItem,
                isCatchWeight,
                isCatchWeight
                  ? combinedPriceMap.get(materialNumber)?.weightUom
                  : undefined,
              ),
            );

            return itemMap;
          },
          initialMap,
        );
    return intermediate.ids.map((id) => intermediate.details[id]);
  }

  private createUomExportLine(
    orderItem: OrderItem,
    isCatchWeight: boolean,
    catchWeightUom?: string,
  ): UomExportLine {
    return {
      uom: orderItem.uom,
      catchWeightUom,
      productPrice: isCatchWeight
        ? orderItem.productCatchWeightPrice
        : orderItem.productPrice,
      quantityOrdered: orderItem.quantityOrdered,
      quantityShipped: orderItem.quantityShipped,
      total: orderItem.total,
    };
  }

  private getOrderStatusIcon(orderDetails: OrderDetails): string {
    const orderStatus = orderDetails.orderStatus;

    switch (orderDetails.orderStatusSeverity) {
      case StatusSeverity.Success:
        if (orderStatus === OrderStatus.Delivered) {
          return OrderDetailsConstants.DELIVERED_ICON;
        }
        if (orderStatus === OrderStatus.Invoiced) {
          return OrderDetailsConstants.INVOICED_ICON;
        }
        if (this.isDropShipOrSpecialOrderType(orderDetails.orderType)) {
          if (orderStatus === OrderStatus.EnRoute) {
            return OrderDetailsConstants.SHIPPING_ICON;
          }
          return OrderDetailsConstants.CLOCK_ICON;
        }
        return OrderDetailsConstants.SUCCESS_ICON;
      case StatusSeverity.Warning:
        return OrderDetailsConstants.WARNING_ICON;
      case StatusSeverity.Error:
        return OrderDetailsConstants.EXCEPTION_ICON;
      default:
        return OrderDetailsConstants.CLOCK_ICON;
    }
  }

  private getLineStatusIcon(
    orderType: OrderType,
    lineStatus: string,
    statusSeverity: StatusSeverity,
  ): string {
    switch (statusSeverity) {
      case StatusSeverity.Success:
        if (lineStatus === OrderStatus.Delivered) {
          return OrderDetailsConstants.DELIVERED_ICON;
        }
        if (this.isDropShipOrSpecialOrderType(orderType)) {
          if (lineStatus === OrderStatus.EnRoute) {
            return OrderDetailsConstants.SHIPPING_ICON;
          }
          return OrderDetailsConstants.CLOCK_ICON;
        }
        return OrderDetailsConstants.SUCCESS_ICON;
      case StatusSeverity.Warning:
        return OrderDetailsConstants.WARNING_ICON;
      case StatusSeverity.Error:
        return OrderDetailsConstants.EXCEPTION_ICON;
      default:
        return OrderDetailsConstants.CLOCK_ICON;
    }
  }

  private getSeverityIconColor(statusSeverity: StatusSeverity): string {
    switch (statusSeverity) {
      case StatusSeverity.Error:
        return 'red';
      case StatusSeverity.Warning:
        return 'yellow-warning-icon';
      default:
        return 'gray';
    }
  }

  private getSeverityIcon(orderItem: OrderItem): string {
    switch (orderItem.lineStatusSeverity) {
      case StatusSeverity.Error:
        return 'exception-icon-v1';
      case StatusSeverity.Warning:
        return 'warning-icon-v3';
      default:
        return '';
    }
  }

  private getIconColor(orderDetails: OrderDetails): string {
    const orderStatus = orderDetails.orderStatus;

    switch (orderDetails.orderStatusSeverity) {
      case StatusSeverity.Success:
        if (orderStatus === OrderStatus.Invoiced) {
          return 'purple';
        }
        if (
          orderStatus === OrderStatus.Delivered ||
          this.isDropShipOrSpecialOrderType(orderDetails.orderType)
        ) {
          return 'gray';
        }
        return 'green';
      case StatusSeverity.Warning:
        return 'yellow-warning-icon';
      case StatusSeverity.Error:
        return 'red';
      default:
        return 'gray';
    }
  }

  private getIconAltTextKey(severity: StatusSeverity): string {
    switch (severity) {
      case StatusSeverity.Success:
        return 'ORDERS.DETAILS.SUCCESS_ICON_ALT_TEXT';
      case StatusSeverity.Warning:
        return 'ORDERS.DETAILS.WARNING_ICON_ALT_TEXT';
      case StatusSeverity.Error:
        return 'ORDERS.DETAILS.ERROR_ICON_ALT_TEXT';
      default:
        return '';
    }
  }

  private getOrderStatusShippingMessageKey(orderDetails: OrderDetails): string {
    if (orderDetails.estimatedDeliveryDate === null) {
      return '';
    }

    switch (orderDetails.orderStatus) {
      case OrderStatus.OnOrder:
      case OrderStatus.Requested:
        return 'ORDERS.HISTORY.ON_ORDER_SHIPPING_MESSAGE';
      case OrderStatus.EnRoute:
        return 'ORDERS.HISTORY.EN_ROUTE_SHIPPING_MESSAGE';
      default:
        return '';
    }
  }

  private getCalculatedTime(
    orderDetails: OrderDetails,
    offset: string,
  ): string | undefined {
    if (this.isExpress(orderDetails)) {
      return undefined;
    }
    if (this.isISPU(orderDetails)) {
      return this.dateService.getLocalDateTime(
        orderDetails.storeFulfillment?.requestedPickupTimestamp,
        'time24',
        offset,
        false,
      );
    }
    return orderDetails.estimatedDeliveryTime;
  }

  private getCalculatedDate(orderDetails: OrderDetails): string | undefined {
    if (this.isISPU(orderDetails)) {
      return orderDetails.storeFulfillment?.requestedPickupTimestamp;
    } else if (this.isExpress(orderDetails)) {
      return orderDetails.storeFulfillment?.deliveryWindowStartTimestamp;
    } else if (this.isDropShip(orderDetails.orderType)) {
      return orderDetails.estimatedDeliveryDate;
    }
    return this.getCalculatedDateString(orderDetails);
  }

  private getCalculatedDateString(orderDetails: OrderDetails): string {
    return (
      orderDetails.actualDeliveryDate ||
      this.getDateTimeString(
        orderDetails.estimatedDeliveryDate,
        orderDetails.estimatedDeliveryTime,
      ) ||
      orderDetails.requestedCustomerArrivalDate ||
      orderDetails.shippedOnDate ||
      orderDetails.orderDate
    );
  }

  private getDateTimeString(date: string, time?: string): string | undefined {
    if (!Date.parse(date)) {
      return undefined;
    }
    const dateTimeMoment = time?.trim()
      ? moment(`${date} ${time}`)
      : moment(date);
    if (!dateTimeMoment.isValid()) {
      return undefined;
    }
    return time
      ? dateTimeMoment.format(SapDateTimeFormat.DATE_TIME)
      : dateTimeMoment.format(SapDateTimeFormat.DATE_ONLY);
  }

  private getEstimatedDeliveryTime(
    orderDetails: OrderDetails,
  ): string | undefined {
    if (orderDetails.actualDeliveryDate) {
      return undefined;
    }
    return !!orderDetails.estimatedDeliveryDate &&
      !!orderDetails.estimatedDeliveryTime
      ? orderDetails.estimatedDeliveryTime
      : undefined;
  }

  private shouldHideDeliveryEta(order: OrderDetails): boolean {
    return (
      this.isDropShip(order.orderType) ||
      order.orderStatus === OrderStatus.Rejected ||
      (order.orderStatus === OrderStatus.Invoiced &&
        (!order.estimatedDeliveryTime ||
          order.estimatedDeliveryTime.trim().length <= 0))
    );
  }

  private showTrackingIdsLink(
    orderDetails: OrderDetails,
    currentSystem: CurrentSystem,
  ) {
    if (currentSystem === CurrentSystem.Sap) {
      return this.showTrackingIdsSap(orderDetails);
    }
    return this.showTrackingIdsNotSap(orderDetails);
  }

  private showTrackingIdsSap(orderDetails: OrderDetails) {
    const trackingNumbers = orderDetails?.orderLines?.reduce(
      (trackingTotal, orderLine) => {
        let lineTotal = 0;
        if (orderLine?.freightInformation) {
          lineTotal = orderLine.freightInformation.reduce(
            (itemCount, freightInformation) => {
              return !freightInformation.trackingNumbers
                ? itemCount
                : itemCount + freightInformation.trackingNumbers.length;
            },
            0,
          );
        }
        return trackingTotal + lineTotal;
      },
      0,
    );
    return trackingNumbers > 0;
  }

  private showTrackingIdsNotSap(orderDetails: OrderDetails) {
    let trackingTotal = 0;
    if (orderDetails?.freightInformation) {
      trackingTotal = orderDetails.freightInformation.reduce(
        (totalTrackingNumbers, freightInformation) => {
          return !freightInformation || !freightInformation.trackingNumbers
            ? totalTrackingNumbers
            : totalTrackingNumbers + freightInformation.trackingNumbers.length;
        },
        0,
      );
    }
    return trackingTotal > 0 && !this.isCanceled(orderDetails.orderStatus);
  }

  private getTrackingUrls(
    order: OrderDetails,
    currentSystem: CurrentSystem,
  ): string[] {
    if (this.showTrackingIdsLink(order, currentSystem)) {
      if (currentSystem === CurrentSystem.Sap) {
        const ups = this.collectTrackingUrlsForSap(order, FreightCarrier.UPS);
        const fedex = this.collectTrackingUrlsForSap(
          order,
          FreightCarrier.FEDEX,
        );
        return ups.concat(fedex);
      }
      return order.freightInformation
        .filter(
          (freightInformation) =>
            !!freightInformation?.trackingNumbers &&
            freightInformation?.trackingNumbers.length > 0,
        )
        .map((freightInformation) =>
          this.collectTrackingUrlsForNonSap(freightInformation),
        )
        .reduce((carriers, carrier2) => carriers.concat(carrier2), []);
    } else {
      return [];
    }
  }

  private collectTrackingUrlsForSap(
    order: OrderDetails,
    carrier: FreightCarrier,
  ): string[] {
    return this.createTrackingUrl(
      carrier,
      Array.from(collectTrackingNumbers(order, carrier).values()),
    );
  }

  private collectTrackingUrlsForNonSap(
    freightInformation: FreightInformation,
  ): string[] {
    return this.createTrackingUrl(
      freightInformation.freightCarrier,
      freightInformation.trackingNumbers,
    );
  }

  private createTrackingUrl(
    carrier: string,
    trackingNumbers: string[],
  ): string[] {
    if (trackingNumbers.length < 1) {
      return [];
    }
    switch (carrier) {
      case FreightCarrier.FEDEX:
        return [
          OrderDetailsConstants.FEDEX_URL +
            (trackingNumbers ? trackingNumbers.join(',') : ''),
        ];
      case FreightCarrier.UPS:
        return [
          OrderDetailsConstants.UPS_URL +
            (trackingNumbers ? trackingNumbers.join('%250D%250A') : ''),
        ];
      default:
        return undefined;
    }
  }

  private isCanceled(orderStatus: string): boolean {
    return (
      orderStatus === OrderStatus.CancelledByRep ||
      orderStatus === OrderStatus.CancelledByCustomer ||
      orderStatus === OrderStatus.CancelledByVendor
    );
  }

  private isDropShip(orderType: string): boolean {
    return OrderType.DropShip === orderType;
  }

  private isISPU(orderDetails: OrderDetails): boolean {
    return (
      OrderType.StoreFulfillment === orderDetails.orderType &&
      RequestedDeliveryType.EXPRESS !==
        orderDetails.storeFulfillment?.requestedDeliveryType
    );
  }

  private isExpress(orderDetails: OrderDetails): boolean {
    return (
      OrderType.StoreFulfillment === orderDetails.orderType &&
      RequestedDeliveryType.EXPRESS ===
        orderDetails.storeFulfillment?.requestedDeliveryType
    );
  }

  private isDropShipOrSpecialOrderType(orderType: OrderType): boolean {
    return this.isDropShip(orderType) || OrderType.NonStock === orderType;
  }

  private isWarningOrErrorSeverity(severity: StatusSeverity): boolean {
    return (
      severity === StatusSeverity.Warning || severity === StatusSeverity.Error
    );
  }

  private shouldShowLineStatus(
    isDropShipOrSpecialOrderType: boolean,
    severity: StatusSeverity,
  ): boolean {
    return (
      this.isWarningOrErrorSeverity(severity) || isDropShipOrSpecialOrderType
    );
  }
}
