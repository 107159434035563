import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CustomerCartCountRecord } from '../../shared/services/user-cart-totals/user-cart-totals';
import { SessionCustomer } from 'src/app/core/services/session/models/session-record';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

type LabeledSection =
  | 'ActiveItems'
  | 'Id'
  | 'Name'
  | 'SavedCarts'
  | 'SubmittedCarts'
  | 'PunchedOutCarts';

@Component({
  selector: 'naoo-customer-unit-card',
  templateUrl: './customer-unit-card.component.html',
  styleUrls: ['./customer-unit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule],
})
export class CustomerUnitCardComponent {
  @Input() customer: SessionCustomer;
  @Input() isMobile: boolean;
  @Input() isOrderSubmission: boolean;
  @Input() hasPunchedOutCarts: boolean;
  @Input() active: boolean;
  @Input() isLast: boolean;
  @Input() cartTotal?: CustomerCartCountRecord;

  constructor(
    private sessionFacade: SessionFacade,
    private router: Router,
    private cartFacade: CartFacade,
  ) {}

  selectCustomerUnit(naooCustomerId: string) {
    this.sessionFacade.updateSelectedCustomer(naooCustomerId);

    this.sessionFacade
      .isCustomerUpdated()
      .pipe(first((isUpdated) => !!isUpdated))
      .subscribe(() => {
        this.router.navigate(['']);
        setTimeout(() => this.cartFacade.openFulfillmentModal());
      });
  }

  getLabel(section: LabeledSection): string {
    const naooCustomerId = this.customer?.naooCustomerId;
    return naooCustomerId && section
      ? `customer${section}Label-${naooCustomerId}`
      : undefined;
  }

  get customerAddress(): string {
    const whitespace = /\s+/g;
    return [
      this.customer.address.line2,
      this.customer.address.city,
      this.customer.address.provinceState,
      this.customer.address.postalCode,
    ]
      .filter((part) => !!part && part.replace(whitespace, '') !== '')
      .join(', ');
  }

  get ngClassForWidth(): { [key: string]: boolean } {
    const isLargeWidth = !this.isOrderSubmission && !this.hasPunchedOutCarts;
    if (isLargeWidth) {
      return {
        'large-width': isLargeWidth,
      };
    }
    return {
      'medium-width': !this.isOrderSubmission || !this.hasPunchedOutCarts,
    };
  }
}
