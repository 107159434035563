import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvironmentSpecificService } from '../shared/services/environment-specific/environment-specific.service';
import { HeaderHeightService } from '../shared/services/header-height/header-height.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LocalizationService } from '../shared/services/translation/localization.service';
import { Locale } from '../core/services/session/models/session-record';
import { getLanguageTag } from '../shared/utilities/locale-utilities';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressBar, AsyncPipe],
})
export class SiteComponent implements OnInit, AfterViewInit {
  private readonly siteUrl = 'site/';

  @ViewChild('iframe') iframe: ElementRef;
  headerHeight$: Observable<number>;
  viewportHeight$ = new BehaviorSubject<number>(window.innerHeight);
  currentLocale: Locale;
  loading: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly environmentSpecificService: EnvironmentSpecificService,
    private readonly headerHeightService: HeaderHeightService,
    private readonly localizationService: LocalizationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.headerHeight$ = combineLatest([
      this.viewportHeight$.asObservable().pipe(distinctUntilChanged()),
      this.headerHeightService.observeHeaderHeight(),
    ]).pipe(
      map(
        ([viewportHeight, headerHeight]) => viewportHeight - headerHeight - 8,
      ),
    );
    this.localizationService.locale().subscribe((locale: Locale) => {
      if (!this.loading) {
        this.loading = true;
        this.onLoad();
      }
      this.currentLocale = locale;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    if (this.loading) {
      this.onLoad();
    }
  }

  private onLoad(): void {
    this.iframe.nativeElement.onload = () => {
      this.loading = false;
      this.changeDetectorRef.markForCheck();
      this.iframe.nativeElement.onload = null;
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.viewportHeight$.next(window.innerHeight);
  }

  get iframeUrl(): SafeResourceUrl {
    const url = this.router.url;
    const slug = url.substring(url.indexOf(this.siteUrl) + this.siteUrl.length);

    let languageTag = getLanguageTag(Locale.en_US);
    if (Locale.es_US !== this.currentLocale) {
      // NAOO-32599 - This is not available for es-us yet
      languageTag = getLanguageTag(this.currentLocale);
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.sanitizer.sanitize(
        SecurityContext.URL,
        `${this.environmentSpecificService.getEcomContentUrl()}/${languageTag.toLowerCase()}/${slug}`,
      ),
    );
  }
}
