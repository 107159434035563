<mat-menu
  #guideChangesActionsMenu
  class="guide-changes-menu"
  xPosition="before"
>
  <ng-template matMenuContent>
    <div
      class="changes-menu-header"
      mat-menu-item
      (click)="stopPropagation($event)"
      (keyup.enter)="stopPropagation($event)"
    >
      {{
        (isAddedItem
          ? 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.CAN_ADD'
          : 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.CAN_REMOVE'
        ) | translate
      }}
    </div>

    @if (shouldShowNewGuideOption) {
      <div
        class="new-guide-option changes-option"
        [ngClass]="{ disabled: !isUserOnline }"
        (click)="handleNewGuideClick($event)"
        (keyup.enter)="handleNewGuideClick($event)"
        mat-menu-item
      >
        <mat-icon class="button-icon" svgIcon="plus_icon"></mat-icon>
        {{ 'LISTS.CREATE_GUIDE' | translate }}
      </div>
    }

    @if (shouldShowCriticalItemOption) {
      <div
        class="guide-option changes-option critical-items-option"
        [ngClass]="{ disabled: !isUserOnline }"
        (click)="stopPropagation($event)"
        (keyup.enter)="handleCriticalItemsClick($event)"
        mat-menu-item
      >
        <div class="guide-name">
          {{ 'LISTS.CRITICAL_ITEMS_GUIDE' | translate }}
        </div>
        <button
          class="options-action-button"
          (click)="handleCriticalItemsClick($event)"
          [disabled]="!isUserOnline"
        >
          {{
            (isAddedItem
              ? 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.ADD'
              : 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.REMOVE'
            ) | translate
          }}
        </button>
      </div>
    }

    @for (guide of menuOptions; track guide.id) {
      <div
        class="guide-option changes-option custom-guide-option"
        [ngClass]="{ disabled: !isUserOnline }"
        (click)="stopPropagation($event)"
        (keyup.enter)="handleGuideClick($event, guide.id)"
        mat-menu-item
      >
        <div class="guide-name">
          {{ guide.name }}
        </div>
        <button
          class="options-action-button"
          (click)="handleGuideClick($event, guide.id)"
          [disabled]="!isUserOnline"
        >
          {{
            (isAddedItem
              ? 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.ADD'
              : 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.REMOVE'
            ) | translate
          }}
        </button>
      </div>
    }
  </ng-template>
</mat-menu>

<button
  class="update-guide-button"
  [disabled]="shouldDisableUpdateGuides"
  [matMenuTriggerFor]="guideChangesActionsMenu"
>
  {{
    (isAddedItem
      ? 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.ADD'
      : 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.REMOVE'
    ) | translate
  }}
</button>
