<mat-dialog-content>
  @if (title) {
    <div class="title-container">
      <span class="title">{{ title | translate }}</span>
      <ng-container [ngTemplateOutlet]="closeButton"></ng-container>
    </div>
  } @else if (closeAction) {
    <button
      class="close-button"
      (click)="closeAction()"
      attr.aria-label="{{ 'CLOSE' | translate }}"
      mat-button
    >
      <mat-icon class="close-button-img" svgIcon="clear_icon"></mat-icon>
    </button>
  }

  <ng-template #closeButton>
    @if (closeAction) {
      <button
        class="close-button"
        (click)="closeAction()"
        attr.aria-label="{{ 'CLOSE' | translate }}"
        mat-button
      >
        <mat-icon class="close-button-img" svgIcon="clear_icon"></mat-icon>
      </button>
    }
  </ng-template>

  @if (image) {
    @if (hasVideoData) {
      <video
        [autoplay]="true"
        [loop]="true"
        [muted]="true"
        playsinline="playsinline"
        [controls]="false"
        disablePictureInPicture="true"
        class="modal-image"
        src="{{ image.imageLocation }}"
      ></video>
    } @else {
      <img
        class="modal-image"
        [src]="image.imageLocation"
        alt="{{ image.altText | translate }}"
      />
    }
  }

  @if (messageData?.messageKey) {
    <div
      class="message"
      [innerHTML]="messageData.messageKey | translate: messageData.parameters"
    ></div>
  }
  @if (helperMessageData?.messageKey) {
    <div
      class="helper-message"
      [innerHTML]="
        helperMessageData.messageKey | translate: helperMessageData.parameters
      "
    ></div>
  }

  @if (primaryButtonText || secondaryButtonText) {
    <div
      [ngClass]="
        primaryButtonText && secondaryButtonText
          ? 'two-button-container'
          : 'single-button-container'
      "
    >
      @if (secondaryButtonText && secondaryButtonAction) {
        <button
          class="large-button multilingual-button"
          (click)="secondaryButtonAction(); dialogRef.close()"
          [ngClass]="
            isDestructive ? 'secondary-destructive-button' : 'secondary-button'
          "
        >
          {{ secondaryButtonText | translate }}
        </button>
      }
      @if (primaryButtonText && primaryButtonAction) {
        <button
          class="large-button multilingual-button"
          (click)="primaryButtonAction(); dialogRef.close()"
          [ngClass]="
            isDestructive ? 'primary-destructive-button' : 'primary-button'
          "
        >
          {{ primaryButtonText | translate }}
        </button>
      }
    </div>
  }
</mat-dialog-content>
