import { Component, Input, OnInit } from '@angular/core';
import { CatalogResponse } from '../../shared/models/catalog/catalog-response';
import { CatalogService } from '../../shared/services/catalog/catalog.service';
import { NaooConstants } from '../../shared/NaooConstants';
import { RouterLink } from '@angular/router';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-product-group',
  templateUrl: './product-group.component.html',
  styleUrls: ['./product-group.component.scss'],
  standalone: true,
  imports: [RouterLink, NaooStringDefaulterPipe],
})
export class ProductGroupComponent implements OnInit {
  @Input() category: CatalogResponse;

  catalogImageSrc: string;
  catalogImageSrcSet: string;

  constructor(private catalogService: CatalogService) {}

  ngOnInit() {
    const imageSources = this.catalogService.buildImageSrcUrls(
      this.category,
      'jpg',
    );

    this.catalogImageSrc = imageSources.src;
    this.catalogImageSrcSet = imageSources.srcset;
  }

  get getRouterLink() {
    return ['/categories/', this.category.categoryKey];
  }

  noImageAvailableURL(event: UIEvent): void {
    (<HTMLImageElement>event.target).src = NaooConstants.placeHolderImagePath;
    (<HTMLImageElement>event.target).srcset =
      NaooConstants.placeHolderImagePath;
  }
}
