<button
  mat-icon-button
  class="mat-icon-button"
  [matMenuTriggerFor]="menu"
  attr.aria-label="{{ 'LISTS.MENU_OPTIONS' | translate }}"
>
  <mat-icon svgIcon="triple_dot_menu"></mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before" (closed)="menuClosed()">
  <button
    mat-menu-item
    class="menu-button rename-guide"
    role="button"
    attr.aria-label="{{ 'LISTS.RENAME_CUSTOM_GUIDE_WCAG' | translate }}"
    (click)="menuAction = openRenameModal"
    [disabled]="isUserOffline"
    [ngClass]="{
      'button-disabled': isUserOffline,
    }"
  >
    {{ 'LISTS.RENAME_CUSTOM_GUIDE' | translate }}
  </button>
  <button
    mat-menu-item
    class="menu-button duplicate-guide"
    role="button"
    (click)="menuAction = openDuplicateModal"
    attr.aria-label="{{
      'LISTS.DUPLICATE_CUSTOM_GUIDE_WCAG'
        | translate: { guideName: customGuideName }
    }}"
    [disabled]="isUserOffline"
    [ngClass]="{
      'button-disabled': isUserOffline,
    }"
  >
    {{ 'LISTS.DUPLICATE_CUSTOM_GUIDE' | translate }}
  </button>
  @if (showCopyGuide) {
    <button
      mat-menu-item
      class="menu-button copy-guide"
      attr.aria-label="{{ 'LISTS.COPY_CUSTOM_GUIDE_WCAG' | translate }}"
      (click)="menuAction = openCopyModal"
      [disabled]="isUserOffline"
      [ngClass]="{
        'button-disabled': isUserOffline,
      }"
    >
      {{ 'LISTS.COPY_CUSTOM_GUIDE' | translate }}
    </button>
  }
  <!--Do not remove ngif from below for obvious reasons ==> https://github.com/angular/material.angular.io/issues/577-->
  @if (true) {
    <button
      mat-menu-item
      class="menu-button delete-guide"
      role="button"
      attr.aria-label="{{ 'LISTS.DELETE_CUSTOM_GUIDE_WCAG' | translate }}"
      (click)="menuAction = openDeleteModal"
      [disabled]="isUserOffline"
      [ngClass]="{
        'button-disabled': isUserOffline,
      }"
    >
      {{ 'LISTS.DELETE_CUSTOM_GUIDE' | translate }}
    </button>
  }
</mat-menu>
