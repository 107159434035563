import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import { PunchoutService } from '../../../shared/services/punchout/punchout.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../shared/services/loading-service/loading.service';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { CartFacade } from '../../../core/store/cart/cart.facade';
import { CartReviewFacade } from '../../../core/store/cart-review/cart-review.facade';
import { MultipleCartsFacade } from '../../../core/store/multiple-carts/multiple-carts.facade';
import { ModalFacade } from '../../../core/store/modal/modal.facade';
import { MatDialog } from '@angular/material/dialog';
import { SalesCriticalItemsFacade } from '../../../core/store/sales-critical-items/sales-critical-items.facade';
import {
  CostSummaryContext,
  SubmitOrderButtonState,
} from '../../shared/cost-summary';
import { filter, first, map, skip, take, takeUntil, tap } from 'rxjs/operators';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MaterialWarningContainerComponent } from '../../../order-confirmation/material-warning/material-warning-container.component';
import { OfflineModeFacade } from '../../../core/store/offline-mode/offline-mode.facade';
import { OrderSubmitButtonContentComponent } from '../order-submit-button-content/order-submit-button-content.component';
import { AsyncPipe } from '@angular/common';
import { NaooDeliveryOrShipPipe } from '../../../shared/pipes/delivery-or-ship.pipe';

@Component({
  selector: 'naoo-order-submit-button-container',
  template: ` <naoo-order-submit-button-content
    (onClick)="this.submit()"
    [isDisabled]="this.isButtonDisabled$ | async"
    [buttonText]="this.buttonText"
  >
  </naoo-order-submit-button-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrderSubmitButtonContentComponent, AsyncPipe],
})
export class OrderSubmitButtonContainerComponent implements OnInit, OnDestroy {
  readonly addPaymentOrderButtonText = 'CART.ADD_PAYMENT';
  readonly cartReviewOrderButtonText = 'CART.REVIEW_YOUR_ORDER';
  readonly cartPlaceOrderButtonText = 'CART_REVIEW.PLACE_ORDER';
  readonly noOrderSubmissionPlaceOrderText = 'CART.SUBMIT_FOR_APPROVAL';
  readonly transferCartPunchOutButtonText = 'CART_REVIEW.TRANSFER_CART';
  @Input() cartTotal: number;
  @Input() isPunchOut: boolean;
  @Input() hasIspuOrExpressEnabled: boolean;
  @Output() submitButtonClick = new EventEmitter<void>();
  buttonText: string;
  private isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private isReadyToSubmit$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _hasHardStopSalesCriticalItems: boolean;
  private destroyed$ = new Subject<boolean>();

  // eslint-disable-next-line max-params
  constructor(
    private punchOutService: PunchoutService,
    private router: Router,
    private loadingService: LoadingService,
    private defaultDialogService: DefaultDialogService,
    private cartFacade: CartFacade,
    private cartReviewFacade: CartReviewFacade,
    private multipleCartsFacade: MultipleCartsFacade,
    private modalFacade: ModalFacade,
    private deliveryOrShipPipe: NaooDeliveryOrShipPipe,
    private dialog: MatDialog,
    private salesCriticalItemsFacade: SalesCriticalItemsFacade,
    private offlineModeFacade: OfflineModeFacade,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  private _context: CostSummaryContext;

  get context(): CostSummaryContext {
    return this._context;
  }

  @Input()
  set context(context: CostSummaryContext) {
    this._context = context;
    this.determineButtonStateAndText();
  }

  private _buttonState: SubmitOrderButtonState;

  @Input()
  set buttonState(buttonState: SubmitOrderButtonState) {
    this._buttonState = buttonState;
    this.determineButtonStateAndText();
  }

  private _isPoInvalid: boolean;

  @Input()
  set isPoInvalid(isPoInvalid: boolean) {
    this._isPoInvalid = isPoInvalid;
    this.determineButtonStateAndText();
  }

  private _isPoRequired: boolean;

  @Input()
  set isPoRequired(isPoRequired: boolean) {
    this._isPoRequired = isPoRequired;
    this.determineButtonStateAndText();
  }

  private _isCreditPaymentLoading: boolean;

  @Input() set isCreditPaymentLoading(loading: boolean) {
    this._isCreditPaymentLoading = loading;
    this.determineButtonStateAndText();
  }

  isButtonDisabled$: Observable<boolean> = combineLatest([
    this.loadingService.loadingState,
    this.cartFacade.isDoneUpdating(),
    this.cartReviewFacade.isCartReviewLoading(),
    this.offlineModeFacade.getIsOffline(),
    this.isDisabled$,
    this.isReadyToSubmit$,
  ]).pipe(
    map(
      ([
        loadingState,
        cartIsDoneUpdating,
        cartReviewLoading,
        isOffline,
        isDisabled,
        isReadyToSubmit,
      ]) => {
        return (
          loadingState ||
          cartReviewLoading ||
          isOffline ||
          isDisabled ||
          (!cartIsDoneUpdating && isReadyToSubmit)
        );
      },
    ),
  );

  ngOnInit() {
    this.salesCriticalItemsFacade
      .getSalesCriticalItemsWithExceededHardStopQuantity()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((itemsExceedingLimit) => {
        this._hasHardStopSalesCriticalItems = itemsExceedingLimit?.length > 0;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  submit(): void {
    if (this.context == CostSummaryContext.CartReviewPaymentRequired) {
      this.submitButtonClick.emit();
      return;
    }
    this.checkForInvalidDropShipOrders(() => {
      this.submitOrder();
    });
  }

  /**
   * Displays a modal for invalid dropship orders, or invokes callback if there are none.
   * @param success callback to be invoked if there are no invalid dropship orders.
   * @private
   */
  private checkForInvalidDropShipOrders(success: () => void) {
    this.cartReviewFacade
      .hasFailedFulfillmentSplitOrder()
      .pipe(first())
      .subscribe((hasFailedFullfillment) => {
        if (hasFailedFullfillment) {
          this.promptSystemErrorModal();
        } else {
          this.cartFacade
            .getInvalidDropShipOrders()
            .pipe(first())
            .subscribe((invalidDropShipOrders) => {
              if (invalidDropShipOrders?.length > 0) {
                this.submitButtonClick.emit();
                this.promptDropShipModal(invalidDropShipOrders.length);
              } else {
                success();
              }
            });
        }
      });
  }

  private submitOrder(): void {
    if (
      this.context === CostSummaryContext.CartReview ||
      this.context === CostSummaryContext.AddPayment
    ) {
      if (this._hasHardStopSalesCriticalItems) {
        this.salesCriticalItemsModal();
      } else if (this.isPunchOut) {
        this.punchOutService.punchOutCart(this.cartTotal);
      } else if (
        this._buttonState === SubmitOrderButtonState.NoOrderSubmissionPermission
      ) {
        this.multipleCartsFacade.submitCartForApproval();
      } else {
        const untilDestroyedOrPaymentChanges = merge(
          this.destroyed$,
          this.cartReviewFacade.isCreditCardOptionSelected().pipe(skip(1)),
        );
        this.cartReviewFacade
          .submitCreditPaymentIfNeeded()
          .pipe(
            takeUntil(untilDestroyedOrPaymentChanges),
            filter((hasResolved) => hasResolved),
            take(1),
            tap(() =>
              this.router.navigate([NaooConstants.ORDER_CONFIRMATION_URL], {
                replaceUrl: true,
              }),
            ),
          )
          .subscribe();
      }
    } else {
      this.router.navigateByUrl(NaooConstants.CART_REVIEW_URL);
    }
  }

  private promptSystemErrorModal() {
    this.defaultDialogService.oneButtonModal(
      'order-submit-system-error',
      'CART.ERROR.SYSTEM_ERROR',
      'CART.ERROR.SYSTEM_ERROR_BUTTON',
      () => this.cartReviewFacade.removeAllFailedFulfillmentMaterials(),
      true,
      true,
      null,
      null,
      () => this.cartReviewFacade.refreshCartReview(),
    );
  }

  private promptDropShipModal(invalidItems: number) {
    this.modalFacade.promptOneButtonModal(
      'submit-button-cart-needs-attention',
      {
        messageKey: 'CART.ERROR.NEEDS_ATTENTION',
        parameters: { count: invalidItems },
      },
      'CART.ERROR.DISMISS_MODAL',
      () => {},
      true,
    );
  }

  private salesCriticalItemsModal() {
    return this.dialog.open(MaterialWarningContainerComponent, {
      id: 'material-warning-container',
      panelClass: 'naoo-material-warning-modal',
      maxWidth: '100%',
      disableClose: true,
    });
  }

  private determineButtonStateAndText() {
    if (
      this._isPoInvalid &&
      this._isPoRequired &&
      this._buttonState !== SubmitOrderButtonState.NoRouteDate
    ) {
      this.buttonText = 'CART.EMPTY_PO_BUTTON';
      this.isDisabled$.next(true);
      this.changeDetectorRef.markForCheck();
      return;
    }

    switch (this._buttonState) {
      case SubmitOrderButtonState.TransferCart:
        this.buttonText = this.transferCartPunchOutButtonText;
        this.isDisabled$.next(false);
        break;
      case SubmitOrderButtonState.Ok:
        this.buttonText =
          this._context === CostSummaryContext.CartSummary
            ? this.cartReviewOrderButtonText
            : this.cartPlaceOrderButtonText;
        this.isDisabled$.next(
          this._isPoInvalid || this._isCreditPaymentLoading,
        );
        break;
      case SubmitOrderButtonState.EmptyCart:
        this.buttonText =
          this._context === CostSummaryContext.CartSummary
            ? this.cartReviewOrderButtonText
            : this.cartPlaceOrderButtonText;
        this.isDisabled$.next(true);
        break;
      case SubmitOrderButtonState.NoOrderSubmissionPermission:
        this.buttonText =
          this._context === CostSummaryContext.CartSummary
            ? this.cartReviewOrderButtonText
            : this.noOrderSubmissionPlaceOrderText;
        this.isDisabled$.next(false);
        break;
      case SubmitOrderButtonState.NoOrderSubmissionPermissionEmptyCart:
        this.buttonText =
          this._context === CostSummaryContext.CartSummary
            ? this.cartReviewOrderButtonText
            : this.noOrderSubmissionPlaceOrderText;
        this.isDisabled$.next(true);
        break;
      case SubmitOrderButtonState.NoRouteDate:
        this.determineButtonStateAndTextForNoRouteDate();
        break;
      case SubmitOrderButtonState.AddPayment:
        this.buttonText = this.addPaymentOrderButtonText;
        this.isDisabled$.next(false);
        break;
    }

    this.isReadyToSubmit$.next(this.isReadyToSubmit());
    this.changeDetectorRef.markForCheck();
  }

  private determineButtonStateAndTextForNoRouteDate() {
    let buttonText: string;
    if (this.context == CostSummaryContext.CartReviewPaymentRequired) {
      buttonText = this.addPaymentOrderButtonText;
    } else if (this.hasIspuOrExpressEnabled) {
      buttonText = 'SELECT_ORDER_METHOD.SUBMIT_NO_DATE';
    } else {
      buttonText = this.deliveryOrShipPipe.transform('SUBMIT_NO_DATE');
    }
    this.buttonText = buttonText;
    this.isDisabled$.next(true);
    this.changeDetectorRef.markForCheck();
  }

  private isReadyToSubmit(): boolean {
    return (
      this._context !== CostSummaryContext.CartSummary &&
      this._buttonState === SubmitOrderButtonState.Ok
    );
  }
}
