import { HelpModalValues } from '../../shared/modals/generic-helper-modal/generic-help-modal.component';

export const customGuideHelpModal: HelpModalValues = {
  title: 'LISTS.HELP.MODAL_TITLE',
  expansions: [
    {
      title: 'LISTS.HELP.ADD_PRODUCTS.TITLE',
      paragraphs: [],
      imageDetails: {
        listItems: [
          'LISTS.HELP.ADD_PRODUCTS.LI_1',
          'LISTS.HELP.ADD_PRODUCTS.LI_2',
          'LISTS.HELP.ADD_PRODUCTS.LI_3',
          'LISTS.HELP.ADD_PRODUCTS.LI_4',
        ],
        image: {
          altText: 'LISTS.HELP.ADD_PRODUCTS.IMG_LABEL',
          src: 'LISTS.HELP.ADD_PRODUCTS.IMG',
          width: 253,
          height: 286,
        },
      },
    },
    {
      title: 'LISTS.HELP.GUIDES_DIFFERENCE_V1.TITLE',
      paragraphs: [
        'LISTS.HELP.GUIDES_DIFFERENCE_V1.P1',
        'LISTS.HELP.GUIDES_DIFFERENCE_V1.P2',
      ],
    },
    {
      title: 'LISTS.HELP.PAR.TITLE',
      paragraphs: ['LISTS.HELP.PAR.P1', 'LISTS.HELP.PAR.P2'],
    },
  ],
};
