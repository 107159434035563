@if (isCriticalItemsGuide) {
  <div class="card">
    <button class="card-top critical-items" (click)="criticalItemsClicked()">
      <mat-icon class="card-top-icon" svgIcon="critical_item_star"></mat-icon>
      <span class="card-top-text">
        {{
          isCriticalItemImported
            ? ('LISTS.CRITICAL_ITEMS.IMPORTED' | translate)
            : listTitle
        }}
      </span>
      @if (shouldRenderProductCount) {
        <span class="product-count">
          ({{ totalListProducts }} {{ productString() | translate }})
        </span>
      }
    </button>
    <mat-divider></mat-divider>
    <div class="card-bottom">
      <button
        role="button"
        class="card-bottom-button"
        (click)="openCriticalItemHelperModal()"
        tabindex="0"
      >
        <mat-icon
          class="card-bottom-icon hand-icon"
          svgIcon="hand_string"
        ></mat-icon>
        {{ 'LISTS.CRITICAL_ITEM_HELPER.LEARN_HOW_THIS_GUIDE' | translate }}
      </button>
    </div>
  </div>
} @else {
  <div class="card">
    <button class="card-top order-guide" (click)="orderGuideClicked()">
      <img
        class="card-top-icon"
        src="{{ 'LOGO.ICON' | naoobrand: customerBrand | translate }}"
        alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
      />
      <span class="card-top-text">
        {{ listTitle }}
      </span>
      @if (shouldRenderProductCount) {
        <span class="product-count">
          ({{ totalListProducts }} {{ productString() | translate }})
        </span>
      }
    </button>
    <mat-divider></mat-divider>
    <div class="card-bottom">
      @if (shouldRenderOrderGuideChanges) {
        <button
          role="button"
          class="card-bottom-button"
          (click)="openGuideChangesModal()"
          tabindex="0"
        >
          <mat-icon
            class="card-bottom-icon alarm-icon"
            svgIcon="alarm_icon"
          ></mat-icon>
          {{ 'LISTS.RECENT_CHANGES' | translate }}
        </button>
      }
    </div>
  </div>
}
