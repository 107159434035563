<naoo-hero-images
  class="hero-banner"
  [hostComponent]="'catalog-v4'"
></naoo-hero-images>
<div class="order-summary-header">{{ 'MY_ID_CARD.TITLE' | translate }}</div>
<div class="barcode-element">
  <img
    class="barcode-image"
    id="barcode"
    [alt]="'MY_ID_CARD.TITLE' | translate"
    src=""
  />
  <hr class="solid" />
  <mat-icon class="critical-item-star" svgIcon="critical_item_star"></mat-icon>
  <h1>{{ fullName$ | async }}</h1>
</div>
