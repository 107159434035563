import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { Localized } from '../../../../shared/models/localized';
import { RouterLink } from '@angular/router';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-header-breadcrumb',
  templateUrl: './guides-header-breadcrumb.component.html',
  styleUrls: ['./guides-header-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NaooStringDefaulterPipe, TranslateModule],
})
export class GuidesHeaderBreadcrumbComponent {
  @Input() name: Localized<string>;

  readonly guidesPath = NaooConstants.GUIDES_PATH;
}
