import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SmartCartRecommendationsActions } from './smart-cart-recommendations.actions';
import { Observable } from 'rxjs';
import { SmartCartRecommendation } from './smart-cart-recommendations.state';
import { selectOrderableSmartCartRecommendationRecords } from './smart-cart-recommendations.selectors';

@Injectable({ providedIn: 'root' })
export class SmartCartRecommendationsFacade {
  constructor(private store: Store) {}

  getOrderableSmartCartRecommendationRecords(
    limit?: number,
  ): Observable<SmartCartRecommendation[]> {
    return this.store.select(
      selectOrderableSmartCartRecommendationRecords(limit),
    );
  }

  loadSmartCartRecommendations(): void {
    this.store.dispatch(
      SmartCartRecommendationsActions.loadSmartCartRecommendations(),
    );
  }
}
