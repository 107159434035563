import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
  csvFieldSeparator,
  defaultOrderDetailsExportFileTypes,
  ExportFeatureType,
  ExportFileType,
  ExportOrderDetailsTranslationKeys,
} from '../../models/export/export-properties';
import { finalize, first, switchMap } from 'rxjs/operators';
import { ExportModalResponse } from '../../modals/export-modal/export-modal.component';
import { CustomDialogService } from '../dialog/custom-dialog/custom-dialog.service';
import {
  defaultOrderDetailsExportList,
  ExportModalDataPoint,
} from '../../models/export-modal-data-point';
import { IconState } from '../../action-icon/action-icon.component';
import { LocalizationService } from '../translation/localization.service';
import { ExportService } from '../export-service/export.service';
import { OrderType } from '../../models/order-type';
import moment from 'moment-timezone';
import { getWeightUom } from '../../../core/store/material-price/material-price.util';
import {
  OrderDetailsExportModel,
  OrderDetailsLineExportModel,
  UomExportLine,
} from '../../models/order-details';
import { NaooStringDefaulter } from '../../string-defaulter/naoo-string-defaulter';
import { OrderDetailsService } from '../order/order-details.service';
import { LocalizedUtilities } from '../../utilities/localized-utilities';
import { getQtyPerMasterSellUnitFromMaterialInfo } from '../../models/material-info';
import { MaterialUnitsFacade } from '../../../core/store/material-units/material-units.facade';
import { formatCurrency } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExportOrderDetailsService {
  private readonly exportTitle = 'EXPORT_MODAL.EXPORT_ORDER_DETAILS.TITLE';
  private readonly analyticsEventCategory = 'Export Order Details';

  constructor(
    private readonly customDialogService: CustomDialogService,
    private readonly localizationService: LocalizationService,
    private readonly exportService: ExportService,
    private readonly stringDefaulter: NaooStringDefaulter,
    private readonly materialUnitsFacade: MaterialUnitsFacade,
    private readonly orderDetailsService: OrderDetailsService,
  ) {}

  public openExportDialog(
    orderNumber: string,
    orderType: OrderType,
    groupNumber: string,
    iconState$: Subject<IconState>,
  ): Observable<void> {
    iconState$.next(IconState.Disabled);
    return this.customDialogService
      .exportModal(
        'export-order-details',
        this.exportTitle,
        defaultOrderDetailsExportFileTypes,
        this.analyticsEventCategory,
        defaultOrderDetailsExportList,
        ExportFeatureType.ORDER_DETAILS,
      )
      .pipe(
        switchMap((dialog) => dialog.afterClosed()),
        first(),
        switchMap((modalResponse: ExportModalResponse) =>
          modalResponse?.closedByCancel
            ? of<void>()
            : this.exportOrderDetails(
                orderNumber,
                orderType,
                groupNumber,
                modalResponse,
              ),
        ),
        finalize(() => iconState$.next(IconState.Enabled)),
      );
  }

  private exportOrderDetails(
    orderNumber: string,
    orderType: OrderType,
    groupNumber: string,
    exportModalResponse: ExportModalResponse,
  ): Observable<void> {
    return this.orderDetailsService
      .getOrderDetailsExportModel(orderNumber, orderType, groupNumber)
      .pipe(
        first(),
        switchMap((exportModel: OrderDetailsExportModel) => {
          const fileName = this.buildExportFileName(exportModel);
          const exportData = this.extractCsvData(
            exportModel,
            exportModalResponse.dataPoints,
          );
          const separator = this.localizationService.instant(csvFieldSeparator);
          switch (exportModalResponse.fileFormat) {
            case ExportFileType.CSV:
              return this.exportService.downloadCsv(
                exportData,
                fileName,
                separator,
              );
            case ExportFileType.EXCEL:
              return this.exportService.downloadXlsx(exportData, fileName);
            default:
              break;
          }
        }),
      );
  }

  private buildExportFileName(order: OrderDetailsExportModel): string {
    let fileName =
      this.localizationService.instant(
        ExportOrderDetailsTranslationKeys.Order,
      ) + '_';
    if (order?.orderType !== OrderType.DropShip) {
      fileName += `${order?.orderNumber}_`;
    }
    fileName += `${order?.customerArrivalDate}_`;
    if (order?.customerPurchaseOrder) {
      fileName +=
        this.localizationService.instant(
          ExportOrderDetailsTranslationKeys.PurchaseOrder,
        ) + `_${order?.customerPurchaseOrder}_`;
    }
    fileName += `${moment().format('YYYYMMDD_HHmm')}`;
    return fileName;
  }

  private extractCsvData(
    order: OrderDetailsExportModel,
    dataPoints: ExportModalDataPoint[],
  ) {
    const headerRow: { [key: string]: string } = {};
    dataPoints.forEach((column) => {
      headerRow[column.label] = this.localizationService.instant(column.label);
    });
    const items = order?.lines
      .reduce((acc, line) => {
        const qtyPerMasterSellUnit = getQtyPerMasterSellUnitFromMaterialInfo(
          line.material,
        );
        const exportLinesPerUnit = line.units.map((unit) =>
          this.createCsvRow(line, unit, qtyPerMasterSellUnit),
        );
        return acc.concat(exportLinesPerUnit);
      }, [])
      .map((line) => {
        const rowObject: { [key: string]: string } = {};
        dataPoints.forEach((dataPoint) => {
          rowObject[dataPoint.label] = line.get(dataPoint.label);
        });
        return rowObject;
      });
    items.unshift(headerRow);
    return items;
  }

  private createCsvRow(
    line: OrderDetailsLineExportModel,
    unit: UomExportLine,
    qtyPerMasterSellUnit: number,
  ): Map<string, string> {
    const weightUomDisplay = getWeightUom(unit.catchWeightUom);
    return new Map<string, string>([
      [
        ExportOrderDetailsTranslationKeys.ProductDescription,
        this.stringDefaulter.getString(
          line.material?.description,
          this.localizationService.currentLanguage,
        ),
      ],
      [ExportOrderDetailsTranslationKeys.ItemCode, line.materialNumber],
      [
        ExportOrderDetailsTranslationKeys.Unit,
        this.materialUnitsFacade.getMaterialUnitFromUomCode(
          { uomCode: unit.uom },
          false,
        ),
      ],
      [
        ExportOrderDetailsTranslationKeys.Price,
        formatCurrency(
          unit.productPrice,
          this.localizationService.currentLocale,
          '',
          'CAD',
          '1.2-2',
        ),
      ],
      [
        ExportOrderDetailsTranslationKeys.PriceUom,
        weightUomDisplay
          ? '/' + weightUomDisplay
          : '/' +
            this.materialUnitsFacade.getMaterialUnitFromUomCode(
              { uomCode: unit.uom },
              false,
            ),
      ],
      [
        ExportOrderDetailsTranslationKeys.CatchWeight,
        this.localizationService.instant(
          line.isCatchWeight
            ? ExportOrderDetailsTranslationKeys.AffirmativeResponse
            : ExportOrderDetailsTranslationKeys.NegativeResponse,
        ),
      ],
      [
        ExportOrderDetailsTranslationKeys.QuantityOrdered,
        unit.quantityOrdered + '',
      ],
      [
        ExportOrderDetailsTranslationKeys.QuantityShipped,
        unit.quantityShipped ? unit.quantityShipped + '' : '0',
      ],
      [
        ExportOrderDetailsTranslationKeys.Total,
        unit.total
          ? formatCurrency(
              unit.total,
              this.localizationService.currentLocale,
              '',
              'CAD',
              '1.2-2',
            )
          : '0',
      ],
      [
        ExportOrderDetailsTranslationKeys.Category,
        line.category
          ? LocalizedUtilities.getLocalizedStringValue(
              line.category,
              this.localizationService.currentLanguage,
            ) + ''
          : '',
      ],
      [
        ExportOrderDetailsTranslationKeys.NetWeight,
        line.material?.baseUomWeight?.net,
      ],
      [
        ExportOrderDetailsTranslationKeys.PackSize,
        qtyPerMasterSellUnit ? qtyPerMasterSellUnit + '' : '',
      ],
      [
        ExportOrderDetailsTranslationKeys.UnitSize,
        line.material?.innerPackSize,
      ],
      [
        ExportOrderDetailsTranslationKeys.Brand,
        line.material?.brand
          ? LocalizedUtilities.getLocalizedStringValue(
              line.material.brand,
              this.localizationService.currentLanguage,
            ) + ''
          : '',
      ],
    ]);
  }
}
