@if (!!orderGuide) {
  @if (isShowingMobileActions && isMobile) {
    <naoo-guides-mobile-actions-list
      [sideBar]="orderGuide.sideBar"
      [preferredView]="orderGuide.preferredView"
      [isOffline]="orderGuide.isOffline"
      (closeMobileActions)="toggleMobileActions()"
      (sortByChange)="handleSortByChanged($event)"
    ></naoo-guides-mobile-actions-list>
  } @else {
    <div class="order-guide-wrapper">
      <mat-drawer-container>
        <mat-drawer
          (openedChange)="drawerStateChangeCompleted()"
          [opened]="!!orderGuide && sideNavOpen"
          mode="side"
          [style.marginTop.px]="headerHeight$ | async"
          [ngClass]="{ closed: !sideNavOpen }"
        >
          <naoo-guides-side-bar
            [sideBar]="orderGuide.sideBar"
            [isOffline]="orderGuide.isOffline"
            (scrollToCategoryClick)="handleScrollToCategory($event)"
            (sortByChange)="handleSortByChanged($event)"
          ></naoo-guides-side-bar>
        </mat-drawer>
        <mat-drawer-content>
          @if (!isMobile) {
            <button
              class="drawer-button"
              [ngClass]="{ 'drawer-closed': drawerClosedAndFinishedAnimating }"
              (click)="toggleDrawer()"
              attr.aria-label="{{ expansionAriaText | translate }}"
            >
              <mat-icon
                svgIcon="arrow-v2"
                [ngClass]="{ 'closed-rotation': !sideNavOpen }"
                class="arrow-icon"
              ></mat-icon>
            </button>
          }
          <div class="order-guide-content">
            <naoo-guides-header
              class="guides-header"
              [guideHeader]="orderGuide.header"
              [preferredView]="orderGuide.preferredView"
              [categoryHeaders]="orderGuide.sideBar.categoryHeaders"
              [isOffline]="orderGuide.isOffline"
              [printInput$]="printInput$"
              [exportInput$]="exportInput$"
              (openMobileActionsModal)="toggleMobileActions()"
              (scrollToCategory)="handleScrollToCategory($event)"
              (updateSearchText)="handleUpdateSearchText($event)"
              (updateMaterialView)="handleUpdateMaterialView($event)"
            >
              <div class="guide-header">
                <span class="guide-title">{{
                  orderGuide.header.name | stringdefaulter
                }}</span>
                <span class="guide-material-count">
                  ({{ orderGuide.header.count }}
                  {{ 'ORDER_GUIDE.PRODUCTS' | translate }})
                </span>
                @if (isEditOrderGuidePageAccessible$ | async) {
                  <span class="edit-guide">
                    <button
                      class="secondary-button large-button edit-guide-button"
                      attr.aria-label="{{
                        'LISTS.EDIT_ORDER_GUIDE' | translate
                      }}"
                      (click)="editOrderGuide()"
                      [ngClass]="{ disabled: orderGuide.isOffline }"
                      [disabled]="orderGuide.isOffline"
                    >
                      {{ 'LISTS.EDIT_ORDER_GUIDE' | translate }}
                    </button>
                  </span>
                }
              </div>
              @if (shouldRenderOrderGuideChanges) {
                <div class="order-guide-changes">
                  {{
                    'ORDER_GUIDE.ORDER_GUIDE_CHANGES.CHANGES_MESSAGE'
                      | translate
                  }}
                  <button
                    class="order-guide-changes-link"
                    tabindex="0"
                    (click)="openGuideChangesModal()"
                  >
                    {{
                      'ORDER_GUIDE.ORDER_GUIDE_CHANGES.CHANGES_MESSAGE_LINK_TEXT'
                        | translate
                    }}
                  </button>
                </div>
              }
            </naoo-guides-header>
            @if (shouldRenderList) {
              <div class="list-content">
                <naoo-material-list
                  #materialList
                  [materialListItems]="orderGuide.materialListRows"
                  [context]="listContext"
                  [listStyle]="orderGuide.preferredView"
                ></naoo-material-list>
              </div>
            } @else if (isFiltering) {
              <naoo-no-results
                [searchText]="orderGuide.header.actionBar.searchText"
              ></naoo-no-results>
            }
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  }
}
