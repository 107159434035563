import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomerBrand } from '../../core/services/session/models/session-record';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../pipes/naoo-date.pipe';
import { NaooBrandPipe } from '../pipes/naoo-brand.pipe';

@Component({
  selector: 'naoo-print-header',
  templateUrl: './print-header.component.html',
  styleUrls: ['./print-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, NaooDatePipe, NaooBrandPipe],
})
export class PrintHeaderComponent {
  @Input() customerBrand: CustomerBrand;

  todaysDate: number = Date.now();
}
