import { createSelector } from '@ngrx/store';
import { CostSummary, CostSummaryContext } from './cost-summary';
import {
  selectCartAvailability,
  selectCartCountsForDropShip,
  selectCartProductInfo,
  selectDropShipOrders,
  selectPoNumber,
  selectStoreFulfillment,
} from 'src/app/core/store/cart/cart.selectors';
import { CostSummaryTransformer } from './cost-summary-transformer';
import { selectCurrentDeliverySchedule } from 'src/app/core/store/delivery-schedule/delivery-schedule.selectors';
import { selectSession } from '../../core/store/session/session.selectors';
import { MaterialAvailabilityRecordState } from '../../core/store/material-availability/material-availability.state';
import { MaterialInfoRecordState } from '../../core/store/material-info/material-info.state';
import { selectIsOffline } from '../../core/store/offline-mode/offline-mode.selectors';
import {
  CartPriceTotals,
  selectCartPriceTotals,
} from '../../core/store/material-price/material-price.selectors';
import { StoreFulfillment } from '../../core/services/cart/models/cart-record';
import {
  selectCartReviewStoreCountsContext,
  selectCreditWidgetLoadingState,
  selectFulfillmentStateIsLoaded,
  selectIsSelectedFulfillmentValidForSubmission,
  selectSelectedCreditCardOption,
} from '../../core/store/cart-review/cart-review.selectors';
import { CreditWidgetLoadingState } from '../../core/store/cart-review/cart-review.state';

export interface CartContext {
  poNumber: string;
  availabilities: MaterialAvailabilityRecordState[];
  productInfos: MaterialInfoRecordState[];
  cartPriceTotals: CartPriceTotals;
  storeFulfillment: StoreFulfillment;
  isSelectedFulfillmentValidForSubmission: boolean;
  isSelectedFulfillmentLoaded?: boolean;
  creditWidgetLoadingState?: CreditWidgetLoadingState;
  payWithCreditSelected?: boolean;
}

const selectCartContext = createSelector(
  selectPoNumber,
  selectCartAvailability,
  selectCartProductInfo,
  selectCartPriceTotals(),
  selectStoreFulfillment,
  selectIsSelectedFulfillmentValidForSubmission,
  selectFulfillmentStateIsLoaded,
  selectCreditWidgetLoadingState,
  selectSelectedCreditCardOption,
  (
    poNumber,
    availabilities,
    productInfos,
    cartPriceTotals,
    storeFulfillment,
    isSelectedFulfillmentValidForSubmission,
    isSelectedFulfillmentLoaded,
    creditWidgetLoadingState,
    payWithCreditSelected,
  ): CartContext => ({
    poNumber,
    availabilities,
    productInfos,
    cartPriceTotals,
    storeFulfillment,
    isSelectedFulfillmentValidForSubmission,
    isSelectedFulfillmentLoaded,
    creditWidgetLoadingState,
    payWithCreditSelected,
  }),
);

export const selectCostSummary = (context: CostSummaryContext) =>
  createSelector(
    selectSession,
    selectCurrentDeliverySchedule,
    selectCartContext,
    selectIsOffline,
    selectDropShipOrders,
    selectCartCountsForDropShip,
    selectCartReviewStoreCountsContext,
    (
      session,
      currentDeliverySchedule,
      cartContext,
      isOffline,
      splitOrders,
      dropShipTotals,
      storeCountsContext,
    ): CostSummary =>
      CostSummaryTransformer.transformCostSummary(
        context,
        session,
        currentDeliverySchedule,
        cartContext,
        isOffline,
        splitOrders,
        dropShipTotals,
        storeCountsContext,
      ),
  );
