import { inject } from '@angular/core';

import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { mergeMap, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DefaultDialogService } from '../services/dialog/default-dialog/default-dialog.service';
import { CanDeactivateFn } from '@angular/router';

export const editOrderGuideRedirectGuard: CanDeactivateFn<
  any
> = (): Observable<boolean> => {
  const orderGuideFacade = inject(OrderGuideFacade);
  const defaultDialogService = inject(DefaultDialogService);

  return orderGuideFacade.hasUnsavedChanges().pipe(
    first(),
    mergeMap((hasUnsavedChanges) => {
      let shouldContinueRedirect = true;
      if (hasUnsavedChanges) {
        return defaultDialogService
          .twoButtonModal(
            'unsaved-changes',
            'LISTS.SAVE_AND_EXIT.MESSAGE',
            'LISTS.SAVE_AND_EXIT.LEAVE',
            'LISTS.SAVE_AND_EXIT.STAY',
            () => {
              orderGuideFacade.reloadOrderGuide();
            },
            () => {
              shouldContinueRedirect = false;
            },
            true,
          )
          .afterClosed()
          .pipe(
            first(),
            map(() => shouldContinueRedirect),
          );
      } else {
        return of(shouldContinueRedirect);
      }
    }),
  );
};
