import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActiveCartSummary } from '../shared/models/active-cart-summary';
import { PoInputComponent } from '../../../po-input/po-input.component';
import { SelectOrderMethodWidgetComponent } from '../../../../select-order-method-widget/select-order-method-widget/select-order-method-widget.component';
import { DeliveryScheduleWidgetComponent } from '../../../../delivery-schedule-widget/delivery-schedule-widget/delivery-schedule-widget.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-active-cart-header',
  templateUrl: './active-cart-header.component.html',
  styleUrls: ['./active-cart-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PoInputComponent,
    SelectOrderMethodWidgetComponent,
    DeliveryScheduleWidgetComponent,
    TranslateModule,
  ],
})
export class ActiveCartHeaderComponent {
  @Input() activeCartSummary: ActiveCartSummary;
  @Input() hasIspuOrExpressPermissions: boolean;
  @Output() updatedPoNumber = new EventEmitter<string>();
}
