import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Localized } from '../../../../shared/models/localized';
import { SearchResultSet } from '../../../../core/store/search/shared/search-results';
import { SearchFacade } from '../../../../core/store/search/search.facade';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-search-text',
  templateUrl: './search-text.component.html',
  styleUrls: ['./search-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule, NaooStringDefaulterPipe],
})
export class SearchTextComponent {
  @Input() resultSet: SearchResultSet;
  @Input() searchText: Localized<string>;
  @Input() isOrderGuideTab: boolean;
  @Input() isMobile: boolean;

  constructor(private readonly searchFacade: SearchFacade) {}

  handleRedirectToAllSearch(): void {
    this.searchFacade.toggleOrderGuideFilter(false);
  }

  get searchName(): Localized<string> {
    const suggestedText = this.resultSet.suggestedText;
    return suggestedText
      ? { en: suggestedText, fr: undefined, es: undefined }
      : this.searchText;
  }

  get titleHeaderCopy(): string {
    return this.resultSet.totalResults === 1
      ? 'SEARCH.RESULT_FOR_SINGULAR'
      : 'SEARCH.RESULTS_FOR_PLURAL';
  }
}
