@if (showSponsoredMaterial) {
  <div
    class="sponsored-material"
    [ngClass]="isGrid ? 'sponsored-grid-view' : 'sponsored-list-view'"
  >
    {{ 'LISTS.SEARCH.SPONSORED_PRODUCT' | translate }}
  </div>
}
@if (isLinkDisabled) {
  <div
    class="description multi-line-clamp disable-link"
    [ngClass]="{
      mobile: isMobile,
      slim: isSlim,
      'grid-description': isGrid || isSlimGrid,
      complimentary: isComplimentaryProduct,
    }"
  >
    {{ materialInfo?.description | stringdefaulter }}
  </div>
} @else {
  <a
    class="description multi-line-clamp"
    [ngClass]="{
      mobile: isMobile,
      slim: isSlim,
      'grid-description': isGrid || isSlimGrid,
      complimentary: isComplimentaryProduct,
    }"
    (click)="handleDetailsClick()"
    (keyup.enter)="handleDetailsClick()"
    [routerLink]="detailsLink"
    [state]="{
      analytics: materialRowOptions.analytics,
      context: materialRowOptions.context,
    }"
    [matTooltip]="materialInfo?.description | stringdefaulter"
    [matTooltipShowDelay]="500"
    [matTooltipDisabled]="isMobile"
  >
    {{ materialInfo?.description | stringdefaulter }}
  </a>
}
@if (!isComplimentaryProduct) {
  <div
    class="details"
    [ngClass]="{ 'multi-line-clamp': isMobile || isGrid || isSlimGrid }"
  >
    @if (!!materialRowOptions.customerMaterialNumber) {
      {{ materialRowOptions.customerMaterialNumber }} :
    }
    {{ pipeDelimitedDetails }}
  </div>
}
