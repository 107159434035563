import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { NaooConstants } from '../../../../shared/NaooConstants';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowInfo,
  MaterialRowSharedOptions,
} from '../../../../core/store/material-row/models/material-row';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { PackSizePipe } from '../../../../shared/pipes/pack-size.pipe';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-info',
  templateUrl: './material-info.component.html',
  styleUrls: ['./material-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    MatTooltip,
    NaooStringDefaulterPipe,
    TranslateModule,
  ],
})
export class MaterialInfoComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() materialInfo: MaterialRowInfo;
  @Input() isMobile: boolean;

  @Output() descriptionClicked = new EventEmitter();

  @HostBinding('class.mobile') get mobile() {
    return this.isMobile;
  }

  @HostBinding('class.slim') get slim() {
    return this.isSlim;
  }

  @HostBinding('class.grid') get grid() {
    return this.isGrid;
  }

  @HostBinding('class.slim-grid') get slimGrid() {
    return this.isSlimGrid;
  }

  @HostBinding('class.recommended') get gridRecommended() {
    return this.isGrid && this.showSponsoredMaterial;
  }

  @HostBinding('class.full-width') get isFullWidth() {
    return [
      MaterialRowContext.CartReview,
      MaterialRowContext.CriticalItem,
      MaterialRowContext.Substitutes,
    ].includes(this.materialRowOptions.context);
  }

  @HostBinding('class.complimentary') get complimentary() {
    return this.isComplimentaryProduct;
  }

  private readonly materialDetailsPath = NaooConstants.PRODUCT_DETAILS_PATH;

  constructor(
    private searchFacade: SearchFacade,
    private stringDefaulterPipe: NaooStringDefaulterPipe,
    private packSizePipe: PackSizePipe,
  ) {}

  get isLinkDisabled(): boolean {
    return (
      this.materialRowOptions.isOffline ||
      this.materialRowOptions.context === MaterialRowContext.Substitutes
    );
  }

  get isSlim(): boolean {
    return this.materialRowOptions.materialListStyle === MaterialListStyle.Slim;
  }

  get isGrid(): boolean {
    return this.materialRowOptions.materialListStyle === MaterialListStyle.Grid;
  }

  get isSlimGrid(): boolean {
    return (
      this.materialRowOptions.materialListStyle === MaterialListStyle.SlimGrid
    );
  }

  get detailsLink(): string {
    return `${this.materialDetailsPath}/${this.materialRowOptions.materialNumber}`;
  }

  get pipeDelimitedDetails(): string {
    // combine all necessary delimited material info, filtering for empty elements.
    // assuming material number can/should never be empty.
    return [
      '#' + this.materialRowOptions.materialNumber,
      this.stringDefaulterPipe.transform(this.materialInfo?.brand),
      this.packSizePipe.transform(
        this.materialInfo?.units,
        this.materialInfo?.innerPackSize,
      ),
    ]
      .filter((value) => value?.trim().length > 0)
      .join(' | ');
  }

  get showSponsoredMaterial() {
    return (
      this.materialRowOptions.isSponsoredMaterial &&
      this.materialRowOptions.isSponsoredMaterialView
    );
  }

  get isComplimentaryProduct() {
    return (
      this.materialRowOptions.context ===
      MaterialRowContext.ComplimentaryProduct
    );
  }

  handleDetailsClick(): void {
    this.descriptionClicked.emit();
    if (this.materialRowOptions.context === MaterialRowContext.Browse) {
      this.searchFacade.searchMaterialClick(
        this.materialRowOptions.materialNumber,
      );
    }
  }
}
