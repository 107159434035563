import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CartActionBarButton } from '../model/cart-action-bar';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

interface ButtonView {
  id: string;
  disabled: boolean;
  icon: string;
  text: string;
  ariaLabel: string;
  clicked: (event: Event) => void;
}

@Component({
  selector: 'naoo-cart-action-bar-menu',
  templateUrl: './cart-action-bar-menu.component.html',
  styleUrls: ['./cart-action-bar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class CartActionBarMenuComponent {
  @Input() buttons: CartActionBarButton[];
  @Input() hasNoMaterials: boolean;
  @Input() hasOrderableMaterials: boolean;
  @Input() isOffline: boolean;
  @Output() emptyCartClick = new EventEmitter<void>();
  @Output() printClick = new EventEmitter<void>();
  @Output() saveCartClick = new EventEmitter<void>();

  get viewButtons() {
    return this.buttons
      .map((id) => this.getButtonView(id))
      .filter((button) => !!button);
  }

  private getButtonView(buttonId: CartActionBarButton): ButtonView {
    switch (buttonId) {
      case CartActionBarButton.Print:
        return {
          id: buttonId,
          disabled: !this.hasOrderableMaterials,
          icon: 'print_v2',
          text: 'ACTION.PRINT',
          ariaLabel: 'ACTION.PRINT',
          clicked: () => {
            this.printClick.emit();
          },
        };
      case CartActionBarButton.SaveCart:
        return {
          id: buttonId,
          disabled: this.isOffline || !this.hasOrderableMaterials,
          icon: 'move-to-icon',
          text: 'CART.MOVE_TO_SAVED_ABBREVIATED',
          ariaLabel: 'CART.MOVE_TO_SAVED',
          clicked: () => {
            this.saveCartClick.emit();
          },
        };
      case CartActionBarButton.EmptyCart:
        return {
          id: buttonId,
          disabled: this.hasNoMaterials,
          icon: 'trash-icon-v3',
          text: 'CART.EMPTY_CART_MENU',
          ariaLabel: 'CART.EMPTY_CART_MENU',
          clicked: () => {
            this.emptyCartClick.emit();
          },
        };
    }
  }
}
