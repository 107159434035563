import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebBffService } from '../web-bff/web-bff.service';
import { Observable } from 'rxjs';
import { CancelOrderRecord } from './models/edit-order-record';
import { OrderType } from '../../models/order-type';

export interface EditOrderRequest {
  orderNumber: string;
  groupNumber: string;
  orderType: OrderType;
  materials: EditOrderRequestMaterial[];
}

export interface CancelOrderRequest {
  orderId: string;
  groupNumber: string;
}

export interface EditOrderMaterial {
  orderNumber: string;
  details: EditOrderLineDetail[];
}

export enum EditStatus {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

interface EditOrderLineDetail {
  materialNumber: string;
  orderLineId: string;
  editStatus: EditStatus;
}

interface EditOrderRequestMaterial {
  materialNumber: string;
  quantity: number;
  orderLineId: string;
}

@Injectable({
  providedIn: 'root',
})
export class EditOrderService {
  static readonly EDIT_ORDER_ENDPOINT = '/api/v1/orders/edit';
  static readonly CANCEL_ORDER_ENDPOINT = '/api/v1/orders/cancel';

  constructor(
    private httpClient: HttpClient,
    private webBffService: WebBffService,
  ) {}

  saveEditOrder(
    editOrderRequest: EditOrderRequest,
  ): Observable<EditOrderMaterial> {
    return this.httpClient.post<EditOrderMaterial>(
      this.webBffService.getBff() + EditOrderService.EDIT_ORDER_ENDPOINT,
      editOrderRequest,
    );
  }

  cancelOrder(
    cancelOrderRequest: CancelOrderRequest,
  ): Observable<CancelOrderRecord> {
    return this.httpClient.post<CancelOrderRecord>(
      this.webBffService.getBff() + EditOrderService.CANCEL_ORDER_ENDPOINT,
      cancelOrderRequest,
    );
  }
}
