<div class="select-date-step">
  <div class="calendar-wrapper">
    @if (isMobileWidth && isRouteDateExpired) {
      <ng-container [ngTemplateOutlet]="expiredRoute"></ng-container>
    }
    <naoo-select-date-step-calendar
      [fulfillmentType]="fulfillmentType"
      [availableDates]="availableDates"
      [selectedDate]="selectedDate"
      (dateChangeEmitter)="dateChangeEmitter.emit($event)"
    >
    </naoo-select-date-step-calendar>
  </div>
  <div class="store-details-wrapper">
    @if (!isMobileWidth && isRouteDateExpired) {
      <ng-container [ngTemplateOutlet]="expiredRoute"></ng-container>
    }

    @if (!isMobileWidth) {
      <naoo-store-display
        [storeRecord]="storeDetailsViewModel?.storeRecord"
        [customerTimeZone]="customerTimeZone"
        [isMobileWidth]="isMobileWidth"
      ></naoo-store-display>
    }

    <div class="pickup-details" [ngClass]="{ 'extra-padding': !isSameDay }">
      @if (!isMobileWidth) {
        <div class="pickup-header">
          {{ 'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.PICKUP_DATE' | translate }}
        </div>
      }
      <div>
        {{
          storeDetailsViewModel?.storeRecord
            | naooPickupDate: selectedDate
            | async
        }}
      </div>
      @if (isSameDay) {
        <div class="pickup-hours-text">
          {{
            'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.PICKUP_TWO_HOURS' | translate
          }}
        </div>
      }
    </div>
  </div>
</div>

<ng-template #expiredRoute>
  <div class="invalid-date-text">
    {{
      'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_PICKUP_MESSAGE'
        | translate
    }}
  </div>
</ng-template>
