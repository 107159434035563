import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { SortByType } from '../../guides';
import { MatFormField } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-sort-by',
  templateUrl: './guides-sort-by.component.html',
  styleUrls: ['./guides-sort-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormField, MatSelectModule, TranslateModule],
})
export class GuidesSortByComponent {
  @Input() selectedSortBy: SortByType;
  @Input() sortByOptions: SortByType[] = [];

  @Output() sortByChange = new EventEmitter<SortByType>();

  displayKeyForSortBy(sortByType: SortByType): string {
    switch (sortByType) {
      case SortByType.Custom:
        return 'LISTS.SORT_ITEMS_BY.CUSTOM_OPTION';
      case SortByType.Description:
        return 'LISTS.SORT_ITEMS_BY.DESCRIPTION_OPTION';
      case SortByType.Brand:
        return 'LISTS.SORT_ITEMS_BY.BRAND_OPTION';
      case SortByType.ItemCode:
        return 'LISTS.SORT_ITEMS_BY.ITEM_CODE_OPTION';
      case SortByType.OrderDate:
        return 'LISTS.SORT_ITEMS_BY.ORDER_DATE';
    }
  }

  changeSort($event: MatSelectChange) {
    this.sortByChange.emit($event.value);
  }
}
