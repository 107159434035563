<div class="select-date-step">
  <div class="calendar-wrapper">
    @if (isMobileWidth && isRouteDateExpired) {
      <ng-container [ngTemplateOutlet]="expiredRoute"></ng-container>
    }
    <naoo-select-date-step-calendar
      [fulfillmentType]="fulfillmentType"
      [availableDates]="availableDates"
      [selectedDate]="selectedDate"
      (dateChangeEmitter)="dateChangeEmitter.emit($event)"
      (tabPressedEmitter)="tabToTimeSelection()"
    >
    </naoo-select-date-step-calendar>
  </div>
  <div class="calendar-details">
    @if (!isMobileWidth && isRouteDateExpired) {
      <ng-container [ngTemplateOutlet]="expiredRoute"></ng-container>
    }
    <div class="calendar-body">
      @if (selectedExpressDeliveryWindow) {
        <div class="window-title" id="available-delivery-windows">
          {{ 'ORDER_METHOD_MODAL.DATE.FORM.DELIVERY_WINDOW' | translate }}
        </div>
        <mat-radio-group
          aria-labelledby="available-delivery-windows"
          class="delivery-windows"
        >
          @for (
            expressDeliveryWindow of availableExpressDeliveryWindows;
            track expressDeliveryWindow.storeDeliveryScheduleId
          ) {
            <mat-radio-button
              #timeFocus
              class="delivery-window"
              [checked]="
                selectedExpressDeliveryWindow === expressDeliveryWindow
              "
              [value]="expressDeliveryWindow"
              (change)="selectTime($event)"
            >
              <span class="delivery-time-info">
                <div>
                  {{
                    expressDeliveryWindow.deliveryWindowStart
                      | naooTimeRange
                        : expressDeliveryWindow.deliveryWindowEnd
                        : customerTimeZone
                  }}
                </div>
                @if (selectedExpressDeliveryWindow === expressDeliveryWindow) {
                  <div class="deadline-header">
                    {{ 'ORDER_METHOD_MODAL.DATE.FORM.DEADLINE' | translate }}
                  </div>
                  {{
                    expressDeliveryWindow.orderEntryCutoff
                      | naooDate
                        : 'monthDayTime'
                        : undefined
                        : undefined
                        : customerTimeZone
                  }}
                }
              </span>
            </mat-radio-button>
          }
        </mat-radio-group>
      }
    </div>
  </div>
</div>

<ng-template #expiredRoute>
  <div class="invalid-date-text">
    {{ 'ORDER_METHOD_MODAL.DATE.FORM.DATE_PICKER_EXPIRED_MESSAGE' | translate }}
  </div>
</ng-template>
