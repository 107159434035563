<div
  class="content"
  [ngClass]="{
    'border-right':
      unselectedDataPoints.length >
      totalDataPoints - unselectedDataPoints.length,
    disabled: disabled,
    highlighted: highlighted,
  }"
>
  <div class="header">
    <div class="title">
      {{
        (isPrintModal
          ? 'EXPORT_MODAL.AVAILABLE_COLUMNS'
          : 'EXPORT_MODAL.AVAILABLE_DATA'
        )
          | translate
            : {
                selected: translatedDataPoints.length,
                total: totalDataPoints,
              }
      }}
    </div>
    @if (!isPrintModal) {
      <button
        class="add-all-button"
        type="button"
        (click)="selectAll()"
        [attr.aria-label]="'EXPORT_MODAL.ADD_ALL' | translate"
        [disabled]="disabled"
      >
        {{ 'EXPORT_MODAL.ADD_ALL' | translate }}
      </button>
    }
  </div>
  @for (data of translatedDataPoints; track data.name) {
    <div class="row">
      <div class="name">
        {{ data.name }}
      </div>
      <button
        class="chevron-btn"
        (click)="select(data.dataPoint)"
        [attr.aria-label]="
          data.name + ', ' + ('EXPORT_MODAL.ADD_DATA' | translate)
        "
      ></button>
    </div>
  }
</div>
