<div class="print-header">
  <div class="logo">
    <img
      src="{{ 'LOGO.URL' | naoobrand: customerBrand | translate }}"
      alt="{{ 'LOGO.TEXT' | naoobrand: customerBrand | translate }}"
    />
  </div>
  <div class="date">
    {{ todaysDate | naooDate: 'shortDate' }}
  </div>
</div>
