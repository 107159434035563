<div class="complete-modal-container">
  <div class="icon-container">
    <img
      class="complete-icon"
      src="{{ successIconPath }}"
      alt="{{ imageAltText }}"
    />
  </div>
  <h2 class="title" [innerHTML]="title"></h2>
  @if (subtitle) {
    <div class="subtitle">{{ subtitle }}</div>
  }
  <button
    attr.aria-label="{{ completeButtonMessage }}"
    class="primary-button large-button"
    (click)="onComplete()"
  >
    {{ completeButtonMessage }}
  </button>
</div>
