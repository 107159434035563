<div class="cart-review-default-header">
  <div class="sub-header-keys">
    @if (section.subHeaderKeys?.length === 1) {
      <span class="single-sub-header-key">{{
        section.subHeaderKeys[0].key | translate
      }}</span>
    } @else {
      <ul>
        @for (subHeader of section.subHeaderKeys; track subHeader.key) {
          <li class="multiple-sub-header-key">
            {{ subHeader.key | translate }}
            @if (!!subHeader.link) {
              <a
                [routerLink]="subHeader.link.route"
                [queryParams]="subHeader.link.params"
                >{{ subHeader.link.key | translate }}</a
              >
            }
          </li>
        }
      </ul>
    }
  </div>
  @if (!!section.routeDate) {
    <div class="sub-header-extra-details">
      <div class="content-details-section">
        <div id="route-date-title" class="header-title">
          {{ 'CART_REVIEW_SUB_HEADER_DATE_ON' | deliveryOrShip | translate }}
        </div>
        <div id="route-date" class="header-content">
          @if (section.routeDateKey) {
            <span>{{
              section.routeDateKey
                | translate
                  : {
                      date:
                        section.routeDate
                        | naooDate: 'longDateNoDayAbbrMo' : 'UTC',
                    }
            }}</span>
          } @else {
            {{ section.routeDate | naooDate: 'longDateNoDayAbbrMo' : 'UTC' }}
          }
        </div>
      </div>
      @if (!!section.poNumber) {
        <div class="po-number">
          <div class="header-title">
            {{ 'CART_REVIEW.SUB_HEADER.PO' | translate }}
          </div>
          <div class="header-content">{{ section.poNumber }}</div>
        </div>
      }
    </div>
  }
</div>
