import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  MaterialRow,
  MaterialRowStatus,
} from '../../core/store/material-row/models/material-row';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import {
  OrderDetailLineStatus,
  OrderDetailsLineViewModel,
  UomLine,
} from '../../shared/models/order-details';
import { StatusSeverity } from '../../shared/models/status-severity';
import { Language } from '../../core/services/session/models/session-record';
import { NaooStringDefaulter } from '../../shared/string-defaulter/naoo-string-defaulter';
import { MaterialUnavailableComponent } from '../../material-list/material-row-container/shared/material-unavailable/material-unavailable.component';
import { MaterialImageComponent } from '../../material-list/material-row-container/shared/material-image/material-image.component';
import { MaterialInfoComponent } from '../../material-list/material-row-container/shared/material-info/material-info.component';
import { MatIconModule } from '@angular/material/icon';
import { MaterialFlagContainerComponent } from '../../material-list/material-row-container/shared/material-flag-container/material-flag-container.component';
import { MaterialOptionsMenuComponent } from '../../material-list/material-row-container/shared/material-options-menu/material-options-menu.component';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { NaooPricePipe } from '../../shared/pipes/naoo-price.pipe';
import { NaooDecimalPipe } from '../../shared/pipes/naoo-decimal.pipe';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-order-details-material-row-container',
  templateUrl: './order-details-material-row-container.component.html',
  styleUrls: ['./order-details-material-row-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MaterialUnavailableComponent,
    MaterialImageComponent,
    MaterialInfoComponent,
    MaterialFlagContainerComponent,
    MaterialOptionsMenuComponent,
    NgTemplateOutlet,
    NgClass,
    MatIconModule,
    MatTooltip,
    AsyncPipe,
    NaooPricePipe,
    NaooDecimalPipe,
    NaooStringDefaulterPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class OrderDetailsMaterialRowContainerComponent implements OnInit {
  @Input() materialNumber: string;
  @Input() orderLine: OrderDetailsLineViewModel;
  @Input() isEditOrderEnabled: boolean;
  @Input() currentLanguage: Language;
  @Input() isMobile: boolean;

  @Output() cancelClick = new EventEmitter<void>();
  @Output() editClick = new EventEmitter<void>();

  readonly tooltipShowDelay = 500;

  materialRow$: Observable<MaterialRow>;

  constructor(
    private materialRowFacade: MaterialRowFacade,
    private stringDefaulter: NaooStringDefaulter,
  ) {}

  ngOnInit(): void {
    this.materialRow$ = this.materialRowFacade
      .getMaterialRow({ materialNumber: this.materialNumber })
      .pipe(shareReplay(1));
  }

  getToolTipText(lineStatusDetail: OrderDetailLineStatus): string | undefined {
    return lineStatusDetail?.secondaryLineStatus?.[this.currentLanguage];
  }

  getToolTipTabIndex(lineStatusDetail: OrderDetailLineStatus): string {
    return this.getToolTipText(lineStatusDetail) ? '0' : '';
  }

  isUnavailable(status: MaterialRowStatus): boolean {
    return MaterialRowStatus.Unavailable === status;
  }

  isErrorSeverity(severity: StatusSeverity): boolean {
    return severity === StatusSeverity.Error;
  }

  isWarningOrErrorSeverity(severity: StatusSeverity): boolean {
    return [StatusSeverity.Error, StatusSeverity.Warning].includes(severity);
  }

  shouldShowStatusForAnyLine(lines: UomLine[]): boolean {
    return lines.some((line) => line.shouldShowLineStatus);
  }

  shouldShowStatusForMoreThanOneLine(lines: UomLine[]): boolean {
    return this.filterItemsThatShouldShowStatus(lines).length > 1;
  }

  filterItemsThatShouldShowStatus(lines: UomLine[]): UomLine[] {
    return lines.filter((line) => line.shouldShowLineStatus);
  }

  getSubstitutedItemDescription() {
    const description = this.stringDefaulter.getString(
      this.orderLine?.substituteMaterial?.description,
      this.currentLanguage,
    );
    return `${description} | #${this.orderLine?.substituteMaterial?.materialNumber}`;
  }

  orderDetailLineStatus(uomLine: UomLine): OrderDetailLineStatus {
    return uomLine?.lineStatusDetails?.[0];
  }
}
