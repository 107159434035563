<div class="critical-items-section">
  <div class="critical-items-header">
    <mat-icon [ngClass]="icon.color" svgIcon="{{ icon.name }}"></mat-icon>
    <span>
      {{
        headerKey
          | translate: { productCount: criticalItems.length }
          | uppercase
      }}
    </span>
  </div>
  <div class="critical-items-subheader">
    {{ subHeaderKey | translate }}
  </div>
  <div class="panel-content">
    <naoo-material-list
      [context]="criticalItemsContext"
      [listStyle]="listStyle"
      [materialListItems]="allVisibleItems"
    ></naoo-material-list>
  </div>
</div>
@if (shouldDisplayExpansionToggle) {
  <div class="expansion-toggle">
    <button
      class="view-more"
      (click)="expansionToggle()"
      (keydown.enter)="expansionToggle()"
    >
      {{ expansionToggleText | translate }}
      <mat-icon
        svgIcon="arrow-v2"
        class="arrow"
        [ngClass]="expanded ? 'up-arrow' : 'down-arrow'"
      >
      </mat-icon>
    </button>
  </div>
}
