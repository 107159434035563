import { takeUntil } from 'rxjs/operators';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  MenuAction,
  MenuActionInfo,
} from '../../product-row/product-menu/menu-action';
import { naooAnimations } from '../animations/animations';
import { AnimationEvent } from '@angular/animations';
import {
  MatMenu,
  MenuPositionY,
  MatMenuContent,
  MatMenuItem,
  MatMenuTrigger,
} from '@angular/material/menu';
import { ListScrollService } from '../services/list-scroll/list-scroll.service';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { EntitlementFacade } from '../../core/store/entitlement/entitlement.facade';
import { CustomDialogService } from '../services/dialog/custom-dialog/custom-dialog.service';
import {
  MaterialRow,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import { DeviceIdentifierService } from '../services/device-identifier/device-identifier.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-add-to-guide-menu',
  templateUrl: './add-to-guide-menu.component.html',
  styleUrls: ['./add-to-guide-menu.component.scss'],
  animations: [naooAnimations.rotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    NgClass,
    MatIcon,
    MatMenuTrigger,
    AsyncPipe,
    TranslateModule,
  ],
})
export class AddToGuideMenuComponent
  implements OnDestroy, AfterViewInit, AfterViewChecked
{
  @ViewChild('popoverMenu') popoverMenu: ElementRef;
  @ViewChild('menu', { static: true }) private matMenu: MatMenu;

  @Input() isEmptyMaterial: boolean;
  @Input() isCommodityItem: boolean;
  @Input() isOrderGuideItem: boolean;
  @Input() menuTrigger: ElementRef;
  @Input() additionalMenuItems: MenuActionInfo[];
  @Input() yDirection: MenuPositionY = 'below';
  @Input() materialNumber: string;
  @Input() isCriticalItemsGuide = false;
  @Input() hideAddToCriticalItems = false;

  @Output() menuActionSelected = new EventEmitter<MenuActionInfo>();

  private menuAction: () => void;
  private MENU_ITEM_HEIGHT = 50;
  private MENU_ITEM_COUNT_BEFORE_SCROLL = 5;
  private MENU_MAX_HEIGHT =
    this.MENU_ITEM_HEIGHT * this.MENU_ITEM_COUNT_BEFORE_SCROLL;
  private didSubscribeToMenuItems = false;

  readonly maxScrollDelay = 150;

  isPanelExpanded = false;
  shouldShowSubMenu = false;
  private destroyed$ = new Subject<void>();
  rotationState = 'default';
  expandedMenuHeight = 0;
  createNewGuideMenuItem = {
    name: 'LISTS.CREATE_GUIDE',
    action: MenuAction.AddToNewCustomGuide,
  };
  submenuItems: MenuActionInfo[];
  isOffline = false;
  isOrderGuideEditable$: Observable<boolean>;
  materialRow: MaterialRow;
  isMobile: boolean;

  // eslint-disable-next-line max-params
  constructor(
    private customGuideFacade: CustomGuideFacade,
    private orderGuideFacade: OrderGuideFacade,
    private listScrollService: ListScrollService,
    private offlineModeFacade: OfflineModeFacade,
    private changeDetector: ChangeDetectorRef,
    private entitlementFacade: EntitlementFacade,
    private customDialogService: CustomDialogService,
    private deviceIdentifierService: DeviceIdentifierService,
    private materialRowFacade: MaterialRowFacade,
  ) {}

  ngAfterViewInit(): void {
    this.offlineModeFacade
      .getIsOffline()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isOffline) => {
        this.isOffline = isOffline;
        this.changeDetector.markForCheck();
      });

    this.isOrderGuideEditable$ = this.orderGuideFacade.isOrderGuideEditable();
  }

  ngAfterViewChecked(): void {
    this.subscribeToGuidesWhenMenuLoads();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  menu(): MatMenu {
    return this.matMenu;
  }

  expandAddToCustomGuide(event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    const panelWillBeVisible = !this.isPanelExpanded;
    let scrollDelay = 0;
    if (panelWillBeVisible) {
      this.shouldShowSubMenu = true;
      const didScroll = this.adjustScrollForMenuOpen();
      if (didScroll) {
        // if the browser needs to adjust it's scroll position
        // we should add a delay before we open the menu to wait
        // for that scroll operation to complete
        scrollDelay = this.maxScrollDelay;
      }
    }

    setTimeout(() => {
      if (this.isPanelExpanded) {
        this.closeExpansionItem();
      } else {
        this.openExpansionItem();
      }
      this.changeDetector.markForCheck();
    }, scrollDelay);
  }

  private subscribeToGuidesWhenMenuLoads() {
    // this code should only execute once - then this function is set to an empty fn
    if (this.didSubscribeToMenuItems) {
      return;
    }

    const popoverMenuHasLoaded =
      this.popoverMenu && this.popoverMenu.nativeElement;
    // should be invoked when the mat-menu loads the menu content
    // this should only happen when the user taps the button
    // to open the menu (e.g. clicking the triple dot menu button)
    if (popoverMenuHasLoaded) {
      setTimeout(() => {
        this.loadSubmenuItems();
      });

      this.didSubscribeToMenuItems = true;
    }
  }

  onMenuClosed() {
    this.closeExpansionItem();
    if (this.menuAction) {
      this.menuAction();
      this.menuAction = null;
    }
  }

  performMenuAction(menuItem: MenuActionInfo) {
    this.menuAction = () => {
      this.menuActionSelected.emit(menuItem);
    };
  }

  addToCriticalItemsGuideMenuAction() {
    this.menuAction = () => {
      this.menuActionSelected.emit({
        name: 'LISTS.ADD_TO_CRITICAL_ITEMS_GUIDE',
        action: MenuAction.AddToCriticalItemsGuide,
      });
    };
  }

  removeFromCriticalItemsGuideMenuAction() {
    this.menuAction = () => {
      this.menuActionSelected.emit({
        name: 'LISTS.REMOVE_PRODUCT_FROM_CRITICAL_ITEMS_GUIDE',
        action: MenuAction.RemoveItemFromCriticalItemGuide,
      });
    };
  }

  updateOrderGuideAction() {
    this.menuAction = () => {
      this.menuActionSelected.emit({
        name: this.isOrderGuideItem
          ? 'LISTS.REMOVE_FROM_ORDER_GUIDE'
          : 'LISTS.ADD_TO_ORDER_GUIDE',
        action: this.isOrderGuideItem
          ? MenuAction.RemoveFromOrderGuide
          : MenuAction.AddToOrderGuide,
      });
    };
  }

  viewCommodityDetails() {
    this.entitlementFacade.setSelectedMaterialNumber(this.materialNumber);
  }

  animationComplete(event: AnimationEvent) {
    if (this.rotationState === 'default' && event.fromState === '180') {
      this.shouldShowSubMenu = false;
    }
  }

  private closeExpansionItem() {
    this.isPanelExpanded = false;
    this.shouldShowSubMenu = false;
    this.rotationState = 'default';
    this.expandedMenuHeight = 0;
  }

  private openExpansionItem() {
    this.isPanelExpanded = true;
    this.rotationState = '180';
    this.expandedMenuHeight = this.computeMenuHeightForItemCount(
      this.getSubmenuItemCount(),
    );
  }

  private computeMenuHeightForItemCount(itemCount: number): number {
    // add one to account for default + Create New Guide option
    const count = 1 + itemCount;
    return count > this.MENU_ITEM_COUNT_BEFORE_SCROLL
      ? this.MENU_MAX_HEIGHT
      : this.MENU_ITEM_HEIGHT * count;
  }

  private loadSubmenuItems() {
    this.customGuideFacade
      .getAllLoadedSortedCustomGuides()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customGuides) => {
        const addSubmenuItems: MenuActionInfo[] = customGuides.map((guide) => ({
          id: guide.id,
          name: guide.name,
          action: MenuAction.AddToExistingCustomGuide,
        }));
        this.setSubMenuItems(addSubmenuItems);
      });
  }

  // menu repositioning
  private amountMenuWillBeOffScreen(willOpenUpwards: boolean): number {
    if (!this.popoverMenu || !this.popoverMenu.nativeElement) {
      return 0;
    }

    const sizeIncreaseUponExpansion = this.computeMenuHeightForItemCount(
      this.getSubmenuItemCount(),
    );

    const { top, bottom } =
      this.popoverMenu.nativeElement.getBoundingClientRect();

    if (willOpenUpwards) {
      const expandedTopPosition = top - sizeIncreaseUponExpansion;
      if (expandedTopPosition < 0) {
        return expandedTopPosition;
      }
    } else {
      const expandedBottomPosition = bottom + sizeIncreaseUponExpansion;
      if (expandedBottomPosition > window.innerHeight) {
        return expandedBottomPosition - window.innerHeight;
      }
    }

    return 0;
  }

  private menuDidOpenUpwards(): boolean {
    if (!this.popoverMenu || !this.popoverMenu.nativeElement) {
      return false;
    }
    const menuRect = this.popoverMenu.nativeElement.getBoundingClientRect();
    if (!this.menuTrigger || !this.menuTrigger.nativeElement) {
      return menuRect.top < 0;
    }

    const buttonRect = this.menuTrigger.nativeElement.getBoundingClientRect();
    return menuRect.top < buttonRect.top;
  }

  private adjustScrollForMenuOpen(): boolean {
    const willOpenUpwards = this.menuDidOpenUpwards();
    const overlap = this.amountMenuWillBeOffScreen(willOpenUpwards);
    const willScroll = overlap !== 0;
    if (willScroll) {
      const additionalPadding = willOpenUpwards ? -10 : 10;
      this.listScrollService.scrollByOptions({
        top: overlap + additionalPadding,
        behavior: 'smooth',
      });
    }
    return willScroll;
  }

  private setSubMenuItems(newItems: MenuActionInfo[]) {
    this.submenuItems = newItems;
    if (this.isPanelExpanded) {
      const count = newItems ? newItems.length : 0;
      this.expandedMenuHeight = this.computeMenuHeightForItemCount(count);
      this.changeDetector.markForCheck();
    }
  }

  private getSubmenuItemCount(): number {
    return this.submenuItems?.length ?? 0;
  }

  openSimilarItemsModal(): void {
    this.materialRowFacade
      .getMaterialRow({
        context: MaterialRowContext.Substitutes,
        materialNumber: this.materialNumber,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (materialRow: MaterialRow) => (this.materialRow = materialRow),
      );

    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.customDialogService.materialRelatedModal(
      undefined,
      this.materialRow,
      this.isMobile,
    );
  }
}
