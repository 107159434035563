@if (!!storeSection) {
  <div class="container-details-header">
    @if (hasStoreLocation) {
      <div class="content-details-container">
        <div class="content-details-section">
          <span class="location-title header-title">{{
            'CART_REVIEW.STORE_PICKUP_ITEMS.BANNER.STORE_LOCATION' | translate
          }}</span>
          <div class="store-location-content header-content">
            <span class="store-location">{{ storeLocation }}</span>
          </div>
        </div>
      </div>
    }
    <div class="content-details-container">
      <div class="content-details-section">
        <span class="quantity-title header-title">
          {{
            'CART_REVIEW.STORE_PICKUP_ITEMS.BANNER.ORDER_QUANTITY' | translate
          }}</span
        >
        <div class="header-content">
          <span class="quantity-lines">
            {{
              'CART_REVIEW.STORE_PICKUP_ITEMS.BANNER.LINES'
                | translate
                  : {
                      lines: storeSectionCartCounts.cartLineCount,
                    }
            }},
          </span>
          <span class="quantity-items">{{
            'CART_REVIEW.STORE_PICKUP_ITEMS.BANNER.ITEMS'
              | translate
                : {
                    items: storeSectionCartCounts.totalQuantity,
                  }
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="content-details-container"
      [ngClass]="{ 'hide-me': !shouldShowDate || !hasResolutionDate }"
    >
      <div class="content-details-section">
        <span class="route-date-title header-title">{{
          dateLabel | translate
        }}</span>
        <span class="route-date header-content">
          {{ storeDate }}
        </span>
      </div>
    </div>
  </div>
}
