import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SpecialOrderRowViewModel } from './special-order-row-view-model';
import { CONTENT_SCROLL } from '../../../shared/services/scrollable-content/scrollable-content.service';
import { BehaviorSubject, combineLatest, map, of, Subject } from 'rxjs';
import {
  ActionIconComponent,
  IconState,
} from '../../../shared/action-icon/action-icon.component';
import { PrintService } from '../../../shared/services/print/print.service';
import { SpecialOrdersFacade } from '../../../core/store/special-orders/special-orders.facade';
import { SpecialOrdersStatus } from '../../../core/store/special-orders/special-orders.state';
import { LoadingService } from 'src/app/shared/services/loading-service/loading.service';
import { SpecialOrderInfo } from '../../../core/store/special-orders/special-orders.selectors';
import { VirtualScrollerComponent } from '../../../vendor/ngx-virtual-scroller/virtual-scroller';
import { ErrorStateComponent } from '../../../shared/error-state/error-state/error-state.component';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { SpecialOrderMaterialRowContainerComponent } from './special-order-material-row-container.component';
import { NaooDatePipe } from '../../../shared/pipes/naoo-date.pipe';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { SearchFieldComponent } from '../../../shared/search-bar/search-field.component';
import { LocalizationService } from '../../../shared/services/translation/localization.service';
import { NaooBrandPipe } from '../../../shared/pipes/naoo-brand.pipe';

@Component({
  selector: 'naoo-special-order',
  templateUrl: './special-order.component.html',
  styleUrls: ['./special-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ErrorStateComponent,
    MatIcon,
    ActionIconComponent,
    NgTemplateOutlet,
    VirtualScrollerComponent,
    NgClass,
    SpecialOrderMaterialRowContainerComponent,
    AsyncPipe,
    NaooDatePipe,
    NaooStringDefaulterPipe,
    TranslateModule,
    SearchFieldComponent,
    NaooBrandPipe,
  ],
})
export class SpecialOrderComponent implements OnInit, OnDestroy {
  static readonly ANALYTICS_CATEGORY = 'special order summary';
  static readonly ANALYTICS_LABEL_VIEW_DETAILS = 'view details';
  static readonly ANALYTICS_ACTION_DISPLAYED = 'displayed';
  readonly SEARCH_QUERY = 'filter';
  @ViewChild('scroll', { static: false })
  virtualScroll: VirtualScrollerComponent<SpecialOrderRowViewModel>;
  specialOrderInfo: SpecialOrderInfo;
  shouldRenderPrint$: Subject<boolean> = new BehaviorSubject(false);
  iconState: IconState = IconState.Disabled;
  searchTerm = '';
  filteredSpecialOrderRowViewModels: SpecialOrderRowViewModel[] = [];

  private destroyed$ = new Subject<void>();
  searchField: SearchFieldComponent;

  constructor(
    private specialOrdersFacade: SpecialOrdersFacade,
    private changeDetector: ChangeDetectorRef,
    private printService: PrintService,
    private loadingService: LoadingService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly analytics: NaooAnalyticsManager,
    private readonly localizationService: LocalizationService,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  get hasSpecialOrdersError(): boolean {
    return (
      !!this.specialOrderInfo &&
      this.specialOrderInfo.status === SpecialOrdersStatus.Error
    );
  }

  ngOnInit() {
    this.loadingService.start();
    this.specialOrdersFacade.refreshSpecialOrders();

    combineLatest([
      this.specialOrdersFacade.getLoadedSpecialOrderInfo(),
      this.localizationService.language().pipe(distinctUntilChanged()),
    ])
      .pipe(
        takeUntil(this.destroyed$),
        map(([specialOrderInfo, _language]) => {
          specialOrderInfo.rowViewModels.forEach((row) => {
            this.specialOrdersFacade.generateSearchableStrings(row);
          });
          return specialOrderInfo;
        }),
      )
      .subscribe((specialOrderInfo) => {
        this.filteredSpecialOrderRowViewModels = specialOrderInfo.rowViewModels;
        this.specialOrderInfo = specialOrderInfo;
        this.iconState = IconState.Enabled;
        this.loadingService.stop();
        this.changeDetector.markForCheck();
      });

    this.printService.setUp(
      () => {
        this.shouldRenderPrint$.next(true);
        return of(true);
      },
      () => {
        this.shouldRenderPrint$.next(false);
      },
    );
    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.searchTerm = params[this.SEARCH_QUERY]
          ? params[this.SEARCH_QUERY]
          : '';
        this.filterOrders(this.searchTerm);
      });
  }

  ngOnDestroy(): void {
    this.loadingService.stop();
    this.printService.tearDown();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  compareViewModels(
    one: SpecialOrderRowViewModel,
    two: SpecialOrderRowViewModel,
  ): boolean {
    return !!(
      one &&
      two &&
      one.materialInfo &&
      two.materialInfo &&
      one.materialInfo.materialNumber === two.materialInfo.materialNumber
    );
  }

  onPrintIconClick(): void {
    this.printService.print();
  }

  trackByRowViewModel(rowViewModel: SpecialOrderRowViewModel) {
    return (
      rowViewModel.materialNumber +
      rowViewModel.requestedQuantity +
      rowViewModel.deliveryDate +
      rowViewModel.poNumber +
      rowViewModel.salesOrderNumber
    );
  }

  goToOrderDetails(routeParams: string[]) {
    this.trackSpecialOrderAnalytics(
      SpecialOrderComponent.ANALYTICS_LABEL_VIEW_DETAILS,
    );
    this.router.navigate(routeParams, {
      relativeTo: this.activatedRoute,
    });
  }

  filterOrders(value: string) {
    this.searchTerm = value;
    const filterByString = value ? value.trim().toLocaleLowerCase() : '';
    if (this.specialOrderInfo) {
      if (filterByString.length) {
        this.filteredSpecialOrderRowViewModels =
          this.specialOrderInfo.rowViewModels.filter((order) =>
            order.searchableStrings.some((field) =>
              field.includes(filterByString),
            ),
          );
      } else {
        this.filteredSpecialOrderRowViewModels =
          this.specialOrderInfo.rowViewModels;
      }
    }
    this.changeDetector.markForCheck();
  }

  updateSearchParam(value: string) {
    let matrixParams;
    if (value === '') {
      this.searchTerm = '';
      matrixParams = [{}];
    } else {
      this.searchTerm = value;
      matrixParams = [{ filter: this.searchTerm }];
    }
    this.router.navigate(matrixParams, {
      queryParamsHandling: 'preserve',
    });
  }

  private trackSpecialOrderAnalytics(eventLabel: string) {
    this.analytics.trackAnalyticsEvent({
      action: SpecialOrderComponent.ANALYTICS_ACTION_DISPLAYED,
      category: SpecialOrderComponent.ANALYTICS_CATEGORY,
      label: eventLabel,
    });
  }
}
