import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MarketingGuideFacade } from '../../core/store/marketing-guide/marketing-guide.facade';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { map, tap } from 'rxjs/operators';
import { NaooConstants } from '../../shared/NaooConstants';

export const marketingGuideResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Observable<string> => {
  const router = inject(Router);
  const defaultDialogService = inject(DefaultDialogService);
  const marketingGuideFacade = inject(MarketingGuideFacade);
  const pathUrl = route.paramMap.get('pathUrl');

  defaultDialogService.openLoadingModal();
  marketingGuideFacade.loadMarketingGuide(pathUrl);
  return marketingGuideFacade.getLoadedMarketingGuideByPathUrl(pathUrl).pipe(
    tap((record) => {
      defaultDialogService.closeLoadingModal();
      if (!record) {
        router.navigate([NaooConstants.HOME_PAGE_PATH]);
      }
    }),
    map((record) => record?.pathUrl),
  );
};
