export class EnumUtilities {
  /**
   * Due to the way enum's are created in typescript, if no value is defined (assigning the key
   * equal to something) the enum object is created with the enum values as indices (0, 1, etc..).
   * In this case those indices are created as BOTH keys and values that map back to each other
   *
   * example:
   * {code}
   * enum Counter {
   *   One,
   *   Two
   * }
   * {code}
   * Object.keys(Counter)   => [ "0", "1", "One", "Two" ]
   * Object.values(Counter) => [ "One", "Two", "0", "1" ]
   *
   * @param enumValue
   * @param enumObj
   */
  static enumFromValue<T, K extends keyof T>(
    enumValue: string,
    enumObj: T,
  ): T[keyof T] | undefined {
    const value = Object.keys(enumObj).find(
      (k) => String(enumObj[k as K]) === enumValue,
    );
    return enumObj[value as keyof typeof enumObj];
  }
}
