import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NotificationSubscriptionType } from '../../../../core/services/customer-notification-preferences/models/customer-notification-preferences-record';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-subscriptions-form',
  templateUrl: './subscriptions-form.component.html',
  styleUrls: ['./subscriptions-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCheckbox, TranslateModule],
})
export class SubscriptionsFormComponent implements OnInit {
  @Input() subscriptionTypes: NotificationSubscriptionType[];
  @Input() isValid: boolean;
  @Input() allValidNotificationSubscriptionTypes: string[];
  @Input() countryCode: string;
  @Output() subscriptionChanged = new EventEmitter<
    NotificationSubscriptionType[]
  >();
  currentSubscriptionTypes: NotificationSubscriptionType[];

  ngOnInit(): void {
    this.currentSubscriptionTypes = this.subscriptionTypes
      ? [...this.subscriptionTypes]
      : [];
  }

  getTranslationName(id: string): string {
    return `PREFERENCES.SMS_NOTIFICATIONS.SUBSCRIPTION_TYPES.${id}`;
  }

  getTranslationDescription(id: string): string {
    if (id == 'TRUCK_ETA_ON_DEMAND') {
      if (this.countryCode == 'US') {
        return `PREFERENCES.SMS_NOTIFICATIONS.SUBSCRIPTION_DESCRIPTIONS.TRUCK_ETA_ON_DEMAND_US`;
      } else {
        return `PREFERENCES.SMS_NOTIFICATIONS.SUBSCRIPTION_DESCRIPTIONS.TRUCK_ETA_ON_DEMAND_CA`;
      }
    }
    return `PREFERENCES.SMS_NOTIFICATIONS.SUBSCRIPTION_DESCRIPTIONS.${id}`;
  }

  toggleSubscription(event$: MatCheckboxChange): void {
    const id: string = event$.source.id;
    const option = id as keyof typeof NotificationSubscriptionType;
    this.updateSubscriptionOption(
      NotificationSubscriptionType[option],
      event$.checked,
    );

    this.subscriptionChanged.emit(this.currentSubscriptionTypes);
  }

  hasSubscription(id: string): boolean {
    const option =
      NotificationSubscriptionType[
        id as keyof typeof NotificationSubscriptionType
      ];
    return !!this.currentSubscriptionTypes.find((item) => item === option);
  }

  updateSubscriptionOption(
    option: NotificationSubscriptionType,
    value: boolean,
  ): void {
    if (this.currentSubscriptionTypes) {
      const index = this.currentSubscriptionTypes.findIndex(
        (item) => item === option,
      );
      if (value && index < 0) {
        this.currentSubscriptionTypes.push(option);
      } else if (!value && index >= 0) {
        this.currentSubscriptionTypes.splice(index, 1);
      }

      this.currentSubscriptionTypes.sort((a, b) =>
        a.toString().localeCompare(b.toString()),
      );
    }
  }
}
