import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../../core/store/material-row/models/material-row';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { MaterialListRow } from '../../../../material-list/models/material-list';
import { MatIcon } from '@angular/material/icon';
import { NgClass, UpperCasePipe } from '@angular/common';
import { MaterialListComponent } from '../../../../material-list/material-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-critical-items-content',
  templateUrl: './critical-items-content.component.html',
  styleUrls: ['./critical-items-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    NgClass,
    MaterialListComponent,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class CriticalItemsContentComponent {
  readonly materialListStyle = MaterialListStyle.List;
  readonly headerKey = 'CART.CRITICAL_ITEMS.BANNER.NAME';
  readonly subHeaderKey = 'CART.CRITICAL_ITEMS.BANNER.MISSING_ITEMS';
  readonly icon = NaooConstants.icons.critical_item_star;
  readonly criticalItemsContext = MaterialRowContext.CriticalItem;
  readonly listStyle = MaterialListStyle.List;
  readonly viewMore = 'CART.CRITICAL_ITEMS.VIEW_MORE';
  readonly viewLess = 'CART.CRITICAL_ITEMS.VIEW_LESS';

  @Input()
  criticalItems: MaterialListRow[] = [];

  expanded = false;
  visibleItems = 2;
  expansionToggleText = this.viewMore;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get allVisibleItems(): MaterialListRow[] {
    return this.criticalItems.slice(0, this.visibleItems);
  }

  get shouldDisplayExpansionToggle(): boolean {
    return this.criticalItems.length > 2;
  }

  expansionToggle(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.visibleItems = this.criticalItems.length;
      this.expansionToggleText = this.viewLess;
    } else {
      this.visibleItems = 2;
      this.expansionToggleText = this.viewMore;
    }
    this.changeDetectorRef.markForCheck();
  }
}
