import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SearchResults } from '../../../../core/store/search/shared/search-results';
import { MaterialRowContext } from '../../../../core/store/material-row/models/material-row';
import { SearchHeaderComponent } from '../search-header/search-header.component';
import { MaterialListComponent } from '../../../../material-list/material-list.component';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { NoResultsExpoComponent } from '../../../../shared/no-results-expo/no-results-expo.component';

@Component({
  selector: 'naoo-search-content',
  templateUrl: './search-content.component.html',
  styleUrls: ['./search-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchHeaderComponent,
    MaterialListComponent,
    NaooStringDefaulterPipe,
    NoResultsExpoComponent,
  ],
})
export class SearchContentComponent {
  @Input() searchResult: SearchResults;
  @Input() isMobile: boolean;
  @Input() shouldRenderSearchList: boolean;
  @Input() hasNoResults: boolean;
  @Input() isOrderGuideOptionSelected: boolean;
  @Input() isOrderGuideTab: boolean;

  readonly context = MaterialRowContext.Browse;

  @Output() openFilterModalEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() endOfListEvent: EventEmitter<boolean> = new EventEmitter();
}
