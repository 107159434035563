<div class="tab-content">
  @if (displayMaxAllocation) {
    <span class="commodity-available">
      <button (click)="viewCommodityDetailsClicked.emit()">
        {{
          materialRowOptions.materialCommodityInfo.maxAllocationDisplayKey
            | translate
              : {
                  count:
                    materialRowOptions.materialCommodityInfo
                      .maxAllocationDisplay,
                }
        }}
      </button>
    </span>
  }
  @if (!!materialOrdering.materialWarning) {
    <naoo-material-warning
      [warning]="materialOrdering.materialWarning"
      [openDirection]="materialOpenDirection"
      [materialRowOptions]="materialRowOptions"
      [isInventoryLoaded]="materialOrdering.isInventoryLoaded"
      [selectedOrderingInfoIndex]="selectedTabIndex"
      [isMobile]="isMobile"
    ></naoo-material-warning>
  }

  @if (displayLoyaltyPoints) {
    <div class="points" [ngClass]="{ 'no-icon': !displayGoPointsLogo }">
      @if (displayGoPointsLogo) {
        <div>
          <mat-icon class="loyalty-icon" svgIcon="loyalty_icon"></mat-icon>
        </div>
      }
      {{ selectedMaterialOrderingInfo.loyaltyPoints | naooloyalty }}
    </div>
  }

  <mat-tab-group
    (selectedTabChange)="tabChanged($event)"
    [disablePagination]="true"
    [mat-stretch-tabs]="false"
  >
    @for (tab of materialOrdering.tabs; track tab.uom; let index = $index) {
      <mat-tab>
        <ng-template matTabLabel>
          {{ { uomCode: tab.displayCode } | materialunits }}
          @if (this.selectedTabIndex !== index && tab.quantity > 0) {
            <span>({{ tab.quantity }})</span>
          }
        </ng-template>
        <ng-template matTabContent>
          <naoo-material-ordering-info
            [orderingInfos]="materialOrdering.materialOrderingInfos"
            [currentOrderingInfo]="
              materialOrdering.materialOrderingInfos[index]
            "
            [materialRowOptions]="materialRowOptions"
            [materialWarning]="materialOrdering.materialWarning"
            [isMobile]="isMobile"
          >
          </naoo-material-ordering-info>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
