import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { ActiveCartSummary } from '../shared/models/active-cart-summary';
import { CostSummaryContext } from '../../../../cost-summary/shared/cost-summary';
import { CostSummaryContainerComponent } from '../../../../cost-summary/cost-summary-container.component';
import { ViewportRuler } from '@angular/cdk/overlay';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../../core/store/material-row/models/material-row';
import { DeviceIdentifierService } from '../../../../shared/services/device-identifier/device-identifier.service';
import { HeaderHeightService } from '../../../../shared/services/header-height/header-height.service';
import { SessionFacade } from '../../../../core/store/session/session.facade';
import { CartFacade } from '../../../../core/store/cart/cart.facade';
import { ActiveCartHeaderComponent } from '../active-cart-header/active-cart-header.component';
import { CriticalItemsContentComponent } from '../critical-items-content/critical-items-content.component';
import { CartActionBarContainerComponent } from '../cart-action-bar-container/cart-action-bar-container.component';
import { MaterialListComponent } from '../../../../material-list/material-list.component';
import { EmptyCartComponent } from '../empty-cart/empty-cart.component';
import { QuickAddComponent } from '../quick-add/quick-add.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { SmartCartRecommendationsComponent } from '../smart-cart-recommendations/smart-cart-recommendations.component';

@Component({
  selector: 'naoo-active-cart-content',
  templateUrl: './active-cart-content.component.html',
  styleUrls: ['./active-cart-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CostSummaryContainerComponent,
    ActiveCartHeaderComponent,
    CriticalItemsContentComponent,
    CartActionBarContainerComponent,
    MaterialListComponent,
    EmptyCartComponent,
    QuickAddComponent,
    NgClass,
    SmartCartRecommendationsComponent,
    AsyncPipe,
  ],
})
export class ActiveCartContentComponent {
  readonly costSummaryContext = CostSummaryContext.CartSummary;
  readonly materialRowContext = MaterialRowContext.CartSummary;
  readonly materialListStyle = MaterialListStyle.List;

  @Input() activeCartSummary: ActiveCartSummary;

  @Input() shouldRenderPrint: boolean;

  @ViewChild('costSummaryContainer')
  costSummaryContainer: CostSummaryContainerComponent;
  isMobile$ = this.deviceIdentifierService.observeDeviceType();
  customerBrand$ = this.sessionFacade.getLoadedCustomerBrand();

  hasIspuOrExpressPermissions$ =
    this.sessionFacade.hasIspuOrExpressPermissions();

  constructor(
    private viewportRuler: ViewportRuler,
    private deviceIdentifierService: DeviceIdentifierService,
    private headerHeightService: HeaderHeightService,
    private sessionFacade: SessionFacade,
    private cartFacade: CartFacade,
  ) {}

  get shouldStick() {
    const headerHeight = this.headerHeightService.getHeaderHeight();
    const bottomOffset =
      this.viewportRuler.getViewportRect().bottom - headerHeight;
    return bottomOffset > this.getCostSummaryHeight();
  }

  updatePoNumber(poNumber: string): void {
    this.cartFacade.updatePoNumber(poNumber);
  }

  private getCostSummaryHeight(): number {
    if (!this.costSummaryContainer) {
      return 0;
    }
    if (
      !!this.costSummaryContainer.costSummaryContent &&
      !!this.costSummaryContainer.costSummaryContent.costSummaryRef
    ) {
      return this.costSummaryContainer.costSummaryContent.costSummaryRef.nativeElement.getBoundingClientRect()
        .height;
    }
    return 0;
  }
}
