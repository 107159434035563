<div class="row-container">
  @if (isEmail) {
    <mat-icon class="email-icon" svgIcon="email"></mat-icon>
  } @else {
    <mat-icon class="phone-icon">phone_in_talk</mat-icon>
  }
  <div class="recipient-info">
    <span
      class="contact-info"
      [ngClass]="{ 'no-language-selection': !isRetalix }"
    >
      {{ getContactInfo }}
    </span>
    @if (isRetalix) {
      <span class="contact-name no-language-selection">
        {{ notification.name }}
      </span>
    }
    <span class="language-icon-container">
      <span class="language-container">
        {{ notification.language | translate }}
      </span>
      <span class="button-container">
        <button
          [attr.aria-label]="getNotificationsText('EDIT') | translate"
          mat-icon-button
          class="edit-button"
          [ngClass]="{ 'active-button': isEnabled }"
          [disabled]="!isEnabled"
          [matTooltip]="'PREFERENCES.TOOLTIPS.EDIT' | translate"
          [matTooltipShowDelay]="tooltipShowDelay"
          (click)="editClick()"
        >
          <mat-icon
            class="icon"
            svgIcon="edit-icon"
            [ngClass]="{ 'inactive-svg': !isEnabled }"
          >
          </mat-icon>
        </button>
        <button
          [attr.aria-label]="getNotificationsText('DELETE') | translate"
          mat-icon-button
          class="trash-button"
          [ngClass]="{ 'active-button': isEnabled }"
          [disabled]="!isEnabled"
          [matTooltip]="'PREFERENCES.TOOLTIPS.DELETE' | translate"
          [matTooltipShowDelay]="tooltipShowDelay"
          (click)="deleteClick()"
        >
          <mat-icon
            class="icon"
            svgIcon="trash-icon"
            [ngClass]="{ 'inactive-svg': !isEnabled }"
          >
          </mat-icon>
        </button>
      </span>
    </span>
  </div>
</div>
