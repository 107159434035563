import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NaooConstants } from '../../shared/NaooConstants';
import {
  FormControl,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { InstantErrorStateMatcher } from '../../shared/instant-error-state-matcher/instant-error-state-matcher';
import {
  MatFormField,
  MatPrefix,
  MatLabel,
  MatError,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-po-input',
  templateUrl: './po-input.component.html',
  styleUrls: ['./po-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatPrefix,
    NgClass,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    MatError,
    TranslateModule,
  ],
})
export class PoInputComponent implements OnInit {
  @Output() updatedPoNumber = new EventEmitter<string>();

  @Input()
  set isOffline(isOffline: boolean) {
    this._isOffline = isOffline;
    if (isOffline) {
      this.formPoNumber.disable();
    } else {
      this.formPoNumber.enable();
    }
  }

  get isOffline(): boolean {
    return this._isOffline;
  }

  @Input() isPoRequired: boolean;

  @Input()
  set poNumber(poNumber: string) {
    this._poNumber = poNumber;
    this.formPoNumber.setValue(this._poNumber || '');
  }

  get poNumber(): string {
    return this._poNumber;
  }

  formPoNumber: FormControl = new FormControl();
  instantErrorState: InstantErrorStateMatcher = new InstantErrorStateMatcher();
  readonly poNumberMaxLength = NaooConstants.PO_NUMBER_MAX_LENGTH;
  readonly purchaseOrderTextValidator: ValidatorFn = Validators.pattern(
    '^([ ]*[a-zA-Z0-9À-ÿ-.,!@$%&*‘’”“~[_#"\'^()=+\\]}{:/]+[ ]*)+$',
  );
  private readonly regexChecker = new RegExp(
    NaooConstants.ALLOWED_CHARACTERS_REGEX,
  );
  private readonly allowedCharactersWhenPasted =
    /[^a-zA-Z0-9À-ÿ-.,!@$%&*‘’”“~[_ #"'^()=+\]}{:/*$]/g;
  private _poNumber: string;
  private _isOffline: boolean;

  ngOnInit(): void {
    const validators: ValidatorFn[] = [this.purchaseOrderTextValidator];
    if (this.isPoRequired) {
      validators.push(Validators.required);
    }
    this.formPoNumber.setValidators(validators);
  }

  onPaste(event: ClipboardEvent): void {
    const clipBoardData = event.clipboardData.getData('text/plain');
    const newValue = clipBoardData.replace(
      this.allowedCharactersWhenPasted,
      '',
    );

    this.formPoNumber.setValue(newValue.slice(0, this.poNumberMaxLength));
    this.updatePoNumber();

    event.preventDefault();
  }

  onKeyDown(event: KeyboardEvent): boolean {
    if (this.regexChecker.test(event.key)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  updatePoNumber() {
    if (this.hasPoValueChanged()) {
      this.updatedPoNumber.emit(this.formPoNumber?.value);
    }
  }

  errorText(): string {
    return this.shouldShowPoWarning() ? 'EMPTY_PO' : 'INVALID_CHARACTERS';
  }

  private shouldShowPoWarning(): boolean {
    return this.isPoRequired && !this.formPoNumber.value?.trim();
  }

  private hasPoValueChanged(): boolean {
    return this.poNumber !== (this.formPoNumber?.value as string);
  }
}
