import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MaterialOrderingInfo,
  MaterialRow,
  MaterialRowContext,
} from '../../../../../core/store/material-row/models/material-row';
import { CartQuantityUpdate } from '../../../../../core/store/cart/cart.actions';
import { CartFacade } from '../../../../../core/store/cart/cart.facade';
import { NaooConstants } from '../../../../../shared/NaooConstants';
import { SmartCartRecommendation } from '../../../../../core/store/smart-cart-recommendations/smart-cart-recommendations.state';
import { EcommerceAnalyticsFacade } from '../../../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { ListScrollService } from '../../../../../shared/services/list-scroll/list-scroll.service';
import { AuxiliaryAnalyticsData } from '../../../../../core/services/ecommerce-analytics/models/google-events';
import { MaterialRowFacade } from '../../../../../core/store/material-row/material-row.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MaterialImageComponent } from '../../../../../material-list/material-row-container/shared/material-image/material-image.component';
import { RouterLink } from '@angular/router';
import { MaterialFlagContainerComponent } from '../../../../../material-list/material-row-container/shared/material-flag-container/material-flag-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooPricePipe } from '../../../../../shared/pipes/naoo-price.pipe';
import { NaooStringDefaulterPipe } from '../../../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { MaterialUnitsPipe } from '../../../../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-smart-cart-record',
  templateUrl: './smart-cart-record.component.html',
  styleUrl: './smart-cart-record.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MaterialImageComponent,
    RouterLink,
    MaterialFlagContainerComponent,
    TranslateModule,
    NaooPricePipe,
    NaooStringDefaulterPipe,
    MaterialUnitsPipe,
  ],
})
export class SmartCartRecordComponent implements OnInit, OnDestroy {
  @Input() analytics: AuxiliaryAnalyticsData;
  @Input() context: MaterialRowContext;
  @Input() recommendation: SmartCartRecommendation;

  private readonly destroyed$ = new Subject<void>();
  private readonly scrollDelayMs = 1000;

  protected materialRow?: MaterialRow;

  constructor(
    private cartFacade: CartFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private listScrollService: ListScrollService,
    private materialRowFacade: MaterialRowFacade,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.materialRowFacade
      .getMaterialRow({
        analytics: this.analytics,
        context: this.context,
        materialNumber: this.recommendation.product,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((materialRow) => {
        this.materialRow = materialRow;
        this.changeDetectorRef.markForCheck();
      });

    this.trackGoogleViewItem();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get shouldRender(): boolean {
    return (
      !!this.currentOrderingInfo &&
      !!this.materialRow?.materialRowInfo &&
      !!this.materialRow?.materialRowOptions
    );
  }

  get currentOrderingInfo(): MaterialOrderingInfo | undefined {
    return this.materialRow?.materialOrdering?.materialOrderingInfos?.[0];
  }

  get detailsLink(): string {
    return `${NaooConstants.PRODUCT_DETAILS_PATH}/${this.recommendation.product}`;
  }

  get materialUnitsInput(): { uomCode: string; quantity?: number } {
    return {
      uomCode: this.recommendation.uom,
      quantity: this.recommendation.quantity,
    };
  }

  addRecommendationToCart(): void {
    this.cartFacade.updateCartQuantities(
      this.createCartQuantityUpdate(),
      this.analytics,
    );

    setTimeout(
      () => this.listScrollService.scrollSmoothlyToBottom(),
      this.scrollDelayMs,
    );
  }

  private createCartQuantityUpdate(): CartQuantityUpdate[] {
    return [
      {
        materialNumber: this.recommendation.product,
        lines: [
          {
            uom: this.recommendation.uom,
            quantity: this.recommendation.quantity,
          },
        ],
        isAddedFromCriticalItem: false,
        context: this.context,
      },
    ];
  }

  private trackGoogleViewItem(): void {
    this.ecommerceAnalyticsFacade.trackGoogleViewItem(
      this.recommendation.product,
      this.context,
      this.analytics,
    );
  }
}
