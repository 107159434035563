<div class="modal-container">
  <div class="modal-header" #modalHeader>
    <h1>{{ 'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES_DEFINITIONS' | translate }}</h1>
    <button
      attr.aria-label="{{ 'CLOSE' | translate }}"
      class="close-button"
      (click)="closeModal()"
    >
      <img
        class="close-button-img"
        src="assets/images/close-icon.svg"
        alt="{{ 'CLOSE' | translate }}"
      />
    </button>
  </div>
  <div tabindex="0" class="attributes-content" #modalContent>
    @for (
      materialAttribute of materialAttributes;
      track materialAttribute.value
    ) {
      @if (attributes && attributes[materialAttribute.value]) {
        <div>
          <h3>{{ materialAttribute.name[currentLanguage] }}</h3>
          <div
            class="description"
            [innerHtml]="
              attributes[materialAttribute.value].description | stringdefaulter
            "
          ></div>
        </div>
      }
    }
  </div>
</div>
