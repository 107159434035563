import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NaooConstants } from '../NaooConstants';
import { NaooLocalStorage } from '../storage/local-storage/local-storage.service';

export const storeCartContentGuard: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router = inject(Router);
  const storage = inject(NaooLocalStorage);
  const data = _next.params.data;
  if (data) {
    storage.setItem(NaooConstants.CART_TRANSFER_CONTENT, data);
    return router.parseUrl(NaooConstants.CART_TRANSFER_URL);
  }
  return false;
};
