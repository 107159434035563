import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '../../shared/models/order-confirmation';
import { OrderItemRowComponent } from '../../order-item-row/order-item-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooCurrencyPipe } from '../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-drop-ship-orders-v1',
  templateUrl: './drop-ship-orders-v1.component.html',
  styleUrls: ['./drop-ship-orders-v1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OrderItemRowComponent, TranslateModule, NaooCurrencyPipe],
})
export class DropShipOrdersV1Component {
  @Input() dropShipOrder: Order;
}
