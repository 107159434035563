import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { OrderGuideChangeItem } from 'src/app/core/store/order-guide-change-history/order-guide-change-history.facade';
import { OrderGuideChangeItemStatus } from 'src/app/core/services/order-guide-change-history/models/order-guide-change-history-record';
import { CustomGuideFacade } from 'src/app/core/store/custom-guide/custom-guide.facade';
import { CriticalItemsFacade } from 'src/app/core/store/critical-items/critical-items.facade';
import { CustomDialogService } from 'src/app/shared/services/dialog/custom-dialog/custom-dialog.service';
import { CustomGuideCreationSourceAnalyticLabel } from 'src/app/lists/lists-analytics.constants';
import {
  MatMenuTrigger,
  MatMenu,
  MatMenuContent,
  MatMenuItem,
} from '@angular/material/menu';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ZoneUtilities } from 'src/app/shared/utilities/zone-utilities';
import {
  MaterialGuideMatch,
  MatchedCustomGuideInfo,
} from 'src/app/core/store/order-guide-change-history/models/order-guide-change-history';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-order-guide-changes-menu',
  templateUrl: './order-guide-changes-menu.component.html',
  styleUrls: ['./order-guide-changes-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    NgClass,
    MatIcon,
    MatMenuTrigger,
    TranslateModule,
  ],
})
export class OrderGuideChangesMenuComponent implements OnDestroy {
  @Input() changedItem: OrderGuideChangeItem;
  @Input() materialGuideMatch: MaterialGuideMatch;
  @Input() isUserOnline: boolean;
  @Input() isAvailableItem: boolean;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('guideChangesActionsMenu') menu: MatMenu;

  private destroyed$ = new Subject<void>();

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private criticalItemsFacade: CriticalItemsFacade,
    private customDialogService: CustomDialogService,
    private ngZone: NgZone,
  ) {}

  get isAddedItem(): boolean {
    return this.changedItem.status === OrderGuideChangeItemStatus.Added;
  }

  get shouldDisableUpdateGuides(): boolean {
    return (
      !this.materialGuideMatch ||
      !this.materialGuideMatch.hasLoaded ||
      !this.isUserOnline ||
      (this.isAddedItem
        ? this.isInAllGuides() || !this.isAvailableItem
        : this.isInNoGuides())
    );
  }

  get shouldShowCriticalItemOption(): boolean {
    return (
      (this.isAddedItem && !this.materialGuideMatch.isCriticalItem) ||
      (!this.isAddedItem && this.materialGuideMatch.isCriticalItem)
    );
  }

  get shouldShowNewGuideOption(): boolean {
    return (
      this.isAddedItem && this.materialGuideMatch.containingGuides.length === 0
    );
  }

  get menuOptions(): MatchedCustomGuideInfo[] {
    return this.isAddedItem
      ? this.materialGuideMatch
        ? this.materialGuideMatch.excludingGuides
        : []
      : this.materialGuideMatch
        ? this.materialGuideMatch.containingGuides
        : [];
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleGuideClick($event: Event, guideId: string) {
    this.stopPropagation($event);

    if (!this.isUserOnline) {
      return;
    }

    const index = this.getOffsetGuideIndex(guideId);

    if (this.isAddedItem) {
      this.customGuideFacade.addCustomGuideMaterials(guideId, [
        this.changedItem.materialNumber,
      ]);
    } else {
      this.customGuideFacade.removeCustomGuideMaterials(guideId, [
        this.changedItem.materialNumber,
      ]);
    }

    if ($event.type !== 'click') {
      this.focusNextItem(index);
    }
  }

  handleCriticalItemsClick($event: Event) {
    this.stopPropagation($event);

    if (!this.isUserOnline) {
      return;
    }

    if (this.isAddedItem) {
      this.criticalItemsFacade.addCriticalItem(this.changedItem.materialNumber);
    } else {
      this.criticalItemsFacade.deleteCriticalItem(
        this.changedItem.materialNumber,
      );
    }

    if ($event.type !== 'click') {
      this.focusNextItem(this.shouldShowNewGuideOption ? 1 : 0);
    }
  }

  handleNewGuideClick($event: Event) {
    this.stopPropagation($event);

    if (!this.isUserOnline) {
      return;
    }

    this.customDialogService.openCreateCustomGuideModal(
      CustomGuideCreationSourceAnalyticLabel.OrderGuideChanges,
      [this.changedItem.materialNumber],
    );
  }

  stopPropagation($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  private getOffsetGuideIndex(guideId: string): number {
    let index = this.isAddedItem
      ? this.materialGuideMatch.excludingGuides.findIndex(
          (guide) => guide.id === guideId,
        )
      : this.materialGuideMatch.containingGuides.findIndex(
          (guide) => guide.id === guideId,
        );

    if (this.shouldShowCriticalItemOption) {
      index++;
    }

    if (this.shouldShowNewGuideOption) {
      index++;
    }

    return index;
  }

  private focusNextItem(index: number) {
    ZoneUtilities.onZoneStable(this.ngZone)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(() => {
        const menuItems = this.menu._allItems.toArray();
        const nextItem = menuItems[index + 1];
        const prevItem = menuItems[index];
        if (nextItem) {
          nextItem.focus();
        } else {
          prevItem.focus();
        }
      });
  }

  private isInAllGuides(): boolean {
    return (
      this.materialGuideMatch.excludingGuides.length === 0 &&
      this.materialGuideMatch.isCriticalItem
    );
  }

  private isInNoGuides(): boolean {
    return (
      this.materialGuideMatch.containingGuides.length === 0 &&
      !this.materialGuideMatch.isCriticalItem
    );
  }
}
