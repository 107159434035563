import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-warning-materials-header',
  templateUrl: './warning-materials-header.component.html',
  styleUrls: ['./warning-materials-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class WarningMaterialsHeaderComponent {
  @Input() canFindRelated: boolean;
}
