<label
  class="filter-option"
  attr.aria-label="{{ filter.name | stringdefaulter }} {{ filter.count }} {{
    (filter.count === 1 ? 'SEARCH.RESULT' : 'SEARCH.RESULTS') | translate
  }}"
>
  <mat-checkbox
    class="naoo-checkbox"
    [disableRipple]="true"
    [checked]="filter.isSelected"
    (change)="toggleCheckBox($event)"
    (keyup.enter)="toggleCheckBox()"
  >
  </mat-checkbox>
  {{ filter.name | stringdefaulter }} ({{ filter.count }})
</label>
