@if (guideItem.product.offeringId) {
  <div
    class="list-card"
    [ngClass]="enableParOrdering ? 'fixed-par-height' : 'fixed-no-par-height'"
    [@moveRow]="moveRowState"
    (@moveRow.done)="animationComplete()"
    [@highlightRow]="highlightedRowState"
    (@highlightRow.done)="highlightComplete()"
  >
    <div class="product-details-row-container">
      <div
        class="product-details-container"
        [ngClass]="{ draggable: !isMobile && groupBy === 'custom' }"
      >
        <span>
          @if (groupBy === 'custom') {
            <img
              class="drag-handle"
              [ngClass]="{ draggable: !isMobile }"
              src="/assets/images/draghandle.svg"
              alt=""
            />
          }
        </span>
        <naoo-product-details
          [product]="guideItem.product"
          [units]="guideItem.material.units"
          [isCollapsed]="false"
          [isEditGuidePage]="true"
          [isMobile]="isMobile"
        ></naoo-product-details>
      </div>
      @if (productFlags$ | async; as productFlags) {
        @if (productFlags?.length > 0) {
          <div class="product-flag-row">
            @if (!isMobile) {
              <div class="draggable draggable-filler-space"></div>
            }
            <div class="product-flag-wrapper">
              @for (productFlag of productFlags; track productFlag.title) {
                <naoo-product-flag [productFlag]="productFlag">
                </naoo-product-flag>
              }
            </div>
          </div>
        }
      }
      @if (materialAvailability && isMobile && shouldDisplayPar) {
        <div class="product-par-ordering-quantity">
          @if (primaryMaterialUnitInfo && enableParOrdering) {
            <naoo-product-par-order-quantity-box
              [materialUnitInfo]="primaryMaterialUnitInfo"
              [product]="guideItem.product"
              [customGuideId]="customGuideId"
              [materialParOrder]="materialParOrder"
            ></naoo-product-par-order-quantity-box>
          }
          @if (
            primaryMaterialUnitInfo &&
            secondaryMaterialUnitInfo &&
            enableParOrdering
          ) {
            <div class="mobile-spacer"></div>
          }
          @if (secondaryMaterialUnitInfo && enableParOrdering) {
            <naoo-product-par-order-quantity-box
              [materialUnitInfo]="secondaryMaterialUnitInfo"
              [customGuideId]="customGuideId"
              [product]="guideItem.product"
              [materialParOrder]="materialParOrder"
            ></naoo-product-par-order-quantity-box>
          }
        </div>
      }
    </div>
    @if (materialAvailability && !isMobile && shouldDisplayPar) {
      <div class="product-par-ordering-quantity">
        @if (primaryMaterialUnitInfo && enableParOrdering) {
          <naoo-product-par-order-quantity-box
            [materialUnitInfo]="primaryMaterialUnitInfo"
            [customGuideId]="customGuideId"
            [product]="guideItem.product"
            [materialParOrder]="materialParOrder"
          ></naoo-product-par-order-quantity-box>
        }
        @if (secondaryMaterialUnitInfo && enableParOrdering) {
          <naoo-product-par-order-quantity-box
            [materialUnitInfo]="secondaryMaterialUnitInfo"
            [customGuideId]="customGuideId"
            [product]="guideItem.product"
            [materialParOrder]="materialParOrder"
          ></naoo-product-par-order-quantity-box>
        }
      </div>
    }
    <div class="edit-product">
      <div class="edit-buttons">
        <button
          attr.aria-label="{{
            isUpActive
              ? ('LISTS.PRODUCT_UP_BUTTON' | translate)
              : ('LISTS.PRODUCT_UP_BUTTON_INACTIVE' | translate)
          }}"
          (click)="moveUp()"
          [ngClass]="isUpActive ? 'product-up-active' : 'product-up-inactive'"
          [disabled]="!isUpActive"
        ></button>
        <button
          attr.aria-label="{{
            isDownActive
              ? ('LISTS.PRODUCT_DOWN_BUTTON' | translate)
              : ('LISTS.PRODUCT_DOWN_BUTTON_INACTIVE' | translate)
          }}"
          (click)="moveDown()"
          [ngClass]="
            isDownActive ? 'product-down-active' : 'product-down-inactive'
          "
          [disabled]="!isDownActive"
        ></button>
      </div>
      <naoo-product-edit-menu
        [customGuideId]="customGuideId"
        [product]="guideItem.product"
        [isDeleteOnly]="groupBy !== 'custom'"
        [isGfsCategory]="isGfsCategory"
        (actOnProductInMenu)="actOnProductInEditableRow($event)"
      >
      </naoo-product-edit-menu>
    </div>
  </div>
}
@if (!guideItem.product.offeringId) {
  <div class="list-card empty">
    {{ 'LISTS.DROP_PRODUCT_HERE' | translate }}
  </div>
}
