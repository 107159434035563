<div class="container">
  <div class="hamburger-menu-header">
    <naoo-header-tabs [isOffline]="isOffline$ | async"></naoo-header-tabs>
    <button
      class="close-button"
      type="button"
      (click)="navigateBack()"
      [disabled]="!backRoute"
      [attr.aria-label]="'MENU.CLOSE.ALT_TEXT' | translate"
    >
      <mat-icon svgIcon="clear_icon_v2"></mat-icon>
    </button>
  </div>

  <div class="menu-hamburger-section">
    <div class="hamburger-menu-option cart-icon">
      <naoo-cart-icon [wideLayout]="true"></naoo-cart-icon>
    </div>

    <div class="hamburger-menu-option">
      <naoo-customer-unit-selector
        [showArrowIcon]="true"
        [isDisabled]="isOffline$ | async"
      ></naoo-customer-unit-selector>
    </div>

    @if (hasIspuOrExpressPermissions$ | async) {
      <div class="hamburger-menu-option order-method-widget">
        <naoo-hamburger-order-method-widget
          [isBaymard]="true"
        ></naoo-hamburger-order-method-widget>
      </div>
    } @else {
      <div class="hamburger-menu-option delivery-schedule-widget">
        <naoo-delivery-schedule-widget
          [wideLayout]="true"
          [hideBorders]="true"
          [showArrowIcon]="true"
          [disabled]="isOffline$ | async"
        ></naoo-delivery-schedule-widget>
      </div>
    }
  </div>

  <div class="menu-hamburger-section">
    <mat-list>
      <a
        (click)="openGuides()"
        role="link"
        [attr.aria-label]="'MENU.GUIDES' | translate"
        class="guides-menu-option"
        matListItem
      >
        <mat-icon
          svgIcon="guides_menu_icon"
          class="icon"
          matListItemIcon
        ></mat-icon>
        <span matListItemTitle>{{ 'MENU.GUIDES' | translate }}</span>
        <mat-icon
          svgIcon="arrow-v2"
          class="arrow-icon"
          matListItemMeta
        ></mat-icon>
      </a>
    </mat-list>
  </div>

  <div class="hamburger-menu-option">
    <naoo-account-menu [isOffline]="isOffline$ | async"></naoo-account-menu>
  </div>
</div>
