<div class="empty-saved-carts-box">
  <h2 class="empty-saved-carts-title-mobile">
    {{ 'CART.EMPTY_SAVED_CARTS.WELCOME' | translate }}
  </h2>

  <div class="empty-saved-carts-icon">
    <video
      [muted]="true"
      [autoplay]="true"
      [loop]="true"
      playsinline="playsinline"
      class="empty-cart-gif"
      [controls]="false"
      disablePictureInPicture="true"
      [src]="'CART.EMPTY_SAVED_CARTS.VIDEO_PATH' | translate"
    ></video>
  </div>

  <div class="empty-saved-carts-content">
    <h2 class="empty-saved-carts-title">
      {{ 'CART.EMPTY_SAVED_CARTS.WELCOME' | translate }}
    </h2>

    <div class="empty-saved-carts-description">
      <div class="info-container">
        <div class="description-title">
          {{ 'CART.EMPTY_SAVED_CARTS.HOW_TO_SAVE_QUESTION' | translate }}
        </div>
        <div class="description-message">
          {{ 'CART.EMPTY_SAVED_CARTS.HOW_TO_SAVE_DESCRIPTION' | translate }}
          <a
            title="{{
              'CART.EMPTY_SAVED_CARTS.HOW_TO_SAVE_DESCRIPTION_ANCHOR_TEXT_ALT'
                | translate
            }}"
            href="javascript:void(0)"
            (click)="openHelperModal()"
            class="help-button"
            id="help-modal"
          >
            {{
              'CART.EMPTY_SAVED_CARTS.HOW_TO_SAVE_DESCRIPTION_ANCHOR_TEXT'
                | translate
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
