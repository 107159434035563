import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  TaxonomyFilter,
  Taxonomy,
} from 'src/app/core/store/search/shared/search-results';
import { TaxonomyFilterComponent } from './taxonomy-filter/taxonomy-filter.component';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-taxonomy-filter-group',
  templateUrl: './taxonomy-filter-group.component.html',
  styleUrls: ['./taxonomy-filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TaxonomyFilterComponent, NgClass, TranslateModule],
})
export class TaxonomyFilterGroupComponent {
  @Input() taxonomy: Taxonomy;

  isViewingMore = false;

  get taxonomyFilterList(): TaxonomyFilter[] {
    return this.isViewingMore
      ? this.taxonomy.filters
      : Array.from(Array(this.taxonomy.viewMoreThreshold))
          .map((i, index) => this.taxonomy.filters[index])
          .filter((taxonomy) => !!taxonomy);
  }

  get canViewMore(): boolean {
    return this.taxonomy.filters.length > this.taxonomy.viewMoreThreshold;
  }

  toggleViewMore() {
    this.isViewingMore = !this.isViewingMore;
  }
}
