<button
  attr.aria-label="{{ 'LISTS.MENU_OPTIONS' | translate }}"
  mat-icon-button
  class="mat-icon-button edit-menu"
  [disabled]="isDisabled"
  [ngClass]="{ inactive: isDisabled }"
  [matMenuTriggerFor]="menu"
>
  <mat-icon svgIcon="triple_dot_menu_white"></mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before" (closed)="menuClosed()">
  @if (!isGfsCategory) {
    <button
      attr.aria-label="{{ 'LISTS.RENAME_CATEGORY_BUTTON' | translate }}"
      mat-menu-item
      class="rename-menu-button"
      role="button"
      (click)="menuAction = openRenameModal"
    >
      {{ 'LISTS.RENAME_CATEGORY' | translate }}
    </button>
  }
  <button
    attr.aria-label="{{ 'LISTS.DELETE_CATEGORY_BUTTON' | translate }}"
    mat-menu-item
    class="delete-menu-button"
    role="button"
    (click)="menuAction = openDeleteModal"
  >
    {{ 'LISTS.DELETE_CATEGORY' | translate }}
  </button>
</mat-menu>
