@if (!!submittedCart) {
  <div class="submitted-cart-container">
    <div
      class="last-updated-content"
      [ngClass]="{
        'no-margin-bottom': isValidSubmittedCart,
      }"
    >
      <div class="last-updated-cart-container">
        <div class="last-updated-details">
          <div class="last-updated-title">
            {{ 'CART.LAST_UPDATED' | translate }}
          </div>
          <div>
            {{
              submittedCart.lastUpdatedTimestamp
                | naooDate: 'longDateNoDayAbbrMo' : 'UTC'
            }}
          </div>
        </div>
        <div
          class="cart-status-content-mobile"
          [ngClass]="{
            'red-color': submittedCart.submittedCartStatus === deletedStatus,
          }"
        >
          <mat-icon
            [svgIcon]="getSubmittedCartStatusIcon"
            class="cart-status-icon"
          ></mat-icon>
          <span class="cart-status-value">{{
            submittedCart.submittedCartStatus | translate
          }}</span>
        </div>
      </div>
      <div class="last-updated-customer-container">
        @if (
          submittedCart.lastUpdatedUserName ||
          submittedCart.lastUpdatedUserEmail
        ) {
          <div class="last-updated-customer-info">
            @if (submittedCart.lastUpdatedUserName) {
              <span id="userName">
                {{ submittedCart.lastUpdatedUserName }}
              </span>
            }
            @if (submittedCart.lastUpdatedUserEmail) {
              <span id="userEmail">
                {{ submittedCart.lastUpdatedUserEmail }}
              </span>
            }
          </div>
        }
        @if (isValidSubmittedCart) {
          <div class="cart-info-content-mobile">
            <mat-icon svgIcon="cart_icon_v2"></mat-icon>
            {{ submittedCart.totalQuantity }}
          </div>
        }
      </div>
    </div>
    <div
      class="shipment-info-content"
      [ngClass]="{
        'hide-container': isValidSubmittedCart,
      }"
    >
      <div class="shipment-details">
        @if (submittedCart.truckFulfillment?.routeDate) {
          <div
            class="route-date-container"
            [ngClass]="{
              'ship-date-gap': submittedCart.customerPoNumber,
            }"
          >
            <div class="route-date-title">
              {{ 'CART.SAVED_CARTS.SHIP_DATE' | translate }}
            </div>
            <div>
              {{
                submittedCart.truckFulfillment?.routeDate
                  | naooDate: 'longDateNoDayAbbrMo' : 'UTC'
              }}
            </div>
          </div>
        }
        @if (submittedCart.customerPoNumber) {
          <span id="customer-po-number">
            {{ 'CART.SAVED_CARTS.PO' | translate }}
            {{ submittedCart.customerPoNumber }}
          </span>
        }
      </div>
      <div class="cart-info-content">
        <mat-icon svgIcon="cart_icon_v2"></mat-icon>
        {{ submittedCart.totalQuantity }}
      </div>
    </div>
    <div
      class="cart-status-content"
      [ngClass]="{
        'red-color': submittedCart.submittedCartStatus === deletedStatus,
      }"
    >
      <div class="cart-status-icon">
        <mat-icon [svgIcon]="getSubmittedCartStatusIcon"></mat-icon>
      </div>
      <span class="cart-status-value">{{
        submittedCart.submittedCartStatus | translate
      }}</span>
    </div>
    <div class="button-container-content">
      @if (submittedCart.submittedCartStatus === awaitingApprovalStatus) {
        <div class="cart-buttons">
          <div class="delete-button-container">
            <button
              class="delete-button ghost-button"
              (click)="deleteCart()"
              [attr.aria-label]="'CART.DELETE_BUTTON_ARIA_TEXT' | translate"
              [disabled]="isOffline"
            >
              <mat-icon
                [ngClass]="{ disabled: isOffline }"
                svgIcon="trash-icon-v3"
                class="trash-icon"
              ></mat-icon>
              <div class="button-text" [ngClass]="{ disabled: isOffline }">
                {{ 'CART.DELETE_BUTTON' | translate }}
              </div>
            </button>
          </div>
          <button
            type="button"
            class="preview-button secondary-button"
            [ngClass]="{
              'preview-button-pressed':
                isOffline || (isPreviewOpen && isSubmittedCartSelected),
            }"
            (click)="togglePreview()"
            [disabled]="isOffline"
          >
            {{ 'CART.PREVIEW_BUTTON' | translate }}
          </button>
          <button
            type="button"
            class="activate-button primary-button"
            [ngClass]="{ disabled: isOffline }"
            (click)="activateCart()"
            [disabled]="isOffline"
          >
            {{ 'CART.ACTIVATE_BUTTON' | translate }}
          </button>
        </div>
      }
      @if (submittedCart.submittedCartStatus === approvedStatus) {
        @if (isOffline) {
          <div class="order-history-link disabled">
            {{ 'CART.SUBMITTED_CARTS.ORDER_HISTORY_ANCHOR_TEXT' | translate }}
          </div>
        } @else {
          <a class="order-history-link" [routerLink]="ordersPath"
            >{{ 'CART.SUBMITTED_CARTS.ORDER_HISTORY_ANCHOR_TEXT' | translate }}
          </a>
        }
      }
    </div>
  </div>
}
