import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-go-points-logo',
  templateUrl: './go-points-logo.component.html',
  styleUrls: ['./go-points-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule],
})
export class GoPointsLogoComponent {
  readonly textTranslationKey = 'GO_POINTS.LOGO.TEXT';

  @Input() context: string;
  @Input() disabled = false;
  @Input() header = false;

  getClass(): string {
    return `${this.context} ${this.disabled ? 'disabled' : ''}`;
  }
}
