<ng-container>
  <button
    mat-menu-item
    class="language-button"
    [matMenuTriggerFor]="appMenu"
    [disabled]="isDisabled"
    attr.aria-label="{{
      'MENU.LANGUAGE_MENU_SELECTED'
        | translate: { value: getSelectedLanguageDisplayName() }
    }}"
  >
    <mat-icon
      class="language-icon"
      [ngClass]="{
        disabled: isDisabled,
      }"
      svgIcon="language_icon"
    ></mat-icon>
    <div
      class="button-text"
      [ngClass]="{
        disabled: isDisabled,
      }"
    >
      {{ getSelectedLanguageDisplayName() }}
    </div>
  </button>
  <mat-menu #appMenu="matMenu" class="languages-mat-menu" xPosition="before">
    @for (language of languages; track language.display) {
      <button
        class="mat-menu-item"
        attr.aria-label="{{ language.display }}"
        mat-menu-item
        (click)="setLanguage(language.lang)"
      >
        {{ language.display }}
      </button>
    }
  </mat-menu>
</ng-container>
