import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NaooConstants } from '../NaooConstants';
import { NgClass } from '@angular/common';
import { NaooImagePipe } from '../pipes/naoo-image.pipe';

@Component({
  selector: 'naoo-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NaooImagePipe],
})
export class ProductImageComponent {
  @Input() materialNumber: string;
  @Input() dimension: number;
  @Input() alt = '';
  @Input() src: string;
  @Input() srcSet: string;
  @Input() isDraggable = false;

  noImageAvailableURL($event: UIEvent): void {
    (<HTMLImageElement>$event.target).src = NaooConstants.placeHolderImagePath;
    (<HTMLImageElement>$event.target).srcset =
      NaooConstants.placeHolderImagePath;
  }
}
