import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';
import { MaterialListRow } from '../../material-list/models/material-list';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { Localized } from '../../shared/models/localized';
import { NgClass } from '@angular/common';
import { MaterialListComponent } from '../../material-list/material-list.component';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { DecodeHtmlEntityPipe } from '../../shared/pipes/decode-html-entity.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-top-recommendation',
  templateUrl: './top-recommendation.component.html',
  styleUrls: ['./top-recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    MaterialListComponent,
    NaooStringDefaulterPipe,
    DecodeHtmlEntityPipe,
    TranslateModule,
  ],
})
export class TopRecommendationComponent implements OnInit {
  @Input() materialListRow: MaterialListRow;
  @Input() title: Localized<string>;
  @Input() showButton = true;
  @Input() showBoxShadowOnMaterialRowOnly = false;

  @Output() materialClick = new EventEmitter<string>();
  @Output() scrollToRecommendationsClick = new EventEmitter();

  readonly recommendationEngineName = 'Top product for you';
  readonly materialContext: MaterialRowContext =
    MaterialRowContext.Recommendation;
  readonly materialListStyle: MaterialListStyle = MaterialListStyle.List;

  constructor(
    private analytics: NaooAnalyticsManager,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnInit(): void {
    const topRecommendation = this.materialListRow
      ? (this.materialListRow.value as string)
      : undefined;
    if (topRecommendation) {
      this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
        [topRecommendation],
        this.materialContext,
        {
          analytics: {
            recommendationEngineName: this.recommendationEngineName,
          },
        },
      );
      this.ecommerceAnalyticsFacade.trackRecommendationViewEvent(
        topRecommendation,
      );
    }
  }

  handleScrollToRecommendations() {
    this.scrollToRecommendationsClick.emit();
    this.trackViewAllRecommendations();
  }

  private trackViewAllRecommendations() {
    const event: AnalyticsEventInfo = {
      action: 'click',
      category: 'recommendations',
      label: 'view all recommendations',
    };
    this.analytics.trackAnalyticsEvent(event);
  }
}
