import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialEntitlementMapping } from '../../../core/store/material-row/models/material-row';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-commodity-allocation',
  templateUrl: './commodity-allocation.component.html',
  styleUrls: ['./commodity-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class CommodityAllocationComponent {
  @Input() mapping: MaterialEntitlementMapping;
}
