import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  SavedCart,
  SavedCarts,
} from '../../../core/store/multiple-carts/multiple-carts.state';
import { SavedCartService } from '../saved-carts/shared/saved-cart.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatExpansionModule } from '@angular/material/expansion';
import { SavedCartRowComponent } from '../saved-carts/saved-cart-row/saved-cart-row.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-punched-out-carts-content',
  templateUrl: './punched-out-carts-content.component.html',
  styleUrls: ['./punched-out-carts-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatExpansionModule, SavedCartRowComponent, TranslateModule],
})
export class PunchedOutCartsContentComponent implements OnInit, OnDestroy {
  @Input() savedCarts: SavedCarts;

  selectedCartId: string;
  isPreviewOpen = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private savedCartService: SavedCartService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.savedCartService
      .previewOpen$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((previewOpen: boolean) => {
        this.isPreviewOpen = previewOpen;
        this.changeDetectorRef.markForCheck();
      });
    this.savedCartService
      .cartPreviewState$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((previewCart: SavedCart) => {
        this.selectedCartId = previewCart?.id;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get punchedOutCarts(): SavedCart[] {
    return this.savedCarts?.punchedOutCarts;
  }
}
