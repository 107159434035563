import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'naoo-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  standalone: true,
  imports: [MatProgressSpinner],
})
export class LoadingModalComponent {
  readonly diameter: number = 70;
  readonly strokeWidth: number = 7;
}
