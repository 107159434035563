<naoo-modal-header
  [title]="getModalTitle() | translate"
  [showCloseButton]="showCloseButton"
  (closeModalEmitter)="closeModal()"
></naoo-modal-header>
<div class="bulk-add-modal-container">
  <h2 class="title">{{ 'BULK_ADD_MODAL.EXISTING_ITEMS_TITLE' | translate }}</h2>
  <div class="subtitle">
    {{ 'BULK_ADD_MODAL.EXISTING_ITEMS_SUBTITLE' | translate }}
  </div>
  <div class="cart-actions">
    @for (action of cartActions; track action.actionId) {
      <button (click)="setCartActionType(action.cartActionType)">
        <mat-card
          appearance="outlined"
          [ngClass]="{ selected: isCartActionType(action.cartActionType) }"
        >
          <div class="cart-action-header">
            <div class="cart-action-icon-with-text">
              <span class="cart-action-title">
                {{ action.title | translate }}
              </span>
              <span class="cart-action-subtitle">
                {{ action.subtitle | translate }}
              </span>
            </div>
            <mat-icon class="icon" [svgIcon]="action.icon"></mat-icon>
          </div>
        </mat-card>
      </button>
    }
  </div>
  <div class="buttons-container">
    <button
      attr.aria-label="{{ 'BULK_ADD_MODAL.CONTINUE_BUTTON' | translate }}"
      class="continue-button"
      [disabled]="!enableContinueButton"
      (click)="submit()"
    >
      {{ 'BULK_ADD_MODAL.CONTINUE_BUTTON' | translate }}
    </button>
  </div>
</div>
