import { Localized } from './localized';
import { GuideItem } from './guide-item';
import { GroupByType, SortByType } from 'src/app/guides/shared/guides';
import { Language } from '../../core/services/session/models/session-record';
import { getCollator } from '../collator/collator';
import { LocalizedUtilities } from '../utilities/localized-utilities';

export interface CategorizedCustomGuide {
  groupBy: GroupByType;
  sortBy: SortByType;
  categorizedMaterials: CategorizedMaterials[];
}

export interface CategorizedMaterials {
  categoryName: Localized<string>;
  categoryOrder: number;
  items: GuideItem[];
  categoryType: string;
  categoryId?: string;
}

export function sortByCustom(
  categorizedMaterials: CategorizedMaterials,
): CategorizedMaterials {
  (categorizedMaterials.items || []).sort((lhs, rhs) =>
    lhs.order > rhs.order ? 1 : -1,
  );
  return categorizedMaterials;
}

export function sortByDescription(
  categorizedMaterials: CategorizedMaterials,
  language: Language,
): CategorizedMaterials {
  categorizedMaterials.items.sort((lhs, rhs) => {
    return descriptionComparator(lhs, rhs, language);
  });
  return categorizedMaterials;
}

function descriptionComparator(
  lhs: GuideItem,
  rhs: GuideItem,
  language: Language,
): number {
  if (!lhs.material.description?.en || !rhs.material.description?.en) {
    return 1;
  }

  return getCollator(language).compare(
    LocalizedUtilities.getLocalizedStringValue(
      lhs.material.description,
      language,
    ),
    LocalizedUtilities.getLocalizedStringValue(
      rhs.material.description,
      language,
    ),
  );
}

export function sortByBrand(
  categorizedMaterials: CategorizedMaterials,
  language: Language,
) {
  categorizedMaterials.items.sort((lhs, rhs) => {
    if (!lhs.material.brand?.en || !rhs.material.brand?.en) {
      return 1;
    }

    const brandComparisonValue = getCollator(language).compare(
      LocalizedUtilities.getLocalizedStringValue(lhs.material.brand, language),
      LocalizedUtilities.getLocalizedStringValue(rhs.material.brand, language),
    );

    return brandComparisonValue !== 0
      ? brandComparisonValue
      : descriptionComparator(lhs, rhs, language);
  });
  return categorizedMaterials;
}

export function sortByItemCode(categorizedMaterials: CategorizedMaterials) {
  categorizedMaterials.items.sort((lhs, rhs) =>
    lhs.material.materialNumber > rhs.material.materialNumber ? 1 : -1,
  );
  return categorizedMaterials;
}

export function sortByLastOrderDate(
  categorizedMaterials: CategorizedMaterials,
  language: Language,
): CategorizedMaterials {
  categorizedMaterials.items.sort((lhs, rhs) => {
    if (lhs.material.lastOrderDate && rhs.material.lastOrderDate) {
      if (lhs.material.lastOrderDate === rhs.material.lastOrderDate) {
        return descriptionComparator(lhs, rhs, language);
      }

      return getCollator(language).compare(
        rhs.material.lastOrderDate,
        lhs.material.lastOrderDate,
      );
    } else if (lhs.material.lastOrderDate) {
      return -1;
    } else if (rhs.material.lastOrderDate) {
      return 1;
    }
    return descriptionComparator(lhs, rhs, language);
  });
  return categorizedMaterials;
}
