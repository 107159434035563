<button
  class="date-picker-button delivery-schedule-widget"
  [disabled]="isDisabled"
  (click)="onClick()"
  [ngClass]="{
    'normal-layout': !wideLayout,
    'wide-layout': wideLayout,
    'no-borders': hideBorders,
    disabled: isDisabled,
  }"
  [attr.aria-label]="combinedAriaLabel | translate"
>
  <mat-icon
    class="calendar-image"
    [ngClass]="{ disabled: isDisabled }"
    svgIcon="{{ imageIcon }}"
  ></mat-icon>
  <div class="text" [ngClass]="{ disabled: isDisabled }">
    @if (wideLayout) {
      <div class="combined-title">
        {{ combinedTitle | translate }}
      </div>
    } @else {
      <div class="title">{{ title | translate }}</div>
      <div class="subtitle">{{ subtitle | translate }}</div>
    }
  </div>
  @if (showArrowIcon) {
    <mat-icon svgIcon="arrow-v2" class="arrow"></mat-icon>
  }
</button>
