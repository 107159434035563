import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CatalogService } from '../../shared/services/catalog/catalog.service';
import { Subject } from 'rxjs';
import { CatalogResponse } from '../../shared/models/catalog/catalog-response';
import { ActivatedRoute } from '@angular/router';
import { NavigationLink } from '../../shared/models/navigation-link';
import { NaooStringDefaulter } from '../../shared/string-defaulter/naoo-string-defaulter';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Language } from '../../core/services/session/models/session-record';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { BackButtonComponent } from '../../shared/back-button/back-button.component';
import { ProductClassComponent } from '../product-class/product-class.component';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    BackButtonComponent,
    ProductClassComponent,
    NaooStringDefaulterPipe,
  ],
})
export class CategoryDetailsComponent implements OnInit, OnDestroy {
  category: CatalogResponse;
  currentLanguage: Language;
  destroyed$ = new Subject<boolean>();
  breadcrumbs: NavigationLink[];
  backNavigationLink: NavigationLink;

  constructor(
    private catalogService: CatalogService,
    private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService,
    private stringDefaulter: NaooStringDefaulter,
  ) {
    this.currentLanguage = this.localizationService.currentLanguage;
  }

  ngOnInit() {
    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((language) => {
        this.currentLanguage = language;
        this.setBreadcrumbs();
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.catalogService.loadCategoryTree(params.categoryCoordinate);
        this.catalogService
          .observeCategoryResponse(params.categoryCoordinate)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((root) => {
            // TODO: NAOO-4076 Route to a 404 page if there are no subcategories.
            if (root != null) {
              this.category = root.subCategories[0];
              this.setBreadcrumbs();
            }
          });
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private setBreadcrumbs() {
    if (!this.category) {
      return;
    }

    const root: NavigationLink = {
      name: this.localizationService.instant('CATEGORIES.TITLE'),
      link: '/categories',
    };

    const current: NavigationLink = {
      name: this.stringDefaulter.getString(
        this.category.name,
        this.currentLanguage,
      ),
      link: null,
    };

    this.breadcrumbs = [root, current];
    this.setBackNavigationLink();
  }

  private setBackNavigationLink() {
    const categoriesNavigationLink = this.breadcrumbs
      ? this.breadcrumbs[0]
      : null;
    if (!categoriesNavigationLink) {
      this.backNavigationLink = null;
      return;
    }

    this.backNavigationLink = {
      name: `${this.localizationService.instant('CATEGORIES.BACK_TO')} ${
        categoriesNavigationLink.name
      }`,
      link: categoriesNavigationLink.link,
    };
  }
}
