import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NutritionLabel } from '../../../core/store/nutrition/models/nutrition';
import { NutritionFactsContentComponent } from './nutrition-content/nutrition-facts-content.component';

@Component({
  selector: 'naoo-nutrition-facts-container',
  template: ` <naoo-nutrition-facts-content [nutritionLabel]="nutritionLabel">
  </naoo-nutrition-facts-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NutritionFactsContentComponent],
})
export class NutritionFactsComponent {
  @Input() materialNumber: string;
  @Input() nutritionLabel: NutritionLabel;
}
