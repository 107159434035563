<mat-expansion-panel [expanded]="true" class="item-processing-expansion-panel">
  <mat-expansion-panel-header
    class="item-processing-header expansion-panel-header-large-indicator"
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'"
  >
    <mat-panel-title class="processing-description">
      <mat-icon class="warning-icon" svgIcon="warning-icon"></mat-icon>
      <span class="text-center"
        >{{ processingOrders.length }}
        {{ 'ORDER_CONFIRMATION.PROCESSING_ORDERS.TITLE' | translate }}</span
      >
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="processing-orders">
    <div class="processing-message">
      {{ 'ORDER_CONFIRMATION.PROCESSING_ORDERS.CHECK_BACK' | translate }}
    </div>
    <div class="help-desk-message">
      {{
        'ORDER_CONFIRMATION.PROCESSING_ORDERS.HELP_DESK.BODY'
          | translate: { brand: getCustomerBrand() | translate }
      }}
    </div>

    <div class="table-headers">
      <div class="left-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.ITEM' | translate }}
      </div>
      <div class="right-headers">
        <div class="right-header text-left short-header">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.SIZE' | translate }}
        </div>
        <div class="right-header text-right">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.PRICE' | translate }}
        </div>
        <div class="right-header text-center short-header">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.QUANTITY' | translate }}
        </div>
        <div class="right-header short-header">
          {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
        </div>
      </div>
    </div>
    @for (item of processingOrders; track item; let isLast = $last) {
      <naoo-order-item-row
        class="order-item-row"
        [orderItem]="item"
        [isLastRow]="isLast"
        [isProcessing]="true"
      ></naoo-order-item-row>
    }
  </div>
</mat-expansion-panel>
