@if (viewModel) {
  <div class="customer-points-container">
    <div class="go-points-tab-header">
      {{ 'GO_POINTS.LABELS.LOYALTY_TIER' | translate }}
    </div>
    <div class="container">
      @if (viewModel.hasPoints) {
        <div class="lhs-container">
          <mat-icon class="tier-icon" [svgIcon]="viewModel.tierIcon"></mat-icon>
          <span class="tier-name">{{ viewModel.tierText | translate }}</span>
        </div>
        <div class="rhs-container">
          <span class="points-balance-label">
            {{ 'GO_POINTS.LABELS.POINTS_BALANCE' | translate }}
          </span>
          <span class="points-balance">
            {{ viewModel.loyaltyPointsBalance | naoodecimal }}
          </span>
        </div>
      } @else {
        <span
          class="no-points"
          [innerHTML]="'GO_POINTS.TEXT.NO_POINTS' | translate"
        ></span>
      }
    </div>
    <span class="disclaimer">{{
      'GO_POINTS.TEXT.DISCLAIMER' | translate
    }}</span>
    @if (viewModel.checkEligible) {
      <div class="check-redemption-container">
        <div class="check-redemption-check-amount">
          {{ 'GO_POINTS.LABELS.CHECK_AMOUNT' | translate }}
        </div>
        <div class="check-redemption-icon">
          <mat-icon
            class="paper-check-icon"
            [svgIcon]="'paper_check'"
          ></mat-icon>
        </div>
        <div class="check-redemption-points-amount">
          {{
            'GO_POINTS.TEXT.POINTS_REQUIRED'
              | translate: { value: viewModel.checkPointValue | naoodecimal }
          }}
        </div>
        <div class="check-redemption-divider"></div>
        <div class="check-redemption-label">
          {{ 'GO_POINTS.LABELS.CHECKS_REQUESTED' | translate }}
        </div>
        <div class="check-redemption-control">
          <button
            class="decrement-checks"
            [disabled]="numberOfChecks <= 0"
            [attr.aria-label]="'GO_POINTS.LABELS.DECREMENT_CHECKS' | translate"
            (click)="adjustNumberOfChecks(numberOfChecks - 1)"
          >
            <mat-icon [svgIcon]="'minus'"></mat-icon>
          </button>
          <input
            #input
            autocomplete="off"
            class="input-checks"
            maxlength="3"
            type="tel"
            [attr.aria-label]="'GO_POINTS.LABELS.CHECKS_REQUESTED' | translate"
            [value]="numberOfChecks"
            (input)="validateInput()"
            (blur)="adjustNumberOfChecks(getInputValue())"
          />
          <button
            class="increment-checks"
            [disabled]="numberOfChecks >= viewModel.maxNumberOfChecks"
            [attr.aria-label]="'GO_POINTS.LABELS.INCREMENT_CHECKS' | translate"
            (click)="adjustNumberOfChecks(numberOfChecks + 1)"
          >
            <mat-icon [svgIcon]="'plus'"></mat-icon>
          </button>
        </div>
        <div class="check-redemption-points-count">
          <span
            [innerHTML]="
              'GO_POINTS.TEXT.POINTS_REDEEMING'
                | translate
                  : {
                      value:
                        numberOfChecks * this.viewModel.checkPointValue
                        | naoodecimal,
                    }
            "
          >
          </span>
        </div>
        <div class="check-redemption-submit">
          <button
            mat-button
            (click)="redeemCheck()"
            [disabled]="!numberOfChecks"
          >
            {{ 'GO_POINTS.LABELS.REDEEM' | translate }}
          </button>
        </div>
      </div>
    }
  </div>
}
<div class="text-blocks">
  <div class="about-gordon-go">
    <h1>{{ 'GO_POINTS.LABELS.ABOUT_GORDON_GO' | translate }}</h1>
    <span [innerHTML]="'GO_POINTS.TEXT.ABOUT_GORDON_GO' | translate"></span>
    <a [href]="GO_POINTS_LEARN_MORE_URL" target="_blank" rel="noopener">
      {{ 'GO_POINTS.TEXT.LEARN_MORE' | translate }}
    </a>
  </div>
  <div class="redeem-gordon-go">
    <h1>{{ 'GO_POINTS.LABELS.REDEEM_GORDON_GO' | translate }}</h1>
    <span [innerHTML]="'GO_POINTS.TEXT.REDEEM_GORDON_GO' | translate"></span>
  </div>
</div>
