import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { CreateCustomGuideErrorStateMatcher } from './create-custom-guide-error-state-matcher';
import { NaooConstants } from '../../shared/NaooConstants';
import { CustomGuideNameExistsValidator } from '../../shared/services/validators/custom-guide-name-exists-validator.service';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { NotOnlyWhitespaceValidator } from '../../shared/services/validators/not-only-whitespace-validator.service';
import { createCustomGuideCreationAnalyticEvent } from '../../core/store/custom-guide/custom-guide.util';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { CustomGuideCreationSourceAnalyticLabel } from '../lists-analytics.constants';
import { GroupByType } from 'src/app/guides/shared/guides';
import {
  MatFormField,
  MatLabel,
  MatHint,
  MatError,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';

export interface CreateCustomGuideModal {
  customGuideId: string;
  customGuideName: string;
}

@Component({
  selector: 'naoo-create-custom-guide-modal',
  templateUrl: './create-custom-guide-modal.component.html',
  styleUrls: ['./create-custom-guide-modal.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    MatHint,
    MatError,
    MatRadioGroup,
    MatRadioButton,
    TranslateModule,
  ],
})
export class CreateCustomGuideModalComponent implements OnInit {
  customGuideNameFormControl: FormControl;
  groupByFormControl: FormControl;
  matcher = new CreateCustomGuideErrorStateMatcher();
  customGuideNameMaxLength = NaooConstants.CUSTOM_GUIDE_NAME_MAX_LENGTH;
  groupByOptions = NaooConstants.GROUP_BY_OPTIONS;

  constructor(
    private dialogRef: MatDialogRef<CreateCustomGuideModalComponent>,
    private customGuideFacade: CustomGuideFacade,
    private notOnlyWhitespaceValidator: NotOnlyWhitespaceValidator,
    private customGuideNameExistsValidator: CustomGuideNameExistsValidator,
    private analytics: NaooAnalyticsManager,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      materialNumbers: string[];
      customGuideIdToDuplicate: string;
      creationSourceLabel: CustomGuideCreationSourceAnalyticLabel;
    },
  ) {}

  get newCustomGuideName() {
    return this.customGuideNameFormControl.value.toString().trim();
  }

  get shouldDuplicateCustomGuide() {
    return !!this.data.customGuideIdToDuplicate;
  }

  ngOnInit(): void {
    this.customGuideNameFormControl = new FormControl(
      '',
      [
        Validators.maxLength(this.customGuideNameMaxLength),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
        this.notOnlyWhitespaceValidator.validate(),
      ],
      this.customGuideNameExistsValidator
        .validate()
        .bind(this.customGuideNameExistsValidator),
    );
    this.groupByFormControl = new FormControl(GroupByType.Custom);
  }

  closeModal() {
    this.dialogRef.close();
  }

  createCustomGuide() {
    if (this.isFormValid()) {
      const selectedGroupByOption = this.getSelectedGroupByOption();
      const event = createCustomGuideCreationAnalyticEvent(
        this.data.creationSourceLabel,
        selectedGroupByOption.name,
      );
      this.analytics.trackAnalyticsEvent(event);

      if (this.shouldDuplicateCustomGuide) {
        this.customGuideFacade.duplicateCustomGuide(
          this.data.customGuideIdToDuplicate,
          this.newCustomGuideName,
          selectedGroupByOption.name,
        );
      } else {
        const materialNumbers = this.getMaterialNumbersToAddToGuide();
        this.customGuideFacade.createCustomGuide(
          this.newCustomGuideName,
          selectedGroupByOption.name,
          materialNumbers,
        );
      }
    }
  }

  isFormValid(): boolean {
    return this.customGuideNameFormControl.valid;
  }

  private getSelectedGroupByOption() {
    return NaooConstants.GROUP_BY_OPTIONS.find(
      (groupByOption) =>
        groupByOption.name === this.groupByFormControl.value.toString(),
    );
  }

  private getMaterialNumbersToAddToGuide(): string[] {
    if (this.data && this.data.materialNumbers) {
      return this.data.materialNumbers;
    } else {
      return [];
    }
  }
}
