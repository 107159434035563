import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActiveCartSummary } from './shared/models/active-cart-summary';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { MaterialAvailabilityFacade } from '../../../core/store/material-availability/material-availability.facade';
import { MaterialInfoFacade } from '../../../core/store/material-info/material-info.facade';
import { InventoryAvailabilityFacade } from '../../../core/store/inventory-availability/inventory-availability.facade';
import { filter, tap } from 'rxjs/operators';
import { PrintService } from '../../../shared/services/print/print.service';
import { CartFacade } from '../../../core/store/cart/cart.facade';
import { ActiveCartSummaryFacade } from '../../../core/store/active-cart/active-cart-summary.facade';
import { ActiveCartContentComponent } from './active-cart-content/active-cart-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-active-cart-container',
  template: `
    <naoo-active-cart-content
      [activeCartSummary]="activeCartSummary$ | async"
      [shouldRenderPrint]="shouldRenderPrint$ | async"
    ></naoo-active-cart-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ActiveCartContentComponent],
})
export class ActiveCartContainerComponent implements OnInit, OnDestroy {
  activeCartSummary$: Observable<ActiveCartSummary>;
  shouldRenderPrint$: Subject<boolean> = new BehaviorSubject(false);

  private destroyed$ = new Subject<void>();
  private needsLoad = true;

  constructor(
    private activeCartSummaryFacade: ActiveCartSummaryFacade,
    private availabilityFacade: MaterialAvailabilityFacade,
    private cartFacade: CartFacade,
    private infoFacade: MaterialInfoFacade,
    private inventoryFacade: InventoryAvailabilityFacade,
    private printService: PrintService,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.activeCartSummary$ = this.activeCartSummaryFacade
      .getActiveCartSummary()
      .pipe(
        filter((cartSummary) => !!cartSummary),
        tap((cartSummary) => {
          if (this.needsLoad) {
            this.needsLoad = false;
            this.loadData(
              cartSummary.materialNumbers.map((x) => x.value as string),
            );
          }
        }),
      );

    this.printService.setUp(
      () => {
        this.shouldRenderPrint$.next(true);
        // For Safari, to get updated DOM, in order to generate print preview
        this.changeDetectorRef.detectChanges();
        return of(true);
      },
      () => {
        this.shouldRenderPrint$.next(false);
      },
    );
  }

  ngOnDestroy(): void {
    this.cartFacade.clearDeletedCartMaterials();
    this.printService.tearDown();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadData(materialNumbers: string[]) {
    this.availabilityFacade.loadMaterialAvailabilities(materialNumbers);
    this.infoFacade.loadMaterialInfos(materialNumbers);
    this.inventoryFacade.refreshCartInventoryAvailability();
  }
}
