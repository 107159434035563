<div class="deleted-info">
  @if (isOK || isUnorderable) {
    <span class="material-description">
      {{ materialInfo.description | stringdefaulter }}
    </span>
  }

  @if (isUnavailable) {
    <span class="material-description">
      {{ 'PRODUCT.UNAVAILABLE_ITEM' | translate }}
    </span>
  }

  <span class="message">
    {{ 'CART.REMOVED_MATERIAL_MESSAGE' | translate }}
  </span>
</div>

@if (isOK && !isErrorProcessing) {
  <button
    class="restore-button"
    [attr.aria-label]="'CART.RESTORE_WCAG' | translate"
    (click)="restoreMaterial()"
  >
    <mat-icon svgIcon="refresh_icon"></mat-icon>
    {{ 'CART.RESTORE' | translate }}
  </button>
}
