import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { LocalizationService } from '../services/translation/localization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type DisplayType = 'code' | 'symbol' | 'symbol-narrow' | string | boolean;

@Pipe({
  name: 'naoocurrency',
  pure: false,
  standalone: true,
})
export class NaooCurrencyPipe
  extends CurrencyPipe
  implements PipeTransform, OnDestroy
{
  private destroyed$ = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    super(localizationService.currentLocale);
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this._ref?.markForCheck());
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    value: null | undefined,
    currencyCode?: string,
    display?: DisplayType,
    digitsInfo?: string,
  ): null;
  transform(
    value: number | string,
    currencyCode?: string,
    display?: DisplayType,
    digitsInfo?: string,
  ): string | null;
  transform(
    value: any,
    currencyCode?: string,
    display?: DisplayType,
    digitsInfo?: string,
  ): string | null {
    return super.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      this.localizationService.currentLocale,
    );
  }
}
