import { BannerAd, BannerAdCollection } from './banner-ads.state';
import { HomepageBannerAdSlotName } from '../../services/banner-ads/banner-ads.service';

export class BannerAdsTransformer {
  public static transformBannerAdsToCollection(
    bannerAds: BannerAd[],
  ): BannerAdCollection {
    const bannerMap = new Map<string, BannerAd>();
    bannerAds?.forEach((bannerAd) => {
      bannerMap.set(bannerAd?.divName, bannerAd);
    });

    return {
      bannerAds: [
        [bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot1)],
        [
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot2),
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot3),
        ],
        [
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot4),
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot5),
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot6),
        ],
        [bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot7)],
        [
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot8),
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot9),
        ],
        [
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot10),
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot11),
          bannerMap.get(HomepageBannerAdSlotName.BannerAdSlot12),
        ],
      ],
    };
  }
}
