import { Injectable } from '@angular/core';
import {
  ExportDataPoint,
  ExportFileType,
  untranslatedDataPoints,
} from '../../models/export/export-properties';
import { ExportService } from '../export-service/export.service';
import { formatDate } from '@angular/common';
import { ExportMaterialsRow } from './models/export-materials-row';
import { dateFormats } from '../../utilities/date-utilities';
import { from, Observable } from 'rxjs';
import { ExportMaterial, ExportMaterialsData } from './models/export-materials';
import { LocalizationService } from '../translation/localization.service';

@Injectable({
  providedIn: 'root',
})
export class ExportMaterialsFileService {
  constructor(
    private exportService: ExportService,
    private localizationService: LocalizationService,
  ) {}

  exportFile(
    exportMaterialsData: ExportMaterialsData,
    headerDataPoints: ExportDataPoint[],
  ): Observable<void> {
    const exportRows: ExportMaterialsRow[] = [
      this.buildHeaderRow(headerDataPoints),
    ];
    exportMaterialsData.materials.forEach((material) => {
      exportRows.push(this.buildMaterialRow(material));
    });

    const fileNameDate = formatDate(
      Date.now(),
      dateFormats[this.localizationService.currentLocale].exportDate,
      this.localizationService.currentLocale,
      '',
    );
    const fileName = `${exportMaterialsData.fileName}_${fileNameDate}`;

    switch (exportMaterialsData.fileType) {
      case ExportFileType.CSV:
        return from(this.exportService.downloadCsv(exportRows, fileName));
      case ExportFileType.EXCEL:
        return from(this.exportService.downloadXlsx(exportRows, fileName));
      default:
        throw new Error('Unexpected file type');
    }
  }

  private buildHeaderRow(
    headerDataPoints: ExportDataPoint[],
  ): ExportMaterialsRow {
    const exportRow: ExportMaterialsRow = {};
    headerDataPoints.forEach((headerDataPoint) => {
      exportRow[headerDataPoint] = this.localizationService.instant(
        `EXPORT_MODAL.EXPORT_ITEMS.${headerDataPoint}`,
      );
    });
    return exportRow;
  }

  private buildMaterialRow(material: ExportMaterial): ExportMaterialsRow {
    const exportRow: ExportMaterialsRow = {};
    Array.from(material.data.entries()).forEach(([key, value]) => {
      exportRow[key] =
        untranslatedDataPoints.includes(key) && value != null
          ? this.localizationService.instant(value)
          : value;
    });
    return exportRow;
  }
}
