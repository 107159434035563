@if (!(dismissed$ | async) && isVisible) {
  <div class="banner-message-container">
    <div class="messages" [ngClass]="{ expanded: !(collapsed$ | async) }">
      @if (collapsed$ | async) {
        <div class="collapsed-messages">
          {{ 'NOTIFICATION_BANNER.IMPORTANT_MESSAGES' | translate }}
        </div>
      }
      @if (!(collapsed$ | async)) {
        <div class="expanded-messages">
          <mat-icon class="important-icon">error</mat-icon>
          <div class="message-sources">
            @if (notificationMessage?.title || notificationMessage?.body) {
              <div class="system-notifications-container message-source">
                @if (notificationMessage.title) {
                  <div class="msg-title">
                    {{ notificationMessage.title | translate }}
                  </div>
                }
                <span class="msg-body"
                  >{{ notificationMessage.body | translate }}&#32;</span
                >
                @if (callback) {
                  <a (click)="executeCallback()">{{
                    'NOTIFICATION_BANNER.RETRY_TEXT' | translate
                  }}</a>
                }
                @if (notificationMessage.route) {
                  <a
                    id="router-link"
                    [routerLink]="notificationMessage.route.routerLink"
                    [queryParams]="notificationMessage.route.queryParams"
                    >{{ notificationMessage.route.text | translate }}</a
                  >
                }
              </div>
            }
            @for (
              customerMessage of customerMessages;
              track customerMessage.messageId
            ) {
              <div class="customer-message message-source">
                <div class="msg-title">
                  {{ customerMessage.title | stringdefaulter }}
                </div>
                <span class="msg-body"
                  >{{ customerMessage.bodyText | stringdefaulter }}&#32;</span
                >
                @if (customerMessage.linkUrl) {
                  <a
                    [href]="customerMessage.linkUrl | stringdefaulter"
                    target="_blank"
                  >
                    <span
                      >{{
                        customerMessage.linkLabel || customerMessage.linkUrl
                          | stringdefaulter
                      }}&nbsp;<mat-icon
                        class="open-in-new-window"
                        [attr.aria-hidden]="true"
                        >open_in_new</mat-icon
                      >
                    </span>
                  </a>
                }
              </div>
            }
          </div>
        </div>
      }
      <button
        class="toggle-button"
        (click)="toggle()"
        [attr.aria-label]="
          (!(collapsed$ | async)
            ? 'NOTIFICATION_BANNER.IMPORTANT_MESSAGES_LABEL_COLLAPSE'
            : 'NOTIFICATION_BANNER.IMPORTANT_MESSAGES_LABEL_EXPAND'
          ) | translate
        "
      >
        <mat-icon
          class="toggle-icon"
          [@rotate]="(collapsed$ | async) ? ROTATE_180 : ROTATE_DEFAULT"
          [attr.aria-hidden]="true"
          >expand_less</mat-icon
        >
      </button>
    </div>
    <div class="dismiss-button-container">
      @if (!(collapsed$ | async)) {
        <button
          mat-button
          disableRipple
          class="dismiss-button"
          (click)="dismiss()"
        >
          {{ 'NOTIFICATION_BANNER.DISMISS' | translate }}
        </button>
      }
    </div>
  </div>
}
