import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  MatSelectChange,
  MatSelect,
  MatSelectTrigger,
} from '@angular/material/select';
import { MaterialListStyle } from '../../core/store/material-row/models/material-row';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import { NgClass } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

interface MaterialViewOption {
  icon: string;
  title: string;
  materialListStyle: MaterialListStyle;
}

@Component({
  selector: 'naoo-material-view-select',
  templateUrl: './material-view-select.component.html',
  styleUrls: ['./material-view-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    MatFormField,
    MatSelect,
    MatSelectTrigger,
    MatIcon,
    MatOption,
    TranslateModule,
  ],
})
export class MaterialViewSelectComponent {
  private static readonly materialViews: {
    [materialViewName in MaterialListStyle]: MaterialViewOption;
  } = {
    [MaterialListStyle.Slim]: {
      icon: 'slim_icon',
      title: 'MATERIAL_VIEW_TOGGLE.SLIM',
      materialListStyle: MaterialListStyle.Slim,
    },
    [MaterialListStyle.SlimGrid]: {
      // not implemented - only to stop compile warning
      icon: 'grid_icon_v2',
      title: 'MATERIAL_VIEW_TOGGLE.GRID',
      materialListStyle: MaterialListStyle.Grid,
    },
    [MaterialListStyle.Grid]: {
      icon: 'grid_icon_v2',
      title: 'MATERIAL_VIEW_TOGGLE.GRID',
      materialListStyle: MaterialListStyle.Grid,
    },
    [MaterialListStyle.List]: {
      icon: 'list_icon_v2',
      title: 'MATERIAL_VIEW_TOGGLE.LIST',
      materialListStyle: MaterialListStyle.List,
    },
  };

  @Input() set selectedViewStyle(view: MaterialListStyle) {
    this.preferredView = this.transformMaterialViewOption(view);
  }
  @Input() set viewStyleOptions(options: MaterialListStyle[]) {
    this.materialViewOptions = options.map(this.transformMaterialViewOption);
  }
  @Input() disabled = false;
  @Input() shouldHideLabel = false;

  @Output() onMaterialViewChange = new EventEmitter<MaterialListStyle>();

  constructor(private materialRowFacade: MaterialRowFacade) {}

  preferredView: MaterialViewOption;
  materialViewOptions: MaterialViewOption[];

  onPreferredViewChange(event: MatSelectChange) {
    this.materialRowFacade.updatePreferredMaterialView(event.value);
    this.onMaterialViewChange.emit(event.value);
  }

  private transformMaterialViewOption(
    materialView: MaterialListStyle,
  ): MaterialViewOption {
    return MaterialViewSelectComponent.materialViews[materialView];
  }
}
