import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SubmittedCart,
  SubmittedCartStatus,
} from '../../../../core/store/multiple-carts/multiple-carts.state';
import { SavedCartService } from '../shared/saved-cart.service';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../../../shared/pipes/naoo-date.pipe';

@Component({
  selector: 'naoo-submitted-cart-row',
  templateUrl: './submitted-cart-row.component.html',
  styleUrls: ['./submitted-cart-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, RouterLink, TranslateModule, NaooDatePipe],
})
export class SubmittedCartRowComponent {
  @Input() shouldPromptActivateModal: boolean;
  @Input() submittedCart: SubmittedCart;
  @Input() isOffline: boolean;
  @Input() isSelectedCart: boolean;
  @Input() isPreviewOpen = false;

  readonly awaitingApprovalStatus = SubmittedCartStatus.AwaitingApproval;
  readonly deletedStatus = SubmittedCartStatus.Deleted;
  readonly approvedStatus = SubmittedCartStatus.SubmittedAndApproved;
  readonly ordersPath = NaooConstants.ORDERS_PATH;

  constructor(private savedCartService: SavedCartService) {}

  activateCart(): void {
    this.savedCartService.activateCart(
      this.submittedCart,
      this.shouldPromptActivateModal,
    );
  }

  deleteCart() {
    this.savedCartService.deleteCart(this.submittedCart.id);
  }

  get getSubmittedCartStatusIcon(): string {
    switch (this.submittedCart.submittedCartStatus) {
      case SubmittedCartStatus.InReview:
        return NaooConstants.icons.review_icon.name;
      case SubmittedCartStatus.AwaitingApproval:
        return NaooConstants.icons.clock_filled.name;
      case SubmittedCartStatus.SubmittedAndApproved:
        return NaooConstants.icons.success.name;
      case SubmittedCartStatus.Deleted:
      default:
        return NaooConstants.icons.exception_icon_v1.name;
    }
  }

  get isValidSubmittedCart(): boolean {
    return (
      !this.submittedCart.truckFulfillment?.routeDate &&
      !this.submittedCart.customerPoNumber
    );
  }

  togglePreview(): void {
    if (this.isSelectedCart || !this.isPreviewOpen) {
      this.savedCartService.togglePreview(!this.isPreviewOpen);
    }
    this.savedCartService.setCartPreviewState(this.submittedCart);
  }
}
