@if (!!savedCart) {
  <div class="saved-cart-container">
    <div class="last-updated-content">
      <span class="last-updated-details">
        <div class="last-updated-title">
          {{ 'CART.LAST_UPDATED' | translate }}
        </div>
        <div>
          {{
            savedCart.lastUpdatedTimestamp
              | naooDate: 'longDateNoDayAbbrMo' : 'UTC'
          }}
        </div>
      </span>
      <div class="last-updated-customer-container">
        @if (savedCart.lastUpdatedUserName || savedCart.lastUpdatedUserEmail) {
          <div class="last-updated-customer-info">
            @if (savedCart.lastUpdatedUserName) {
              <span id="userName">
                {{ savedCart.lastUpdatedUserName }}
              </span>
            }
            @if (savedCart.lastUpdatedUserEmail) {
              <span id="userEmail">
                {{ savedCart.lastUpdatedUserEmail }}
              </span>
            }
          </div>
        }
        @if (isValidSavedCart) {
          <div class="cart-info-content-mobile">
            <mat-icon svgIcon="cart_icon_v2"></mat-icon>
            {{ savedCart.totalQuantity }}
          </div>
        }
      </div>
    </div>
    <div
      class="shipment-info-content"
      [ngClass]="{
        'hide-container': isValidSavedCart,
      }"
    >
      <div class="shipment-details">
        @if (SavedCartRequestDate) {
          <div
            class="route-date-container"
            [ngClass]="{
              'ship-date-gap': savedCart.customerPoNumber,
            }"
          >
            <div class="route-date-title">
              {{ SavedCartDateDisplay | translate }}
            </div>
            <div>
              {{
                SavedCartRequestDate | naooDate: 'longDateNoDayAbbrMo' : 'UTC'
              }}
            </div>
          </div>
        }
        @if (savedCart.customerPoNumber) {
          <span id="customer-po-number">
            {{ 'CART.SAVED_CARTS.PO' | translate }}
            {{ savedCart.customerPoNumber }}
          </span>
        }
      </div>
      <div class="cart-info-content">
        <mat-icon svgIcon="cart_icon_v2"></mat-icon>
        {{ savedCart.totalQuantity }}
      </div>
    </div>
    <div class="status-placeholder-content"></div>
    <div class="button-container-content">
      @if (!hideDelete) {
        <div class="delete-button-container">
          <button
            type="button"
            class="delete-button ghost-button"
            (click)="deleteCart()"
            [attr.aria-label]="'CART.DELETE_BUTTON_ARIA_TEXT' | translate"
            [disabled]="isOffline"
          >
            <mat-icon
              [ngClass]="{ disabled: isOffline }"
              svgIcon="trash-icon-v3"
              class="trash-icon"
            ></mat-icon>
            <div class="button-text" [ngClass]="{ disabled: isOffline }">
              {{ 'CART.DELETE_BUTTON' | translate }}
            </div>
          </button>
        </div>
      }
      <button
        type="button"
        class="preview-button secondary-button"
        [ngClass]="{
          'preview-button-pressed':
            isOffline || (isPreviewOpen && isSavedCartSelected),
        }"
        (click)="togglePreview()"
        [disabled]="isOffline"
      >
        {{ 'CART.PREVIEW_BUTTON' | translate }}
      </button>
      <button
        type="button"
        class="activate-button primary-button"
        (click)="activateCart()"
        [disabled]="isOffline"
      >
        {{ 'CART.ACTIVATE_BUTTON' | translate }}
      </button>
    </div>
  </div>
}
