<div class="back-button-container">
  <div class="breadcrumb-container">
    @if (isMobile) {
      <naoo-back-button
        [navigationLink]="backNavigationLink"
      ></naoo-back-button>
    } @else {
      <naoo-breadcrumb [breadcrumbs]="breadcrumbs"></naoo-breadcrumb>
    }
  </div>
</div>
<div class="page-header">
  <div class="title-container">
    <span class="page-title">
      {{ 'LISTS.EDIT' | translate }}
      <strong>{{ 'MENU.ORDER_GUIDE' | translate }}</strong></span
    >
    @if (totalMaterials > 0) {
      <span class="product-count"
        >({{ totalMaterials }}
        {{
          (totalMaterials !== 1
            ? 'ORDER_GUIDE.PRODUCTS'
            : 'ORDER_GUIDE.PRODUCT'
          ) | translate
        }})</span
      >
    }
  </div>
  <div class="header-button-container">
    <button
      class="primary-button large-button exit-guide-button"
      [disabled]="isLoading"
      attr.aria-label="{{ 'LISTS.SAVE_AND_EXIT.PRIMARY' | translate }}"
      (click)="doneEditing()"
    >
      {{ 'LISTS.SAVE_AND_EXIT.PRIMARY' | translate }}
    </button>
    @if (isMobile) {
      <button
        attr.aria-label="{{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}"
        class="secondary-button large-button new-category-button new-category-mobile-button"
        (click)="openCreateCategoryModal()"
      >
        + {{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}
      </button>
    }
  </div>
</div>
@if (isLoading) {
  <div class="loading-container">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    <div class="header">{{ 'LOADING' | translate }}</div>
  </div>
}
<div class="guide-content" [ngClass]="{ hide: isLoading }" cdkDropListGroup>
  <div class="guide-navigation-container">
    <div class="new-category">
      <button
        attr.aria-label="{{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}"
        class="secondary-button large-button new-category-button"
        (click)="openCreateCategoryModal()"
      >
        + {{ 'LISTS.CREATE_A_NEW_CATEGORY' | translate }}
      </button>
    </div>
    <div class="guide-navigation" id="category-sidebar">
      <ul>
        <div class="category-title-drag">
          @for (
            categorizedMaterial of categorizedMaterials;
            track categorizedMaterial.categoryId;
            let isFirst = $first;
            let index = $index;
            let isLast = $last
          ) {
            <div>
              <li>
                <div
                  class="category-element"
                  [ngClass]="{ 'add-margin': isLast }"
                >
                  <div class="category-title-container">
                    <div class="category-text-container">
                      @if (isGfsCategory(categorizedMaterial)) {
                        <div class="gfs-icon">
                          <naoo-edit-order-guide-gfs-icon></naoo-edit-order-guide-gfs-icon>
                        </div>
                      }
                      <span
                        >{{
                          categorizedMaterial.categoryName.en ||
                            ('ORDER_GUIDE.OTHER' | translate)
                        }}
                        - ({{ categorizedMaterial.items?.length || 0 }})</span
                      >
                    </div>
                    @if (categorizedMaterial.categoryName.en) {
                      <div class="edit-category-buttons">
                        <button
                          tabindex="0"
                          attr.aria-label="{{
                            isUpArrowActive(isFirst)
                              ? ('LISTS.MOVE_CATEGORY_UP_BUTTON' | translate)
                              : ('LISTS.MOVE_CATEGORY_UP_BUTTON_DISABLED'
                                | translate)
                          }}"
                          mat-icon-button
                          class="mat-icon-button up-arrow"
                          [ngClass]="{
                            'active-button':
                              isUpArrowActive(isFirst) && isCustom,
                          }"
                          [disabled]="!isUpArrowActive(isFirst) || !isCustom"
                          (click)="moveUp(categorizedMaterial)"
                        >
                          <mat-icon
                            svgIcon="category_up_active"
                            [ngClass]="{
                              'inactive-svg':
                                !isUpArrowActive(isFirst) || !isCustom,
                            }"
                          ></mat-icon>
                        </button>
                        <button
                          tabindex="0"
                          attr.aria-label="{{
                            isDownArrowActive(isLast)
                              ? ('LISTS.MOVE_CATEGORY_DOWN_BUTTON' | translate)
                              : ('LISTS.MOVE_CATEGORY_DOWN_BUTTON_DISABLED'
                                | translate)
                          }}"
                          mat-icon-button
                          class="mat-icon-button down-arrow"
                          [ngClass]="{
                            'active-button':
                              isDownArrowActive(isLast) && isCustom,
                          }"
                          [disabled]="!isDownArrowActive(isLast) || !isCustom"
                          (click)="moveDown(categorizedMaterial)"
                        >
                          <mat-icon
                            svgIcon="category_down_active"
                            [ngClass]="{
                              'inactive-svg':
                                !isDownArrowActive(isLast) || !isCustom,
                            }"
                          >
                          </mat-icon>
                        </button>
                      </div>
                    }
                  </div>
                  <div class="products-container">
                    <div
                      class="item-drag item-bucket"
                      [ngClass]="{
                        disabled: isGfsCategory(categorizedMaterial),
                      }"
                      cdkDropList
                      [cdkDropListData]="index.toString()"
                      (cdkDropListDropped)="drop($event)"
                    >
                      <div class="help-text no-drag">
                        {{ 'LISTS.DROP_PRODUCT_HERE' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          }
        </div>
      </ul>
    </div>
  </div>
  <div class="guide-products-container">
    <div class="guide-actions">
      <div class="sort-by-action">
        <span class="sort-by-label">{{
          'LISTS.SORT_ITEMS_BY.LABEL' | translate
        }}</span>
        <mat-form-field class="sort-by-field">
          <mat-select
            [value]="sortBy"
            [disabled]="true"
            aria-label="{{ 'LISTS.SORT_ITEMS_BY.LABEL' | translate }}"
          >
            <mat-option [value]="sortBy">
              {{ 'LISTS.SORT_ITEMS_BY.CUSTOM_OPTION' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="product-list-container">
      @for (
        categorizedMaterialRecord of categorizedMaterials;
        track categorizedMaterialRecord.categoryId;
        let index = $index;
        let isFirst = $first;
        let isLast = $last
      ) {
        <div class="categorized-products">
          <naoo-edit-order-guide-category
            [sortBy]="sortBy"
            [categoryIndex]="index"
            [categorizedMaterial]="categorizedMaterialRecord"
            [isUpArrowActive]="isUpArrowActive(isFirst)"
            [isDownArrowActive]="isDownArrowActive(isLast)"
            [enableParOrdering]="false"
            [materialParOrders]="materialParOrders"
            [isGfsCategory]="isGfsCategory(categorizedMaterialRecord)"
            [isMobile]="isMobile"
            (moveCategorizedMaterial)="moveCategory($event)"
            (actOnMaterialInCategorizedMaterial)="
              actOnMaterialInACategory($event)
            "
            (renameCategory)="actOnRenameCategory($event)"
            (deleteCategory)="actOnDeleteCategory($event)"
          >
          </naoo-edit-order-guide-category>
        </div>
      }
    </div>
  </div>
</div>
