import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectActiveCustomer,
  selectActiveCustomerPermissions,
  selectActiveCustomerTimezone,
  selectCurrentSystem,
  selectCustomerBrand,
  selectHasIspuOrExpressEnabled,
  selectHasPermissionEnabled,
  selectHasShowPurchaseHistoryEnabled,
  selectInactiveCustomers,
  selectIsCustomerUpdated,
  selectIsFeatureEnabled,
  selectIsLockedToOrderGuide,
  selectIsLoyaltyProgramEligible,
  selectIsMultiCustomerUnit,
  selectIsSpecialOrderDashboardAccess,
  selectLocale,
  selectPrimarySite,
  selectPrimarySiteId,
  selectSession,
} from './session.selectors';
import { filter, map } from 'rxjs/operators';
import {
  CurrentSystem,
  CustomerBrand,
  CustomerPermission,
  FeatureFlag,
  Language,
  Locale,
  SessionActiveCustomer,
  SessionCustomer,
  SessionPermittedApplications,
  SessionRecord,
  Site,
} from '../../services/session/models/session-record';
import { SessionActions } from './session.actions';

@Injectable({ providedIn: 'root' })
export class SessionFacade {
  constructor(private readonly store: Store) {}

  getLoadedSession(): Observable<SessionRecord> {
    return this.store
      .select(selectSession)
      .pipe(filter((sessionRecord) => !!sessionRecord));
  }

  getLoadedActiveCustomer(): Observable<SessionActiveCustomer> {
    return this.store
      .select(selectActiveCustomer)
      .pipe(filter((customer) => !!customer));
  }

  getLoadedActiveCustomerPermittedApps(): Observable<SessionPermittedApplications> {
    return this.store
      .select(selectActiveCustomerPermissions)
      .pipe(filter((permissions) => !!permissions));
  }

  getLoadedNonActiveCustomers(): Observable<SessionCustomer[]> {
    return this.store
      .select(selectInactiveCustomers)
      .pipe(filter((inactiveCustomers) => !!inactiveCustomers));
  }

  getLoadedActiveCustomerTimeZone(): Observable<string> {
    return this.store
      .select(selectActiveCustomerTimezone)
      .pipe(filter((timeZone) => timeZone !== undefined));
  }

  getLoadedCurrentSystem(): Observable<CurrentSystem> {
    return this.store
      .select(selectCurrentSystem)
      .pipe(filter((currentSystem) => !!currentSystem));
  }

  getLoadedLocale(): Observable<Locale> {
    return this.store.select(selectLocale).pipe(filter((locale) => !!locale));
  }

  getLoadedCustomerBrand(): Observable<CustomerBrand> {
    return this.store
      .select(selectCustomerBrand)
      .pipe(filter((brand) => !!brand));
  }

  getPrimarySiteId(): Observable<string> {
    return this.store.select(selectPrimarySiteId);
  }

  getPrimarySite(): Observable<Site> {
    return this.store.select(selectPrimarySite);
  }

  isPermissionEnabled(permission: CustomerPermission): Observable<boolean> {
    return this.store.select(selectHasPermissionEnabled(permission));
  }

  isFeatureEnabled(feature: FeatureFlag): Observable<boolean> {
    return this.store.select(selectIsFeatureEnabled(feature));
  }

  hasIspuOrExpressPermissions(): Observable<boolean> {
    return this.store.select(selectHasIspuOrExpressEnabled);
  }

  hasStorePurchaseHistoryPermissions(): Observable<boolean> {
    return this.store.select(selectHasShowPurchaseHistoryEnabled);
  }

  isCustomerUpdated(): Observable<boolean> {
    return this.store.select(selectIsCustomerUpdated);
  }

  isMultiCustomerUnit(): Observable<boolean> {
    return this.store.select(selectIsMultiCustomerUnit);
  }

  isCurrentlyMigrating(): Observable<boolean> {
    return this.getLoadedCurrentSystem().pipe(
      map((currentSystem) => currentSystem === CurrentSystem.Migrating),
    );
  }

  isLockedToOrderGuide(): Observable<boolean> {
    return this.store
      .select(selectIsLockedToOrderGuide)
      .pipe(
        filter((isLockedToOrderGuide) => isLockedToOrderGuide !== undefined),
      );
  }

  isSpecialOrderDashboardAccess(): Observable<boolean> {
    return this.store.select(selectIsSpecialOrderDashboardAccess);
  }

  isUsCustomer(): Observable<boolean> {
    return this.getLoadedCustomerBrand().pipe(
      map((brand) => brand === CustomerBrand.GFS_US),
    );
  }

  loadSession(): void {
    this.store.dispatch(SessionActions.getSession());
  }

  updateLanguage(language: Language): void {
    this.store.dispatch(SessionActions.updateSelectedLanguage(language));
  }

  updateSelectedCustomer(compositeCustomerId: string): void {
    this.store.dispatch(
      SessionActions.updateSelectedCustomer(compositeCustomerId),
    );
  }

  isLoyaltyProgramEligible(): Observable<boolean> {
    return this.store.select(selectIsLoyaltyProgramEligible);
  }
}
