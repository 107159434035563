@for (
  button of buttons;
  track button.text;
  let index = $index;
  let isFirst = $first
) {
  @if (!isFirst && buttonSpacing > 0) {
    <div class="spacer" [style.height.px]="buttonSpacing"></div>
  }
  <naoo-selectable-button
    #selectableButton
    [isSelected]="index === selectedIndex"
    [text]="button.text"
    [icon]="button.icon"
    [isDisabled]="button.disabled"
    (didClick)="didSelectButton(index)"
  >
  </naoo-selectable-button>
}
