<div class="product-class-card">
  <h2>
    <a
      href="javascript:void(0)"
      class="product-class-title-link"
      attr.aria-label="{{ 'CATEGORIES.CLASS' | translate }} {{
        category.name | stringdefaulter
      }}"
      (click)="navigateToCategories(category.categoryKey)"
    >
      <img
        class="category-image"
        [src]="catalogImageSrc"
        [srcset]="catalogImageSrcSet"
        (error)="noImageAvailableURL($event)"
        [alt]="category.name | stringdefaulter"
      />{{ category.name | stringdefaulter }}
    </a>
  </h2>
  <div class="product-subclass-container">
    @for (
      productSubclass of category.subCategories;
      track productSubclass.categoryKey
    ) {
      <naoo-product-subclass
        [category]="productSubclass"
        (navigateToCategoriesPage)="navigateToCategories($event)"
      ></naoo-product-subclass>
    }
    @if (category.subCategories.length % 3 !== 0) {
      <div class="product-subclass product-subclass-stub"></div>
    }
    @if (category.subCategories.length % 3 === 1) {
      <div class="product-subclass product-subclass-stub"></div>
    }
  </div>
</div>
