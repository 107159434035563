@if (isDeleteOnly) {
  <button
    attr.aria-label="{{ 'SHARED.DELETE' | translate }}"
    mat-icon-button
    class="secondary-button trash-button"
    (click)="openRemoveModal()"
  >
    <mat-icon class="icon" svgIcon="trash-icon"></mat-icon>
  </button>
} @else {
  <button
    attr.aria-label="{{ 'LISTS.MENU_OPTIONS' | translate }}"
    mat-icon-button
    class="mat-icon-button"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon svgIcon="triple_dot_menu"></mat-icon>
  </button>
}
<mat-menu #menu="matMenu" xPosition="before" (closed)="onMenuClosed()">
  <ng-template matMenuContent>
    <div #popoverMenu>
      @if (categoryNames.length) {
        <button
          mat-menu-item
          class="primary-action"
          role="button"
          [ngClass]="{ 'expanded-section': isPanelExpanded }"
          (click)="expandMoveToCategory($event)"
        >
          <div class="action-container">
            <div class="action-label">
              {{ 'LISTS.MOVE_TO_A_DIFFERENT_CATEGORY' | translate }}
            </div>
            <img
              [@rotate]="rotationState"
              class="action-arrow"
              src="assets/images/arrow.svg"
              alt=""
            />
          </div>
        </button>
      }
      @if (!isOrderGuideEdit && !categoryNames.length) {
        <button
          mat-menu-item
          class="menu-button"
          role="button"
          (click)="openCreateCustomGuideCategoryModal()"
        >
          {{ 'LISTS.CREATE_NEW_CATEGORY' | translate }}
        </button>
      }
      @if (isPanelExpanded) {
        <div
          [attr.aria-hidden]="!isPanelExpanded"
          [style.height.px]="expandedMenuHeight"
          class="d1"
        >
          <div class="d2">
            <div class="d3">
              <div class="d4">
                @if (isOrderGuideEdit) {
                  <div>
                    <button
                      mat-menu-item
                      id="create-category-button"
                      class="menu-button"
                      role="button"
                      (click)="openCreateCategoryModal()"
                    >
                      {{ 'LISTS.CREATE_NEW_CATEGORY' | translate }}
                    </button>
                    @if (!isGfsCategory) {
                      <button
                        mat-menu-item
                        id="organize-for-me-button"
                        class="menu-button"
                        role="button"
                        (click)="moveProductInMenuWithOrganizeForMe()"
                      >
                        <div class="organize-for-me-container">
                          <img
                            class="gfs-icon-title"
                            alt=""
                            src="/assets/images/gfs-icon.png"
                          />
                          <div class="organize-for-me-menu-button">
                            {{ 'LISTS.ORGANIZE_FOR_ME' | translate }}
                          </div>
                        </div>
                      </button>
                    }
                  </div>
                }
                @for (categoryName of categoryNames; track categoryName) {
                  <button
                    class="mat-menu-item submenu-item"
                    role="button"
                    (click)="moveProductInMenu(categoryName)"
                    mat-menu-item
                  >
                    {{
                      (categoryName | stringdefaulter) ||
                        ('ORDER_GUIDE.OTHER' | translate)
                    }}
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <button
        mat-menu-item
        class="menu-button"
        role="button"
        (click)="menuAction = openRemoveModal"
      >
        {{ 'LISTS.REMOVE_PRODUCT_FROM_GUIDE' | translate }}
      </button>
    </div>
  </ng-template>
</mat-menu>
<button class="hidden-menu-trigger" [matMenuTriggerFor]="menu"></button>
