import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CartReviewSection } from '../../../../core/store/cart-review/cart-review.state';
import { CartCounts } from '../../../../core/store/cart/cart.selectors';
import { CartReviewExpansionComponent } from '../cart-review-expansion/cart-review-expansion.component';

@Component({
  selector: 'naoo-cart-review-truck-container',
  template: `<naoo-cart-review-expansion
    [section]="section"
    [cartCounts]="cartCounts"
    (expansionToggled)="expansionToggled.emit($event)"
    [submitAttempted]="submitAttempted"
    [isSectionInvalid]="isSectionInvalid"
    [isOffline]="isOffline"
  >
  </naoo-cart-review-expansion>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CartReviewExpansionComponent],
})
export class CartReviewTruckContainerComponent {
  @ViewChild(CartReviewExpansionComponent, { static: true })
  cartReviewExpansionComponent: CartReviewExpansionComponent;
  @Input() section: CartReviewSection;
  @Input() cartCounts: CartCounts;
  @Input() isSectionInvalid: boolean;
  @Input() isOffline: boolean;
  @Input() submitAttempted: boolean;
  @Output() expansionToggled = new EventEmitter();

  expandSection(): void {
    this.cartReviewExpansionComponent.expandSection();
  }

  scrollToContent(): void {
    this.cartReviewExpansionComponent.scrollToContent();
  }
}
