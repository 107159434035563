<div class="nutrition-info">
  <div class="nutrition-blocks">
    @for (group of blockGroups; track $index) {
      <div class="block-container">
        @for (block of group; track block.label) {
          <ng-container
            [ngTemplateOutlet]="renderBlock"
            [ngTemplateOutletContext]="{ block: block }"
          >
          </ng-container>
        }
      </div>
    }
  </div>
</div>

<ng-template #renderBlock let-block="block">
  <h4>{{ block.label }}</h4>
  @for (line of block.lineItems; track line.label; let last = $last) {
    <div class="nutrition-block-line" [ngClass]="{ last: last }">
      {{ line.label }}
      <span>{{ parsedAmount(line.amount, line.uom) }}</span>
    </div>
  }
  <div class="break"></div>
</ng-template>
