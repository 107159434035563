import { ProductInfo } from './product-info';
import { MaterialInfo } from './material-info';

export interface GuideItem {
  order: number;
  product?: ProductInfo;
  material?: MaterialInfo;
}

export function buildGuideItemPlaceholder(): GuideItem {
  return {
    order: 0,
    material: {
      materialNumber: null,
      brand: {
        id: null,
        en: null,
        fr: null,
        es: null,
      },
      description: {
        en: null,
        fr: null,
        es: null,
      },
      innerPackSize: null,
      units: [
        {
          uom: null,
          displayCode: null,
          parentUom: null,
          qtyInParent: null,
          primaryGtin: null,
          alternateGtins: '',
        },
      ],
      baseUom: null,
      baseUomWeight: {
        net: '0',
        gross: null,
        uom: null,
      },
      isCatchWeight: false,
    },
    product: {
      offeringId: null,
      brand: {
        id: null,
        en_CA: null,
        fr_CA: null,
      },
      description: {
        en: null,
        fr: null,
        es: null,
      },
      innerPackSize: null,
      qtyPerMasterSellUnit: 0,
      gtin: null,
      catchWeightInd: false,
      netWeightKg: 0,
      isEmpty: false,
    },
  };
}
