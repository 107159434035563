import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MarketingGuideActions } from './marketing-guide.actions';
import { filter, Observable } from 'rxjs';
import { MarketingGuideRecord } from '../../services/marketing-guide/models/marketing-guide-record';
import {
  selectIsMarketingGuideLoaded,
  selectMarketingGuideExportMaterials,
  selectMarketingGuideRecord,
  selectMarketingGuideViewModel,
} from './marketing-guide.selectors';
import { switchMap } from 'rxjs/operators';
import { MarketingGuideViewModel } from './models/marketing-guide';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';

@Injectable({ providedIn: 'root' })
export class MarketingGuideFacade {
  constructor(private store: Store) {}

  loadMarketingGuide(pathUrl: string): void {
    this.store.dispatch(MarketingGuideActions.getMarketingGuide(pathUrl));
  }

  getLoadedMarketingGuideByPathUrl(
    pathUrl: string,
  ): Observable<MarketingGuideRecord> {
    return this.store.select(selectIsMarketingGuideLoaded(pathUrl)).pipe(
      filter((isGuideLoaded) => isGuideLoaded),
      switchMap(() => this.store.select(selectMarketingGuideRecord(pathUrl))),
    );
  }

  getMarketingGuideViewModel(
    pathUrl: string,
  ): Observable<MarketingGuideViewModel> {
    return this.store.select(selectMarketingGuideViewModel(pathUrl));
  }

  updateMarketingGuideSearchText(pathUrl: string, searchText: string): void {
    this.store.dispatch(
      MarketingGuideActions.updateMarketingGuideSearchText(pathUrl, searchText),
    );
  }

  getExportMaterialsInput(
    pathUrl: string,
    isPrint: boolean,
  ): Observable<ExportMaterialsInput> {
    return this.store.select(
      selectMarketingGuideExportMaterials(pathUrl, isPrint),
    );
  }
}
