<div class="title">
  {{ getNotificationsText('TITLE') | translate }}
</div>
@if (notifications) {
  <naoo-preferences-header
    [notifications]="notifications"
    [customer]="notifications.customer"
    [methodCode]="methodCode"
    (addRequest)="processAdd()"
  ></naoo-preferences-header>
  <div class="body-container">
    @if (notifications.customerNotifications.length > 0) {
      @for (
        notification of notifications.customerNotifications;
        track notification.contactInfo
      ) {
        <div class="notification-row">
          @if (isEmail) {
            @if (isActive(notification)) {
              <naoo-preferences-row-editing-email
                [notification]="notification"
                [isRetalix]="isRetalix"
                [allNotifications]="notifications.customerNotifications"
                [allValidNotificationSubscriptionTypes]="
                  notifications.enabledSubscriptionTypes
                "
                [defaultLanguage]="language"
                [methodCode]="methodCode"
                (saveRequest)="processSave($event)"
                (cancelRequest)="processCancel()"
                (hasUnsavedChanges)="hasPendingChanges($event)"
              ></naoo-preferences-row-editing-email>
            }
          } @else if (isActive(notification)) {
            <naoo-preferences-row-editing-phone
              [notification]="notification"
              [isRetalix]="isRetalix"
              [allValidNotificationSubscriptionTypes]="
                notifications.enabledSubscriptionTypes
              "
              [defaultLanguage]="language"
              [methodCode]="methodCode"
              [reminderDetails]="notifications?.reminderDetails"
              (saveRequest)="processSave($event)"
              (cancelRequest)="processCancel()"
              (hasUnsavedChanges)="hasPendingChanges($event)"
            >
            </naoo-preferences-row-editing-phone>
          }
          @if (!isActive(notification)) {
            <naoo-preferences-row-readonly
              [notification]="notification"
              [isRetalix]="isRetalix"
              (editRequest)="processEdit($event)"
              (deleteRequest)="processDelete($event)"
              [methodCode]="methodCode"
            ></naoo-preferences-row-readonly>
          }
        </div>
      }
    } @else {
      <div class="notification-row no-emails">
        <span class="no-emails-prompt">{{
          getNotificationsText('NO_DATA') | translate
        }}</span>
      </div>
    }
  </div>
} @else {
  <div class="loading-container">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    <div class="header">{{ 'LOADING' | translate }}</div>
  </div>
}
