import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-remove-from-critical-items-guide',
  templateUrl: './remove-from-critical-items-guide-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class RemoveFromCriticalItemsGuideToastComponent {}
