import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GroupByType, GuideSideBar, SortByType } from '../guides';
import { MaterialListStyle } from 'src/app/core/store/material-row/models/material-row';
import { MatIcon } from '@angular/material/icon';
import { GuidesSideBarComponent } from '../guides-side-bar/guides-side-bar.component';
import { MaterialViewSelectComponent } from '../../../shared/material-view-select/material-view-select.component';
import { GuidesParActionsComponent } from '../guides-par-actions/guides-par-actions.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-mobile-actions-list',
  templateUrl: './guides-mobile-actions-list.component.html',
  styleUrls: ['./guides-mobile-actions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    GuidesSideBarComponent,
    MaterialViewSelectComponent,
    GuidesParActionsComponent,
    TranslateModule,
  ],
})
export class GuidesMobileActionsListComponent {
  @Input() sideBar: GuideSideBar;
  @Input() preferredView: MaterialListStyle;
  @Input() isOffline: boolean;
  @Input() isSortByDisabled?: boolean;
  @Input() isParEnabled?: boolean;

  @Output() closeMobileActions = new EventEmitter();
  @Output() groupByChange = new EventEmitter<GroupByType>();
  @Output() sortByChange = new EventEmitter<SortByType>();
  @Output() clearInventoryQuantities = new EventEmitter<void>();
  @Output() toggleParOrdering = new EventEmitter<boolean>();

  readonly selectableViews = [MaterialListStyle.List, MaterialListStyle.Slim];

  get shouldRenderParContainer(): boolean {
    return this.isParEnabled !== undefined;
  }

  closeMobileActionModal() {
    this.closeMobileActions.emit();
  }

  handleGroupByChanged(groupBy: GroupByType) {
    this.groupByChange.emit(groupBy);
  }

  handleSortByChanged(sortBy: SortByType) {
    this.sortByChange.emit(sortBy);
  }

  handleClearInventoryQuantities() {
    this.clearInventoryQuantities.emit();
  }

  handleToggleParOrdering(isEnabled: boolean) {
    this.toggleParOrdering.emit(isEnabled);
  }
}
