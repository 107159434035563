<ng-container>
  <div class="header hamburger-box">
    <div class="ordering">
      <naoo-header-tabs [isOffline]="isOffline$ | async"></naoo-header-tabs>
    </div>
    <button
      class="close"
      type="button"
      (click)="navigateBack()"
      [attr.aria-label]="'MENU.CLOSE.ALT_TEXT' | translate"
    >
      <mat-icon svgIcon="clear_icon_v2"></mat-icon>
    </button>
  </div>

  <div
    class="scene-element scene-element-fadeinleft container hamburger-menu-container"
  >
    <div class="hamburger-menu-top hamburger-box">
      <div class="top-item">
        <naoo-cart-icon [wideLayout]="true"></naoo-cart-icon>
      </div>
      <div class="top-item">
        @if (hasIspuOrExpressPermissions) {
          <naoo-hamburger-order-method-widget></naoo-hamburger-order-method-widget>
        } @else {
          <naoo-delivery-schedule-widget
            [wideLayout]="true"
            [hideBorders]="true"
            [showArrowIcon]="true"
            [disabled]="isOffline$ | async"
          ></naoo-delivery-schedule-widget>
        }
      </div>
      <div class="top-item">
        <naoo-customer-unit-selector
          [showArrowIcon]="true"
          [isDisabled]="isOffline$ | async"
        ></naoo-customer-unit-selector>
      </div>
    </div>
    <div class="hamburger-menu-middle hamburger-box">
      @for (link of links; track link.text) {
        <div class="menu-item">
          <button
            class="links"
            routerLink="{{ link.url }}"
            [queryParams]="link.queryParams"
            (click)="handleClick(link)"
            [ngClass]="{
              'current-location':
                link.url === previousUrl ||
                (!!link.queryParams &&
                  previousUrl.includes(link.queryParams.activeTab)),
            }"
            [disabled]="(isOffline$ | async) && link.disabledOnOffline"
          >
            @if (link.text === goPointsLabel) {
              <naoo-go-points-logo
                [context]="'naoo-mobile-hamburger-menu'"
                [disabled]="(isOffline$ | async) && link.disabledOnOffline"
              ></naoo-go-points-logo>
            } @else {
              <span>{{ link.text | translate }}</span>
            }
            <ng-template #text>
              <span>{{ link.text | translate }}</span>
            </ng-template>
          </button>
        </div>
      }
    </div>
    <div class="hamburger-menu-bottom hamburger-box">
      <div
        class="menu-item"
        [ngClass]="{
          disabled: isOffline$ | async,
        }"
      >
        <mat-icon svgIcon="quick_add_icon"></mat-icon>
        <button
          class="links"
          id="quickAddBtn"
          routerLink="{{ quickAdd.url }}"
          (click)="handleClick(quickAdd)"
          [disabled]="isOffline$ | async"
        >
          <span>{{ quickAdd.text | translate }}</span>
        </button>
      </div>
      <div class="menu-item">
        <naoo-language-menu
          class="naoo-language-menu"
          [isDisabled]="isOffline$ | async"
        ></naoo-language-menu>
      </div>
      <div
        class="menu-item"
        [ngClass]="{
          disabled: isOffline$ | async,
        }"
      >
        <mat-icon svgIcon="gear_icon"></mat-icon>
        <button
          class="links preferences-menu"
          routerLink="{{ preferences.url }}"
          (click)="handleClick(preferences)"
          [disabled]="isOffline$ | async"
        >
          <span>{{ preferences.text | translate }}</span>
        </button>
      </div>
      <div class="menu-item">
        <mat-icon svgIcon="info_filled_icon"></mat-icon>
        <button class="links" (click)="openSupportContactModal()">
          <span>{{ 'MENU.SUPPORT' | translate }}</span>
        </button>
      </div>
      <div
        class="menu-item"
        [ngClass]="{
          disabled: isOffline$ | async,
        }"
      >
        <mat-icon
          class="clear-filter-icon"
          svgIcon="clear_filter_icon"
        ></mat-icon>
        <button
          class="logout links"
          (click)="logout()"
          [disabled]="isOffline$ | async"
        >
          <span>{{ 'LOGOUT.TEXT' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
