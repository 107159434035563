@if (viewModel) {
  @if (shouldRenderMobileActionsList) {
    <naoo-guides-mobile-actions-list
      [sideBar]="viewModel.sideBar"
      [preferredView]="viewModel.preferredView"
      [isOffline]="viewModel.isOffline"
      [isSortByDisabled]="true"
      (closeMobileActions)="toggleMobileActions()"
    ></naoo-guides-mobile-actions-list>
  } @else {
    <div class="marketing-guide-wrapper">
      <mat-drawer-container>
        <mat-drawer
          [opened]="sideNavOpen"
          mode="side"
          [ngClass]="{ closed: !sideNavOpen }"
          [style.margin-top.px]="headerHeight$ | async"
        >
          <naoo-guides-side-bar
            [sortByDisabled]="true"
            [sideBar]="viewModel.sideBar"
            [isOffline]="viewModel.isOffline"
            (scrollToCategoryClick)="handleCategoryScroll($event)"
          ></naoo-guides-side-bar>
        </mat-drawer>
        <mat-drawer-content>
          @if (!isMobile) {
            <button
              class="drawer-button"
              [ngClass]="{ 'drawer-closed': !sideNavOpen }"
              (click)="toggleDrawer()"
              [attr.aria-label]="expansionAriaText | translate"
            >
              <mat-icon
                svgIcon="arrow-v2"
                class="arrow-icon"
                [ngClass]="{ 'closed-rotation': !sideNavOpen }"
              ></mat-icon>
            </button>
          }
          <div class="marketing-guide-content">
            <naoo-guides-header
              class="guides-header"
              [guideHeader]="viewModel.header"
              [preferredView]="viewModel.preferredView"
              [categoryHeaders]="viewModel.sideBar.categoryHeaders"
              [isOffline]="viewModel.isOffline"
              [isBreadcrumbDisabled]="true"
              [printInput$]="printInput$"
              [exportInput$]="exportInput$"
              (openMobileActionsModal)="toggleMobileActions()"
              (updateSearchText)="handleUpdateSearchText($event)"
              (scrollToCategory)="handleCategoryScroll($event)"
            >
              @if (viewModel.imageUrl) {
                <img
                  src="{{ viewModel.imageUrl }}"
                  alt=""
                  class="header-image"
                />
              }
              <div class="guide-header">
                <div class="guide-title-container">
                  <span class="guide-title">
                    {{ viewModel.header.name | stringdefaulter }}
                  </span>
                </div>
                <span class="guide-material-count">
                  ({{ viewModel.header.count }}
                  {{ 'ORDER_GUIDE.PRODUCTS' | translate }})
                </span>
              </div>
            </naoo-guides-header>
            @if (shouldRenderList) {
              <div class="list-content">
                <naoo-material-list
                  #materialList
                  [materialListItems]="viewModel.materialListRows"
                  [context]="listContext"
                  [listStyle]="viewModel.preferredView"
                  [analytics]="analytics"
                ></naoo-material-list>
              </div>
            } @else {
              <naoo-no-results
                [searchText]="viewModel.header.actionBar.searchText"
              ></naoo-no-results>
            }
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  }
}
