import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { EnvironmentSpecificService } from '../../services/environment-specific/environment-specific.service';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-credit-card-modal',
  templateUrl: './credit-card-modal.component.html',
  styleUrls: ['./credit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalHeaderComponent,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
})
export class CreditCardModalComponent {
  constructor(
    private dialogRef: MatDialogRef<CreditCardModalComponent>,
    private windowService: Window,
    private environmentSpecificService: EnvironmentSpecificService,
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  redirectToOnlinePayment(): void {
    this.windowService.open(
      this.environmentSpecificService.getOnlinePaymentUrl(),
    );
  }
}
