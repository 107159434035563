@if (childNavigation) {
  <naoo-generic-navigation-menu
    parentMenuName="{{
      isString(menuNavigation?.name)
        ? (menuNavigation?.name | translate)
        : (menuNavigation?.name | stringdefaulter)
    }}"
    [menuNavigation]="childNavigation"
    (closeModalEvent)="closeModal()"
    (backNavigationEvent)="backNavigation()"
  ></naoo-generic-navigation-menu>
} @else {
  @if (menuNavigation?.showBackNavigation) {
    <naoo-modal-header
      title="{{ 'CATEGORIES.BACK_TO' | translate }} {{ parentMenuName }}"
      [showBackArrow]="menuNavigation.showBackNavigation"
      [showCloseButton]="true"
      (backActionEmitter)="backNavigationEvent.emit()"
      (closeModalEmitter)="closeModalEvent.emit()"
    ></naoo-modal-header>
  }

  <mat-list>
    @if (menuNavigation?.viewAllRoute) {
      <a
        mat-list-item
        (click)="closeModal()"
        [routerLink]="menuNavigation.viewAllRoute"
        class="view-all"
        >{{ 'HOME_PAGE.VIEW_ALL' | translate }}</a
      >
    }
    @for (option of menuNavigation?.childNavigation; track option.name) {
      @if (option.route) {
        <a
          mat-list-item
          [routerLink]="option.route"
          (click)="closeModal()"
          class="menu-route-option"
        >
          <ng-container
            [ngTemplateOutlet]="menuName"
            [ngTemplateOutletContext]="{ option }"
          ></ng-container>
        </a>
      } @else if (option.action) {
        <a
          mat-list-item
          (click)="callAction(option.action)"
          class="menu-action-option"
        >
          <ng-container
            [ngTemplateOutlet]="menuName"
            [ngTemplateOutletContext]="{ option }"
          ></ng-container>
        </a>
      } @else if (option.childNavigation) {
        <a
          mat-list-item
          (click)="openSubmenu(option)"
          class="menu-child-option"
        >
          <ng-container
            [ngTemplateOutlet]="menuName"
            [ngTemplateOutletContext]="{ option }"
          ></ng-container>
          <span matListItemMeta>
            <mat-icon svgIcon="arrow-v2" class="arrow-icon"></mat-icon> </span
        ></a>
      }
    }
  </mat-list>
}

<ng-template #menuName let-option="option">
  @if (isString(option.name)) {
    {{ option.name | translate }}
  } @else {
    {{ option.name | stringdefaulter }}
  }
</ng-template>
