<div class="form-container">
  <form [formGroup]="notificationForm" (ngSubmit)="saveClick()">
    <div
      class="row-container"
      [ngClass]="{ 'with-language-selection': isRetalix }"
    >
      <mat-icon class="main-icon" svgIcon="email"></mat-icon>
      <div class="form-inputs-container">
        <mat-form-field class="recipient-email">
          <mat-label>
            {{ 'PREFERENCES.EMAIL_NOTIFICATIONS.INPUT.PRIMARY' | translate }}
          </mat-label>
          <input
            matInput
            type="email"
            formControlName="emailInput"
            #emailInput
          />
          @if (validate('emailInput', 'required')) {
            <mat-error>{{
              'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.EMAIL_REQUIRED'
                | translate
            }}</mat-error>
          }
          @if (validate('emailInput', 'maxlength')) {
            <mat-error>{{
              'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.120_CHARACTERS_LIMIT'
                | translate
            }}</mat-error>
          }
          @if (
            validate('emailInput', 'email') &&
            !validate('emailInput', 'pattern')
          ) {
            <mat-error>{{
              'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.INVALID_EMAIL_ADDRESS'
                | translate
            }}</mat-error>
          }
          @if (validate('emailInput', 'duplicateEmail')) {
            <mat-error>{{
              'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.DUPLICATE_EMAIL_ADDRESS'
                | translate
            }}</mat-error>
          }
          @if (validate('emailInput', 'pattern')) {
            <mat-error>{{
              'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.INVALID_CHARACTERS'
                | translate
            }}</mat-error>
          }
        </mat-form-field>
        @if (isRetalix) {
          <mat-form-field class="recipient-name">
            <mat-label>
              {{ 'PREFERENCES.EMAIL_NOTIFICATIONS.INPUT.NAME' | translate }}
            </mat-label>
            <input matInput type="text" formControlName="recipientName" />
            @if (validate('recipientName', 'maxlength')) {
              <mat-error>{{
                'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.120_CHARACTERS_LIMIT'
                  | translate
              }}</mat-error>
            }
            @if (validate('recipientName', 'pattern')) {
              <mat-error>{{
                'PREFERENCES.EMAIL_NOTIFICATIONS.VALIDATIONS.INVALID_CHARACTERS'
                  | translate
              }}</mat-error>
            }
          </mat-form-field>
        }
        <div class="language-dropdown">
          <notification-language-dropdown
            [selectedLanguage]="languageSelected()"
            (currentLangChange)="setUserLanguage($event)"
          ></notification-language-dropdown>
        </div>
      </div>
    </div>
    <naoo-subscriptions-form
      [subscriptionTypes]="notification?.subscriptionTypes"
      [allValidNotificationSubscriptionTypes]="
        allValidNotificationSubscriptionTypes
      "
      (subscriptionChanged)="subscriptionChanged($event)"
      [isValid]="hasValidSubscriptionsToOptFrom"
    >
    </naoo-subscriptions-form>
    <div class="button-container">
      <button
        type="button"
        class="small secondary-button"
        (click)="cancelClick()"
      >
        {{ 'SHARED.CANCEL' | translate }}
      </button>
      <button
        class="small primary-button"
        type="submit"
        [disabled]="shouldDisableSaveButton"
      >
        {{ 'SHARED.SAVE' | translate }}
      </button>
    </div>
  </form>
</div>
