@if (shouldShowCompareDocket) {
  <div class="docket-container">
    <div
      class="docket-header"
      [ngClass]="{ 'docket-header-expanded': !isCollapsed }"
    >
      <button
        class="product-counter"
        [ngClass]="{ expanded: !isCollapsed }"
        [disabled]="materialComparisonNumbers.length === 0"
        (click)="toggleExpandedState()"
      >
        <span class="arrow-expansion" [ngClass]="{ expanded: !isCollapsed }">
          {{
            'COMPARE_DOCKET.PRODUCT_COUNT'
              | translate
                : {
                    currentProductCount: materialComparisonNumbers.length,
                    maxProductCount: maxProductCount,
                  }
          }}
          <mat-icon
            class="arrow-icon"
            svgIcon="{{
              materialComparisonNumbers.length === 0
                ? 'arrow-gray'
                : 'arrow-icon'
            }}"
            [ngClass]="{ 'toggle-arrow-action': !isCollapsed }"
          ></mat-icon>
        </span>
      </button>
      <button
        class="clear-all"
        [disabled]="materialComparisonNumbers.length === 0"
        (click)="clearAll()"
      >
        {{ 'COMPARE_DOCKET.CLEAR_ALL' | translate }}
      </button>
      <div class="compare-button-container compare-button-desktop">
        <ng-container *ngTemplateOutlet="compareButton"></ng-container>
      </div>
    </div>
    @if (!isCollapsed) {
      <div class="product-card-container">
        @for (
          materialNumber of materialComparisonNumbers;
          track materialNumber
        ) {
          <naoo-compare-card [materialNumber]="materialNumber">
          </naoo-compare-card>
        }
      </div>
    }
    <div class="compare-button-container compare-button-mobile">
      <ng-container *ngTemplateOutlet="compareButton"></ng-container>
    </div>
  </div>
}

<ng-template #compareButton>
  @if (!isCurrentPathComparison) {
    <button
      class="compare-button primary-button large-button"
      [disabled]="materialComparisonNumbers.length < 2"
      (click)="navigateToProductComparison()"
    >
      {{ 'COMPARE_DOCKET.COMPARE' | translate }}
    </button>
  }
</ng-template>
