@if (shouldRender) {
  <div class="content-container">
    <div class="image-wrapper">
      <naoo-material-image
        class="image-wrapper"
        [materialRowOptions]="materialRow.materialRowOptions"
      ></naoo-material-image>
    </div>
    <div class="material-info">
      <a
        class="material-description multi-line-clamp"
        [routerLink]="detailsLink"
        [state]="{
          analytics,
          context,
        }"
      >
        {{ materialRow.materialRowInfo.description | stringdefaulter }}
      </a>
      <naoo-material-flag-container
        class="material-flag-container"
        [flags]="materialRow.flags"
        [maximumNumberOfFlags]="4"
      ></naoo-material-flag-container>
      <div>
        <div class="uom-price-display">
          <ng-container>
            {{ { uomCode: currentOrderingInfo.displayCode } | materialunits }}
          </ng-container>
          <ng-container>
            {{
              currentOrderingInfo.price
                | naooprice: currentOrderingInfo.catchWeightUom
            }}
          </ng-container>
        </div>
      </div>
      <div class="button-container">
        <button class="add-button" (click)="addRecommendationToCart()">
          {{
            'CART_REVIEW.SMART_CART_RECOMMENDATIONS.BUTTON'
              | translate: { uom: materialUnitsInput | materialunits }
          }}
        </button>
      </div>
    </div>
  </div>
}
