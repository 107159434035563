import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomDialogService } from 'src/app/shared/services/dialog/custom-dialog/custom-dialog.service';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import { NaooAnalyticsManager } from 'src/app/shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from 'src/app/shared/analytics/analytics-event-info';
import { ActiveCartSummaryFacade } from '../../../../core/store/active-cart/active-cart-summary.facade';
import { BulkAddWorkflow } from '../../../../shared/bulk-add-modal/bulk-add-modal.component';
import { RouterLink } from '@angular/router';
import { GenericDisableableLinkComponent } from '../../../../shared/generic-disableable-link/generic-disableable-link.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-empty-cart',
  templateUrl: './empty-cart.component.html',
  styleUrls: ['./empty-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, GenericDisableableLinkComponent, TranslateModule],
})
export class EmptyCartComponent {
  readonly guidesPath = NaooConstants.GUIDES_PATH;
  readonly catalogPath = NaooConstants.CATEGORIES_PATH;

  @Input() isOffline: boolean;

  constructor(
    private analyticsManager: NaooAnalyticsManager,
    private activeCartSummaryFacade: ActiveCartSummaryFacade,
    private customDialogService: CustomDialogService,
  ) {}

  openImportOrderModal() {
    this.customDialogService.bulkAddImportChecker(BulkAddWorkflow.IMPORT);
    return false;
  }

  beginQuack() {
    const analyticsEvent: AnalyticsEventInfo = {
      action: 'click',
      category: 'quick add',
      label: 'shopping cart link',
    };

    this.analyticsManager.trackAnalyticsEvent(analyticsEvent);
    this.activeCartSummaryFacade.enableQuickAdd();
    return false;
  }
}
