@if (!!defaultFilters && defaultFilters.length > 0) {
  <div class="filter-header">
    {{ 'SEARCH.FILTERS' | translate }}
  </div>
}
@if (!!activeDefaultFilters && activeDefaultFilters.length > 0) {
  <naoo-filter-chips
    [activeDefaultFilters]="activeDefaultFilters"
  ></naoo-filter-chips>
}
@if (!!taxonomy && taxonomy.filters.length > 0) {
  <div class="taxonomy-filter">
    <naoo-taxonomy-filter-group [taxonomy]="taxonomy">
    </naoo-taxonomy-filter-group>
  </div>
}
@if (!!defaultFilters) {
  @for (filterGroup of defaultFilters; track filterGroup.name.en) {
    <div>
      <naoo-filter-group [filterGroup]="filterGroup"></naoo-filter-group>
    </div>
  }
}
