import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CostSummary,
  CostSummaryContext,
  OrderSummary,
} from '../../shared/cost-summary';
import { NaooConstants } from '../../../shared/NaooConstants';
import { OrderSubmitButtonContainerComponent } from '../../order-submit-button/order-submit-button-container/order-submit-button-container.component';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NaooPricePipe } from '../../../shared/pipes/naoo-price.pipe';

@Component({
  selector: 'naoo-cost-summary-content-floating-footer',
  templateUrl: './cost-summary-content-floating-footer.component.html',
  styleUrls: ['./cost-summary-content-floating-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    OrderSubmitButtonContainerComponent,
    RouterLink,
    TranslateModule,
    NaooPricePipe,
  ],
})
export class CostSummaryContentFloatingFooterComponent {
  @Input() costSummary: CostSummary;
  @Input() isPoNumberInvalid: boolean;
  @Input() isCreditPaymentLoading: boolean;

  @Output() submitButtonClick = new EventEmitter<void>();

  readonly catalogPath = NaooConstants.CATEGORIES_PATH;
  readonly cartPath = NaooConstants.CART_PATH;

  get isCartSummaryContext(): boolean {
    return this.costSummary.context === CostSummaryContext.CartSummary;
  }

  get standardOrderTotals(): OrderSummary {
    return this.costSummary?.quantities?.find(
      (summary) => summary.orderType == null,
    );
  }
}
