import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  finalize,
  first,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { MultipleCartsFacade } from '../../core/store/multiple-carts/multiple-carts.facade';
import { LoadingService } from '../../shared/services/loading-service/loading.service';
import { SessionFacade } from '../../core/store/session/session.facade';
import { Subject } from 'rxjs';
import { SavedCartService } from './saved-carts/shared/saved-cart.service';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ActiveCartContainerComponent } from './active-cart/active-cart-container.component';
import { SavedCartsContentComponent } from './saved-carts/saved-carts-content.component';
import { PunchedOutCartsContentComponent } from './punched-out-carts/punched-out-carts-content.component';
import { PreviewCartComponent } from './saved-carts/preview-cart/preview-cart.component';
import { TranslateModule } from '@ngx-translate/core';

export const enum CartSummaryTab {
  ActiveCart = 'active-cart',
  SavedCarts = 'saved-carts',
  PunchedOutCarts = 'punched-out-carts',
}

@Component({
  selector: 'naoo-cart-summary-container',
  templateUrl: './cart-summary-container.component.html',
  styleUrls: ['./cart-summary-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslateModule,
    NgClass,
    MatSidenavModule,
    MatTabsModule,
    ActiveCartContainerComponent,
    SavedCartsContentComponent,
    PunchedOutCartsContentComponent,
    PreviewCartComponent,
  ],
})
export class CartSummaryContainerComponent implements OnDestroy, OnInit {
  private readonly tabs: CartSummaryTab[] = [
    CartSummaryTab.ActiveCart,
    CartSummaryTab.SavedCarts,
    CartSummaryTab.PunchedOutCarts,
  ];
  private readonly activeTabParameter = 'activeTab';

  private destroyed$ = new Subject<void>();
  drawerOpened = false;
  isMobile: boolean;

  activeTabIndex$ = this.activatedRoute.queryParams.pipe(
    map((params) => {
      const activeTabParam = params[this.activeTabParameter];
      const tabIndex = this.tabs.indexOf(activeTabParam);
      return tabIndex > 0 ? tabIndex : 0;
    }),
  );

  isOffline$ = this.offlineModeFacade.getIsOffline().pipe(shareReplay(1));

  savedCartsLoading$ = this.multipleCartsFacade.isLoadingSavedCarts().pipe(
    startWith(true),
    tap((savedCartLoading) => {
      if (savedCartLoading) {
        this.loadingService.start();
      } else {
        this.loadingService.stop();
      }
    }),
    finalize(() => this.loadingService.stop()),
  );

  savedCarts$ = this.multipleCartsFacade.getSavedCarts().pipe(shareReplay(1));

  isPunchoutSession$ = this.sessionFacade.getLoadedSession().pipe(
    first(),
    map((sessionInfo) => sessionInfo.isPunchOut),
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private offlineModeFacade: OfflineModeFacade,
    private multipleCartsFacade: MultipleCartsFacade,
    private loadingService: LoadingService,
    private sessionFacade: SessionFacade,
    private savedCartService: SavedCartService,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceIdentifierService: DeviceIdentifierService,
  ) {}

  ngOnInit(): void {
    this.setAppDeviceType();
    this.multipleCartsFacade.throttledRefreshSavedCarts();
    this.savedCartService
      .previewOpen$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((drawerOpened) => {
        this.drawerOpened = drawerOpened;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.savedCartService.togglePreview(false);
  }

  handleActiveTabChanged(tabIndex: number): void {
    const index = tabIndex >= 0 ? tabIndex : 0;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [this.activeTabParameter]: this.tabs[index] },
      replaceUrl: true,
    });
    this.savedCartService.togglePreview(false);
  }

  private setAppDeviceType(): void {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      });
  }
}
