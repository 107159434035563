import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  MaterialRow,
  MaterialRowContext,
  MaterialRowStatus,
} from 'src/app/core/store/material-row/models/material-row';
import { animate, style, transition, trigger } from '@angular/animations';
import { naooAnimations } from 'src/app/shared/animations/animations';
import { MaterialWarningType } from '../../../core/store/material-warning/material-warning';
import { MaterialAvailability } from '../../../shared/models/material-availability';
import { NgClass } from '@angular/common';
import { MaterialImageComponent } from '../shared/material-image/material-image.component';
import { MaterialInfoComponent } from '../shared/material-info/material-info.component';
import { MaterialOptionsMenuComponent } from '../shared/material-options-menu/material-options-menu.component';
import { MaterialOrderingComponent } from '../shared/material-ordering/material-ordering.component';
import { MaterialUnorderableComponent } from '../shared/material-unorderable/material-unorderable.component';
import { MaterialUnavailableComponent } from '../shared/material-unavailable/material-unavailable.component';
import { MaterialDeletedComponent } from '../shared/material-deleted/material-deleted.component';
import { MaterialSkeletonComponent } from '../shared/material-skeleton/material-skeleton.component';

@Component({
  selector: 'naoo-material-row-slim-content',
  templateUrl: './material-row-slim-content.component.html',
  styleUrls: ['./material-row-slim-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
    trigger('fadeInOnly', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
    ]),
    naooAnimations.fadeAndSlide,
  ],
  standalone: true,
  imports: [
    NgClass,
    MaterialImageComponent,
    MaterialInfoComponent,
    MaterialOptionsMenuComponent,
    MaterialOrderingComponent,
    MaterialUnorderableComponent,
    MaterialUnavailableComponent,
    MaterialDeletedComponent,
    MaterialSkeletonComponent,
  ],
})
export class MaterialRowSlimContentComponent {
  @Input() materialRow: MaterialRow;
  @Input() isLast: boolean;
  @Input() isMobile: boolean;
  @Input() materialAvailability: MaterialAvailability;
  @Output() viewCommodityDetailsClicked = new EventEmitter();

  get isLoading(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Loading
    );
  }

  get isOK(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Ok &&
      !this.isDeleted
    );
  }

  get isUnorderable(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Unorderable &&
      !this.isDeleted
    );
  }

  get isUnavailable(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Unavailable &&
      !this.isDeleted
    );
  }

  get isDeleted(): boolean {
    return !!this.materialRow && this.materialRow.isDeleted;
  }

  get isInsideContainer(): boolean {
    return (
      !!this.materialRow &&
      [
        MaterialRowContext.CartReview,
        MaterialRowContext.CriticalItem,
        MaterialRowContext.Substitutes,
      ].includes(this.materialRow.materialRowOptions.context)
    );
  }

  get shouldShowRedBorder(): boolean {
    return (
      this.isOverAllocatedAndNonModalContext() ||
      this.hasMaterialErrorCartContext()
    );
  }

  get isParEnabled(): boolean {
    return this.materialRow.materialRowOptions.isParEnabled;
  }

  get shouldHideTrashCan(): boolean {
    return (
      this.materialRow?.materialRowOptions?.context ===
      MaterialRowContext.MarketingGuide
    );
  }

  private isOverAllocatedAndNonModalContext(): boolean {
    return (
      MaterialWarningType.OverAllocation ===
        this.materialRow?.materialOrdering?.materialWarning?.type &&
      MaterialRowContext.OverallocationModal !==
        this.materialRow.materialRowOptions?.context
    );
  }

  private hasMaterialErrorCartContext() {
    return (
      (this.isUnavailable || this.isUnorderable) &&
      [MaterialRowContext.CartReview, MaterialRowContext.CartSummary].includes(
        this.materialRow.materialRowOptions?.context,
      )
    );
  }
}
