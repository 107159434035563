import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SessionFacade } from '../core/store/session/session.facade';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-offline-error',
  templateUrl: './offline-error.component.html',
  styleUrls: ['./offline-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, RouterLink, TranslateModule, AsyncPipe],
})
export class OfflineErrorComponent {
  isCustomerUnitSelected$ = this.sessionFacade.getLoadedActiveCustomer();

  constructor(private sessionFacade: SessionFacade) {}
}
