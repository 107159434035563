import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  CustomerNotification,
  EditableStatus,
  NotificationMethodCode,
  Notifications,
} from '../shared/models/customer-notifications';
import { Language } from '../../../core/services/session/models/session-record';
import { PreferencesHeaderComponent } from './preferences-header/preferences-header.component';
import { PreferencesRowEditingEmailComponent } from './preferences-row-editing-email/preferences-row-editing-email.component';
import { PreferencesRowEditingPhoneComponent } from './preferences-row-editing-phone/preferences-row-editing-phone.component';
import { PreferencesRowReadonlyComponent } from './preferences-row-readonly/preferences-row-readonly.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-preferences-content',
  templateUrl: './preferences-content.component.html',
  styleUrls: ['./preferences-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PreferencesHeaderComponent,
    PreferencesRowEditingEmailComponent,
    PreferencesRowEditingPhoneComponent,
    PreferencesRowReadonlyComponent,
    MatProgressBar,
    TranslateModule,
  ],
})
export class PreferencesContentComponent implements OnDestroy {
  @Input() notifications?: Notifications;
  @Input() language: Language;
  @Input() methodCode: NotificationMethodCode;
  @Input() isRetalix?: boolean;
  @Output() addRequest = new EventEmitter();
  @Output() editRequest = new EventEmitter<CustomerNotification>();
  @Output() deleteRequest = new EventEmitter<CustomerNotification>();
  @Output() saveRequest = new EventEmitter<CustomerNotification>();
  @Output() cancelRequest = new EventEmitter();
  @Output() hasUnsavedChanges = new EventEmitter<boolean>();

  ngOnDestroy(): void {
    this.hasPendingChanges(false);
  }

  get isEmail(): boolean {
    return NotificationMethodCode.Email === this.methodCode;
  }

  isActive(notification: CustomerNotification): boolean {
    return EditableStatus.Active === notification.editableStatus;
  }

  processAdd() {
    this.addRequest.emit();
  }

  processEdit(notification: CustomerNotification) {
    this.editRequest.emit(notification);
  }

  processDelete(notification: CustomerNotification) {
    this.deleteRequest.emit(notification);
  }

  processSave(notification: CustomerNotification) {
    this.saveRequest.emit(notification);
  }

  processCancel() {
    this.cancelRequest.emit();
  }

  hasPendingChanges(value: boolean) {
    this.hasUnsavedChanges.emit(value);
  }

  getNotificationsText(key: string): string {
    return `PREFERENCES.${this.methodCode}_NOTIFICATIONS.${key}`;
  }
}
