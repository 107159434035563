<div class="par-order-row">
  <div class="quantity-type">
    {{ { uomCode: materialUnitInfo.displayCode } | materialunits }}
  </div>
  <div class="case-par-quantity-container">
    <div class="par-order-label">{{ 'PRODUCT.PAR' | translate }}</div>
    <naoo-product-decimal-quantity-box
      (quantityUpdated)="updateCaseParQuantity($event)"
      (nextBox)="goToNextQuantityBox()"
      (previousBox)="goToPreviousQuantityBox()"
      [quantityValue]="parDisplayValue"
      [quantityBoxId]="product.offeringId + materialUnitInfo.uom + '_par'"
      [qtyBoxLabel]="
        'PRODUCT.PAR_LEVEL'
          | translate
            : { productDescription: product.description | stringdefaulter }
      "
    ></naoo-product-decimal-quantity-box>
  </div>
</div>
