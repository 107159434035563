<button
  class="primary-action"
  role="button"
  [disabled]="disabled"
  [matMenuTriggerFor]="addMenu"
  (click)="expandAddToGuide($event)"
  [ngClass]="{
    'trim-text': isInFrench,
  }"
>
  <div
    class="action-container"
    [ngClass]="{
      disabled: disabled,
    }"
  >
    <div class="action-label">{{ addToGuideLabel | translate }}</div>
    <mat-icon
      [@rotate]="rotationState"
      (@rotate.done)="animationComplete($event)"
      class="action-arrow"
      [svgIcon]="disabled ? 'arrow-gray' : 'arrow-icon'"
    ></mat-icon>
  </div>
</button>

<mat-menu
  class="add-to-custom-guide-menu"
  #addMenu="matMenu"
  xPosition="before"
  (closed)="onMenuClosed()"
>
  <div
    [attr.aria-hidden]="!isPanelExpanded"
    [style.height.px]="expandedMenuHeight"
    class="d1"
  >
    <div class="d2">
      <div class="d3">
        <div class="d4">
          <button
            class="submenu-item create-submenu-item"
            role="button"
            (click)="performMenuAction(createNewGuideMenuItem)"
            mat-menu-item
          >
            <div class="button-icon">
              <mat-icon svgIcon="plus_icon"></mat-icon>
            </div>
            {{ createNewGuideMenuItem.name | translate }}
          </button>

          @for (menuItem of submenuItems; track menuItem.name) {
            <button
              class="mat-menu-item submenu-item custom-guide"
              role="button"
              (click)="performMenuAction(menuItem)"
              mat-menu-item
            >
              {{
                menuItem.name === 'CRITICAL_ITEMS.IMPORTED'
                  ? ('LISTS.CRITICAL_ITEMS.IMPORTED' | translate)
                  : menuItem.name
              }}
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</mat-menu>
