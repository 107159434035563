import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CartReviewSection } from '../../../../../../core/store/cart-review/cart-review.state';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../../../../../shared/pipes/naoo-date.pipe';
import { PipeModule } from '../../../../../../shared/pipes/pipe.module';

@Component({
  selector: 'naoo-cart-review-success-header',
  templateUrl: './cart-review-success-header.component.html',
  styleUrls: ['./cart-review-success-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule, NaooDatePipe, PipeModule],
})
export class CartReviewSuccessHeaderComponent {
  @Input() section: CartReviewSection;

  get isValidPoNumber(): boolean {
    return !!this.section && this.section.poNumber.trim() !== '';
  }
}
