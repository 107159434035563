@if (
  !(shouldDisplayNotificationsErrorPage$ | async) &&
  !(shouldDisplayPreferencesErrorPage$ | async)
) {
  <div class="page-title">
    {{ 'PREFERENCES.TITLE' | translate }}
  </div>
  <div class="page-content">
    <mat-tab-group
      class="tab-group"
      [disableRipple]="true"
      [mat-stretch-tabs]="false"
      [selectedIndex]="selectedIndex"
      (selectedIndexChange)="handleActiveTabChanged($event)"
    >
      <mat-tab [label]="'PREFERENCES.TABS.EMAIL' | translate | uppercase">
        <ng-template matTabContent>
          <naoo-preferences-content
            [notifications]="emailNotifications$ | async"
            [isRetalix]="isRetalix"
            [language]="language$ | async"
            (addRequest)="processAdd(NotificationMethodCode.Email)"
            (editRequest)="processEdit($event)"
            (deleteRequest)="processDelete($event)"
            (saveRequest)="processSave($event)"
            (cancelRequest)="processCancel(NotificationMethodCode.Email)"
            (hasUnsavedChanges)="populateUnsavedChanges($event)"
            [methodCode]="NotificationMethodCode.Email"
          ></naoo-preferences-content>
        </ng-template>
      </mat-tab>
      @if (!isRetalix) {
        <mat-tab [label]="'PREFERENCES.TABS.SMS' | translate | uppercase">
          <ng-template matTabContent>
            <naoo-preferences-content
              [notifications]="smsNotifications$ | async"
              [isRetalix]="isRetalix"
              [language]="language$ | async"
              (addRequest)="processAdd(NotificationMethodCode.Sms)"
              (editRequest)="processEdit($event)"
              (deleteRequest)="processDelete($event)"
              (saveRequest)="processSave($event)"
              (cancelRequest)="processCancel(NotificationMethodCode.Sms)"
              (hasUnsavedChanges)="populateUnsavedChanges($event)"
              [methodCode]="NotificationMethodCode.Sms"
            ></naoo-preferences-content>
          </ng-template>
        </mat-tab>
      }
      <mat-tab>
        <ng-template matTabLabel>
          <div class="privacy-label">
            <mat-icon class="privacy-icon" [svgIcon]="'privacy'"></mat-icon>
            <span>{{
              'PREFERENCES.TABS.PRIVACY' | translate | uppercase
            }}</span>
          </div>
        </ng-template>
        <ng-template matTabContent></ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
} @else {
  <naoo-error-message
    [title]="'PREFERENCES.ERROR_PAGE.HEADER'"
    [subtitle]="'PREFERENCES.ERROR_PAGE.MESSAGE'"
  >
  </naoo-error-message>
}
