import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PunchoutOrderMessage } from '../../shared/models/punchout-order-message';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@Component({
  selector: 'naoo-punch-out',
  templateUrl: './punch-out.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule],
})
export class PunchOutComponent implements AfterViewInit {
  @ViewChild('punchOutForm') punchOutForm: ElementRef;
  @ViewChild('punchOutCxml') punchOutCxml: ElementRef;

  private punchOutOrderMessage?: PunchoutOrderMessage;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.punchOutOrderMessage =
      this.router.getCurrentNavigation()?.extras?.state?.punchOutOrderMessage;
  }

  ngAfterViewInit(): void {
    if (this.punchOutOrderMessage) {
      this.punchOutCxml.nativeElement.value =
        this.punchOutOrderMessage.cxmlUrlEncoded;
      this.punchOutForm.nativeElement.action =
        this.punchOutOrderMessage.browserFormPostUrl;
      this.punchOutForm.nativeElement.submit();
      this.changeDetectorRef.markForCheck();
    }
  }
}
