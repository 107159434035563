import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ImportGuideRow,
  ImportGuideRowErrors,
} from '../../../../shared/models/import-guide/import-guide-row';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-import-guide-error-table',
  templateUrl: './import-guide-error-table.component.html',
  styleUrls: ['./import-guide-error-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule],
})
export class ImportGuideErrorTableComponent {
  @Input() importGuideRows: ImportGuideRow[];

  importGuideErrorMessage(error: ImportGuideRowErrors): string {
    switch (error) {
      case ImportGuideRowErrors.DuplicateItem:
        return 'IMPORT_GUIDE.ROW_ERROR.DUPLICATE_ITEM';
      case ImportGuideRowErrors.InvalidItem:
        return 'IMPORT_GUIDE.ROW_ERROR.INVALID_ITEM';
      default:
        return '';
    }
  }
}
