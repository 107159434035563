import {
  ExportDataPoint,
  ExportFeatureType,
  ExportFileType,
  ExportOrderDetailsTranslationKeys,
} from './export/export-properties';
import { ExportDialogFileFormat } from '../modals/export-modal/export-modal.component';

export interface ExportModalDataPoint {
  name: ExportDataPoint;
  label: string;
  isSelected: boolean;
}

export function getModalDataPoints(
  featureType: ExportFeatureType,
  isPrint: boolean,
  includeCustomerMaterial: boolean,
): ExportModalDataPoint[] {
  const columnNames: ExportModalDataPoint[] = [
    {
      name: ExportDataPoint.Category,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CATEGORY',
      isSelected: !isPrint,
    },
    {
      name: ExportDataPoint.ItemNumber,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.ITEM_NUMBER',
      isSelected: true,
    },
    {
      name: ExportDataPoint.ItemDescription,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.ITEM_DESCRIPTION',
      isSelected: true,
    },
    {
      name: ExportDataPoint.UnitSize,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.UNIT_SIZE',
      isSelected: true,
    },
    {
      name: ExportDataPoint.PackSize,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.PACK_SIZE',
      isSelected: true,
    },
    {
      name: ExportDataPoint.PackUom,
      label: isPrint
        ? 'PRINT.TABLE_HEADERS.PACK_UOM'
        : 'EXPORT_MODAL.EXPORT_ITEMS.PACK_UOM',
      isSelected: true,
    },
    {
      name: ExportDataPoint.Brand,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.BRAND',
      isSelected: true,
    },
    {
      name: ExportDataPoint.CatchWeight,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CATCH_WEIGHT',
      isSelected: !isPrint,
    },
    {
      name: ExportDataPoint.CasePrice,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CASE_PRICE',
      isSelected: !isPrint,
    },
    {
      name: ExportDataPoint.CasePriceUom,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CASE_PRICE_UOM',
      isSelected: !isPrint,
    },
    {
      name: ExportDataPoint.UnitPrice,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.UNIT_PRICE',
      isSelected: !isPrint,
    },
    {
      name: ExportDataPoint.UnitPriceUom,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.UNIT_PRICE_UOM',
      isSelected: !isPrint,
    },
    {
      name: ExportDataPoint.NetWeight,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.NET_WEIGHT',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Gtin,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.GTIN',
      isSelected: false,
    },
    {
      name: ExportDataPoint.VendorItemCode,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.VENDOR_ITEM_CODE',
      isSelected: false,
    },
    {
      name: ExportDataPoint.EarlyCutoffFlag,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.EARLY_CUTOFF_FLAG',
      isSelected: false,
    },
    {
      name: ExportDataPoint.SpecialOrderFlag,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.SPECIAL_ORDER_FLAG',
      isSelected: false,
    },
    {
      name: ExportDataPoint.DropShipFlag,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.DROP_SHIP_FLAG',
      isSelected: false,
    },
    {
      name: ExportDataPoint.ContractItemFlag,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CONTRACT_ITEM_FLAG',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Qt1,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_1',
      isSelected: isPrint,
    },
    {
      name: ExportDataPoint.Qt2,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_2',
      isSelected: isPrint,
    },
    {
      name: ExportDataPoint.Qt3,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_3',
      isSelected: isPrint,
    },
    {
      name: ExportDataPoint.Qt4,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_4',
      isSelected: isPrint,
    },
    {
      name: ExportDataPoint.Qt5,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_5',
      isSelected: isPrint,
    },
    {
      name: ExportDataPoint.Qt6,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_6',
      isSelected: isPrint && !includeCustomerMaterial,
    },
    {
      name: ExportDataPoint.Qt7,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_7',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Qt8,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_8',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Qt9,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_9',
      isSelected: false,
    },
    {
      name: ExportDataPoint.Qt10,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.QTY_10',
      isSelected: false,
    },
  ];

  if (
    featureType === ExportFeatureType.CUSTOM_GUIDE ||
    featureType === ExportFeatureType.CUSTOM_GUIDE_PRINT
  ) {
    columnNames.push(
      ...[
        {
          name: ExportDataPoint.StorageArea,
          label: 'EXPORT_MODAL.EXPORT_ITEMS.STORAGE_AREA',
          isSelected: false,
        },
        {
          name: ExportDataPoint.CustomCategory,
          label: 'EXPORT_MODAL.EXPORT_ITEMS.CUSTOM_CATEGORY',
          isSelected: false,
        },
        {
          name: ExportDataPoint.ParQty,
          label: 'EXPORT_MODAL.EXPORT_ITEMS.PAR_QTY',
          isSelected: false,
        },
        {
          name: ExportDataPoint.InventoryQty,
          label: isPrint
            ? 'PRINT.TABLE_HEADERS.INVENTORY_QTY'
            : 'EXPORT_MODAL.EXPORT_ITEMS.INVENTORY_QTY',
          isSelected: false,
        },
      ],
    );
  }

  if (includeCustomerMaterial) {
    columnNames.push({
      name: ExportDataPoint.CustomerMaterialNumber,
      label: 'EXPORT_MODAL.EXPORT_ITEMS.CUSTOMER_MATERIAL_NUMBER',
      isSelected: true,
    });
  }

  return columnNames;
}

export function getFileFormats(isPrint: boolean): ExportDialogFileFormat[] {
  return isPrint
    ? [
        {
          name: ExportFileType.PDF,
          value: 'pdf',
          isChecked: true,
          i18nTag: 'EXPORT_MODAL.PDF',
        },
      ]
    : [
        {
          name: ExportFileType.EXCEL,
          value: 'excel',
          isChecked: true,
          i18nTag: 'EXPORT_MODAL.EXCEL',
        },
        {
          name: ExportFileType.CSV,
          value: 'csv',
          isChecked: false,
          i18nTag: 'EXPORT_MODAL.CSV',
        },
      ];
}

export const defaultOrderDetailsExportList: ExportModalDataPoint[] = [
  {
    name: ExportDataPoint.ProductDescription,
    label: ExportOrderDetailsTranslationKeys.ProductDescription,
    isSelected: true,
  },
  {
    name: ExportDataPoint.ItemCode,
    label: ExportOrderDetailsTranslationKeys.ItemCode,
    isSelected: true,
  },
  {
    name: ExportDataPoint.Unit,
    label: ExportOrderDetailsTranslationKeys.Unit,
    isSelected: true,
  },
  {
    name: ExportDataPoint.Price,
    label: ExportOrderDetailsTranslationKeys.Price,
    isSelected: true,
  },
  {
    name: ExportDataPoint.PriceUom,
    label: ExportOrderDetailsTranslationKeys.PriceUom,
    isSelected: true,
  },
  {
    name: ExportDataPoint.CatchWeight,
    label: ExportOrderDetailsTranslationKeys.CatchWeight,
    isSelected: true,
  },
  {
    name: ExportDataPoint.QuantityOrdered,
    label: ExportOrderDetailsTranslationKeys.QuantityOrdered,
    isSelected: true,
  },
  {
    name: ExportDataPoint.QuantityShipped,
    label: ExportOrderDetailsTranslationKeys.QuantityShipped,
    isSelected: true,
  },
  {
    name: ExportDataPoint.Total,
    label: ExportOrderDetailsTranslationKeys.Total,
    isSelected: true,
  },
  {
    name: ExportDataPoint.Category,
    label: ExportOrderDetailsTranslationKeys.Category,
    isSelected: false,
  },
  {
    name: ExportDataPoint.NetWeight,
    label: ExportOrderDetailsTranslationKeys.NetWeight,
    isSelected: false,
  },
  {
    name: ExportDataPoint.PackSize,
    label: ExportOrderDetailsTranslationKeys.PackSize,
    isSelected: false,
  },
  {
    name: ExportDataPoint.UnitSize,
    label: ExportOrderDetailsTranslationKeys.UnitSize,
    isSelected: false,
  },
  {
    name: ExportDataPoint.Brand,
    label: ExportOrderDetailsTranslationKeys.Brand,
    isSelected: false,
  },
];
