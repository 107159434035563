<div class="add-payment-section">
  <div class="payment-selections">
    <naoo-selectable-button-list
      #buttonList
      [buttonSpacing]="33"
      [buttons]="paymentDetails?.paymentChoices"
      (selectionChanged)="buttonSelectionChanged()"
    >
    </naoo-selectable-button-list>
  </div>

  @if (canPayWithCreditCard) {
    <div class="payment-widget-container">
      <naoo-credit-payment
        [hidden]="!payWithCreditSelected"
        [checkoutId]="paymentDetails.checkoutId"
        (onTimeout)="timeoutReached()"
      >
      </naoo-credit-payment>
    </div>
  }

  @if (!payWithCreditSelected) {
    <div class="pay-in-store-container">
      @if (creditPaymentDisabled) {
        <div
          [innerHtml]="'CART_REVIEW.ADD_PAYMENT.CREDIT_UNAVAILABLE' | translate"
        ></div>
      }
    </div>
  }
</div>
