<article class="error-state">
  <img class="error-icon" alt="{{ alt }}" src="{{ errorImageSource }}" />
  <h2 class="error-title">{{ errorTitle }}</h2>
  <div class="error-subtitle">{{ errorSubtitle }}</div>
  @if (showErrorButton) {
    <div class="error-buttons">
      <button
        class="naoo-button primary-button large-button button-nav-back"
        (click)="navigateBack()"
      >
        {{ errorButtonTitle }}
      </button>
    </div>
  }
</article>
