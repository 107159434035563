import { Localized } from '../models/localized';
import { MaterialUnitsRecord } from '../../core/services/material-units/models/material-units-record';
import { Dictionary } from '@ngrx/entity';
import { Language } from '../../core/services/session/models/session-record';
import { UnitAvailabilityRecord } from '../../core/services/material-availability/model/material-availabilities-record';
import { UnitInfoRecord } from '../../core/services/material-info/models/materials-info-record';
import { LocalizedUtilities } from './localized-utilities';

export function getMaterialUnitFromUomCode(
  quantity: number,
  uomCode: string,
  units: Dictionary<MaterialUnitsRecord>,
  language: Language,
  shouldDisplaySingular: boolean = false,
  shouldDisplayQuantity: boolean = false,
): string {
  if (!units?.[uomCode]) {
    return shouldDisplayQuantity && quantity !== undefined
      ? `${quantity} ${uomCode}`
      : uomCode;
  }

  if (quantity === undefined) {
    const singular = units?.[uomCode].descriptions.single;
    return LocalizedUtilities.getLocalizedValue(singular, language, true);
  }

  return getDisplayQuantity(
    quantity,
    uomCode,
    units,
    language,
    shouldDisplaySingular,
    shouldDisplayQuantity,
  );
}

function getDisplayQuantity(
  quantity: number,
  uomCode: string,
  units: Dictionary<MaterialUnitsRecord>,
  language: Language,
  shouldDisplaySingular: boolean = false,
  shouldDisplayQuantity: boolean = false,
): string {
  // French 0 is singular, English/Spanish 0 is plural
  const isPlural = language === Language.fr ? quantity > 1 : quantity !== 1;

  const grammaticalNumber: Localized<string> =
    !isPlural || shouldDisplaySingular
      ? units[uomCode].descriptions.single
      : units[uomCode].descriptions.plural;

  const localizedNumber: string = LocalizedUtilities.getLocalizedValue(
    grammaticalNumber,
    language,
    true,
  );

  return shouldDisplayQuantity
    ? `${quantity} ${localizedNumber}`
    : localizedNumber;
}

function findSellableInfoRecords(
  availabilities: UnitAvailabilityRecord[],
  infoRecords: UnitInfoRecord[],
): UnitInfoRecord[] {
  const availableUoms: string[] = availabilities.map(
    (availability) => availability.uom,
  );
  return infoRecords.filter((infoRecord) =>
    availableUoms.includes(infoRecord.uom),
  );
}

function findUom(
  availabilities: UnitAvailabilityRecord[],
  infoRecords: UnitInfoRecord[],
  startFromInfoRecordPredicate: (infoRecord: UnitInfoRecord) => boolean,
): string | undefined {
  const sellableInfoRecords = findSellableInfoRecords(
    availabilities,
    infoRecords,
  );

  let candidate = infoRecords.find(startFromInfoRecordPredicate);

  while (!sellableInfoRecords.includes(candidate) && candidate) {
    candidate = sellableInfoRecords.find(
      (infoRecord) => infoRecord.parentUom === candidate.uom,
    );
  }

  return candidate?.uom;
}

export function findSecondLargestSellableUom(
  infoRecords: UnitInfoRecord[],
  availabilities: UnitAvailabilityRecord[],
  largestSellableUom: string,
): string | undefined {
  if (!infoRecords || !availabilities || !largestSellableUom) {
    return undefined;
  }

  return findUom(
    availabilities,
    infoRecords,
    (infoRecord) => infoRecord.parentUom === largestSellableUom,
  );
}

export function findLargestSellableUom(
  infoRecords: UnitInfoRecord[],
  availabilities: UnitAvailabilityRecord[],
): string | undefined {
  if (!infoRecords || !availabilities) {
    return undefined;
  }

  return findUom(
    availabilities,
    infoRecords,
    (infoRecord) => !infoRecord.parentUom,
  );
}

export function findParentUomDisplayDescription(
  uomCode: string,
  units: Dictionary<MaterialUnitsRecord>,
  language: Language,
): string {
  const materialUnitsRecord = units[uomCode];
  if (!materialUnitsRecord) {
    return '';
  }
  const localizedDescription = materialUnitsRecord.descriptions.single;
  return LocalizedUtilities.getLocalizedStringValue(
    localizedDescription,
    language,
    true,
  );
}
