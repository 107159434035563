export enum ProductAction {
  move,
  remove,
}

export interface ProductActionInfo {
  materialNumber: string;
  categoryName?: string;
  action: ProductAction;
}
