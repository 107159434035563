@if (customer) {
  <div
    (click)="selectCustomerUnit(customer.naooCustomerId)"
    (keydown.enter)="selectCustomerUnit(customer.naooCustomerId)"
    role="link"
    tabindex="0"
    class="customer-unit-card"
    [ngClass]="{ selected: active, 'card-border-radius': isLast && !isMobile }"
  >
    <div class="customer-info" [ngClass]="ngClassForWidth">
      <div
        class="customer-name-address"
        [attr.aria-labelledby]="getLabel('Name')"
      >
        <span class="customer-name">{{ customer.name }}</span>
        <div class="customer-address">
          {{ customerAddress }}
        </div>
        <span [id]="getLabel('Name')" class="customer-aria-label">
          {{ active ? ('CUSTOMER_UNIT.CURRENTLY_VIEW_AS' | translate) : '' }}
          {{ 'CUSTOMER_UNIT.NAME_AND_ADDRESS' | translate }}
          {{ customer.name }}
          {{ customerAddress }}
        </span>
      </div>
      <div
        class="customer-number"
        [attr.aria-labelledby]="getLabel('Id')"
        [attr.alt]="'CUSTOMER_UNIT.ARIA_CUSTOMER_NUMBER' | translate"
      >
        @if (isMobile) {
          <span>#</span>
        }
        {{ customer.customerDisplayId }}
        <span [id]="getLabel('Id')" class="customer-aria-label">
          {{ 'CUSTOMER_UNIT.ARIA_CUSTOMER_NUMBER' | translate }}
          {{ customer.customerDisplayId }}
        </span>
      </div>
    </div>
    <div class="cart-info" [ngClass]="ngClassForWidth">
      <div
        class="cart-item customer-active-items"
        [attr.aria-labelledby]="getLabel('ActiveItems')"
      >
        @if (isMobile) {
          <span class="active-cart-heading">
            {{ 'CUSTOMER_UNIT.ACTIVE_CART_ITEMS' | translate }}
          </span>
        }
        <div class="customer-active-items-box">
          <mat-icon class="cart-icon" svgIcon="cart-icon"></mat-icon>
          <div class="cart-items-count">
            @if (!!cartTotal) {
              {{ cartTotal.activeCartLinesQuantity }}
            } @else {
              -
            }
          </div>
        </div>
        <span [id]="getLabel('ActiveItems')" class="customer-aria-label">
          {{ 'CUSTOMER_UNIT.ACTIVE_CART_ITEMS' | translate }}
          {{
            !!cartTotal
              ? cartTotal.activeCartLinesQuantity
              : ('CUSTOMER_UNIT.VALUE_UPDATING' | translate)
          }}
        </span>
      </div>

      <div
        class="cart-item saved-carts"
        [attr.aria-labelledby]="getLabel('SavedCarts')"
      >
        @if (isMobile) {
          <span class="saved-carts-heading">
            {{ 'CUSTOMER_UNIT.SAVED_CARTS' | translate }}
          </span>
        }
        <div class="cart-items-count">
          @if (!!cartTotal) {
            {{ cartTotal.savedCartsCount }}
          } @else {
            -
          }
        </div>
        <span [id]="getLabel('SavedCarts')" class="customer-aria-label">
          {{ 'CUSTOMER_UNIT.SAVED_CARTS' | translate }}
          {{
            !!cartTotal
              ? cartTotal.savedCartsCount
              : ('CUSTOMER_UNIT.VALUE_UPDATING' | translate)
          }}
        </span>
      </div>

      @if (isOrderSubmission) {
        <div
          class="cart-item submitted-carts"
          [attr.aria-labelledby]="getLabel('SubmittedCarts')"
        >
          @if (isMobile) {
            <span class="submitted-carts-heading">
              {{ 'CUSTOMER_UNIT.CARTS_SUBMITTED_FOR_APPROVAL' | translate }}
            </span>
          }
          <div class="cart-items-count">
            @if (!!cartTotal) {
              <div
                [ngClass]="{
                  'red-number': cartTotal.submittedForApprovalCartsCount > 0,
                }"
              >
                {{ cartTotal.submittedForApprovalCartsCount }}
              </div>
            } @else {
              -
            }
          </div>
          <span [id]="getLabel('SubmittedCarts')" class="customer-aria-label">
            {{ 'CUSTOMER_UNIT.CARTS_SUBMITTED_FOR_APPROVAL' | translate }}
            {{
              !!cartTotal
                ? cartTotal.submittedForApprovalCartsCount
                : ('CUSTOMER_UNIT.VALUE_UPDATING' | translate)
            }}
          </span>
        </div>
      }
      @if (hasPunchedOutCarts) {
        <div
          class="cart-item punched-out-carts"
          [attr.aria-labelledby]="getLabel('PunchedOutCarts')"
        >
          @if (isMobile) {
            <span class="punched-out-carts-heading">
              {{ 'CUSTOMER_UNIT.PUNCHED_OUT_CARTS' | translate }}
            </span>
          }
          <div class="cart-items-count">
            @if (!!cartTotal) {
              {{ cartTotal.punchedOutCartsCount }}
            } @else {
              -
            }
          </div>
          <span [id]="getLabel('PunchedOutCarts')" class="customer-aria-label">
            {{ 'CUSTOMER_UNIT.PUNCHED_OUT_CARTS' | translate }}
            {{
              !!cartTotal
                ? cartTotal.savedCartsCount
                : ('CUSTOMER_UNIT.VALUE_UPDATING' | translate)
            }}
          </span>
        </div>
      }
    </div>
  </div>
}
