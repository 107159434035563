<div
  [ngClass]="{
    'taxonomy-header': isFirst,
    'selectable-taxonomy': taxonomyFilter.canToggle,
  }"
  class="taxonomy-filter"
>
  @if (shouldShowTaxonomyBracket) {
    <mat-icon
      class="bracket"
      svgIcon="taxonomy-bracket-icon"
      [style.marginLeft.px]="depthBasedLeftMargin"
    >
    </mat-icon>
  }
  <div
    class="taxonomy-label"
    [style.marginLeft.px]="
      !shouldShowTaxonomyBracket ? depthBasedLeftMargin : 0
    "
    [ngClass]="{ 'active-taxonomy': taxonomyFilter.isSelected }"
    [attr.tabindex]="taxonomyFilter.canToggle ? 0 : undefined"
    (click)="toggleTaxonomyFilter()"
    (keyup.enter)="toggleTaxonomyFilter()"
    role="link"
    attr.aria-label="{{ taxonomyFilter.name | stringdefaulter }} {{
      taxonomyFilter.count
    }} {{ 'SEARCH.RESULTS' | translate }}"
  >
    <div>
      {{ taxonomyFilter.name | stringdefaulter }}
      @if (taxonomyFilter.count > 0) {
        <span class="filter-count"> ({{ taxonomyFilter.count }}) </span>
      }
    </div>
    @if (this.taxonomyFilter.canToggle) {
      <span class="arrow"></span>
    }
  </div>
</div>
