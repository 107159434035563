@for (
  taxonomyFilter of taxonomyFilterList;
  track taxonomyFilter?.name?.en;
  let isFirst = $first
) {
  <div class="taxonomy-filter">
    @if (!!taxonomyFilter) {
      <naoo-taxonomy-filter
        [taxonomyFilter]="taxonomyFilter"
        [isFirst]="isFirst"
      >
      </naoo-taxonomy-filter>
    }
  </div>
}
@if (canViewMore) {
  <div
    class="view-more-button"
    (click)="toggleViewMore()"
    (keyup.enter)="toggleViewMore()"
    tabindex="0"
    [ngClass]="{ 'view-less': isViewingMore }"
    role="button"
  >
    {{ (isViewingMore ? 'VIEW.LESS' : 'VIEW.MORE') | translate }}
  </div>
}
<div class="divider"></div>
