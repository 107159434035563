import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { MatIcon } from '@angular/material/icon';
import { CustomGuideEditMenuComponent } from './custom-guide-edit-menu/custom-guide-edit-menu.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, CustomGuideEditMenuComponent, TranslateModule],
})
export class ListCardComponent implements OnInit {
  @Input() listTitle: string;
  @Input() totalListProducts: number;
  @Input() customGuideId: string;
  @Input() isCriticalItemsGuide: boolean;
  @Input() showCopyGuide: boolean;
  @Input() isUserOffline = false;
  @Output() setCriticalItemGuide = new EventEmitter<string>();

  isCriticalItemImported = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isCriticalItemImported = this.listTitle === 'CRITICAL_ITEMS.IMPORTED';
  }

  handleClick() {
    if (this.customGuideId && !this.isCriticalItemsGuide) {
      this.router.navigate([
        NaooConstants.GUIDES_PATH,
        'custom-guide',
        this.customGuideId,
      ]);
    } else if (this.isCriticalItemsGuide) {
      this.router.navigate([NaooConstants.GUIDES_PATH, 'critical-items-guide']);
    } else {
      this.router.navigate([NaooConstants.GUIDES_PATH, 'order-guide']);
    }
  }

  productString(): string {
    return this.totalListProducts !== 1 ? 'LISTS.PRODUCTS' : 'LISTS.PRODUCT';
  }
}
