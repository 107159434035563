import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SessionFacade } from '../../core/store/session/session.facade';
import { EnvironmentSpecificService } from '../../shared/services/environment-specific/environment-specific.service';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { NaooLocalStorage } from '../../shared/storage/local-storage/local-storage.service';
import { CustomerPermission } from '../../core/services/session/models/session-record';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeaderLinkType } from '../header.types';
import { DOCUMENT, NgClass } from '@angular/common';
import { MoreToolsComponent } from '../more-tools/more-tools.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, MoreToolsComponent, NgClass, TranslateModule],
})
export class HeaderTabsComponent implements OnInit, OnDestroy {
  private readonly localStorageHideRecipeBanner = 'hideRecipeBanner';

  @Input() isDisabled: boolean;
  @Input() isOffline: boolean;

  @ViewChild('orderingButton')
  orderingButtonElement: ElementRef<HTMLButtonElement>;
  @ViewChild('orderingMobileMenu')
  orderingMobileMenu: ElementRef<HTMLDivElement>;
  isInventoryEnabled = false;
  isRecipeEnabled = false;
  isMoreToolsEnabled = false;
  customerMoreToolsPermissions: CustomerPermission[] = [];
  isCustomerLoaded = false;
  isMobile = false;
  isMenuOpen = false;
  HeaderLinksTypes = HeaderLinkType;
  private destroyed$ = new Subject<void>();

  constructor(
    private sessionFacade: SessionFacade,
    private environmentSpecificService: EnvironmentSpecificService,
    private analytics: NaooAnalyticsManager,
    private deviceIdentifierService: DeviceIdentifierService,
    private naooLocalStorage: NaooLocalStorage,
    private windowService: Window,
    private changeDetectRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ngOnInit() {
    this.showPermittedApplicationTabs();
    this.setAppDeviceType();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  tabKeydown(event: KeyboardEvent) {
    event.preventDefault();
    this.toggleMenu();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      this.collectMenuItems(false)[0]?.focus();
    } else {
      this.orderingButtonElement?.nativeElement.focus();
    }
    this.changeDetectRef.markForCheck();
  }

  get hideRecipeBanner(): boolean {
    return (
      this.naooLocalStorage?.getItem(this.localStorageHideRecipeBanner) ===
      'true'
    );
  }

  dismissRecipeBanner(): void {
    this.naooLocalStorage.setItem(this.localStorageHideRecipeBanner, 'true');
  }

  private collectMenuItems(onlyVisible: boolean): HTMLElement[] {
    return Array.from(
      this.orderingMobileMenu.nativeElement.querySelectorAll(
        'button.menu-item',
      ),
    ).filter(
      (menuItem: HTMLElement) =>
        !onlyVisible || menuItem.getBoundingClientRect().height > 0,
    ) as HTMLElement[];
  }

  nextMenuItem() {
    const visibleMenuItems = this.collectMenuItems(true);
    const currentlyFocusedIndex = visibleMenuItems.findIndex(
      (menuItem) => this._document.activeElement === menuItem,
    );
    const nextVisibleItem =
      visibleMenuItems[currentlyFocusedIndex + 1] ?? visibleMenuItems[0];
    nextVisibleItem?.focus();
  }

  previousMenuItem() {
    const visibleMenuItems = this.collectMenuItems(true);
    const currentlyFocusedIndex = visibleMenuItems.findIndex(
      (menuItem) => this._document.activeElement === menuItem,
    );
    const previousVisibleItem =
      visibleMenuItems[currentlyFocusedIndex - 1] ??
      visibleMenuItems[visibleMenuItems.length - 1];
    previousVisibleItem?.focus();
  }

  redirectTo(name: HeaderLinkType, newTab: boolean = false) {
    this.fireAnalytics(name);

    const headerUrl = this.environmentSpecificService.getHeaderUrl(name);
    if (!newTab) {
      this.windowService.location.href = headerUrl;
    } else {
      this.windowService.open(headerUrl);
    }
  }

  private showPermittedApplicationTabs() {
    this.sessionFacade
      .getLoadedActiveCustomerPermittedApps()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((permittedApps) => {
        this.isCustomerLoaded = true;
        this.isInventoryEnabled = permittedApps.isInventoryEnabled;
        this.isRecipeEnabled = permittedApps.isRecipeEnabled;
        this.customerMoreToolsPermissions =
          permittedApps.customerMoreToolsPermissions;
        this.isMoreToolsEnabled = permittedApps.isMoreToolsEnabled;

        this.changeDetectRef.markForCheck();
      });
  }

  private setAppDeviceType(): void {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectRef.markForCheck();
      });
  }

  private fireAnalytics(event: HeaderLinkType) {
    const analyticsEventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'general',
      label: `${event.valueOf()} link`,
    };
    this.analytics.trackAnalyticsEvent(analyticsEventInfo);
  }
}
