import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NaooConstants } from '../shared/NaooConstants';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { OrderConfirmation } from './shared/models/order-confirmation';
import { SessionFacade } from '../core/store/session/session.facade';
import {
  CurrentSystem,
  CustomerBrand,
} from '../core/services/session/models/session-record';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { OrderConfirmationHeaderErrorComponent } from './order-confirmation-header-error/order-confirmation-header-error.component';
import { OrderConfirmationHeaderComponent } from './order-confirmation-header/order-confirmation-header.component';
import { ErrorMaterialsComponent } from './order-issues/error-materials/error-materials.component';
import { WarningMaterialsComponent } from './order-issues/warning-materials/warning-materials.component';
import { StillProcessingMaterialsComponent } from './order-issues/still-processing-materials/still-processing-materials.component';
import { MatIcon } from '@angular/material/icon';
import { NonStockOrdersComponent } from './orders/non-stock-orders/non-stock-orders.component';
import { DropShipOrdersV1Component } from './orders/drop-ship-orders-v1/drop-ship-orders-v1.component';
import { DropShipOrdersComponent } from './orders/drop-ship-orders/drop-ship-orders.component';
import { StockOrdersComponent } from './orders/stock-orders/stock-orders.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-order-confirmation-container',
  templateUrl: './order-confirmation-container.component.html',
  styleUrls: ['./order-confirmation-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    OrderConfirmationHeaderErrorComponent,
    OrderConfirmationHeaderComponent,
    ErrorMaterialsComponent,
    WarningMaterialsComponent,
    StillProcessingMaterialsComponent,
    MatIcon,
    NonStockOrdersComponent,
    DropShipOrdersV1Component,
    DropShipOrdersComponent,
    StockOrdersComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class OrderConfirmationContainerComponent implements OnInit {
  customerBrand$: Observable<CustomerBrand> =
    this.sessionFacade.getLoadedCustomerBrand();

  readonly ordersPath = NaooConstants.ORDERS_PATH;
  orderConfirmation: OrderConfirmation;
  isRetalix$: Observable<boolean>;

  constructor(
    private sessionFacade: SessionFacade,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.isRetalix$ = this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(map((currentSystem) => currentSystem === CurrentSystem.Retalix));
    this.route.data.subscribe((data) => {
      const routeData = data as { orderConfirmation: OrderConfirmation };
      this.orderConfirmation = routeData.orderConfirmation;
      this.changeDetectorRef.markForCheck();
    });
  }

  get hasConfirmedOrders(): boolean {
    const hasStockOrder = this.orderConfirmation.orders.mainOrders.length > 0;
    const hasNonStockOrder =
      this.orderConfirmation.orders.nonStockOrders.length > 0;
    const hasDropShipOrder =
      this.orderConfirmation.orders.dropShipOrders.length > 0;
    return hasStockOrder || hasNonStockOrder || hasDropShipOrder;
  }

  get hasSplitOrders() {
    return this.orderConfirmation.orders.mainOrders.length > 1;
  }
}
