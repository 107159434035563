import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SavedCart } from '../../../../core/store/multiple-carts/multiple-carts.state';
import { SavedCartService } from '../shared/saved-cart.service';
import { FulfillmentType } from '../../../../core/services/cart/models/cart-record';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../../../shared/pipes/naoo-date.pipe';

@Component({
  selector: 'naoo-saved-cart-row',
  templateUrl: './saved-cart-row.component.html',
  styleUrls: ['./saved-cart-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NgClass, TranslateModule, NaooDatePipe],
})
export class SavedCartRowComponent {
  @Input() savedCart: SavedCart;
  @Input() shouldPromptActivateModal: boolean;
  @Input() isOffline: boolean;
  @Input() hideDelete: boolean = false;
  @Input() isSelectedCart: boolean;
  @Input() isPreviewOpen = false;

  isSavedCartSelected = false;

  constructor(private savedCartService: SavedCartService) {}

  activateCart(): void {
    this.savedCartService.activateCart(
      this.savedCart,
      this.shouldPromptActivateModal,
    );
  }

  togglePreview(): void {
    if (this.isSelectedCart || !this.isPreviewOpen) {
      this.savedCartService.togglePreview(!this.isPreviewOpen);
    }
    this.savedCartService.setCartPreviewState(this.savedCart);
  }

  deleteCart(): void {
    this.savedCartService.deleteCart(this.savedCart.id);
  }

  get isValidSavedCart(): boolean {
    return (
      !this.savedCart.truckFulfillment?.routeDate &&
      !this.savedCart.customerPoNumber
    );
  }

  get SavedCartRequestDate(): string {
    switch (this.savedCart?.fulfillmentType) {
      case FulfillmentType.TRUCK:
        return this.savedCart?.truckFulfillment?.routeDate?.toString();
      case FulfillmentType.EXPRESS:
        return this.savedCart?.storeFulfillment?.deliveryWindowStartTimestamp;
      case FulfillmentType.PICKUP:
        return this.savedCart?.storeFulfillment?.requestedPickupTimestamp;
      default:
        return '';
    }
  }

  get SavedCartDateDisplay(): string {
    switch (this.savedCart?.fulfillmentType) {
      case FulfillmentType.TRUCK:
        return 'CART.SAVED_CARTS.SHIP_DATE';
      case FulfillmentType.EXPRESS:
        return 'CART.SAVED_CARTS.DELIVERY_DATE';
      case FulfillmentType.PICKUP:
        return 'CART.SAVED_CARTS.PICKUP_DATE';
      default:
        return '';
    }
  }
}
