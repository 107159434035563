import { Component, Input, OnInit } from '@angular/core';
import {
  NutritionTableStructure,
  tableStructure,
} from './nutrition-table-structure';
import { NutritionalInfo } from '../../shared/models/material-additional-info';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-nutrition-details',
  templateUrl: './nutrition-details.component.html',
  styleUrls: ['./nutrition-details.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class NutritionDetailsComponent implements OnInit {
  @Input() nutritionDetails: NutritionalInfo;
  @Input() hideBorder = false;

  nutritionTableStructure: NutritionTableStructure[];
  vitaminsTableStructure: NutritionTableStructure[];
  NO_VALUE = '\u2014';

  ngOnInit() {
    this.nutritionTableStructure = tableStructure.nutrition;
    this.vitaminsTableStructure = tableStructure.vitamins;
  }
  // assumption: amount is a valid number string
  private formatValue(amount: string): string {
    return parseFloat(amount.replace(/,/g, '')).toString();
  }

  getCalculationSize(): string {
    if (
      this.nutritionDetails &&
      this.nutritionDetails.calculationSize &&
      this.nutritionDetails.calculationSizeUom
    ) {
      return this.formatValue(this.nutritionDetails.calculationSize).concat(
        this.nutritionDetails.calculationSizeUom,
      );
    }
    return '';
  }

  getCalories(): string {
    if (this.nutritionDetails && this.nutritionDetails.calories) {
      return this.formatValue(this.nutritionDetails.calories);
    }
    return this.NO_VALUE;
  }

  measurableValueForKey(key: string): string {
    const value = this.nutritionDetails[key];
    const uom = this.nutritionDetails[key + 'Uom'];
    if (value && uom) {
      return this.formatValue(value) + uom;
    }
    return '';
  }

  dailyValueForKey(key: string): string {
    const dailyValue = this.nutritionDetails[key + 'DailyValue'];
    if (dailyValue) {
      return dailyValue;
    } else if (this.measurableValueForKey(key)) {
      return '';
    } else {
      return this.NO_VALUE;
    }
  }
}
