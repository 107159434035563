<naoo-cost-summary-content
  [ngClass]="{ 'viewable-cost-summary-content': !isPrintOnly }"
  #costSummaryContent
  [costSummary]="costSummary$ | async"
  [shouldShowFloatingFooter]="shouldShowFloatingFooter$ | async"
  [isQuickAddEnabled]="isQuickAddEnabled"
  [isMobile]="isMobile$ | async"
  [isPoNumberInvalid]="isPoNumberInvalid$ | async"
  [paymentReview]="paymentReview"
  [hasOnlySuccessSection]="hasOnlySuccessSection"
  (submitButtonClick)="submitButtonClick.emit()"
  (backLinkClicked)="backLinkClicked.emit()"
></naoo-cost-summary-content>
<naoo-cost-summary-print-content [costSummary]="costSummary$ | async">
</naoo-cost-summary-print-content>
