import { Dictionary } from '@ngrx/entity';
import { ExportDataPoint } from '../../../../shared/models/export/export-properties';
import { MaterialInfoRecordState } from '../../material-info/material-info.state';
import { hasMaterialInfoFinishedLoading } from '../../material-info/material-info.util';
import { MaterialAdditionalInfoRecordState } from '../../material-additional-info/material-additional-info.state';
import { hasMaterialAdditionalInfoFinishedLoading } from '../../material-additional-info/material-additional-info.util';
import { MaterialAvailabilityRecordState } from '../../material-availability/material-availability.state';
import { hasMaterialAvailabilityFinishedLoading } from '../../material-availability/material-availability.util';
import { CombinedPricingRecord } from '../../material-price/material-price.util';
import { CustomerMaterialRecord } from '../../../services/customer-material/model/customer-material-record';
import { CustomGuideRecordState } from '../../custom-guide/custom-guide.state';

export class ExportMaterialsRequiredStateInfo<T> {
  readonly validation: LoadingValidation<T>;
  private readonly records?: Dictionary<T>;
  private readonly record?: T;

  constructor(
    validation: LoadingValidation<T>,
    records?: Dictionary<T>,
    record?: T,
  ) {
    this.validation = validation;
    this.records = records;
    this.record = record;
  }

  isFinishedLoading(materialNumber: string): boolean {
    if (this.records) {
      return this.validation.isRecordFinishedLoading(
        this.records[materialNumber],
      );
    } else {
      return this.validation.isRecordFinishedLoading(this.record);
    }
  }
}

export interface LoadingValidation<T> {
  isRecordFinishedLoading: (record: T) => boolean;
  requiredDataPoints?: ExportDataPoint[];
}

export function createExportMaterialsValidations(
  infoRecords: Dictionary<MaterialInfoRecordState>,
  additionalInfoRecords: Dictionary<MaterialAdditionalInfoRecordState>,
  availabilityRecords: Dictionary<MaterialAvailabilityRecordState>,
  combinedPriceRecords: Dictionary<CombinedPricingRecord>,
  customerMaterialRecord: CustomerMaterialRecord,
  customGuideRecordState?: CustomGuideRecordState,
) {
  const validations: ExportMaterialsRequiredStateInfo<Record<any, any>>[] = [
    new ExportMaterialsRequiredStateInfo<MaterialInfoRecordState>(
      infoValidation,
      infoRecords,
    ),
    new ExportMaterialsRequiredStateInfo<MaterialAdditionalInfoRecordState>(
      additionalInfoValidation,
      additionalInfoRecords,
    ),
    new ExportMaterialsRequiredStateInfo<MaterialAvailabilityRecordState>(
      availabilityValidation,
      availabilityRecords,
    ),
    new ExportMaterialsRequiredStateInfo<CustomerMaterialRecord>(
      customerMaterialValidation,
      undefined,
      customerMaterialRecord,
    ),
  ];

  if (Object.keys(combinedPriceRecords).length > 0) {
    validations.push(
      new ExportMaterialsRequiredStateInfo<CombinedPricingRecord>(
        priceValidation,
        combinedPriceRecords,
      ),
    );
  }

  if (customGuideRecordState) {
    validations.push(
      new ExportMaterialsRequiredStateInfo<CustomGuideRecordState>(
        customGuideValidation,
        undefined,
        customGuideRecordState,
      ),
    );
  }

  return validations;
}

export const additionalInfoValidation: LoadingValidation<MaterialAdditionalInfoRecordState> =
  {
    isRecordFinishedLoading: hasMaterialAdditionalInfoFinishedLoading,
    requiredDataPoints: [ExportDataPoint.VendorItemCode],
  };

export const priceValidation: LoadingValidation<CombinedPricingRecord> = {
  isRecordFinishedLoading: (state) => state?.hasLoaded,
  requiredDataPoints: [
    ExportDataPoint.CasePrice,
    ExportDataPoint.UnitPrice,
    ExportDataPoint.CasePriceUom,
    ExportDataPoint.UnitPriceUom,
  ],
};

export const customGuideValidation: LoadingValidation<CustomGuideRecordState> =
  {
    isRecordFinishedLoading: (state) =>
      !!state?.record &&
      state?.storageAreaCategory?.hasLoaded &&
      state?.taxonomyCategory?.hasLoaded,
    requiredDataPoints: [
      ExportDataPoint.Category,
      ExportDataPoint.CustomCategory,
      ExportDataPoint.InventoryQty,
      ExportDataPoint.ParQty,
      ExportDataPoint.StorageArea,
    ],
  };

const infoValidation: LoadingValidation<MaterialInfoRecordState> = {
  isRecordFinishedLoading: hasMaterialInfoFinishedLoading,
};

const availabilityValidation: LoadingValidation<MaterialAvailabilityRecordState> =
  {
    isRecordFinishedLoading: hasMaterialAvailabilityFinishedLoading,
    requiredDataPoints: [
      ExportDataPoint.ContractItemFlag,
      ExportDataPoint.DropShipFlag,
      ExportDataPoint.EarlyCutoffFlag,
      ExportDataPoint.SpecialOrderFlag,
    ],
  };

const customerMaterialValidation: LoadingValidation<CustomerMaterialRecord> = {
  isRecordFinishedLoading: (record) => Object.keys(record).length > 0,
  requiredDataPoints: [ExportDataPoint.CustomerMaterialNumber],
};
