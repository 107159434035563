<div class="breadcrumb-container">
  <naoo-breadcrumb [breadcrumbs]="breadcrumbs"></naoo-breadcrumb>
</div>
<div class="back-button-container">
  <naoo-back-button [navigationLink]="backNavigationLink"></naoo-back-button>
</div>
@if (category) {
  <h1 class="category-title">
    {{ category.name | stringdefaulter }}
  </h1>
  @for (
    productClass of category.subCategories;
    track productClass.categoryKey
  ) {
    <naoo-product-class [category]="productClass"></naoo-product-class>
  }
}
