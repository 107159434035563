import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RenameCategoryModalComponent } from './rename-category-modal/rename-category-modal.component';
import { RenameCategoryAction } from './rename-category-modal/rename-category-action';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { CategorizedMaterials } from '../../shared/models/categorized-materials';
import { MatIconButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-category-edit-menu',
  templateUrl: './category-edit-menu.component.html',
  styleUrls: ['./category-edit-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconButton,
    NgClass,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class CategoryEditMenuComponent {
  @Input() isDisabled: boolean;
  @Input() customGuideId: string;
  @Input() categorizedMaterials: CategorizedMaterials;
  @Input() isGfsCategory: boolean;

  @Output() renameCategory = new EventEmitter<RenameCategoryAction>();
  @Output() deleteCategory = new EventEmitter<CategorizedMaterials>();

  menuAction: () => void;

  constructor(
    public dialog: MatDialog,
    private dialogService: DefaultDialogService,
  ) {}

  get categoryName(): string {
    return this.categorizedMaterials.categoryName.en;
  }

  menuClosed() {
    if (this.menuAction) {
      this.menuAction();
      this.menuAction = null;
    }
  }

  openRenameModal() {
    this.dialog
      .open(RenameCategoryModalComponent, {
        id: 'rename-custom-category',
        disableClose: true,
        panelClass: 'custom-guide-modal',
        data: {
          customGuideId: this.customGuideId,
          categoryToRename: this.categoryName,
        },
      })
      .afterClosed()
      .subscribe((newCategoryName) => {
        if (newCategoryName) {
          this.renameCategory.emit({
            newCategoryName: newCategoryName,
            oldCategoryName: this.categoryName,
          });
        }
      });
  }

  openDeleteModal() {
    this.dialogService.twoButtonModal(
      'delete-custom-category',
      {
        messageKey: 'LISTS.DELETE_CATEGORY_MODAL.TITLE',
        parameters: {
          value: this.categoryName,
        },
      },
      'LISTS.DELETE_CATEGORY_MODAL.DELETE',
      'CANCEL.TEXT',
      () => {
        this.deleteCategory.emit(this.categorizedMaterials);
      },
      () => {},
      true,
      true,
      {
        messageKey: 'LISTS.DELETE_CATEGORY_MODAL.HELPER_TEXT',
        parameters: {
          value: this.categoryName,
        },
      },
    );
  }
}
