import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DOCUMENT, AsyncPipe } from '@angular/common';
import { ListScrollService } from 'src/app/shared/services/list-scroll/list-scroll.service';
import { MaterialListStyle } from '../../../../core/store/material-row/models/material-row';
import {
  CartReviewHeaderType,
  CartReviewSection,
  CartReviewSectionName,
} from '../../../../core/store/cart-review/cart-review.state';
import { CartCounts } from '../../../../core/store/cart/cart.selectors';
import { StoreScenario } from '../cart-review-store-container/cart-review-store-container.component';
import { SessionFacade } from '../../../../core/store/session/session.facade';
import {
  ColorScheme,
  GenericExpansionPanelComponent,
} from '../../../../shared/generic-expansion-panel/generic-expansion-panel.component';
import { NaooIcon } from '../../../../shared/services/images/naoo-icon.service';
import {
  cartReviewExceptionIcon,
  cartReviewSuccessIcon,
  cartReviewWarningIcon,
} from '../../../../core/store/cart-review/transform/cart-review-factory';
import { CartReviewSuccessHeaderComponent } from './cart-review-headers/success-header/cart-review-success-header.component';
import { CartReviewDropShipHeaderComponent } from './cart-review-headers/dropship-header/cart-review-drop-ship-header.component';
import { CartReviewStoreErrorHeaderComponent } from './cart-review-headers/cart-review-store-error-header/cart-review-store-error-header.component';
import { CartReviewStoreSuccessHeaderComponent } from './cart-review-headers/cart-review-store-success-header/cart-review-store-success-header.component';
import { CartReviewDefaultHeaderComponent } from './cart-review-headers/default-header/cart-review-default-header.component';
import { CartReviewMissedCutoffHeaderComponent } from './cart-review-headers/missed-cutoff-header/cart-review-missed-cutoff-header.component';
import { MaterialListComponent } from '../../../../material-list/material-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-cart-review-expansion',
  templateUrl: './cart-review-expansion.component.html',
  styleUrls: ['./cart-review-expansion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GenericExpansionPanelComponent,
    CartReviewSuccessHeaderComponent,
    CartReviewDropShipHeaderComponent,
    CartReviewStoreErrorHeaderComponent,
    CartReviewStoreSuccessHeaderComponent,
    CartReviewDefaultHeaderComponent,
    CartReviewMissedCutoffHeaderComponent,
    MaterialListComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class CartReviewExpansionComponent {
  @ViewChild('genericExpansionPanel')
  genericExpansionPanel: GenericExpansionPanelComponent;
  @Input() section: CartReviewSection;
  @Input() cartCounts: CartCounts;
  @Input() isSectionInvalid: boolean;
  @Input() isOffline: boolean;
  @Input() submitAttempted: boolean;
  @Input() storeScenario?: StoreScenario;
  @Output() expansionToggled = new EventEmitter();

  private readonly potentiallyInvalidSections = [
    CartReviewSectionName.DropShipItemsSection,
    CartReviewSectionName.ExpressItemsSection,
    CartReviewSectionName.PickupItemsSection,
  ];

  private disableScrollToElement = false;
  private isAPartialSubmission = false;
  private expanded = true;

  readonly customerTimeZone$ =
    this.sessionFacade.getLoadedActiveCustomerTimeZone();
  readonly listStyle = MaterialListStyle.List;
  readonly colorScheme = ColorScheme.Gray;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly scrollService: ListScrollService,
    private readonly sessionFacade: SessionFacade,
  ) {}

  get iconAsset(): NaooIcon {
    if (this.isAPartialSubmission) {
      return cartReviewWarningIcon;
    }

    if (this.potentiallyInvalidSections.includes(this.section.name)) {
      return this.isSectionInvalid
        ? cartReviewExceptionIcon
        : cartReviewSuccessIcon;
    }
    return this.section.icon;
  }

  get redBorderActive(): boolean {
    return (
      (this.isSectionInvalid && this.submitAttempted) ||
      this.section.alwaysShowError
    );
  }

  get sectionCount(): string {
    return this.section.sectionCounter
      ? this.section.sectionCounter.toString()
      : '';
  }

  get headerType(): typeof CartReviewHeaderType {
    return CartReviewHeaderType;
  }

  expandSection(): void {
    if (!this.expanded) {
      this.disableScrollToElement = true;
      this.genericExpansionPanel.open();
    }
  }

  scrollToContent(): void {
    if (this.disableScrollToElement) {
      this.disableScrollToElement = false;
      return;
    }
    this.emitExpansionToggled();

    const viewportHeight = this._document.documentElement.clientHeight;
    const headerElement = this._document.getElementById(
      this.section.headerKeyId,
    );
    const headerRect = headerElement
      ? headerElement.getBoundingClientRect()
      : undefined;

    if (headerRect) {
      const scrollDifference = headerRect.top - viewportHeight / 2;
      this.scrollService.scrollDownSmoothly(scrollDifference);
    }
  }

  emitExpansionToggled(): void {
    this.expansionToggled.emit();
  }

  isAPartialSubmit(isAPartialSubmission: boolean): void {
    this.isAPartialSubmission = isAPartialSubmission;
  }

  expandEvent(expanded: boolean): void {
    this.expanded = expanded;
    expanded ? this.scrollToContent() : this.emitExpansionToggled();
  }
}
