import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationLink } from '../../../../../shared/models/navigation-link';
import {
  PurchaseMaterial,
  StorePurchaseDetailsViewModel,
} from '../../../../../core/store/store-purchase-details/models/store-purchase-details-view-model';
import { CONTENT_SCROLL } from '../../../../../shared/services/scrollable-content/scrollable-content.service';
import { RouterExtrasService } from '../../../../../shared/services/router-extras/router-extras.service';
import {
  ActionIconComponent,
  IconState,
} from '../../../../../shared/action-icon/action-icon.component';
import { VirtualScrollerComponent } from '../../../../../vendor/ngx-virtual-scroller/virtual-scroller';
import { BackButtonComponent } from '../../../../../shared/back-button/back-button.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgTemplateOutlet } from '@angular/common';
import { StorePurchaseDetailsHeaderComponent } from './header/store-purchase-details-header.component';
import { GoPointsLogoComponent } from '../../../../../shared/go-points-logo/go-points-logo.component';
import { StorePurchaseDetailsRowContainerComponent } from './row/store-purchase-details-row-container.component';
import { ErrorStateComponent } from '../../../../../shared/error-state/error-state/error-state.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooBrandPipe } from '../../../../../shared/pipes/naoo-brand.pipe';

@Component({
  selector: 'naoo-store-purchase-details-content',
  templateUrl: './store-purchase-details-content.component.html',
  styleUrls: ['./store-purchase-details-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BackButtonComponent,
    ActionIconComponent,
    MatProgressBar,
    NgTemplateOutlet,
    StorePurchaseDetailsHeaderComponent,
    GoPointsLogoComponent,
    VirtualScrollerComponent,
    StorePurchaseDetailsRowContainerComponent,
    ErrorStateComponent,
    TranslateModule,
    NaooBrandPipe,
  ],
})
export class StorePurchaseDetailsContentComponent {
  @Output() printEmitter = new EventEmitter<void>();
  @Input() isMobileWidth: boolean;
  @Input() isTabletWidth: boolean;
  @Input() shouldRenderPrint: boolean;
  @Input() set viewModel(viewModel: StorePurchaseDetailsViewModel) {
    this._viewModel = viewModel;
    setTimeout(() => {
      if (
        this.routerExtrasService.getRestoreScrollPosition(window.location.href)
      ) {
        this.routerExtrasService.restoreScrollPosition(window.location.href);
      }
    });
  }

  get viewModel(): StorePurchaseDetailsViewModel {
    return this._viewModel;
  }

  get printIconState(): IconState {
    return this.viewModel?.isLoading === false
      ? IconState.Enabled
      : IconState.Disabled;
  }

  readonly backNavigationLink: NavigationLink = {
    name: 'PURCHASE_DETAILS.BACK',
  };

  @ViewChild('scroll')
  virtualScroll: VirtualScrollerComponent<PurchaseMaterial>;

  constructor(
    private routerExtrasService: RouterExtrasService,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  private _viewModel: StorePurchaseDetailsViewModel;

  comparePurchaseMaterial(
    purchaseMaterial: PurchaseMaterial,
    otherPurchaseMaterial: PurchaseMaterial,
  ): boolean {
    return (
      purchaseMaterial?.materialNumber ===
        otherPurchaseMaterial?.materialNumber &&
      purchaseMaterial?.uom === otherPurchaseMaterial?.uom
    );
  }

  trackByMaterialUom(purchaseMaterial: PurchaseMaterial): string {
    return purchaseMaterial.materialNumber + purchaseMaterial.uom;
  }
}
