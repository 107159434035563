<mat-expansion-panel
  [expanded]="true"
  class="warning-materials-expansion-panel"
>
  <mat-expansion-panel-header
    class="warning-materials-header expansion-panel-header-large-indicator"
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'"
  >
    <mat-panel-title class="warning-description">
      <mat-icon class="warning-icon" svgIcon="warning-icon"></mat-icon>
      <span class="warning-description text-center"
        >{{ warningMaterials.length }}
        {{
          'ORDER_CONFIRMATION.ITEM_WARNINGS.TITLE' | translate | uppercase
        }}</span
      >
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="warning-materials">
    <naoo-warning-materials-header
      [canFindRelated]="true"
    ></naoo-warning-materials-header>
    @for (item of warningMaterials; track item.itemDetail.id) {
      <naoo-order-issue-row
        class="warning-material-row"
        [orderItem]="item"
        [isExpandedView]="true"
        [materialRelatedRouteDate]="materialRelatedRouteDate"
      ></naoo-order-issue-row>
    }
  </div>
</mat-expansion-panel>
