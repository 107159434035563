import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CartReviewSection } from '../../../../../../core/store/cart-review/cart-review.state';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../../../../../shared/pipes/naoo-date.pipe';
import { PipeModule } from '../../../../../../shared/pipes/pipe.module';

@Component({
  selector: 'naoo-cart-review-default-header',
  templateUrl: './cart-review-default-header.component.html',
  styleUrls: ['./cart-review-default-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, TranslateModule, NaooDatePipe, PipeModule],
})
export class CartReviewDefaultHeaderComponent {
  @Input() section: CartReviewSection;
}
