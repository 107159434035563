import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
  NotificationCallback,
  NotificationMessage,
} from '../models/notification';
import { NotificationService } from '../services/notification/notification.service';
import { naooAnimations } from '../animations/animations';
import { MessageService } from '../services/message/message.service';
import { Message } from '../models/message';
import { Localized, LocalizedText } from '../models/localized';
import { Observable, Subject } from 'rxjs';
import { concatLatestFrom } from '@ngrx/operators';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../string-defaulter/naoo-string-defaulter.pipe';

export interface CustomerMessage {
  messageId: string;
  priority: string;
  title: Localized<string>;
  bodyText?: Localized<string>;
  linkUrl?: Localized<string>;
  linkLabel?: Localized<string>;
}

@Component({
  selector: 'naoo-banner-message',
  templateUrl: './banner-message.component.html',
  styleUrls: ['./banner-message.component.scss'],
  animations: [naooAnimations.rotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    MatIcon,
    RouterLink,
    MatButton,
    AsyncPipe,
    TranslateModule,
    NaooStringDefaulterPipe,
  ],
})
export class BannerMessageComponent implements OnInit, OnDestroy {
  dismissed$: Observable<boolean>;
  collapsed$: Observable<boolean>;

  ROTATE_180 = '180';
  ROTATE_DEFAULT = 'default';

  get isVisible(): boolean {
    return (
      !!this.notificationMessage.body ||
      !!this.notificationMessage.title ||
      !!this.customerMessages.length
    );
  }

  notificationMessage: NotificationMessage = { body: '' };
  callback: { scope: ThisType<unknown>; callbackFunction: () => void };

  customerMessages: CustomerMessage[] = [];

  readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly messageService: MessageService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.retrieveSystemNotifications();
    this.retrieveCustomerMessages();
    this.dismissed$ = this.messageService.isDismissed();
    this.collapsed$ = this.messageService.isCollapsed();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  dismiss(): void {
    this.messageService.setDismissed(true);
    if (this.notificationMessage) {
      this.messageService.setLastSystemNotification(this.notificationMessage);
    }
  }

  toggle(): void {
    this.messageService.toggleCollapsed();
  }

  private retrieveSystemNotifications(): void {
    this.notificationService
      .getNotifications()
      .pipe(
        concatLatestFrom(() => this.messageService.getLastSystemNotification()),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        ([newNotification, seenNotification]) => {
          if (
            !newNotification ||
            !newNotification.message ||
            !newNotification.message.body
          ) {
            this.clear();
            this.changeDetector.markForCheck();
            return;
          }
          if (newNotification.message !== seenNotification) {
            this.messageService.setDismissed(false);
            this.notificationMessage = newNotification.message;
            !newNotification.retryCallback
              ? this.clearCallback()
              : this.setAndShowCallback(newNotification.retryCallback);
            this.changeDetector.markForCheck();
          }
        },
        () => {
          this.clear();
          this.changeDetector.markForCheck();
        },
      );
  }

  private retrieveCustomerMessages(): void {
    this.messageService
      .getMessages()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (messages) => {
          this.customerMessages = messages.map((message) =>
            this.transformCustomerMessage(message),
          );
          this.changeDetector.markForCheck();
        },
        () => {},
      );
  }

  private transformCustomerMessage(message: Message): CustomerMessage {
    return {
      messageId: message.messageId,
      priority: message.priority,
      title: this.convertLocalizedTextToLocalizedString(message.title),
      bodyText: this.convertLocalizedTextToLocalizedString(message.bodyText),
      linkUrl: this.convertLocalizedTextToLocalizedString(message.linkUrl),
      linkLabel: this.convertLocalizedTextToLocalizedString(message.linkLabel),
    };
  }

  private convertLocalizedTextToLocalizedString(
    text: LocalizedText[],
  ): Localized<string> {
    if (!text || !text.length) {
      return null;
    }

    const localized: Localized<string> = { en: null, fr: null, es: null };
    text.forEach((item) => {
      localized[item.language] = item.text;
    });
    return localized;
  }

  private clear(): void {
    this.notificationMessage.title = '';
    this.notificationMessage.body = '';
    this.clearCallback();
  }

  private clearCallback(): void {
    this.callback = null;
  }

  // In the HTML callback != null check is used to determine whether or not the callback link should be displayed
  private setAndShowCallback(callback: NotificationCallback): void {
    this.callback = callback;
  }

  executeCallback(): void {
    this.callback.callbackFunction.call(this.callback.scope, []);
  }
}
