import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateCustomGuideErrorStateMatcher } from '../../create-custom-guide-modal/create-custom-guide-error-state-matcher';
import { CustomGuideCategoryNameExistsValidator } from '../../../shared/services/validators/custom-guide-category-name-exists-validator.service';
import { NotOnlyWhitespaceValidator } from '../../../shared/services/validators/not-only-whitespace-validator.service';
import {
  MatFormField,
  MatLabel,
  MatHint,
  MatError,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-rename-category-modal',
  templateUrl: './rename-category-modal.component.html',
  styleUrls: ['./rename-category-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    MatHint,
    MatError,
    TranslateModule,
  ],
})
export class RenameCategoryModalComponent implements OnInit {
  customCategoryNameFormControl: FormControl;
  matcher = new CreateCustomGuideErrorStateMatcher();
  customCategoryMaxLength = NaooConstants.CUSTOM_CATEGORY_MAX_LENGTH;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customGuideId: string;
      categoryToRename: string;
    },
    private dialogRef: MatDialogRef<RenameCategoryModalComponent>,
    private notOnlyWhitespaceValidator: NotOnlyWhitespaceValidator,
    private customGuideCategoryNameExistsValidator: CustomGuideCategoryNameExistsValidator,
  ) {}

  get newCustomGuideCategoryName() {
    return this.customCategoryNameFormControl.value.toString().trim();
  }

  ngOnInit(): void {
    this.customCategoryNameFormControl = new FormControl(
      this.data.categoryToRename,
      [
        Validators.maxLength(this.customCategoryMaxLength),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
        this.notOnlyWhitespaceValidator.validate(),
      ],
      this.customGuideCategoryNameExistsValidator
        .validate(this.data.customGuideId, this.data.categoryToRename)
        .bind(this.customGuideCategoryNameExistsValidator),
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  renameCategory() {
    if (!this.hasCustomGuideCategoryNameChanged()) {
      this.dialogRef.close();
      return;
    }
    if (this.isFormValid()) {
      this.dialogRef.close(this.newCustomGuideCategoryName);
    }
  }

  isFormValid(): boolean {
    return this.customCategoryNameFormControl.valid;
  }

  private hasCustomGuideCategoryNameChanged() {
    return this.data.categoryToRename !== this.newCustomGuideCategoryName;
  }
}
