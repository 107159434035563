import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ExportModalDataPoint } from '../../../models/export-modal-data-point';
import { TranslatedDataPoint } from '../export-modal.component';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-export-available-data',
  templateUrl: './export-available-data.component.html',
  styleUrls: ['./export-available-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class ExportAvailableDataComponent implements OnChanges {
  @Input() unselectedDataPoints: ExportModalDataPoint[];
  @Input() isPrintModal: boolean;
  @Input() totalDataPoints: number;
  @Input() highlighted: boolean;
  @Output() selectedItems = new EventEmitter<ExportModalDataPoint[]>();

  translatedDataPoints: TranslatedDataPoint[];
  disabled: boolean;

  constructor(private localizationService: LocalizationService) {}

  selectAll() {
    this.unselectedDataPoints.forEach((item) => (item.isSelected = true));
    this.unselectedDataPoints.sort(
      (a: ExportModalDataPoint, b: ExportModalDataPoint) =>
        this.localizationService.instant(a.label) >
        this.localizationService.instant(b.label)
          ? 1
          : -1,
    );
    this.updateSelected();
  }

  updateSelected() {
    this.selectedItems.emit(
      this.unselectedDataPoints.filter((item) => item.isSelected),
    );
  }

  select(item: ExportModalDataPoint) {
    item.isSelected = true;
    this.updateSelected();
  }

  update() {
    this.translatedDataPoints = this.unselectedDataPoints.map((listItem) => {
      return {
        name: this.localizationService.instant(listItem.label),
        dataPoint: listItem,
      };
    });
    this.translatedDataPoints.sort(
      (a: TranslatedDataPoint, b: TranslatedDataPoint) =>
        a.name > b.name ? 1 : -1,
    );
    this.disabled = this.unselectedDataPoints.length === 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unselectedDataPoints']) {
      this.update();
    }
  }
}
