import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-delete-category-toast',
  templateUrl: './delete-category-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class DeleteCategoryToastComponent implements OnInit {
  protected categoryName: string;
  protected guideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private readonly data: { categoryName: string; guideName: string },
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.categoryName = this.data.categoryName;
    this.guideName = this.data.guideName;
    this.changeDetectorRef.markForCheck();
  }
}
