import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { CartReviewFacade } from '../../../../core/store/cart-review/cart-review.facade';
import { ModalFacade } from '../../../../core/store/modal/modal.facade';
import { CreditWidgetLoadingState } from '../../../../core/store/cart-review/cart-review.state';
import { NaooLogger } from '../../../../shared/logger/NaooLogger.service';
import {
  PaymentWidgetDelegate,
  PaymentWidgetInjectionService,
} from '../payment-widget-injection/payment-widget-injection.service';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-credit-payment',
  templateUrl: './credit-payment.component.html',
  styleUrls: ['./credit-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class CreditPaymentComponent
  implements OnDestroy, PaymentWidgetDelegate
{
  @Output() onTimeout = new EventEmitter<void>();

  @ViewChild('footer', { static: true }) footerElement: ElementRef;

  private timer: ReturnType<typeof this._window.setTimeout>;
  // 25 minutes
  private readonly TIME_LIMIT_MS = 25 * 60 * 1000;
  private readonly destroyed$ = new Subject();

  constructor(
    private readonly renderer2: Renderer2,
    private readonly _window: Window,
    private readonly cartReviewFacade: CartReviewFacade,
    private readonly modalFacade: ModalFacade,
    private readonly logger: NaooLogger,
    private readonly self: ElementRef,
    private readonly widgetInjectionService: PaymentWidgetInjectionService,
  ) {}

  private _checkoutId: string;

  @Input() set checkoutId(checkoutId: string) {
    this._checkoutId = checkoutId;
    this.initPaymentElement(checkoutId);
  }

  ngOnDestroy() {
    this.widgetInjectionService.removeCreditPaymentElements(this.renderer2);
    this.widgetInjectionService.unloadWidget();
    this.cartReviewFacade.updateCreditWidgetLoadingState(
      CreditWidgetLoadingState.NOT_LOADING,
    );
    clearTimeout(this.timer);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initPaymentElement(checkoutId: string) {
    this.widgetInjectionService.removeCreditPaymentElements(this.renderer2);
    clearTimeout(this.timer);
    this.widgetInjectionService.initPaymentElement(
      checkoutId,
      this.self,
      this.footerElement,
      this.renderer2,
      this,
    );
    this.startTimeout();
  }

  private startTimeout() {
    if (!this.timer) {
      this.timer = this._window.setTimeout(
        () => this.onTimeout.emit(),
        this.TIME_LIMIT_MS,
      );
    }
  }

  // PaymentWidgetDelegate methods
  widgetBeganLoading() {
    this.cartReviewFacade.updateCreditWidgetLoadingState(
      CreditWidgetLoadingState.LOADING,
    );
  }

  widgetDidSaveTransactionData() {
    this.cartReviewFacade.markExecutePaymentDone();
  }

  widgetFinishedLoading() {
    this.cartReviewFacade.updateCreditWidgetLoadingState(
      CreditWidgetLoadingState.LOADED,
    );
  }

  widgetSubmissionError(error: any) {
    this.logger.error(
      'GFS-BF67482DAAE549AE876BE6: Credit payment widget failure to submit.',
      JSON.stringify(error),
    );

    this.modalFacade.promptOneButtonModal(
      'credit-payment-processing-error',
      'CART_REVIEW.ADD_PAYMENT.PROCESSING_ERROR',
      'Okay',
      () => {},
      true,
    );
  }
}
