@if (isMobile && !isFirst) {
  <naoo-material-category-jump-buttons
    [nextCategoryIndex]="categoryHeader.nextVirtualScrollIndex"
    [prevCategoryIndex]="categoryHeader.previousVirtualScrollIndex"
    [categoryCount]="categoryHeader.count"
    (categoryJump)="jumpToCategory($event)"
  ></naoo-material-category-jump-buttons>
}

<div class="category-header" [id]="categoryHeader.name.en" tabindex="0">
  {{ categoryHeader.name | stringdefaulter }} ({{ categoryHeader.count }})
</div>

@if (list && !mobile) {
  <naoo-material-list-header
    [listStyle]="listStyle"
    [context]="context"
    [isParEnabled]="isParEnabled"
    [isLoyaltyProgramEligible]="isLoyaltyProgramEligible"
  >
  </naoo-material-list-header>
}
