import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { naooAnimations } from '../animations/animations';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderHeightService } from '../services/header-height/header-height.service';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export enum BannerOfflineMessages {
  NoInternetConnection = 'OFFLINE_NOTIFICATION_BANNER.NO_INTERNET_CONNECTION',
  TryingToReconnect = 'OFFLINE_NOTIFICATION_BANNER.TRYING_TO_RECONNECT',
  BackOnline = 'OFFLINE_NOTIFICATION_BANNER.BACK_ONLINE',
}

export enum OfflineActionBannerState {
  Display,
  Hidden,
}

@Component({
  selector: 'naoo-banner-offline-message',
  templateUrl: './banner-offline-message.component.html',
  styleUrls: ['./banner-offline-message.component.scss'],
  animations: [naooAnimations.rotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class BannerOfflineMessageComponent implements OnChanges {
  readonly ROTATE_180 = '180';
  readonly ROTATE_DEFAULT = 'default';
  readonly TITLE_TIMEOUT = 5000;
  messageOfflineToggle: boolean;
  offlineTitle: string;
  isCollapsed = false;
  private destroyed$ = new Subject<void>();
  @Input() bannerState: OfflineActionBannerState;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone,
    private headerHeightService: HeaderHeightService,
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.bannerState === OfflineActionBannerState.Display) {
      this.setOfflineModeBanner();
      this.triggerHeaderHeightCheck();
    }

    if (this.bannerState === OfflineActionBannerState.Hidden) {
      this.setOnlineModeBanner();
    }
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this.triggerHeaderHeightCheck();
  }

  private setOfflineModeBanner() {
    this.initializeBanner();
    this.setIntervalToChangeTexts();
  }

  private setIntervalToChangeTexts() {
    this.ngZone.runOutsideAngular(() => {
      interval(this.TITLE_TIMEOUT)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          if (this.messageOfflineToggle) {
            this.offlineTitle = BannerOfflineMessages.NoInternetConnection;
          } else {
            this.offlineTitle = BannerOfflineMessages.TryingToReconnect;
          }

          this.messageOfflineToggle = !this.messageOfflineToggle;
          this.ngZone.run(() => {
            this.changeDetector.markForCheck();
          });
        });
    });
  }

  private initializeBanner() {
    this.offlineTitle = BannerOfflineMessages.NoInternetConnection;
    this.changeDetector.markForCheck();
  }

  private setOnlineModeBanner() {
    this.offlineTitle = BannerOfflineMessages.BackOnline;
    this.stopInterval();
    this.changeDetector.markForCheck();
    this.triggerHeaderHeightCheck();
  }

  private stopInterval() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private triggerHeaderHeightCheck() {
    setTimeout(() => {
      this.headerHeightService.triggerHeaderHeightChange();
    });
  }
}
