import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ReminderDetails,
  ReminderHourOfTheDay,
  ReminderPreference,
  ReminderWeekDay,
} from '../../../shared/models/customer-notifications';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { isUndefined } from 'lodash-es';
import { DeviceIdentifierService } from '../../../../../shared/services/device-identifier/device-identifier.service';
import { Observable } from 'rxjs';
import moment from 'moment';
import { MatIcon } from '@angular/material/icon';
import {
  MatPrefix,
  MatFormField,
  MatLabel,
} from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'naoo-reminder-preferences',
  templateUrl: './reminder-preferences.component.html',
  styleUrls: ['./reminder-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    MatPrefix,
    MatFormField,
    MatLabel,
    MatSelect,
    ReactiveFormsModule,
    FormsModule,
    MatOption,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatTooltip,
    TranslateModule,
  ],
})
export class ReminderPreferencesComponent implements OnInit {
  @Input() reminderDetails: ReminderDetails;
  @Input() customerReminders: ReminderPreference[];
  @Input() isPhoneValid: boolean;

  @Output() updateCustomerReminders = new EventEmitter<ReminderPreference[]>();

  currentCustomerReminders: ReminderPreference[] = [];
  editingReminderIndex: number;
  selectedMessage: string;
  selectedDay: string;
  selectedHour: string;
  isMobile$: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private deviceIdentifierService: DeviceIdentifierService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
    if (this.customerReminders?.length) {
      this.currentCustomerReminders = [...this.customerReminders];
    }
  }

  public DayOfTheWeek = Object.keys(ReminderWeekDay);
  public HourOfTheDay = Object.keys(ReminderHourOfTheDay).filter((key) =>
    isNaN(Number(key)),
  );
  public disableAddCustomerReminder = true;

  changeSelectedMessage($event: MatSelectChange) {
    this.selectedMessage = $event.value;
    this.validateCustomerReminder();
  }

  changeSelectedDay($event: MatSelectChange) {
    this.selectedDay = $event.value;
    this.validateCustomerReminder();
  }

  changeSelectedHour($event: MatSelectChange) {
    this.selectedHour = $event.value;
    this.validateCustomerReminder();
  }

  addCustomerReminder() {
    const currentReminder = {
      messageId: this.selectedMessage,
      dayOfWeek: this.selectedDay,
      hourOfDay: this.selectedHour,
      timezone: this.reminderDetails.customerTimezone,
    };

    if (!isUndefined(this.editingReminderIndex)) {
      this.currentCustomerReminders[this.editingReminderIndex] =
        currentReminder;
      this.editingReminderIndex = undefined;
    } else {
      this.currentCustomerReminders.push(currentReminder);
    }

    this.updateCustomerReminders.emit(this.currentCustomerReminders);
    this.resetFields();
  }

  deleteCustomerReminder(deletingReminderIndex: number) {
    this.adjustEditingReminderIndex(deletingReminderIndex);
    this.currentCustomerReminders.splice(deletingReminderIndex, 1);
    this.updateCustomerReminders.emit(this.currentCustomerReminders);
  }

  editCustomerReminder(index: number) {
    this.editingReminderIndex = index;
    const editReminder = this.currentCustomerReminders[index];
    this.selectedMessage = editReminder.messageId;
    this.selectedDay = editReminder.dayOfWeek;
    this.selectedHour = editReminder.hourOfDay;

    this.changeDetector.markForCheck();
    this.validateCustomerReminder();
  }

  getTitleDate(customerReminder: ReminderPreference) {
    return (
      this.translate.instant('DAY_OF_THE_WEEK.' + customerReminder.dayOfWeek) +
      ', ' +
      this.translate.instant('HOUR_OF_THE_DAY.' + customerReminder.hourOfDay)
    );
  }

  getTitleCustomerReminder(customerReminder: ReminderPreference) {
    return (
      ', ' +
      this.translate.instant(
        'PREFERENCES.SMS_NOTIFICATIONS.REMINDER_MESSAGES.' +
          customerReminder.messageId,
      )
    );
  }

  getTimezoneTranslation(): string {
    return this.translate.instant(
      'REMINDER_TIMEZONES.' +
        moment
          .tz(new Date(Date.now()), this.reminderDetails.customerTimezone)
          .format('zz')
          .toUpperCase(),
    );
  }

  private adjustEditingReminderIndex(deletingReminderIndex: number) {
    if (deletingReminderIndex < this.editingReminderIndex) {
      this.editingReminderIndex--;
    } else if (deletingReminderIndex === this.editingReminderIndex) {
      this.editingReminderIndex = undefined;
    }
    this.changeDetector.markForCheck();
  }

  private resetFields(): void {
    this.selectedMessage = undefined;
    this.selectedDay = undefined;
    this.selectedHour = undefined;

    this.changeDetector.markForCheck();
    this.validateCustomerReminder();
  }

  private validateCustomerReminder(): void {
    this.disableAddCustomerReminder =
      isUndefined(this.selectedMessage) ||
      isUndefined(this.selectedHour) ||
      isUndefined(this.selectedDay);
  }
}
