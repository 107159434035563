<google-map width="100%" height="100%">
  @for (
    markerOption of storeMapMarkers?.storeMapMarkersMap | keyvalue;
    track markerOption.value.storePlantId
  ) {
    <map-marker
      [position]="markerOption.value.position"
      [icon]="getIcon(markerOption.value)"
      (mapClick)="selectPin(markerOption.value)"
    >
    </map-marker>
  }
</google-map>
