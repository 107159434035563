import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { Localized } from '../../../shared/models/localized';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-custom-guide-empty',
  templateUrl: './custom-guide-empty.component.html',
  styleUrls: ['./custom-guide-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    MatIcon,
    TranslateModule,
    NaooStringDefaulterPipe,
  ],
})
export class CustomGuideEmptyComponent {
  @Input() customGuideName: Localized<string>;
  @Input() isOfflineMode: boolean;

  constructor(private customDialogService: CustomDialogService) {}

  openMobileSearchModal() {
    this.customDialogService.openMobileSearchModal();
  }
}
