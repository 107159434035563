<div class="preferences-container">
  <div class="preferences-header">
    {{
      'PREFERENCES.SMS_NOTIFICATIONS.MANAGE_NOTIFICATION_PREFERENCES'
        | translate
    }}
  </div>
  <div class="subscriptions-container">
    @for (id of allValidNotificationSubscriptionTypes; track id) {
      <div class="subscription">
        <mat-checkbox
          class="naoo-checkbox"
          [id]="id"
          [checked]="hasSubscription(id)"
          [disabled]="!isValid"
          (change)="toggleSubscription($event)"
        >
        </mat-checkbox>
        <span class="subscription-type">{{
          getTranslationName(id) | translate
        }}</span>
        <div class="subscription-description">
          {{ getTranslationDescription(id) | translate }}
        </div>
      </div>
    }
  </div>
</div>
