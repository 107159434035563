import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Moment } from 'moment';
import { timeZoneCode } from '../../../../../../utilities/date-utilities';
import { LocalizationService } from '../../../../../../services/translation/localization.service';
import { FulfillmentType } from '../../../../../../../core/services/cart/models/cart-record';
import { NgTemplateOutlet } from '@angular/common';
import { SelectDateStepCalendarComponent } from '../select-date-step-calendar/select-date-step-calendar.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../../../../../pipes/naoo-date.pipe';

@Component({
  selector: 'naoo-select-date-step-truck',
  templateUrl: './select-date-step-truck.component.html',
  styleUrls: ['./select-date-step-truck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    SelectDateStepCalendarComponent,
    TranslateModule,
    NaooDatePipe,
  ],
})
export class SelectDateStepTruckComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() availableDates: string[];
  @Input() selectedDate: Moment;
  @Input() customerTimeZone: string;
  @Input() cutoffDateTime: string;
  @Input() isRouteDateExpired: boolean;
  @Input() isMobileWidth: boolean;

  @Output() dateChangeEmitter = new EventEmitter<Moment>();

  get timeZoneCodeCutOff(): string {
    const dateFormat = this.cutoffDateTime.substring(
      0,
      this.cutoffDateTime.indexOf('T'),
    );
    return timeZoneCode(dateFormat, this.customerTimeZone);
  }

  get timeZone(): string {
    return this.localizationService.instant(
      'TIMEZONES.' + this.timeZoneCodeCutOff,
    );
  }

  constructor(private localizationService: LocalizationService) {}
}
