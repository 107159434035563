import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { of, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { CartFacade } from '../../../core/store/cart/cart.facade';

@Injectable({
  providedIn: 'root',
})
export class CartCouponExistsValidator {
  constructor(private cartFacade: CartFacade) {}

  validate(): AsyncValidatorFn {
    return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
      const newCouponCode = ctrl.value?.toUpperCase().trim();
      return this.cartFacade.getLoadedCart().pipe(
        first(),
        map((cart) => {
          const existingCouponCode = cart.coupons.find(
            (coupon) => !!coupon && coupon.toUpperCase() === newCouponCode,
          );
          return existingCouponCode
            ? { alreadyExistingCouponCode: true }
            : null;
        }),
        catchError(() => of(null)),
      );
    };
  }
}
