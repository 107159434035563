import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GoogleMapsNaooContainerComponent } from '../../../../maps/component/google-maps-container.component';

@Component({
  selector: 'naoo-select-store-step',
  templateUrl: './select-store-step.component.html',
  styleUrls: ['./select-store-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GoogleMapsNaooContainerComponent],
})
export class SelectStoreStepComponent {
  @Input() selectedStorePlantId: string;
  @Input() customerTimeZone: string;
  @Input() isMobileWidth: boolean;
  @Output() selectedStorePlantIdEmitter = new EventEmitter<string>();

  selectStorePlantId(storePlantId: string): void {
    this.selectedStorePlantIdEmitter.emit(storePlantId);
  }
}
