import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { LogoutService } from '../../shared/services/logout/logout.service';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { HeaderComponent } from '../../header/header/header.component';
import { NaooConstants } from '../../shared/NaooConstants';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { SessionFacade } from '../../core/store/session/session.facade';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { OrdersTab } from '../../orders/orders/orders.component';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { ListScrollService } from '../../shared/services/list-scroll/list-scroll.service';
import { ActiveCartSummaryFacade } from '../../core/store/active-cart/active-cart-summary.facade';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { CustomerBrand } from '../../core/services/session/models/session-record';
import { HeaderTabsComponent } from '../../header/header-tabs/header-tabs.component';
import { MatIcon } from '@angular/material/icon';
import { CartIconComponent } from '../../cart/cart-icon/cart-icon.component';
import { HamburgerOrderMethodWidgetComponent } from '../../hamburger-order-method-widget/hamburger-order-method-widget/hamburger-order-method-widget.component';
import { DeliveryScheduleWidgetComponent } from '../../delivery-schedule-widget/delivery-schedule-widget/delivery-schedule-widget.component';
import { CustomerUnitSelectorComponent } from '../../customer-unit-selection/customer-unit-selector/customer-unit-selector.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { GoPointsLogoComponent } from '../../shared/go-points-logo/go-points-logo.component';
import { LanguageMenuComponent } from '../../shared/language-menu/language-menu.component';
import { TranslateModule } from '@ngx-translate/core';

export interface HamburgerLink {
  text: string;
  url: string;
  disabledOnOffline: boolean;
  queryParams?: {
    [k: string]: string;
  };
}

@Component({
  selector: 'naoo-mobile-hamburger-menu',
  templateUrl: './mobile-hamburger-menu-v1.component.html',
  styleUrls: ['./mobile-hamburger-menu-v1.component.scss'],
  standalone: true,
  imports: [
    HeaderTabsComponent,
    MatIcon,
    CartIconComponent,
    HamburgerOrderMethodWidgetComponent,
    DeliveryScheduleWidgetComponent,
    CustomerUnitSelectorComponent,
    RouterLink,
    NgClass,
    GoPointsLogoComponent,
    LanguageMenuComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class MobileHamburgerMenuV1Component
  implements OnDestroy, OnInit, AfterViewInit
{
  private readonly quickAddText = 'MENU.QUICK_ADD';
  private readonly specialOrderText = 'ORDERS.SPECIAL.TAB_TITLE';
  private readonly invoiceLabel = 'ORDERS.INVOICES_CREDITS.TAB_TITLE';
  private readonly storePurchaseHistoryLabel =
    'ORDERS.STORE_PURCHASE.TAB_TITLE';
  private readonly myIdCardLabel = 'MENU.MY_ID_CARD';
  readonly goPointsLabel = 'goPointsLabel';

  isOffline$ = this.offlineModeFacade.getIsOffline().pipe(shareReplay(1));

  quickAdd: HamburgerLink = {
    text: this.quickAddText,
    url: NaooConstants.CART_PATH,
    disabledOnOffline: true,
  };

  defaultLinks: HamburgerLink[] = [
    {
      text: 'MENU.MY_ID_CARD',
      url: NaooConstants.MY_ID_CARD_PATH,
      disabledOnOffline: false,
    },
    {
      text: 'MENU.GUIDES',
      url: NaooConstants.GUIDES_PATH,
      disabledOnOffline: false,
    },
    {
      text: 'MENU.CATEGORIES',
      url: NaooConstants.CATEGORIES_PATH,
      disabledOnOffline: true,
    },
    {
      text: 'ORDERS.HISTORY.TITLE',
      url: NaooConstants.ORDERS_PATH,
      queryParams: { activeTab: OrdersTab.OrderHistory },
      disabledOnOffline: true,
    },
    {
      text: this.specialOrderText,
      url: NaooConstants.ORDERS_PATH,
      queryParams: { activeTab: OrdersTab.SpecialOrders },
      disabledOnOffline: true,
    },
    {
      text: this.invoiceLabel,
      url: NaooConstants.ORDERS_PATH,
      queryParams: { activeTab: OrdersTab.InvoicesCredits },
      disabledOnOffline: true,
    },
    {
      text: this.storePurchaseHistoryLabel,
      url: NaooConstants.ORDERS_PATH,
      queryParams: { activeTab: OrdersTab.StorePurchaseHistory },
      disabledOnOffline: true,
    },
    {
      text: this.goPointsLabel,
      url: NaooConstants.ORDERS_PATH,
      queryParams: { activeTab: OrdersTab.GoPoints },
      disabledOnOffline: true,
    },
  ];

  links: HamburgerLink[] = [];

  destroyed$ = new Subject();
  customerBrand: CustomerBrand;
  previousUrl: string;
  preferences = {
    text: 'MENU.PREFERENCES',
    url: NaooConstants.PREFERENCES_PATH,
    disabledOnOffline: true,
  };
  hasIspuOrExpressPermissions: boolean;

  constructor(
    private readonly logoutService: LogoutService,
    private readonly analytics: NaooAnalyticsManager,
    private readonly sessionFacade: SessionFacade,
    private readonly activeCartSummaryFacade: ActiveCartSummaryFacade,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly offlineModeFacade: OfflineModeFacade,
    private readonly listScrollService: ListScrollService,
    private readonly dialogService: CustomDialogService,
  ) {}

  ngOnInit(): void {
    this.previousUrl = this.route.snapshot.queryParamMap.get('from')
      ? this.route.snapshot.queryParamMap.get('from')
      : '';

    this.sessionFacade
      .getLoadedCustomerBrand()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customerBrand) => {
        this.customerBrand = customerBrand;
      });

    this.sessionFacade
      .hasIspuOrExpressPermissions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((hasIspuOrExpressPermissions) => {
        this.hasIspuOrExpressPermissions = hasIspuOrExpressPermissions;
      });

    const hasStorePurchaseHistoryAccess$ =
      this.sessionFacade.hasStorePurchaseHistoryPermissions();
    const isUsCustomer$ = this.sessionFacade.isUsCustomer();
    const isLoyaltyProgramEligible$ =
      this.sessionFacade.isLoyaltyProgramEligible();
    const isSpecialOrderDashboardAccess$ =
      this.sessionFacade.isSpecialOrderDashboardAccess();

    combineLatest([
      hasStorePurchaseHistoryAccess$,
      isUsCustomer$,
      isLoyaltyProgramEligible$,
      isSpecialOrderDashboardAccess$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([
          hasStorePurchaseHistoryAccess,
          isUsCustomer,
          isLoyaltyProgramEligible,
          isSpecialOrderDashboardAccess,
        ]) => {
          const linksToHide: string[] = [];
          if (!isSpecialOrderDashboardAccess) {
            linksToHide.push(this.specialOrderText);
          }
          if (hasStorePurchaseHistoryAccess) {
            linksToHide.push(this.invoiceLabel);
          } else {
            linksToHide.push(this.storePurchaseHistoryLabel);
          }
          if (!isUsCustomer) {
            linksToHide.push(this.myIdCardLabel);
          }
          if (!isLoyaltyProgramEligible) {
            linksToHide.push(this.goPointsLabel);
          }
          this.links = this.defaultLinks.filter(
            (link) => !linksToHide.includes(link.text),
          );
        },
      );
  }

  ngAfterViewInit(): void {
    this.listScrollService.scrollToTop();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  navigateBack() {
    const baseUrl = this.previousUrl.split('?')[0];
    const activeTab = this.previousUrl.split('?activeTab=')[1];
    const searchQuery = this.previousUrl.split('search?')[1];
    const queryParams: Params = {};
    if (activeTab) {
      queryParams['activeTab'] = activeTab;
    } else if (searchQuery) {
      const params = searchQuery.split('&');
      params.forEach((word) => {
        const key = word.split('=')[0];
        queryParams[key] = decodeURIComponent(word.split('=')[1]);
      });
    }

    const urlParams = {
      queryParams,
    };

    this.router.navigate([baseUrl], urlParams);
  }

  logout() {
    this.logoutService.logout();
  }

  trackLogoClick() {
    const eventInfo: AnalyticsEventInfo = {
      action: HeaderComponent.LOGO_ANALYTICS_ACTION,
      category: HeaderComponent.LOGO_ANALYTICS_CATEGORY,
      label: HeaderComponent.LOGO_ANALYTICS_LABEL,
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  handleClick(link: HamburgerLink) {
    if (link.text === this.quickAddText) {
      this.activeCartSummaryFacade.enableQuickAdd();
      const quickAddAnalyticsEvent: AnalyticsEventInfo = {
        action: 'click',
        category: 'quick add',
        label: 'hamburger menu link',
      };
      this.analytics.trackAnalyticsEvent(quickAddAnalyticsEvent);
    }
  }

  openSupportContactModal() {
    this.dialogService.supportContactModal(this.customerBrand);
  }
}
