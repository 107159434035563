import { HelpModalValues } from '../../../shared/modals/generic-helper-modal/generic-help-modal.component';

export const guideDifferenceHelpModal: HelpModalValues = {
  title: 'LISTS.DASHBOARD_HELP_MODAL.UNDERSTAND_GUIDES_TITLE',
  sections: [
    {
      title: 'LISTS.DASHBOARD_HELP_MODAL.ORDER_GUIDE_TITLE',
      paragraphs: ['LISTS.DASHBOARD_HELP_MODAL.ORDER_GUIDE_PARAGRAPH'],
    },
    {
      title: 'LISTS.DASHBOARD_HELP_MODAL.CRITICAL_ITEMS_TITLE',
      paragraphs: ['LISTS.DASHBOARD_HELP_MODAL.CRITICAL_ITEMS_PARAGRAPH'],
    },
    {
      title: 'LISTS.DASHBOARD_HELP_MODAL.CUSTOM_GUIDE_TITLE',
      paragraphs: ['LISTS.DASHBOARD_HELP_MODAL.CUSTOM_GUIDE_PARAGRAPH'],
    },
  ],
};
