import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import { ActiveDefaultFilter } from 'src/app/core/store/search/shared/search-results';
import { FilterChipComponent } from './filter-chip/filter-chip.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FilterChipComponent, MatIcon, TranslateModule],
})
export class FilterChipsComponent {
  @Input() activeDefaultFilters: ActiveDefaultFilter[];

  constructor(private searchFacade: SearchFacade) {}

  clearAllFilters() {
    this.searchFacade.clearAllFilters();
  }
}
