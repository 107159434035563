import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  MaterialWarningType,
  WarningOpenDirection,
} from 'src/app/core/store/material-warning/material-warning';
import {
  MaterialLine,
  MaterialListStyle,
  MaterialOrdering,
  MaterialRowContext,
  MaterialRowSharedOptions,
} from '../../../../core/store/material-row/models/material-row';
import { MaterialOrderingTabComponent } from './material-ordering-tab/material-ordering-tab.component';
import { MaterialWarningComponent } from './material-warning/material-warning.component';
import { NgClass } from '@angular/common';
import { MaterialOrderingInfoComponent } from './material-ordering-info/material-ordering-info.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-ordering',
  templateUrl: './material-ordering.component.html',
  styleUrls: ['./material-ordering.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MaterialOrderingTabComponent,
    MaterialWarningComponent,
    NgClass,
    MaterialOrderingInfoComponent,
    TranslateModule,
  ],
})
export class MaterialOrderingComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() isMobile: boolean;
  @Input() displayGoPointsLogo: boolean;
  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();
  @Output() viewCommodityDetailsClicked = new EventEmitter();

  uoms: string[] = [];
  selectedOrderingInfoIndex = 0;

  private _materialOrdering: MaterialOrdering;

  @HostBinding('class.grid') get grid() {
    return this.isGrid;
  }

  @Input()
  set materialOrdering(materialOrdering: MaterialOrdering) {
    this._materialOrdering = materialOrdering;
    const inputUoms = materialOrdering.materialOrderingInfos.map(
      (info) => info.uom,
    );
    if (!inputUoms.every((uom, index) => uom === this.uoms[index])) {
      this.uoms = inputUoms;
    }
  }

  get materialOrdering(): MaterialOrdering {
    return this._materialOrdering;
  }

  get warningOpenDirection(): WarningOpenDirection {
    switch (this.materialRowOptions.materialListStyle) {
      case MaterialListStyle.List:
        return WarningOpenDirection.DownLeft;
      case MaterialListStyle.Slim:
        return WarningOpenDirection.Left;
      case MaterialListStyle.Grid:
        return this.materialOrdering.materialWarning &&
          this.doesMessageFitOnMobile(
            this.materialOrdering.materialWarning.type,
          )
          ? WarningOpenDirection.Up
          : WarningOpenDirection.UpOffset;
    }
    return undefined;
  }

  get shouldUseDropdownForUoms(): boolean {
    return (
      !this.isMobile &&
      this.materialRowOptions.materialListStyle === MaterialListStyle.Slim &&
      this.materialOrdering.materialOrderingInfos.length > 1
    );
  }

  get isGrid(): boolean {
    return this.materialRowOptions.materialListStyle === MaterialListStyle.Grid;
  }

  get displayMaxAllocation(): boolean {
    return (
      this.isGrid &&
      !!this.materialRowOptions?.materialCommodityInfo?.displayMaxAllocation
    );
  }

  get isTabView(): boolean {
    if (
      MaterialRowContext.ProductDetails === this.materialRowOptions?.context
    ) {
      return true;
    }
    return this.isMobile && !this.isGrid;
  }

  changeTab(event$: string) {
    this.selectedOrderingInfoIndex = this.uoms.indexOf(event$);
  }

  private doesMessageFitOnMobile(
    materialWarningType: MaterialWarningType,
  ): boolean {
    switch (materialWarningType) {
      case MaterialWarningType.NoStock:
      case MaterialWarningType.PartialStockCase:
      case MaterialWarningType.OpenSpecialOrders:
      case MaterialWarningType.MissedCutoff:
      case MaterialWarningType.MaximumQuantityHardStop:
      case MaterialWarningType.MaximumQuantitySoftStop:
      case MaterialWarningType.MinimumQuantitySoftStop:
        return true;
      default:
        return false;
    }
  }
}
