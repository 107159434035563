import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CartReviewSection,
  CartReviewSectionName,
} from '../../../../../../core/store/cart-review/cart-review.state';
import {
  cartReviewExceptionIcon,
  cartReviewSuccessIcon,
  cartReviewWarningIcon,
} from '../../../../../../core/store/cart-review/transform/cart-review-factory';
import {
  MatSelectChange,
  MatSelect,
  MatSelectTrigger,
} from '@angular/material/select';
import { PickupSchedulesFacade } from '../../../../../../core/store/pickup-schedules/pickup-schedules.facade';
import {
  SplitOrder,
  SplitOrderType,
} from '../../../../../../core/services/cart-order/models/cart-order';
import { CartFacade } from '../../../../../../core/store/cart/cart.facade';
import { CartCounts } from '../../../../../../core/store/cart/cart.selectors';
import { CartReviewFacade } from '../../../../../../core/store/cart-review/cart-review.facade';
import { StoreScenario } from '../../../cart-review-store-container/cart-review-store-container.component';
import { takeUntil } from 'rxjs/operators';
import { ExpressSchedulesFacade } from '../../../../../../core/store/express-schedules/express-schedules.facade';
import { ExpressDeliveryWindow } from '../../../../../../core/services/express-schedules/models/express-schedule-record';
import { Subject } from 'rxjs';
import moment, { Moment } from 'moment';
import { NaooIcon } from '../../../../../../shared/services/images/naoo-icon.service';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CartReviewStoreOptionComponent } from '../shared/cart-review-store-option/cart-review-store-option.component';
import { MatOption } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { CartReviewStoreSuccessHeaderComponent } from '../cart-review-store-success-header/cart-review-store-success-header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-cart-review-store-error-header',
  templateUrl: './cart-review-store-error-header.component.html',
  styleUrls: ['./cart-review-store-error-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    NgClass,
    MatButton,
    MatFormField,
    MatLabel,
    MatSelect,
    MatSelectTrigger,
    CartReviewStoreOptionComponent,
    MatOption,
    MatDivider,
    CartReviewStoreSuccessHeaderComponent,
    TranslateModule,
  ],
})
export class CartReviewStoreErrorHeaderComponent implements OnInit, OnDestroy {
  @Input() isOffline: boolean;
  @Input() storeSection: CartReviewSection;
  @Input() storeSectionCartCounts: CartCounts;
  @Input() storeScenario: StoreScenario;
  @Input() customerTimeZone: string;
  @Output() warningIcon = new EventEmitter<boolean>();

  isExpress: boolean;
  isSinglePickup: boolean = true;
  pickupDateChoices: Moment[] = [];
  expressDateChoices: ExpressDeliveryWindow[] = [];
  selectedDateIndex: number;
  private readonly dateFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
  private readonly dropDownCount = 5;
  private destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private cartFacade: CartFacade,
    private cartReviewFacade: CartReviewFacade,
    private pickupSchedulesFacade: PickupSchedulesFacade,
    private expressSchedulesFacade: ExpressSchedulesFacade,
  ) {}

  get bannerIcon(): NaooIcon {
    if (this.isPartial) {
      return cartReviewWarningIcon;
    }
    return this.hasResolutionDate
      ? cartReviewSuccessIcon
      : cartReviewExceptionIcon;
  }

  get displayDate(): boolean {
    return (
      StoreScenario.FutureDayPickup === this.storeScenario ||
      !this.isSinglePickup ||
      this.selectedDateIndex > 0
    );
  }

  get filteredDateChoices(): Moment[] | ExpressDeliveryWindow[] {
    const start = this.isSinglePickup && this.isSplitPickupScenario ? 0 : 1;
    return (
      this.isExpress ? this.expressDateChoices : this.pickupDateChoices
    ).slice(start, start + this.dropDownCount);
  }

  get hasResolutionDate(): boolean {
    return (
      this.isSectionEmpty ||
      (this.isExpress
        ? !!this.storeSection.expressResolutionDate
        : !!this.storeSection.pickupResolutionDate)
    );
  }

  get StoreScenario(): typeof StoreScenario {
    return StoreScenario;
  }

  get isSplitPickupScenario(): boolean {
    return StoreScenario.SplitPickup === this.storeScenario;
  }

  get dropdownLabel(): string {
    return this.isExpress
      ? 'CART_REVIEW.STORE_EXPRESS_ITEMS.NOT_AVAILABLE.EXPRESS'
      : 'CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.PICKUP';
  }

  get unavailableSingleText(): string {
    return this.isExpress
      ? 'CART_REVIEW.STORE_EXPRESS_ITEMS.NOT_AVAILABLE.SINGLE_TEXT'
      : 'CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.SINGLE_TEXT';
  }

  get pickupMoment(): Moment | undefined {
    return !this.isExpress ? (this.selectedDate as Moment) : undefined;
  }

  get expressDate(): ExpressDeliveryWindow | undefined {
    return this.isExpress
      ? (this.selectedDate as ExpressDeliveryWindow)
      : undefined;
  }

  get selectedDate(): Moment | ExpressDeliveryWindow {
    return this.filteredDateChoices[this.selectedDateIndex];
  }

  get isDisabled(): boolean {
    return this.isOffline || this.isSectionEmpty;
  }

  get isPartial(): boolean {
    return (
      this.isSinglePickup &&
      this.isSplitPickupScenario &&
      this.selectedDateIndex === 0
    );
  }

  get isSectionEmpty(): boolean {
    return this.storeSectionCartCounts.cartLineCount === 0;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    switch (this.storeSection.name) {
      case CartReviewSectionName.ExpressItemsSection:
        this.initExpress();
        break;
      case CartReviewSectionName.PickupItemsSection:
        this.initPickup();
        break;
    }
  }

  choosePickupDate(event: MatSelectChange): void {
    this.selectedDateIndex = event.value;
    if (!this.isSinglePickup) {
      this.resetSplitOrder();
    }
    this.emitToParent();
    this.updateSectionStoreResolutionDateAction();
  }

  singlePickup(): void {
    this.isSinglePickup = true;
    this.selectedDateIndex = undefined;
    this.changeDetectorRef.markForCheck();
    this.reset();
  }

  splitPickup(): void {
    this.isSinglePickup = false;
    this.selectedDateIndex = 0;
    this.changeDetectorRef.markForCheck();
    this.reset();
    this.updateSectionStoreResolutionDateAction();
  }

  private initExpress(): void {
    this.isExpress = true;
    this.expressSchedulesFacade
      .getDaysAfterFromExpressSchedules(
        this.storeSection?.suggestedAvailabilityDateTime,
        this.dropDownCount,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((daysAfterStartDate) => {
        this.expressDateChoices = [
          this.storeSection?.originalExpressDate,
        ].concat(daysAfterStartDate);
        this.changeDetectorRef.markForCheck();
      });
  }

  private initPickup(): void {
    this.isExpress = false;
    this.pickupSchedulesFacade
      .getDaysAfterFromPickupSchedules(
        this.storeSection?.store?.storePlantId,
        this.storeSection?.suggestedAvailabilityDateTime,
        this.dropDownCount,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((daysAfterStartDate) => {
        const dateChoices = [this.storeSection?.originalPickupDate];
        if (this.isSinglePickup) {
          dateChoices.push(this.storeSection?.suggestedAvailabilityDateTime);
        }
        this.pickupDateChoices = dateChoices.concat(daysAfterStartDate);
        this.changeDetectorRef.markForCheck();
      });
  }

  private getSplitOrder(storeResolutionDate: Moment): SplitOrder {
    const requestedPickupTimestamp = moment
      .tz(storeResolutionDate, 'UTC')
      .format(this.dateFormat);

    return {
      orderType: SplitOrderType.PICKUP,
      materialNumbers: this.storeSection.materialNumbers.map(
        (row) => row.value as string,
      ),
      customerPoNumber: this.storeSection.poNumber,
      storeFulfillment: {
        storePlantId: this.storeSection.store.storePlantId,
        requestedPickupTimestamp,
      },
    } as SplitOrder;
  }

  private updateSectionStoreResolutionDateAction(): void {
    const isSplit = !this.isSinglePickup && !this.isExpress;
    let sectionIndex = undefined;
    if (isSplit) {
      sectionIndex = this.storeSection.sectionCounter;
      this.updateSplitOrder(this.pickupMoment);
    }
    this.cartReviewFacade.updateCartReviewSection(
      this.storeSection.name,
      sectionIndex,
      {
        store: this.storeSection.store,
        pickupResolutionDate: this.pickupMoment,
        expressResolutionDate: this.expressDate,
      },
      isSplit,
    );
  }

  private updateSplitOrder(storeResolutionDate: Moment) {
    const splitOrder = this.getSplitOrder(storeResolutionDate);
    this.cartFacade.updateSplitOrder(splitOrder);
  }

  private reset(): void {
    this.emitToParent();
    if (!this.isSplitPickupScenario) {
      return;
    }
    this.resetSplitOrder();
    this.cartReviewFacade.updateCartReviewSection(
      this.storeSection.name,
      0,
      {
        pickupResolutionDate: undefined,
        expressResolutionDate: undefined,
      },
      false,
    );
    this.cartReviewFacade.updateCartReviewSection(
      this.storeSection.name,
      1,
      {
        pickupResolutionDate: this.storeSection.originalPickupDate,
        expressResolutionDate: this.storeSection.originalExpressDate,
      },
      false,
    );
  }

  private resetSplitOrder(): void {
    this.cartFacade.clearSplitOrders();
  }

  private emitToParent(): void {
    this.warningIcon.emit(this.isPartial);
  }
}
