@if (shouldShow) {
  <div class="preferences-opt-in">
    <div class="opt-in-header">
      <mat-icon class="email-icon" svgIcon="email"></mat-icon>
      <span class="opt-in-title">{{
        'PREFERENCES.EMAIL_NOTIFICATIONS.TITLE' | translate
      }}</span>
    </div>
    <div class="opt-in-message">
      @if (isDenyMessage()) {
        <span>
          {{ 'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.DENY.START' | translate }}
          <span class="red-text">
            {{
              'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.DENY.MIDDLE'
                | translate: { email: orderConfirmationEmailOptIn.email }
            }}
          </span>
          {{ 'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.DENY.END' | translate }}
        </span>
      } @else {
        <span
          [innerHTML]="
            optInMessage
              | translate: { email: orderConfirmationEmailOptIn.email }
          "
        ></span>
      }
      @if (showPreferencesLink) {
        <span class="preferences-link" [routerLink]="preferencesPath">{{
          'PREFERENCES.TITLE' | translate
        }}</span
        >.
      }
    </div>
    @if (showButtons) {
      <div class="opt-in-buttons">
        <button type="button" class="secondary-button" (click)="optOut()">
          {{ 'SHARED.MODALS.NO_BUTTON' | translate }}
        </button>
        <button type="button" class="primary-button" (click)="optIn()">
          {{ 'SHARED.MODALS.YES_BUTTON' | translate }}
        </button>
      </div>
    }
  </div>
}
