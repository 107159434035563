<div class="dialog-container" role="dialog">
  <naoo-modal-header
    class="order-guide-changes-modal-header"
    [title]="'ORDER_GUIDE.ORDER_GUIDE_CHANGES.TITLE_MODAL_CHANGES' | translate"
    [showCloseButton]="true"
    (closeModalEmitter)="closeModal()"
  ></naoo-modal-header>
  <div class="changes-table">
    <div class="table-header">
      <div class="cell description">
        {{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.DESCRIPTION_MODAL' | translate }}
      </div>
      <div class="cell item-code">
        {{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.ITEM_CODE_MODAL' | translate }}
      </div>
      <div class="cell status">
        {{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.STATUS_MODAL' | translate }}
      </div>
      <div class="cell date">
        {{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.DATE_MODAL' | translate }}
      </div>
      <div class="cell actions">
        {{ 'ORDER_GUIDE.ORDER_GUIDE_CHANGES.UPDATE_GUIDES' | translate }}
      </div>
    </div>
    <div class="table-body">
      @for (
        changedItem of changesData;
        track changedItem.materialNumber;
        let isOdd = $odd
      ) {
        <naoo-order-guide-changes-row
          [changedItem]="changedItem"
          [isUserOnline]="isUserOnline"
          [materialGuideMatch]="getMaterialGuideMatch(changedItem)"
          (modalClosed)="closeModal()"
          [isOdd]="isOdd"
        >
        </naoo-order-guide-changes-row>
      }
    </div>
  </div>
</div>
