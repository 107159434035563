@if (expiredDateViewModel) {
  <naoo-generic-banner
    [titleTranslationKey]="expiredBannerTitleTranslationKey"
    [messageTranslationKey]="expiredBannerMessageTranslationKey"
    [messageTranslationArgs]="{
      date: expiredDateViewModel.selectedDate | naooDate: 'shortYearDate',
      time:
        expiredDateViewModel.cartDeliveryWindowStartTimestamp
        | naooTimeRange
          : expiredDateViewModel.cartDeliveryWindowEndTimestamp
          : sharedViewModel.customerTimeZone,
    }"
  ></naoo-generic-banner>
}

<div class="header">
  <div class="header-title">
    {{ 'ORDER_METHOD_MODAL.HEADER.LABEL' | translate }}
  </div>
  <button
    [attr.aria-label]="'ORDER_METHOD_MODAL.BUTTON.CLOSE' | translate"
    (click)="close()"
    class="close-button"
  >
    <mat-icon class="close-icon" svgIcon="clear_icon"></mat-icon>
  </button>
</div>
<mat-stepper
  class="stepper"
  [disableRipple]="true"
  [linear]="true"
  [orientation]="orientation"
  [animationDuration]="animationDuration"
  (selectionChange)="stepDidChange($event)"
  (animationDone)="stepperDoneAnimating()"
  #stepper
>
  <mat-step
    class="order-method-step"
    [completed]="fulfillmentType && viewModel?.hasAvailableDates"
  >
    <ng-template matStepLabel>
      <span class="step-header">
        <div
          class="order-method-title"
          [ngClass]="{ 'completed-step': stepper.selectedIndex > 0 }"
        >
          {{ orderMethodTitle | translate }}
        </div>
        @if (this.isMobileWidth) {
          <ng-container [ngTemplateOutlet]="horizontalLine"></ng-container>
          {{ fulfillmentText(fulfillmentType) | translate }}
        }
      </span>
    </ng-template>
    <div class="step-wrapper">
      @if (!!sharedViewModel?.displayDataMap) {
        <naoo-order-method-step-container
          [isFirst]="true"
          [secondaryButtonAction]="stepBackActionForOrderMethod()"
          [isPrimaryButtonDisabled]="isOrderStepNextDisabled"
          [isSecondaryButtonHidden]="
            !!sharedViewModel?.selectedFulfillmentType || !!data?.isImportOrder
          "
        >
          <naoo-order-method-step
            [fulfillmentView]="sharedViewModel.displayDataMap"
            [fulfillmentType]="fulfillmentType"
            [expressDeliveryFees]="
              selectedExpressScheduleRecord?.expressDelivery
            "
            [expressDeliveryDate]="date"
            (fulfillmentTypeChangeEmitter)="updateUserFulfillmentType($event)"
          >
          </naoo-order-method-step>
        </naoo-order-method-step-container>
      }
    </div>
  </mat-step>
  @if (isFulfillmentType(pickupFulfillment)) {
    <mat-step [completed]="!!selectedStorePlantId">
      <ng-template matStepLabel>
        <span class="step-header">
          <div
            class="location-title"
            [ngClass]="{ 'completed-step': stepper.selectedIndex > 1 }"
          >
            {{ locationTitle | translate }}
          </div>
          @if (this.isMobileWidth) {
            <ng-container [ngTemplateOutlet]="horizontalLine"></ng-container>
            <naoo-store-display
              [storeRecord]="storeDetailsViewModel?.storeRecord"
              [customerTimeZone]="sharedViewModel.customerTimeZone"
              [isMobileHeader]="true"
            >
            </naoo-store-display>
          }
        </span>
      </ng-template>
      <div class="step-wrapper location-step">
        <naoo-order-method-step-container
          [isPrimaryButtonDisabled]="!selectedStorePlantId"
        >
          @if (!isMobileWidth) {
            <naoo-order-method-step
              [fulfillmentView]="sharedViewModel.displayDataMap"
              [fulfillmentType]="fulfillmentType"
              [filterByFulfillmentType]="true"
            >
            </naoo-order-method-step>
          }
          <naoo-select-store-step
            [selectedStorePlantId]="selectedStorePlantId"
            [customerTimeZone]="sharedViewModel.customerTimeZone"
            [isMobileWidth]="isMobileWidth"
            (selectedStorePlantIdEmitter)="updateSelectedStorePlantId($event)"
          ></naoo-select-store-step>
        </naoo-order-method-step-container>
      </div>
    </mat-step>
  }
  <mat-step
    #dateStep
    [completed]="isDateStepCompleted && isStepSelected(dateStep)"
  >
    <ng-template matStepLabel>
      <span class="step-header">
        {{ dateTitle | translate }}
        @if (this.isMobileWidth) {
          <ng-container [ngTemplateOutlet]="horizontalLine"></ng-container>
          {{ date | naooDate: 'shortDate' : 'UTC' }}
        }
      </span>
    </ng-template>
    <div class="step-wrapper date-step">
      <naoo-order-method-step-container
        [primaryButtonAction]="stepNextActionForDate()"
        [secondaryButtonAction]="stepBackActionForDateStep()"
        [isLast]="true"
        [isPrimaryButtonDisabled]="!isDateStepCompleted"
        [isSecondaryButtonHidden]="!!data?.isImportOrder"
      >
        @if (!isMobileWidth) {
          <naoo-order-method-step
            [fulfillmentView]="sharedViewModel.displayDataMap"
            [fulfillmentType]="fulfillmentType"
            [expressDeliveryFees]="
              selectedExpressScheduleRecord?.expressDelivery
            "
            [expressDeliveryDate]="date"
            [filterByFulfillmentType]="true"
          >
          </naoo-order-method-step>
        }
        @if (isStepSelected(dateStep)) {
          <naoo-select-date-container
            [fulfillmentType]="fulfillmentType"
            [selectedDate]="date"
            [availableDates]="availableDates"
            [cutoffDateTime]="cutoffDateTime"
            [customerTimeZone]="sharedViewModel.customerTimeZone"
            [selectedExpressDeliveryWindow]="expressDeliveryWindow"
            [selectedExpressScheduleRecord]="selectedExpressScheduleRecord"
            [storeDetailsViewModel]="storeDetailsViewModel"
            [isMobileWidth]="isMobileWidth"
            [isRouteDateExpired]="viewModel?.isSelectedDateExpired"
            (dateChangeEmitter)="updateUserDate($event)"
            (expressDeliveryWindowChangeEmitter)="
              updateUserSelectedExpressDeliveryWindow($event)
            "
          ></naoo-select-date-container>
        }
      </naoo-order-method-step-container>
    </div>
  </mat-step>
  <ng-template matStepperIcon="edit">
    <mat-icon>check</mat-icon>
  </ng-template>
</mat-stepper>

<ng-template #horizontalLine>
  <div class="horizontal-line"></div>
</ng-template>
