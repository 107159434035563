<a
  class="product-group-card"
  [routerLink]="getRouterLink"
  [tabindex]="0"
  [attr.aria-label]="category.name | stringdefaulter"
>
  <div class="category-image-container">
    <img
      class="category-image"
      [src]="catalogImageSrc"
      [srcset]="catalogImageSrcSet"
      (error)="noImageAvailableURL($event)"
      [alt]="category.name | stringdefaulter"
    />
  </div>
  <div class="product-group-title-wrapper">
    <h2 class="product-group-title">
      {{ category.name | stringdefaulter }}
    </h2>
  </div>
  <div class="product-group-arrow"></div>
</a>
