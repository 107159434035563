@if (shouldShowCutoffMessage) {
  <div class="cutoff-container">
    <mat-icon class="alarm-icon" svgIcon="alarm_icon"></mat-icon>
    <div>
      {{
        'HOME_PAGE.CUTOFF_MESSAGE'
          | translate
            : {
                cutoffTime:
                  (selectedDeliverySchedule?.cutoffDateTime.toDate()
                    | naooDate: 'shortYearDateTime' : translatedTimeZoneAbbr) +
                  ' ' +
                  translatedTimeZoneAbbr,
                deliveryDate:
                  selectedDeliverySchedule.routeDate
                  | naooDate: 'shortYearDate' : '',
              }
      }}
    </div>
  </div>
}
