<naoo-cutoff-message></naoo-cutoff-message>
<div class="home-page-container">
  @if (isTopRecommendationLoaded && bannersLoaded) {
    <div class="top-recommendation-container">
      <naoo-top-recommendation
        [materialListRow]="topRecommendation.materialListRows[0]"
        [title]="topRecommendation.title"
        [showButton]="isViewAllButtonVisible"
        [showBoxShadowOnMaterialRowOnly]="isSingleListWithSingleItem"
        (scrollToRecommendationsClick)="handleScrollToRecommendations()"
        (materialClick)="handleMaterialClick($event)"
      ></naoo-top-recommendation>
    </div>
  }
  <naoo-banner-ad (bannersLoaded)="bannerLoaded($event)"></naoo-banner-ad>
  @if (hasMultipleRecommendedProducts) {
    <div class="material-recommendation-container">
      <naoo-material-recommendation
        [recommendedMaterials]="recommendedMaterials"
      ></naoo-material-recommendation>
    </div>
  }
</div>
