import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DeliveryScheduleFacade } from '../../core/store/delivery-schedule/delivery-schedule.facade';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DeliverySchedule } from '../../shared/models/delivery-schedule';
import { SessionFacade } from '../../core/store/session/session.facade';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import moment from 'moment-timezone';
import { MatIcon } from '@angular/material/icon';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-cutoff-message',
  templateUrl: './cutoff-message.component.html',
  styleUrls: ['./cutoff-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NaooDatePipe, TranslateModule],
})
export class CutoffMessageComponent implements OnInit, OnDestroy {
  selectedDeliverySchedule: DeliverySchedule;
  translatedTimeZoneAbbr: string;

  private timeZone: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private deliveryScheduleFacade: DeliveryScheduleFacade,
    private localizationService: LocalizationService,
    private sessionFacade: SessionFacade,
    public changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.timeZone) {
          this.translatedTimeZoneAbbr = this.localizationService.instant(
            `TIMEZONES.${this.timeZone}`,
          );
          this.changeDetector.markForCheck();
        }
      });

    const deliverySchedule$ = this.deliveryScheduleFacade
      .getCurrentDeliverySchedule()
      .pipe(filter((schedule) => !!schedule));

    const activeCustomer$ = this.sessionFacade.getLoadedActiveCustomer();

    combineLatest([deliverySchedule$, activeCustomer$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([deliverySchedule, activeCustomer]) => {
        this.selectedDeliverySchedule = deliverySchedule;
        this.timeZone = moment
          .tz(deliverySchedule.cutoffDateTime, activeCustomer.timeZone)
          .zoneAbbr();
        this.translatedTimeZoneAbbr = this.localizationService.instant(
          'TIMEZONES.' + this.timeZone,
        );
        this.changeDetector.markForCheck();
      });
  }

  get shouldShowCutoffMessage(): boolean {
    const cutoffDateTime =
      this.selectedDeliverySchedule?.cutoffDateTime?.toString();
    return cutoffDateTime ? !!Date.parse(cutoffDateTime) : false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
