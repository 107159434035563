import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export enum IconState {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export enum ActionIcon {
  Print = 'print_v2',
  Export = 'download',
}

@Component({
  selector: 'naoo-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule],
})
export class ActionIconComponent implements OnChanges {
  @Input() actionIcon: ActionIcon;
  @Input() iconCurrentState: IconState;
  @Input() isInCartActionBar: boolean;
  @Output() clickAction = new EventEmitter<void>();

  readonly enabledIconState = IconState.Enabled;
  readonly disabledIconState = IconState.Disabled;
  readonly printActionIcon = ActionIcon.Print;

  ngOnChanges(changes: SimpleChanges) {
    const newActionIcon =
      changes && changes['actionIcon'] && changes['actionIcon'].currentValue;
    if (newActionIcon) {
      this.actionIcon = newActionIcon;
    }
  }

  handleClick() {
    this.clickAction.next();
  }

  get label() {
    return this.actionIcon === ActionIcon.Print
      ? 'ACTION.PRINT'
      : 'ACTION.EXPORT';
  }
}
