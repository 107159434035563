import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CartReviewSection,
  CartReviewSectionGroup,
  CartReviewSectionName,
  CartReviewSectionType,
} from '../../../../core/store/cart-review/cart-review.state';
import { CartCounts } from '../../../../core/store/cart/cart.selectors';
import { Moment } from 'moment';
import { CartReviewExpansionComponent } from '../cart-review-expansion/cart-review-expansion.component';

export enum StoreScenario {
  SplitPickup = 'SplitPickup',
  SameDayPickup = 'SameDayPickup',
  FutureDayPickup = 'FutureDayPickup',
}

@Component({
  selector: 'naoo-cart-review-store-container',
  templateUrl: './cart-review-store-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CartReviewExpansionComponent],
})
export class CartReviewStoreContainerComponent implements OnInit {
  @Input() isOffline: boolean;
  @Input() storeCartCounts: CartCounts[];
  @Input() storeSectionGroup: CartReviewSectionGroup;
  @Output() expansionToggled = new EventEmitter();

  scenario: StoreScenario;

  ngOnInit(): void {
    const section = this.storeSectionGroup.sections[0];
    const routeMoment = section.routeDate as Moment;
    if (this.storeSectionGroup.sections.length > 1) {
      this.scenario = StoreScenario.SplitPickup;
    } else if (routeMoment.isSame(section.suggestedAvailabilityDateTime)) {
      this.scenario = StoreScenario.SameDayPickup;
    } else {
      this.scenario = StoreScenario.FutureDayPickup;
    }
  }

  get StoreScenario(): typeof StoreScenario {
    return StoreScenario;
  }

  get CartReviewSectionType(): typeof CartReviewSectionType {
    return CartReviewSectionType;
  }

  isSectionInvalid(section: CartReviewSection, index: number): boolean {
    if (this.storeCartCounts?.[index]?.cartLineCount === 0) {
      return false;
    }
    return CartReviewSectionName.ExpressItemsSection === section.name
      ? !section.expressResolutionDate
      : !section.pickupResolutionDate;
  }
}
