<div class="content-container">
  <div class="title">
    {{ 'CART_COUPON.LIMIT' | translate }}
  </div>

  @for (cartCoupon of cartCoupons?.cartCoupons; track cartCoupon) {
    <div class="coupon-content">
      <div
        class="coupon-display"
        [ngClass]="{ 'invalid-coupon': cartCoupon.isInvalid }"
      >
        {{ cartCoupon.couponCode }}
        @if (cartCoupon.isInvalid) {
          <div class="invalid-message">
            {{ 'CART_COUPON.NOT_APPLIED' | translate }}
          </div>
        }
      </div>
      <button
        class="remove-link"
        [disabled]="cartCoupons?.isOffline"
        (click)="removeCouponCode(cartCoupon.couponCode)"
      >
        {{ 'CART_COUPON.REMOVE' | translate }}
      </button>
    </div>
  }

  @if (cartCoupons) {
    <naoo-cart-coupon-input
      [cartCoupons]="cartCoupons"
      (addCoupon)="addCouponCode($event)"
    >
    </naoo-cart-coupon-input>
  }
</div>
