<div class="header">
  <div class="title">
    <span class="recipient-message">
      {{ getNotificationsText('RECIPIENTS') | translate }}
    </span>
    <span class="customer-unit-address">
      {{ customerDisplay | titlecase }}.
    </span>
  </div>
  @if (isSms) {
    <div class="choose-notifications">
      {{ getNotificationsText('CHOOSE') | translate }}
    </div>
  }
  @if (!isSms) {
    <div class="max-notifications">
      {{ getNotificationsText('MAXIMUM') | translate }}
    </div>
  }
  @if (isSms) {
    <div class="opt-in-reminder">
      <br />
      {{ getNotificationsText('OPT_IN_REMINDER') | translate }}
    </div>
  }

  <div class="bottom-row">
    <div class="recipient-count">
      {{
        getNotificationsText('COUNT')
          | translate: { count: notifications?.customerNotifications.length }
      }}
    </div>
    <div class="button-container">
      <button
        class="add-contact-button"
        mat-icon-button
        [attr.aria-label]="
          getNotificationsText('ADD_ENTRY_ARIA_LABEL') | translate
        "
        [disabled]="notifications?.isAddDisabled"
        (click)="processAdd()"
      >
        <mat-icon class="plus-icon" svgIcon="plus-v2"></mat-icon>
        <span class="add-contact-text">{{
          getNotificationsText('ADD_ENTRY') | translate
        }}</span>
      </button>
    </div>
  </div>
</div>
