import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CostSummaryFacade } from './shared/cost-summary.facade';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CostSummary, CostSummaryContext } from './shared/cost-summary';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ViewportRuler } from '@angular/cdk/overlay';
import { CostSummaryContentComponent } from './cost-summary-content/cost-summary-content.component';
import { NaooConstants } from '../shared/NaooConstants';
import { DeviceIdentifierService } from '../shared/services/device-identifier/device-identifier.service';
import { CartFacade } from '../core/store/cart/cart.facade';
import { CartReview } from '../core/store/cart-review/cart-review.selectors';
import { NgClass, AsyncPipe } from '@angular/common';
import { CostSummaryPrintContentComponent } from './cost-summary-print-content/cost-summary-print-content.component';

@Component({
  selector: 'naoo-cost-summary-container',
  templateUrl: './cost-summary-container.component.html',
  styleUrls: ['./cost-summary-container.component.scss'],
  standalone: true,
  imports: [
    CostSummaryContentComponent,
    NgClass,
    CostSummaryPrintContentComponent,
    AsyncPipe,
  ],
})
export class CostSummaryContainerComponent implements OnInit, OnDestroy {
  private _context: CostSummaryContext;
  get context(): CostSummaryContext {
    return this._context;
  }
  @Input() set context(context: CostSummaryContext) {
    this._context = context;
    this.costSummary$ = this.costSummaryFacade.getCostSummary(context).pipe(
      tap((costSummary) => {
        this.hasMaterials =
          !!costSummary &&
          costSummary.quantities.some((quantity) => quantity.totalQuantity > 0);
        this.updateFooterVisibility();
      }),
    );
  }
  @Input() isPrintOnly = false;
  @Input() isQuickAddEnabled = false;
  @Input() hasOnlySuccessSection: boolean;
  @Input() cartReview: CartReview;
  @Output() submitButtonClick = new EventEmitter<void>();
  @Output() backLinkClicked = new EventEmitter<void>();
  @ViewChild('costSummaryContent', { static: true })
  costSummaryContent: CostSummaryContentComponent;
  private destroyed$: Subject<boolean> = new Subject<boolean>();
  private _shouldShowFloatingFooter$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isMobile$: Observable<boolean>;
  isPoNumberInvalid$: Observable<boolean>;
  costSummary$: Observable<CostSummary>;
  onScroll: EventListener;

  shouldShowFloatingFooter$: Observable<boolean> =
    this._shouldShowFloatingFooter$.pipe(distinctUntilChanged());

  private hasMaterials: boolean;
  private canSkipFooterCheck: boolean;

  constructor(
    private costSummaryFacade: CostSummaryFacade,
    private viewportRuler: ViewportRuler,
    private deviceIdentifierService: DeviceIdentifierService,
    private cartFacade: CartFacade,
    private _window: Window,
  ) {}

  ngOnInit() {
    this.isMobile$ = this.deviceIdentifierService.observeDeviceType().pipe(
      tap((isMobile) => {
        this.canSkipFooterCheck = !isMobile;
      }),
    );

    this.onScroll = () => {
      this.updateFooterVisibility();
    };

    this._window.addEventListener(
      NaooConstants.SCROLL_EVENT_LISTENER_TYPE,
      this.onScroll,
      true,
    );

    this.isPoNumberInvalid$ = this.cartFacade.isPoNumberInvalid();
  }

  ngOnDestroy() {
    this._window.removeEventListener(
      NaooConstants.SCROLL_EVENT_LISTENER_TYPE,
      this.onScroll,
      true,
    );
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  updateFooterVisibility() {
    this._shouldShowFloatingFooter$.next(this.isSummaryHidden());
  }

  get paymentReview(): boolean {
    return !!this.cartReview?.groups[0]?.sections[0]?.originalPickupDate;
  }

  isSummaryHidden(): boolean {
    if (this.canSkipFooterCheck || !this.hasMaterials) {
      return false;
    }
    const bottomOffset = this.viewportRuler.getViewportRect().bottom;
    const costSummaryBottom =
      this.costSummaryContent.costSummaryRef.nativeElement.getBoundingClientRect()
        .bottom;

    return bottomOffset < costSummaryBottom;
  }
}
