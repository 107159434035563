import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'naoo-hero-images',
  templateUrl: './hero-images.component.html',
  styleUrls: ['./hero-images.component.scss'],
  standalone: true,
})
export class HeroImagesComponent implements OnInit {
  @Input() hostComponent: string | null;
  @Input() height = '200px';
  imageUrl: string;

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.imageUrl = '/assets/images/' + this.hostComponent + '.jpg';
    this.httpClient
      .get('./assets/images/' + this.hostComponent + '.jpg', {
        responseType: 'blob',
      })
      .subscribe(
        () => {},
        () => {
          this.hostComponent = null;
        },
      );
  }
}
