<div>
  <mat-form-field>
    <mat-label class="phone-label">
      {{ 'PREFERENCES.SMS_NOTIFICATIONS.INPUT.PRIMARY' | translate }}
    </mat-label>
    <input
      class="phone-input"
      type="tel"
      matInput
      #phoneElement
      autocomplete="off"
      [formControl]="telephoneControl"
      (input)="inputHandler($event)"
      (focusout)="showValidation()"
      (keydown.enter)="showValidation()"
      [maxlength]="formattedMaxLength"
    />
    @if (isDuplicateNumber) {
      <mat-error class="phone-error">
        {{
          'PREFERENCES.SMS_NOTIFICATIONS.VALIDATIONS.DUPLICATE_PHONE_NUMBER'
            | translate
        }}
      </mat-error>
    }
    @if (displayLengthErrors && isInvalidLength) {
      <mat-error class="phone-error">
        {{
          'PREFERENCES.SMS_NOTIFICATIONS.VALIDATIONS.INVALID_CHARACTERS'
            | translate
        }}
      </mat-error>
    }
  </mat-form-field>
</div>
