<naoo-modal-header
  [title]="'IMPORT_ORDER.MODAL_TITLE' | translate"
  [showCloseButton]="showCloseButton"
  (closeModalEmitter)="closeModal(true)"
></naoo-modal-header>

<div class="step-container">
  @switch (importOrderCurrentStep) {
    @case (importOrderStep.Loading) {
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    }
    @case (importOrderStep.ImportFile) {
      @if (!cartTransferData) {
        <naoo-import-file
          [imagePath]="'IMPORT_ORDER.IMPORT_FILE.GRID_IMAGE_URL'"
          [fileRequirements]="fileRequirements"
          [csvTemplate]="'IMPORT_ORDER.FILE_ERROR.CSV_TEMPLATE_URL'"
          [excelTemplate]="'IMPORT_ORDER.FILE_ERROR.EXCEL_TEMPLATE_URL'"
          [title]="'IMPORT_ORDER.IMPORT_FILE.TITLE'"
          (fileSelected)="importContentStepNext($event)"
          (templateDownload)="trackTemplateDownload($event)"
        ></naoo-import-file>
      }
    }
    @case (importOrderStep.RowError) {
      <naoo-row-error
        canAddInvalidRows="true"
        [totalImportRowsCount]="totalRowCount"
        [validImportRowsCount]="importableRowCount"
        (startOver)="startOver()"
        (importContinue)="rowErrorStepNext()"
        (includeInvalidRows)="includeInvalidRows($event)"
      >
        <naoo-import-order-error-table
          [importOrderRows]="invalidImportOrderRows"
          [includeCustomerMaterial]="customerMaterialEnabled"
        ></naoo-import-order-error-table>
      </naoo-row-error>
    }
    @case (importOrderStep.FileError) {
      <naoo-file-error
        [fileRequirements]="fileRequirements"
        [csvTemplate]="'IMPORT_ORDER.FILE_ERROR.CSV_TEMPLATE_URL'"
        [excelTemplate]="'IMPORT_ORDER.FILE_ERROR.EXCEL_TEMPLATE_URL'"
        [fileName]="fileName"
        [fileError]="fileError"
        [errorImage]="'IMPORT_ORDER.FILE_ERROR.ERROR_IMAGE_URL'"
        (startOver)="startOver()"
        (templateDownload)="trackTemplateDownload($event)"
      ></naoo-file-error>
    }
  }
</div>
