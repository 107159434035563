import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-delete-custom-guide-toast',
  templateUrl: './delete-custom-guide-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class DeleteCustomGuideToastComponent implements OnInit {
  protected customGuideName: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    private readonly data: { customGuideName: string },
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.customGuideName = this.data.customGuideName;
    this.changeDetectorRef.markForCheck();
  }
}
