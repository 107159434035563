import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { GuideCategoryHeader, GuideHeader } from '../guides';
import { MaterialListStyle } from 'src/app/core/store/material-row/models/material-row';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { IconState } from 'src/app/shared/action-icon/action-icon.component';
import { finalize, first, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { isPrintEvent } from '../../../shared/utilities/keyboard-event-utilities';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { ExportMaterialsService } from '../../../shared/services/export-materials/export-materials.service';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { NgClass, AsyncPipe } from '@angular/common';
import { GuidesHeaderBreadcrumbComponent } from './guides-header-breadcrumb/guides-header-breadcrumb.component';
import { ActionIconComponent } from '../../../shared/action-icon/action-icon.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { GuidesActionBarComponent } from '../guides-action-bar/guides-action-bar.component';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-header',
  templateUrl: './guides-header.component.html',
  styleUrls: ['./guides-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    GuidesHeaderBreadcrumbComponent,
    ActionIconComponent,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatIcon,
    GuidesActionBarComponent,
    AsyncPipe,
    NaooStringDefaulterPipe,
    TranslateModule,
  ],
})
export class GuidesHeaderComponent implements OnDestroy {
  @Input() guideHeader: GuideHeader;
  @Input() preferredView: MaterialListStyle;
  @Input() categoryHeaders: GuideCategoryHeader[];
  @Input() isOffline: boolean;
  @Input() isBreadcrumbDisabled?: boolean;
  @Input() printInput$: Observable<ExportMaterialsInput>;
  @Input() exportInput$: Observable<ExportMaterialsInput>;

  @Output() openMobileActionsModal = new EventEmitter();
  @Output() scrollToCategory = new EventEmitter<number>();
  @Output() updateSearchText = new EventEmitter<string>();
  @Output() updateMaterialView = new EventEmitter<MaterialListStyle>();
  @Output() clearInventoryQuantities = new EventEmitter<void>();
  @Output() toggleParOrdering = new EventEmitter<boolean>();

  readonly disabledIconState = IconState.Disabled;

  printIconState$ = new BehaviorSubject<IconState>(IconState.Enabled);
  exportIconState$ = new BehaviorSubject<IconState>(IconState.Enabled);
  destroyed$ = new Subject<void>();

  constructor(
    private exportMaterialsService: ExportMaterialsService,
    private analytics: NaooAnalyticsManager,
  ) {}

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      isPrintEvent(event) &&
      this.printIconState$.getValue() === IconState.Enabled
    ) {
      this.trackCtrlPrintEvent();
      this.printGuide();
    }
  }

  handleUpdateSearchText(searchText: string) {
    this.updateSearchText.emit(searchText);
  }

  handleUpdateMaterialView(listStyle: MaterialListStyle) {
    this.updateMaterialView.emit(listStyle);
  }

  handleOpenMobileActionsModal() {
    this.openMobileActionsModal.emit();
  }

  handleScrollToCategory($event: MatSelectChange) {
    this.scrollToCategory.emit($event.value);
  }

  handleClearInventoryQuantities() {
    this.clearInventoryQuantities.emit();
  }

  handleToggleParOrdering(isEnabled: boolean) {
    this.toggleParOrdering.emit(isEnabled);
  }

  printGuide() {
    this.exportMaterials(this.printInput$, this.printIconState$);
  }

  exportGuide() {
    this.exportMaterials(this.exportInput$, this.exportIconState$);
  }

  private exportMaterials(
    exportMaterialsInput$: Observable<ExportMaterialsInput>,
    iconState$: Subject<IconState>,
  ) {
    iconState$.next(IconState.Disabled);

    exportMaterialsInput$
      .pipe(
        first(),
        switchMap((input: ExportMaterialsInput) =>
          this.exportMaterialsService.exportMaterials(input),
        ),
        finalize(() => iconState$.next(IconState.Enabled)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  private trackCtrlPrintEvent(): void {
    const eventInfo: AnalyticsEventInfo = {
      action: 'print',
      category: 'general',
      label: 'ctrlp',
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }
}
