import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NaooAnalyticsManager } from '../analytics/NaooAnalyticsManager';
import { Warning } from '../../core/services/product-warning/model/warning';
import { MaterialWarningFacade } from '../../core/store/material-warning/material-warning.facade';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-warning-alert',
  templateUrl: './warning-alert.component.html',
  styleUrls: ['./warning-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class WarningAlertComponent implements OnChanges {
  @Output() dismiss = new EventEmitter();
  @Input() arrowOffset = '162px';
  @Input() isInContainer = false;
  @Input() isArrowVisible = true;

  @Input() warning: Warning;
  hasFocus = false;

  constructor(
    private analytics: NaooAnalyticsManager,
    private materialWarningFacade: MaterialWarningFacade,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['warning'] && changes['warning'].currentValue) {
      this.update(changes['warning'].currentValue);
    }
  }

  private update(warning: Warning) {
    const displayEvent = warning.getDisplayAnalyticsEvent();
    if (displayEvent) {
      this.analytics.trackAnalyticsEvent(displayEvent);
      this.materialWarningFacade.displayedAnalytics(
        warning.offeringId,
        warning.type,
      );
    }
  }

  dismissWarning() {
    const dismissEvent = this.warning.getDismissAnalyticsEvent();
    if (dismissEvent) {
      this.analytics.trackAnalyticsEvent(dismissEvent);
    }
    this.materialWarningFacade.acknowledgeWarning(
      this.warning.offeringId,
      this.warning.type,
    );

    this.dismiss.emit();
  }

  isDismissButtonFocused(): boolean {
    return this.hasFocus;
  }
}
