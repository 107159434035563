import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CriticalItemsFacade } from '../../../core/store/critical-items/critical-items.facade';
import { Observable } from 'rxjs';
import { CriticalItemsGuide } from '../../../core/store/critical-items/models/critical-items-guide';
import { tap } from 'rxjs/operators';
import { LoadingService } from '../../../shared/services/loading-service/loading.service';
import { DeviceIdentifierService } from 'src/app/shared/services/device-identifier/device-identifier.service';
import { RouterExtrasService } from 'src/app/shared/services/router-extras/router-extras.service';
import { LastOrderedFacade } from '../../../core/store/last-ordered/last-ordered.facade';
import { InventoryAvailabilityFacade } from '../../../core/store/inventory-availability/inventory-availability.facade';
import { MaterialListRowType } from '../../../material-list/models/material-list';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { MaterialRowContext } from '../../../core/store/material-row/models/material-row';
import { CriticalItemsGuideContentComponent } from '../critical-items-guide-content/critical-items-guide-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-critical-items-guide-container',
  template: `
    <naoo-critical-items-guide-content
      [criticalItemsGuide]="criticalItemsGuide$ | async"
      [isMobile]="isMobile$ | async"
    ></naoo-critical-items-guide-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, CriticalItemsGuideContentComponent],
})
export class CriticalItemsGuideContainerComponent implements OnInit, OnDestroy {
  criticalItemsGuide$: Observable<CriticalItemsGuide>;
  isMobile$: Observable<boolean>;

  private needsLoad = true;

  constructor(
    private loadingService: LoadingService,
    private criticalItemsFacade: CriticalItemsFacade,
    private deviceIdentifierService: DeviceIdentifierService,
    private routerExtras: RouterExtrasService,
    private lastOrderedFacade: LastOrderedFacade,
    private inventoryAvailabilityFacade: InventoryAvailabilityFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnInit() {
    this.loadingService.start();
    this.criticalItemsGuide$ = this.criticalItemsFacade
      .getCriticalItemsGuide()
      .pipe(
        tap((guide) => {
          if (guide) {
            this.loadingService.stop();
            if (
              this.routerExtras.getRestoreScrollPosition(window.location.href)
            ) {
              this.routerExtras.restoreScrollPosition(window.location.href);
            }
            this.loadData(guide);
          }
        }),
      );

    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
  }

  ngOnDestroy() {
    this.loadingService.stop();
    this.criticalItemsFacade.updateSearchText('');
  }

  private loadData(guide: CriticalItemsGuide) {
    if (this.needsLoad) {
      const materialNumbers = guide.materialListRows
        .filter((row) => row.type === MaterialListRowType.MaterialRow)
        .map((row) => row.value as string);

      this.inventoryAvailabilityFacade.loadCartInventoryAvailability(
        materialNumbers,
      );
      this.lastOrderedFacade.loadLastOrdered(materialNumbers);
      this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
        materialNumbers,
        MaterialRowContext.CriticalItemsGuide,
      );

      this.needsLoad = false;
    }
  }
}
