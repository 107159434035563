import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { WebBffService } from '../web-bff/web-bff.service';
import { CartsTotalsRecord } from './user-cart-totals';
import { NaooHttpClient } from '../../http-client/naoo-http-client';
import { silentNoRetryRequestConfiguration } from '../../http-client/naoo-request-configuration';

@Injectable({ providedIn: 'root' })
export class UserCartTotalsService {
  constructor(
    private httpClient: NaooHttpClient,
    private webBffService: WebBffService,
  ) {}

  getUserCartTotals(): Observable<CartsTotalsRecord> {
    return this.httpClient
      .get<CartsTotalsRecord>(
        this.webBffService.getBff() + '/api/v4/cart-totals',
        undefined,
        silentNoRetryRequestConfiguration,
      )
      .pipe(share());
  }
}
