<div class="header-container">
  <div class="back-container">
    <naoo-back-button
      [navigationLink]="getBackNavigationLink()"
    ></naoo-back-button>
    <div class="action-item-container">
      <naoo-action-icon
        [actionIcon]="ActionIcon.Print"
        [iconCurrentState]="printIconCurrentState"
        (clickAction)="printMaterialDetails()"
      ></naoo-action-icon>
    </div>
  </div>
  <div class="line-separator"></div>
</div>
@if (materialInfo) {
  <div class="main-container">
    @if (!isAccordionView) {
      <div class="image-and-ordering-container">
        <ng-container
          *ngTemplateOutlet="imageAndQuantityTemplate"
        ></ng-container>
      </div>
    } @else {
      <div class="image-container">
        <naoo-material-store-stored-button
          [materialAvailability]="materialDetailsViewModel.materialAvailability"
          [fulfillmentType]="materialDetailsViewModel.cartFulfillmentType"
          [isPdp]="true"
        ></naoo-material-store-stored-button>
        <naoo-image-carousel
          [imageAlt]="materialInfo.description | stringdefaulter"
          [images]="
            materialDetailsViewModel?.materialAdditionalInfo?.images
              | naoocarouselimage: materialInfo.materialNumber
          "
          [isFallbackImage]="isFallbackImage()"
          [isMobile]="isMobile"
          [isAccordionView]="isAccordionView"
          [materialNumber]="
            materialDetailsViewModel?.materialRow?.materialRowOptions
              .materialNumber
          "
          (select)="onImageSelect($event)"
        ></naoo-image-carousel>
      </div>
      <div class="inventory-container">
        <naoo-inventory
          [materialNumber]="materialInfo.materialNumber"
        ></naoo-inventory>
      </div>
      @if (materialDetailsViewModel?.orderableComplimentaryMaterials?.length) {
        <div class="complimentary-product-container">
          <span class="section-header">
            {{ 'PRODUCT_DETAILS.COMPATIBLE_WITH' | translate }}
          </span>
          @for (
            materialNumber of materialDetailsViewModel.orderableComplimentaryMaterials
              | slice: 0 : 5;
            track materialNumber
          ) {
            <naoo-complimentary-product [materialNumber]="materialNumber">
            </naoo-complimentary-product>
          }
        </div>
      }
      @if (materialDetailsViewModel.materialAttributes?.length > 0) {
        <div class="attributes-section">
          <div class="attributes-header-section">
            <span class="section-header attributes-header">
              {{ 'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES' | translate }}
            </span>
            <button
              attr.aria-label="{{
                'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES_DEFINITIONS' | translate
              }}"
              class="product-attributes-button"
              (click)="openInfoModal()"
            ></button>
          </div>
          <ul class="attribute-item-container">
            @for (
              attr of materialDetailsViewModel.materialAttributes;
              track attr.name.en
            ) {
              <li class="attribute-item">
                {{
                  ProductDetailsUtilities.getLocalized(
                    materialDetailsViewModel?.currentLanguage,
                    attr.name
                  )
                }}
              </li>
            }
          </ul>
        </div>
      }
      @if (!isAccordionView && materialDetailsViewModel.isGfsUsCustomerBrand) {
        <naoo-nutrition-facts-container
          [materialNumber]="materialInfo.materialNumber"
          [nutritionLabel]="materialDetailsViewModel.nutritionLabel"
        ></naoo-nutrition-facts-container>
      }
      @if (
        !isAccordionView &&
        !materialDetailsViewModel.isGfsUsCustomerBrand &&
        ProductDetailsUtilities.hasNutritionInformation(
          materialDetailsViewModel
        )
      ) {
        <div class="nutrition-tables-container">
          <naoo-nutrition-details
            class="nutrition-details"
            [nutritionDetails]="
              materialDetailsViewModel.materialAdditionalInfo.nutritionalInfo
            "
          ></naoo-nutrition-details>
        </div>
      }
    }
    @if (!isAccordionView) {
      <div class="product-container">
        <ng-container *ngTemplateOutlet="productTemplate"></ng-container>
      </div>
    } @else {
      <div class="print-header">
        <img
          [src]="
            'LOGO.URL'
              | naoobrand: materialDetailsViewModel.customerBrand
              | translate
          "
          [alt]="
            'LOGO.TEXT'
              | naoobrand: materialDetailsViewModel.customerBrand
              | translate
          "
        />
        <div class="title">
          {{ materialDescription | stringdefaulter }}
        </div>
      </div>
      <div class="print-product-details">
        <div>
          <ng-container *ngTemplateOutlet="productDescription"></ng-container>
          <ng-container
            *ngTemplateOutlet="productPrimaryDetails"
          ></ng-container>
        </div>
        @if (selectedCarouselImage) {
          <img
            [src]="selectedCarouselImage.largeImageUrl.src"
            [alt]="materialInfo.description | stringdefaulter"
            (error)="onImageSelect(null)"
            class="print-image"
          />
        }
      </div>
      <ng-container *ngTemplateOutlet="productDescription"></ng-container>
      <ng-container *ngTemplateOutlet="productPrimaryDetails"></ng-container>
      <div class="product-secondary-details">
        <naoo-product-secondary-details
          [viewModel]="materialDetailsViewModel"
          [isAccordionView]="isAccordionView"
        >
        </naoo-product-secondary-details>
        @if (materialInfo) {
          <div class="print-only">
            <ng-container *ngTemplateOutlet="disclaimer"></ng-container>
          </div>
        }
      </div>
    }
  </div>
}
@if (materialInfo) {
  <div class="material-recommendation-container">
    <ng-container *ngTemplateOutlet="materialRecommendation"></ng-container>
  </div>
}

<ng-template #imageAndQuantityTemplate>
  <div class="image-container">
    <naoo-material-store-stored-button
      [materialAvailability]="materialDetailsViewModel.materialAvailability"
      [fulfillmentType]="materialDetailsViewModel.cartFulfillmentType"
      [isPdp]="true"
    ></naoo-material-store-stored-button>
    <naoo-image-carousel
      [imageAlt]="materialInfo.description | stringdefaulter"
      [images]="
        materialDetailsViewModel?.materialAdditionalInfo?.images
          | naoocarouselimage: materialInfo.materialNumber
      "
      [isFallbackImage]="isFallbackImage()"
      [isMobile]="isMobile"
      [isAccordionView]="isAccordionView"
      [materialNumber]="
        materialDetailsViewModel?.materialRow?.materialRowOptions.materialNumber
      "
      (select)="onImageSelect($event)"
    ></naoo-image-carousel>
  </div>
  <div class="inventory-container">
    <naoo-inventory
      [materialNumber]="materialInfo.materialNumber"
    ></naoo-inventory>
  </div>
  @if (materialDetailsViewModel?.orderableComplimentaryMaterials?.length) {
    <div class="complimentary-product-container">
      <span class="section-header">
        {{ 'PRODUCT_DETAILS.COMPATIBLE_WITH' | translate }}
      </span>
      @for (
        materialNumber of materialDetailsViewModel.orderableComplimentaryMaterials
          | slice: 0 : 5;
        track materialNumber
      ) {
        <naoo-complimentary-product [materialNumber]="materialNumber">
        </naoo-complimentary-product>
      }
    </div>
  }
  @if (materialDetailsViewModel.materialAttributes?.length > 0) {
    <div class="attributes-section">
      <div class="attributes-header-section">
        <span class="section-header attributes-header">
          {{ 'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES' | translate }}
        </span>
        <button
          attr.aria-label="{{
            'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES_DEFINITIONS' | translate
          }}"
          class="product-attributes-button"
          (click)="openInfoModal()"
        ></button>
      </div>
      <ul class="attribute-item-container">
        @for (
          attr of materialDetailsViewModel.materialAttributes;
          track attr.name.en
        ) {
          <li class="attribute-item">
            {{
              ProductDetailsUtilities.getLocalized(
                materialDetailsViewModel?.currentLanguage,
                attr.name
              )
            }}
          </li>
        }
      </ul>
    </div>
  }

  @if (!isAccordionView && materialDetailsViewModel.isGfsUsCustomerBrand) {
    <naoo-nutrition-facts-container
      [materialNumber]="materialInfo.materialNumber"
      [nutritionLabel]="materialDetailsViewModel.nutritionLabel"
    ></naoo-nutrition-facts-container>
  }

  @if (
    !isAccordionView &&
    !materialDetailsViewModel.isGfsUsCustomerBrand &&
    ProductDetailsUtilities.hasNutritionInformation(materialDetailsViewModel)
  ) {
    <div class="nutrition-tables-container">
      <naoo-nutrition-details
        class="nutrition-details"
        [nutritionDetails]="
          materialDetailsViewModel.materialAdditionalInfo.nutritionalInfo
        "
      ></naoo-nutrition-details>
    </div>
  }
</ng-template>

<ng-template #productTemplate>
  <div class="print-header">
    <img
      [src]="
        'LOGO.URL'
          | naoobrand: materialDetailsViewModel.customerBrand
          | translate
      "
      [alt]="
        'LOGO.TEXT'
          | naoobrand: materialDetailsViewModel.customerBrand
          | translate
      "
    />
    <div class="title">
      {{ materialDescription | stringdefaulter }}
    </div>
  </div>
  <div class="print-product-details">
    <div>
      <ng-container *ngTemplateOutlet="productDescription"></ng-container>
      <ng-container *ngTemplateOutlet="productPrimaryDetails"></ng-container>
    </div>
    @if (selectedCarouselImage) {
      <img
        [src]="selectedCarouselImage.largeImageUrl.src"
        [alt]="materialInfo.description | stringdefaulter"
        (error)="onImageSelect(null)"
        class="print-image"
      />
    }
  </div>

  <ng-container *ngTemplateOutlet="productDescription"></ng-container>
  <ng-container *ngTemplateOutlet="productPrimaryDetails"></ng-container>
  <div class="product-secondary-details">
    <naoo-product-secondary-details
      [viewModel]="materialDetailsViewModel"
      [isAccordionView]="isAccordionView"
    >
    </naoo-product-secondary-details>
    @if (materialInfo) {
      <div class="print-only">
        <ng-container *ngTemplateOutlet="disclaimer"></ng-container>
      </div>
    }
  </div>
</ng-template>

<ng-template #disclaimer>
  @if (materialDetailsViewModel.materialAdditionalInfo?.halalInfo) {
    <p class="certifying-body">
      {{
        'PRODUCT_DETAILS.HALAL_CERTIFYING_BODY'
          | translate
            : {
                certifyingBody:
                  materialDetailsViewModel.materialAdditionalInfo.halalInfo,
              }
      }}
    </p>
  }
  <p class="disclaimer">
    <strong>{{ 'DISCLAIMER' | translate }}: </strong>
    <span>{{
      'PRODUCT_DETAILS.DISCLAIMER_TEXT'
        | naoobrand: materialDetailsViewModel.customerBrand
        | translate
    }}</span>
  </p>
</ng-template>

<ng-template #materialRecommendation>
  @if (materialDetailsViewModel?.recommendedMaterials?.length > 0) {
    <div class="material-recommendation">
      <naoo-material-recommendation
        [recommendedMaterials]="materialDetailsViewModel.recommendedMaterials"
      >
      </naoo-material-recommendation>
    </div>
  }
  <ng-container *ngTemplateOutlet="disclaimer"></ng-container>
</ng-template>

<ng-template #productDescription>
  <div class="description-wrapper">
    <div class="product-description">
      <div class="description-header">
        <h1 class="description">
          {{ materialDescription | stringdefaulter }}
        </h1>
      </div>
      <div class="sub-text-wrapper">
        <span class="sub-text">
          <span class="offering-id">
            @if (materialDetailsViewModel?.customerMaterialNumber) {
              {{ materialDetailsViewModel.customerMaterialNumber }} :
            }
            #{{ materialInfo?.materialNumber }}
            @if (materialDetailsViewModel?.gtin) {
              <span class="gtin"
                >| {{ 'PRODUCT_DETAILS.GTIN' | translate }}:
                {{ materialDetailsViewModel.gtin }}</span
              >
            }
          </span>
        </span>
        @if (materialInfo?.units?.length > 0) {
          <span class="pack-size">
            |
            {{ materialInfo.units | packsize: materialInfo.innerPackSize }}
          </span>
        }
      </div>
      @if (displayLoyaltyPoints) {
        <div class="print-points">
          <mat-icon class="loyalty-icon" svgIcon="loyalty_icon"></mat-icon>
          {{
            materialRow.materialOrdering.materialOrderingInfos[0].loyaltyPoints
              | naooloyalty
          }}
        </div>
      }
    </div>
    <naoo-material-options-menu
      [materialRowOptions]="materialRow?.materialRowOptions"
      [isMobile]="isMobile"
    >
    </naoo-material-options-menu>
  </div>
</ng-template>

<ng-template #productPrimaryDetails>
  <div class="product-primary-details">
    <div class="product-flag-wrapper">
      <naoo-material-flag-container
        [flags]="materialRow?.flags"
      ></naoo-material-flag-container>
    </div>
    <div class="ordering-container">
      <div
        [ngClass]="
          materialRow?.materialOrdering
            ? 'ordering-content'
            : 'unorderable-content'
        "
      >
        @if (materialRow?.materialOrdering) {
          <naoo-material-ordering
            [materialOrdering]="materialRow.materialOrdering"
            [materialRowOptions]="materialRow.materialRowOptions"
            [isMobile]="isMobile"
            (viewCommodityDetailsClicked)="openCommodityDocket()"
            [displayGoPointsLogo]="true"
          >
          </naoo-material-ordering>
        } @else {
          <ul class="unorderable-list-content">
            <li class="unorderable-content">
              <div class="unorderable-message">
                {{ unorderableText | translate }}
              </div>
            </li>
            <li class="unorderable-content">
              <div class="similar-items" [ngClass]="{ mobile: isMobile }">
                <naoo-material-similar-items
                  [isMobile]="isMobile"
                  [materialWithSubstitutes]="materialRow"
                >
                </naoo-material-similar-items>
              </div>
            </li>
          </ul>
        }
      </div>
    </div>
    @if (
      ProductDetailsUtilities.hasDataForSection(
        materialDetailsViewModel,
        ProductDetailsSection.SellingParagraphs
      )
    ) {
      <div class="selling-statement selling-paragraphs">
        @for (
          paragraph of ProductDetailsUtilities.getDataForSection(
            materialDetailsViewModel,
            ProductDetailsSection.SellingParagraphs
          );
          track trackAsString(paragraph)
        ) {
          <p>
            {{ paragraph }}
          </p>
        }
      </div>
    }
    @if (
      ProductDetailsUtilities.hasDataForSection(
        materialDetailsViewModel,
        ProductDetailsSection.SellingBullets
      )
    ) {
      <div class="selling-statement selling-bullets-section">
        <span class="selling-title section-header">
          {{ 'PRODUCT_DETAILS.PRODUCT_DESCRIPTION' | translate }}
        </span>
        <ul class="selling-bullets">
          @for (
            bullet of ProductDetailsUtilities.getDataForSection(
              materialDetailsViewModel,
              ProductDetailsSection.SellingBullets
            );
            track trackAsString(bullet)
          ) {
            <span>
              <li class="selling-bullet">
                {{ bullet }}
              </li>
            </span>
          }
        </ul>
      </div>
    }
  </div>
</ng-template>
