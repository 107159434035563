import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MarketingGuideViewModel } from '../../core/store/marketing-guide/models/marketing-guide';
import { MaterialRowContext } from '../../core/store/material-row/models/material-row';
import { HeaderHeightService } from '../../shared/services/header-height/header-height.service';
import { NaooLocalStorage } from '../../shared/storage/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { NaooConstants } from '../../shared/NaooConstants';
import { ExportMaterialsInput } from '../../shared/services/export-materials/models/export-materials';
import { MaterialListComponent } from '../../material-list/material-list.component';
import { MarketingGuideFacade } from '../../core/store/marketing-guide/marketing-guide.facade';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import {
  MaterialListRow,
  MaterialListRowType,
} from '../../material-list/models/material-list';
import { GuideCategoryHeader, GuideHeader } from '../../guides/shared/guides';
import { AuxiliaryAnalyticsData } from '../../core/services/ecommerce-analytics/models/google-events';
import { GuidesMobileActionsListComponent } from '../../guides/shared/guides-mobile-actions-list/guides-mobile-actions-list.component';
import {
  MatDrawer,
  MatDrawerContainer,
  MatDrawerContent,
} from '@angular/material/sidenav';
import { AsyncPipe, NgClass } from '@angular/common';
import { GuidesSideBarComponent } from '../../guides/shared/guides-side-bar/guides-side-bar.component';
import { MatIcon } from '@angular/material/icon';
import { GuidesHeaderComponent } from '../../guides/shared/guides-header/guides-header.component';
import { NoResultsComponent } from '../../shared/no-results/no-results.component';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-marketing-guide-content',
  templateUrl: './marketing-guide-content.component.html',
  styleUrls: ['./marketing-guide-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuidesMobileActionsListComponent,
    MatDrawerContainer,
    MatDrawer,
    NgClass,
    GuidesSideBarComponent,
    MatDrawerContent,
    MatIcon,
    GuidesHeaderComponent,
    MaterialListComponent,
    NoResultsComponent,
    AsyncPipe,
    NaooStringDefaulterPipe,
    TranslateModule,
  ],
})
export class MarketingGuideContentComponent implements OnInit {
  readonly listContext = MaterialRowContext.MarketingGuide;

  @Input() isMobile: boolean;

  @Input() set viewModel(viewModel: MarketingGuideViewModel) {
    this._viewModel = viewModel;
    if (viewModel) {
      this.printInput$ = this.marketingGuideFacade.getExportMaterialsInput(
        viewModel.pathUrl,
        true,
      );

      this.exportInput$ = this.marketingGuideFacade.getExportMaterialsInput(
        viewModel.pathUrl,
        false,
      );

      this.analytics = this.getAnalyticsData(
        viewModel.materialListRows,
        viewModel.header,
      );
      this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
        Array.from(this.analytics.itemSubheadingMap.keys()),
        MaterialRowContext.MarketingGuide,
        {
          analytics: this.analytics,
        },
      );
    }
  }

  get viewModel() {
    return this._viewModel;
  }

  headerHeight$: Observable<number>;
  printInput$: Observable<ExportMaterialsInput>;
  exportInput$: Observable<ExportMaterialsInput>;

  analytics: AuxiliaryAnalyticsData;
  sideNavOpen = true;
  isShowingMobileActions = false;

  @ViewChild('materialList')
  materialList: MaterialListComponent;

  private _viewModel: MarketingGuideViewModel;

  constructor(
    private headerHeightService: HeaderHeightService,
    private marketingGuideFacade: MarketingGuideFacade,
    private localStorage: NaooLocalStorage,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnInit() {
    this.headerHeight$ = this.headerHeightService.observeHeaderHeight();
    this.sideNavOpen = this.getSavedMenuState();
  }

  get shouldRenderMobileActionsList(): boolean {
    return this.isShowingMobileActions && this.isMobile;
  }

  get shouldRenderList(): boolean {
    return !!this.viewModel.materialListRows?.length;
  }

  get expansionAriaText(): string {
    return this.sideNavOpen
      ? 'LISTS.FILTERS.COLLAPSE_MENU'
      : 'LISTS.FILTERS.EXPAND_MENU';
  }

  handleUpdateSearchText(searchText: string) {
    this.marketingGuideFacade.updateMarketingGuideSearchText(
      this.viewModel.pathUrl,
      searchText,
    );
  }

  handleCategoryScroll(vsIndex: number): void {
    this.materialList.scrollToIndex(vsIndex);
  }

  toggleDrawer(): void {
    this.sideNavOpen = !this.sideNavOpen;
    this.localStorage.setItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
      String(this.sideNavOpen),
    );
  }

  toggleMobileActions(): void {
    this.isShowingMobileActions = !this.isShowingMobileActions;
  }

  private getSavedMenuState(): boolean {
    const savedState = this.localStorage.getItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
    );
    return savedState == null ? true : savedState === 'true';
  }

  private getAnalyticsData(
    materialListRows: MaterialListRow[],
    header: GuideHeader,
  ): AuxiliaryAnalyticsData {
    let category: GuideCategoryHeader;
    const itemSubheadingMap = new Map<string, string>();
    for (const material of materialListRows) {
      if (MaterialListRowType.CategoryHeader === material.type) {
        category = material.value as GuideCategoryHeader;
        continue;
      }
      if (MaterialListRowType.MaterialRow === material.type) {
        const materialNumber = material.value as string;
        itemSubheadingMap.set(materialNumber, category?.name?.en);
      }
    }
    return {
      collectionName: header?.name?.en,
      itemSubheadingMap,
    };
  }
}
