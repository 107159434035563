import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class NotFoundComponent {
  constructor(
    private location: Location,
    private router: Router,
  ) {}

  navigateToCategories() {
    this.router.navigate(['categories']);
  }

  navigateBack() {
    this.location.back();
  }
}
