<button
  #matMenuButton
  mat-icon-button
  [matMenuTriggerFor]="addToGuideMenu.menu()"
  (mousedown)="setMenuPosition()"
  class="mat-icon-button"
  [attr.aria-label]="'LISTS.MENU_OPTIONS' | translate"
>
  <mat-icon svgIcon="triple_dot_menu"></mat-icon>
</button>

<naoo-add-to-guide-menu
  #addToGuideMenu
  [materialNumber]="product.offeringId"
  [isEmptyMaterial]="product.isEmpty"
  [yDirection]="openDirection"
  [menuTrigger]="menuButton"
  [additionalMenuItems]="menuItems"
  [isCriticalItemsGuide]="isCriticalItemsGuide"
  [isCommodityItem]="isCommodityItem"
  [isOrderGuideItem]="isOrderGuideItem"
  (menuActionSelected)="performMenuAction($event)"
>
</naoo-add-to-guide-menu>
