@if (!selectedFulfillmentType) {
  <button
    class="delivery-schedule-widget no-top-margin"
    (click)="openOrderMethodModal()"
  >
    <div class="step-title">
      {{ 'SELECT_ORDER_METHOD.SELECT_ORDER_METHOD_HEADER_TITLE' | translate }}
    </div>
    <div class="widget-border"></div>
    @if (isBaymard) {
      <mat-icon svgIcon="arrow-v2" class="arrow-icon"></mat-icon>
    }
  </button>
} @else {
  <ng-container
    [ngTemplateOutlet]="orderMethodWidget"
    [ngTemplateOutletContext]="{
      icon: fulfillmentIcon,
      svgIcon: fulfillmentSvgIcon,
      title: 'SELECT_ORDER_METHOD.ORDER_METHOD' | translate,
      subtitle: fulfillmentSubtitle | translate,
      step: OrderMethodStep.FULFILLMENT,
      first: true,
    }"
  ></ng-container>
  <ng-container
    [ngTemplateOutlet]="orderMethodWidget"
    [ngTemplateOutletContext]="{
      svgIcon: 'calendar-icon-v3',
      title: dateTitle | translate,
      subtitle: dateSubtitle,
      note: dateNote,
      step: OrderMethodStep.DATE,
    }"
  >
  </ng-container>
}

<ng-template
  #orderMethodWidget
  let-icon="icon"
  let-svgIcon="svgIcon"
  let-title="title"
  let-subtitle="subtitle"
  let-note="note"
  let-step="step"
  let-first="first"
>
  <button
    class="delivery-schedule-widget"
    (click)="openOrderMethodModal(step)"
    [ngClass]="{ 'no-top-margin': first }"
  >
    @if (icon) {
      <mat-icon class="gfs-icon">{{ icon }}</mat-icon>
    }
    @if (svgIcon) {
      <mat-icon [svgIcon]="svgIcon" class="gfs-icon"></mat-icon>
    }
    <div class="step-title">
      {{ title }}
    </div>
    <div class="widget-border"></div>
    <div class="step-subtitle">
      {{ subtitle }}
    </div>
    @if (isBaymard) {
      <mat-icon svgIcon="arrow-v2" class="arrow-icon"></mat-icon>
    }
  </button>
  @if (note) {
    <div class="date-note">
      {{ note }}
    </div>
  }
</ng-template>
