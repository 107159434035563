<div class="image-container">
  <video
    [muted]="true"
    [autoplay]="true"
    [loop]="true"
    playsinline="playsinline"
    class="empty-guide-image"
    [controls]="false"
    disablePictureInPicture="true"
    [src]="'LISTS.EMPTY_GUIDE.VIDEO_PATH' | translate"
  ></video>
</div>
<div class="title-container">
  <h4 class="guide-name">{{ customGuideName | stringdefaulter }}</h4>
  <h2 class="main-title">{{ 'LISTS.EMPTY_GUIDE.TITLE' | translate }}</h2>
</div>
<div class="info-container">
  <div class="info-block">
    <div class="block-marker"></div>
    <div class="content-block">
      <p class="empty-guide-description">
        {{ 'LISTS.EMPTY_GUIDE.FIND_ITEMS.DESCRIPTION' | translate }}
      </p>
      <div class="empty-guide-navigation">
        <span class="nav-search">
          <button
            [disabled]="isOfflineMode"
            [ngClass]="{ disabled: isOfflineMode }"
            (click)="openMobileSearchModal()"
            class="nav-button"
          >
            {{ 'LISTS.EMPTY_GUIDE.FIND_ITEMS.SEARCH' | translate }}
          </button>
          <span class="link-separator"></span>
        </span>
        <a
          [attr.tabindex]="isOfflineMode ? -1 : 0"
          [routerLink]="'/categories'"
          [ngClass]="{ disabled: isOfflineMode }"
          role="button"
          class="nav-button"
        >
          <span>
            {{ 'LISTS.EMPTY_GUIDE.FIND_ITEMS.BROWSE_CATEGORIES' | translate }}
          </span>
        </a>
        <span class="link-separator"></span>
        <a [routerLink]="'/guides'" role="button" class="nav-button">
          <span>
            {{ 'LISTS.EMPTY_GUIDE.FIND_ITEMS.GUIDES' | translate }}
          </span>
        </a>
        <span class="link-separator"></span>
        <a
          [attr.tabindex]="isOfflineMode ? -1 : 0"
          [routerLink]="'/orders'"
          [ngClass]="{ disabled: isOfflineMode }"
          role="button"
          class="nav-button"
        >
          <span>
            {{ 'LISTS.EMPTY_GUIDE.FIND_ITEMS.ORDER_HISTORY' | translate }}
          </span>
        </a>
      </div>
    </div>
  </div>
  <div class="info-block">
    <div class="block-marker"></div>
    <div class="content-block">
      <h3 class="help-title">
        {{ 'LISTS.EMPTY_GUIDE.ADD_ITEMS.TITLE' | translate }}
      </h3>
      <div class="empty-guide-help">
        <span>{{
          'LISTS.EMPTY_GUIDE.ADD_ITEMS.DESCRIPTION_1' | translate
        }}</span>
        <mat-icon class="more-actions" svgIcon="triple_dot_menu"></mat-icon>
        <span>{{
          'LISTS.EMPTY_GUIDE.ADD_ITEMS.DESCRIPTION_2' | translate
        }}</span>
      </div>
    </div>
  </div>
</div>
