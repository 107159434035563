import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { GuideCategoryHeader } from '../../guides';
import { NaooStringDefaulterPipe } from '../../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-guides-category-link',
  templateUrl: './guides-category-link.component.html',
  styleUrls: ['./guides-category-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NaooStringDefaulterPipe],
})
export class GuidesCategoryLinkComponent {
  @Input() categoryHeader: GuideCategoryHeader;

  @Output() scrollToCategoryClick = new EventEmitter<number>();

  handleScrollToCategory() {
    this.scrollToCategoryClick.emit(this.categoryHeader.virtualScrollIndex);
  }
}
