import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NavigationLink } from '../../../shared/models/navigation-link';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MaterialAdditionalInfoFacade } from '../../../core/store/material-additional-info/material-additional-info.facade';
import { InventoryAvailabilityFacade } from '../../../core/store/inventory-availability/inventory-availability.facade';
import { MaterialInfoFacade } from '../../../core/store/material-info/material-info.facade';
import { Location, NgClass, AsyncPipe } from '@angular/common';
import { ComparisonAttributesViewModel } from '../shared/models/comparison-attributes';
import { NaooConstants } from '../../../shared/NaooConstants';
import { CONTENT_SCROLL } from '../../../shared/services/scrollable-content/scrollable-content.service';
import { MaterialAvailabilityFacade } from '../../../core/store/material-availability/material-availability.facade';
import { MaterialPriceFacade } from '../../../core/store/material-price/material-price.facade';
import {
  MaterialListRow,
  MaterialListRowType,
} from '../../../material-list/models/material-list';
import { MaterialCarouselComponent } from '../../../material-list/material-recommendation/material-carousel/material-carousel.component';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../core/store/material-row/models/material-row';
import { NutritionFacade } from '../../../core/store/nutrition/nutrition.facade';
import { BackButtonComponent } from '../../../shared/back-button/back-button.component';
import { ComparisonAttributesComponent } from '../comparison-attributes/comparison-attributes.component';
import { TranslateModule } from '@ngx-translate/core';

enum ArrowType {
  Next = 'Next',
  Previous = 'Previous',
}

@Component({
  selector: 'naoo-material-comparison-container',
  templateUrl: './material-column-container.component.html',
  styleUrls: ['./material-column-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BackButtonComponent,
    NgClass,
    MaterialCarouselComponent,
    ComparisonAttributesComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class MaterialColumnContainerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() materialNumbers: string[];
  @ViewChild('pageHeader', { static: true }) pageHeader: ElementRef;
  @ViewChild('carouselComponent') carouselComponent: MaterialCarouselComponent;
  @ViewChild('popoverCarouselComponent')
  popoverCarouselComponent: MaterialCarouselComponent;
  @ViewChild('mainCarousel') mainCarousel: ElementRef;

  materialListRows: MaterialListRow[] = [];

  numberOfVisibleMaterials: number;
  backNavigationLink: NavigationLink = {
    name: 'NAVIGATION.BACK',
  };
  attributesViewModel$: Observable<ComparisonAttributesViewModel>;
  onScroll: EventListener;
  shouldStickyHeader = false;

  readonly Next = ArrowType.Next;
  readonly Previous = ArrowType.Previous;
  readonly visibleMaterialIds$ = new BehaviorSubject([]);
  readonly slimGridStyle = MaterialListStyle.SlimGrid;
  readonly comparisonsContext = MaterialRowContext.Comparison;

  private destroyed$ = new Subject<boolean>();

  // eslint-disable-next-line max-params
  constructor(
    private additionalInfoFacade: MaterialAdditionalInfoFacade,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private inventoryAvailabilityFacade: InventoryAvailabilityFacade,
    private changeDetector: ChangeDetectorRef,
    private materialInfoFacade: MaterialInfoFacade,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
    private location: Location,
    private nutritionFacade: NutritionFacade,
  ) {}

  ngOnInit(): void {
    this.initializeMaterials();

    this.onScroll = () => this.updateStickyHeader();
    this.parentScrollElement.addEventListener(
      NaooConstants.SCROLL_EVENT_LISTENER_TYPE,
      this.onScroll,
      true,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.materialNumbers &&
      changes.materialNumbers.previousValue !==
        changes.materialNumbers.currentValue
    ) {
      if (changes.materialNumbers.currentValue.length < 2) {
        this.location.back();
      } else {
        this.initializeMaterials();
      }
    }
  }

  ngOnDestroy() {
    this.parentScrollElement.removeEventListener(
      NaooConstants.SCROLL_EVENT_LISTENER_TYPE,
      this.onScroll,
      true,
    );
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  initializeMaterials(): void {
    this.loadViewModelData();

    if (this.materialNumbers) {
      this.materialListRows = this.materialNumbers.map(
        (materialId: string): MaterialListRow => {
          return {
            type: MaterialListRowType.MaterialRow,
            value: materialId,
          };
        },
      );
      this.attributesViewModel$ =
        this.additionalInfoFacade.getComparisonAttributesViewModel(
          this.materialNumbers,
        );
    }
  }

  slideMaterialCard(shouldSwipeLeft: boolean) {
    if (shouldSwipeLeft) {
      this.carouselComponent.arrowNext();
    } else {
      this.carouselComponent.arrowPrevious();
    }
  }

  suppressContextMenu($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  popoverDidChangeVisibleRows(visibleRows: MaterialListRow[]) {
    this.updateVisibleRows(visibleRows);
    this.carouselComponent?.setVisibleMaterialRows(visibleRows);
  }

  visibleRowsDidChange(visibleRows: MaterialListRow[]) {
    this.updateVisibleRows(visibleRows);
    this.popoverCarouselComponent?.setVisibleMaterialRows(visibleRows);
  }

  private updateVisibleRows(visibleRows: MaterialListRow[]) {
    this.numberOfVisibleMaterials = visibleRows.length;
    const visibleMaterialIds = visibleRows.map((row) => row.value);

    this.visibleMaterialIds$.next(visibleMaterialIds);
  }

  get headerWidth(): string {
    return this.mainCarousel?.nativeElement?.offsetWidth + 'px';
  }

  updateStickyHeader() {
    const headerBottom = this.pageHeader
      ? this.pageHeader.nativeElement.getBoundingClientRect().bottom
      : -1;
    if (!this.shouldStickyHeader && headerBottom < 0) {
      this.shouldStickyHeader = true;
      this.changeDetector.markForCheck();
    } else if (this.shouldStickyHeader && headerBottom > 0) {
      this.shouldStickyHeader = false;
      this.changeDetector.markForCheck();
    }
  }

  private loadViewModelData() {
    this.additionalInfoFacade.loadMaterialAdditionalInfos(this.materialNumbers);
    this.materialPriceFacade.loadMaterialPrices(this.materialNumbers);
    this.materialAvailabilityFacade.loadMaterialAvailabilities(
      this.materialNumbers,
    );
    this.inventoryAvailabilityFacade.loadInventoryAvailabilities(
      this.materialNumbers,
    );
    this.materialInfoFacade.loadMaterialInfos(this.materialNumbers);
    this.nutritionFacade.loadNutrition(this.materialNumbers);
  }
}
