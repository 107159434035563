<div class="drop-ship-orders">
  <div class="drop-ship-orders-header">
    <div class="drop-ship-orders-header-title">
      <div class="special-order-items-container">
        <span class="special-order-items">
          {{
            (dropShipOrder.dropShipOrderIndex > 0
              ? 'ORDER_CONFIRMATION.DROP_SHIP_ORDERS.ORDER_TITLE_ENUMERATED'
              : 'ORDER_CONFIRMATION.DROP_SHIP_ORDERS.ORDER_TITLE'
            ) | translate: { count: dropShipOrder.dropShipOrderIndex }
          }}
        </span>
        <span class="items-in-order">({{ dropShipOrder.totalQuantity }})</span>
        @if (dropShipOrder.shipToAddress) {
          <div class="delivery-address-container">
            <span class="delivery-address-title">
              {{
                'ORDER_CONFIRMATION.DROP_SHIP_ORDERS.DELIVERY_ADDRESS'
                  | translate
              }}
            </span>
            <span class="address">
              @if (dropShipOrder.shipToAddress.shipAddress1) {
                <div>
                  {{ dropShipOrder.shipToAddress.shipAddress1 }}
                </div>
              }
              @if (dropShipOrder.shipToAddress.shipAddress2) {
                <div>
                  {{ dropShipOrder.shipToAddress.shipAddress2 }}
                </div>
              }
              <div>
                {{
                  dropShipOrder.shipToAddress.city +
                    ', ' +
                    dropShipOrder.shipToAddress.province +
                    ' ' +
                    dropShipOrder.shipToAddress.zipCode
                }}
              </div>
            </span>
          </div>
        }
      </div>
    </div>
    <div class="header-container">
      <ng-container *ngTemplateOutlet="headerDetails"></ng-container>
      <ng-container *ngTemplateOutlet="totalsContent"></ng-container>
    </div>
  </div>

  <div class="table-headers">
    <div class="left-header">
      {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.ITEM' | translate }}
    </div>
    <div class="right-headers">
      <div class="right-header text-left short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.SIZE' | translate }}
      </div>
      <div class="right-header text-right">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.PRICE' | translate }}
      </div>
      <div class="right-header text-center short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.QUANTITY' | translate }}
      </div>
      <div class="right-header short-header">
        {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
      </div>
    </div>
  </div>

  @for (item of dropShipOrder.items; track item; let isLast = $last) {
    <naoo-order-item-row
      class="order-item-row"
      [orderItem]="item"
      [isLastRow]="isLast"
    >
    </naoo-order-item-row>
  }
</div>

<ng-template #headerDetails>
  <div class="drop-ship-orders-header-details">
    @if (!!dropShipOrder.orderNumber) {
      <div class="header-detail">
        <div class="header-detail-label">
          {{ 'ORDER_CONFIRMATION.HEADER.ORDER_NUMBER' | translate }}&nbsp;
        </div>
        <div class="header-detail-data">{{ dropShipOrder.orderNumber }}</div>
      </div>
    }
    @if (!!dropShipOrder.poNumber) {
      <div class="header-detail">
        <div class="header-detail-label">
          {{ 'ORDER_CONFIRMATION.HEADER.PO_NUMBER' | translate }}:&nbsp;
        </div>
        <div class="header-detail-data order-po-number">
          {{ dropShipOrder.poNumber }}
        </div>
      </div>
    }
    <div class="header-detail">
      <div class="header-detail-label">
        {{ 'ORDER_CONFIRMATION.ESTIMATED_DELIVERY_DATE' | translate }}&nbsp;
      </div>
      <div class="delivery-dates">
        <div class="header-detail-data">
          {{
            dropShipOrder.requestedCustomerArrivalDate
              | naooDate: 'weekdayMonthDay' : ''
          }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #totalsContent>
  <div class="totals">
    <div class="total-column">
      <span class="total-labels">
        <div class="total-label-subtotal total-media">
          {{ 'ORDER_CONFIRMATION.SUB_TOTAL' | translate }}
        </div>
        <div class="total-label-shipping total-media">
          {{ 'ORDER_CONFIRMATION.SHIPPING' | translate }}
        </div>
        <div class="total-label-estimated-total total-media">
          {{ 'ORDER_CONFIRMATION.ESTIMATED_TOTAL' | translate }}
        </div>
      </span>
      <span class="total-values">
        <div class="dropship-subtotal-value total-media">
          {{
            dropShipOrder.subTotalCost
              | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
          }}
        </div>
        <div class="dropship-cost-value total-media">
          {{
            dropShipOrder.shippingCost
              | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
          }}
        </div>
        <div class="dropship-total total-media">
          {{
            dropShipOrder.estimatedTotal
              | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
          }}
        </div>
      </span>
    </div>
  </div>
</ng-template>
