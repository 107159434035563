<div class="cart-review-missed-cutoff-header">
  <div class="sub-header-keys">
    <span
      class="single-sub-header-key"
      [innerHTML]="section.subHeaderKeys?.[0]?.key | translate"
    ></span>
  </div>

  <div class="missed-cutoff-content">
    <mat-checkbox
      class="missed-cutoff-checkbox"
      (change)="toggle($event)"
      [disabled]="!nextValidDeliverySchedules.length || isOffline"
      [ngClass]="{ disabled: isOffline }"
      >{{ 'CART_REVIEW.CUTOFF_SPLIT.CHECKBOX_LABEL' | translate }}</mat-checkbox
    >
    <div class="delivery-schedule-content">
      <button
        class="delivery-schedule-button"
        [disabled]="isFormDisabled"
        (click)="openCalendar()"
      >
        <mat-icon
          class="calendar-icon"
          [ngClass]="{ disabled: isFormDisabled }"
          svgIcon="calendar-icon-v3"
        ></mat-icon>
        <div class="text" [ngClass]="{ disabled: isFormDisabled }">
          <div class="title-label">
            {{ 'CART_REVIEW.CUTOFF_SPLIT.DELIVERY_LABEL' | translate }}
          </div>
          <div class="delivery-schedule-text">
            {{ dateInput.value | naooDate: 'dayMonthDate' : '' }}
          </div>
        </div>
      </button>
      <input
        [disabled]="isFormDisabled"
        #dateInput
        (dateChange)="updateDeliveryDate()"
        class="hidden-input"
        [matDatepickerFilter]="nextValidDeliveryDatesFilter"
        [matDatepicker]="picker"
      />
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="po-number-content">
      <naoo-po-input
        [isPoRequired]="section.isPoNumberRequired"
        [poNumber]="poNumber"
        [isOffline]="isFormDisabled"
        (updatedPoNumber)="updatePoNumber($event)"
      ></naoo-po-input>
    </div>
  </div>
</div>
