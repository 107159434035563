import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-critical-items-guide-empty',
  templateUrl: './critical-items-guide-empty.component.html',
  styleUrls: ['./critical-items-guide-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, RouterLink, TranslateModule],
})
export class CriticalItemsGuideEmptyComponent {
  @Input() isOffline: boolean;

  constructor(private customDialogService: CustomDialogService) {}

  openMobileSearchModal() {
    this.customDialogService.openMobileSearchModal();
  }
}
