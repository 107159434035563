import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GroupByType } from '../../guides';
import {
  MatRadioChange,
  MatRadioGroup,
  MatRadioButton,
} from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-group-by',
  templateUrl: './guides-group-by.component.html',
  styleUrls: ['./guides-group-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatRadioGroup, MatRadioButton, TranslateModule],
})
export class GuidesGroupByComponent {
  @Input() isOffline: boolean;
  @Input() selectedGroupBy: GroupByType;
  @Input() groupByOptions: GroupByType[] = [];

  @Output() groupByChange = new EventEmitter<GroupByType>();

  shouldDisableButton(groupByType: GroupByType): boolean {
    switch (groupByType) {
      case GroupByType.Custom:
        return false;
      case GroupByType.Storage:
      case GroupByType.Taxonomy:
      default:
        return this.isOffline;
    }
  }

  displayKeyForGroupBy(groupByType: GroupByType): string {
    switch (groupByType) {
      case GroupByType.Custom:
        return 'LISTS.GROUP_ITEMS_BY.CUSTOM_OPTION';
      case GroupByType.Storage:
        return 'LISTS.GROUP_ITEMS_BY.STORAGE_OPTION';
      case GroupByType.Taxonomy:
        return 'LISTS.GROUP_ITEMS_BY.TAXONOMY_OPTION';
    }
  }

  changeGroup($event: MatRadioChange) {
    this.groupByChange.emit($event.value);
  }
}
