import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { MaterialUnitsFacade } from '../../core/store/material-units/material-units.facade';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { LocalizationService } from '../services/translation/localization.service';

@Pipe({
  standalone: true,
  name: 'materialunits',
  pure: false,
})
export class MaterialUnitsPipe implements PipeTransform, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private materialUnitsFacade: MaterialUnitsFacade,
    private localizationService: LocalizationService,
    private _ref?: ChangeDetectorRef,
  ) {
    combineLatest([
      this.materialUnitsFacade.getLoadedMaterialUnitsRecords(),
      this.localizationService.locale(),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this._ref?.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  transform(
    value: { uomCode: string; quantity?: number },
    shouldDisplayQuantity: boolean = true,
  ): string {
    return this.materialUnitsFacade.getMaterialUnitFromUomCode(
      value,
      shouldDisplayQuantity,
    );
  }
}
