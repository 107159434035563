<div class="header-details">
  <div class="header-row">
    <div class="item">
      <div class="item-title">
        {{ 'ORDER_CONFIRMATION.HEADER.CUSTOMER' | translate }}
      </div>
      <div class="item-detail">
        {{ header.customerUnitName }}
      </div>
      <div class="item-detail">#{{ header.customerNumber }}</div>
    </div>

    <div class="item">
      <div class="item-title">
        {{ header.shipToLabel | translate }}
      </div>
      @if (header.shipToAddress.shipAddress1) {
        <div class="item-detail">
          {{ header.shipToAddress.shipAddress1 }}
        </div>
      }
      @if (header.shipToAddress.shipAddress2) {
        <div class="item-detail">
          {{ header.shipToAddress.shipAddress2 }}
        </div>
      }
      <div class="item-detail">
        {{
          header.shipToAddress.city +
            ', ' +
            header.shipToAddress.province +
            ' ' +
            header.shipToAddress.zipCode
        }}
      </div>
    </div>
  </div>

  <div class="header-row second-row">
    <div class="item">
      <div class="item-title">
        {{ 'ORDER_CONFIRMATION.HEADER.SUBMITTED_ON' | translate }}
      </div>
      <div class="item-detail">
        {{ header.submittedOn | naooDate: 'longDateAbbr' : timeZoneCode }}
      </div>
      <div class="item-detail">
        {{ header.submittedOn | naooDate: 'time' : timeZoneCode }}
        {{ timeZone }}
      </div>
      @if (!!header.userName) {
        <div class="item-detail">
          {{ 'ORDER_CONFIRMATION.HEADER.BY' | translate }} {{ header.userName }}
        </div>
      }
    </div>

    <div class="item">
      <div class="item-title">
        {{ 'ORDER_CONFIRMATION.HEADER.ORDER_QUANTITY' | translate }}
      </div>
      <div class="item-detail">
        <span>{{ header.headerTotals.lineCount }} </span>
        <span>{{ 'ORDER_CONFIRMATION.HEADER.LINES' | translate }}</span>
        <span>{{ ', ' + header.headerTotals.itemCount }} </span>
        <span>{{ 'ORDER_CONFIRMATION.HEADER.TOTALS_ITEMS' | translate }}</span>
      </div>
    </div>
  </div>
</div>

<div class="item price-and-discount-amount">
  <div class="item-title item-cost-title">
    {{ 'ORDER_CONFIRMATION.HEADER.EST_TOTAL' | translate }}
  </div>
  <div class="item-cost-detail">
    {{
      header.headerTotals.estimatedCost
        | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
    }}
  </div>

  @if (!!header.headerTotals.totalDiscountAmount) {
    <div class="discount-area">
      <div class="item-title item-cost-title discount-amount">
        {{ 'ORDER_CONFIRMATION.HEADER.TOTAL_SAVED' | translate }}
      </div>
      <div class="item-cost-detail discount-amount">
        {{
          header.headerTotals.totalDiscountAmount
            | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
        }}
      </div>
    </div>
  }
</div>
