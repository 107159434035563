@if (isShowingMobileActions && isMobile) {
  <naoo-guides-mobile-actions-list
    [sideBar]="criticalItemsGuide.sideBar"
    [preferredView]="criticalItemsGuide.preferredView"
    [isOffline]="criticalItemsGuide.isOffline"
    (closeMobileActions)="toggleMobileActions()"
    (sortByChange)="handleSortByChanged($event)"
  ></naoo-guides-mobile-actions-list>
} @else if (!!criticalItemsGuide) {
  <div
    class="critical-items-guide-wrapper"
    [ngClass]="{ 'padding-left': !shouldRenderGuideContent }"
  >
    @if (shouldRenderGuideContent) {
      <mat-drawer-container>
        <mat-drawer
          [opened]="sideNavOpen"
          (openedChange)="drawerStateChangeCompleted()"
          mode="side"
          [style.marginTop.px]="headerHeight$ | async"
          [ngClass]="{ closed: !sideNavOpen }"
        >
          <naoo-guides-side-bar
            [sideBar]="criticalItemsGuide.sideBar"
            [isOffline]="criticalItemsGuide.isOffline"
            (scrollToCategoryClick)="handleScrollToCategory($event)"
            (sortByChange)="handleSortByChanged($event)"
          ></naoo-guides-side-bar>
        </mat-drawer>
        <mat-drawer-content>
          @if (!isMobile) {
            <button
              class="drawer-button"
              [ngClass]="{
                'drawer-closed': drawerClosedAndFinishedAnimating,
              }"
              (click)="toggleDrawer()"
              attr.aria-label="{{ expansionAriaText | translate }}"
            >
              <mat-icon
                svgIcon="arrow-v2"
                class="arrow-icon"
                [ngClass]="{ 'closed-rotation': !sideNavOpen }"
              ></mat-icon>
            </button>
          }
          <div class="critical-items-guide-content">
            <naoo-guides-header
              class="guides-header"
              [guideHeader]="criticalItemsGuide.header"
              [preferredView]="criticalItemsGuide.preferredView"
              [categoryHeaders]="criticalItemsGuide.sideBar.categoryHeaders"
              [isOffline]="criticalItemsGuide.isOffline"
              [printInput$]="printInput$"
              [exportInput$]="exportInput$"
              (openMobileActionsModal)="toggleMobileActions()"
              (scrollToCategory)="handleScrollToCategory($event)"
              (updateSearchText)="handleUpdateSearchText($event)"
              (updateMaterialView)="handleUpdateMaterialView($event)"
            >
              <div class="guide-header">
                <span class="guide-title">
                  <mat-icon
                    class="guide-icon"
                    svgIcon="critical_item_star"
                  ></mat-icon>
                  {{ criticalItemsGuide.header.name | stringdefaulter }}
                </span>
                <span class="guide-material-count">
                  ({{ criticalItemsGuide.header.count }}
                  {{ 'ORDER_GUIDE.PRODUCTS' | translate }})
                </span>
              </div>
              <div class="critical-items-description">
                <div class="how-it-works">
                  {{
                    'LISTS.CRITICAL_ITEMS_GUIDE_INFO.HOW_IT_WORKS' | translate
                  }}
                </div>
                {{
                  'LISTS.CRITICAL_ITEMS_GUIDE_INFO.READY_TO_SUBMIT' | translate
                }}
              </div>
            </naoo-guides-header>
            @if (shouldRenderList) {
              <div class="list-content">
                <naoo-material-list
                  #materialList
                  [materialListItems]="criticalItemsGuide.materialListRows"
                  [context]="listContext"
                  [listStyle]="criticalItemsGuide.preferredView"
                ></naoo-material-list>
              </div>
            } @else {
              <naoo-no-results
                [searchText]="criticalItemsGuide.header.actionBar.searchText"
              ></naoo-no-results>
            }
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    } @else {
      <div class="empty-guide-wrapper">
        <naoo-guides-header-breadcrumb
          [name]="criticalItemsGuide.header.name"
        ></naoo-guides-header-breadcrumb>
        <naoo-critical-items-guide-empty
          class="empty-guide"
          [isOffline]="criticalItemsGuide.isOffline"
        >
        </naoo-critical-items-guide-empty>
      </div>
    }
  </div>
}
