import { map, takeUntil, tap } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { NaooConstants } from '../../shared/NaooConstants';
import { ProductDirection } from '../../product-row/product-guide-edit/product-direction';
import { ToastMessageService } from '../../shared/services/toast-message/toast-message.service';
import {
  ProductAction,
  ProductActionInfo,
} from '../../product-row/product-guide-edit/product-action';
import { RenameCategoryAction } from '../category-edit-menu/rename-category-modal/rename-category-action';
import { MatDialog } from '@angular/material/dialog';
import { NavigationLink } from '../../shared/models/navigation-link';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import {
  CategorizedCustomGuide,
  CategorizedMaterials,
  sortByCustom,
} from '../../shared/models/categorized-materials';
import { MaterialInfo } from '../../shared/models/material-info';
import { getIndexForDirection } from './shared/edit-order-guide.util';
import { CustomGuideMaterialParOrder } from '../../core/store/custom-guide/custom-guide.selectors';
import { GroupByType, SortByType } from 'src/app/guides/shared/guides';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import {
  CurrentSystem,
  Language,
} from '../../core/services/session/models/session-record';
import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { CreateOrderGuideCategoryModalComponent } from '../create-order-guide-category-modal/create-order-guide-category-modal.component';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { SessionFacade } from '../../core/store/session/session.facade';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { BackButtonComponent } from '../../shared/back-button/back-button.component';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgClass } from '@angular/common';
import { EditOrderGuideGfsIconComponent } from './shared/edit-order-guide-gfs-icon/edit-order-guide-gfs-icon.component';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { EditOrderGuideCategoryComponent } from './edit-order-guide-category/edit-order-guide-category.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-edit-order-guide',
  templateUrl: './edit-order-guide.component.html',
  styleUrls: ['./edit-order-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BackButtonComponent,
    BreadcrumbComponent,
    MatProgressBar,
    DragDropModule,
    NgClass,
    EditOrderGuideGfsIconComponent,
    MatIconButton,
    MatIcon,
    MatFormField,
    MatSelect,
    MatOption,
    EditOrderGuideCategoryComponent,
    TranslateModule,
  ],
})
export class EditOrderGuideComponent implements OnInit, OnDestroy {
  readonly ORDER_GUIDE_NAME = 'MENU.ORDER_GUIDE';
  readonly ECOMM = 'EComm';
  readonly SALES = 'Sales';

  sortBy = SortByType.Custom;
  groupBy = GroupByType.Custom;
  materialParOrders: Map<string, CustomGuideMaterialParOrder> = new Map();
  categorizedMaterials: CategorizedMaterials[] = [];
  materials: MaterialInfo[] = [];

  breadcrumbs: NavigationLink[];
  backNavigationLink: NavigationLink;

  currentLanguage: Language;
  destroyed$ = new Subject();

  isMobile = false;
  groupIsChanging = false;
  guideHasUnassignedCategory: boolean;
  private currentSystem: CurrentSystem;

  constructor(
    public dialog: MatDialog,
    private orderGuideFacade: OrderGuideFacade,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private deviceIdentifierService: DeviceIdentifierService,
    public changeDetectorRef: ChangeDetectorRef,
    private sessionFacade: SessionFacade,
    private customDialogService: CustomDialogService,
  ) {}

  get isLoading(): boolean {
    return this.groupIsChanging;
  }

  get totalMaterials() {
    return this.categorizedMaterials.reduce(
      (totalMaterials, categorizedMaterialRecord) => {
        return totalMaterials + categorizedMaterialRecord.items.length;
      },
      0,
    );
  }

  get isCustom(): boolean {
    return this.groupBy === 'custom';
  }

  ngOnInit() {
    this.changeDetectorRef.markForCheck();
    this.setupSubscriptions();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  doneEditing() {
    if (
      this.currentSystem.includes(CurrentSystem.Sap) &&
      this.categorizedMaterials.find(
        (category) => category.items.length == 0,
      ) != null
    ) {
      this.customDialogService.openEmptyCategoryModal();
    } else {
      this.orderGuideFacade.saveOrderGuideChanges();
    }
  }

  moveCategory(event: { categoryName: string; direction: ProductDirection }) {
    this.moveCustomGuideCategoryWithDirection(
      this.categorizedMaterials,
      event.categoryName,
      event.direction,
    );
  }

  actOnMaterialInACategory(event: ProductActionInfo) {
    if (event.action === ProductAction.remove) {
      this.orderGuideFacade.removeItemFromOrderGuideInLocal(
        event.materialNumber,
      );
    } else if (event.action === ProductAction.move) {
      const customCategorizedMaterialRecord = this.categorizedMaterials;
      const newCategoryIndex = this.getCategoryIndexFromCategorizedMaterials(
        event.categoryName,
        customCategorizedMaterialRecord,
      );
      const newMaterialIndex = -1;
      this.orderGuideFacade.moveOrderGuideMaterials(
        event.materialNumber,
        newCategoryIndex,
        newMaterialIndex,
      );
      const isUnassignedCategory =
        NaooConstants.unassignedCategory === event.categoryName;
      if (!isUnassignedCategory) {
        this.toastMessageService.showMovedToCategoryMessage(
          event.categoryName,
          true,
        );
      }
    }
  }

  actOnDeleteCategory(event: CategorizedMaterials) {
    const categoryIndex = this.getCategoryIndexFromCategorizedMaterials(
      event.categoryName.en,
      this.categorizedMaterials,
    );
    this.orderGuideFacade.removeOrderGuideCategory(categoryIndex);
    this.toastMessageService.showDeletedCategoryMessage(
      event.categoryName.en,
      NaooConstants.ORDER_GUIDE_EN,
    );
  }

  actOnRenameCategory(event: RenameCategoryAction) {
    const categoryIndex = this.getCategoryIndexFromCategorizedMaterials(
      event.oldCategoryName,
      this.categorizedMaterials,
    );
    this.orderGuideFacade.renameOrderGuideCategory(
      categoryIndex,
      event.newCategoryName,
    );
  }

  openCreateCategoryModal() {
    this.dialog
      .open(CreateOrderGuideCategoryModalComponent, {
        id: NaooConstants.CREATE_CATEGORY_MODAL_ID,
        panelClass: 'order-guide-modal',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((categoryName) => {
        if (categoryName === undefined) {
          return;
        }
        this.orderGuideFacade.createOrderGuideCategory(categoryName);
        this.toastMessageService.showToastMessage(
          this.localizationService.instant('LISTS.TOAST_CREATE_CATEGORY', {
            categoryName,
          }),
        );
      });
  }

  isGfsCategory(categorizedMaterial: CategorizedMaterials): boolean {
    return (
      categorizedMaterial.categoryType !== null &&
      (categorizedMaterial.categoryType === this.ECOMM ||
        categorizedMaterial.categoryType === this.SALES)
    );
  }

  drop(event: CdkDragDrop<string, any>): void {
    this.orderGuideFacade.moveOrderGuideMaterials(
      event.item.data,
      +event.container.data,
      -1,
    );
  }

  moveUp(categorizedMaterial: CategorizedMaterials) {
    this.moveCategory({
      categoryName: categorizedMaterial.categoryName.en,
      direction: ProductDirection.up,
    });
  }

  moveDown(categorizedMaterial: CategorizedMaterials) {
    this.moveCategory({
      categoryName: categorizedMaterial.categoryName.en,
      direction: ProductDirection.down,
    });
  }

  isUpArrowActive(isFirst: boolean): boolean {
    return !isFirst;
  }

  isDownArrowActive(isLast: boolean): boolean {
    return !isLast;
  }

  private setupSubscriptions() {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      });

    this.setBreadcrumbs();
    this.changeDetectorRef.markForCheck();

    this.localizationService
      .language()
      .pipe(
        takeUntil(this.destroyed$),
        map(() => {
          this.setBreadcrumbs();
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();

    this.orderGuideFacade
      .getLoadedCategorizedOrderGuide()
      .pipe(
        takeUntil(this.destroyed$),
        map(
          (categorizedOrderGuide) =>
            <CategorizedCustomGuide>{
              ...categorizedOrderGuide,
              categorizedMaterials: categorizedOrderGuide.categorizedMaterials,
            },
        ),
        tap((categorizedOrderGuide) => {
          this.categorizedMaterials =
            categorizedOrderGuide.categorizedMaterials.map((material) =>
              sortByCustom(material),
            );
          this.setUnassignedCategoryExist();
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();

    this.sessionFacade
      .getLoadedCurrentSystem()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentSystem) => {
        this.currentSystem = currentSystem;
      });
  }

  private getCategoryIndexFromCategorizedMaterials(
    categoryName: string,
    categorizedMaterials: CategorizedMaterials[],
  ): number {
    return categorizedMaterials.findIndex(
      (categorizedMaterial) =>
        categorizedMaterial.categoryName.en === categoryName,
    );
  }

  private setUnassignedCategoryExist() {
    this.guideHasUnassignedCategory = !!this.categorizedMaterials.find(
      (category) =>
        category.categoryName.en === NaooConstants.unassignedCategory,
    );
  }

  private setBreadcrumbs() {
    this.backNavigationLink = {
      name: `${this.localizationService.instant(
        'LISTS.BACK_TO',
      )} ${this.localizationService.instant(this.ORDER_GUIDE_NAME)}`,
      link: `${NaooConstants.ORDER_GUIDE_PATH}`,
    };
    const root: NavigationLink = {
      name: this.localizationService.instant('MENU.GUIDES'),
      link: NaooConstants.GUIDES_PATH,
    };
    const current: NavigationLink = {
      name: this.localizationService.instant(this.ORDER_GUIDE_NAME),
      link: `${NaooConstants.ORDER_GUIDE_PATH}`,
    };
    const editCurrent: NavigationLink = {
      name: `${this.localizationService.instant(
        'LISTS.EDIT',
      )} ${this.localizationService.instant(this.ORDER_GUIDE_NAME)}`,
      link: null,
    };
    this.breadcrumbs = [root, current, editCurrent];
  }

  private moveCustomGuideCategoryWithDirection(
    categorizedMaterialRecords: CategorizedMaterials[],
    categoryName: string,
    direction: ProductDirection,
  ): void {
    const originalCategoryIndex = this.getCategoryIndexFromCategorizedMaterials(
      categoryName,
      categorizedMaterialRecords,
    );
    const newCategoryIndex = getIndexForDirection(
      originalCategoryIndex,
      categorizedMaterialRecords.length,
      direction,
    );
    if (newCategoryIndex !== -1) {
      this.orderGuideFacade.moveOrderGuideCategory(
        originalCategoryIndex,
        newCategoryIndex,
      );
    }
  }
}
