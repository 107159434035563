<div class="filter-chips-container">
  <div class="filter-chips">
    @for (
      activeFilter of activeDefaultFilters;
      track activeFilter.filter.name.en
    ) {
      <naoo-filter-chip [defaultFilter]="activeFilter"> </naoo-filter-chip>
    }
  </div>
  @if (activeDefaultFilters.length > 1) {
    <button (click)="clearAllFilters()" class="clear-all-button">
      <mat-icon svgIcon="clear_filter_icon"></mat-icon>
      {{ 'CLEAR.ALL' | translate }}
    </button>
  }
</div>

<div class="divider"></div>
