import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NutritionInfo,
  NutritionLabel,
} from '../../../../core/store/nutrition/models/nutrition';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { NutritionLabelComponent } from '../../nutrition-content/nutrition-label/nutrition-label.component';
import { TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-nutrition-facts-content',
  templateUrl: './nutrition-facts-content.component.html',
  styleUrls: ['./nutrition-facts-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    MatTooltip,
    MatSlideToggle,
    NutritionLabelComponent,
    TitleCasePipe,
    TranslateModule,
  ],
})
export class NutritionFactsContentComponent {
  @Input()
  set nutritionLabel(nutritionLabel: NutritionLabel) {
    this.nutritionInfos = nutritionLabel?.nutritionInfos;
    this.selectedInfo = this.nutritionInfos
      ? this.nutritionInfos[0]
      : undefined;
  }

  nutritionInfos: NutritionInfo[];
  selectedInfo: NutritionInfo;
  shouldRound = true;
}
