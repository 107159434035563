<mat-menu
  #menu="matMenu"
  xPosition="before"
  [yPosition]="yDirection"
  (closed)="onMenuClosed()"
>
  <ng-template matMenuContent>
    @if (!isEmptyMaterial) {
      <div #popoverMenu>
        @if (isCommodityItem) {
          <button
            class="primary-action"
            role="menuitem"
            (click)="viewCommodityDetails()"
            [disabled]="isOffline"
            mat-menu-item
          >
            {{ 'LISTS.VIEW_COMMODITY_DETAILS' | translate }}
          </button>
        }
        @if (!isCriticalItemsGuide && !hideAddToCriticalItems) {
          <button
            class="primary-action"
            role="menuitem"
            (click)="addToCriticalItemsGuideMenuAction()"
            [disabled]="isOffline"
            mat-menu-item
          >
            {{ 'LISTS.ADD_TO_CRITICAL_ITEMS_GUIDE' | translate }}
          </button>
        }
        @if (isCriticalItemsGuide) {
          <button
            class="primary-action"
            role="menuitem"
            [disabled]="isOffline"
            (click)="removeFromCriticalItemsGuideMenuAction()"
            mat-menu-item
          >
            {{ 'LISTS.REMOVE_PRODUCT_FROM_CRITICAL_ITEMS_GUIDE' | translate }}
          </button>
        }
        <button
          class="primary-action"
          role="menuitem"
          [ngClass]="{ 'expanded-section': isPanelExpanded }"
          (click)="expandAddToCustomGuide($event)"
          [disabled]="isOffline"
          mat-menu-item
        >
          <div class="action-container">
            <div class="action-label">
              {{ 'LISTS.ADD_TO_CUSTOM_GUIDE' | translate }}
            </div>
            <mat-icon
              [@rotate]="rotationState"
              (@rotate.done)="animationComplete($event)"
              class="action-arrow"
              [ngClass]="{ 'arrow-disabled': isOffline }"
              svgIcon="arrow-v2"
            ></mat-icon>
          </div>
        </button>
        <div
          [attr.aria-hidden]="!isPanelExpanded"
          [style.height.px]="expandedMenuHeight"
          class="d1"
        >
          <div class="d2">
            <div class="d3">
              @if (shouldShowSubMenu) {
                <div class="d4">
                  <button
                    class="submenu-item create-submenu-item"
                    role="menuitem"
                    (click)="performMenuAction(createNewGuideMenuItem)"
                    mat-menu-item
                  >
                    <div class="button-icon">
                      <mat-icon svgIcon="plus_icon"></mat-icon>
                    </div>
                    {{ createNewGuideMenuItem.name | translate }}
                  </button>
                  @for (menuItem of submenuItems; track menuItem.name) {
                    <button
                      class="mat-menu-item submenu-item custom-guide"
                      role="menuitem"
                      (click)="performMenuAction(menuItem)"
                      mat-menu-item
                    >
                      {{
                        menuItem.name === 'CRITICAL_ITEMS.IMPORTED'
                          ? ('LISTS.CRITICAL_ITEMS.IMPORTED' | translate)
                          : menuItem.name
                      }}
                    </button>
                  }
                </div>
              }
            </div>
          </div>
        </div>
        @for (menuItem of additionalMenuItems; track menuItem.name) {
          <button
            class="primary-action additional-menu"
            role="menuitem"
            [disabled]="menuItem.disabled"
            (click)="performMenuAction(menuItem)"
            mat-menu-item
          >
            {{ menuItem.name | translate }}
          </button>
        }
        @if (isOrderGuideEditable$ | async) {
          <button
            class="primary-action"
            role="menuitem"
            (click)="updateOrderGuideAction()"
            [disabled]="isOffline"
            mat-menu-item
          >
            {{
              (isOrderGuideItem
                ? 'LISTS.REMOVE_FROM_ORDER_GUIDE'
                : 'LISTS.ADD_TO_ORDER_GUIDE'
              ) | translate
            }}
          </button>
        }
        <button
          class="primary-action"
          role="menuitem"
          (click)="openSimilarItemsModal()"
          [disabled]="isOffline"
          mat-menu-item
        >
          {{ 'PRODUCT.SIMILAR_ITEMS_BUTTON' | translate }}
        </button>
      </div>
    }
  </ng-template>
</mat-menu>
<button class="hidden-menu-trigger" [matMenuTriggerFor]="menu"></button>
