import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { MarketingGuideFacade } from '../../core/store/marketing-guide/marketing-guide.facade';
import { Observable } from 'rxjs';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { MarketingGuideContentComponent } from '../marketing-guide-content/marketing-guide-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-marketing-guide-container',
  template: ` <naoo-marketing-guide-content
    [viewModel]="marketingGuideViewModel$ | async"
    [isMobile]="isMobile$ | async"
  ></naoo-marketing-guide-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MarketingGuideContentComponent, AsyncPipe],
})
export class MarketingGuideContainerComponent {
  marketingGuideViewModel$ = this.activatedRoute.paramMap.pipe(
    map((paramMap) => paramMap.get('pathUrl')),
    switchMap((pathUrl) =>
      this.marketingGuideFacade.getMarketingGuideViewModel(pathUrl),
    ),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  isMobile$: Observable<boolean> =
    this.deviceIdentifierService.observeDeviceType();

  constructor(
    private activatedRoute: ActivatedRoute,
    private deviceIdentifierService: DeviceIdentifierService,
    private marketingGuideFacade: MarketingGuideFacade,
  ) {}
}
