import {
  Route,
  UrlMatchResult,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';

export function coordinateMatcher(
  segments: UrlSegment[],
  _group: UrlSegmentGroup,
  _route: Route,
): UrlMatchResult {
  if (segments.length === 1) {
    return {
      consumed: segments,
      posParams: {
        categoryCoordinate: segments[0],
      },
    };
  }
  return null;
}

export function resultsCoordinateMatcher(
  segments: UrlSegment[],
  _group: UrlSegmentGroup,
  _route: Route,
): UrlMatchResult {
  if (segments.length === 2 && segments[0].toString() === 'results') {
    return {
      consumed: segments,
      posParams: {
        categoryCoordinate: segments[1],
      },
    };
  }
  return null;
}
