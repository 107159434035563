import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { NaooConstants } from '../../shared/NaooConstants';
import { SessionFacade } from '../../core/store/session/session.facade';
import { HeroImagesComponent } from '../../shared/hero-images/hero-images.component';
import { GoPointsLogoComponent } from '../../shared/go-points-logo/go-points-logo.component';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { SpecialOrderComponent } from './special-order/special-order.component';
import { StorePurchaseHistoryContainerComponent } from './store-purchase/history/store-purchase-history-container.component';
import { InvoicesCreditsComponent } from './invoices-credits/invoices-credits.component';
import { GoPointsContainerComponent } from './go-points/go-points-container.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export enum OrdersTab {
  OrderHistory = 'order-history',
  StorePurchaseHistory = 'purchase-history',
  SpecialOrders = 'special-orders',
  InvoicesCredits = 'invoices-credits',
  GoPoints = 'go-points',
}

@Component({
  selector: 'naoo-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  standalone: true,
  imports: [
    HeroImagesComponent,
    GoPointsLogoComponent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    OrderHistoryComponent,
    SpecialOrderComponent,
    StorePurchaseHistoryContainerComponent,
    InvoicesCreditsComponent,
    GoPointsContainerComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class OrdersComponent implements OnInit, OnDestroy {
  private static readonly TABS = [
    OrdersTab.OrderHistory,
    OrdersTab.SpecialOrders,
    OrdersTab.StorePurchaseHistory,
    OrdersTab.InvoicesCredits,
    OrdersTab.GoPoints,
  ];

  hasStorePurchaseHistoryAccess$ = this.sessionFacade
    .hasStorePurchaseHistoryPermissions()
    .pipe(shareReplay(1));

  isLoyaltyProgramEligible$ = this.sessionFacade
    .isLoyaltyProgramEligible()
    .pipe(shareReplay(1));

  isSpecialOrderDashboardAccess$ = this.sessionFacade
    .isSpecialOrderDashboardAccess()
    .pipe(shareReplay(1));

  activeTabIndex = 0;
  tabs: OrdersTab[] = [];

  private ACTIVE_TAB_PARAMETER = 'activeTab';
  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionFacade: SessionFacade,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.hasStorePurchaseHistoryAccess$,
      this.isLoyaltyProgramEligible$,
      this.isSpecialOrderDashboardAccess$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([
          hasStorePurchaseHistoryAccess,
          isLoyaltyProgramEligible,
          isSpecialOrderDashboardAccess,
        ]) => {
          const tabsToHide: OrdersTab[] = [];
          if (!isSpecialOrderDashboardAccess) {
            tabsToHide.push(OrdersTab.SpecialOrders);
          }
          if (!isLoyaltyProgramEligible) {
            tabsToHide.push(OrdersTab.GoPoints);
          }
          if (hasStorePurchaseHistoryAccess) {
            tabsToHide.push(OrdersTab.InvoicesCredits);
          } else {
            tabsToHide.push(OrdersTab.StorePurchaseHistory);
          }
          this.tabs = OrdersComponent.TABS.filter(
            (tab) => !tabsToHide.includes(tab),
          );
        },
      );

    combineLatest([
      this.hasStorePurchaseHistoryAccess$,
      this.isSpecialOrderDashboardAccess$,
      this.isLoyaltyProgramEligible$,
      this.activatedRoute.queryParams,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ([
          hasStorePurchaseHistoryAccess,
          isSpecialOrderDashboardAccess,
          isLoyaltyProgramEligible,
          params,
        ]) => {
          const activeTabParam = params[this.ACTIVE_TAB_PARAMETER];
          if (
            (!isSpecialOrderDashboardAccess &&
              activeTabParam === OrdersTab.SpecialOrders) ||
            (!hasStorePurchaseHistoryAccess &&
              activeTabParam === OrdersTab.StorePurchaseHistory) ||
            (hasStorePurchaseHistoryAccess &&
              activeTabParam === OrdersTab.InvoicesCredits) ||
            (!isLoyaltyProgramEligible && activeTabParam === OrdersTab.GoPoints)
          ) {
            this.router.navigateByUrl(NaooConstants.INFO_UNAVAILABLE_PATH);
          }
          const tabIndex = this.tabs.indexOf(activeTabParam);
          this.activeTabIndex = tabIndex > 0 ? tabIndex : 0;
        },
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get OrdersTab(): typeof OrdersTab {
    return OrdersTab;
  }

  handleActiveTabChanged(tabIndex: number) {
    const index = tabIndex >= 0 && tabIndex < this.tabs.length ? tabIndex : 0;
    this.router.navigate([{}], {
      relativeTo: this.activatedRoute,
      queryParams: { [this.ACTIVE_TAB_PARAMETER]: this.tabs[index] },
      replaceUrl: true,
    });
  }

  isTabActive(tab: OrdersTab): boolean {
    return this.tabs?.[this.activeTabIndex] === tab;
  }
}
