import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ProductInfo } from '../../shared/models/product-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import {
  ListScrollService,
  Move,
  QtyBoxType,
} from '../../shared/services/list-scroll/list-scroll.service';
import { DecimalUtilities } from '../../shared/utilities/decimal-utilities';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { CustomGuideMaterialParOrder } from '../../core/store/custom-guide/custom-guide.selectors';
import { ListsAnalyticsConstants } from '../../lists/lists-analytics.constants';
import { MaterialUnitInfo } from '../../shared/models/material-info';
import { ProductDecimalQuantityBoxComponent } from '../product-decimal-quantity-box/product-decimal-quantity-box.component';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-product-par-order-quantity-box',
  templateUrl: './product-par-order-quantity-box.component.html',
  styleUrls: ['./product-par-order-quantity-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ProductDecimalQuantityBoxComponent,
    NaooStringDefaulterPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class ProductParOrderQuantityBoxComponent {
  private static readonly parOrderingLabel = 'Add PAR Level - ';

  @Input() product: ProductInfo;
  @Input() customGuideId: string;
  @Input() set materialUnitInfo(uom: MaterialUnitInfo) {
    this._materialUnitInfo = uom;
    this.setParValue();
  }

  get materialUnitInfo(): MaterialUnitInfo {
    return this._materialUnitInfo;
  }

  @Input() set materialParOrder(materialParOrder: CustomGuideMaterialParOrder) {
    this._materialParOrder = materialParOrder;
    this.setParValue();
  }

  get materialParOrder(): CustomGuideMaterialParOrder {
    return this._materialParOrder;
  }

  parDisplayValue: string;

  private _materialParOrder: CustomGuideMaterialParOrder;
  private _materialUnitInfo: MaterialUnitInfo;

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private analyticsManager: NaooAnalyticsManager,
    private listScrollService: ListScrollService,
    private decimalUtilities: DecimalUtilities,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  updateCaseParQuantity(quantity: string): void {
    const parQuantity = this.decimalUtilities.parseValue(quantity);
    if (parQuantity !== this.getParValue()) {
      this.customGuideFacade.updateParQuantity(
        this.customGuideId,
        this.product.offeringId,
        this.materialUnitInfo.uom,
        parQuantity,
      );
      this.trackParOrderingValue(parQuantity);
    }
  }

  goToNextQuantityBox(): void {
    this.listScrollService.moveToBox(Move.Next, QtyBoxType.PAR);
  }

  goToPreviousQuantityBox(): void {
    this.listScrollService.moveToBox(Move.Prev, QtyBoxType.PAR);
  }

  private trackParOrderingValue(parQuantity: number): void {
    const eventInfo: AnalyticsEventInfo = {
      category: ListsAnalyticsConstants.customGuideCategory,
      action: ListsAnalyticsConstants.enterAction,
      label:
        ProductParOrderQuantityBoxComponent.parOrderingLabel +
        parQuantity +
        ' - ' +
        this.product.offeringId,
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private setParValue(): void {
    const parQuantity = this.getParValue();
    if (parQuantity) {
      this.parDisplayValue = parQuantity.toString();
      this.changeDetectorRef.markForCheck();
    }
  }

  private getParValue(): number | undefined {
    const parLine = this.materialParOrder
      ? this.materialParOrder.parLineRecords.find(
          (line) => line.uom === this.materialUnitInfo?.uom,
        )
      : undefined;
    return !!parLine && parLine.parQuantity ? parLine.parQuantity : undefined;
  }
}
