<div class="breadcrumbs">
  @for (breadcrumb of breadcrumbs; track breadcrumb.name) {
    <div class="breadcrumb-element">
      @if (breadcrumb.link) {
        <a
          class="breadcrumb-text"
          [routerLink]="[breadcrumb.link]"
          role="button"
          >{{ breadcrumb.name }}</a
        >
        <mat-icon class="breadcrumb-arrow" svgIcon="arrow-gray"></mat-icon>
      } @else {
        {{ breadcrumb.name }}
      }
    </div>
  }
</div>
