import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CatalogService } from '../../shared/services/catalog/catalog.service';
import { CatalogResponse } from '../../shared/models/catalog/catalog-response';
import { Subject } from 'rxjs';
import { HeroImagesComponent } from '../../shared/hero-images/hero-images.component';
import { ProductGroupComponent } from '../product-group/product-group.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  standalone: true,
  imports: [HeroImagesComponent, ProductGroupComponent, TranslateModule],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  categories: CatalogResponse[];
  destroyed$ = new Subject();

  constructor(private catalogService: CatalogService) {}

  ngOnInit() {
    const rootCategoryKey = '0';
    this.catalogService.loadCategoryTree(rootCategoryKey);
    this.catalogService
      .observeCategoryResponse(rootCategoryKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((category) => {
        if (category != null) {
          this.categories = category.subCategories;
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
