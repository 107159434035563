<div class="order-method-step">
  @if (isDisplayed(FulfillmentTypeEnum.TRUCK)) {
    <button
      [tabIndex]="tabIndex"
      [disabled]="isDisabled(FulfillmentTypeEnum.TRUCK)"
      (click)="setUserSelectedFulfillmentType(FulfillmentTypeEnum.TRUCK)"
      class="truck-fulfillment-button"
    >
      <mat-card
        appearance="outlined"
        [ngClass]="{
          selected: isFulfillmentType(FulfillmentTypeEnum.TRUCK),
        }"
        class="truck-fulfillment"
      >
        <div class="fulfillment-header row-display">
          <div class="fulfillment-icon-with-text">
            <mat-icon class="gfs-icon" svgIcon="semi_truck"></mat-icon>
            <span class="fulfillment-text">
              {{ fulfillmentText(FulfillmentTypeEnum.TRUCK) | translate }}
            </span>
          </div>
          @if (isFulfillmentType(FulfillmentTypeEnum.TRUCK)) {
            <div class="fulfillment-message truck-subtitle">
              {{
                'ORDER_METHOD_MODAL.ORDER_METHOD.FORM.TRUCK_MESSAGE' | translate
              }}
            </div>
          }
        </div>
      </mat-card>
    </button>
  }
  @if (isDisplayed(FulfillmentTypeEnum.EXPRESS)) {
    <button
      [tabIndex]="tabIndex"
      [disabled]="isDisabled(FulfillmentTypeEnum.EXPRESS)"
      (click)="setUserSelectedFulfillmentType(FulfillmentTypeEnum.EXPRESS)"
      class="express-fulfillment-button"
    >
      <mat-card
        appearance="outlined"
        [ngClass]="{
          selected: isFulfillmentType(FulfillmentTypeEnum.EXPRESS),
        }"
        class="express-fulfillment"
      >
        <div class="fulfillment-header row-display">
          <div class="fulfillment-icon-with-text">
            <mat-icon class="google-icon">local_shipping</mat-icon>
            <span class="fulfillment-text">
              {{ fulfillmentText(FulfillmentTypeEnum.EXPRESS) | translate }}
            </span>
          </div>
          @if (isFulfillmentType(FulfillmentTypeEnum.EXPRESS)) {
            <div
              class="fulfillment-message express-subtitle"
              [innerHTML]="
                expressOrderSubtitle
                  | translate
                    : {
                        minimumOrderCost:
                          expressDeliveryFees?.minOrderDollarAmount
                          | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2',
                        deliveryFee:
                          expressDeliveryFees?.minOrderAmountFee
                          | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2',
                      }
              "
            ></div>
          }
        </div>
      </mat-card>
    </button>
  }
  @if (isDisplayed(FulfillmentTypeEnum.PICKUP)) {
    <button
      [tabIndex]="tabIndex"
      [disabled]="isDisabled(FulfillmentTypeEnum.PICKUP)"
      (click)="setUserSelectedFulfillmentType(FulfillmentTypeEnum.PICKUP)"
      class="pickup-fulfillment-button"
    >
      <mat-card
        appearance="outlined"
        [ngClass]="{
          selected: isFulfillmentType(FulfillmentTypeEnum.PICKUP),
        }"
        class="pickup-fulfillment"
      >
        <div class="fulfillment-header">
          <div class="fulfillment-icon-with-text">
            <mat-icon class="google-icon">store_mall_directory</mat-icon>
            <span class="fulfillment-text">
              {{ fulfillmentText(FulfillmentTypeEnum.PICKUP) | translate }}
            </span>
          </div>
        </div>
      </mat-card>
    </button>
  }
</div>
