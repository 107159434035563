<div #actionBarContainer class="cart-action-bar-container">
  <div class="visible-buttons">
    @for (
      template of visibleTemplates([
        addToCustomGuideTemplate,
        printButtonTemplate,
        saveCartButtonTemplate,
        emptyButtonTemplate,
        sortByButtonTemplate,
      ]);
      track $index
    ) {
      <ng-template [ngTemplateOutlet]="template"></ng-template>
    }
  </div>

  @if (isHidingButtons) {
    <naoo-cart-action-bar-menu
      #menuButton
      class="menu-button"
      [buttons]="hiddenButtons"
      [hasNoMaterials]="hasNoMaterialNumbers"
      [hasOrderableMaterials]="activeCartSummary.hasOrderableMaterials"
      [isOffline]="activeCartSummary.isOffline"
      (emptyCartClick)="emptyCart()"
      (printClick)="onPrintIconClick()"
      (saveCartClick)="moveToSavedCarts()"
    >
    </naoo-cart-action-bar-menu>
  }
</div>

<ng-template #addToCustomGuideTemplate>
  <naoo-add-to-custom-guide
    #addToCustomGuideButton
    id="add-to-custom-guide-button"
    class="cart-action-button add-to-custom-guide"
    [addToGuideLabel]="'CART.ADD_TO_GUIDE'"
    [disabled]="isActionBarButtonDisabled"
    (menuActionSelected)="performMenuAction($event)"
    [currentLanguage]="currentLanguage"
  ></naoo-add-to-custom-guide>
</ng-template>

<ng-template #printButtonTemplate>
  <naoo-action-icon
    #printButton
    class="cart-action-button print-cart"
    actionIcon="print_v2"
    [iconCurrentState]="printState"
    (click)="onPrintIconClick()"
    [isInCartActionBar]="true"
  ></naoo-action-icon>
</ng-template>

<ng-template #saveCartButtonTemplate>
  <div #saveCartButton class="cart-action-button save-cart-container">
    <button
      role="button"
      class="save-cart"
      (click)="moveToSavedCarts()"
      [disabled]="isActionBarButtonDisabled"
      [ngClass]="{ disabled: isActionBarButtonDisabled }"
      [attr.aria-label]="'CART.MOVE_TO_SAVED' | translate"
    >
      <mat-icon
        id="save-cart-icon"
        [ngClass]="{ disabled: isActionBarButtonDisabled }"
        svgIcon="move-to-icon"
      ></mat-icon>
      <span>{{ 'CART.MOVE_TO_SAVED_ABBREVIATED' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #emptyButtonTemplate>
  <div #emptyButton class="cart-action-button empty-cart-container">
    <button
      role="button"
      class="empty-cart"
      [disabled]="hasNoMaterialNumbers"
      [ngClass]="{ disabled: hasNoMaterialNumbers }"
      (click)="emptyCart()"
      [attr.aria-label]="'CART.EMPTY_CART_MENU' | translate"
    >
      <mat-icon
        id="empty-cart-icon"
        [ngClass]="{ disabled: hasNoMaterialNumbers }"
        svgIcon="trash-icon-v3"
      ></mat-icon>
      {{ 'CART.EMPTY_CART_MENU' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #sortByButtonTemplate>
  <naoo-cart-sort-by
    #sortByButton
    class="cart-action-button cart-sort-by"
    [selectedOption]="activeCartSummary.sortBy"
    [disabled]="activeCartSummary.isQuickAddEnabled || hasNoMaterialNumbers"
    (sortChange)="sortCartBy($event)"
  ></naoo-cart-sort-by>
</ng-template>
