import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { InventoryAvailabilityViewModel } from '../../shared/models/inventory-availability';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { InventoryAvailabilityFacade } from '../../core/store/inventory-availability/inventory-availability.facade';
import { MaterialAvailabilityFacade } from '../../core/store/material-availability/material-availability.facade';
import {
  getCurrentUnitsAvailable,
  getEstInStockDateViewModel,
  getTotalOnOrderViewModel,
} from '../../shared/utilities/inventory-utilities';
import { MaterialAvailability } from '../../shared/models/material-availability';
import { StockType } from '../../core/services/material-availability/model/material-availabilities-record';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import {
  MaterialInfo,
  MaterialUnitInfo,
} from '../../shared/models/material-info';
import { MaterialUnitsFacade } from '../../core/store/material-units/material-units.facade';
import { MaterialUnitsRecord } from '../../core/services/material-units/models/material-units-record';
import { Localized } from '../../shared/models/localized';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { NaooDecimalPipe } from '../../shared/pipes/naoo-decimal.pipe';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NaooDatePipe,
    NaooDecimalPipe,
    NaooStringDefaulterPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class InventoryComponent implements OnDestroy, OnChanges {
  @Input() materialNumber: string;
  portionPricingPerBaseUom: number;
  portionPricingUom: MaterialUnitsRecord;
  inventory: InventoryAvailabilityViewModel;
  materialAvailability: MaterialAvailability;
  materialInfoAvailabilityUom: string;
  materialInfoAvailabilityDisplayCode: string;
  shouldNotDisplayInventoryData: boolean;
  private activeSubscriptions$: Subscription;
  private destroyed$ = new Subject<void>();

  constructor(
    private inventoryAvailabilityFacade: InventoryAvailabilityFacade,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private materialUnitsFacade: MaterialUnitsFacade,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['materialNumber']?.currentValue) {
      this.updateSubscriptions(this.materialNumber);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get portionPricingUomDescription(): Localized<string> {
    const descriptions = this.portionPricingUom.descriptions;
    return 1 === this.portionPricingPerBaseUom
      ? descriptions.single
      : descriptions.plural;
  }

  getUnitsAvailable(uom: string): number {
    return getCurrentUnitsAvailable(
      this.inventory.currentUnitsAvailability,
      uom,
    );
  }

  getEstInStockDate(): string {
    return getEstInStockDateViewModel(this.inventory.currentUnitsAvailability);
  }

  getTotalOnOrder(): number {
    return getTotalOnOrderViewModel(this.inventory.currentUnitsAvailability);
  }

  private updateSubscriptions(materialNumber: string): void {
    if (this.activeSubscriptions$) {
      this.activeSubscriptions$.unsubscribe();
      this.shouldNotDisplayInventoryData = false;
    }
    this.inventoryAvailabilityFacade.loadInventoryAvailability(
      this.materialNumber,
    );

    const inventory$ =
      this.inventoryAvailabilityFacade.getLoadedInventoryAvailability(
        materialNumber,
      );
    const availability$ =
      this.materialAvailabilityFacade.getLoadedMaterialAvailability(
        materialNumber,
      );
    const materialInfo$ = this.materialInfoFacade.getLoadedMaterialInfos([
      materialNumber,
    ]);
    const materialUnits$ =
      this.materialUnitsFacade.getLoadedMaterialUnitsRecords();

    this.activeSubscriptions$ = combineLatest([
      inventory$,
      availability$,
      materialInfo$,
      materialUnits$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([inventory, availability, materialInfos, materialUnits]) => {
        this.inventory = inventory;
        this.materialAvailability = availability;
        const materialInfo: MaterialInfo | undefined = materialInfos[0];
        const childUnit: MaterialUnitInfo | undefined =
          materialInfo?.units.filter((unit) => !unit.parentUom)[0];
        this.materialInfoAvailabilityUom = childUnit?.uom;
        this.materialInfoAvailabilityDisplayCode = childUnit?.displayCode;
        this.portionPricingUom =
          materialUnits.entities[materialInfo?.pricingPortionUom];
        this.portionPricingPerBaseUom = materialInfo?.pricingPortionPerBaseUom;
        if (
          this.inventory === undefined ||
          !this.inventory?.considerInventory ||
          this.isJitSoOrEpo()
        ) {
          this.inventory = this.emptyInventory();
          this.shouldNotDisplayInventoryData = true;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  private emptyInventory(): InventoryAvailabilityViewModel {
    return {
      materialNumber: this.materialNumber,
      currentUnitsAvailability: [],
      considerInventory: true,
    };
  }

  private isJitSoOrEpo(): boolean {
    const stockType = this.materialAvailability?.stockType;
    return (
      stockType === StockType.SpecialOrder ||
      stockType === StockType.SpecialOrderSAP ||
      stockType === StockType.DropShip ||
      stockType === StockType.Jit
    );
  }
}
