<div class="header-wrapper">
  <div class="first-half">
    <div class="column">
      <ng-container
        [ngTemplateOutlet]="summaryCell"
        [ngTemplateOutletContext]="{
          class: 'delivery-date',
          icon: 'calendar-icon',
          label: 'PURCHASE_DETAILS.TRANSACTION_DATE' | translate,
          value:
            viewModel.purchaseDetails.saleDate | naooDate: 'shortDate' : 'UTC',
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="summaryCell"
        [ngTemplateOutletContext]="{
          class: 'delivery-eta',
          icon: 'clock-icon',
          label: 'PURCHASE_DETAILS.TIME' | translate,
          value:
            (viewModel.purchaseDetails.saleTimestamp
              | naooDate
                : 'time'
                : undefined
                : undefined
                : viewModel.customerTimezone) +
            ' ' +
            (timezoneAbbrTranslationKey | translate),
        }"
      ></ng-container>
    </div>
    <div class="column">
      <ng-container
        [ngTemplateOutlet]="summaryCell"
        [ngTemplateOutletContext]="{
          class: 'customer',
          icon: 'address-icon',
          label: 'PURCHASE_DETAILS.CUSTOMER' | translate,
          value:
            viewModel.purchaseDetails.customerDetails?.customerName +
            '<br/>' +
            ('PURCHASE_DETAILS.CUSTOMER_ID'
              | translate
                : {
                    customerId:
                      viewModel.purchaseDetails.customerDetails?.customerId,
                  }),
        }"
      ></ng-container>
      <div class="cell store-location">
        <ng-container
          [ngTemplateOutlet]="iconWrapper"
          [ngTemplateOutletContext]="{
            icon: 'store-icon',
          }"
        ></ng-container>
        <div class="content">
          <div class="label">
            {{ 'PURCHASE_DETAILS.STORE_LOCATION' | translate }}
          </div>
          <naoo-store-display
            [storeRecord]="viewModel.purchaseDetails.storeRecord"
            [customerTimeZone]="viewModel.customerTimezone"
            [isMobileWidth]="isMobileWidth"
            [context]="storeDisplayContext"
          >
          </naoo-store-display>
        </div>
      </div>
    </div>
  </div>

  <div class="second-half">
    <div class="column">
      <div class="cell item-totals">
        <ng-container [ngTemplateOutlet]="iconWrapper"></ng-container>
        <div class="content">
          <div class="label">
            {{ 'PURCHASE_DETAILS.ITEM_TOTALS' | translate }}
          </div>
          <div class="value">
            {{
              'PURCHASE_DETAILS.TOTAL_LINES'
                | translate
                  : {
                      lines: viewModel.purchaseDetails.totalDetails.totalLines,
                    }
            }},
            {{
              'PURCHASE_DETAILS.TOTAL_ITEMS'
                | translate
                  : {
                      items:
                        viewModel.purchaseDetails.totalDetails.totalQuantity,
                    }
            }}
          </div>
        </div>
      </div>
      <div class="cell total-points">
        <ng-container [ngTemplateOutlet]="iconWrapper"></ng-container>
        <div class="content">
          <div class="label total-points">
            {{ 'PURCHASE_DETAILS.TOTAL' | translate }}
            <mat-icon class="loyalty-icon" svgIcon="loyalty_icon"></mat-icon>
            {{ 'PURCHASE_DETAILS.POINTS' | translate }}
          </div>
          <div class="value">
            {{ viewModel.purchaseDetails.totalDetails.totalPoints }}
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <ng-container
        [ngTemplateOutlet]="summaryCell"
        [ngTemplateOutletContext]="{
          class: 'total',
          label: 'PURCHASE_DETAILS.TOTAL' | translate,
          value:
            viewModel.purchaseDetails.totalDetails.totalCost | naoocurrency,
          message: 'PURCHASE_DETAILS.TOTAL_INCLUDES_TAXES' | translate,
        }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template
  #summaryCell
  let-cls="class"
  let-icon="icon"
  let-label="label"
  let-value="value"
  let-message="message"
>
  <div class="cell {{ cls }}">
    <ng-container
      [ngTemplateOutlet]="iconWrapper"
      [ngTemplateOutletContext]="{
        icon: icon,
      }"
    ></ng-container>
    <div class="content">
      <div class="label">{{ label }}</div>
      <div class="value" [innerHTML]="value"></div>
      @if (message) {
        <div class="value message">{{ message }}</div>
      }
    </div>
  </div>
</ng-template>

<ng-template #iconWrapper let-icon="icon">
  <div class="icon-wrapper" [ngClass]="{ 'icon-spacer': !icon }">
    @if (icon) {
      <mat-icon [svgIcon]="icon" [ngClass]="'gray'"></mat-icon>
    }
  </div>
</ng-template>
