import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MaterialEntitlementMapping } from '../../../core/store/material-row/models/material-row';
import { ResizeEventService } from '../../services/resize-event/resize-event.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CommodityAllocationComponent } from '../commodity-allocation/commodity-allocation.component';

@Component({
  selector: 'naoo-commodity-docket-carousel',
  templateUrl: './commodity-docket-carousel.component.html',
  styleUrls: ['./commodity-docket-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, CommodityAllocationComponent],
})
export class CommodityDocketCarouselComponent implements OnInit, OnDestroy {
  @Input() entitlementMappings: MaterialEntitlementMapping[];

  get isNextDisabled(): boolean {
    return (
      this.carouselIndex + this.carouselLimit >=
      this.entitlementMappings?.length
    );
  }

  get isPreviousDisabled(): boolean {
    return this.carouselIndex === 0;
  }

  get shouldHideArrows(): boolean {
    return this.entitlementMappings?.length <= this.carouselLimit;
  }

  get getVisibleEntitlementMappings(): MaterialEntitlementMapping[] {
    let entitlementMappingsSlice: MaterialEntitlementMapping[];
    if (this.entitlementMappings?.length === 1) {
      entitlementMappingsSlice = [this.entitlementMappings[this.carouselIndex]];
    } else {
      entitlementMappingsSlice = this.entitlementMappings?.slice(
        this.carouselIndex,
        this.carouselIndex + this.carouselLimit,
      );
    }
    return entitlementMappingsSlice;
  }

  private destroyed$ = new Subject<void>();
  private readonly maxCarouselSize = 2;
  private readonly singleCardWidth = 1244;

  carouselLimit = 1;
  carouselIndex = 0;

  constructor(
    private resizeEventService: ResizeEventService,
    private window: Window,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.resizeEventService
      .getEvents()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const calculatedCarouselLimit =
          this.window.innerWidth <= this.singleCardWidth
            ? 1
            : this.maxCarouselSize;
        if (this.carouselLimit !== calculatedCarouselLimit) {
          this.carouselLimit = calculatedCarouselLimit;
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  previousCard(): void {
    if (!this.isPreviousDisabled) {
      this.carouselIndex--;
    }
  }

  nextCard(): void {
    if (!this.isNextDisabled) {
      this.carouselIndex++;
    }
  }
}
