import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectExportMaterialsData } from './export-materials.selectors';
import { filter } from 'rxjs/operators';
import {
  ExportMaterialsData,
  ExportModalSelection,
} from '../../../shared/services/export-materials/models/export-materials';

@Injectable({ providedIn: 'root' })
export class ExportMaterialsFacade {
  constructor(private store: Store) {}

  getLoadedExportMaterialsData(
    modalSelection: ExportModalSelection,
  ): Observable<ExportMaterialsData> {
    return this.store
      .select(selectExportMaterialsData(modalSelection))
      .pipe(filter((data) => !!data));
  }
}
