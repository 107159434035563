<article class="not-found-container">
  <div class="not-found-img">
    <img
      alt="{{ 'NOT_FOUND.HEADING.ALT_TEXT' | translate }}"
      src="assets/images/404@2x.png"
      srcset="assets/images/404@1x.png 1x, assets/images/404@2x.png 2x"
    />
  </div>
  <div class="not-found-title">{{ 'NOT_FOUND.HEADING.TITLE' | translate }}</div>
  <div class="not-found-subtitle">
    {{ 'NOT_FOUND.HEADING.SUBTITLE' | translate }}
  </div>
  <div class="not-found-buttons">
    <button
      class="naoo-button secondary-button large-button button-categories"
      (click)="navigateToCategories()"
    >
      {{ 'NOT_FOUND.BUTTON.CATEGORIES' | translate }}
    </button>
    <button
      class="naoo-button primary-button large-button button-back"
      (click)="navigateBack()"
    >
      {{ 'RETURN_TO_PREVIOUS_PAGE' | translate }}
    </button>
  </div>
</article>
