import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CartReviewSection,
  CartReviewSectionName,
} from '../../../../../../core/store/cart-review/cart-review.state';
import { CartCounts } from '../../../../../../core/store/cart/cart.selectors';
import { ExpressDeliveryWindow } from '../../../../../../core/services/express-schedules/models/express-schedule-record';
import { DateService } from '../../../../../../shared/services/date/date.service';
import { Moment } from 'moment';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-cart-review-store-success-header',
  templateUrl: './cart-review-store-success-header.component.html',
  styleUrls: ['./cart-review-store-success-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class CartReviewStoreSuccessHeaderComponent {
  @Input() customerTimeZone: string;
  @Input() shouldShowDate: boolean;
  @Input() storeSection: CartReviewSection;
  @Input() storeSectionCartCounts: CartCounts;
  @Input() selectedDate?: Moment | ExpressDeliveryWindow;

  constructor(private dateService: DateService) {}

  get storeLocation(): string {
    const storeAddress1 = this.storeSection?.store?.address?.address1;
    return `${this.storeSection?.store?.name}${
      storeAddress1 ? ', ' + storeAddress1 : ''
    }`;
  }

  get pickupResolutionDate(): Moment | undefined {
    return this.selectedDate
      ? (this.selectedDate as Moment)
      : this.storeSection.pickupResolutionDate;
  }

  get expressResolutionDate(): ExpressDeliveryWindow | undefined {
    return this.selectedDate
      ? (this.selectedDate as ExpressDeliveryWindow)
      : this.storeSection.expressResolutionDate;
  }

  get hasResolutionDate(): boolean {
    return this.isExpress
      ? !!this.storeSection.expressResolutionDate
      : !!this.storeSection.pickupResolutionDate;
  }

  get isExpress(): boolean {
    return CartReviewSectionName.ExpressItemsSection === this.storeSection.name;
  }

  get hasStoreLocation(): boolean {
    return !!this.storeSection?.store && !this.isExpress;
  }

  get dateLabel(): string {
    switch (this.storeSection.name) {
      case CartReviewSectionName.PickupItemsSection:
        return 'CART_REVIEW.STORE_PICKUP_ITEMS.BANNER.PICKUP';
      case CartReviewSectionName.ExpressItemsSection:
        return 'CART_REVIEW.STORE_EXPRESS_ITEMS.BANNER.EXPRESS';
      default:
        return '';
    }
  }

  get storeDate(): string | undefined {
    const isResolved = this.hasResolutionDate;
    const section = this.storeSection;

    let expressDate: ExpressDeliveryWindow | undefined = undefined;
    let pickupDate: Moment | undefined = undefined;

    if (this.isExpress) {
      expressDate = isResolved
        ? this.expressResolutionDate
        : section.originalExpressDate;
    } else {
      pickupDate = isResolved
        ? this.pickupResolutionDate
        : section.originalPickupDate;
    }
    return this.dateService.getStoreDate(
      this.customerTimeZone,
      expressDate,
      pickupDate,
    );
  }
}
