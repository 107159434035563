@if (!!storeSection) {
  <div class="resolution-section">
    <div class="banner-header">
      <mat-icon
        [ngClass]="{
          'error-icon': !hasResolutionDate,
          'success-icon': hasResolutionDate && !isPartial,
          'warning-icon': hasResolutionDate && isPartial,
        }"
        [svgIcon]="bannerIcon.name"
        class="banner-icon"
      ></mat-icon>
      <h3 class="banner-title">
        {{ 'CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.RESOLVE' | translate }}
      </h3>
    </div>
    <div class="resolve-options">
      @if (!isExpress && isSplitPickupScenario) {
        <div class="button-container">
          <button
            (click)="singlePickup()"
            [ngClass]="{ selected: isSinglePickup }"
            [disabled]="isDisabled"
            class="single-pickup-button"
            mat-button
          >
            <mat-icon
              [svgIcon]="
                isSinglePickup && !isSectionEmpty
                  ? 'rounded_corner_selected'
                  : 'rounded_corner'
              "
              class="corner-icon"
            ></mat-icon>
            <div class="option-group">
              <mat-icon
                [svgIcon]="
                  isSinglePickup ? 'single_pickup_red' : 'single_pickup_blue'
                "
                class="option-icon single-pickup-icon"
              ></mat-icon>
              {{
                'CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.SINGLE_PICKUP'
                  | translate
              }}
            </div>
          </button>
          <button
            (click)="splitPickup()"
            [ngClass]="{ selected: !isSinglePickup }"
            [disabled]="isDisabled"
            class="split-order-button"
            mat-button
          >
            <mat-icon
              [svgIcon]="
                !isSinglePickup && !isSectionEmpty
                  ? 'rounded_corner_selected'
                  : 'rounded_corner'
              "
              class="corner-icon"
            >
            </mat-icon>
            <div class="option-group">
              <mat-icon
                [svgIcon]="
                  !isSinglePickup ? 'split_order_red' : 'split_order_blue'
                "
                class="option-icon"
              ></mat-icon>
              {{
                'CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.SPLIT_ORDER'
                  | translate
              }}
            </div>
          </button>
        </div>
      }
      <div
        class="resolve-data"
        [ngClass]="{
          'split-pickup': isSplitPickupScenario,
        }"
      >
        @if (isSplitPickupScenario) {
          <div class="resolve-text">
            @if (isSinglePickup) {
              {{ unavailableSingleText | translate }}
            } @else {
              {{
                'CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.SPLIT_TEXT'
                  | translate
              }}
            }
          </div>
        }
        <div class="resolve-drop-down">
          <mat-icon
            [svgIcon]="'calendar-icon-v3'"
            class="calendar-icon"
          ></mat-icon>
          <mat-form-field>
            <mat-label class="color-primary">
              {{ dropdownLabel | translate }}
            </mat-label>
            <mat-select
              (selectionChange)="choosePickupDate($event)"
              [value]="selectedDateIndex"
              [disabled]="isDisabled"
              class="pickup-drop-down"
            >
              <mat-select-trigger>
                <naoo-cart-review-store-option
                  [customerTimeZone]="customerTimeZone"
                  [expressDate]="expressDate"
                  [pickupDate]="pickupMoment"
                  [isFirst]="selectedDateIndex === 0"
                  [isSinglePickup]="isSinglePickup"
                  [isAvailableDateValid]="isSplitPickupScenario"
                >
                </naoo-cart-review-store-option>
              </mat-select-trigger>
              @for (
                date of filteredDateChoices;
                track date;
                let isFirst = $first;
                let idx = $index
              ) {
                <mat-option [value]="idx">
                  <naoo-cart-review-store-option
                    [customerTimeZone]="customerTimeZone"
                    [expressDate]="isExpress ? date : undefined"
                    [pickupDate]="!isExpress ? date : undefined"
                    [isFirst]="isFirst"
                    [isSinglePickup]="isSinglePickup"
                    [isAvailableDateValid]="isSplitPickupScenario"
                  >
                  </naoo-cart-review-store-option>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="error-divider"> </mat-divider>
  <naoo-cart-review-store-success-header
    [customerTimeZone]="customerTimeZone"
    [shouldShowDate]="displayDate"
    [storeSection]="storeSection"
    [storeSectionCartCounts]="storeSectionCartCounts"
    [selectedDate]="selectedDate"
  ></naoo-cart-review-store-success-header>
}
