<mat-expansion-panel [expanded]="true" class="item-exceptions-expansion-panel">
  <mat-expansion-panel-header
    class="item-exceptions-header expansion-panel-header-large-indicator white-indicator"
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'"
  >
    <mat-panel-title class="exception-description">
      <mat-icon class="exception-icon" svgIcon="exception-icon-v1"></mat-icon>
      <span class="exception-description text-center"
        >{{ errorMaterials.length }}
        {{
          'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.TITLE' | translate | uppercase
        }}</span
      >
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="item-exceptions">
    <div class="problem-message">
      {{ 'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.SUBMISSION_PROBLEMS' | translate }}
    </div>
    <div class="contact-sales-message">
      {{ 'ORDER_CONFIRMATION.ITEM_EXCEPTIONS.CONTACT_SALES' | translate }}
    </div>
    <naoo-error-materials-header
      [canFindRelated]="true"
    ></naoo-error-materials-header>
    @for (
      item of errorMaterials;
      track item.itemDetail.id;
      let isLast = $last
    ) {
      <naoo-order-issue-row
        class="item-exception-row"
        [orderItem]="item"
        [isExpandedView]="true"
        [materialRelatedRouteDate]="materialRelatedRouteDate"
      ></naoo-order-issue-row>
    }
  </div>
</mat-expansion-panel>
