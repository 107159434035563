<mat-drawer-container
  [ngClass]="{
    'cart-summary-container': !drawerOpened && !isMobile,
    'cart-summary-container-preview-open': drawerOpened && !isMobile,
  }"
>
  <mat-drawer-content>
    <h1>{{ 'CART_SUMMARY.TITLE' | translate }}</h1>
    <mat-tab-group
      class="focusable no-print-tab-labels"
      animationDuration="200ms"
      [disableRipple]="true"
      [selectedIndex]="activeTabIndex$ | async"
      [dynamicHeight]="true"
      [mat-stretch-tabs]="false"
      (selectedIndexChange)="handleActiveTabChanged($event)"
    >
      <mat-tab
        id="activeCartTab"
        [label]="'CART.ACTIVE_CART.TITLE' | translate"
        [aria-label]="'CART.ACTIVE_CART.TITLE' | translate"
      >
        <ng-template matTabContent>
          <naoo-active-cart-container></naoo-active-cart-container>
        </ng-template>
      </mat-tab>

      <mat-tab
        id="savedCartsTab"
        [label]="'CART.SAVED_CARTS.TITLE' | translate"
        [aria-label]="'CART.SAVED_CARTS.TITLE' | translate"
        [disabled]="isOffline$ | async"
      >
        <ng-template matTabContent>
          <naoo-saved-carts-content
            [savedCarts]="savedCarts$ | async"
            [savedCartsLoading]="savedCartsLoading$ | async"
          ></naoo-saved-carts-content>
        </ng-template>
      </mat-tab>

      @if (
        (isPunchoutSession$ | async) ||
        (savedCarts$ | async)?.punchedOutCarts?.length
      ) {
        <mat-tab
          id="punchedOutCartsTab"
          [label]="'CART.PUNCHED_OUT_CARTS.TITLE' | translate"
          [aria-label]="'CART.PUNCHED_OUT_CARTS.TITLE' | translate"
          [disabled]="isOffline$ | async"
        >
          <ng-template matTabContent>
            <naoo-punched-out-carts-content
              [savedCarts]="savedCarts$ | async"
            ></naoo-punched-out-carts-content>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  </mat-drawer-content>
  <mat-drawer
    id="mat-drawer"
    position="{{ isMobile ? 'start' : 'end' }}"
    [opened]="drawerOpened"
    mode="{{ isMobile ? 'over' : 'side' }}"
    [ngClass]="{ 'mobile-preview': isMobile, 'mat-drawer': !isMobile }"
  >
    @if (drawerOpened) {
      <naoo-preview-cart
        [isMobile]="isMobile"
        [savedCarts]="savedCarts$ | async"
      ></naoo-preview-cart>
    }
  </mat-drawer>
</mat-drawer-container>
