@if (!!section) {
  <div class="container-details-header">
    <div
      class="first-section"
      [ngClass]="{ 'hidden-po-number': !isValidPoNumber }"
    >
      <div class="content-details-container">
        <div class="content-details-section">
          <span id="customer-title" class="header-title">{{
            'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.CUSTOMER' | translate
          }}</span>
          <div class="customer-info-content header-content">
            <span class="customer-name">{{ section.customerName }}</span>
            <span id="customer-number">#{{ section.customerNumber }}</span>
          </div>
        </div>
      </div>
      <div class="content-details-container">
        <div class="content-details-section">
          <span id="route-date-title" class="header-title">{{
            'CART_REVIEW_HEADER_DATE_ON' | deliveryOrShip | translate
          }}</span>
          <span id="route-date" class="header-content">{{
            section.routeDate | naooDate: 'longDateNoDayAbbrMo' : 'UTC'
          }}</span>
        </div>
      </div>
    </div>
    @if (isValidPoNumber) {
      <div class="second-section">
        <div class="content-details-container">
          <div class="content-details-section">
            <span id="po-number-title" class="header-title">{{
              'CART_REVIEW.REST_OF_YOUR_ORDER.HEADER.PO' | translate
            }}</span>
            <span id="po-number" class="header-content">{{
              section.poNumber
            }}</span>
          </div>
        </div>
      </div>
    }
  </div>
}
