<!-- payment form element dynamically inserted here-->
<div class="footer" #footer>
  <div>
    {{ 'CART_REVIEW.ADD_PAYMENT.ACCEPT_ALL_CARDS' | translate }}
  </div>
  <div class="secure-payment-text">
    {{ 'CART_REVIEW.ADD_PAYMENT.SECURE_PAYMENT' | translate }}
    <mat-icon class="secure-icon" svgIcon="secure_payment"></mat-icon>
  </div>
</div>
