@if (materialRow$ | async) {
  @if (!!orderLine.discountAmount) {
    <div class="line-item-savings">
      {{ 'ORDERS.DETAILS.SAVED' | translate }}
      {{ orderLine.discountAmount | naooprice }}
    </div>
  }
  <div class="order-details-table-row">
    <div class="order-details-table-cell product">
      @if (isUnavailable((materialRow$ | async)?.status)) {
        <naoo-material-unavailable
          [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
          [isMobile]="true"
          [isDeleteHidden]="true"
        >
        </naoo-material-unavailable>
      } @else {
        <div class="material-wrapper">
          <div class="description">
            <naoo-material-image
              [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
              [isMobile]="true"
            ></naoo-material-image>
            <div class="info">
              <naoo-material-info
                [materialRowOptions]="
                  (materialRow$ | async)?.materialRowOptions
                "
                [materialInfo]="(materialRow$ | async)?.materialRowInfo"
                [isMobile]="isMobile"
              ></naoo-material-info>
              @if (orderLine.substituteMaterial) {
                <div
                  tabindex="0"
                  class="substituted-message tooltip"
                  [attr.tooltip]="getSubstitutedItemDescription()"
                  [attr.aria-label]="getSubstitutedItemDescription()"
                >
                  <mat-icon
                    svgIcon="change_circle"
                    class="substitute-icon"
                  ></mat-icon>
                  <div class="order-issue-text-container">
                    {{ 'ORDER_CONFIRMATION.SUBSTITUTED' | translate }}
                  </div>
                </div>
              } @else {
                <div class="flag-wrapper">
                  <naoo-material-flag-container
                    [flags]="(materialRow$ | async)?.flags"
                    [maximumNumberOfFlags]="6"
                  >
                  </naoo-material-flag-container>
                </div>
              }
            </div>
          </div>
          @if (isMobile) {
            <div>
              <naoo-material-options-menu
                [materialRowOptions]="
                  (materialRow$ | async)?.materialRowOptions
                "
                [isMobile]="isMobile"
              >
              </naoo-material-options-menu>
            </div>
          }
        </div>
      }
    </div>
    @if (!isMobile) {
      <div class="order-details-table-cell details">
        @for (uomLine of orderLine.units; track uomLine.uom) {
          <div class="order-details-uom-row">
            <div class="order-details-uom-container">
              <div class="order-details-table-cell status">
                @if (uomLine.shouldShowLineStatus) {
                  <ng-container
                    [ngTemplateOutlet]="lineStatus"
                    [ngTemplateOutletContext]="{
                      uomLine: uomLine,
                    }"
                  ></ng-container>
                }
              </div>
              <div class="order-details-table-cell unit">
                <div class="unit-value-container">
                  <span class="value">{{
                    { uomCode: uomLine.displayCode } | materialunits
                  }}</span>
                </div>
              </div>
              <div class="order-details-table-cell price">
                <div class="price-value-container">
                  <span class="value">{{
                    uomLine.productPrice | naooprice: uomLine.catchWeightUom
                  }}</span>
                </div>
              </div>
              <div class="order-details-table-cell qty-order">
                <div class="qty-order-value-container">
                  <span class="value">{{
                    uomLine.quantityOrdered | naoodecimal: '1.0-0'
                  }}</span>
                </div>
              </div>
              <div class="order-details-table-cell qty-ship">
                <div
                  class="qty-ship-value-container"
                  [ngClass]="{
                    'ship-error': isErrorSeverity(uomLine.lineStatusSeverity),
                  }"
                >
                  @if (isWarningOrErrorSeverity(uomLine.lineStatusSeverity)) {
                    <mat-icon
                      class="qty-error status-icon"
                      [svgIcon]="uomLine.severityIcon"
                      [ngClass]="uomLine.severityIconColor"
                    ></mat-icon>
                  }
                  <span class="value uom-shipped">{{
                    uomLine.quantityShipped != null
                      ? (uomLine.quantityShipped | naoodecimal: '1.0-0')
                      : ('ORDERS.SUMMARY.TBD' | translate)
                  }}</span>
                </div>
              </div>
              <div class="order-details-table-cell total">
                <div class="total-value-container">
                  <span class="value">{{ uomLine.total | naooprice }}</span>
                </div>
              </div>
              <div class="order-details-table-cell received">
                <div class="total-value-container">
                  <span class="value">________</span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    }
    @if (isMobile) {
      <div class="order-details-table-cell details">
        @if (shouldShowStatusForAnyLine(orderLine.units)) {
          <div class="order-details-table-cell status">
            @if (shouldShowStatusForMoreThanOneLine(orderLine.units)) {
              <span class="naoo-table-cell-label multiple-sub-label">{{
                'ORDERS.DETAILS.STATUS' | translate
              }}</span>
            }
            @for (
              uomLine of filterItemsThatShouldShowStatus(orderLine.units);
              track uomLine.uom;
              let isFirst = $first;
              let isLast = $last
            ) {
              <div class="uom-status">
                @if (!(isFirst && isLast)) {
                  <div class="status-sub-label">
                    {{
                      {
                        uomCode: uomLine.uom,
                        quantity: uomLine.quantityOrdered,
                      } | materialunits: false
                    }}
                  </div>
                }
                @if (isFirst && isLast) {
                  <div class="naoo-table-cell-label">
                    {{ 'ORDERS.DETAILS.STATUS' | translate }}
                  </div>
                }
                <ng-container
                  [ngTemplateOutlet]="lineStatus"
                  [ngTemplateOutletContext]="{
                    uomLine: uomLine,
                  }"
                ></ng-container>
              </div>
            }
          </div>
        }
        <div class="order-details-table-cell price">
          <div class="naoo-table-cell-label">
            {{ 'ORDERS.DETAILS.PRICE' | translate }}
          </div>
          <div class="uom-container">
            @for (
              uomLine of orderLine.units;
              track uomLine.uom;
              let isLast = $last
            ) {
              <div class="price-value-container">
                <span class="sub-label"
                  >{{ { uomCode: uomLine.uom } | materialunits }}:</span
                >
                <span class="value">{{
                  uomLine.productPrice | naooprice: uomLine.catchWeightUom
                }}</span>
                @if (!isLast) {
                  <span class="unit-separator">|</span>
                }
              </div>
            }
          </div>
        </div>
        <div class="order-details-table-cell qty-order">
          <div class="naoo-table-cell-label">
            {{ 'ORDERS.DETAILS.QTY_ORDER' | translate }}
          </div>
          <div class="uom-container">
            @for (
              uomLine of orderLine.units;
              track uomLine.uom;
              let isLast = $last
            ) {
              <div class="qty-order-value-container">
                <span class="value">{{
                  { uomCode: uomLine.uom, quantity: uomLine.quantityOrdered }
                    | materialunits
                }}</span>
                @if (!isLast) {
                  <span class="unit-separator">|</span>
                }
              </div>
            }
          </div>
        </div>
        <div class="order-details-table-cell qty-ship">
          <div class="naoo-table-cell-label">
            {{ 'ORDERS.DETAILS.QTY_SHIP' | translate }}
          </div>
          <div class="uom-container">
            @for (
              uomLine of orderLine.units;
              track uomLine.uom;
              let isLast = $last
            ) {
              <div
                class="qty-ship-value-container"
                [ngClass]="{
                  'ship-error': isErrorSeverity(uomLine.lineStatusSeverity),
                }"
              >
                @if (isWarningOrErrorSeverity(uomLine.lineStatusSeverity)) {
                  <mat-icon
                    class="qty-error status-icon"
                    [svgIcon]="uomLine.severityIcon"
                    [ngClass]="uomLine.severityIconColor"
                  ></mat-icon>
                }
                <span class="value uom-shipped">
                  @if (uomLine.quantityShipped !== null) {
                    {{
                      {
                        uomCode: uomLine.uom,
                        quantity: uomLine.quantityShipped,
                      } | materialunits
                    }}
                  } @else {
                    {{ 'ORDERS.SUMMARY.TBD' | translate }}
                  }
                </span>
                @if (!isLast) {
                  <span class="unit-separator">|</span>
                }
              </div>
            }
          </div>
        </div>
        <div class="order-details-table-cell total">
          <div class="naoo-table-cell-label">
            {{ 'ORDERS.DETAILS.TOTAL' | translate }}
          </div>
          <div class="uom-container">
            @for (
              uomLine of orderLine.units;
              track uomLine.uom;
              let isLast = $last
            ) {
              <div class="total-value-container">
                <span class="sub-label"
                  >{{
                    { uomCode: uomLine.uom, quantity: uomLine.quantityShipped }
                      | materialunits: false
                  }}:</span
                >
                <span class="value">{{ uomLine.total | naooprice }}</span>
                @if (!isLast) {
                  <span class="unit-separator">|</span>
                }
              </div>
            }
          </div>
        </div>
        @if (!!isEditOrderEnabled) {
          <span class="order-edit">
            <button
              [attr.aria-label]="
                'ORDERS.DETAILS.EDIT_ICON_ALT_TEXT' | translate
              "
              class="edit-button"
              [disabled]="orderLine.isEditDisabled"
              (click)="editClick.emit()"
            >
              <mat-icon
                class="icon edit"
                svgIcon="edit-icon"
                [ngClass]="{ 'inactive-svg': orderLine.isEditDisabled }"
              >
              </mat-icon>
              &nbsp;&nbsp;{{ 'ORDERS.DETAILS.EDIT' | translate }}
            </button>
            <button
              [attr.aria-label]="
                'ORDERS.DETAILS.DELETE_ICON_ALT_TEXT' | translate
              "
              class="trash-button"
              [disabled]="orderLine.isCancelDisabled"
              (click)="cancelClick.emit()"
            >
              <mat-icon
                class="icon trash"
                svgIcon="trash-icon"
                [ngClass]="{ 'inactive-svg': orderLine.isCancelDisabled }"
              >
              </mat-icon>
              &nbsp;&nbsp;{{ 'ORDERS.DETAILS.CANCEL' | translate }}
            </button>
          </span>
        }
      </div>
    }
    @if (!isMobile) {
      <div
        class="order-details-table-cell order-options"
        [ngClass]="{
          'contains-order-edit': isEditOrderEnabled,
        }"
      >
        @if (isEditOrderEnabled) {
          <span class="order-edit">
            <button
              [attr.aria-label]="
                'ORDERS.DETAILS.EDIT_ICON_ALT_TEXT' | translate
              "
              mat-icon-button
              class="edit-button"
              [disabled]="orderLine.isEditDisabled"
              [matTooltip]="'ORDERS.DETAILS.EDIT' | translate"
              [matTooltipShowDelay]="tooltipShowDelay"
              (click)="editClick.emit()"
            >
              <mat-icon
                class="icon edit"
                svgIcon="edit-icon"
                [ngClass]="{ 'inactive-svg': orderLine.isEditDisabled }"
              >
              </mat-icon>
            </button>
            <button
              [attr.aria-label]="
                'ORDERS.DETAILS.DELETE_ICON_ALT_TEXT' | translate
              "
              mat-icon-button
              class="trash-button"
              [disabled]="orderLine.isCancelDisabled"
              [matTooltip]="'ORDERS.DETAILS.CANCEL' | translate"
              [matTooltipShowDelay]="tooltipShowDelay"
              (click)="cancelClick.emit()"
            >
              <mat-icon
                class="icon trash"
                svgIcon="trash-icon"
                [ngClass]="{ 'inactive-svg': orderLine.isCancelDisabled }"
              >
              </mat-icon>
            </button>
          </span>
        }
        <naoo-material-options-menu
          [materialRowOptions]="(materialRow$ | async)?.materialRowOptions"
          [isMobile]="isMobile"
        >
        </naoo-material-options-menu>
      </div>
    }
  </div>
}

<ng-template #lineStatus let-uomLine="uomLine">
  @if (uomLine) {
    <div class="status-display">
      <div
        class="line-status-value-container tooltip"
        [ngClass]="{
          'ship-error': isErrorSeverity(uomLine.lineStatusSeverity),
        }"
        [attr.tooltip]="getToolTipText(orderDetailLineStatus(uomLine))"
        [attr.aria-label]="getToolTipText(orderDetailLineStatus(uomLine))"
        [attr.tabindex]="getToolTipTabIndex(orderDetailLineStatus(uomLine))"
      >
        <mat-icon
          class="status-icon"
          [svgIcon]="orderDetailLineStatus(uomLine)?.lineStatusIcon"
          [ngClass]="orderDetailLineStatus(uomLine)?.lineStatusClass"
          [attr.aria-label]="
            orderDetailLineStatus(uomLine)?.lineStatusIconAltTextKey | translate
          "
          [attr.aria-hidden]="false"
        ></mat-icon>
        <div class="line-status-value">
          <div class="line-status-description">
            {{
              orderDetailLineStatus(uomLine)?.primaryLineStatus
                | stringdefaulter
            }}
          </div>
        </div>
      </div>
      <div class="secondary-line-status-description">
        {{
          orderDetailLineStatus(uomLine)?.secondaryLineStatus | stringdefaulter
        }}
      </div>
    </div>
  }
</ng-template>
