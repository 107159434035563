<div class="mobile-filter-container">
  <div class="mobile-filter-list">
    <naoo-search-filters
      [taxonomy]="taxonomy"
      [defaultFilters]="defaultFilters"
    >
    </naoo-search-filters>
  </div>

  <div class="mobile-filter-footer">
    <button class="clear-button secondary-button" (click)="clearAll()">
      <mat-icon svgIcon="refresh_icon" class="clear-all-icon"></mat-icon>
      {{ 'CLEAR.ALL' | translate }}
    </button>
    <button class="done-button primary-button" (click)="close()">
      {{ 'DONE' | translate }}
    </button>
  </div>
</div>
