import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ProductAction,
  ProductActionInfo,
} from '../product-guide-edit/product-action';
import { naooAnimations } from '../../shared/animations/animations';
import { Observable, Subject } from 'rxjs';
import { ProductInfo } from '../../shared/models/product-info';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { ListScrollService } from '../../shared/services/list-scroll/list-scroll.service';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { Localized } from '../../shared/models/localized';
import { NaooStringDefaulter } from '../../shared/string-defaulter/naoo-string-defaulter';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { NaooConstants } from '../../shared/NaooConstants';
import { CreateOrderGuideCategoryModalComponent } from '../../lists/create-order-guide-category-modal/create-order-guide-category-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastMessageService } from '../../shared/services/toast-message/toast-message.service';
import { CategorizedMaterials } from '../../shared/models/categorized-materials';
import { CreateCategoryModalComponent } from '../../lists/create-category-modal/create-category-modal.component';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  MatMenuTrigger,
  MatMenu,
  MatMenuContent,
  MatMenuItem,
} from '@angular/material/menu';
import { NgClass } from '@angular/common';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-product-edit-menu',
  templateUrl: './product-edit-menu.component.html',
  styleUrls: ['./product-edit-menu.component.scss'],
  animations: [naooAnimations.rotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    NgClass,
    NaooStringDefaulterPipe,
    TranslateModule,
  ],
})
export class ProductEditMenuComponent implements OnInit, OnDestroy {
  private readonly MenuItemHeight = 50;
  private readonly MenuItemCountBeforeScroll = 5;
  private readonly MenuMaxHeight =
    this.MenuItemHeight * this.MenuItemCountBeforeScroll;

  @ViewChild('popoverMenu') private popoverMenu: ElementRef;

  @Input() customGuideId: string;
  @Input() product: ProductInfo;
  @Input() addToGuideLabel: string;
  @Input() menuTrigger: ElementRef;
  @Input() isDeleteOnly: boolean;
  @Input() isGfsCategory: boolean;
  @Output() actOnProductInMenu = new EventEmitter<ProductActionInfo>();
  @Output() disableButton = new EventEmitter<boolean>();

  isPanelExpanded = false;
  expandedMenuHeight = 0;
  menuAction: () => void;
  rotationState = 'default';

  destroyed$ = new Subject();
  categoryNames: Localized<string>[] = [];
  isOrderGuideEdit = false;

  constructor(
    public dialog: MatDialog,
    private naooStringDefaulter: NaooStringDefaulter,
    private customGuideFacade: CustomGuideFacade,
    private orderGuideFacade: OrderGuideFacade,
    private dialogService: DefaultDialogService,
    private listScrollService: ListScrollService,
    private localizationService: LocalizationService,
    private toastMessageService: ToastMessageService,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.customGuideId) {
      this.getCustomGuideCategoryNames()
        .pipe(startWith([]), takeUntil(this.destroyed$))
        .subscribe((customGuideCategories) => {
          this.categoryNames = customGuideCategories;
          this.changeDetectorRef.markForCheck();
        });
    } else {
      this.getOrderGuideCategoryNames()
        .pipe(startWith([]), takeUntil(this.destroyed$))
        .subscribe((orderGuideCategorizedMaterials) => {
          this.categoryNames = this.getOrderGuideCategories(
            orderGuideCategorizedMaterials,
          );
          this.changeDetectorRef.markForCheck();
        });
      this.isOrderGuideEdit = true;
    }
  }

  getOrderGuideCategories(
    orderGuideCategorizedMaterials: CategorizedMaterials[],
  ): Localized<string>[] {
    const customCategoryNames: Localized<string>[] = [];
    /*we only want to include custom categories when on order guide edit
      because a user cannot move to a GFS category (Sales, EComm, Unassigned)*/
    orderGuideCategorizedMaterials
      .filter(
        (categories) =>
          categories.categoryType === 'Custom' ||
          categories.categoryType === NaooConstants.unassignedCategory,
      )
      .forEach((category) => {
        customCategoryNames.push(category.categoryName);
      });
    return customCategoryNames;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  expandMoveToCategory(event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    const panelWillBeVisible = !this.isPanelExpanded;
    let scrollDelay = 0;
    if (panelWillBeVisible) {
      const didScroll = this.adjustScrollForMenuOpen();
      if (didScroll) {
        // if the browser needs to adjust it's scroll position
        // we should add a delay before we open the menu to wait
        // for that scroll operation to complete
        scrollDelay = 150;
      }
    }

    setTimeout(() => {
      if (this.isPanelExpanded) {
        this.closeExpansionItem();
      } else {
        this.openExpansionItem();
      }
    }, scrollDelay);
  }

  moveProductInMenuWithOrganizeForMe() {
    this.menuAction = () => {
      this.actOnProductInMenu.emit(<ProductActionInfo>{
        materialNumber: this.product.offeringId,
        categoryName: NaooConstants.unassignedCategory,
        action: ProductAction.move,
      });
    };
  }

  moveProductInMenu(categoryName: Localized<string>) {
    this.menuAction = () => {
      this.actOnProductInMenu.emit(<ProductActionInfo>{
        materialNumber: this.product.offeringId,
        categoryName: this.naooStringDefaulter.getString(
          categoryName,
          this.localizationService.currentLanguage,
        ),
        action: ProductAction.move,
      });
    };
  }

  openCreateCategoryModal() {
    this.dialog
      .open(CreateOrderGuideCategoryModalComponent, {
        id: NaooConstants.CREATE_CATEGORY_MODAL_ID,
        panelClass: 'order-guide-modal',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((categoryName) => {
        if (categoryName === undefined) {
          return;
        }
        this.orderGuideFacade.createOrderGuideCategory(categoryName);
        this.toastMessageService.showToastMessage(
          this.localizationService.instant('LISTS.TOAST_CREATE_CATEGORY', {
            categoryName,
          }),
        );
      });
  }

  openCreateCustomGuideCategoryModal() {
    this.dialog
      .open(CreateCategoryModalComponent, {
        id: NaooConstants.CREATE_CATEGORY_MODAL_ID,
        panelClass: 'custom-guide-modal',
        disableClose: true,
        data: {
          customGuideId: this.customGuideId,
        },
      })
      .afterClosed()
      .subscribe((categoryName) => {
        if (categoryName === undefined) {
          return;
        }

        this.customGuideFacade.createCustomGuideCategory(
          this.customGuideId,
          categoryName,
        );

        this.customGuideFacade.moveCustomGuideMaterials(
          this.customGuideId,
          this.product.offeringId,
          1,
          0,
        );

        this.toastMessageService.showToastMessage(
          this.localizationService.instant('LISTS.TOAST_CREATE_CATEGORY', {
            categoryName,
          }),
        );
      });
  }

  openRemoveModal() {
    const removeModal = this.dialogService.twoButtonModal(
      'remove-product-from-guide-legacy',
      'LISTS.REMOVE_PRODUCT_MODAL.TITLE',
      'LISTS.REMOVE_PRODUCT_MODAL.REMOVE',
      'CANCEL.TEXT',
      () => {
        this.removeItem();
      },
      () => {},
      true,
      true,
    );

    removeModal.afterClosed().subscribe(() => {
      this.disableButton.emit(true);
    });
  }

  onMenuClosed() {
    this.closeExpansionItem();
    if (this.menuAction) {
      this.menuAction();
      this.menuAction = null;
    }
  }

  private getCustomGuideCategoryNames(): Observable<Localized<string>[]> {
    return this.customGuideFacade.getCustomGuideRecord(this.customGuideId).pipe(
      map((customGuide) =>
        customGuide.categories.filter(
          (category) =>
            category.materials
              .map((material) => material.materialNumber)
              .indexOf(this.product.offeringId) === -1,
        ),
      ),
      map((categories) => categories.map((category) => category.name)),
    );
  }

  private getOrderGuideCategoryNames(): Observable<CategorizedMaterials[]> {
    return this.orderGuideFacade
      .getLoadedCategorizedOrderGuide()
      .pipe(
        map((orderGuide) =>
          orderGuide.categorizedMaterials.filter(
            (category) =>
              category.items
                .map((item) => item.material.materialNumber)
                .indexOf(this.product.offeringId) === -1,
          ),
        ),
      );
  }

  private removeItem() {
    this.actOnProductInMenu.emit({
      materialNumber: this.product.offeringId,
      action: ProductAction.remove,
    });
    this.disableButton.emit(false);
  }

  private closeExpansionItem() {
    this.isPanelExpanded = false;
    this.rotationState = 'default';
    this.expandedMenuHeight = 0;
    this.changeDetectorRef.markForCheck();
  }

  private openExpansionItem() {
    this.isPanelExpanded = true;
    this.rotationState = '180';
    this.expandedMenuHeight = this.computeMenuHeightForItemCount(
      this.categoryNames.length,
    );
    this.changeDetectorRef.markForCheck();
  }

  private computeMenuHeightForItemCount(itemCount: number): number {
    if (this.isOrderGuideEdit) {
      itemCount = itemCount + 1; //need to add 1 to account for 'Create new category'
      if (!this.isGfsCategory) {
        itemCount = itemCount + 1; //need to add another 1 to account for 'Organize for me' options when not in a GFS Category
      }
    }
    const count = itemCount;
    return count > this.MenuItemCountBeforeScroll
      ? this.MenuMaxHeight
      : this.MenuItemHeight * count;
  }

  private amountMenuWillBeOffScreen(willOpenUpwards: boolean): number {
    if (!this.popoverMenu || !this.popoverMenu.nativeElement) {
      return 0;
    }

    const sizeIncreaseUponExpansion = this.computeMenuHeightForItemCount(
      this.categoryNames.length,
    );

    const { top, bottom } =
      this.popoverMenu.nativeElement.getBoundingClientRect();

    if (willOpenUpwards) {
      const expandedTopPosition = top - sizeIncreaseUponExpansion;
      if (expandedTopPosition < 0) {
        return expandedTopPosition;
      }
    } else {
      const expandedBottomPosition = bottom + sizeIncreaseUponExpansion;
      if (expandedBottomPosition > window.innerHeight) {
        return expandedBottomPosition - window.innerHeight;
      }
    }

    return 0;
  }

  private menuDidOpenUpwards(): boolean {
    if (!this.popoverMenu || !this.popoverMenu.nativeElement) {
      return false;
    }
    const menuRect = this.popoverMenu.nativeElement.getBoundingClientRect();
    if (!this.menuTrigger || !this.menuTrigger.nativeElement) {
      return menuRect.top < 0;
    }

    const buttonRect = this.menuTrigger.nativeElement.getBoundingClientRect();
    return menuRect.top < buttonRect.top;
  }

  private adjustScrollForMenuOpen(): boolean {
    const willOpenUpwards = this.menuDidOpenUpwards();
    const overlap = this.amountMenuWillBeOffScreen(willOpenUpwards);
    const willScroll = overlap !== 0;
    if (willScroll) {
      const additionalPadding = willOpenUpwards ? -10 : 10;
      this.listScrollService.scrollByOptions({
        top: overlap + additionalPadding,
        behavior: 'smooth',
      });
    }

    return willScroll;
  }
}
