import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '../../shared/models/order-confirmation';
import { OrderItemRowComponent } from '../../order-item-row/order-item-row.component';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NaooCurrencyPipe } from '../../../shared/pipes/naoo-currency.pipe';

@Component({
  selector: 'naoo-standard-orders',
  templateUrl: './stock-orders.component.html',
  styleUrls: ['./stock-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    OrderItemRowComponent,
    NaooStringDefaulterPipe,
    TranslateModule,
    NaooCurrencyPipe,
  ],
})
export class StockOrdersComponent {
  @Input() stockOrder: Order;
  @Input() orderIndex: number;
  @Input() isSplitOrder: boolean;

  get orderIndexNumber() {
    return this.orderIndex + 1;
  }
}
