import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CartActionBarContentComponent } from './cart-action-bar-content/cart-action-bar-content.component';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import {
  CartActionBarButton,
  CartActionBarWidths,
} from './model/cart-action-bar';
import { ActiveCartSummary } from '../shared/models/active-cart-summary';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { AsyncPipe } from '@angular/common';

const OrderedCartActionButtons = [
  CartActionBarButton.SortBy,
  CartActionBarButton.AddToCustomGuide,
  CartActionBarButton.SaveCart,
  CartActionBarButton.Print,
  CartActionBarButton.EmptyCart,
];

@Component({
  selector: 'naoo-cart-action-bar-container',
  templateUrl: './cart-action-bar-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CartActionBarContentComponent, AsyncPipe],
})
export class CartActionBarContainerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() activeCartSummary: ActiveCartSummary;
  @Input() activeCartContentWidth: number;
  @Input() isMobile: boolean;
  @Input() disablePrint: boolean;

  @ViewChild('content', { static: true })
  actionBar: CartActionBarContentComponent;

  readonly addToCustomGuideId = CartActionBarButton.AddToCustomGuide;
  readonly printId = CartActionBarButton.Print;
  readonly saveCartId = CartActionBarButton.SaveCart;
  readonly emptyCartId = CartActionBarButton.EmptyCart;
  readonly sortById = CartActionBarButton.SortBy;

  private readonly windowPadding = 80;
  private lastTimeoutId: number = undefined;
  private destroyed$ = new Subject<void>();

  private hiddenButtons: { id: CartActionBarButton; width: number }[] = [];
  private visibleButtons = [...OrderedCartActionButtons];

  visibleButtons$ = new BehaviorSubject<CartActionBarButton[]>(
    this.visibleButtons,
  );
  hiddenButtons$ = new BehaviorSubject<CartActionBarButton[]>([]);

  constructor(
    private localizationService: LocalizationService,
    private _window: Window,
  ) {}

  ngOnInit() {
    fromEvent(this._window, 'resize')
      .pipe(debounceTime(100), startWith(undefined), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.onResize();
      });

    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.onResize());
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.hasChanged(changes.isMobile) ||
      this.hasChanged(changes.activeCartContentWidth)
    ) {
      this.onResize();
    }
  }

  get currentLanguage() {
    return this.localizationService.currentLanguage;
  }

  onResize() {
    if (this.isMobile) {
      this.visibleButtons = [...OrderedCartActionButtons];
      this.hiddenButtons = [];
      this.emitButtonChanges();
    } else {
      if (this.lastTimeoutId !== undefined) {
        this._window.clearTimeout(this.lastTimeoutId);
      }
      const widths = this.calculateWidths();
      this.lastTimeoutId = this._window.setTimeout(() => {
        this.resizeActionBar(widths);
      });
    }
  }

  calculateWidths(): CartActionBarWidths {
    return {
      activeCartContentWidth: this.activeCartContentWidth,
      windowWidth: this._window.innerWidth - this.windowPadding,
      actionBarContainer:
        this.actionBar.actionBarContainer.nativeElement.clientWidth,
      menuButton: this.getWidth(this.actionBar.menuButton),
      actionsButtons: {
        [this.addToCustomGuideId]: this.getWidth(
          this.actionBar.addToCustomGuideButton,
        ),
        [this.printId]: this.getWidth(this.actionBar.printButton),
        [this.saveCartId]: this.getWidth(this.actionBar.saveCartButton),
        [this.emptyCartId]: this.getWidth(this.actionBar.emptyButton),
        [this.sortById]: this.getWidth(this.actionBar.sortByButton),
      },
    };
  }

  private hasChanged(change: SimpleChange): boolean {
    return !!change && change.previousValue !== change.currentValue;
  }

  private getWidth(elementRef: ElementRef) {
    if (elementRef) {
      const computedStyle = this._window.getComputedStyle(
        elementRef.nativeElement,
      );

      let width = elementRef.nativeElement.offsetWidth;
      width += parseFloat(computedStyle.marginLeft);
      width += parseFloat(computedStyle.marginRight);
      return width;
    } else {
      return 0;
    }
  }

  private resizeActionBar(widths: CartActionBarWidths) {
    if (this.canButtonBeRemoved(widths)) {
      this.removeButton(widths);
    } else if (this.canButtonBeAdded(widths)) {
      this.addButton();
    }
  }

  private canButtonBeRemoved(widths: CartActionBarWidths) {
    return this.isOverflowing(widths) && this.visibleButtons.length > 0;
  }

  private canButtonBeAdded(widths: CartActionBarWidths) {
    const buttonToAdd = this.hiddenButtons[this.hiddenButtons.length - 1];

    if (!this.isOverflowing(widths) && !!buttonToAdd) {
      return this.getRemainingWidth(widths) >= buttonToAdd.width;
    } else {
      return false;
    }
  }

  private isOverflowing(widths: CartActionBarWidths): boolean {
    return widths.activeCartContentWidth > widths.windowWidth;
  }

  private getRemainingWidth(widths: CartActionBarWidths): number {
    const visibleButtonsWidth = this.visibleButtons
      .map((id) => this.getButtonWidth(widths, id))
      .reduce((previous, current) => previous + current, 0);

    let remainingWidth = widths.actionBarContainer - visibleButtonsWidth;

    const isLastButton = this.hiddenButtons.length === 1;
    if (!isLastButton) {
      remainingWidth -= widths.menuButton;
    }

    return remainingWidth;
  }

  private getButtonWidth(
    widths: CartActionBarWidths,
    id: CartActionBarButton,
  ): number {
    return widths.actionsButtons[id];
  }

  private addButton() {
    const buttonToAdd = this.hiddenButtons.pop();
    this.visibleButtons.push(buttonToAdd.id);
    this.emitButtonChanges();
  }

  private removeButton(widths: CartActionBarWidths) {
    const popped = this.visibleButtons.pop();
    this.hiddenButtons.push({
      id: popped,
      width: this.getButtonWidth(widths, popped),
    });
    this.emitButtonChanges();

    if (this.hiddenButtons.length === 1) {
      this.onResize();
    }
  }

  private emitButtonChanges() {
    this.visibleButtons$.next(this.visibleButtons);
    this.hiddenButtons$.next(
      this.hiddenButtons.map((hiddenButton) => hiddenButton.id).reverse(),
    );
  }
}
