import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  MaterialListStyle,
  MaterialRowContext,
} from '../../../core/store/material-row/models/material-row';
import { MaterialListRow } from '../../../material-list/models/material-list';
import { ModalHeaderComponent } from '../../../shared/modal-header/modal-header.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MaterialListComponent } from '../../../material-list/material-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-warning-content',
  templateUrl: './material-warning-content.component.html',
  styleUrls: ['./material-warning-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalHeaderComponent,
    MatProgressBar,
    MaterialListComponent,
    TranslateModule,
  ],
})
export class MaterialWarningContentComponent {
  readonly context = MaterialRowContext.Substitutes;
  readonly listStyle = MaterialListStyle.List;

  @Input() numberOfSalesCriticalItemsWarnings: number;
  @Input() salesCriticalItemsRows: MaterialListRow[];
  @Output() closeModalEmitter = new EventEmitter();

  closeModal(): void {
    this.closeModalEmitter.emit();
  }
}
