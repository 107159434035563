import { Component, Input, ViewChild } from '@angular/core';
import { LogoutService } from '../../../shared/services/logout/logout.service';
import { Router } from '@angular/router';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { map } from 'rxjs/operators';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { CustomerBrand } from '../../../core/services/session/models/session-record';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageMenuComponent } from '../../../shared/language-menu/language-menu.component';

@Component({
  selector: 'naoo-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatIcon,
    MatMenuModule,
    AsyncPipe,
    TranslateModule,
    LanguageMenuComponent,
  ],
})
export class AccountMenuComponent {
  @Input() isDisabled: boolean;
  @Input() customerBrand: CustomerBrand;
  @Input() isOffline: boolean;

  @ViewChild(LanguageMenuComponent)
  languageMenuComponent: LanguageMenuComponent;

  isCustomerSelected$ = this.sessionFacade
    .getLoadedSession()
    .pipe(map((session) => !!session.activeCustomer));
  isMenuOpen: boolean = false;

  constructor(
    private router: Router,
    private logoutService: LogoutService,
    private sessionFacade: SessionFacade,
    private dialogService: CustomDialogService,
  ) {}

  languageMenuButtonPressed(event: Event) {
    event.preventDefault();
    this.languageMenuComponent.toggleMenu();
  }

  navigateToPreferences() {
    this.router.navigateByUrl('/preferences');
  }

  showSupportDialog() {
    this.dialogService.supportContactModal(this.customerBrand);
  }

  logOut() {
    this.logoutService.logout();
  }
}
