import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExpressDeliveryWindow } from '../../../../../../../core/services/express-schedules/models/express-schedule-record';
import { DateService } from '../../../../../../../shared/services/date/date.service';
import { Moment } from 'moment';
import { TranslateModule } from '@ngx-translate/core';

enum DisplayOption {
  PartialOrder = 'PARTIAL_ORDER',
  CompleteOrder = 'COMPLETE_ORDER',
  SplitOrderOptions = 'SPLIT_ORDER_OPTIONS',
}

@Component({
  selector: 'naoo-cart-review-store-option',
  templateUrl: './cart-review-store-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class CartReviewStoreOptionComponent {
  @Input() isFirst: boolean = false;
  @Input() isSinglePickup: boolean = true;
  @Input() isAvailableDateValid: boolean = true;
  @Input() customerTimeZone: string;
  @Input() expressDate: ExpressDeliveryWindow;
  @Input() pickupDate: Moment;

  constructor(private dateService: DateService) {}

  get displayOption(): string {
    let option: DisplayOption;
    if (this.isSinglePickup) {
      option =
        this.isFirst && this.isAvailableDateValid
          ? DisplayOption.PartialOrder
          : DisplayOption.CompleteOrder;
    } else {
      option = DisplayOption.SplitOrderOptions;
    }
    return `CART_REVIEW.STORE_PICKUP_ITEMS.NOT_AVAILABLE.${option}`;
  }

  get storeDate(): string | undefined {
    return this.dateService.getStoreDate(
      this.customerTimeZone,
      this.expressDate,
      this.pickupDate,
    );
  }
}
