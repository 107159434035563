<div class="attribute-container">
  @for (attribute of attributes; track attribute) {
    <ng-container
      [ngTemplateOutletContext]="{
        attribute,
      }"
      [ngTemplateOutlet]="section"
    >
    </ng-container>
  }
</div>

<ng-template #bodyForDescription>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (shouldRenderDescription(material)) {
        <div class="section-content">
          @for (
            paragraph of getDetails(material).sellingParagraphs;
            track paragraph
          ) {
            <span class="selling-paragraph">
              {{ paragraph }}
              <br />
            </span>
          }
          <ul>
            @for (
              bullet of getDetails(material).sellingBulletPoints;
              track bullet
            ) {
              <li class="selling-bullet">
                {{ bullet }}
              </li>
            }
          </ul>
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForPricePerWeight>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (shouldRenderPricePerWeight(material)) {
        <div class="section-content">
          {{
            getDetails(material).price
              | naooprice: getDetails(material).weightUom
          }}
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForIngredients>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (getDetails(material)?.ingredients) {
        <div class="section-content">
          {{ getDetails(material).ingredients }}
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForPackagingAndStorage>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (getDetails(material)) {
        <div class="section-content">
          @if (getDetails(material)?.baseUomWeight) {
            <div class="weight">
              <div class="packaging-field-header">
                {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.WEIGHT' | translate }}
              </div>
              <span>{{ getDetails(material).baseUomWeight | naooweight }}</span>
            </div>
          }
          @if (getDimensions(material)) {
            <div class="dimensions">
              <div class="packaging-field-header">
                {{
                  'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS' | translate
                }}
              </div>
              <span>
                {{
                  'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_IN'
                    | translate
                      : { dimension: getDimensions(material).dimensionsIn }
                }}
                <br />
                {{
                  'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.DIMENSIONS_CM'
                    | translate
                      : { dimension: getDimensions(material).dimensionsCm }
                }}
              </span>
            </div>
          }
          @if (getDetails(material)?.packagingStorage) {
            <div class="packaging">
              <div class="packaging-field-header">
                {{ 'PRODUCT_DETAILS.PRODUCT_DIMENSIONS.PACKAGING' | translate }}
              </div>
              <span>{{ getDetails(material).packagingStorage }}</span>
            </div>
          }
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForBrand>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (getDetails(material)?.brand) {
        <div class="section-content">
          {{ getDetails(material).brand }}
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForAttributes>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (getDetails(material)?.attributes?.length) {
        <div class="section-content">
          <ul>
            @for (a of getDetails(material).attributes; track a) {
              <li>
                {{ a }}
              </li>
            }
          </ul>
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForNutritionFacts>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (shouldRenderNutritionInfo(material)) {
        <div class="section-content">
          @if (this.getDetails(material)?.nutritionalInfo) {
            <naoo-nutrition-details
              [nutritionDetails]="getDetails(material).nutritionalInfo"
              [hideBorder]="true"
            >
            </naoo-nutrition-details>
          }
          @if (this.getDetails(material)?.nutritionLabel) {
            <naoo-nutrition-facts-container
              [materialNumber]="material"
              [nutritionLabel]="getDetails(material).nutritionLabel"
            >
            </naoo-nutrition-facts-container>
          }
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForAllergens>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (getDetails(material)?.allergenInfo) {
        <div class="section-content">
          <naoo-allergen-details
            [allergenInfo]="getDetails(material)?.allergenInfo"
            [hideBorder]="true"
          >
          </naoo-allergen-details>
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #bodyForServingSuggestions>
  @for (material of materialNumbers; track material) {
    <div class="section-wrapper">
      @if (getDetails(material)?.servingSuggestions) {
        <div class="section-content">
          {{ getDetails(material).servingSuggestions }}
        </div>
      } @else {
        <div class="section-content">&ndash;</div>
      }
    </div>
  }
</ng-template>

<ng-template #section let-attribute="attribute">
  @if (shouldRenderExpansionPanel(attribute)) {
    <naoo-generic-expansion-panel
      [headerTitle]="attribute"
      [startExpanded]="shouldStartExpanded(attribute)"
    >
      <div
        class="body-content"
        [ngClass]="getClassForComparisonAttribute(attribute)"
      >
        @switch (attribute) {
          @case (ComparisonAttribute.Description) {
            <ng-container
              [ngTemplateOutlet]="bodyForDescription"
            ></ng-container>
          }
          @case (ComparisonAttribute.PricePerWeight) {
            <ng-container
              [ngTemplateOutlet]="bodyForPricePerWeight"
            ></ng-container>
          }
          @case (ComparisonAttribute.Ingredients) {
            <ng-container
              [ngTemplateOutlet]="bodyForIngredients"
            ></ng-container>
          }
          @case (ComparisonAttribute.PackagingAndStorage) {
            <ng-container
              [ngTemplateOutlet]="bodyForPackagingAndStorage"
            ></ng-container>
          }
          @case (ComparisonAttribute.Brand) {
            <ng-container [ngTemplateOutlet]="bodyForBrand"></ng-container>
          }
          @case (ComparisonAttribute.Attributes) {
            <ng-container [ngTemplateOutlet]="bodyForAttributes"></ng-container>
          }
          @case (ComparisonAttribute.NutritionFacts) {
            <ng-container
              [ngTemplateOutlet]="bodyForNutritionFacts"
            ></ng-container>
          }
          @case (ComparisonAttribute.Allergens) {
            <ng-container [ngTemplateOutlet]="bodyForAllergens"></ng-container>
          }
          @case (ComparisonAttribute.ServingSuggestions) {
            <ng-container
              [ngTemplateOutlet]="bodyForServingSuggestions"
            ></ng-container>
          }
        }
      </div>
    </naoo-generic-expansion-panel>
    <br />
  }
</ng-template>
