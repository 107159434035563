import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { StoreOrderPaymentOptions } from '../../../../core/store/cart-review/cart-review.selectors';
import { CartReviewFacade } from '../../../../core/store/cart-review/cart-review.facade';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { Router } from '@angular/router';
import { SelectableButtonListComponent } from '../../../selectable-button-list/selectable-button-list.component';
import { SelectableButtonType } from '../../../selectable-button/selectable-button.component';
import { CreditPaymentComponent } from '../credit-payment/credit-payment.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-add-payment-content',
  templateUrl: './add-payment-content.component.html',
  styleUrls: ['./add-payment-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SelectableButtonListComponent,
    CreditPaymentComponent,
    TranslateModule,
  ],
})
export class AddPaymentContentComponent {
  @Input() paymentDetails: StoreOrderPaymentOptions;
  @ViewChild('buttonList', { static: true })
  buttonList: SelectableButtonListComponent;

  get payWithCreditSelected(): boolean {
    return (
      this.paymentDetails?.paymentChoices[this.selectedIndex]?.buttonType ===
      SelectableButtonType.CREDIT_PAYMENT
    );
  }

  get canPayWithCreditCard(): boolean {
    return !!(
      this.paymentDetails?.checkoutId && this.paymentDetails?.paymentAvailable
    );
  }

  get creditPaymentDisabled(): boolean {
    return this.paymentDetails?.paymentChoices.some(
      (button) =>
        button.buttonType === SelectableButtonType.CREDIT_PAYMENT &&
        !!button.disabled,
    );
  }

  get selectedIndex(): number {
    return this.buttonList?.selectedIndex;
  }

  constructor(
    private cartReviewFacade: CartReviewFacade,
    private router: Router,
  ) {}

  buttonSelectionChanged() {
    if (this.canPayWithCreditCard) {
      this.cartReviewFacade.saveSelectedCreditCardOption(
        this.payWithCreditSelected,
      );
    }
  }

  timeoutReached() {
    this.router.navigate([NaooConstants.CART_PATH]);
  }
}
