import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  MaterialLine,
  MaterialRow,
  MaterialRowContext,
  MaterialRowStatus,
} from 'src/app/core/store/material-row/models/material-row';
import { trigger, transition, style, animate } from '@angular/animations';
import { naooAnimations } from 'src/app/shared/animations/animations';
import { MaterialWarningType } from '../../../core/store/material-warning/material-warning';
import { MaterialAvailability } from '../../../shared/models/material-availability';
import { FulfillmentType } from '../../../core/services/cart/models/cart-record';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MaterialImageComponent } from '../shared/material-image/material-image.component';
import { MaterialStoreStockComponent } from '../shared/material-store-stock/material-store-stock.component';
import { MaterialInfoComponent } from '../shared/material-info/material-info.component';
import { MaterialOptionsMenuComponent } from '../shared/material-options-menu/material-options-menu.component';
import { MaterialOrderingComponent } from '../shared/material-ordering/material-ordering.component';
import { MaterialRowFooterComponent } from '../shared/material-row-footer/material-row-footer.component';
import { MaterialUnorderableComponent } from '../shared/material-unorderable/material-unorderable.component';
import { MaterialSimilarItemsComponent } from '../shared/material-similar-items/material-similar-items.component';
import { MaterialSkeletonComponent } from '../shared/material-skeleton/material-skeleton.component';
import { MaterialFlagContainerComponent } from '../shared/material-flag-container/material-flag-container.component';

@Component({
  selector: 'naoo-material-row-grid-content',
  templateUrl: './material-row-grid-content.component.html',
  styleUrls: ['./material-row-grid-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
    trigger('fadeInOnly', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
    ]),
    naooAnimations.fadeAndSlide,
  ],
  standalone: true,
  imports: [
    NgClass,
    MaterialImageComponent,
    MaterialStoreStockComponent,
    MaterialInfoComponent,
    MaterialOptionsMenuComponent,
    NgTemplateOutlet,
    MaterialOrderingComponent,
    MaterialRowFooterComponent,
    MaterialUnorderableComponent,
    MaterialSimilarItemsComponent,
    MaterialSkeletonComponent,
    MaterialFlagContainerComponent,
  ],
})
export class MaterialRowGridContentComponent {
  @Input() materialRow: MaterialRow;
  @Input() isLast: boolean;
  @Input() isMobile: boolean;
  @Input() materialAvailability: MaterialAvailability;
  @Input() fulfillmentType: FulfillmentType;
  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();
  @Output() viewCommodityDetailsClicked = new EventEmitter();

  get isLoading(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Loading
    );
  }

  get isOK(): boolean {
    return (
      !!this.materialRow && this.materialRow.status === MaterialRowStatus.Ok
    );
  }

  get isUnorderable(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Unorderable
    );
  }

  // This appears to be unused, likely missing html code that needs to display unavailable items
  get isUnavailable(): boolean {
    return (
      !!this.materialRow &&
      this.materialRow.status === MaterialRowStatus.Unavailable
    );
  }

  get fullSize(): boolean {
    return (
      MaterialRowContext.OrderDetailsEditModal ===
      this.materialRow.materialRowOptions?.context
    );
  }

  get shouldShowRedBorder(): boolean {
    return this.isOverAllocatedAndNonModalContext();
  }

  private isOverAllocatedAndNonModalContext(): boolean {
    return (
      MaterialWarningType.OverAllocation ===
        this.materialRow?.materialOrdering?.materialWarning?.type &&
      MaterialRowContext.OverallocationModal !==
        this.materialRow.materialRowOptions?.context
    );
  }
}
