<div class="navigation-wrapper">
  <nav
    mat-tab-nav-bar
    disableRipple="true"
    mat-stretch-tabs="false"
    [tabPanel]="tabPanel"
  >
    <a
      class="mat-mdc-tab-link guides-tab"
      role="tab"
      [routerLink]="guidesLink.link"
      [ngClass]="{ focused: guidesLink.isActive }"
    >
      {{ guidesLink.text | translate }}
    </a>
    <naoo-generic-disableable-link
      [className]="
        'mat-mdc-tab-link ' +
        (categoriesLink.isActive ? 'focused' : '') +
        (isDisabled ? 'disabled' : '')
      "
      [routingUrl]="categoriesLink.link"
      [isDisabled]="isDisabled"
      role="tab"
    >
      {{ categoriesLink.text | translate }}
    </naoo-generic-disableable-link>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <div class="action-wrapper">
    <button
      id="ordersBtn"
      [attr.aria-label]="'MENU.ORDERS' | translate"
      class="mat-mdc-tab-link orders-button"
      [ngClass]="{ focused: isOrdersTab, disabled: isDisabled }"
      [matMenuTriggerFor]="myOrderMenu"
      (menuClosed)="isMenuOpen = false"
      (menuOpened)="isMenuOpen = true"
      [disabled]="isDisabled"
    >
      <mat-icon class="truck-icon" svgIcon="truck"></mat-icon>
      <span>{{ 'MENU.ORDERS' | translate }}</span>
      <mat-icon
        [ngClass]="{ 'rotate-arrow': isMenuOpen }"
        class="dropdown-arrow-icon"
        svgIcon="arrow-v2"
      ></mat-icon>
    </button>
    @if (!hideOrderTools) {
      <naoo-order-tools
        class="sub-header-action"
        [ngClass]="{ disabled: isDisabled || !(isCartLoaded$ | async) }"
        id="order-tools"
        [isDisabled]="isDisabled || !(isCartLoaded$ | async)"
      ></naoo-order-tools>
    }
  </div>
</div>

<mat-menu #myOrderMenu="matMenu" xPosition="before">
  <div class="subheader-menu">
    @for (link of myOrderLinks; track link.label) {
      <a
        class="subheader-menu-item"
        mat-menu-item
        [attr.aria-label]="link.label | translate"
        [routerLink]="link.link"
        [queryParams]="link.queryParams"
        [ngClass]="{
          active:
            !!link.queryParams &&
            !!currentUrl &&
            currentUrl.includes(link.queryParams.activeTab),
        }"
      >
        @if (link.label === goPointsLabel) {
          <naoo-go-points-logo
            [context]="'naoo-sub-header'"
          ></naoo-go-points-logo>
        } @else {
          {{ link.label | translate }}
        }
      </a>
    }
  </div>
</mat-menu>
