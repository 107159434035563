import { ProductDirection } from '../../../product-row/product-guide-edit/product-direction';
import { CategorizedMaterials } from '../../../shared/models/categorized-materials';

export function getIndexForDirection(
  originalIndex: number,
  arraySize: number,
  direction: ProductDirection,
): number {
  if (direction === ProductDirection.up && originalIndex > 0) {
    return originalIndex - 1;
  } else if (
    direction === ProductDirection.down &&
    originalIndex < arraySize - 1
  ) {
    return originalIndex + 1;
  }
  return -1;
}

export function getMaterialOrderIndex(
  categorizedMaterials: CategorizedMaterials,
  materialIndex: number,
) {
  // Need to account for hidden/unavailable items so use the order value which accounts for this
  const item = categorizedMaterials
    ? categorizedMaterials.items[materialIndex]
    : undefined;
  return item ? item.order : materialIndex;
}
