import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DecimalUtilities } from '../../shared/utilities/decimal-utilities';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';

@Component({
  selector: 'naoo-product-decimal-quantity-box',
  templateUrl: './product-decimal-quantity-box.component.html',
  styleUrls: ['./product-decimal-quantity-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ProductDecimalQuantityBoxComponent implements OnInit, OnDestroy {
  @Input() quantityBoxId: string;
  @ViewChild('box', { static: true }) box: ElementRef;
  @Input() qtyBoxLabel: string;

  @Input()
  set quantityValue(value: string | undefined) {
    if (typeof value === 'undefined') {
      return;
    }
    this.updateFieldQuantity(value);
  }

  get quantityValue(): string {
    return this.boxElement.value;
  }

  @Output()
  didLoseFocus = new EventEmitter<ProductDecimalQuantityBoxComponent>();
  @Output() quantityUpdated = new EventEmitter<string>();

  @Output() nextBox = new EventEmitter<string>();
  @Output() previousBox = new EventEmitter<string>();

  get boxElement(): HTMLInputElement {
    return <HTMLInputElement>this.box.nativeElement;
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private decimalUtilities: DecimalUtilities,
  ) {}

  ngOnInit() {
    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.boxElement.value = this.decimalUtilities.getLocalizedValue(
          this.quantityValue,
        );
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onKeyPress(event: KeyboardEvent): boolean {
    /**
     * Character code of 0 gets a pass here for keys like arrows and backspace
     * on browsers like FireFox that don't fire TextEvents.
     */
    const charCode = String.fromCharCode(event.charCode);
    if (!this.shouldAccept(charCode)) {
      event.preventDefault();
      return false;
    }
    // get all input text of the boxElement with the updated input character
    const inputText = this.boxElement.value + charCode;
    if (
      this.validateInput(inputText) ||
      this.handleNumberAndOnlyDecimal(inputText)
    ) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  /**
   * This gives us full coverage of event types for browser/OS coverage
   * @param {Event} event
   * @return {boolean}
   */
  onTextInput(event: InputEvent): boolean {
    if (!this.shouldAccept(event.data)) {
      event.preventDefault();
      return false;
    }
    // get all input text of the boxElement with the updated input character
    const inputText = this.boxElement.value + event.data;
    if (
      this.validateInput(inputText) ||
      this.handleNumberAndOnlyDecimal(inputText)
    ) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  focusWasLost() {
    this.validateAndChangeInputBy();
    this.didLoseFocus.emit(this);
  }

  private validateAndChangeInputBy() {
    const value = this.boxElement.value;
    if (this.updateFieldQuantity(value)) {
      this.quantityUpdated.emit(value);
    } else {
      this.quantityUpdated.emit('');
    }
  }

  focus(): void {
    this.box.nativeElement.focus();
    this.box.nativeElement.select();
  }

  suppressContextMenu($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  goToNextBox(): void {
    this.nextBox.emit(this.quantityValue);
  }

  goToPreviousBox(): void {
    this.previousBox.emit(this.quantityValue);
  }

  selectAllText(): void {
    this.boxElement.setSelectionRange(0, this.boxElement.value.length);
  }

  private updateFieldQuantity(value: string): boolean {
    value = this.parseValue(value);
    if (!this.validateInput(value)) {
      this.boxElement.value = '';
      return false;
    } else {
      this.boxElement.value = this.decimalUtilities.getLocalizedValue(value);
      return true;
    }
  }

  private shouldAccept(charCode: string): boolean {
    return /^(\d|(?:,|\.))+$/.test(charCode);
  }

  private validateInput(inputText: string): boolean {
    return /^\d{1,3}([.,]\d{1,2})?$/.test(inputText);
  }

  private handleNumberAndOnlyDecimal(inputText: string): boolean {
    return (
      /^\d{1,3}[.,]{1}$/.test(inputText) || /^[.,]{1}\d{0,2}$/.test(inputText)
    );
  }

  private parseValue(value: string): string {
    value = value.replace(',', '.');
    const parseValue = parseFloat(value);

    if (isNaN(parseValue)) {
      return '';
    }
    return parseValue.toString();
  }
}
