import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BannerAdsFacade } from '../../core/store/banner-ads/banner-ads.facade';
import {
  BannerAd,
  BannerAdCollection,
} from '../../core/store/banner-ads/banner-ads.state';
import { ScrollListenerService } from '../services/scroll-listener/scroll-listener.service';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { Router } from '@angular/router';
import { DOCUMENT, NgClass } from '@angular/common';

@Component({
  selector: 'naoo-banner-ad',
  templateUrl: './banner-ad.component.html',
  styleUrls: ['./banner-ad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class BannerAdComponent implements OnInit, OnDestroy {
  private readonly bannerAdContentSelector: string = 'naoo-banner-ad .content';

  @Output() bannersLoaded = new EventEmitter<boolean>();
  private destroyed$ = new Subject<void>();

  bannerAdCollection: BannerAdCollection;
  impressionAnalyticsSent: string[] = [];

  constructor(
    private bannerAdsFacade: BannerAdsFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private scrollListenerService: ScrollListenerService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private window: Window,
  ) {}

  ngOnInit() {
    this.getBannerData();
    this.scrollListenerService
      .getEvents()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.detectAdImpressions();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  detectAdImpressions() {
    const flatBannerAdsList: BannerAd[] = [].concat.apply(
      [],
      this.bannerAdCollection?.bannerAds,
    );
    const viewedBannerAds: BannerAd[] = [];
    const bannerAdElements = this.document.querySelectorAll(
      this.bannerAdContentSelector,
    );
    // adverts is a NodeList, not an array. IE11 does not support forEach on a NodeList, so a
    // for loop must be used
    for (let i = 0; i < bannerAdElements.length; i++) {
      const bannerAdElement = bannerAdElements[i];
      const bannerAd = flatBannerAdsList.find(
        (ad) => ad?.divName === bannerAdElement.id,
      );
      if (
        !!bannerAd &&
        !this.impressionAnalyticsSent.includes(bannerAd.divName) &&
        this.isHalfVisible(bannerAdElement)
      ) {
        this.impressionAnalyticsSent.push(bannerAd.divName);
        viewedBannerAds.push(bannerAd);
      }
    }

    if (viewedBannerAds.length > 0) {
      this.ecommerceAnalyticsFacade.trackPromotionViewEvent({
        bannerAds: viewedBannerAds,
      });
    }
  }

  checkIfAdsAreVisibleAfterLoading() {
    setTimeout(() => {
      this.detectAdImpressions();
    }, 500);
  }

  bannerClickEvent(bannerAd: BannerAd): void {
    this.ecommerceAnalyticsFacade.trackPromotionClickEvent({
      bannerAds: [bannerAd],
    });
    if (bannerAd.ctTargetUrl.toLocaleLowerCase().startsWith('http')) {
      this.window.open(bannerAd.ctTargetUrl, '_blank');
    } else {
      this.router.navigateByUrl(bannerAd.ctTargetUrl);
    }
  }

  getWCAGAriaLabel(bannerAd: BannerAd): string {
    return bannerAd.cta + '. ' + bannerAd.headline;
  }

  private getBannerData() {
    this.checkIfAdsAreVisibleAfterLoading();
    this.bannerAdsFacade
      .getLoadedBannerAds()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((bannerAdCollection: BannerAdCollection) => {
        this.bannerAdCollection = bannerAdCollection;
        this.bannersLoaded.emit(
          this.getTotalBannerCount(bannerAdCollection) > 0,
        );
        this.changeDetector.markForCheck();
      });
  }

  private getTotalBannerCount(bannerAdsCollection: BannerAdCollection): number {
    let count = 0;
    const bannerAds = bannerAdsCollection.bannerAds;
    bannerAds.forEach((adSection) => {
      adSection.forEach((ad) => {
        if (ad) {
          count++;
        }
      });
    });
    return count;
  }

  public isRowEmpty(bannerAds: BannerAd[]): boolean {
    return bannerAds.every((bannerAd) => !bannerAd);
  }

  private isHalfVisible(elm: Element): boolean {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      this.document.documentElement.clientHeight,
      this.window.innerHeight,
    );
    return viewHeight - rect.top >= rect.height / 2;
  }
}
