<div>
  <naoo-hero-images [hostComponent]="'catalog-v4'"></naoo-hero-images>
  <div class="naoo-hero-page-container">
    <h1 class="naoo-heading">{{ 'CATEGORIES.TITLE' | translate }}</h1>
    <div class="categories-container">
      @for (category of categories; track category) {
        <naoo-product-group [category]="category"></naoo-product-group>
      }
    </div>
  </div>
</div>
