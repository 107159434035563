@if (!!section) {
  <mat-expansion-panel
    class="generic-help-panel-content"
    [ngClass]="{ first: isFirst, last: isLast }"
  >
    <mat-expansion-panel-header
      [expandedHeight]="'auto'"
      [collapsedHeight]="'auto'"
    >
      <mat-panel-title>
        {{ section.title | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    @for (paragraph of section.paragraphs; track paragraph) {
      <div class="paragraph-content">
        <p class="paragraph">{{ paragraph | translate }}</p>
      </div>
    }
    @if (!!section.imageDetails) {
      <div class="list-items-content">
        <div class="ordered-list">
          @for (listItem of section.imageDetails.listItems; track listItem) {
            <div class="list-item">{{ listItem | translate }}</div>
          }
        </div>
        @if (!!section.imageDetails.image) {
          <div class="help-img">
            <img
              [alt]="section.imageDetails.image.altText | translate"
              [src]="section.imageDetails.image.src | translate"
              [height]="section.imageDetails.image.height"
              [width]="section.imageDetails.image.width"
            />
          </div>
        }
      </div>
    }
  </mat-expansion-panel>
}
