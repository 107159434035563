import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BannerAdsState } from './banner-ads.state';
import { BannerAdsTransformer } from './banner-ads-transformer';

const selectBannerAdsState = createFeatureSelector<BannerAdsState>('bannerAds');

export const selectBannerAds = createSelector(
  selectBannerAdsState,
  (state: BannerAdsState) =>
    BannerAdsTransformer.transformBannerAdsToCollection(state.bannerAds),
);
