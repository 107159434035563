@if (
  isShowingMobileFilters && !!searchResult && !!searchResult.defaultFilters
) {
  <naoo-mobile-filter-list
    [defaultFilters]="searchResult.defaultFilters"
    [taxonomy]="searchResult.taxonomy"
    (closeFilterList)="closeMobileFilters()"
  >
  </naoo-mobile-filter-list>
} @else {
  <div class="search-results">
    <mat-drawer-container [@.disabled]="!isDrawerAnimationEnabled">
      @if (hasResults) {
        <mat-drawer
          [opened]="sideNavOpen"
          mode="side"
          [style.marginTop.px]="headerHeight$ | async"
          [ngClass]="{ closed: !sideNavOpen }"
          (openedChange)="drawerStateChangeCompleted()"
        >
          @if (!!searchResult) {
            <naoo-search-filters
              class="desktop-filters"
              [defaultFilters]="searchResult.defaultFilters"
              [taxonomy]="searchResult.taxonomy"
              [activeDefaultFilters]="searchResult.activeDefaultFilters"
            ></naoo-search-filters>
          }
        </mat-drawer>
      }
      <mat-drawer-content>
        @if (!isMobile && hasResults) {
          <button
            class="drawer-button"
            [ngClass]="{ 'drawer-closed': drawerClosedAndFinishedAnimating }"
            (click)="toggleDrawer()"
            attr.aria-label="{{ expansionAriaText | translate }}"
          >
            <mat-icon
              [ngClass]="{ 'closed-rotation': !sideNavOpen }"
              svgIcon="arrow-v2"
              class="arrow-icon"
            >
            </mat-icon>
          </button>
        }
        @if (!!searchResult?.orderGuideFilter) {
          <mat-tab-group
            [disableRipple]="true"
            [mat-stretch-tabs]="false"
            [selectedIndex]="activeTabIndex$ | async"
            (selectedIndexChange)="selectedIndexChanged($event)"
          >
            <mat-tab
              [label]="
                allResultsCount
                  ? ('SEARCH.ALL_RESULTS_COUNT'
                    | translate: { count: allResultsCount })
                  : ('SEARCH.ALL_RESULTS' | translate)
              "
              [aria-label]="
                allResultsCount
                  ? ('SEARCH.ALL_RESULTS_COUNT'
                    | translate: { count: allResultsCount })
                  : ('SEARCH.ALL_RESULTS' | translate)
              "
            >
              <ng-template matTabContent>
                <naoo-search-content
                  [searchResult]="searchResult"
                  [isMobile]="isMobile"
                  [shouldRenderSearchList]="shouldRenderSearchList"
                  [hasNoResults]="!hasResults"
                  [isOrderGuideOptionSelected]="isOrderGuideOptionSelected"
                  (openFilterModalEvent)="openFilterModal()"
                  (endOfListEvent)="handleEndOfList()"
                >
                </naoo-search-content>
              </ng-template>
            </mat-tab>
            <mat-tab
              [label]="
                orderGuideCount
                  ? ('SEARCH.ORDER_GUIDE_COUNT'
                    | translate: { count: orderGuideCount })
                  : ('SEARCH.ORDER_GUIDE_ONLY' | translate)
              "
              [aria-label]="
                orderGuideCount
                  ? ('SEARCH.ORDER_GUIDE_COUNT'
                    | translate: { count: orderGuideCount })
                  : ('SEARCH.ORDER_GUIDE_ONLY' | translate)
              "
            >
              <ng-template matTabContent>
                <naoo-search-content
                  [searchResult]="searchResult"
                  [isMobile]="isMobile"
                  [shouldRenderSearchList]="shouldRenderSearchList"
                  [hasNoResults]="!hasResults"
                  [isOrderGuideOptionSelected]="isOrderGuideOptionSelected"
                  [isOrderGuideTab]="true"
                  (openFilterModalEvent)="openFilterModal()"
                  (endOfListEvent)="handleEndOfList()"
                >
                </naoo-search-content>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        } @else {
          <naoo-search-content
            [searchResult]="searchResult"
            [isMobile]="isMobile"
            [shouldRenderSearchList]="shouldRenderSearchList"
            [hasNoResults]="!hasResults"
            [isOrderGuideOptionSelected]="isOrderGuideOptionSelected"
            (openFilterModalEvent)="openFilterModal()"
            (endOfListEvent)="handleEndOfList()"
          >
          </naoo-search-content>
        }
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
}
