import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderItem } from '../../shared/models/order-confirmation';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { ErrorMaterialsHeaderComponent } from './error-materials-header/error-materials-header.component';
import { OrderIssueRowComponent } from '../order-issue-row/order-issue-row.component';
import { UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-error-materials',
  templateUrl: './error-materials.component.html',
  styleUrls: ['./error-materials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIcon,
    ErrorMaterialsHeaderComponent,
    OrderIssueRowComponent,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class ErrorMaterialsComponent {
  @Input() errorMaterials: OrderItem[];
  @Input() materialRelatedRouteDate?: Date;
}
