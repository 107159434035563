@if (shouldRenderMobileActionsList) {
  <naoo-guides-mobile-actions-list
    [sideBar]="customGuide.sideBar"
    [preferredView]="customGuide.preferredView"
    [isOffline]="customGuide.isOffline"
    [isParEnabled]="customGuide.header.actionBar.isParEnabled"
    (closeMobileActions)="toggleMobileActions()"
    (groupByChange)="handleGroupByChanged($event)"
    (sortByChange)="handleSortByChanged($event)"
    (clearInventoryQuantities)="handleClearInventoryQuantities()"
    (toggleParOrdering)="handleToggleParOrdering($event)"
  ></naoo-guides-mobile-actions-list>
} @else if (!!customGuide) {
  <div
    class="custom-guide-wrapper"
    [ngClass]="{ 'padding-left': shouldRenderEmptyGuide }"
  >
    @if (shouldRenderEmptyGuide) {
      <div class="empty-guide-wrapper">
        <naoo-guides-header-breadcrumb
          [name]="customGuide.header.name"
        ></naoo-guides-header-breadcrumb>
        <naoo-custom-guide-empty
          class="empty-guide"
          [customGuideName]="customGuide.header.name"
          [isOfflineMode]="customGuide.isOffline"
        >
        </naoo-custom-guide-empty>
      </div>
    } @else {
      <mat-drawer-container>
        <mat-drawer
          [opened]="sideNavOpen"
          mode="side"
          [ngClass]="{ closed: !sideNavOpen }"
          [style.marginTop.px]="headerHeight$ | async"
          (openedChange)="drawerStateChangeCompleted()"
        >
          <naoo-guides-side-bar
            [sideBar]="customGuide.sideBar"
            [isOffline]="customGuide.isOffline"
            (scrollToCategoryClick)="handleCategoryScroll($event)"
            (groupByChange)="handleGroupByChanged($event)"
            (sortByChange)="handleSortByChanged($event)"
          ></naoo-guides-side-bar>
        </mat-drawer>
        <mat-drawer-content>
          @if (!isMobile) {
            <button
              class="drawer-button"
              [ngClass]="{
                'drawer-closed': drawerClosedAndFinishedAnimating,
              }"
              (click)="toggleDrawer()"
              attr.aria-label="{{ expansionAriaText | translate }}"
            >
              <mat-icon
                svgIcon="arrow-v2"
                class="arrow-icon"
                [ngClass]="{ 'closed-rotation': !sideNavOpen }"
              ></mat-icon>
            </button>
          }
          <div class="custom-guide-content">
            <naoo-guides-header
              class="guides-header"
              [guideHeader]="customGuide.header"
              [preferredView]="customGuide.preferredView"
              [categoryHeaders]="customGuide.sideBar.categoryHeaders"
              [isOffline]="customGuide.isOffline"
              [printInput$]="printInput$"
              [exportInput$]="exportInput$"
              (openMobileActionsModal)="toggleMobileActions()"
              (scrollToCategory)="handleCategoryScroll($event)"
              (updateSearchText)="handleUpdateSearchText($event)"
              (updateMaterialView)="handleUpdateMaterialView($event)"
              (clearInventoryQuantities)="handleClearInventoryQuantities()"
              (toggleParOrdering)="handleToggleParOrdering($event)"
            >
              <div class="guide-header">
                <div class="guide-title-container">
                  <span class="guide-title">
                    {{ customGuide.header.name | stringdefaulter }}
                  </span>
                  <button
                    class="rename-guide-button secondary-button"
                    attr.aria-label="{{ 'LISTS.RENAME' | translate }}"
                    [ngClass]="{ disabled: customGuide.isOffline }"
                    [disabled]="customGuide.isOffline"
                    (click)="openRenameGuideModal()"
                  >
                    <mat-icon
                      class="icon"
                      [ngClass]="{ disabled: customGuide.isOffline }"
                      svgIcon="edit-icon"
                    ></mat-icon>
                  </button>
                </div>
                <span class="guide-material-count">
                  ({{ customGuide.header.count }}
                  {{ 'ORDER_GUIDE.PRODUCTS' | translate }})
                </span>
                <div class="edit-guide">
                  <button
                    class="secondary-button large-button edit-guide-button"
                    attr.aria-label="{{
                      'LISTS.EDIT_CUSTOM_GUIDE' | translate
                    }}"
                    (click)="editCustomGuide()"
                    [ngClass]="{ disabled: customGuide.isOffline }"
                    [disabled]="customGuide.isOffline"
                  >
                    {{ 'LISTS.EDIT_CUSTOM_GUIDE' | translate }}
                  </button>
                </div>
              </div>
              <div class="custom-guide-description">
                {{ 'LISTS.POPULATING_HELPER_TEXT' | translate }}
                <br />
                {{ 'LISTS.FOR_MORE_INFORMATION.TEXT' | translate }}
                <button
                  class="learn-more-link"
                  tabindex="0"
                  (click)="openLearnMoreModal()"
                >
                  {{ 'LISTS.FOR_MORE_INFORMATION.LINK' | translate }}
                </button>
              </div>
            </naoo-guides-header>
            @if (shouldRenderList) {
              <div class="list-content">
                <naoo-material-list
                  #materialList
                  [materialListItems]="customGuide.materialListRows"
                  [context]="listContext"
                  [listStyle]="customGuide.preferredView"
                  [customGuideId]="customGuide.id"
                  [isParEnabled]="customGuide.header.actionBar.isParEnabled"
                ></naoo-material-list>
              </div>
            } @else {
              <naoo-no-results
                [searchText]="customGuide.header.actionBar.searchText"
              ></naoo-no-results>
            }
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    }
  </div>
}
