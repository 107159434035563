export class OrderDetailsConstants {
  static readonly DELIVERED_ICON = 'delivered-icon';
  static readonly INVOICED_ICON = 'invoiced-icon-v1';
  static readonly SHIPPING_ICON = 'shipping-icon-v1';
  static readonly CLOCK_ICON = 'clock-icon-v1';
  static readonly SUCCESS_ICON = 'success-icon-v1';
  static readonly WARNING_ICON = 'warning-icon-v3';
  static readonly EXCEPTION_ICON = 'exception-icon-v1';

  static readonly FEDEX_URL =
    'https://www.fedex.com/apps/fedextrack/?tracknumbers=';
  static readonly UPS_URL = 'https://www.ups.com/track?tracknum=';
}
