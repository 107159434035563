<div class="form-container">
  <div class="row-container">
    <div class="form-inputs-container">
      <mat-icon class="phone-icon">phone_in_talk</mat-icon>
      <naoo-telephone-form-control
        [contactInfo]="notification?.contactInfo"
        (isValid)="isValidHandler($event)"
        (value)="valueHandler($event)"
        (keydown.enter)="save()"
        class="first-input"
      ></naoo-telephone-form-control>
    </div>
    <div class="language-dropdown">
      <notification-language-dropdown
        [selectedLanguage]="languageSelected()"
        (currentLangChange)="setUserLanguage($event)"
      ></notification-language-dropdown>
    </div>
  </div>
  <naoo-subscriptions-form
    [subscriptionTypes]="notification?.subscriptionTypes"
    [allValidNotificationSubscriptionTypes]="
      allValidNotificationSubscriptionTypes
    "
    (subscriptionChanged)="subscriptionChanged($event)"
    [isValid]="isPhoneValid"
    [countryCode]="reminderDetails.countryCode"
  >
  </naoo-subscriptions-form>
  @if (reminderDetails) {
    <naoo-reminder-preferences
      [reminderDetails]="reminderDetails"
      [customerReminders]="notification?.customerReminders"
      (updateCustomerReminders)="updateCustomerReminders($event)"
      [isPhoneValid]="isPhoneValid"
    >
    </naoo-reminder-preferences>
  }
  <div class="button-container">
    <button type="button" class="small secondary-button" (click)="cancel()">
      {{ 'SHARED.CANCEL' | translate }}
    </button>
    <button
      class="small primary-button"
      type="submit"
      [disabled]="!isValid"
      (click)="save()"
    >
      {{ 'SHARED.SAVE' | translate }}
    </button>
  </div>
</div>
