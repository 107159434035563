import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-quick-add-learn-modal',
  templateUrl: './quick-add-learn-modal.component.html',
  styleUrls: ['./quick-add-learn-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class QuickAddLearnModalComponent {
  constructor(private dialogRef: MatDialogRef<QuickAddLearnModalComponent>) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
