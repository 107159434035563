<div class="left-header">
  {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.ITEM' | translate }}
</div>
<div class="right-headers" [ngClass]="{ 'left-padded': !canFindRelated }">
  @if (canFindRelated) {
    <div class="right-header text-left order-status">
      {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.STATUS' | translate }}
    </div>
  }
  <div class="right-header text-left short-header">
    {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.SIZE' | translate }}
  </div>
  <div class="right-header text-center order-quantity">
    {{ 'ORDER_CONFIRMATION.ITEM_WARNINGS.QUANTITY_ORDERED' | translate }}
  </div>
  <div class="right-header text-center order-quantity">
    {{ 'ORDER_CONFIRMATION.ITEM_WARNINGS.QUANTITY_SHIPPED' | translate }}
  </div>
  <div class="right-header no-padding short-header">
    {{ 'ORDER_CONFIRMATION.TABLE_HEADERS.TOTAL' | translate }}
  </div>
</div>
@if (canFindRelated) {
  <div class="right-header find-related"></div>
}
