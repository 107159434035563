import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SelectOrderMethodService } from '../../shared/services/select-order-method/select-order-method.service';
import { OrderMethodModalFacade } from '../../core/store/order-method-modal/order-method-modal.facade';
import { DateService } from '../../shared/services/date/date.service';
import { takeUntil } from 'rxjs/operators';
import { OrderMethodModalSharedViewModel } from '../../core/store/order-method-modal/order-method-modal-view-model';
import { FulfillmentType } from '../../core/services/cart/models/cart-record';
import { OrderMethodStep } from '../../shared/modals/order-method-modal/order-method-modal.component';
import { Moment } from 'moment';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-hamburger-order-method-widget',
  templateUrl: './hamburger-order-method-widget.component.html',
  styleUrls: ['./hamburger-order-method-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgClass, MatIcon, TranslateModule],
})
export class HamburgerOrderMethodWidgetComponent implements OnInit, OnDestroy {
  private readonly mobileBreakPoint = '(max-width: 425px)';
  static readonly ANALYTICS_CATEGORY = 'general';
  static readonly ANALYTICS_ACTION = 'click';
  static readonly ANALYTICS_SELECT_ORDER_METHOD_LABEL =
    'select order method widget';
  static readonly ANALYTICS_ORDER_METHOD_LABEL = 'order method widget';
  static readonly ANALYTICS_DATE_LABEL = 'date widget';

  private destroyed$ = new Subject<void>();

  sharedViewModel: OrderMethodModalSharedViewModel;
  isMobile: boolean;

  @Input() isBaymard?: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private analytics: NaooAnalyticsManager,
    private selectOrderMethodService: SelectOrderMethodService,
    private orderMethodModalFacade: OrderMethodModalFacade,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(this.mobileBreakPoint)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.isMobile = result.breakpoints[this.mobileBreakPoint];
        this.changeDetectorRef.markForCheck();
      });

    this.orderMethodModalFacade
      .getOrderMethodModalSharedViewModel()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sharedViewModel) => {
        this.sharedViewModel = sharedViewModel;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openOrderMethodModal(step?: OrderMethodStep): void {
    this.trackClickEvent(step);
    this.selectOrderMethodService.openSelectOrderMethodDialog(step);
  }

  get selectedFulfillmentType(): FulfillmentType {
    return this.sharedViewModel?.selectedFulfillmentType;
  }

  get fulfillmentIcon(): string {
    switch (this.selectedFulfillmentType) {
      case FulfillmentType.EXPRESS:
        return 'local_shipping';
      case FulfillmentType.PICKUP:
        return 'store_mall_directory';
      default:
        return undefined;
    }
  }

  get fulfillmentSvgIcon(): string {
    return FulfillmentType.TRUCK === this.selectedFulfillmentType
      ? 'semi_truck'
      : undefined;
  }

  get fulfillmentSubtitle(): string {
    switch (this.selectedFulfillmentType) {
      case FulfillmentType.EXPRESS:
        return 'SELECT_ORDER_METHOD.EXPRESS_DELIVERY';
      case FulfillmentType.PICKUP:
        return 'SELECT_ORDER_METHOD.IN-STORE_PICKUP';
      case FulfillmentType.TRUCK:
        return 'SELECT_ORDER_METHOD.TRUCK_DELIVERY';
      default:
        return undefined;
    }
  }

  get dateTitle(): string {
    switch (this.selectedFulfillmentType) {
      case FulfillmentType.EXPRESS:
      case FulfillmentType.TRUCK:
        return 'SELECT_ORDER_METHOD.DELIVERY_DATE';
      case FulfillmentType.PICKUP:
        return 'SELECT_ORDER_METHOD.PICKUP_DATE';
      default:
        return undefined;
    }
  }

  get dateSubtitle(): string {
    if (this.isMobile) {
      const formattedDate = this.dateService.getFormattedDate(
        this.getSelectedDate(),
        'shortDate',
      );
      return formattedDate + '*';
    } else {
      return this.dateService.getFormattedDate(
        this.getSelectedDate(),
        'fullDate',
      );
    }
  }

  get dateNote(): string {
    if (!this.isMobile) {
      return undefined;
    }
    const note = this.dateService.getFormattedDate(
      this.getSelectedDate(),
      'weekDay',
    );
    return '*' + note;
  }

  get OrderMethodStep(): typeof OrderMethodStep {
    return OrderMethodStep;
  }

  private getSelectedDate(): Moment {
    return this.sharedViewModel.viewModelMap.get(this.selectedFulfillmentType)
      .selectedDate;
  }

  private trackClickEvent(step?: OrderMethodStep): void {
    const eventInfo: AnalyticsEventInfo = {
      action: HamburgerOrderMethodWidgetComponent.ANALYTICS_ACTION,
      label: HamburgerOrderMethodWidgetComponent.getClickEventLabel(step),
      category: HamburgerOrderMethodWidgetComponent.ANALYTICS_CATEGORY,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  private static getClickEventLabel(step?: OrderMethodStep): string {
    switch (step) {
      case OrderMethodStep.FULFILLMENT:
        return HamburgerOrderMethodWidgetComponent.ANALYTICS_ORDER_METHOD_LABEL;
      case OrderMethodStep.DATE:
        return HamburgerOrderMethodWidgetComponent.ANALYTICS_DATE_LABEL;
      default:
        return HamburgerOrderMethodWidgetComponent.ANALYTICS_SELECT_ORDER_METHOD_LABEL;
    }
  }
}
