import { Injectable } from '@angular/core';
import { MaterialInfoFacade } from '../../../core/store/material-info/material-info.facade';
import { OrderService } from './order.service';
import {
  OrderDetails,
  OrderDetailsExportModel,
  OrderDetailsViewModel,
} from '../../models/order-details';
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  mergeMap,
  timeout,
} from 'rxjs/operators';
import { SessionFacade } from '../../../core/store/session/session.facade';
import {
  CurrentSystem,
  CustomerBrand,
  SessionActiveCustomer,
} from '../../../core/services/session/models/session-record';
import { MaterialAvailabilityFacade } from '../../../core/store/material-availability/material-availability.facade';
import { MaterialInfo } from '../../models/material-info';
import { MaterialAvailability } from '../../models/material-availability';
import { MaterialAdditionalInfoFacade } from '../../../core/store/material-additional-info/material-additional-info.facade';
import { MaterialAdditionalInfo } from '../../models/material-additional-info';
import { OrderDetailsTransformationService } from './order-details-transformation.service';
import { OrderType } from '../../models/order-type';
import { OfflineModeFacade } from '../../../core/store/offline-mode/offline-mode.facade';
import { EntitlementFacade } from '../../../core/store/entitlement/entitlement.facade';
import { EntitlementMaterialDetail } from '../../../core/services/entitlement/models/entitlement';
import { MaterialPriceFacade } from '../../../core/store/material-price/material-price.facade';
import { CombinedPricingRecord } from '../../../core/store/material-price/material-price.util';
import { StoreFacade } from '../../../core/store/store/store.facade';
import { StoreRecord } from '../../../core/services/store/model/store-record';
import { CartFacade } from '../../../core/store/cart/cart.facade';

@Injectable({ providedIn: 'root' })
export class OrderDetailsService {
  // eslint-disable-next-line max-params
  constructor(
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private materialAdditionalInfoFacade: MaterialAdditionalInfoFacade,
    private orderService: OrderService,
    private priceFacade: MaterialPriceFacade,
    private sessionFacade: SessionFacade,
    private offlineModeFacade: OfflineModeFacade,
    private entitlementFacade: EntitlementFacade,
    private storeFacade: StoreFacade,
    private orderDetailsTransformationService: OrderDetailsTransformationService,
    private cartFacade: CartFacade,
  ) {}

  getOrderDetailsViewModel(
    orderNumber: string,
    orderType: OrderType,
    groupNumber: string | undefined,
  ): Observable<OrderDetailsViewModel> {
    return combineLatest([
      this.cartFacade.fulfillmentChangesHaveCompleted(),
      this.orderService
        .getOrderDetails(orderNumber, orderType, groupNumber)
        .pipe(first()),
    ]).pipe(
      mergeMap(([_, orderDetails]) => {
        const materialNumbers =
          OrderDetailsTransformationService.getDedupedMaterialNumbers(
            orderDetails,
          );
        const substituteMaterials = orderDetails.orderLines
          .map((line) => line.substituteForMaterial)
          .filter((substituteMaterial) => !!substituteMaterial);
        materialNumbers.push(...substituteMaterials);
        this.materialInfoFacade.loadMaterialInfos(materialNumbers);
        this.priceFacade.loadMaterialPrices(materialNumbers);
        this.materialAvailabilityFacade.loadMaterialAvailabilities(
          materialNumbers,
        );
        let storeRecord$: Observable<StoreRecord | undefined> = of(undefined);
        const storePlantId = orderDetails.storeFulfillment?.storePlantId;
        if (storePlantId) {
          this.storeFacade.loadStores();
          storeRecord$ = this.storeFacade
            .getLoadedStoreRecord(storePlantId)
            .pipe(
              first(),
              timeout(5000),
              catchError(() => of(undefined)),
            );
        }
        return combineLatest([
          of(orderDetails),
          this.materialInfoFacade
            .getLoadedMaterialInfoMap(materialNumbers)
            .pipe(distinctUntilChanged()),
          this.priceFacade
            .getCombinedPriceMap(materialNumbers)
            .pipe(distinctUntilChanged()),
          this.materialAvailabilityFacade
            .getLoadedMaterialAvailabilitiesMap(materialNumbers)
            .pipe(distinctUntilChanged()),
          this.entitlementFacade
            .getEntitlementMaterialDetailMap(materialNumbers)
            .pipe(distinctUntilChanged()),
          this.sessionFacade.getLoadedActiveCustomerTimeZone(),
          this.sessionFacade.getLoadedCustomerBrand(),
          this.isEditOrderEnabled$(),
          this.sessionFacade.getLoadedCurrentSystem(),
          this.offlineModeFacade.getIsOnline(),
          storeRecord$,
          this.sessionFacade.getLoadedActiveCustomer(),
        ]);
      }),
      map(
        ([
          orderDetails,
          materialInfoMap,
          combinedPriceMap,
          materialAvailabilityMap,
          entitlementMap,
          customerTimeZone,
          customerBrand,
          isEditOrderEnabled,
          currentSystem,
          isOnline,
          storeRecord,
          customer,
        ]: [
          OrderDetails,
          Map<string, MaterialInfo>,
          Map<string, CombinedPricingRecord>,
          Map<string, MaterialAvailability>,
          Map<string, EntitlementMaterialDetail>,
          string,
          CustomerBrand,
          boolean,
          CurrentSystem,
          boolean,
          StoreRecord,
          SessionActiveCustomer,
        ]) => {
          return this.orderDetailsTransformationService.transformOrderDetailsViewModel(
            orderDetails,
            materialInfoMap,
            combinedPriceMap,
            materialAvailabilityMap,
            entitlementMap,
            customerTimeZone,
            customerBrand,
            isEditOrderEnabled,
            currentSystem,
            isOnline,
            storeRecord,
            customer,
          );
        },
      ),
    );
  }

  getOrderDetailsExportModel(
    orderNumber: string,
    orderType: OrderType,
    groupNumber: string | undefined,
  ): Observable<OrderDetailsExportModel> {
    return this.orderService
      .getOrderDetails(orderNumber, orderType, groupNumber)
      .pipe(
        first(),
        mergeMap((orderDetails: OrderDetails) => {
          const materialNumbers =
            OrderDetailsTransformationService.getDedupedMaterialNumbers(
              orderDetails,
            );
          this.materialInfoFacade.loadMaterialInfos(materialNumbers);
          this.priceFacade.loadMaterialPrices(materialNumbers);
          this.materialAdditionalInfoFacade.loadMaterialAdditionalInfos(
            materialNumbers,
          );
          return combineLatest([
            of(orderDetails),
            this.materialInfoFacade.getLoadedMaterialInfoMap(materialNumbers),
            this.priceFacade.getLoadedCombinedPriceMap(materialNumbers),
            this.materialAdditionalInfoFacade.getLoadedMaterialAdditionalInfoMap(
              materialNumbers,
            ),
            this.sessionFacade.getLoadedCurrentSystem(),
          ]);
        }),
        map(
          ([
            orderDetails,
            materialInfoMap,
            combinedPriceMap,
            materialAdditionalInfoMap,
            currentSystem,
          ]: [
            OrderDetails,
            Map<string, MaterialInfo>,
            Map<string, CombinedPricingRecord>,
            Map<string, MaterialAdditionalInfo>,
            CurrentSystem,
          ]) => {
            return this.orderDetailsTransformationService.transformOrderDetailsExportModel(
              orderDetails,
              materialInfoMap,
              combinedPriceMap,
              materialAdditionalInfoMap,
              currentSystem,
            );
          },
        ),
      );
  }

  private isEditOrderEnabled$(): Observable<boolean> {
    return combineLatest([this.sessionFacade.getLoadedCurrentSystem()]).pipe(
      map(([currentSystem]) => CurrentSystem.isMygfsOrSap(currentSystem)),
    );
  }
}
