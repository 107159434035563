import { createSelector } from '@ngrx/store';
import {
  selectActiveCustomer,
  selectCustomerBrand,
  selectLanguage,
  selectLocale,
} from '../session/session.selectors';
import { ExportMaterialsTransformer } from './export-materials-transformer';
import {
  ExportMaterialsData,
  ExportModalSelection,
} from '../../../shared/services/export-materials/models/export-materials';
import { selectAllMaterialInfoRecordStates } from '../material-info/material-info.selectors';
import { selectMaterialAdditionalInfosRecord } from '../material-additional-info/material-additional-info.selectors';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { MaterialAdditionalInfoRecordState } from '../material-additional-info/material-additional-info.state';
import { selectCustomerMaterialRecord } from '../customer-material/customer-material.selector';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import { selectAllMaterialAvailabilityRecords } from '../material-availability/material-availability.selectors';
import { CustomGuideRecordState } from '../custom-guide/custom-guide.state';
import { selectAllCustomGuideRecordStateEntities } from '../custom-guide/custom-guide.selectors';
import {
  CustomerBrand,
  Language,
  Locale,
  SessionActiveCustomer,
} from '../../services/session/models/session-record';
import { CombinedPricingRecord } from '../material-price/material-price.util';
import { selectAllCombinedPricingRecords } from '../material-price/material-price.selectors';
import { selectContractIndicatorMaterialsSet } from '../indicator-lists/indicator-lists.selectors';

interface MaterialData {
  infoRecords: Dictionary<MaterialInfoRecordState>;
  additionalInfoRecords: Dictionary<MaterialAdditionalInfoRecordState>;
  availabilityRecords: Dictionary<MaterialAvailabilityRecordState>;
  combinedPriceRecords: Dictionary<CombinedPricingRecord>;
  customerMaterialRecord: CustomerMaterialRecord;
  contractIndicatorMaterialsSet: Set<string>;
}

const selectCustomGuideRecordData = (customGuideId?: string) =>
  createSelector(
    selectAllCustomGuideRecordStateEntities,
    (customGuideRecordStates) =>
      customGuideId ? customGuideRecordStates[customGuideId] : undefined,
  );

const selectMaterialData = createSelector(
  selectAllMaterialInfoRecordStates,
  selectMaterialAdditionalInfosRecord,
  selectAllMaterialAvailabilityRecords,
  selectAllCombinedPricingRecords(),
  selectCustomerMaterialRecord,
  selectContractIndicatorMaterialsSet,
  (
    infoRecords: Dictionary<MaterialInfoRecordState>,
    additionalInfoRecords: Dictionary<MaterialAdditionalInfoRecordState>,
    availabilityRecords: Dictionary<MaterialAvailabilityRecordState>,
    combinedPriceRecords: Dictionary<CombinedPricingRecord>,
    customerMaterialRecord: CustomerMaterialRecord,
    contractIndicatorMaterialsSet: Set<string>,
  ): MaterialData => {
    return {
      infoRecords,
      additionalInfoRecords,
      availabilityRecords,
      combinedPriceRecords,
      customerMaterialRecord,
      contractIndicatorMaterialsSet,
    };
  },
);

export const selectExportMaterialsData = (
  modalSelection: ExportModalSelection,
) =>
  createSelector(
    selectMaterialData,
    selectActiveCustomer,
    selectCustomerBrand,
    selectLanguage,
    selectLocale,
    selectCustomGuideRecordData(modalSelection.customGuideId),
    (
      materialData: MaterialData,
      customer: SessionActiveCustomer,
      customerBrand: CustomerBrand,
      language: Language,
      locale: Locale,
      customGuideRecordState?: CustomGuideRecordState,
    ): ExportMaterialsData => {
      return ExportMaterialsTransformer.transformExportMaterialsData(
        modalSelection,
        materialData.infoRecords,
        materialData.additionalInfoRecords,
        materialData.availabilityRecords,
        materialData.combinedPriceRecords,
        materialData.customerMaterialRecord,
        materialData.contractIndicatorMaterialsSet,
        customer,
        customerBrand,
        language,
        locale,
        customGuideRecordState,
      );
    },
  );
