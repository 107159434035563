import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NaooIcon } from '../services/images/naoo-icon.service';
import { ListScrollService } from '../services/list-scroll/list-scroll.service';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
} from '@angular/material/expansion';
import { NgClass, UpperCasePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export enum ColorScheme {
  Default = 'default',
  Red = 'red',
  Gray = 'gray',
}

@Component({
  selector: 'naoo-generic-expansion-panel',
  templateUrl: './generic-expansion-panel.component.html',
  styleUrls: ['./generic-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionPanel,
    NgClass,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    MatExpansionPanelDescription,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class GenericExpansionPanelComponent {
  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;
  @Input() headerTitle: string;
  @Input() startExpanded = true;
  @Input() autoScroll = true;
  @Input() colorScheme: ColorScheme = ColorScheme.Default;
  @Input() icon?: NaooIcon;
  @Input() isLargerThanMaxHeight = false;
  @Input() hasRedBorder: boolean;
  @Input() hideToggleIcon = false;
  @Input() collapsedHeight = 'auto';
  @ViewChild('scrollAnchor', { static: true }) scrollAnchor: ElementRef;
  @Output() expanded = new EventEmitter<boolean>();

  constructor(private listScrollService: ListScrollService) {}

  scrollToContent(): void {
    this.expanded.emit(true);
    if (this.autoScroll) {
      this.listScrollService.scrollSmoothlyToCenter(
        this.scrollAnchor.nativeElement,
      );
    }
  }

  afterCollapse(): void {
    this.expanded.emit(false);
  }

  open(): void {
    this.expansionPanel.open();
  }
}
