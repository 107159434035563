<div class="search-content">
  <naoo-search-header
    [searchResult]="searchResult"
    [isMobile]="isMobile"
    [isOrderGuideTab]="isOrderGuideTab"
    (openFilters)="openFilterModalEvent.emit($event)"
  >
  </naoo-search-header>

  @if (shouldRenderSearchList) {
    <naoo-material-list
      [materialListItems]="searchResult?.resultSet?.materialNumbers"
      [listStyle]="searchResult.preferredView"
      [context]="context"
      [analytics]="{
        searchText: searchResult?.searchText | stringdefaulter,
      }"
      (endOfList)="endOfListEvent.emit($event)"
    >
    </naoo-material-list>
  } @else if (hasNoResults && searchResult?.hasLoaded) {
    <naoo-no-results-expo
      [isZeroWithOG]="isOrderGuideOptionSelected"
      [searchText]="searchResult.searchText | stringdefaulter"
    >
    </naoo-no-results-expo>
  }
</div>
