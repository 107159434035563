import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { CustomerNotificationPreferencesFacade } from '../../../core/store/customer-notification-preferences/customer-notification-preferences.facade';

@Injectable({
  providedIn: 'root',
})
export class SmsNotificationsDuplicateValidator {
  constructor(
    private customerNotificationsFacade: CustomerNotificationPreferencesFacade,
  ) {}

  validate(contactInfo: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const phoneNumber = this.formatPhoneNumber(control.value);
      if (phoneNumber === contactInfo) {
        return of(null);
      }
      return this.customerNotificationsFacade.getSmsNotifications().pipe(
        first(),
        map((notifications) => {
          const matchingPhoneNumber = notifications.customerNotifications.find(
            (notification) => notification.contactInfo === phoneNumber,
          );
          return matchingPhoneNumber ? { duplicateNumber: phoneNumber } : null;
        }),
        catchError(() => of(null)),
      );
    };
  }

  private formatPhoneNumber(input: string): string {
    return input.replace(/\D/g, '');
  }
}
