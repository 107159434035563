import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FreightOption } from '../../../../../../core/services/pre-submit/model/order-split-record';
import {
  MatSelectChange,
  MatSelect,
  MatSelectTrigger,
} from '@angular/material/select';
import { Site } from '../../../../../../core/services/session/models/session-record';
import { CartFacade } from '../../../../../../core/store/cart/cart.facade';
import {
  SplitOrder,
  SplitOrderType,
} from '../../../../../../core/services/cart-order/models/cart-order';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartReviewSection } from '../../../../../../core/store/cart-review/cart-review.state';
import { MaterialPriceFacade } from '../../../../../../core/store/material-price/material-price.facade';
import { DefaultDialogService } from '../../../../../../shared/services/dialog/default-dialog/default-dialog.service';
import {
  MatFormField,
  MatLabel,
  MatPrefix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { PoInputComponent } from '../../../../../po-input/po-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooPricePipe } from '../../../../../../shared/pipes/naoo-price.pipe';
import { NaooDatePipe } from '../../../../../../shared/pipes/naoo-date.pipe';

@Component({
  selector: 'naoo-cart-review-drop-ship-header',
  templateUrl: './cart-review-drop-ship-header.component.html',
  styleUrls: ['./cart-review-drop-ship-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatIcon,
    MatPrefix,
    NgClass,
    MatSelect,
    MatSelectTrigger,
    NgTemplateOutlet,
    MatOption,
    PoInputComponent,
    TranslateModule,
    AsyncPipe,
    NaooPricePipe,
    NaooDatePipe,
  ],
})
export class CartReviewDropShipHeaderComponent implements OnInit, OnDestroy {
  @Input() isOffline: boolean;
  @Input() section: CartReviewSection;

  poNumber: string;
  selectedFreightOption: FreightOption;
  selectedSite: Site;
  estimatedSectionTotal$: Observable<number>;

  private destroyed$ = new Subject<void>();

  constructor(
    private cartFacade: CartFacade,
    private materialPriceFacade: MaterialPriceFacade,
    private dialogService: DefaultDialogService,
  ) {}

  get isAddressDisabled(): boolean {
    return this.section.sites.length <= 1 || this.section.sectionCounter > 1;
  }

  ngOnInit(): void {
    this.poNumber = this.section.poNumber;
    this.selectedSite = this.section.sites?.find(
      (site) => site.siteId === this.section.selectedSiteId,
    );
    this.estimatedSectionTotal$ = this.materialPriceFacade
      .getCartSectionTotal(
        this.section.materialNumbers
          .filter((material) => !!material)
          .map((material) => material.value as string),
      )
      .pipe(takeUntil(this.destroyed$));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  changeFreight($event: MatSelectChange) {
    this.selectedFreightOption = $event.value;
    this.updateSplitOrder();

    if (this.selectedFreightOption.isLtlShipping) {
      this.openLtlDialog();
    }
  }

  changeSite($event: MatSelectChange) {
    this.selectedSite = $event.value;
    this.cartFacade.updateDropShipSiteId(this.selectedSite.siteId);
  }

  updatePoNumber(poNumber: string) {
    this.poNumber = poNumber;
    this.updateSplitOrder();
  }

  openLtlDialog(): void {
    this.dialogService.prompt(
      'Less than truck material in cart',
      'CART_REVIEW.LTL_WARNING_MODAL.MESSAGE',
      false,
      true,
      undefined,
      undefined,
      'CART_REVIEW.LTL_WARNING_MODAL.CONFIRMATION',
      () => {},
      'CART_REVIEW.LTL_WARNING_MODAL.TITLE',
      'naoo-ltl-modal',
    );
  }

  private updateSplitOrder() {
    this.cartFacade.updateSplitOrder(this.getSplitOrder());
  }

  private getSplitOrder(): SplitOrder {
    return {
      orderType: SplitOrderType.DROP_SHIP,
      materialNumbers: this.section.materialNumbers.map(
        (row) => row.value as string,
      ),
      customerPoNumber: this.poNumber,
      carrierFulfillment: {
        deliverySiteId: this.selectedSite?.siteId,
        carrierDeliveryTypeCode: this.selectedFreightOption?.carrierTypeCode,
        carrierShippingMethodCode:
          this.selectedFreightOption?.carrierShippingMethodCode,
        shippingPrice: this.selectedFreightOption?.freightCost,
        shipmentId: this.section.fulfillmentOrderSplit?.shipmentId,
        estimatedCustomerArrivalDate:
          this.selectedFreightOption?.estimatedDeliveryDate,
      },
    };
  }
}
