import { OrderDetails } from '../../models/order-details';
import { FreightInformation } from '../../models/order-info';
import { FreightCarrier } from './order-details-transformation.service';

export function collectTrackingNumbers(
  order: OrderDetails,
  carrier: FreightCarrier,
): Set<string> {
  const trackingNumbers = new Set<string>();

  return order?.orderLines
    ?.filter(
      (orderLine) =>
        !!orderLine.freightInformation &&
        orderLine.freightInformation.length > 0,
    )
    .reduce((numbers, orderLine) => {
      return collectTrackingNumbersFromSingleOrderLine(
        orderLine.freightInformation,
        carrier,
        numbers,
      );
    }, trackingNumbers);
}

function collectTrackingNumbersFromSingleOrderLine(
  freightInformationArray: FreightInformation[],
  carrier: FreightCarrier,
  trackingNumberSet: Set<string>,
): Set<string> {
  return freightInformationArray
    .filter(
      (freightInformation) =>
        !!freightInformation.trackingNumbers &&
        freightInformation.trackingNumbers.length > 0 &&
        freightInformation.freightCarrier === carrier,
    )
    .reduce((numbers, freightInformation) => {
      return saveTrackingNumbersForSingleFreightInformation(
        freightInformation,
        carrier,
        numbers,
      );
    }, trackingNumberSet);
}

function saveTrackingNumbersForSingleFreightInformation(
  freightInformation: FreightInformation,
  carrier: FreightCarrier,
  trackingNumberSet: Set<string>,
): Set<string> {
  return freightInformation.trackingNumbers.reduce(
    (numbers, trackingNumber) => numbers.add(trackingNumber),
    trackingNumberSet,
  );
}
