import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import { CustomerPreferencesFacade } from 'src/app/core/store/customer-preferences/customer-preferences.facade';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { OrderConfirmationEmailOptIn } from '../../../../core/store/cart-review/cart-review.state';
import { OrderConfirmationEmailOptInStatus } from '../../../../core/store/customer-preferences/customer-preferences.selectors';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

export enum OptInMessage {
  Prompt = 'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.PROMPT',
  Confirm = 'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.CONFIRM',
  Deny = 'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.DENY',
  LimitReached = 'CART_REVIEW.EMAIL_NOTIFICATION_OPT_IN.LIMIT_REACHED',
}

@Component({
  selector: 'naoo-email-notification-opt-in',
  templateUrl: './email-notification-opt-in.component.html',
  styleUrls: ['./email-notification-opt-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, RouterLink, TranslateModule],
})
export class EmailNotificationOptInComponent {
  private _orderConfirmationEmailOptIn: OrderConfirmationEmailOptIn;

  @Input()
  set orderConfirmationEmailOptIn(
    orderConfirmationEmailOptIn: OrderConfirmationEmailOptIn,
  ) {
    this._orderConfirmationEmailOptIn = orderConfirmationEmailOptIn;
    if (!this.shouldShow) {
      this.setInitialValues(orderConfirmationEmailOptIn);
    }
  }

  get orderConfirmationEmailOptIn(): OrderConfirmationEmailOptIn {
    return this._orderConfirmationEmailOptIn;
  }

  readonly preferencesPath = NaooConstants.PREFERENCES_PATH;
  shouldShow = false;
  optInMessage: OptInMessage;

  constructor(
    private readonly customerPreferencesFacade: CustomerPreferencesFacade,
    private readonly localizationService: LocalizationService,
  ) {}

  optIn() {
    this.optInMessage = OptInMessage.Confirm;
    this.customerPreferencesFacade.addOptInEmail(
      this.orderConfirmationEmailOptIn.email,
      this.localizationService.currentLanguage,
    );
  }

  optOut() {
    this.optInMessage = OptInMessage.Deny;
    this.customerPreferencesFacade.addOptOutEmail(
      this.orderConfirmationEmailOptIn.email,
    );
  }

  isDenyMessage(): boolean {
    return this.optInMessage === OptInMessage.Deny;
  }

  get showButtons(): boolean {
    return this.optInMessage === OptInMessage.Prompt;
  }

  get showPreferencesLink(): boolean {
    return [OptInMessage.Deny, OptInMessage.LimitReached].includes(
      this.optInMessage,
    );
  }

  private setInitialValues(
    orderConfirmationEmailOptIn: OrderConfirmationEmailOptIn,
  ) {
    switch (orderConfirmationEmailOptIn?.status) {
      case OrderConfirmationEmailOptInStatus.Prompt:
        this.optInMessage = OptInMessage.Prompt;
        this.shouldShow = true;
        break;
      case OrderConfirmationEmailOptInStatus.LimitReached:
        this.optInMessage = OptInMessage.LimitReached;
        this.customerPreferencesFacade.addOptOutEmail(
          this.orderConfirmationEmailOptIn.email,
        );
        this.shouldShow = true;
        break;
      default:
        this.optInMessage = undefined;
        this.shouldShow = false;
        break;
    }
  }
}
