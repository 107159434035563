import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CartCoupon, CartCoupons } from '../../shared/cart-coupon';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { NotOnlyWhitespaceValidator } from '../../../../shared/services/validators/not-only-whitespace-validator.service';
import { CartCouponExistsValidator } from '../../../../shared/services/validators/cart-coupon-exists-validator.service';
import { Observable } from 'rxjs';
import { LoadingService } from '../../../../shared/services/loading-service/loading.service';
import {
  MatError,
  MatFormFieldModule,
  MatHint,
} from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'naoo-cart-coupon-input',
  templateUrl: './cart-coupon-input.component.html',
  styleUrls: ['./cart-coupon-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatHint,
    MatError,
    AsyncPipe,
    TranslateModule,
  ],
})
export class CartCouponInputComponent implements OnInit {
  loadingState$: Observable<boolean>;
  @Output() addCoupon = new EventEmitter<string>();

  @Input()
  set cartCoupons(cartCoupons: CartCoupons) {
    this._cartCoupons = cartCoupons;
    this.updateFormControls(cartCoupons);
  }

  get cartCoupons(): CartCoupons {
    return this._cartCoupons;
  }

  get isAddButtonDisabled(): boolean {
    return (
      this.cartCoupons.isMaxCoupons ||
      this.cartCoupons.isOffline ||
      this.isFormInvalid()
    );
  }

  couponNameFormControl: FormControl;

  readonly maximumLength: number = NaooConstants.COUPON_CODE_MAX_LENGTH;
  private _cartCoupons: CartCoupons;

  constructor(
    private notOnlyWhitespaceValidator: NotOnlyWhitespaceValidator,
    private loadingService: LoadingService,
    private cartCouponExistsValidator: CartCouponExistsValidator,
  ) {
    this.couponNameFormControl = new FormControl(
      { value: '', disabled: true },
      [
        Validators.maxLength(this.maximumLength),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
        this.notOnlyWhitespaceValidator.validate(),
      ],
      this.cartCouponExistsValidator
        .validate()
        .bind(this.cartCouponExistsValidator),
    );
  }

  ngOnInit() {
    this.loadingState$ = this.loadingService.loadingState;
  }

  addCouponCode(): void {
    this.addCoupon.emit(this.couponCodeValue());
  }

  private couponCodeValue(): string {
    return this.couponNameFormControl.value?.toString().toUpperCase().trim();
  }

  private isFormInvalid(): boolean {
    return !(
      this.couponNameFormControl.dirty && this.couponNameFormControl.valid
    );
  }

  private updateFormControls(cartCoupons: CartCoupons) {
    if (cartCoupons?.isMaxCoupons || cartCoupons?.isOffline) {
      this.couponNameFormControl.disable();
    } else {
      this.couponNameFormControl.enable();
    }
    if (this.findMatchingCouponCode(cartCoupons)) {
      this.couponNameFormControl.reset();
    }
    this.couponNameFormControl.setErrors(null);
  }

  private findMatchingCouponCode(cartCoupons: CartCoupons): CartCoupon {
    return cartCoupons?.cartCoupons.find(
      (cartCoupon) =>
        cartCoupon.couponCode.toUpperCase() === this.couponCodeValue(),
    );
  }
}
