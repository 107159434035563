import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MaterialUnitsActions } from './material-units.actions';
import { MaterialUnitsRecord } from '../../services/material-units/models/material-units-record';
import { selectAllMaterialUnitsRecords } from './material-units.selectors';
import { EntityState } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { getMaterialUnitFromUomCode } from '../../../shared/utilities/material-units-utilities';
import { LocalizationService } from '../../../shared/services/translation/localization.service';

@Injectable({ providedIn: 'root' })
export class MaterialUnitsFacade {
  private materialUnitsRecords: EntityState<MaterialUnitsRecord>;

  constructor(
    private readonly store: Store,
    private readonly localizationService: LocalizationService,
  ) {
    this.getLoadedMaterialUnitsRecords().subscribe(
      (entities: EntityState<MaterialUnitsRecord>) => {
        this.materialUnitsRecords = entities;
      },
    );
  }

  public loadMaterialUnitsInfo() {
    this.store.dispatch(MaterialUnitsActions.getMaterialUnitsInformation());
  }

  public getLoadedMaterialUnitsRecords(): Observable<
    EntityState<MaterialUnitsRecord>
  > {
    return this.store.select(selectAllMaterialUnitsRecords);
  }

  public getMaterialUnitFromUomCode(
    value: { uomCode: string; quantity?: number },
    shouldDisplayQuantity: boolean = true,
  ): string {
    return getMaterialUnitFromUomCode(
      value.quantity,
      value.uomCode,
      this.materialUnitsRecords?.entities,
      this.localizationService.currentLanguage,
      false, //shouldDisplaySingular
      shouldDisplayQuantity,
    );
  }
}
