import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MaterialListStyle } from '../../../core/store/material-row/models/material-row';
import { GuideActionBar } from '../guides';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialViewSelectComponent } from '../../../shared/material-view-select/material-view-select.component';
import { GuidesParActionsComponent } from '../guides-par-actions/guides-par-actions.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-action-bar',
  templateUrl: './guides-action-bar.component.html',
  styleUrls: ['./guides-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    ReactiveFormsModule,
    FormsModule,
    MaterialViewSelectComponent,
    GuidesParActionsComponent,
    TranslateModule,
  ],
})
export class GuidesActionBarComponent implements OnInit, OnDestroy {
  @ViewChild('searchText', { static: true }) searchBox: ElementRef;
  searchTextModel: string;
  parEnabled: boolean;
  @Input()
  set actionBar(actionBar: GuideActionBar) {
    this.searchTextModel = actionBar.searchText;
    this.parEnabled = actionBar.isParEnabled;
  }
  @Input() selectedView: MaterialListStyle;
  @Input() isOffline: boolean;

  @Output() updateSearchText = new EventEmitter<string>();
  @Output() updateMaterialView = new EventEmitter<MaterialListStyle>();
  @Output() clearInventoryQuantities = new EventEmitter<void>();
  @Output() toggleParOrdering = new EventEmitter<boolean>();
  readonly selectableViews = [MaterialListStyle.Slim, MaterialListStyle.List];
  private readonly searchDebounceTime: number = 200;

  private searchText$ = new Subject<string>();

  private destroyed$ = new Subject<boolean>();

  get shouldRenderParContainer(): boolean {
    return this.parEnabled !== undefined;
  }

  handleUpdateSearchText(searchText: string) {
    this.searchText$.next(searchText);
  }

  handleUpdateMaterialView(listStyle: MaterialListStyle) {
    this.updateMaterialView.emit(listStyle);
  }

  handleClearInventoryQuantities() {
    this.clearInventoryQuantities.emit();
  }

  handleToggleParOrdering(isEnabled: boolean) {
    this.toggleParOrdering.emit(isEnabled);
  }

  clear() {
    this.updateSearchText.emit('');
  }

  clearAndFocus() {
    this.clear();
    (<HTMLInputElement>this.searchBox.nativeElement).focus();
  }

  ngOnInit() {
    this.searchText$
      .pipe(takeUntil(this.destroyed$), debounceTime(this.searchDebounceTime))
      .subscribe((text) => this.updateSearchText.emit(text));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
