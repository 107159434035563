import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NotificationLanguage } from '../models/customer-notifications';
import { NgClass } from '@angular/common';
import {
  MatLabel,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { FeatureFlag } from 'src/app/core/services/session/models/session-record';
import { SessionFacade } from 'src/app/core/store/session/session.facade';
import { first, Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'notification-language-dropdown',
  templateUrl: './notification-language-dropdown.component.html',
  standalone: true,
  imports: [MatSelectModule, MatLabel, FormsModule, NgClass, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationLanguageDropdownComponent
  implements OnInit, OnDestroy
{
  @Input() selectedLanguage: string;
  @Output() currentLangChange = new EventEmitter<NotificationLanguage>();

  // When Spanish feat flag is removed add Spanish here and remove logic in ngOnInit
  protected availableLanguages: NotificationLanguage[] = [
    NotificationLanguage.English,
    NotificationLanguage.French,
  ];

  private readonly destroyed$ = new Subject();

  constructor(private readonly sessionFacade: SessionFacade) {}

  ngOnInit(): void {
    this.sessionFacade
      .isFeatureEnabled(FeatureFlag.SPANISH_LANGUAGE)
      .pipe(takeUntil(this.destroyed$), first())
      .subscribe((includeSpanish: boolean) => {
        if (includeSpanish) {
          this.availableLanguages.push(NotificationLanguage.Spanish);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSelectionChange($selection: MatSelectChange): void {
    this.currentLangChange.emit($selection.value);
  }
}
