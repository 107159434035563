import { CustomerBrand } from '../../core/services/session/models/session-record';
import { SplitOrderType } from '../../core/services/cart-order/models/cart-order';
import { CreditWidgetLoadingState } from '../../core/store/cart-review/cart-review.state';

export interface CostSummary {
  context: CostSummaryContext;
  isPunchOut: boolean;
  customerInfo: CostSummaryCustomerInfo;
  shippingInfo: CostSummaryShippingInfo;
  quantities: OrderSummary[];
  submitButtonState: SubmitOrderButtonState;
  isOffline: boolean;
  displayCouponContainer: boolean;
  isLoyaltyProgramEligible: boolean;
  hasStoresPermissions: boolean;
  warning?: string;
  creditWidgetLoadingState?: CreditWidgetLoadingState;
  payWithCreditSelected?: boolean;
}

export enum CostSummaryContext {
  CartSummary = 'Cart Summary',
  CartSummaryPaymentRequired = 'Cart Summary Payment Required',
  CartReview = 'Cart Review',
  CartReviewPaymentRequired = 'Cart Review Payment Required',
  AddPayment = 'Add Payment',
}

export enum SubmitOrderButtonState {
  EmptyCart = 'Empty Cart',
  Ok = 'Ok',
  NoRouteDate = 'No Route Date',
  NoOrderSubmissionPermission = 'Not Authorized',
  NoOrderSubmissionPermissionEmptyCart = 'Not Authorized Empty Cart',
  AddPayment = 'Add Payment',
  TransferCart = 'Transfer Cart',
}

export interface CostSummaryCustomerInfo {
  customerName: string;
  customerNumber: string;
  customerBrand: CustomerBrand;
}

export interface CostSummaryShippingInfo {
  poNumber: string;
  isPoRequired: boolean;
  routeDate?: string;
}

export interface OrderSummary {
  lines: number;
  totalQuantity: number;
  placeholder: string;
  title: string;
  estimatedCost?: number;
  estimatedSavings?: number;
  estimatedDeliveryDate?: string;
  orderType?: SplitOrderType;
  totalLoyaltyPoints?: number;
}
