import { Component, EventEmitter, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  standalone: true,
  imports: [NgClass, MatIcon, TranslateModule],
})
export class FileInputComponent {
  @Output() fileSelected = new EventEmitter<File>();
  dragging: boolean;

  constructor() {
    this.dragging = false;
  }

  fileChanged(files: ArrayLike<File>) {
    if (files.length === 1) {
      this.fileSelected.emit(files[0]);
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
    const files = event.dataTransfer.files;
    this.fileChanged(files);
  }
}
