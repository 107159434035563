import { ModuleWithProviders, NgModule } from '@angular/core';
import { NaooDeliveryOrShipPipe } from './delivery-or-ship.pipe';

@NgModule({
  declarations: [NaooDeliveryOrShipPipe],
  exports: [NaooDeliveryOrShipPipe],
  providers: [NaooDeliveryOrShipPipe],
})
export class PipeModule {
  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
      ngModule: PipeModule,
    };
  }
}
