export interface CartActionBarWidths {
  windowWidth: number;
  activeCartContentWidth: number;
  actionBarContainer: number;
  menuButton: number;
  actionsButtons: { [key: string]: number };
}

export enum CartActionBarButton {
  AddToCustomGuide = 'add-to-custom-guide',
  Print = 'print',
  SaveCart = 'save-cart',
  EmptyCart = 'empty-cart',
  SortBy = 'sort-by',
}
