import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import moment, { Moment } from 'moment';
import { FulfillmentType } from '../../../../../../../core/services/cart/models/cart-record';
import { StoreDetailsViewModel } from '../../../../../../../core/store/order-method-modal/order-method-modal-view-model';
import { NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';
import { SelectDateStepCalendarComponent } from '../select-date-step-calendar/select-date-step-calendar.component';
import { StoreDisplayComponent } from '../../../../../../store-display/store-display.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooPickupDatePipe } from '../../../../../../pipes/naoo-pickup-date.pipe';

@Component({
  selector: 'naoo-select-date-step-ispu',
  templateUrl: './select-date-step-ispu.component.html',
  styleUrls: ['./select-date-step-ispu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    SelectDateStepCalendarComponent,
    StoreDisplayComponent,
    NgClass,
    AsyncPipe,
    TranslateModule,
    NaooPickupDatePipe,
  ],
})
export class SelectDateStepIspuComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() availableDates: string[];
  @Input() customerTimeZone: string;
  @Input() selectedDate: Moment;
  @Input() storeDetailsViewModel: StoreDetailsViewModel;
  @Input() isRouteDateExpired: boolean;
  @Input() isMobileWidth: boolean;

  @Output() dateChangeEmitter = new EventEmitter<Moment>();

  private today: Moment;

  constructor() {
    this.today = moment(new Date());
  }

  get isSameDay(): boolean {
    return this.today.isSame(this.selectedDate, 'day');
  }
}
