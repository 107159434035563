<div
  class="parent-container"
  [ngClass]="{
    'red-border': shouldShowRedBorder,
    'gray-border': !shouldShowRedBorder,
    'no-side-borders': isInsideContainer,
    'last-row': isLast,
    mobile: isMobile,
  }"
>
  @if (isOK) {
    <div
      class="content material-content consistent-height"
      [ngClass]="{ mobile: isMobile }"
      @fadeInOnly
    >
      @if (!isMobile) {
        <naoo-material-image
          [materialRowOptions]="materialRow.materialRowOptions"
          [isUnorderable]="false"
          [isMobile]="isMobile"
        ></naoo-material-image>
      }
      <div class="details-container" [ngClass]="{ mobile: isMobile }">
        <div class="row-details" [ngClass]="{ mobile: isMobile }">
          <div
            class="info-content"
            [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
          >
            @if (isMobile) {
              <naoo-material-image
                [materialRowOptions]="materialRow.materialRowOptions"
                [isUnorderable]="false"
                [isMobile]="isMobile"
              ></naoo-material-image>
            }
            <div
              class="details-content"
              [ngClass]="{
                mobile: isMobile,
                'full-width': isInsideContainer,
              }"
            >
              <naoo-material-info
                [materialRowOptions]="materialRow.materialRowOptions"
                [materialInfo]="materialRow.materialRowInfo"
                [isMobile]="isMobile"
              ></naoo-material-info>
              @if (!isInsideContainer && isMobile) {
                <div class="mobile-triple-dot">
                  <naoo-material-options-menu
                    [materialRowOptions]="materialRow.materialRowOptions"
                    [isMobile]="isMobile"
                  >
                  </naoo-material-options-menu>
                </div>
              }
            </div>
          </div>
          <div
            class="ordering-content"
            [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
          >
            @if (!!materialRow.materialOrdering) {
              <naoo-material-ordering
                [materialOrdering]="materialRow.materialOrdering"
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
                [ngClass]="{ mobile: isMobile }"
                (viewCommodityDetailsClicked)="
                  viewCommodityDetailsClicked.emit()
                "
              ></naoo-material-ordering>
            }
            @if (!isInsideContainer && !isMobile) {
              <div class="triple-dot">
                <naoo-material-options-menu
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [isMobile]="isMobile"
                >
                </naoo-material-options-menu>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }

  @if (isUnorderable) {
    <div
      class="content unorderable-content consistent-height"
      [ngClass]="{ mobile: isMobile }"
      @fadeInOnly
    >
      @if (!isMobile) {
        <naoo-material-image
          [materialRowOptions]="materialRow.materialRowOptions"
          [isUnorderable]="true"
          [isMobile]="isMobile"
        ></naoo-material-image>
      }
      <div class="details-container" [ngClass]="{ mobile: isMobile }">
        <div class="row-details" [ngClass]="{ mobile: isMobile }">
          <div
            class="info-content"
            [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
          >
            @if (isMobile) {
              <naoo-material-image
                [materialRowOptions]="materialRow.materialRowOptions"
                [isUnorderable]="true"
                [isMobile]="isMobile"
              ></naoo-material-image>
            }
            <div class="details-content" [ngClass]="{ mobile: isMobile }">
              <naoo-material-info
                [materialRowOptions]="materialRow.materialRowOptions"
                [materialInfo]="materialRow.materialRowInfo"
                [isMobile]="isMobile"
              ></naoo-material-info>
              @if (!isInsideContainer && isMobile) {
                <div class="mobile-triple-dot">
                  <naoo-material-options-menu
                    [materialRowOptions]="materialRow.materialRowOptions"
                    [isMobile]="isMobile"
                  >
                  </naoo-material-options-menu>
                </div>
              }
            </div>
          </div>
          <div
            class="ordering-content"
            [ngClass]="{ mobile: isMobile, 'par-enabled': isParEnabled }"
          >
            <naoo-material-unorderable
              [materialRowOptions]="materialRow.materialRowOptions"
              [isMobile]="isMobile"
              [materialAvailability]="materialAvailability"
            >
            </naoo-material-unorderable>
            @if (!isInsideContainer && !isMobile) {
              <div class="triple-dot">
                <naoo-material-options-menu
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [isMobile]="isMobile"
                >
                </naoo-material-options-menu>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }

  @if (isUnavailable) {
    <div
      class="content consistent-height unavailable-content"
      [ngClass]="{ mobile: isMobile }"
      @fadeInOnly
    >
      <naoo-material-unavailable
        [materialRowOptions]="materialRow.materialRowOptions"
        [isMobile]="isMobile"
        [isDeleteHidden]="shouldHideTrashCan"
      >
      </naoo-material-unavailable>
    </div>
  }

  @if (isDeleted) {
    <div
      class="content deleted-content consistent-height"
      [ngClass]="{ mobile: isMobile }"
      @fadeInOnly
    >
      <naoo-material-deleted
        [materialInfo]="materialRow.materialRowInfo"
        [status]="materialRow.status"
        [materialRowOptions]="materialRow.materialRowOptions"
        [isMobile]="isMobile"
      >
      </naoo-material-deleted>
    </div>
  }

  @if (isLoading) {
    <div
      class="content loading-content consistent-height"
      [ngClass]="{ mobile: isMobile }"
      @fadeInAndOut
    >
      <naoo-material-skeleton
        [isMobile]="isMobile"
        [materialListStyle]="materialRow.materialRowOptions.materialListStyle"
      >
      </naoo-material-skeleton>
    </div>
  }
</div>
