<a
  class="product-subclass"
  href="javascript:void(0)"
  attr.aria-label="{{ 'CATEGORIES.SUBCLASS' | translate }} {{
    category.name | stringdefaulter
  }}"
  (click)="navigateToCategories()"
>
  {{ category.name | stringdefaulter }}
</a>
