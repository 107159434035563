import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { CustomGuideInfo } from '../../core/store/custom-guide/custom-guide.selectors';
import { CustomerBrand } from '../../core/services/session/models/session-record';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import { CriticalItemsFacade } from '../../core/store/critical-items/critical-items.facade';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { OrderGuideFacade } from '../../core/store/order-guide/order-guide.facade';
import { SessionFacade } from '../../core/store/session/session.facade';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { CustomGuideCreationSourceAnalyticLabel } from '../lists-analytics.constants';
import { AnalyticsEventInfo } from '../../shared/analytics/analytics-event-info';
import { OrderGuide } from '../../core/store/order-guide/models/order-guide';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import { guideDifferenceHelpModal } from './guide-difference-help-modal/guide-difference-help-modal';
import { HeroImagesComponent } from '../../shared/hero-images/hero-images.component';
import { MainGuideCardComponent } from './main-guide-card/main-guide-card.component';
import { MatIcon } from '@angular/material/icon';
import { ListCardComponent } from '../list-card/list-card.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

const miniTabletScreenSize = 601;
const miniDesktopScreenSize = 901;

@Component({
  selector: 'naoo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HeroImagesComponent,
    MainGuideCardComponent,
    MatIcon,
    ListCardComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  customGuideInfos$: Observable<CustomGuideInfo[]>;
  destroyed$ = new Subject();
  criticalItemsCount$: Observable<number>;
  orderGuideCount$: Observable<number>;
  orderGuide$: Observable<OrderGuide>;
  showCopyGuide = true;
  customerBrand: CustomerBrand;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;

  constructor(
    private _window: Window,
    private analytics: NaooAnalyticsManager,
    private changeDetector: ChangeDetectorRef,
    private criticalItemsFacade: CriticalItemsFacade,
    private customGuideFacade: CustomGuideFacade,
    private customDialogService: CustomDialogService,
    private dialogService: DefaultDialogService,
    private orderGuideFacade: OrderGuideFacade,
    private sessionFacade: SessionFacade,
  ) {}

  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(
        map((event) => (event.target as Window).innerWidth),
        startWith(this._window.innerWidth),
        debounceTime(50),
        takeUntil(this.destroyed$),
      )
      .subscribe((innerWidth) => this.onScreenResize(innerWidth));

    this.customGuideInfos$ =
      this.customGuideFacade.getAllLoadedSortedCustomGuideInfoRecords();

    this.criticalItemsCount$ = this.criticalItemsFacade
      .getLoadedValidCriticalItems()
      .pipe(map((criticalItems) => criticalItems.length));

    this.orderGuide$ = this.orderGuideFacade.getOrderGuide();
    this.orderGuideCount$ = this.orderGuideFacade
      .getLoadedOrderGuideMaterials()
      .pipe(map((materialNumbers) => materialNumbers?.size));

    this.sessionFacade
      .getLoadedSession()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sessionInfo) => {
        this.showCopyGuide = sessionInfo
          ? sessionInfo.customers.length > 1
          : false;
        this.customerBrand = sessionInfo.activeCustomer.brand;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onScreenResize(width: number): void {
    this.isMobile = width < miniTabletScreenSize;
    this.isDesktop = width >= miniDesktopScreenSize;
    this.isTablet =
      width >= miniTabletScreenSize && width < miniDesktopScreenSize;
    this.changeDetector.markForCheck();
  }

  openCreateCustomGuideModal() {
    this.customDialogService.openCreateCustomGuideModal(
      CustomGuideCreationSourceAnalyticLabel.CreateNewGuide,
      [],
    );
  }

  openImportGuideModal() {
    this.customDialogService.importGuideModal();
    const event: AnalyticsEventInfo = {
      action: 'Click',
      category: 'Import Custom Guide',
      label: 'Import Guide Button',
    };
    this.analytics.trackAnalyticsEvent(event);
  }

  openGuideDifferenceHelpModal() {
    this.dialogService.helperModal(
      'order-guide-difference-helper',
      guideDifferenceHelpModal,
    );
    const event: AnalyticsEventInfo = {
      action: 'Click',
      category: 'guides',
      label: 'What is the difference',
    };
    this.analytics.trackAnalyticsEvent(event);
  }
}
