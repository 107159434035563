@if (viewModel) {
  <div class="preview-cart">
    <div class="header">
      <div class="cart-last-updated">
        {{ 'CART.LAST_UPDATED' | translate }}
        {{ viewModel.lastUpdated | naooDate: 'longDateNoDayAbbrMo' : 'UTC' }}
      </div>
      <button
        attr.aria-label="{{ 'CLOSE' | translate }}"
        class="close-button"
        (click)="closeCartPreview()"
      >
        <img
          class="close-button-img"
          src="assets/images/close-icon.svg"
          alt="{{ 'CLOSE' | translate }}"
        />
      </button>
    </div>
    <form class="search-container">
      <div class="search-bar">
        <div class="search-icon">
          <mat-icon svgIcon="search_icon_v2"></mat-icon>
        </div>
        <div class="search-box">
          <input
            type="text"
            autocomplete="off"
            [attr.aria-label]="'SEARCH.TITLE' | translate"
            [placeholder]="'SEARCH.TITLE' | translate"
            (input)="updateSearchText($event)"
            [(ngModel)]="searchText"
            [ngModelOptions]="{ standalone: true }"
          />
          @if (searchText?.length) {
            <button
              class="clear-search-button"
              [attr.aria-label]="'SEARCH.CANCEL' | translate"
              (click)="resetSearch()"
            >
              <mat-icon svgIcon="clear_icon"></mat-icon>
            </button>
          }
        </div>
      </div>
    </form>
    <div class="total-price-container">
      <div class="total-price-title">Total:</div>
      <div class="total-price">
        {{
          this.viewModel.totalPrice
            | naoocurrency: 'CAD' : 'symbol-narrow' : '1.2-2'
        }}
      </div>
    </div>
    <mat-list class="list">
      @for (material of materials; track material.materialNumber) {
        <mat-list-item class="item" lines="10">
          <div class="material-number" matListItemTitle>
            {{ material.materialNumber }}
          </div>
          <div class="product-name">
            {{ material.description | stringdefaulter }}
          </div>
          @for (line of material.lines; track line.uom) {
            <div class="uom">
              <span>
                {{ { uomCode: line.displayUom } | materialunits }} ({{
                  line.quantity
                }})
              </span>
              @if (line.price) {
                <span>
                  {{ line.price.price * line.quantity | naooprice }}
                </span>
              }
            </div>
          }
        </mat-list-item>
      }
    </mat-list>
    @if (isMobile) {
      <div class="parentDiv">
        <button
          type="button"
          class="activate-button-preview-cart primary-button"
          (click)="activateCart()"
        >
          {{ 'CART.ACTIVATE_BUTTON' | translate }}
        </button>
      </div>
    }
  </div>
}
