<div class="image-container">
  <video
    [muted]="true"
    [autoplay]="true"
    [loop]="true"
    playsinline="playsinline"
    class="empty-guide-image"
    [controls]="false"
    disablePictureInPicture="true"
    [src]="'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.VIDEO_PATH' | translate"
  ></video>
</div>
<div class="title-info-container">
  <div class="title-container">
    <div class="critical-items-title">
      <mat-icon
        svgIcon="critical_item_star"
        class="critical-items-star"
      ></mat-icon>
      <span class="critical-items-title-text">{{
        'LISTS.CRITICAL_ITEMS_GUIDE' | translate
      }}</span>
    </div>
    <h2 class="main-title">
      {{ 'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.TITLE' | translate }}
    </h2>
  </div>
  <div class="info-container">
    <div class="info-block">
      <div class="block-marker"></div>
      <div class="content-block">
        <p class="empty-guide-description">
          {{
            'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.DESCRIPTION'
              | translate
          }}
        </p>
        @if (!isOffline) {
          <div class="empty-guide-navigation">
            <span class="nav-search">
              <button (click)="openMobileSearchModal()" class="nav-button">
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.SEARCH'
                    | translate
                }}
              </button>
              <span class="link-separator"></span>
            </span>
            <a
              id="catalog-link"
              [routerLink]="'/categories'"
              class="nav-button"
            >
              <span>
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.BROWSE_CATEGORIES'
                    | translate
                }}
              </span>
            </a>
            <span class="link-separator"></span>
            <a [routerLink]="'/guides'" class="nav-button">
              <span>
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.GUIDES'
                    | translate
                }}
              </span>
            </a>
            <span class="link-separator"></span>
            <a [routerLink]="'/orders'" class="nav-button">
              <span>
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.ORDER_HISTORY'
                    | translate
                }}
              </span>
            </a>
          </div>
        } @else {
          <div class="empty-guide-navigation offline-links">
            <span class="nav-search">
              <button
                (click)="openMobileSearchModal()"
                class="nav-button disabled"
                disabled
              >
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.SEARCH'
                    | translate
                }}
              </button>
              <span class="link-separator"></span>
            </span>
            <div class="nav-button disabled">
              <span>
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.BROWSE_CATEGORIES'
                    | translate
                }}
              </span>
            </div>
            <span class="link-separator"></span>
            <a [routerLink]="'/guides'" class="nav-button">
              <span>
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.GUIDES'
                    | translate
                }}
              </span>
            </a>
            <span class="link-separator"></span>
            <div class="nav-button disabled">
              <span>
                {{
                  'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.FIND_ITEMS.ORDER_HISTORY'
                    | translate
                }}
              </span>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="info-block">
      <div class="block-marker"></div>
      <div class="content-block">
        <h3 class="help-title">
          {{ 'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.ADD_ITEMS.TITLE' | translate }}
        </h3>
        <div
          class="empty-guide-description"
          [innerHTML]="
            'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.ADD_ITEMS.DESCRIPTION' | translate
          "
        ></div>
      </div>
    </div>
    <div class="info-block">
      <div class="block-marker"></div>
      <div class="content-block">
        <h3 class="help-title">
          {{
            'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.CRITICAL_ITEMS_HELP.TITLE'
              | translate
          }}
        </h3>
        <p class="empty-guide-description">
          {{
            'LISTS.EMPTY_CRITICAL_ITEMS_GUIDE.CRITICAL_ITEMS_HELP.DESCRIPTION'
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
</div>
