import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OfflineModeFacade } from '../../core/store/offline-mode/offline-mode.facade';
import { SessionFacade } from '../../core/store/session/session.facade';
import { Params, Router } from '@angular/router';
import { RouterFacade } from '../../core/store/router/router.facade';
import { HamburgerOrderMethodWidgetComponent } from '../../hamburger-order-method-widget/hamburger-order-method-widget/hamburger-order-method-widget.component';
import { DeliveryScheduleWidgetComponent } from '../../delivery-schedule-widget/delivery-schedule-widget/delivery-schedule-widget.component';
import { AccountMenuComponent } from '../../header/sub-header/account-menu/account-menu.component';
import { CartIconComponent } from '../../cart/cart-icon/cart-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderTabsComponent } from '../../header/header-tabs/header-tabs.component';
import { CustomerUnitSelectorComponent } from '../../customer-unit-selection/customer-unit-selector/customer-unit-selector.component';
import { Observable, Subject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import {
  MatList,
  MatListItemIcon,
  MatListItemMeta,
  MatListItemTitle,
} from '@angular/material/list';
import { GenericNavigationElement } from '../../shared/generic-navigation-modal/generic-navigation-model';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { CustomGuideFacade } from '../../core/store/custom-guide/custom-guide.facade';

@Component({
  selector: 'naoo-mobile-hamburger-menu-v2',
  standalone: true,
  imports: [
    HamburgerOrderMethodWidgetComponent,
    DeliveryScheduleWidgetComponent,
    AccountMenuComponent,
    CartIconComponent,
    TranslateModule,
    HeaderTabsComponent,
    CustomerUnitSelectorComponent,
    AsyncPipe,
    MatIcon,
    MatList,
    MatListItemIcon,
    MatListItemTitle,
    MatListItemMeta,
  ],
  templateUrl: './mobile-hamburger-menu-v2.component.html',
  styleUrl: './mobile-hamburger-menu-v2.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHamburgerMenuV2Component implements OnInit, OnDestroy {
  protected hasIspuOrExpressPermissions$: Observable<boolean> =
    this.sessionFacade.hasIspuOrExpressPermissions();
  protected isOffline$: Observable<boolean> =
    this.offlineModeFacade.getIsOffline();

  backRoute: Params;
  private readonly destroyed$ = new Subject();

  modalMenus: { [key: string]: GenericNavigationElement } = {
    GUIDES: {
      name: 'MENU.GUIDES',
      showBackNavigation: true,
      viewAllRoute: '/guides',
      childNavigation: [
        {
          name: 'LISTS.CRITICAL_ITEMS_GUIDE',
          route: '/guides/critical-items-guide',
        },
        {
          name: 'LISTS.ORDER_GUIDE',
          route: '/guides/order-guide',
        },
        {
          name: 'LISTS.CUSTOM_GUIDE',
          showBackNavigation: true,
          childNavigation: [],
        },
      ],
    },
  };

  constructor(
    private readonly offlineModeFacade: OfflineModeFacade,
    private readonly sessionFacade: SessionFacade,
    private readonly routerFacade: RouterFacade,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dialogService: CustomDialogService,
    private readonly customGuideFacade: CustomGuideFacade,
  ) {}

  ngOnInit(): void {
    this.routerFacade
      .getMenuBackRoute()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((route) => {
        this.backRoute = route;
        this.changeDetector.markForCheck();
      });

    this.customGuideFacade
      .getAllLoadedSortedCustomGuideInfoRecords()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((customGuides) => {
        const menu: GenericNavigationElement[] = [];
        customGuides.forEach((customGuide) => {
          menu.push({
            name: customGuide.name,
            route: `/guides/custom-guide/${customGuide.id}`,
          });
        });

        this.modalMenus['GUIDES'].childNavigation[2].childNavigation = menu;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  navigateBack(): void {
    this.router.navigate([this.backRoute['url']], {
      queryParams: this.backRoute['queryParams'],
    });
  }

  openGuides() {
    this.dialogService.openMenuModal(
      'MENU.MAIN_MENU',
      this.modalMenus['GUIDES'],
    );
  }
}
