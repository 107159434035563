import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  ValidationErrors,
  ValidatorFn,
  ReactiveFormsModule,
} from '@angular/forms';
import { SmsNotificationsDuplicateValidator } from '../../../../../shared/services/validators/sms-notifications-duplicate-validator.service';
import { NaooConstants } from '../../../../../shared/NaooConstants';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

/** @title Form field with custom telephone number input control. */
@Component({
  selector: 'naoo-telephone-form-control',
  templateUrl: './telephone-form-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    MatError,
    TranslateModule,
  ],
})
export class TelephoneFormControlComponent implements OnInit, AfterContentInit {
  @Input() contactInfo: string;
  @Output() isValid = new EventEmitter<boolean>();
  @Output() value = new EventEmitter<string>();
  @ViewChild('phoneElement', { static: true }) phoneElement: ElementRef;

  readonly formattedMaxLength = 16;

  telephoneControl: FormControl;
  isDuplicateNumber: boolean;
  isInvalidLength: boolean;
  displayLengthErrors: boolean;
  phoneValid: boolean;

  constructor(
    private smsNotificationsDuplicateValidator: SmsNotificationsDuplicateValidator,
  ) {}

  ngOnInit() {
    const formattedPhoneNumber = this.formatPhoneNumber(this.contactInfo);
    this.telephoneControl = new FormControl(
      formattedPhoneNumber,
      [this.validatePhoneLength()],
      this.smsNotificationsDuplicateValidator
        .validate(this.contactInfo)
        .bind(this.smsNotificationsDuplicateValidator),
    );
    setTimeout(() => this.validateForm(formattedPhoneNumber));
  }

  ngAfterContentInit() {
    setTimeout(() => this.phoneElement?.nativeElement.focus(), 0);
  }

  validatePhoneLength(): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!this.isInvalidLength || !this.displayLengthErrors) {
        return null;
      }
      return {
        numberLength: { value: control.value },
      };
    };
  }

  inputHandler(event: Event): void {
    this.displayLengthErrors = false;
    const phoneElement = (event as InputEvent).target as HTMLInputElement;
    const value = phoneElement.value;
    const cursor = phoneElement.selectionStart;
    this.telephoneControl.setValue(this.formatPhoneNumber(value));
    if (cursor < value.length) {
      phoneElement.selectionStart = cursor;
      phoneElement.selectionEnd = cursor;
    }
    this.validateForm(this.telephoneControl.value);
  }

  validateForm(phoneNumber: string) {
    this.isDuplicateNumber = this.telephoneControl?.hasError('duplicateNumber');
    this.isInvalidLength = this.checkPhoneLength(phoneNumber);
    this.phoneValid = !this.isDuplicateNumber && !this.isInvalidLength;
    this.isValid.emit(this.phoneValid);
    if (this.phoneValid) {
      this.value.emit(
        this.removeNonDigitsFromString(this.telephoneControl?.value),
      );
    }
  }

  showValidation() {
    this.displayLengthErrors = true;
    this.telephoneControl?.updateValueAndValidity();
  }

  checkPhoneLength(phoneNumber: string): boolean {
    return phoneNumber?.length !== this.formattedMaxLength;
  }

  private formatPhoneNumber(contactInfo: string): string {
    if (contactInfo?.length < 1) {
      return '';
    }
    const stripped = this.removeNonDigitsFromString(contactInfo);
    const area = stripped.slice(0, 3);
    const exchange = stripped.slice(3, 6);
    const subscriber = stripped.slice(6, 10);

    let formatted = area;
    if (exchange !== '') {
      formatted += ` ${NaooConstants.NDASH} ${exchange}`;
    }
    if (subscriber !== '') {
      formatted += ` ${NaooConstants.NDASH} ${subscriber}`;
    }
    return formatted;
  }

  private removeNonDigitsFromString(contactInfo: string): string {
    return contactInfo?.replace(/\D/g, '');
  }
}
