import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemDetail } from '../models/order-confirmation';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import { LocalizationService } from '../../../shared/services/translation/localization.service';
import { NaooStringDefaulter } from '../../../shared/string-defaulter/naoo-string-defaulter';
import { MatIcon } from '@angular/material/icon';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { PackSizePipe } from '../../../shared/pipes/pack-size.pipe';
import { NaooImagePipe } from '../../../shared/pipes/naoo-image.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-confirmation-material-details',
  templateUrl: './confirmation-material-details.component.html',
  styleUrls: ['./confirmation-material-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    NaooStringDefaulterPipe,
    PackSizePipe,
    NaooImagePipe,
    TranslateModule,
  ],
})
export class ConfirmationMaterialDetailsComponent {
  @Input() itemDetail: ItemDetail;

  readonly imageDimension = 40;

  constructor(
    private localizationService: LocalizationService,
    private stringDefaulter: NaooStringDefaulter,
  ) {}

  noImageAvailableURL(event: UIEvent): void {
    (<HTMLImageElement>event.target).src = NaooConstants.placeHolderImagePath;
    (<HTMLImageElement>event.target).srcset =
      NaooConstants.placeHolderImagePath;
  }

  getSubstitutedItemDescription(itemDetail: ItemDetail) {
    const description = this.stringDefaulter.getString(
      itemDetail.substitutedItem.description,
      this.localizationService.currentLanguage,
    );
    return `${description} | #${this.itemDetail.substitutedItem.id}`;
  }
}
