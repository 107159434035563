@if (!!orderConfirmation) {
  <div class="container">
    <h1 class="thank-you padded">
      {{
        (hasSplitOrders
          ? 'ORDER_CONFIRMATION.THANK_YOU_PLURAL'
          : 'ORDER_CONFIRMATION.THANK_YOU'
        ) | translate
      }}
    </h1>
    <h3 class="notice padded">
      <span>{{ 'ORDER_CONFIRMATION.NOTICE' | translate }}</span>
      <span
        ><a
          class="orders"
          [routerLink]="ordersPath"
          [queryParams]="{ activeTab: 'order-history' }"
          >{{ 'ORDER_CONFIRMATION.ORDERS' | translate }}
        </a></span
      >
    </h3>
    @for (headerError of orderConfirmation.headerErrors; track headerError) {
      <naoo-order-confirmation-header-error
        class="padded"
        [headerError]="headerError"
      >
      </naoo-order-confirmation-header-error>
    }
    <naoo-order-confirmation-header
      class="padded"
      [header]="orderConfirmation?.header"
    ></naoo-order-confirmation-header>
    @if (orderConfirmation.orders.errorMaterials?.length > 0) {
      <naoo-error-materials
        class="item-exceptions padded"
        [errorMaterials]="orderConfirmation.orders.errorMaterials"
        [materialRelatedRouteDate]="orderConfirmation.materialRelatedRouteDate"
      ></naoo-error-materials>
    }
    @if (orderConfirmation.orders.warningMaterials?.length > 0) {
      <naoo-warning-materials
        class="item-exceptions padded"
        [warningMaterials]="orderConfirmation.orders.warningMaterials"
        [materialRelatedRouteDate]="orderConfirmation.materialRelatedRouteDate"
      ></naoo-warning-materials>
    }
    @if (orderConfirmation.orders.stillProcessingMaterials?.length > 0) {
      <naoo-still-processing-materials
        class="items-processing padded"
        [customerBrand]="customerBrand$ | async"
        [processingOrders]="orderConfirmation.orders.stillProcessingMaterials"
      ></naoo-still-processing-materials>
    }
    @if (hasConfirmedOrders) {
      <div class="order-summary-headline">
        <mat-icon svgIcon="success-icon" class="order-summary-icon"></mat-icon>
        <div class="headline-text-box order-summary-text">
          {{ 'ORDER_CONFIRMATION.ORDER_SUMMARY' | translate }}
        </div>
      </div>
    }
    @for (
      nonStockOrder of orderConfirmation.orders.nonStockOrders;
      track nonStockOrder
    ) {
      <naoo-special-orders
        class="special-orders"
        [nonStockOrder]="nonStockOrder"
      ></naoo-special-orders>
    }
    @for (
      dropShipOrder of orderConfirmation.orders.dropShipOrders;
      track dropShipOrder
    ) {
      @if (isRetalix$ | async) {
        <naoo-drop-ship-orders-v1
          class="drop-ship-orders"
          [dropShipOrder]="dropShipOrder"
        ></naoo-drop-ship-orders-v1>
      } @else {
        <naoo-drop-ship-orders
          class="drop-ship-orders"
          [dropShipOrder]="dropShipOrder"
        ></naoo-drop-ship-orders>
      }
    }
    @for (
      stockOrder of orderConfirmation.orders.mainOrders;
      track stockOrder;
      let i = $index
    ) {
      <naoo-standard-orders
        class="standard-orders"
        [stockOrder]="stockOrder"
        [orderIndex]="i"
        [isSplitOrder]="orderConfirmation.orders.mainOrders.length > 1"
      ></naoo-standard-orders>
    }
  </div>
}
