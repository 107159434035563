<div>
  <naoo-hero-images [hostComponent]="'my-orders-v4'"></naoo-hero-images>
  <div class="naoo-hero-page-container">
    @if (isTabActive(OrdersTab.GoPoints)) {
      <div class="loyalty-heading">
        <naoo-go-points-logo
          [context]="'naoo-orders'"
          [header]="true"
        ></naoo-go-points-logo>
      </div>
    } @else {
      <h1 class="naoo-heading default-heading">
        {{ 'MENU.ORDERS' | translate }}
      </h1>
    }
    @if (tabs.length > 0) {
      <mat-tab-group
        [disableRipple]="true"
        [selectedIndex]="activeTabIndex"
        [mat-stretch-tabs]="false"
        (selectedIndexChange)="handleActiveTabChanged($event)"
        class="tab-group no-print-tab-labels"
      >
        <mat-tab
          class="order-history-container"
          label="{{ 'ORDERS.HISTORY.TITLE' | translate }}"
        >
          <ng-template matTabContent>
            <naoo-order-history></naoo-order-history>
          </ng-template>
        </mat-tab>
        @if (isSpecialOrderDashboardAccess$ | async) {
          <mat-tab
            label="{{ 'ORDERS.SPECIAL.TAB_TITLE' | translate }}"
            class="special-order-tab"
          >
            <ng-template matTabContent>
              <naoo-special-order></naoo-special-order>
            </ng-template>
          </mat-tab>
        }
        @if (hasStorePurchaseHistoryAccess$ | async) {
          <mat-tab
            label="{{ 'PURCHASE_HISTORY.TITLES.PURCHASE_HISTORY' | translate }}"
            class="store-purchase-history-tab"
          >
            <ng-template matTabContent>
              <naoo-store-purchase-history-container></naoo-store-purchase-history-container>
            </ng-template>
          </mat-tab>
        } @else {
          <mat-tab
            label="{{ 'ORDERS.INVOICES_CREDITS.TAB_TITLE' | translate }}"
            data-test-id="invoices-credits"
          >
            <!--For lazy loading -->
            <ng-template matTabContent>
              <naoo-invoices-credits></naoo-invoices-credits>
            </ng-template>
          </mat-tab>
        }
        @if (isLoyaltyProgramEligible$ | async) {
          <mat-tab
            label="{{ 'GO_POINTS.TITLES.TAB' | translate }}"
            class="go-points-tab"
          >
            <ng-template matTabContent>
              <naoo-go-points-container></naoo-go-points-container>
            </ng-template>
          </mat-tab>
        }
      </mat-tab-group>
    }
  </div>
</div>
