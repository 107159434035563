@if (shouldRenderGroupBy) {
  <h2 class="group-by-title">{{ 'LISTS.GROUP_ITEMS_BY.LABEL' | translate }}</h2>
  <naoo-guides-group-by
    [isOffline]="isOffline"
    [selectedGroupBy]="sideBar.groupBy"
    [groupByOptions]="sideBar.groupByOptions"
    (groupByChange)="handleGroupByChanged($event)"
  ></naoo-guides-group-by>
}

@if (!sortByDisabled) {
  <h2 class="sort-by-title">{{ 'ORDER_GUIDE.SORT_BY' | translate }}</h2>
  <naoo-guides-sort-by
    [selectedSortBy]="sideBar.sortBy"
    [sortByOptions]="sideBar.sortByOptions"
    (sortByChange)="handleSortByChanged($event)"
  ></naoo-guides-sort-by>
}

@if (!!sideBar.categoryHeaders && sideBar.categoryHeaders.length > 0) {
  <h2 class="categories-title">
    {{ 'ORDER_GUIDE.CATEGORIES' | translate }}
  </h2>
}

@for (categoryHeader of sideBar.categoryHeaders; track categoryHeader.name.en) {
  <naoo-guides-category-link
    [categoryHeader]="categoryHeader"
    (scrollToCategoryClick)="handleScrollToCategory($event)"
  ></naoo-guides-category-link>
}
