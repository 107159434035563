@for (
  categoryLink of categoryLinks;
  track categoryLink.categoryKey;
  let isLast = $last
) {
  <div class="breadcrumb-container">
    @if (!!categoryLink.name) {
      <button
        role="link"
        class="breadcrumb"
        (click)="onCategoryClicked(categoryLink, isLast)"
        [attr.tabindex]="isLast ? undefined : 0"
      >
        <a class="crumb" [ngClass]="{ 'current-crumb': isLast }">
          {{ categoryLink.name | stringdefaulter }}
        </a>
        @if (!isLast) {
          <div class="breadcrumb-arrow"></div>
        }
      </button>
    } @else {
      <button
        role="link"
        (click)="onCategoryClicked(categoryLink, isLast)"
        class="crumb catalog-crumb"
      >
        {{ 'CATEGORIES.TITLE' | translate }}
        <div class="breadcrumb-arrow"></div>
      </button>
    }
  </div>
}

<div class="mobile-breadcrumb">
  @if (!!mobileBreadCrumb) {
    <button
      role="link"
      class="breadcrumb"
      (click)="onCategoryClicked(mobileBreadCrumb)"
      [attr.tabindex]="0"
    >
      <div class="mobile-breadcrumb-arrow"></div>
      {{ 'CATEGORIES.BACK_TO' | translate }}
      {{ mobileBreadCrumb.name | stringdefaulter }}
    </button>
  }
</div>
