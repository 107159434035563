import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { SlideToggleComponent } from '../../../shared/slide-toggle/slide-toggle.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-guides-par-actions',
  templateUrl: './guides-par-actions.component.html',
  styleUrls: ['./guides-par-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, SlideToggleComponent, TranslateModule],
})
export class GuidesParActionsComponent {
  @Input() isOffline: boolean;
  @Input() isParEnabled: boolean;

  @Output() clearInventoryQuantities = new EventEmitter<void>();
  @Output() toggleParOrdering = new EventEmitter<boolean>();

  clearInventory() {
    this.clearInventoryQuantities.emit();
  }

  toggleParEnabled(event: MatSlideToggleChange) {
    this.toggleParOrdering.emit(event.checked);
  }
}
