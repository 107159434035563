<div class="cart-review-drop-ship-header">
  <div class="single-sub-header-key">
    {{ section.deliveryKey | translate }}
  </div>
  <span
    class="header-title"
    [innerHTML]="section.shippingOptionsKey | translate"
  ></span>
  <div class="sub-header-details-section">
    <div class="sub-header-details-section-left">
      <div class="freight-content">
        <mat-form-field class="freight-field">
          <mat-label>{{ section.selectShippingKey | translate }}</mat-label>
          <mat-icon
            class="freight-icon"
            [ngClass]="{ 'disabled-icon': isOffline }"
            matPrefix
            svgIcon="calendar-icon-v3"
            aria-hidden="true"
          ></mat-icon>
          <mat-select
            class="freight-option"
            [disabled]="isOffline"
            [placeholder]="section.selectShippingKey | translate"
            (selectionChange)="changeFreight($event)"
            disableOptionCentering
          >
            @if (selectedFreightOption) {
              <mat-select-trigger class="freight-display-trigger">
                <ng-container
                  [ngTemplateOutlet]="freightDisplay"
                  [ngTemplateOutletContext]="{
                    deliveryDate: selectedFreightOption.estimatedDeliveryDate,
                    freightCost: selectedFreightOption.freightCost,
                  }"
                >
                </ng-container>
              </mat-select-trigger>
            }
            @for (
              freightOption of section?.fulfillmentOrderSplit?.freightOptions;
              track freightOption
            ) {
              <mat-option [value]="freightOption" class="drop-ship-option">
                <ng-container
                  [ngTemplateOutlet]="freightDisplay"
                  [ngTemplateOutletContext]="{
                    deliveryDate: freightOption.estimatedDeliveryDate,
                    freightCost: freightOption.freightCost,
                  }"
                >
                </ng-container>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="shipping-cost shipping-cost-mobile-hidden">
        <div class="cost-item">
          <span>{{ section.subTotalKey | translate }}</span>
          <span>{{ estimatedSectionTotal$ | async | naooprice }}</span>
        </div>
        <div class="cost-item">
          <span>{{ section.shippingCostKey | translate }}</span>
          <span>{{ selectedFreightOption?.freightCost | naooprice }}</span>
        </div>
        <div class="cost-item">
          <span class="estimated-cost-title">{{
            section.estimatedCostKey | translate
          }}</span>
          <span class="estimated-cost-price">{{
            (estimatedSectionTotal$ | async) +
              selectedFreightOption?.freightCost | naooprice
          }}</span>
        </div>
      </div>
    </div>
    <div class="sub-header-details-section-right">
      <div
        class="address-content"
        [ngClass]="{ 'disabled-drop-down': isAddressDisabled || isOffline }"
      >
        <mat-form-field class="address-field">
          <mat-label>{{ section.selectAddressKey | translate }}</mat-label>
          <mat-icon
            class="store-icon"
            matPrefix
            svgIcon="store_icon_gray"
            aria-hidden="true"
          ></mat-icon>
          <mat-select
            class="address-option"
            [(value)]="selectedSite"
            (selectionChange)="changeSite($event)"
            [disabled]="isAddressDisabled || isOffline"
            disableOptionCentering
          >
            @if (selectedSite) {
              <mat-select-trigger class="address-display-trigger">
                {{
                  'CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.ADDRESS'
                    | translate
                      : {
                          address: selectedSite.address?.address1,
                        }
                }}
              </mat-select-trigger>
            }
            @for (site of section.sites; track site.siteId) {
              <mat-option [value]="site">
                {{ site?.address?.address1 }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <naoo-po-input
        [isPoRequired]="section.isPoNumberRequired"
        [poNumber]="poNumber"
        [isOffline]="isOffline"
        (updatedPoNumber)="updatePoNumber($event)"
      ></naoo-po-input>
    </div>
    <div class="shipping-cost shipping-cost-mobile-display">
      <div class="cost-item">
        <span>{{ section.subTotalKey | translate }}</span>
        <span>{{ estimatedSectionTotal$ | async | naooprice }}</span>
      </div>
      <div class="cost-item">
        <span>{{ section.shippingCostKey | translate }}</span>
        <span>{{ selectedFreightOption?.freightCost | naooprice }}</span>
      </div>
      <div class="cost-item">
        <span class="estimated-cost-title">{{
          section.estimatedCostKey | translate
        }}</span>
        <span class="estimated-cost-price">{{
          (estimatedSectionTotal$ | async) + selectedFreightOption?.freightCost
            | naooprice
        }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template
  #freightDisplay
  let-deliveryDate="deliveryDate"
  let-freightCost="freightCost"
>
  <span>{{ deliveryDate | naooDate: 'dayMonthDate' : '' }}</span>
  <span>{{
    !!freightCost
      ? (freightCost | naooprice)
      : ('CART_REVIEW.DROP_SHIP_ORDER.BANNER.DETAILS.FREE_COST' | translate)
  }}</span>
</ng-template>
