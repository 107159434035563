@if (!!costSummary) {
  <div class="floating-footer">
    @if (!!costSummary.warning) {
      <div class="cost-summary-footer-warning-row">
        {{ costSummary.warning | translate }}
      </div>
    }
    <div class="cost-summary-footer-body">
      <div class="cost-summary-footer-quantities">
        <div class="cost-summary-footer-quantity-title">
          {{ 'CART.ESTIMATED_COST' | translate }}
        </div>
        <div class="cost-summary-footer-quantity-cost">
          {{ standardOrderTotals.estimatedCost | naooprice }}
        </div>
      </div>
      <div class="cost-summary-footer-actions">
        <div class="footer-step-button-container">
          <naoo-order-submit-button-container
            class="order-submit-button"
            [context]="costSummary.context"
            [isCreditPaymentLoading]="isCreditPaymentLoading"
            [buttonState]="costSummary.submitButtonState"
            [isPunchOut]="costSummary.isPunchOut"
            [isPoInvalid]="isPoNumberInvalid"
            [isPoRequired]="costSummary.shippingInfo.isPoRequired"
            [cartTotal]="standardOrderTotals.estimatedCost"
            [hasIspuOrExpressEnabled]="costSummary.hasStoresPermissions"
            (submitButtonClick)="submitButtonClick.emit()"
          >
          </naoo-order-submit-button-container>
        </div>
        <button
          class="continue-shopping-footer-link"
          id="continue-shopping-link-fixed-floating"
          role="link"
          [routerLink]="isCartSummaryContext ? catalogPath : cartPath"
          [disabled]="costSummary.isOffline"
        >
          {{
            (isCartSummaryContext
              ? 'CART.CONTINUE_SHOPPING'
              : 'CART_REVIEW.BACK_TO_SHOPPING_CART'
            ) | translate
          }}
        </button>
      </div>
    </div>
  </div>
}
