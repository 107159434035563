import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchFacade } from '../../../../core/store/search/search.facade';
import {
  MatSlideToggleChange,
  MatSlideToggle,
} from '@angular/material/slide-toggle';
import { AvailableTodayFilterRecordState } from '../../../../core/store/search/search.state';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-available-today-filter',
  templateUrl: './available-today-filter.component.html',
  styleUrls: ['./available-today-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatSlideToggle, TranslateModule],
})
export class AvailableTodayFilterComponent {
  @Input() availableTodayFilter: AvailableTodayFilterRecordState;

  constructor(private searchFacade: SearchFacade) {}

  toggledAvailableTodayFilter($event: MatSlideToggleChange) {
    this.searchFacade.toggleAvailableTodayFilter($event.checked);
  }
}
