import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import moment, { Moment } from 'moment';
import {
  MatDatepicker,
  MatDatepickerInput,
} from '@angular/material/datepicker';
import {
  SplitOrder,
  SplitOrderType,
} from '../../../../../../core/services/cart-order/models/cart-order';
import { CartFacade } from '../../../../../../core/store/cart/cart.facade';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CartReviewSection } from '../../../../../../core/store/cart-review/cart-review.state';
import { CartReviewFacade } from '../../../../../../core/store/cart-review/cart-review.facade';
import { DeliveryScheduleEntryRecord } from '../../../../../../core/services/delivery-schedule/models/delivery-schedules-record';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { PoInputComponent } from '../../../../../po-input/po-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooDatePipe } from '../../../../../../shared/pipes/naoo-date.pipe';

@Component({
  selector: 'naoo-cart-review-missed-cutoff-header',
  templateUrl: './cart-review-missed-cutoff-header.component.html',
  styleUrls: ['./cart-review-missed-cutoff-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
  standalone: true,
  imports: [
    MatCheckbox,
    NgClass,
    MatIcon,
    MatDatepickerInput,
    MatDatepicker,
    PoInputComponent,
    TranslateModule,
    NaooDatePipe,
  ],
})
export class CartReviewMissedCutoffHeaderComponent
  implements OnInit, OnDestroy
{
  @Input() isOffline: boolean;
  @Input() section: CartReviewSection;
  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
  @ViewChild('dateInput', { static: true }) dateInput: ElementRef;

  readonly dateFormat = 'YYYY-MM-DD';
  readonly secondaryDateFormat = 'MM/DD/YYYY';

  private destroyed$ = new Subject<void>();
  private isFormControlDisabled = true;

  selectedDate: Date;
  splitOrder: SplitOrder;
  nextValidDeliverySchedules: DeliveryScheduleEntryRecord[];
  poNumber: string;

  constructor(
    private cartFacade: CartFacade,
    private cartReviewFacade: CartReviewFacade,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.poNumber = this.section.poNumber;
    this.changeDetectorRef.markForCheck();
    this.cartReviewFacade
      .getLoadedCutoffSplitNextValidDeliverySchedules()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (nextValidDeliverySchedules) =>
          (this.nextValidDeliverySchedules = nextValidDeliverySchedules),
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggle(event: MatCheckboxChange): void {
    if (event.checked) {
      this.enableFormControls();
      this.setNextAvailableDate();
      this.updateSplitOrderWithDateInput();
      this.setSplitOrder();
    } else {
      this.disableFormControl();
      this.clearEarlyCutOffSplitOrder();
    }
  }

  updateDeliveryDate(): void {
    if (this.updateSplitOrderWithDateInput()) {
      this.setSplitOrder();
    }
  }

  updatePoNumber(poNumber: string) {
    if (poNumber) {
      this.poNumber = poNumber;
      this.setSplitOrder();
    }
  }

  openCalendar(): void {
    this.datepicker.open();
  }

  get isFormDisabled(): boolean {
    return this.isFormControlDisabled || this.isOffline;
  }

  nextValidDeliveryDatesFilter = (moment: Moment): boolean => {
    if (!moment) {
      return false;
    }

    const currentDate = moment.format(this.dateFormat);
    return this.section.nextValidDeliverySchedules.some(
      (delivery) => delivery.routeDate === currentDate,
    );
  };

  private updateSplitOrder(): void {
    this.cartFacade.updateSplitOrder(this.splitOrder);
  }

  private clearEarlyCutOffSplitOrder(): void {
    this.cartFacade.clearEarlyCutOffSplitOrder();
  }

  private enableFormControls(): void {
    this.isFormControlDisabled = false;
  }

  private disableFormControl(): void {
    this.isFormControlDisabled = true;
  }

  private setNextAvailableDate(): void {
    if (this.nextValidDeliverySchedules?.length) {
      this.dateInput.nativeElement.value =
        this.nextValidDeliverySchedules[0]?.routeDate;
    }
  }

  private updateSplitOrderWithDateInput(): boolean {
    const formInputDate = this.dateInput.nativeElement.value;
    const isValid = !!formInputDate;
    if (isValid) {
      this.selectedDate = moment(formInputDate, [
        this.dateFormat,
        this.secondaryDateFormat,
      ]).toDate();
    }
    return isValid;
  }

  private setSplitOrder(): void {
    this.splitOrder = {
      orderType: SplitOrderType.EARLY_CUTOFF,
      materialNumbers: this.section.materialNumbers.map(
        (row) => row.value as string,
      ),
      customerPoNumber: this.poNumber,
      truckFulfillment: {
        routeDate: this.selectedDate,
        customerArrivalDate: this.selectedDate,
      },
    };

    this.updateSplitOrder();
  }
}
