import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-generic-banner',
  templateUrl: './generic-banner.component.html',
  styleUrls: ['./generic-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule],
})
export class GenericBannerComponent {
  @Input() messageTranslationKey: string;
  @Input() messageTranslationArgs: { [key: string]: string } = {};
  @Input() titleTranslationKey: string;
}
