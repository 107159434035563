import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CustomerNotification,
  EditableStatus,
  NotificationMethodCode,
} from '../../shared/models/customer-notifications';
import { DefaultDialogService } from 'src/app/shared/services/dialog/default-dialog/default-dialog.service';
import { ToastMessageService } from '../../../../shared/services/toast-message/toast-message.service';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-preferences-row-readonly',
  templateUrl: './preferences-row-readonly.component.html',
  styleUrls: ['./preferences-row-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NgClass, MatIconButton, MatTooltip, TranslateModule],
})
export class PreferencesRowReadonlyComponent {
  readonly tooltipShowDelay = 500;

  @Input() notification: CustomerNotification;
  @Input() isRetalix: boolean;
  @Input() methodCode: NotificationMethodCode;
  @Output() editRequest = new EventEmitter<CustomerNotification>();
  @Output() deleteRequest = new EventEmitter<CustomerNotification>();

  constructor(
    private defaultDialogService: DefaultDialogService,
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
  ) {}

  get isEmail(): boolean {
    return NotificationMethodCode.Email === this.methodCode;
  }

  get isEnabled(): boolean {
    return EditableStatus.Enabled === this.notification.editableStatus;
  }

  get getContactInfo(): string {
    if (NotificationMethodCode.Email === this.methodCode) {
      return this.notification?.contactInfo;
    }

    if (this.notification?.contactInfo?.length < 1) {
      return '';
    }
    const area = this.notification.contactInfo.slice(0, 3);
    const exchange = this.notification.contactInfo.slice(3, 6);
    const subscriber = this.notification.contactInfo.slice(6);
    return `${area} ${NaooConstants.NDASH} ${exchange} ${NaooConstants.NDASH} ${subscriber}`;
  }

  editClick() {
    this.editRequest.emit(this.notification);
  }

  deleteClick() {
    this.defaultDialogService.twoButtonModal(
      'delete-preference',
      {
        messageKey: this.getNotificationsText(
          'MODALS.DELETE_NOTIFICATION_TITLE',
        ),
        parameters: { value: this.getContactInfo },
      },
      'SHARED.DELETE',
      'SHARED.CANCEL',
      () => {
        this.deleteRequest.emit(this.notification);
        this.toastMessageService.showToastMessage(
          this.localizationService.instant(
            this.getNotificationsText('TOAST_MESSAGES.DELETE_SUCCESS_MESSAGE'),
          ),
        );
      },
      () => {},
      true,
      true,
    );
  }

  getNotificationsText(key: string): string {
    return `PREFERENCES.${this.methodCode}_NOTIFICATIONS.${key}`;
  }
}
