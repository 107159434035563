<div class="material-details">
  <img
    class="material-details-image"
    [alt]="
      itemDetail.description ? (itemDetail.description | stringdefaulter) : ''
    "
    [src]="(itemDetail.id | naooimage: imageDimension)?.src"
    [srcset]="(itemDetail.id | naooimage: imageDimension)?.srcSet"
    (error)="noImageAvailableURL($event)"
  />
  <div class="material-details-information">
    <div>
      <div class="material-description">
        {{ itemDetail.description | stringdefaulter }}
      </div>
      <div class="offering-details">
        <span>
          @if (itemDetail.customerMaterialNumber) {
            {{ itemDetail.customerMaterialNumber }} :
          }
          #{{ itemDetail.id }} | {{ itemDetail.brand | stringdefaulter }} |
          {{
            itemDetail.dimensions.units
              | packsize: itemDetail.dimensions.innerPackSize
          }}
        </span>
      </div>
    </div>

    @if (itemDetail.substitutedItem) {
      <div
        tabindex="0"
        class="substituted-message tooltip"
        [attr.tooltip]="getSubstitutedItemDescription(itemDetail)"
        [attr.aria-label]="getSubstitutedItemDescription(itemDetail)"
      >
        <mat-icon svgIcon="change_circle" class="substitute-icon"></mat-icon>
        <div class="order-issue-text-container">
          {{ 'ORDER_CONFIRMATION.SUBSTITUTED' | translate }}
        </div>
      </div>
    }
  </div>
</div>
