import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomDialogService } from '../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-search-button-mobile',
  templateUrl: './search-button-mobile.component.html',
  styleUrls: ['./search-button-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, NgClass, TranslateModule],
})
export class SearchButtonMobileComponent {
  @Input() isDisabled: boolean;
  constructor(public customDialogService: CustomDialogService) {}

  openMobileSearchModal() {
    this.customDialogService.openMobileSearchModal();
  }
}
