import { Localized } from '../../../shared/models/localized';
import { StatusSeverity } from '../../../shared/models/status-severity';

export enum SpecialOrdersStatus {
  Initial = 'Initial',
  Requested = 'Requested',
  Success = 'Success',
  Error = 'Error',
}

export interface SpecialOrdersState {
  status: SpecialOrdersStatus;
  records?: SpecialOrder[];
}

export const initialSpecialOrdersState: SpecialOrdersState = {
  status: SpecialOrdersStatus.Initial,
  records: undefined,
};

export interface SpecialOrder {
  plantArrivalDateTime: string;
  materialNumber: string;
  statusCode: string;
  statusDescription: Localized<string>;
  statusSecondaryDescription: Localized<string>;
  requestedQuantity: string;
  poNumber: string;
  severityCode?: StatusSeverity;
  salesOrderNumber?: string;
  groupNumber?: string;
  orderType?: string;
}
