import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ProductDirection } from '../../../product-row/product-guide-edit/product-direction';
import { ProductActionInfo } from '../../../product-row/product-guide-edit/product-action';
import { RenameCategoryAction } from '../../category-edit-menu/rename-category-modal/rename-category-action';
import { naooAnimations } from '../../../shared/animations/animations';
import { ListScrollService } from '../../../shared/services/list-scroll/list-scroll.service';
import { GuideAnimations } from '../../../shared/models/guide-animations';
import { CategorizedMaterials } from '../../../shared/models/categorized-materials';
import {
  buildGuideItemPlaceholder,
  GuideItem,
} from '../../../shared/models/guide-item';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import {
  getIndexForDirection,
  getMaterialOrderIndex,
} from '../shared/edit-custom-guide.util';
import { CustomGuideMaterialParOrder } from '../../../core/store/custom-guide/custom-guide.selectors';
import { ListsAnalyticsConstants } from '../../lists-analytics.constants';
import { Language } from '../../../core/services/session/models/session-record';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CategoryEditMenuComponent } from '../../category-edit-menu/category-edit-menu.component';
import { ProductGuideEditComponent } from '../../../product-row/product-guide-edit/product-guide-edit.component';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-edit-custom-guide-category',
  templateUrl: './edit-custom-guide-category.component.html',
  styleUrls: ['./edit-custom-guide-category.component.scss'],
  animations: [naooAnimations.highlightCategory, naooAnimations.moveRow],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconButton,
    NgClass,
    MatIcon,
    CategoryEditMenuComponent,
    ProductGuideEditComponent,
    DragDropModule,
    NaooStringDefaulterPipe,
    TranslateModule,
  ],
})
export class EditCustomGuideCategoryComponent implements OnInit {
  @Input() categoryIndex: number;
  @Input() sortBy: string;
  @Input() groupBy: string;
  @Input() customGuideId: string;
  @Input() categorizedMaterial: CategorizedMaterials;
  @Input() enableParOrdering: boolean;
  @Input() shouldDisableButtons: boolean;
  @Input() isUpArrowActive: boolean;
  @Input() isDownArrowActive: boolean;
  @Input() materialParOrders: Map<string, CustomGuideMaterialParOrder>;
  @Input() isMobile: boolean;

  @Output() moveCategorizedMaterial = new EventEmitter<{
    categoryName: string;
    direction: ProductDirection;
  }>();
  @Output()
  actOnMaterialInCategorizedMaterial = new EventEmitter<ProductActionInfo>();

  @Output() renameCategory = new EventEmitter<RenameCategoryAction>();

  @Output() deleteCategory = new EventEmitter<CategorizedMaterials>();

  disableAnimations = true;
  highlightCategoryState: string;
  moveState: string;
  ITEMS = 'ITEMS';
  placeHolderItem: GuideItem;

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private listScrollService: ListScrollService,
    private naooAnalyticsManager: NaooAnalyticsManager,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  get localizedHeaderAriaLabel(): string {
    const isSingular = this.categorizedMaterial.items.length === 1;
    if (this.hasCategoryName) {
      return isSingular
        ? 'LISTS.PRODUCT_COUNT_CATEGORIZED'
        : 'LISTS.PRODUCTS_COUNT_CATEGORIZED';
    } else {
      return isSingular
        ? 'LISTS.PRODUCT_COUNT_UNASSIGNED'
        : 'LISTS.PRODUCTS_COUNT_UNASSIGNED';
    }
  }

  get hasCategoryName(): boolean {
    return Object.keys(this.categorizedMaterial.categoryName).some(
      (language: Language) => !!this.categorizedMaterial.categoryName[language],
    );
  }

  ngOnInit() {
    this.placeHolderItem = buildGuideItemPlaceholder();
    this.changeDetectorRef.markForCheck();

    setTimeout(() => {
      this.disableAnimations = false;
      this.changeDetectorRef.markForCheck();
    });
  }

  getMaterialParOrder(materialNumber: string): CustomGuideMaterialParOrder {
    return this.materialParOrders
      ? this.materialParOrders.get(materialNumber)
      : undefined;
  }

  moveHighlight(moveDirection: string) {
    this.moveState = moveDirection;
    this.disableAnimations = false;
    this.changeDetectorRef.markForCheck();
  }

  scrollToCategory() {
    setTimeout(() => {
      this.listScrollService.goToCategory(
        this.categorizedMaterial.categoryName.en,
      );
    }, 350);
  }

  moveUp() {
    if (!this.isUpArrowActive) {
      return;
    }

    this.moveHighlight(GuideAnimations.MoveUp);
    this.scrollToCategory();

    this.moveCategorizedMaterial.emit({
      categoryName: this.categorizedMaterial.categoryName.en,
      direction: ProductDirection.up,
    });
    this.trackCategoryMoved('up');
  }

  moveDown() {
    if (!this.isDownArrowActive) {
      return;
    }

    this.moveHighlight(GuideAnimations.MoveDown);
    this.scrollToCategory();

    this.moveCategorizedMaterial.emit({
      categoryName: this.categorizedMaterial.categoryName.en,
      direction: ProductDirection.down,
    });
    this.trackCategoryMoved('down');
  }

  updateProductPosition(event: {
    materialNumber: string;
    direction: ProductDirection;
  }) {
    this.moveProductInCategory(event.materialNumber, event.direction);
  }

  actOnMaterialInCategory(event: ProductActionInfo) {
    this.actOnMaterialInCategorizedMaterial.emit(event);
  }

  actOnRenameCategory(event: RenameCategoryAction) {
    this.renameCategory.emit(event);
  }

  actOnDeleteCategory(event: CategorizedMaterials) {
    this.deleteCategory.emit(event);
  }

  animationComplete() {
    this.moveState = GuideAnimations.Default;
    this.highlightCategoryState = GuideAnimations.Highlighted;
    this.changeDetectorRef.markForCheck();
  }

  highlightComplete() {
    this.highlightCategoryState = GuideAnimations.Default;
    this.changeDetectorRef.markForCheck();
  }

  drop(event: CdkDragDrop<string, any>): void {
    const actualNewMaterialIndex = getMaterialOrderIndex(
      this.categorizedMaterial,
      event.currentIndex,
    );
    this.customGuideFacade.moveCustomGuideMaterials(
      this.customGuideId,
      event.item.data,
      this.categoryIndex,
      actualNewMaterialIndex,
    );
  }

  disableDrag(): boolean {
    return this.groupBy !== 'custom' || this.isMobile;
  }

  private moveProductInCategory(
    materialNumber: string,
    direction: ProductDirection,
  ): void {
    const originalMaterialIndex = this.categorizedMaterial.items.findIndex(
      (item) => {
        return item.material.materialNumber === materialNumber;
      },
    );
    const newMaterialIndex = getIndexForDirection(
      originalMaterialIndex,
      this.categorizedMaterial.items.length,
      direction,
    );

    if (newMaterialIndex !== -1) {
      const actualNewMaterialIndex = getMaterialOrderIndex(
        this.categorizedMaterial,
        newMaterialIndex,
      );
      this.customGuideFacade.moveCustomGuideMaterials(
        this.customGuideId,
        materialNumber,
        this.categoryIndex,
        actualNewMaterialIndex,
      );
    }
  }

  private trackCategoryMoved(direction: string) {
    const event: AnalyticsEventInfo = {
      action: ListsAnalyticsConstants.clickAction,
      category: ListsAnalyticsConstants.customGuideCategory,
      label: `${direction} arrow - category`,
    };
    this.naooAnalyticsManager.trackAnalyticsEvent(event);
  }
}
