import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CartCoupons } from '../shared/cart-coupon';
import { NgClass } from '@angular/common';
import { CartCouponInputComponent } from './cart-coupon-input/cart-coupon-input.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-cart-coupon-content',
  templateUrl: './cart-coupon-content.component.html',
  styleUrls: ['./cart-coupon-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, CartCouponInputComponent, TranslateModule],
})
export class CartCouponContentComponent {
  @Input() cartCoupons: CartCoupons;
  @Output() addCoupon = new EventEmitter<string>();
  @Output() removeCoupon = new EventEmitter<string>();

  addCouponCode(coupon: string): void {
    this.addCoupon.emit(coupon);
  }

  removeCouponCode(coupon: string): void {
    this.removeCoupon.emit(coupon);
  }
}
