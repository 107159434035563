import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NutritionInfo,
  NutritionLine,
} from '../../../../core/store/nutrition/models/nutrition';
import { NgClass } from '@angular/common';

@Component({
  selector: 'naoo-nutrition-label',
  templateUrl: './nutrition-label.component.html',
  styleUrls: ['./nutrition-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class NutritionLabelComponent {
  @Input() nutritionInfo: NutritionInfo;
  @Input() shouldRound: boolean;

  roundedAmount(nutrient: NutritionLine): number {
    return this.shouldRound ? nutrient.roundedAmount : nutrient.amount;
  }

  percentageAmount(value: number): string {
    return value !== null ? value + '%' : 'n/a';
  }

  parsedAmount(amount: number, uom: string): string {
    return amount !== null && uom !== null ? amount + uom : 'n/a';
  }

  getNutrientLabelText(label: string): string {
    return this.isAddedSugars(label) ? 'Includes' : label;
  }

  isAddedSugars(label: string): boolean {
    return label === 'Added Sugars';
  }

  get servingInfo(): string {
    const { amount, label, uom } = this.nutritionInfo.servingInformation ?? {};

    const servingInfoDisplay = !!amount && !!uom ? `${amount} ${uom}` : '';
    return servingInfoDisplay.concat(label ? ` (${label})` : '');
  }
}
