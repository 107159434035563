import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrderGuideFacade } from '../../../core/store/order-guide/order-guide.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-empty-category-modal',
  templateUrl: './empty-category-modal.component.html',
  styleUrls: ['./empty-category-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class EmptyCategoryModalComponent {
  constructor(
    private dialogRef: MatDialogRef<EmptyCategoryModalComponent>,
    private orderGuideFacade: OrderGuideFacade,
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  deleteCategory() {
    this.orderGuideFacade.removeEmptyCategories();
    this.closeModal();
    this.orderGuideFacade.saveOrderGuideChanges();
  }
}
