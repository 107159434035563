import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NaooConstants } from '../../../NaooConstants';
import { Router } from '@angular/router';
import { NaooAnalyticsManager } from '../../../analytics/NaooAnalyticsManager';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cart-import-toast',
  templateUrl: './cart-import-toast.component.html',
  styleUrls: ['./cart-import-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class CartImportToastComponent implements OnInit {
  itemQuantity: number = 0;
  viewCart: boolean = false;

  private readonly ANALYTICS_CATEGORY = 'Reorder From History';
  private readonly ANALYTICS_ACTION_CLICK = 'Click';
  private readonly ANALYTICS_LABEL_MERGE_CART_VIEW_CART =
    'View Cart - Items In Cart Page';

  constructor(
    private router: Router,
    private analyticsManager: NaooAnalyticsManager,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      itemQuantity: number;
      viewCart?: boolean;
    },
  ) {}

  ngOnInit() {
    this.itemQuantity = this.data?.itemQuantity;
    this.viewCart = this.data?.viewCart;
  }

  goToCart(): void {
    this.trackEvent(
      this.ANALYTICS_ACTION_CLICK,
      this.ANALYTICS_LABEL_MERGE_CART_VIEW_CART,
    );
    this.router.navigate([NaooConstants.CART_PATH]);
  }

  private trackEvent(action: string, label: string): void {
    this.analyticsManager.trackAnalyticsEvent({
      action,
      category: this.ANALYTICS_CATEGORY,
      label,
    });
  }
}
