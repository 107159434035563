@if (materialInfo) {
  <div class="product">
    @if (materialInfo.isEmpty) {
      <div
        class="product-image"
        [attr.aria-label]="materialInfo.description | stringdefaulter"
      >
        <ng-container [ngTemplateOutlet]="materialImage"></ng-container>
      </div>
    } @else {
      <span
        class="product-image"
        [attr.aria-label]="materialInfo.description | stringdefaulter"
      >
        <ng-container [ngTemplateOutlet]="materialImage"></ng-container>
      </span>
    }
    <div class="product-details">
      @if (materialInfo.isEmpty) {
        <div [attr.aria-label]="materialInfo.description | stringdefaulter">
          <ng-template [ngTemplateOutlet]="materialDescription"></ng-template>
        </div>
      } @else {
        <span [attr.aria-label]="materialInfo.description | stringdefaulter">
          <ng-template [ngTemplateOutlet]="materialDescription"></ng-template>
        </span>
      }
      <button
        id="remove-{{ materialNumber }}"
        class="remove-button"
        (click)="removeMaterial()"
      >
        {{ 'COMPARE_DOCKET.REMOVE' | translate }}
      </button>
    </div>
  </div>
}

<ng-template #materialImage>
  <naoo-product-image
    [materialNumber]="materialNumber"
    [dimension]="listImageDimension"
    [alt]="materialInfo.description | stringdefaulter"
  ></naoo-product-image>
</ng-template>

<ng-template #materialDescription>
  <div class="description">
    {{ materialInfo.description | stringdefaulter }}
  </div>
</ng-template>
