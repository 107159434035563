import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { PurchaseMaterial } from '../../../../../../core/store/store-purchase-details/models/store-purchase-details-view-model';
import { MaterialRowFacade } from '../../../../../../core/store/material-row/material-row.facade';
import { MaterialRow } from '../../../../../../core/store/material-row/models/material-row';
import { FulfillmentType } from '../../../../../../core/services/cart/models/cart-record';
import { StorePurchaseDetailsRowContentComponent } from './content/store-purchase-details-row-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-store-purchase-details-row-container',
  template: `<naoo-store-purchase-row-content
    [purchaseMaterial]="purchaseMaterial"
    [fulfillmentType]="fulfillmentType"
    [materialRow]="materialRow$ | async"
    [isMobileWidth]="isMobileWidth"
    [isTabletWidth]="isTabletWidth"
    [isLast]="isLast"
  >
  </naoo-store-purchase-row-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, StorePurchaseDetailsRowContentComponent],
})
export class StorePurchaseDetailsRowContainerComponent {
  @Input() fulfillmentType: FulfillmentType;
  @Input() isMobileWidth: boolean;
  @Input() isTabletWidth: boolean;
  @Input() isLast: boolean;
  @Input() set purchaseMaterial(purchaseMaterial: PurchaseMaterial) {
    this._purchaseMaterial = purchaseMaterial;
    this.materialRow$ = this.materialRowFacade.getMaterialRow({
      materialNumber: this.purchaseMaterial.materialNumber,
    });
  }

  get purchaseMaterial(): PurchaseMaterial {
    return this._purchaseMaterial;
  }

  constructor(private materialRowFacade: MaterialRowFacade) {}

  materialRow$: Observable<MaterialRow>;

  private _purchaseMaterial: PurchaseMaterial;
}
