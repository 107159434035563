import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CatalogResponse } from '../../shared/models/catalog/catalog-response';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-product-subclass',
  templateUrl: './product-subclass.component.html',
  styleUrls: ['./product-subclass.component.scss'],
  standalone: true,
  imports: [TranslateModule, NaooStringDefaulterPipe],
})
export class ProductSubclassComponent {
  @Input() category: CatalogResponse;
  @Output() navigateToCategoriesPage = new EventEmitter<string>();

  destroyed$ = new Subject<boolean>();

  navigateToCategories() {
    this.navigateToCategoriesPage.emit(this.category.categoryKey);
  }
}
