<div class="customer-unit-header">
  <div class="customer-info" [ngClass]="ngClassForWidth">
    <div class="customer-header-item customer-name-address">
      {{ 'CUSTOMER_UNIT.NAME_AND_ADDRESS' | translate }}
    </div>
    <div class="customer-header-item customer-number">
      {{ 'CUSTOMER_UNIT.CUSTOMER_NUMBER' | translate }}
    </div>
  </div>
  <div class="cart-info" [ngClass]="ngClassForWidth">
    <div class="customer-header-item customer-active-items">
      {{ 'CUSTOMER_UNIT.ACTIVE_CART_ITEMS' | translate }}
    </div>
    <div class="customer-header-item saved-carts">
      {{ 'CUSTOMER_UNIT.SAVED_CARTS' | translate }}
    </div>
    @if (isOrderSubmission) {
      <div class="customer-header-item submitted-carts">
        {{ 'CUSTOMER_UNIT.CARTS_SUBMITTED_FOR_APPROVAL' | translate }}
      </div>
    }
    @if (hasPunchedOutCarts) {
      <div class="customer-header-item punched-out-carts">
        {{ 'CUSTOMER_UNIT.PUNCHED_OUT_CARTS' | translate }}
      </div>
    }
  </div>
</div>
