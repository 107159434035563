@if ((orderableSmartCartRecommendationRecords$ | async)?.length) {
  <div class="main-container">
    <h3 class="title">
      {{ 'CART_REVIEW.SMART_CART_RECOMMENDATIONS.TITLE' | translate }}
    </h3>
    <span class="sub-title">
      {{ 'CART_REVIEW.SMART_CART_RECOMMENDATIONS.SUB_TITLE' | translate }}
    </span>
    @for (
      recommendation of orderableSmartCartRecommendationRecords$ | async;
      track recommendation.product
    ) {
      <naoo-smart-cart-record
        [recommendation]="recommendation"
        [analytics]="analytics"
        [context]="context"
      ></naoo-smart-cart-record>
    }
  </div>
}
