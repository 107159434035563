import { EventEmitter, Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { ImportGuideModalComponent } from '../../../../import-guide/import-guide-modal/import-guide-modal.component';
import { ReOrderModalComponent } from '../../../../re-order/re-order-modal/re-order-modal.component';
import { ReOrderValidationResult } from '../../../../re-order/re-order-validation-result';
import { OrderGuideChangesModalComponent } from '../../../../guides/order-guide/order-guide-changes-modal/order-guide-changes-modal.component';
import { CopyGuideModalComponent } from '../../../modals/copy-guide-modal/copy-guide-modal.component';
import { CustomGuideAction } from '../../../../lists/dashboard/custom-guide-action';
import { AnalyticsEventInfo } from '../../../analytics/analytics-event-info';
import { NaooAnalyticsManager } from '../../../analytics/NaooAnalyticsManager';
import { OrderGuideChangeItem } from '../../../../core/store/order-guide-change-history/order-guide-change-history.facade';
import { QuickAddLearnModalComponent } from '../../../../cart/cart-summary/active-cart/quick-add/learn-modal/quick-add-learn-modal.component';
import {
  ExportDialogFileFormat,
  ExportModalComponent,
} from '../../../modals/export-modal/export-modal.component';
import { ExportModalDataPoint } from '../../../models/export-modal-data-point';
import { ExportDetailsRecord } from '../../../../core/services/customer-preferences/models/customer-preferences-record';
import { MaterialRelatedContainerComponent } from '../../../../order-confirmation/material-related/material-related-container.component';
import { CustomerPreferencesFacade } from '../../../../core/store/customer-preferences/customer-preferences.facade';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ExportFeatureType } from '../../../models/export/export-properties';
import { EditOrderModalComponent } from '../../../../orders/order-details/edit-order-modal/edit-order-modal.component';
import { OrderDetailsLineViewModel } from '../../../models/order-details';
import { SupportContactModalComponent } from '../../../../header/support-contact-modal/support-contact-modal.component';
import { OrderItem } from '../../../../order-confirmation/shared/models/order-confirmation';
import { CreateCustomGuideModalComponent } from '../../../../lists/create-custom-guide-modal/create-custom-guide-modal.component';
import { CriticalItemHelperModalComponent } from '../../../../lists/dashboard/critical-item-helper-modal/critical-item-helper-modal.component';
import { RenameCustomGuideModalComponent } from '../../../../lists/dashboard/rename-custom-guide-modal/rename-custom-guide-modal.component';
import { CustomGuideCreationSourceAnalyticLabel } from '../../../../lists/lists-analytics.constants';
import { MobileSearchModalComponent } from '../../../../header/mobile-search-modal/mobile-search-modal.component';
import { CustomerBrand } from '../../../../core/services/session/models/session-record';
import { MaterialOverallocationContainerComponent } from '../../../../cart/cart-summary/entitlement/material-overallocation-container.component';
import { CreditCardModalComponent } from '../../../modals/credit-card-modal/credit-card-modal.component';
import {
  MaterialRow,
  MaterialRowContext,
} from '../../../../core/store/material-row/models/material-row';
import { NaooConstants } from '../../../NaooConstants';
import {
  SubmitLoadingModalComponent,
  SubmitLoadingModalData,
} from '../../../modals/submit-loading-modal/submit-loading-modal.component';
import { CartFacade } from '../../../../core/store/cart/cart.facade';
import { ImportOrderModalComponent } from '../../../../import-order/import-order-modal/import-order-modal.component';
import {
  BulkAddModalComponent,
  BulkAddWorkflow,
  CartActionType,
} from '../../../bulk-add-modal/bulk-add-modal.component';
import { CartQuantityUpdate } from '../../../../core/store/cart/cart.actions';
import { CartLine, CartMaterial } from '../../../models/cart-material';
import { ReOrderService } from '../../../../re-order/re-order.service';
import { SavedCart } from '../../../../core/store/multiple-carts/multiple-carts.state';
import { EmptyCategoryModalComponent } from '../../../../lists/dashboard/empty-category-modal/empty-category-modal.component';
import { GenericNavigationElement } from '../../../generic-navigation-modal/generic-navigation-model';
import { GenericNavigationModalComponent } from '../../../generic-navigation-modal/generic-navigation-modal.component';
import { Localized } from '../../../models/localized';

@Injectable({ providedIn: 'root' })
export class CustomDialogService {
  private static readonly ANALYTICS_CLICK_ACTION = 'click';
  private static readonly ANALYTICS_EXPORT_LABEL = 'export';
  private static readonly CUSTOM_GUIDE_MODAL = 'custom-guide-modal';
  private static readonly CREATE_CUSTOM_GUIDE = 'create-custom-guide';
  private static readonly MATERIAL_OVERALLOCATION_MODAL_ID =
    'material-overallocation';

  constructor(
    private dialog: MatDialog,
    private analyticsManager: NaooAnalyticsManager,
    private customerPreferencesFacade: CustomerPreferencesFacade,
    private cartFacade: CartFacade,
    private reOrderService: ReOrderService,
  ) {}

  public importOrderModal(
    cartActionType?: CartActionType,
  ): MatDialogRef<ImportOrderModalComponent> {
    return this.dialog.open(ImportOrderModalComponent, {
      id: 'import-order',
      panelClass: 'naoo-import-order-modal',
      disableClose: true,
      data: cartActionType,
    });
  }

  public bulkAddModal(
    bulkAddWorkflow: BulkAddWorkflow,
    reOrderData?: ReOrderValidationResult,
    nonDeletedMaterials?: CartMaterial[],
    savedCart?: SavedCart,
  ) {
    return this.dialog.open(BulkAddModalComponent, {
      id: 'bulk-add-modal',
      panelClass: 'naoo-bulk-add-modal',
      disableClose: true,
      data: {
        bulkAddWorkflow,
        reOrderData,
        nonDeletedMaterials,
        savedCart,
      },
    });
  }

  public bulkAddImportChecker(bulkAddWorkflow: BulkAddWorkflow) {
    this.cartFacade
      .getLoadedCart()
      .pipe(first())
      .subscribe((cart) => {
        const nonDeletedMaterials = cart.materials.filter(
          (material) => !material.deleted,
        );
        if (nonDeletedMaterials?.length > 0) {
          this.bulkAddModal(bulkAddWorkflow);
        } else {
          this.importOrderModal();
        }
      });
  }

  public copyGuideModal(dialogData: CustomGuideAction) {
    return this.dialog.open(CopyGuideModalComponent, {
      id: 'copy-guide',
      panelClass: 'naoo-copy-guide-modal',
      disableClose: true,
      data: dialogData,
    });
  }

  public creditCardModal(): MatDialogRef<CreditCardModalComponent> {
    return this.dialog.open(CreditCardModalComponent, {
      id: 'credit-card',
      panelClass: 'naoo-credit-card-modal',
      disableClose: true,
    });
  }

  public importGuideModal(): MatDialogRef<ImportGuideModalComponent> {
    return this.dialog.open(ImportGuideModalComponent, {
      id: 'import-guide',
      panelClass: 'naoo-import-guide-modal',
      disableClose: true,
      maxWidth: '90vw',
    });
  }

  public reOrderModal(
    reOrderData: ReOrderValidationResult,
  ): MatDialogRef<ReOrderModalComponent> {
    return this.dialog.open(ReOrderModalComponent, {
      id: 're-order',
      panelClass: 'naoo-re-order-modal',
      disableClose: true,
      maxWidth: '90vw',
      data: reOrderData,
    });
  }

  public reOrderChecker(reOrderData: ReOrderValidationResult) {
    if (Object.keys(reOrderData.nonOrderable).length > 0) {
      this.reOrderModal(reOrderData);
    } else {
      this.cartFacade
        .getLoadedCart()
        .pipe(first())
        .subscribe((cart) => {
          const nonDeletedMaterials = cart.materials.filter(
            (material) => !material.deleted,
          );
          if (nonDeletedMaterials?.length > 0) {
            this.bulkAddModal(
              BulkAddWorkflow.REORDER,
              reOrderData,
              nonDeletedMaterials,
            );
          } else {
            this.reOrderService.addMaterialsToCart(
              this.getCartQuantityUpdates(reOrderData),
            );
          }
        });
    }
  }

  private getCartQuantityUpdates(
    validationResult: ReOrderValidationResult,
  ): CartQuantityUpdate[] {
    return Object.keys(validationResult.orderable).map((materialNumber) => {
      const lines = validationResult.orderable[materialNumber].map(
        (item) => <CartLine>{ uom: item.uom, quantity: item.quantityOrdered },
      );
      return {
        materialNumber,
        lines,
        merge: true,
        context: MaterialRowContext.ReOrder,
      };
    });
  }

  public orderGuideChangesModal(changesData: OrderGuideChangeItem[]) {
    return this.dialog.open(OrderGuideChangesModalComponent, {
      id: 'order-guide-changes',
      panelClass: 'naoo-order-guide-changes-modal',
      maxWidth: '90vw',
      data: changesData,
    });
  }

  openMobileSearchModal() {
    this.dialog.open(MobileSearchModalComponent, {
      id: 'mobile-search',
      panelClass: 'mobile-search-modal',
      disableClose: true,
      height: '100%',
      maxWidth: '100%',
      width: '100%',
    });
  }

  public quickAddLearnMoreModal() {
    this.dialog.open(QuickAddLearnModalComponent, {
      id: 'quick-add-helper',
      disableClose: false,
      panelClass: 'naoo-quick-add-learn-modal',
    });
  }

  public exportModal(
    id: string,
    title: string,
    defaultFileFormats: ExportDialogFileFormat[],
    analyticsEventCategory: string,
    defaultExportModalDataPoints: ExportModalDataPoint[],
    exportFeatureType: ExportFeatureType,
    isPrintModal = false,
  ): Observable<MatDialogRef<ExportModalComponent>> {
    const fileFormats: ExportDialogFileFormat[] = [...defaultFileFormats];
    const dataPoints: ExportModalDataPoint[] = [
      ...defaultExportModalDataPoints,
    ];
    this.trackExportAnalytics(analyticsEventCategory);
    return this.customerPreferencesFacade.getLoadedExportPreferences().pipe(
      take(1),
      map((response) => {
        if (response) {
          const preferences: ExportDetailsRecord = response.find(
            (item) => item.exportFeature === exportFeatureType,
          );

          if (!isPrintModal) {
            this.updateExportTypeFilesWithCustomerPreferences(
              preferences,
              fileFormats,
            );
          }

          this.updateExportDataPointsWithCustomerPreferences(
            preferences,
            dataPoints,
          );
        }
        return this.dialog.open(ExportModalComponent, {
          id,
          panelClass: 'naoo-export-modal',
          disableClose: true,
          data: {
            title,
            fileFormats,
            analyticsEventCategory,
            dataPoints,
            exportFeatureType,
            isPrintModal,
          },
        });
      }),
    );
  }

  public materialRelatedModal(
    itemException: OrderItem,
    materialWithSubstitutes?: MaterialRow,
    isMobile?: boolean,
  ): MatDialogRef<MaterialRelatedContainerComponent> {
    return this.dialog.open(MaterialRelatedContainerComponent, {
      id: 'related-material',
      panelClass: 'naoo-material-related-container',
      disableClose: true,
      data: {
        itemException,
        materialWithSubstitutes,
        isMobile,
      },
    });
  }

  public editOrderModal(orderItem: OrderDetailsLineViewModel) {
    return this.dialog.open(EditOrderModalComponent, {
      id: 'edit-order',
      panelClass: 'naoo-edit-order-modal',
      disableClose: true,
      data: orderItem,
    });
  }

  public supportContactModal(customerBrand: CustomerBrand) {
    const SHOW_MODAL_ANALYTICS_EVENT: AnalyticsEventInfo = {
      action: 'display',
      category: 'support',
      label: customerBrand,
    };
    this.analyticsManager.trackAnalyticsEvent(SHOW_MODAL_ANALYTICS_EVENT);

    return this.dialog.open(SupportContactModalComponent, {
      id: 'contact-support',
      panelClass: 'naoo-support-contact-modal',
      disableClose: true,
      data: customerBrand,
    });
  }

  public openRenameCustomGuideModal(
    customGuideId: string,
    customGuideName: string,
  ) {
    return this.dialog.open(RenameCustomGuideModalComponent, {
      id: 'rename-custom-guide',
      disableClose: true,
      panelClass: CustomDialogService.CUSTOM_GUIDE_MODAL,
      data: {
        customGuideId,
        customGuideName,
      },
    });
  }

  public openEmptyCategoryModal() {
    return this.dialog.open(EmptyCategoryModalComponent, {
      id: 'empty-category',
      disableClose: true,
      panelClass: CustomDialogService.CUSTOM_GUIDE_MODAL,
    });
  }

  public openCreateCustomGuideModal(
    creationSourceLabel: CustomGuideCreationSourceAnalyticLabel,
    materialNumbers: string[],
    customGuideIdToDuplicate?: string,
  ) {
    return this.dialog.open(CreateCustomGuideModalComponent, {
      id: CustomDialogService.CREATE_CUSTOM_GUIDE,
      panelClass: CustomDialogService.CUSTOM_GUIDE_MODAL,
      disableClose: true,
      data: {
        materialNumbers,
        creationSourceLabel,
        customGuideIdToDuplicate,
      },
    });
  }

  public closeCreateCustomGuideModal() {
    const dialog = this.dialog.getDialogById(
      CustomDialogService.CREATE_CUSTOM_GUIDE,
    );
    if (dialog) {
      dialog.close();
    }
  }

  public openCriticalItemHelperModal() {
    return this.dialog.open(CriticalItemHelperModalComponent, {
      id: 'critical-item-helper',
      disableClose: true,
      panelClass: CustomDialogService.CUSTOM_GUIDE_MODAL,
    });
  }

  public openMaterialOverallocationModal(
    overallocatedMaterialsMap: Map<string, object>,
  ) {
    return this.dialog.open(MaterialOverallocationContainerComponent, {
      id: CustomDialogService.MATERIAL_OVERALLOCATION_MODAL_ID,
      maxWidth: '100%',
      disableClose: false,
      panelClass: 'naoo-material-overallocation-modal',
      data: overallocatedMaterialsMap,
    });
  }

  public closeMaterialOverallocationModal() {
    const dialog = this.dialog.getDialogById(
      CustomDialogService.MATERIAL_OVERALLOCATION_MODAL_ID,
    );
    if (dialog) {
      dialog.close();
    }
  }

  public openSubmitLoadingModal(dialogConfig?: Partial<MatDialogConfig>) {
    if (this.areAllDialogsClosed()) {
      const defaultConfig: MatDialogConfig = {
        id: NaooConstants.SUBMIT_LOADING_MODAL_ID,
        panelClass: 'naoo-loading-dialog',
        disableClose: true,
        data: {
          isDone$: new EventEmitter(),
        } as SubmitLoadingModalData,
      };
      const config: MatDialogConfig = {
        ...defaultConfig,
        ...dialogConfig,
      };
      if (!this.dialog.getDialogById(config.id)) {
        this.dialog.open(SubmitLoadingModalComponent, config);
      }
    }
  }

  public markSubmitModalDone(): void {
    const submitLoadingDialog = this.dialog.getDialogById(
      NaooConstants.SUBMIT_LOADING_MODAL_ID,
    );
    const isDone$ = submitLoadingDialog?.componentInstance?.data?.isDone$;
    if (isDone$) {
      isDone$.emit();
    }
  }

  public closeSubmitLoadingModal(): void {
    const submitLoadingDialog = this.dialog.getDialogById(
      NaooConstants.SUBMIT_LOADING_MODAL_ID,
    );
    submitLoadingDialog?.close();
  }

  public openMenuModal(
    parentMenuName: string | Localized<string>,
    menu: GenericNavigationElement,
  ) {
    return this.dialog.open(GenericNavigationModalComponent, {
      id: 'generic-navigation-menu',
      disableClose: true,
      height: '100%',
      maxWidth: '100%',
      width: '100%',
      data: {
        parentMenuName,
        menu,
      },
    });
  }

  private updateExportTypeFilesWithCustomerPreferences(
    preferences: ExportDetailsRecord,
    exportFileTypes: ExportDialogFileFormat[],
  ) {
    if (preferences) {
      exportFileTypes.forEach((type) => {
        type.name === preferences.selectedType
          ? (type.isChecked = true)
          : (type.isChecked = false);
      });
    }
  }

  private updateExportDataPointsWithCustomerPreferences(
    preferences: ExportDetailsRecord,
    exportDataPoint: ExportModalDataPoint[],
  ) {
    if (!!preferences && !!preferences.selectedColumns) {
      exportDataPoint.forEach((dataPoint) => {
        dataPoint.isSelected = !!preferences.selectedColumns.find(
          (item) => item === dataPoint.name,
        );
      });

      exportDataPoint.sort(function (a, b) {
        return (
          preferences.selectedColumns.indexOf(a.name) -
          preferences.selectedColumns.indexOf(b.name)
        );
      });
    }
  }

  private trackExportAnalytics(eventCategory: string): void {
    const eventInfo: AnalyticsEventInfo = {
      action: CustomDialogService.ANALYTICS_CLICK_ACTION,
      category: eventCategory,
      label: CustomDialogService.ANALYTICS_EXPORT_LABEL,
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private areAllDialogsClosed(): boolean {
    return this.dialog.openDialogs.every(
      (dialogRef) => MatDialogState.CLOSED === dialogRef.getState(),
    );
  }
}
