import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { selectBackNavigationFromQueryParams } from './router.selectors';

@Injectable({ providedIn: 'root' })
export class RouterFacade {
  constructor(private readonly store: Store) {}

  getMenuBackRoute(): Observable<Params> {
    return this.store.select(selectBackNavigationFromQueryParams);
  }
}
