import { Injectable } from '@angular/core';
import {
  ProductFlag,
  ProductFlagStyle,
} from '../../../product-row/product-flag/product-flag.component';
import { MaterialCutoff } from '../../models/material-cutoff';
import moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { MaterialCutoffFacade } from '../../../core/store/material-cutoff/material-cutoff.facade';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { SessionFacade } from '../../../core/store/session/session.facade';
import { MaterialAvailability } from '../../models/material-availability';
import { StockType } from '../../../core/services/material-availability/model/material-availabilities-record';
import {
  CurrentSystem,
  Locale,
} from '../../../core/services/session/models/session-record';
import { LocalizationService } from '../translation/localization.service';
import { NaooDeliveryOrShipPipe } from '../../pipes/delivery-or-ship.pipe';

@Injectable({ providedIn: 'root' })
export class ProductFlagService {
  readonly analyticsEventCategory = 'Product Flags';
  private timeZone: string;
  private readonly cache = new Map<string, BehaviorSubject<ProductFlag[]>>();
  currentSystem: CurrentSystem;

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly sessionFacade: SessionFacade,
    private readonly materialCutoffFacade: MaterialCutoffFacade,
    private readonly cartFacade: CartFacade,
    private readonly deliveryOrShipPipe: NaooDeliveryOrShipPipe,
  ) {
    this.sessionFacade.getLoadedActiveCustomer().subscribe((activeCustomer) => {
      this.timeZone = activeCustomer.timeZone;
    });

    this.sessionFacade.getLoadedCurrentSystem().subscribe((currentSystem) => {
      this.currentSystem = currentSystem;
    });
  }

  loadProductFlags(
    offeringId: string,
    materialAvailability: MaterialAvailability,
    isOrderGuideItem: boolean,
  ) {
    const productFlags = this.createAppropriateFlags(
      materialAvailability,
      isOrderGuideItem,
    );
    this.setProductFlags(offeringId, productFlags);

    if (materialAvailability && materialAvailability.cutoffCode) {
      this.cartFacade.getLoadedCart().subscribe((cart) => {
        this.materialCutoffFacade
          .getMaterialCutoffTime(materialAvailability.cutoffCode)
          .subscribe((materialCutoff) => {
            const hasRouteDate = !!cart?.routeDate;
            const earlyCutoffTooltipText = this.getEarlyCutoffTooltipText(
              materialCutoff,
              hasRouteDate,
            );
            const newProductFlags = this.createAppropriateFlags(
              materialAvailability,
              isOrderGuideItem,
              earlyCutoffTooltipText,
            );
            this.setProductFlags(offeringId, newProductFlags);
          });
      });
    }
  }

  observeProductFlags(offeringId: string): BehaviorSubject<ProductFlag[]> {
    this.createObservableIfNeeded(offeringId);
    return this.cache.get(offeringId);
  }

  private createAppropriateFlags(
    materialAvailability: MaterialAvailability,
    isOrderGuideItem: boolean,
    earlyCutoffTooltipText?: string,
  ): ProductFlag[] {
    const productFlags: ProductFlag[] = [];
    if (
      this.isCutoffDisplayable(materialAvailability, earlyCutoffTooltipText)
    ) {
      productFlags.push(this.createEarlyCutoffFlag(earlyCutoffTooltipText));
    }
    if (
      materialAvailability &&
      materialAvailability.stockType === StockType.DropShip
    ) {
      productFlags.push(this.createDropShipFlag());
    } else if (
      materialAvailability &&
      (materialAvailability.stockType === StockType.SpecialOrder ||
        materialAvailability.stockType === StockType.SpecialOrderSAP)
    ) {
      productFlags.push(this.createSpecialOrderFlag());
    }
    if (isOrderGuideItem) {
      productFlags.push(this.createOrderGuideFlag());
    }
    if (materialAvailability && materialAvailability.isContract) {
      productFlags.push(this.createContractFlag());
    }
    if (materialAvailability && materialAvailability.isLocal) {
      productFlags.push(this.createLocalFlag());
    }
    return productFlags;
  }

  private isCutoffDisplayable(
    availability: MaterialAvailability,
    earlyCutoffTooltipText?: string,
  ) {
    return (
      !!availability &&
      !!availability.cutoffCode &&
      (!!earlyCutoffTooltipText || this.currentSystem !== CurrentSystem.Sap)
    );
  }

  private createEarlyCutoffFlag(tooltipText?: string): ProductFlag {
    return {
      title: this.localizationService.instant('PRODUCT_FLAG.EARLY_CUTOFF'),
      style: ProductFlagStyle.Red,
      tooltipText: tooltipText,
      analyticsEvent: {
        action: 'hover',
        category: this.analyticsEventCategory,
        label: 'view cutoff date/time',
      },
    };
  }

  private createOrderGuideFlag(): ProductFlag {
    return {
      title: this.localizationService.instant('LISTS.ORDER_GUIDE'),
      style: ProductFlagStyle.Green,
    };
  }

  private setProductFlags(offeringId: string, productFlags: ProductFlag[]) {
    this.createObservableIfNeeded(offeringId);
    const observable = this.cache.get(offeringId);
    observable.next(productFlags);
  }

  private createObservableIfNeeded(offeringId: string) {
    let observable = this.cache.get(offeringId);
    if (!observable) {
      observable = new BehaviorSubject(null);
      this.cache.set(offeringId, observable);
    }
  }

  private getEarlyCutoffTooltipText(
    materialCutoff: MaterialCutoff,
    hasShipDate: boolean,
  ): string {
    if (
      (!hasShipDate && !CurrentSystem.isMygfsOrSap(this.currentSystem)) ||
      (!hasShipDate &&
        materialCutoff &&
        materialCutoff.timestamp &&
        CurrentSystem.isMygfsOrSap(this.currentSystem))
    ) {
      return this.localizationService.instant(
        this.deliveryOrShipPipe.transform('PRODUCT_FLAG_NO_DATE'),
      );
    } else if (
      materialCutoff === null &&
      !CurrentSystem.isMygfsOrSap(this.currentSystem)
    ) {
      return this.localizationService.instant('PRODUCT_FLAG.ERROR');
    } else if (materialCutoff && materialCutoff.timestamp) {
      return this.getMaterialCutoffText(materialCutoff);
    } else {
      return null;
    }
  }

  private getMaterialCutoffText(materialCutoff: MaterialCutoff): string {
    const locale = this.localizationService.currentLocale;
    const sortBy = this.localizationService.instant('PRODUCT_FLAG.ORDER_BY');
    const on = this.localizationService.instant('PRODUCT_FLAG.ON');

    const momentTimeZone = moment.tz(materialCutoff.timestamp, this.timeZone);
    momentTimeZone.locale(locale);

    const timeZoneAbbr = this.localizationService.instant(
      'TIMEZONES.' + momentTimeZone.zoneAbbr(),
    );

    if ([Locale.en_CA, Locale.en_US, Locale.es_US].includes(locale)) {
      return `${sortBy} ${momentTimeZone.format(
        'LT',
      )} ${timeZoneAbbr} ${on} ${momentTimeZone.format('dddd, MMMM Do')}`;
    } else if (Locale.fr_CA === locale) {
      const dayName = momentTimeZone.format('dddd');
      const date = momentTimeZone.format('DD MMMM LT');

      return `${sortBy} ${dayName} ${on} ${date} ${timeZoneAbbr}`;
    }
  }

  private createSpecialOrderFlag(): ProductFlag {
    return {
      title: this.localizationService.instant('PRODUCT_FLAG.SPECIAL_ORDER'),
      style: ProductFlagStyle.Yellow,
      tooltipText: this.localizationService.instant(
        'PRODUCT_FLAG.SPECIAL_ORDER_TOOLTIP',
      ),
      analyticsEvent: {
        action: 'hover',
        category: this.analyticsEventCategory,
        label: 'View special order',
      },
    };
  }

  private createContractFlag(): ProductFlag {
    return {
      title: this.localizationService.instant('PRODUCT_FLAG.CONTRACT_FLAG'),
      style: ProductFlagStyle.Blue,
    };
  }

  private createLocalFlag(): ProductFlag {
    return {
      title: this.localizationService.instant('PRODUCT_FLAG.LOCAL_FLAG'),
      style: ProductFlagStyle.LtGreen,
    };
  }

  private createDropShipFlag(): ProductFlag {
    return {
      title: this.localizationService.instant('PRODUCT_FLAG.DROP_SHIP'),
      style: ProductFlagStyle.OrderveOrange,
      tooltipText: this.localizationService.instant(
        'PRODUCT_FLAG.DROP_SHIP_TOOLTIP',
      ),
      analyticsEvent: {
        action: 'hover',
        category: this.analyticsEventCategory,
        label: 'View drop ship',
      },
    };
  }
}
