<div class="banner-container">
  <mat-icon class="error-icon" svgIcon="exception-icon-v1"></mat-icon>
  <span class="error-text">
    @if (headerError.primaryText) {
      <span class="primary-text">{{
        headerError.primaryText | stringdefaulter
      }}</span>
    }
    @if (headerError.primaryText && headerError.secondaryText) {
      <span class="separator">: </span>
    }
    @if (headerError.secondaryText) {
      <span id="secondaryText">{{
        headerError.secondaryText | stringdefaulter
      }}</span>
    }
  </span>
</div>
