import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  convertLanguageToNotificationLanguage,
  CustomerNotification,
  NotificationLanguage,
  NotificationMethodCode,
} from '../../shared/models/customer-notifications';
import { ToastMessageService } from '../../../../shared/services/toast-message/toast-message.service';
import { CustomerNotificationsConstants } from '../../../../core/store/customer-notification-preferences/customer-notification-preferences.state';
import { NaooConstants } from '../../../../shared/NaooConstants';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Language } from '../../../../core/services/session/models/session-record';
import { ResizeEventService } from '../../../../shared/services/resize-event/resize-event.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationSubscriptionType } from '../../../../core/services/customer-notification-preferences/models/customer-notification-preferences-record';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { SubscriptionsFormComponent } from '../subscriptions-form/subscriptions-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationLanguageDropdownComponent } from '../../shared/notification-language-dropdown/notification-language-dropdown.component';

@Component({
  selector: 'naoo-preferences-row-editing-email',
  templateUrl: './preferences-row-editing-email.component.html',
  styleUrls: ['./preferences-row-editing-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatMenuTrigger,
    SubscriptionsFormComponent,
    NotificationLanguageDropdownComponent,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class PreferencesRowEditingEmailComponent
  implements OnInit, OnDestroy, AfterContentInit, OnChanges
{
  readonly NewNotification =
    CustomerNotificationsConstants.newNotificationOrdinal;

  @Input() notification: CustomerNotification;
  @Input() allNotifications: CustomerNotification[];
  @Input() allValidNotificationSubscriptionTypes: string[];
  @Input() isRetalix: boolean;
  @Input() defaultLanguage: Language;
  @Input() methodCode: NotificationMethodCode;
  @Output() cancelRequest = new EventEmitter();
  @Output() saveRequest = new EventEmitter<CustomerNotification>();
  @Output() hasUnsavedChanges = new EventEmitter<boolean>();

  @ViewChild('emailInput', { static: true }) emailInput: ElementRef;

  notificationForm: FormGroup;
  languageSelected = signal<NotificationLanguage>(null);
  subscriptionTypes: NotificationSubscriptionType[];

  private destroyed$ = new Subject<void>();

  constructor(
    private toastMessageService: ToastMessageService,
    private localizationService: LocalizationService,
    private resizeService: ResizeEventService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setUserLanguage(language: NotificationLanguage) {
    this.languageSelected.set(language);
  }

  ngOnInit(): void {
    this.resizeService
      .getEvents()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.changeDetector.markForCheck());

    this.initFormControl();
    this.languageSelected.set(
      this.notification?.language ||
        convertLanguageToNotificationLanguage(this.defaultLanguage),
    );
    this.subscriptionTypes = this.notification?.subscriptionTypes;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultLanguage'] && !this.notification?.language) {
      this.languageSelected.set(
        convertLanguageToNotificationLanguage(this.defaultLanguage),
      );
    }
  }

  get hasValidSubscriptionsToOptFrom(): boolean {
    return !!this.allValidNotificationSubscriptionTypes?.length;
  }

  get shouldDisableSaveButton(): boolean {
    return (
      this.hasInvalidNotificationForm() || !this.hasOptedInToASubscription()
    );
  }

  ngAfterContentInit(): void {
    setTimeout(() => this.emailInput?.nativeElement.focus(), 0);
    this.emailInput?.nativeElement.addEventListener('input', () => {
      this.hasPendingChanges(true);
    });
  }

  cancelClick() {
    this.hasPendingChanges(false);
    this.cancelRequest.emit();
  }

  saveClick() {
    const updatedNotification: CustomerNotification = {
      ordinal: this.notification.ordinal,
      contactInfo: this.notificationForm.value['emailInput'],
      name: this.notificationForm.value['recipientName'],
      language:
        this.languageSelected() ||
        convertLanguageToNotificationLanguage(this.defaultLanguage),
      editableStatus: this.notification.editableStatus,
      methodCode: this.methodCode,
      subscriptionTypes: this.subscriptionTypes,
    };
    this.saveRequest.emit(updatedNotification);
    this.hasPendingChanges(false);
    this.toastMessageService.showToastMessage(
      this.localizationService.instant(
        'PREFERENCES.EMAIL_NOTIFICATIONS.TOAST_MESSAGES.SAVE_SUCCESS_MESSAGE',
      ),
    );
  }

  subscriptionChanged(event: NotificationSubscriptionType[]) {
    this.subscriptionTypes = event;
    this.hasPendingChanges(true);
  }

  validate(controlName: string, errorName: string) {
    return this.notificationForm.controls[controlName].hasError(errorName);
  }

  hasPendingChanges(value: boolean) {
    this.hasUnsavedChanges.emit(value);
  }

  private hasOptedInToASubscription(): boolean {
    return !!this.subscriptionTypes?.length;
  }

  private hasInvalidNotificationForm(): boolean {
    return (
      this.notificationForm.invalid &&
      (this.notificationForm.dirty ||
        this.notification.ordinal === this.NewNotification)
    );
  }

  private duplicateNotificationValidator(
    allEmails: CustomerNotification[],
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const duplicateEmails = allEmails.filter((email) => {
        return (
          email.contactInfo.toLowerCase() !==
            this.notification.contactInfo.toLowerCase() &&
          email.contactInfo.toLowerCase() ===
            (control.value as string).toLowerCase()
        );
      });
      return duplicateEmails.length > 0
        ? { duplicateEmail: { value: control.value } }
        : null;
    };
  }

  private initFormControl() {
    const formControls = {
      emailInput: new FormControl(this.notification.contactInfo, [
        Validators.required,
        Validators.maxLength(120),
        Validators.email,
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
        this.duplicateNotificationValidator(this.allNotifications),
      ]),
      recipientName: new FormControl(this.notification.name, [
        Validators.maxLength(120),
        Validators.pattern(NaooConstants.ALLOWED_CHARACTERS_REGEX),
      ]),
    };

    this.notificationForm = new FormGroup(formControls);
  }
}
