import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { EcommerceAnalyticsFacade } from '../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { CartFacade } from '../../core/store/cart/cart.facade';
import { MultipleCartsFacade } from '../../core/store/multiple-carts/multiple-carts.facade';
import { ImportOrderModalComponent } from '../../import-order/import-order-modal/import-order-modal.component';
import { ReOrderValidationResult } from '../../re-order/re-order-validation-result';
import { CartLine, CartMaterial } from '../models/cart-material';
import { CartQuantityUpdate } from '../../core/store/cart/cart.actions';
import { MaterialRowContext } from '../../core/store/material-row/models/material-row';
import { Router } from '@angular/router';
import { NaooConstants } from '../NaooConstants';
import { NaooLocalStorage } from '../storage/local-storage/local-storage.service';
import { mergeMaterialList } from '../utilities/cart-material-utilities';
import { ReOrderService } from '../../re-order/re-order.service';
import { SavedCart } from '../../core/store/multiple-carts/multiple-carts.state';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { MatCard } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export enum CartActionType {
  KEEP = 1,
  REMOVE = 2,
  SAVE = 3,
}

export enum BulkAddWorkflow {
  IMPORT = 'Import cart',
  REORDER = 'Reorder all items',
  ACTIVATE_CART = 'Activate cart',
  TRANSFER_CART = 'Transfer cart',
}

interface CartActionTypeInterface {
  cartAction: string;
  cartActionType: CartActionType;
  actionId: number;
  title: string;
  subtitle: string;
  icon: string;
}

@Component({
  selector: 'naoo-bulk-add-modal',
  templateUrl: './bulk-add-modal.component.html',
  styleUrls: ['./bulk-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalHeaderComponent, MatCard, NgClass, MatIcon, TranslateModule],
})
export class BulkAddModalComponent implements OnInit, OnDestroy {
  workflow: BulkAddWorkflow;
  reOrderData?: ReOrderValidationResult;
  nonDeletedMaterials?: CartMaterial[];
  savedCart?: SavedCart;
  destroyed$ = new Subject();
  showCloseButton: boolean;
  enableContinueButton: boolean;
  cartActionType: CartActionType;

  cartActions: CartActionTypeInterface[] = [
    {
      cartAction: 'Keep',
      cartActionType: CartActionType.KEEP,
      actionId: 1,
      title: 'BULK_ADD_MODAL.ACTIONS.KEEP.TITLE',
      subtitle: 'BULK_ADD_MODAL.ACTIONS.KEEP.SUBTITLE',
      icon: 'keep_cart',
    },
    {
      cartAction: 'Remove',
      cartActionType: CartActionType.REMOVE,
      actionId: 2,
      title: 'BULK_ADD_MODAL.ACTIONS.REMOVE.TITLE',
      subtitle: 'BULK_ADD_MODAL.ACTIONS.REMOVE.SUBTITLE',
      icon: 'remove_cart',
    },
    {
      cartAction: 'Save',
      cartActionType: CartActionType.SAVE,
      actionId: 3,
      title: 'BULK_ADD_MODAL.ACTIONS.SAVE.TITLE',
      subtitle: 'BULK_ADD_MODAL.ACTIONS.SAVE.SUBTITLE',
      icon: 'save_cart',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<BulkAddModalComponent>,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
    private cartFacade: CartFacade,
    private multipleCartFacade: MultipleCartsFacade,
    private dialog: MatDialog,
    private router: Router,
    private naooLocalStorage: NaooLocalStorage,
    private reOrderService: ReOrderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bulkAddWorkflow: BulkAddWorkflow;
      reOrderData?: ReOrderValidationResult;
      nonDeletedMaterials?: CartMaterial[];
      savedCart?: SavedCart;
    },
  ) {
    this.workflow = this.data.bulkAddWorkflow;
    this.reOrderData = this.data.reOrderData;
    this.nonDeletedMaterials = this.data.nonDeletedMaterials;
    this.savedCart = this.data.savedCart;
  }

  ngOnInit(): void {
    this.showCloseButton = true;
    this.enableContinueButton = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  submit() {
    const cartAction = this.cartActions.find(
      (action) => action.cartActionType === this.cartActionType,
    );

    switch (cartAction.cartActionType) {
      case CartActionType.REMOVE:
        this.removeCartContents(cartAction.cartActionType);
        break;
      case CartActionType.SAVE:
        this.saveCartContents(cartAction.cartActionType);
        break;
      case CartActionType.KEEP:
        this.keepCartContents(cartAction.cartActionType);
        break;
    }

    this.dialogRef.close();
    this.trackAnalytics(cartAction);
  }

  private getCartQuantityUpdates(): CartQuantityUpdate[] {
    return Object.keys(this.reOrderData.orderable).map((materialNumber) => {
      const lines = this.reOrderData.orderable[materialNumber].map(
        (item) => <CartLine>{ uom: item.uom, quantity: item.quantityOrdered },
      );
      return {
        materialNumber,
        lines,
        merge: true,
        context: MaterialRowContext.ReOrder,
      };
    });
  }

  closeModal(): void {
    if (
      this.workflow == BulkAddWorkflow.TRANSFER_CART &&
      this.naooLocalStorage.getItem(NaooConstants.CART_TRANSFER_CONTENT)
    ) {
      this.naooLocalStorage.removeItem(NaooConstants.CART_TRANSFER_CONTENT);
      this.router.navigate([NaooConstants.HOME_PAGE_PATH]);
    }
    this.dialogRef.close();
  }

  setCartActionType(cartActionType: CartActionType): void {
    this.enableContinueButton = true;
    this.cartActionType = cartActionType;
  }

  trackAnalytics(cartAction: CartActionTypeInterface): void {
    this.ecommerceAnalyticsFacade.trackAddToCartConflict(
      'bulk-add-modal',
      this.workflow,
      this.cartActions.indexOf(cartAction) + 1,
      cartAction?.actionId,
      cartAction?.cartAction,
    );
  }

  isCartActionType(cartActionType: CartActionType): boolean {
    return this.cartActionType === cartActionType;
  }

  importOrderModal(
    cartActionType: CartActionType,
  ): MatDialogRef<ImportOrderModalComponent> {
    return this.dialog.open(ImportOrderModalComponent, {
      id: 'import-order',
      panelClass: 'naoo-import-order-modal',
      disableClose: true,
      data: cartActionType,
    });
  }

  getModalTitle(): string {
    switch (this.workflow) {
      case BulkAddWorkflow.ACTIVATE_CART:
        return 'BULK_ADD_MODAL.MODAL_TITLE.ACTIVATE';
      case BulkAddWorkflow.TRANSFER_CART:
      case BulkAddWorkflow.IMPORT:
        return 'BULK_ADD_MODAL.MODAL_TITLE.IMPORT';
      case BulkAddWorkflow.REORDER:
        return 'BULK_ADD_MODAL.MODAL_TITLE.REORDER';
    }
  }

  private removeCartContents(cartActionType: CartActionType): void {
    this.cartFacade.clearCartMaterials();
    if (
      this.workflow === BulkAddWorkflow.IMPORT ||
      this.workflow === BulkAddWorkflow.TRANSFER_CART
    ) {
      this.importOrderModal(cartActionType);
    } else if (this.workflow === BulkAddWorkflow.REORDER) {
      this.reOrderService.addMaterialsToCart(this.getCartQuantityUpdates());
    } else if (this.workflow === BulkAddWorkflow.ACTIVATE_CART) {
      this.multipleCartFacade.activateSavedCart(this.savedCart, false);
    }
  }

  private saveCartContents(cartActionType: CartActionType): void {
    if (this.workflow === BulkAddWorkflow.REORDER) {
      this.multipleCartFacade.saveActiveCart(
        true,
        null,
        this.getCartQuantityUpdates(),
      );
    } else if (this.workflow === BulkAddWorkflow.ACTIVATE_CART) {
      this.multipleCartFacade.activateSavedCart(this.savedCart, true);
    } else {
      this.multipleCartFacade.saveActiveCart(false, cartActionType);
    }
  }

  private keepCartContents(cartActionType: CartActionType): void {
    if (
      this.workflow === BulkAddWorkflow.IMPORT ||
      this.workflow === BulkAddWorkflow.TRANSFER_CART
    ) {
      this.importOrderModal(cartActionType);
    } else if (this.workflow === BulkAddWorkflow.REORDER) {
      const updates = this.getCartQuantityUpdates();
      const combinedCartQuantities = mergeMaterialList(
        updates,
        this.nonDeletedMaterials,
        MaterialRowContext.ReOrder,
      );

      this.reOrderService.addMaterialsToCart(
        combinedCartQuantities,
        updates.length,
      );
    } else if (this.workflow === BulkAddWorkflow.ACTIVATE_CART) {
      this.multipleCartFacade.activateSavedCart(this.savedCart, false, true);
    }
  }
}
