<div>
  @for (bannerAdRow of bannerAdCollection?.bannerAds; track $index) {
    @if (!isRowEmpty(bannerAdRow)) {
      <div
        [ngClass]="{
          'layout-one': bannerAdRow.length === 1,
          'layout-two': bannerAdRow.length === 2,
          'layout-three': bannerAdRow.length === 3,
        }"
      >
        @for (bannerAd of bannerAdRow; track $index) {
          @if (!!bannerAd) {
            <button
              id="{{ bannerAd.divName }}"
              class="content"
              role="link"
              [tabindex]="-1"
              (click)="bannerClickEvent(bannerAd)"
            >
              <img
                class="background-image"
                src="{{ bannerAd.imgUrl }}"
                alt="{{ bannerAd.headline }}"
              />
              <div class="inner">
                <div class="title">{{ bannerAd.headline }}</div>
                <button
                  class="button"
                  title="{{ bannerAd.cta }}"
                  role="link"
                  [attr.aria-label]="getWCAGAriaLabel(bannerAd)"
                >
                  {{ bannerAd.cta }}
                </button>
              </div>
            </button>
          }
        }
      </div>
    }
  }
</div>
