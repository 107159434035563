import {
  ExportMaterial,
  ExportMaterialsData,
  ExportModalSelection,
} from '../../../shared/services/export-materials/models/export-materials';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from '../material-info/material-info.state';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import { MaterialAdditionalInfoRecordState } from '../material-additional-info/material-additional-info.state';
import { ExportDataPoint } from '../../../shared/models/export/export-properties';
import {
  getCategoryRecordForMaterial,
  getParLineRecordForMaterial,
} from '../custom-guide/custom-guide.util';
import { StockType } from '../../services/material-availability/model/material-availabilities-record';
import { NaooConstants } from '../../../shared/NaooConstants';
import {
  CategorizedCustomGuideRecordState,
  CustomGuideRecordState,
} from '../custom-guide/custom-guide.state';
import {
  getGtinFromMaterialInfo,
  getQtyPerMasterSellUnitFromMaterialInfo,
} from '../../../shared/models/material-info';
import {
  createExportMaterialsValidations,
  ExportMaterialsRequiredStateInfo,
} from './models/export-materials-validation';
import { LocalizedUtilities } from '../../../shared/utilities/localized-utilities';
import { formatNumber } from '@angular/common';
import { CustomGuideTransformer } from '../custom-guide/custom-guide-transformer';
import {
  CustomerBrand,
  Language,
  Locale,
  SessionActiveCustomer,
} from '../../services/session/models/session-record';
import {
  CombinedPricingRecord,
  getWeightUom,
} from '../material-price/material-price.util';
import { MaterialRecord } from '../../services/material-info/models/materials-info-record';

export class ExportMaterialsTransformer {
  // eslint-disable-next-line max-params
  public static transformExportMaterialsData(
    modalSelection: ExportModalSelection,
    infoRecords: Dictionary<MaterialInfoRecordState>,
    additionalInfoRecords: Dictionary<MaterialAdditionalInfoRecordState>,
    availabilityRecords: Dictionary<MaterialAvailabilityRecordState>,
    combinedPriceRecords: Dictionary<CombinedPricingRecord>,
    customerMaterialRecord: CustomerMaterialRecord,
    contractIndicatorMaterialsSet: Set<string>,
    customer: SessionActiveCustomer,
    customerBrand: CustomerBrand,
    language: Language,
    locale: Locale,
    customGuideRecordState?: CustomGuideRecordState,
  ): ExportMaterialsData | undefined {
    const validations = createExportMaterialsValidations(
      infoRecords,
      additionalInfoRecords,
      availabilityRecords,
      combinedPriceRecords,
      customerMaterialRecord,
      customGuideRecordState,
    );

    if (!this.isRequiredDataLoaded(modalSelection, validations)) {
      return undefined;
    }

    const materials: ExportMaterial[] = modalSelection.materialNumbers.map(
      (materialNumber) => {
        const data = new Map<ExportDataPoint, string>();

        modalSelection.dataPoints.forEach((selectedDataPoint) => {
          const value = this.getValueForMaterial(
            modalSelection,
            infoRecords,
            additionalInfoRecords,
            availabilityRecords,
            combinedPriceRecords,
            customerMaterialRecord,
            contractIndicatorMaterialsSet,
            selectedDataPoint,
            materialNumber,
            language,
            locale,
            customGuideRecordState,
          );
          data.set(selectedDataPoint, value);
        });

        return {
          materialNumber: materialNumber,
          data: data,
          orderableUnits: this.getOrderableUnits(
            availabilityRecords,
            materialNumber,
          ),
        };
      },
    );

    return {
      fileName: modalSelection.fileName,
      fileType: modalSelection.fileType,
      customer: {
        name: customer.name,
        number: customer.customerDisplayId,
        brand: customerBrand,
      },
      materials: materials,
      categoryGroupings: modalSelection.categoryGroupings,
    };
  }

  private static isRequiredDataLoaded(
    modalSelection: ExportModalSelection,
    validations: ExportMaterialsRequiredStateInfo<unknown>[],
  ): boolean {
    return validations.every((stateInfo) =>
      // Only need to check if done loading if data points intersect
      // or if there are no specified data points
      modalSelection.dataPoints.some(
        (selectedDataPoint) =>
          !stateInfo.validation.requiredDataPoints ||
          stateInfo.validation.requiredDataPoints.includes(selectedDataPoint),
      )
        ? modalSelection.materialNumbers.every((materialNumber) =>
            stateInfo.isFinishedLoading(materialNumber),
          )
        : true,
    );
  }

  // eslint-disable-next-line max-params
  private static getValueForMaterial(
    modalSelection: ExportModalSelection,
    infoRecords: Dictionary<MaterialInfoRecordState>,
    additionalInfoRecords: Dictionary<MaterialAdditionalInfoRecordState>,
    availabilityRecords: Dictionary<MaterialAvailabilityRecordState>,
    combinedPriceRecords: Dictionary<CombinedPricingRecord>,
    customerMaterialRecord: CustomerMaterialRecord,
    contractIndicatorMaterialsSet: Set<string>,
    selectedDataPoint: ExportDataPoint,
    materialNumber: string,
    language: Language,
    locale: Locale,
    customGuideRecordState?: CustomGuideRecordState,
  ): string {
    const infoRecord = infoRecords[materialNumber].record;
    const availabilityRecord = availabilityRecords[materialNumber]?.record;
    const combinedPriceRecord = combinedPriceRecords[materialNumber];

    switch (selectedDataPoint) {
      // Info data points
      case ExportDataPoint.Brand:
        return LocalizedUtilities.getLocalizedStringValue(
          infoRecord.brand,
          language,
        );
      case ExportDataPoint.CasePriceUom:
        return infoRecord.isCatchWeight
          ? getWeightUom(combinedPriceRecord?.weightUom)
          : 'PRODUCT.PER_CASE';
      case ExportDataPoint.CatchWeight:
        return LocalizedUtilities.getLocalizedBoolean(
          infoRecord.isCatchWeight,
          language,
        );
      case ExportDataPoint.Gtin:
        return getGtinFromMaterialInfo(infoRecord) ?? '';
      case ExportDataPoint.ItemDescription:
        return LocalizedUtilities.getLocalizedStringValue(
          infoRecord.description,
          language,
        );
      case ExportDataPoint.ItemNumber:
        return infoRecord.materialNumber;
      case ExportDataPoint.NetWeight:
        return infoRecord.baseUomWeight?.net
          ? formatNumber(Number(infoRecord.baseUomWeight?.net), Locale.en_CA)
          : '';
      case ExportDataPoint.PackSize:
        return (
          getQtyPerMasterSellUnitFromMaterialInfo(infoRecord)?.toString() ?? ''
        );
      case ExportDataPoint.PackUom:
        return 'EXPORT.UNITS_PER_CASE';
      case ExportDataPoint.UnitSize:
        return this.getValueForMaterialForUnitSize(infoRecord);

      // Additional info data points
      case ExportDataPoint.VendorItemCode:
        return (
          additionalInfoRecords[materialNumber]?.record?.vendorInfo
            ?.vendorMaterialNumber ?? ''
        );

      // IndicatorList data points
      case ExportDataPoint.ContractItemFlag:
        return LocalizedUtilities.getLocalizedBoolean(
          contractIndicatorMaterialsSet.has(materialNumber),
          language,
        );

      // Availability data points
      case ExportDataPoint.DropShipFlag:
        return LocalizedUtilities.getLocalizedBoolean(
          availabilityRecord?.stockType === StockType.DropShip,
          language,
        );
      case ExportDataPoint.EarlyCutoffFlag:
        return LocalizedUtilities.getLocalizedBoolean(
          !!availabilityRecord?.cutoffCode,
          language,
        );
      case ExportDataPoint.SpecialOrderFlag:
        const isStockType = [
          StockType.SpecialOrder,
          StockType.SpecialOrderSAP,
        ].includes(availabilityRecord?.stockType);
        return LocalizedUtilities.getLocalizedBoolean(isStockType, language);

      // Price data points
      case ExportDataPoint.CasePrice:
        return this.getLocalizedMaterialPrice(
          combinedPriceRecord,
          NaooConstants.Uom.Case,
          infoRecord.isCatchWeight,
          locale,
        );
      case ExportDataPoint.UnitPrice:
        return this.getLocalizedMaterialPrice(
          combinedPriceRecord,
          NaooConstants.Uom.Unit,
          infoRecord.isCatchWeight,
          locale,
        );
      case ExportDataPoint.UnitPriceUom:
        return this.getValueForMaterialForUnitPriceUom(
          infoRecord,
          combinedPriceRecord,
        );

      // Customer material data points
      case ExportDataPoint.CustomerMaterialNumber:
        return (
          customerMaterialRecord[materialNumber]?.customerMaterialNumber ?? ''
        );

      // Custom guide data points
      case ExportDataPoint.InventoryQty:
        return (
          getParLineRecordForMaterial(
            customGuideRecordState.record,
            materialNumber,
            NaooConstants.Uom.Case,
          )?.inventoryQuantity?.toString() ?? ''
        );
      case ExportDataPoint.ParQty:
        return (
          getParLineRecordForMaterial(
            customGuideRecordState.record,
            materialNumber,
            NaooConstants.Uom.Case,
          )?.parQuantity?.toString() ?? ''
        );
      case ExportDataPoint.CustomCategory:
        const customCategory = getCategoryRecordForMaterial(
          customGuideRecordState.record,
          materialNumber,
        );
        return (
          LocalizedUtilities.getLocalizedStringValue(
            customCategory?.name,
            language,
          ) ||
          LocalizedUtilities.getLocalizedStringValue(
            CustomGuideTransformer.unassignedCategoryName,
            language,
          )
        );
      case ExportDataPoint.StorageArea:
        return this.getCategoryNameFromCategorizedCustomGuide(
          customGuideRecordState.storageAreaCategory,
          materialNumber,
          language,
        );

      // Category
      case ExportDataPoint.Category:
        // For custom guides pull from taxonomy, else pull from grouping input
        return customGuideRecordState
          ? this.getCategoryNameFromCategorizedCustomGuide(
              customGuideRecordState.taxonomyCategory,
              materialNumber,
              language,
            )
          : this.getCategoryNameFromModalSelection(
              modalSelection,
              materialNumber,
            );

      // Empty data points
      case ExportDataPoint.Qt1:
      case ExportDataPoint.Qt2:
      case ExportDataPoint.Qt3:
      case ExportDataPoint.Qt4:
      case ExportDataPoint.Qt5:
      case ExportDataPoint.Qt6:
      case ExportDataPoint.Qt7:
      case ExportDataPoint.Qt8:
      case ExportDataPoint.Qt9:
      case ExportDataPoint.Qt10:
        return '';

      default:
        throw new Error('Unsupported data point');
    }
  }

  private static getValueForMaterialForUnitPriceUom(
    infoRecord: MaterialRecord,
    combinedPriceRecord: CombinedPricingRecord,
  ): string {
    return infoRecord.isCatchWeight
      ? getWeightUom(combinedPriceRecord?.weightUom)
      : combinedPriceRecord?.unitPrices.find(
          (unitPrice) =>
            unitPrice.salesUom !== NaooConstants.Uom.Case &&
            (unitPrice.catchWeightPrice || unitPrice.price),
        )?.salesUom;
  }

  private static getValueForMaterialForUnitSize(
    infoRecord: MaterialRecord,
  ): string {
    if (!infoRecord?.innerPackSize && !!infoRecord?.units?.length) {
      const smallestUnit = infoRecord.units[infoRecord.units.length - 1];
      const qtyInParent = smallestUnit.qtyInParent || 1;
      return `${qtyInParent} ${smallestUnit.uom}`;
    }
    return infoRecord.innerPackSize ?? '';
  }

  private static getCategoryNameFromModalSelection(
    modalSelection: ExportModalSelection,
    materialNumber: string,
  ): string {
    const modalSelectionMaterial = modalSelection.categoryGroupings?.find(
      (category) => category.materialNumbers.includes(materialNumber),
    );
    return modalSelectionMaterial?.categoryName ?? '';
  }

  private static getCategoryNameFromCategorizedCustomGuide(
    categorizedCustomGuide: CategorizedCustomGuideRecordState,
    materialNumber: string,
    language: Language,
  ): string {
    const customGuideCategory = categorizedCustomGuide?.categories?.find(
      (category) => category.materialNumbers.includes(materialNumber),
    );
    return LocalizedUtilities.getLocalizedStringValue(
      customGuideCategory?.categoryName,
      language,
    );
  }

  private static getLocalizedMaterialPrice(
    combinedPricingRecord: CombinedPricingRecord,
    uom: string,
    isCatchWeight: boolean,
    locale: Locale,
  ): string {
    let price;
    if (uom === NaooConstants.Uom.Case) {
      price = combinedPricingRecord?.unitPrices.find(
        (unitPrice) => unitPrice.salesUom === NaooConstants.Uom.Case,
      );
    } else {
      price = combinedPricingRecord?.unitPrices.find(
        (unitPrice) =>
          unitPrice.salesUom !== NaooConstants.Uom.Case &&
          (unitPrice.catchWeightPrice !== undefined ||
            unitPrice.price !== null),
      );
    }
    return (
      LocalizedUtilities.getLocalizedPrice(
        locale,
        isCatchWeight ? price?.catchWeightPrice : price?.price,
      ) ?? ''
    );
  }

  private static getOrderableUnits(
    availabilityRecords: Dictionary<MaterialAvailabilityRecordState>,
    materialNumber: string,
  ): string[] {
    const availabilityRecord = availabilityRecords[materialNumber]?.record;
    return availabilityRecord?.units?.map((unit) => unit.uom) ?? [];
  }
}
