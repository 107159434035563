<div
  class="gfs-category-icon"
  [ngClass]="'g-icon'"
  [attr.tooltip]="'ORDER_GUIDE.GFS_CATEGORIES' | translate"
  [attr.tabIndex]="0"
>
  <div class="gfs-icon-title">
    <img class="img-gfs-icon" alt="" src="/assets/images/gfs-icon.png" />
  </div>
</div>
