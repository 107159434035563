<naoo-modal-header
  [title]="getTitle | translate"
  [showCloseButton]="true"
  [stickyHeader]="true"
  (closeModalEmitter)="closeAction()"
></naoo-modal-header>
@if (!!displayValues) {
  <div class="help-container" [@.disabled]="disableAnimation">
    @for (section of displayValues.sections; track section.title) {
      <section class="fixed-section">
        <div class="fixed-title">
          {{ section.title | translate }}
        </div>
        @for (paragraph of section.paragraphs; track paragraph) {
          <p class="fixed-content">
            {{ paragraph | translate }}
          </p>
        }
      </section>
    }
    <mat-accordion>
      @for (
        section of displayValues.expansions;
        track section.title;
        let isFirst = $first;
        let isLast = $last
      ) {
        <naoo-generic-help-expansion-panel
          [section]="section"
          [isFirst]="isFirst"
          [isLast]="isLast"
        ></naoo-generic-help-expansion-panel>
      }
    </mat-accordion>
  </div>
}
