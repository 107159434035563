@if (!!title && materialListRow) {
  <div class="title">
    {{ title | stringdefaulter | decodeHtmlEntity }}
  </div>
  <div
    class="top-recommendation-container"
    [ngClass]="{
      'top-recommendation-shadow': !showBoxShadowOnMaterialRowOnly,
    }"
  >
    <naoo-material-list
      [materialListItems]="[materialListRow]"
      [context]="materialContext"
      [listStyle]="materialListStyle"
      [analytics]="{
        recommendationEngineName,
      }"
      (click)="materialClick.emit(materialListRow.value)"
    >
    </naoo-material-list>
    <button
      [ngClass]="{ 'hide-element': !showButton }"
      class="view-all-button"
      (click)="handleScrollToRecommendations()"
      [attr.aria-label]="'HOME_PAGE.VIEW_MORE' | translate"
    >
      {{ 'HOME_PAGE.VIEW_MORE' | translate }}
    </button>
  </div>
}
