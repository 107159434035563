import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { CustomGuide } from '../../../core/store/custom-guide/models/custom-guide';
import { CustomGuideFacade } from '../../../core/store/custom-guide/custom-guide.facade';
import { MaterialListRowType } from '../../../material-list/models/material-list';
import { InventoryAvailabilityFacade } from '../../../core/store/inventory-availability/inventory-availability.facade';
import { LoadingService } from '../../../shared/services/loading-service/loading.service';
import { DeviceIdentifierService } from '../../../shared/services/device-identifier/device-identifier.service';
import { RouterExtrasService } from '../../../shared/services/router-extras/router-extras.service';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupByType } from '../../shared/guides';
import { LastOrderedFacade } from '../../../core/store/last-ordered/last-ordered.facade';
import { NaooConstants } from '../../../shared/NaooConstants';
import { EcommerceAnalyticsFacade } from '../../../core/store/ecommerce-analytics/ecommerce-analytics.facade';
import { MaterialRowContext } from '../../../core/store/material-row/models/material-row';
import { CustomGuideContentComponent } from '../custom-guide-content/custom-guide-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-custom-guide-container',
  template: `
    <naoo-custom-guide-content
      [customGuide]="customGuide$ | async"
      [isMobile]="isMobile$ | async"
    ></naoo-custom-guide-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CustomGuideContentComponent, AsyncPipe],
})
export class CustomGuideContainerComponent implements OnInit, OnDestroy {
  customGuide$: Observable<CustomGuide>;
  isMobile$: Observable<boolean>;

  private categorizedMaterialsLoaded = new Map<GroupByType, boolean>();
  private customGuideId: string;
  private needsMaterialDataLoad = true;

  constructor(
    private customGuideFacade: CustomGuideFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private deviceIdentifierService: DeviceIdentifierService,
    private routerExtras: RouterExtrasService,
    private lastOrderedFacade: LastOrderedFacade,
    private inventoryAvailabilityFacade: InventoryAvailabilityFacade,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {}

  ngOnInit() {
    this.loadingService.start();

    this.customGuide$ = this.activatedRoute.params.pipe(
      map((routeParams) => decodeURI(routeParams.id)),
      distinctUntilChanged(),
      tap((customGuideId) => {
        this.loadingService.start();
        this.categorizedMaterialsLoaded = new Map<GroupByType, boolean>();
        this.customGuideId = customGuideId;
        this.needsMaterialDataLoad = true;
      }),
      switchMap((customGuideId) => {
        const isValid$ = this.customGuideFacade
          .isValidCustomGuide(customGuideId)
          .pipe(first());
        const getCustomGuide$ =
          this.customGuideFacade.getCustomGuide(customGuideId);

        return combineLatest([isValid$, getCustomGuide$]).pipe(
          tap(([isValid]) => {
            if (!isValid) {
              this.router.navigate([NaooConstants.GUIDES_PATH]);
            }
          }),
          filter(([_, customGuide]) => !!customGuide),
          map(([_, customGuide]) => {
            this.updateGroupByToCustomIfNeeded(customGuide);
            this.loadMaterialDataIfNeeded(customGuide);
            this.loadCategorizedMaterialsIfNeeded(customGuide);

            if (customGuide.hasLoaded) {
              this.restoreScrollPositionIfNeeded();
              this.loadingService.stop();
            } else {
              this.loadingService.start();
            }

            return customGuide;
          }),
        );
      }),
    );

    this.isMobile$ = this.deviceIdentifierService.observeDeviceType();
  }

  ngOnDestroy() {
    this.loadingService.stop();

    if (this.customGuideId) {
      this.customGuideFacade.clearCategorizedCustomGuide(this.customGuideId);
    }
  }

  private updateGroupByToCustomIfNeeded(guide: CustomGuide) {
    const groupByType = guide.sideBar.groupBy;
    const hasLoadedGroupBy = this.categorizedMaterialsLoaded.get(groupByType);
    if (
      guide.isOffline &&
      groupByType !== GroupByType.Custom &&
      !hasLoadedGroupBy
    ) {
      // Trigger updateGroupBy outside the scope of the inner observable
      setTimeout(() => {
        this.customGuideFacade.updateGroupBy(guide.id, GroupByType.Custom);
      });
    }
  }

  private loadMaterialDataIfNeeded(guide: CustomGuide) {
    if (
      this.needsMaterialDataLoad &&
      !guide.isOffline &&
      guide.materialListRows.length > 0
    ) {
      this.needsMaterialDataLoad = false;

      const materialNumbers = guide.materialListRows
        .filter((row) => row.type === MaterialListRowType.MaterialRow)
        .map((row) => row.value as string);

      this.lastOrderedFacade.loadLastOrdered(materialNumbers);
      this.inventoryAvailabilityFacade.loadCartInventoryAvailability(
        materialNumbers,
      );
      this.ecommerceAnalyticsFacade.trackGoogleViewItemList(
        materialNumbers,
        MaterialRowContext.CustomGuide,
      );
    }
  }

  private loadCategorizedMaterialsIfNeeded(guide: CustomGuide) {
    const groupBy = guide.sideBar.groupBy;
    if (groupBy === GroupByType.Custom || guide.isOffline) {
      return;
    }

    if (!this.categorizedMaterialsLoaded.get(groupBy)) {
      this.categorizedMaterialsLoaded.set(groupBy, guide.hasLoaded);
      this.customGuideFacade.loadCategorizedCustomGuide(guide.id, groupBy);
    }
  }

  private restoreScrollPositionIfNeeded() {
    if (this.routerExtras.getRestoreScrollPosition(window.location.href)) {
      this.routerExtras.restoreScrollPosition(window.location.href);
    }
  }
}
