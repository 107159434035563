import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NutritionBlock } from 'src/app/core/store/nutrition/models/nutrition';
import { NgTemplateOutlet, NgClass } from '@angular/common';

@Component({
  selector: 'naoo-nutrition-content',
  templateUrl: './nutrition-content.component.html',
  styleUrls: ['./nutrition-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgClass],
})
export class NutritionContentComponent {
  @Input() blockGroups: NutritionBlock[][];

  parsedAmount(amount: number, uom: string): string {
    if (!amount) {
      return 'n/a';
    }
    const result: string = amount ? amount.toString() : '0';
    if (uom) {
      return result + ' ' + uom;
    }
    return result;
  }
}
