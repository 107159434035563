import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-order-submit-button-content',
  templateUrl: './order-submit-button-content.component.html',
  styleUrls: ['./order-submit-button-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class OrderSubmitButtonContentComponent {
  @Input() isDisabled: boolean;
  @Input() buttonText: string;
  @Output() onClick = new EventEmitter<void>();

  submit(): void {
    this.onClick.emit();
  }
}
