<mat-expansion-panel class="saved-carts-expansion-panel" [expanded]="true">
  <mat-expansion-panel-header
    class="saved-carts-expansion-panel-header carts-saved"
    [expandedHeight]="'100%'"
    [collapsedHeight]="'100%'"
  >
    <div class="banner-header">
      <h3>
        {{
          'CART.PUNCHED_OUT_CARTS.HEADER'
            | translate: { count: punchedOutCarts?.length || 0 }
        }}
      </h3>
    </div>
  </mat-expansion-panel-header>
  <div class="sub-header">
    <span>{{ 'CART.PUNCHED_OUT_CARTS.SUB_HEADER' | translate }}</span>
  </div>
  @for (punchedOutCart of punchedOutCarts; track punchedOutCart.id) {
    <naoo-saved-cart-row
      [savedCart]="punchedOutCart"
      [shouldPromptActivateModal]="savedCarts?.shouldPromptActivateModal"
      [isOffline]="savedCarts?.isOffline"
      [hideDelete]="true"
      [isSelectedCart]="selectedCartId === punchedOutCart?.id"
      [isPreviewOpen]="isPreviewOpen"
    >
    </naoo-saved-cart-row>
  }
</mat-expansion-panel>
