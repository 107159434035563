import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { WarningOpenDirection } from 'src/app/core/store/material-warning/material-warning';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import {
  MaterialOrdering,
  MaterialOrderingInfo,
  MaterialRowSharedOptions,
} from '../../../../../core/store/material-row/models/material-row';
import { MaterialWarningComponent } from '../material-warning/material-warning.component';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MaterialOrderingInfoComponent } from '../material-ordering-info/material-ordering-info.component';
import { NaooLoyaltyPipe } from '../../../../../shared/pipes/naoo-loyalty-points.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUnitsPipe } from '../../../../../shared/pipes/material-units.pipe';

@Component({
  selector: 'naoo-material-ordering-tab',
  templateUrl: './material-ordering-tab.component.html',
  styleUrls: ['./material-ordering-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MaterialWarningComponent,
    NgClass,
    MatIcon,
    MatTabsModule,
    MaterialOrderingInfoComponent,
    NaooLoyaltyPipe,
    TranslateModule,
    MaterialUnitsPipe,
  ],
})
export class MaterialOrderingTabComponent {
  @Input() materialRowOptions: MaterialRowSharedOptions;
  @Input() materialOrdering: MaterialOrdering;
  @Input() isMobile: boolean;
  @Input() displayGoPointsLogo: boolean;

  @Output() viewCommodityDetailsClicked = new EventEmitter();

  readonly materialOpenDirection = WarningOpenDirection.Up;

  selectedTabIndex = 0;

  get displayMaxAllocation(): boolean {
    return (
      this.isMobile &&
      this.materialRowOptions?.materialCommodityInfo?.displayMaxAllocation
    );
  }

  get displayLoyaltyPoints(): boolean {
    return !!(
      this.materialRowOptions.isLoyaltyProgramEligible &&
      this.selectedMaterialOrderingInfo?.loyaltyPoints
    );
  }

  get selectedMaterialOrderingInfo(): MaterialOrderingInfo {
    return this.materialOrdering.materialOrderingInfos[this.selectedTabIndex];
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }
}
