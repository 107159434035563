<div class="step-buttons" [ngClass]="{ 'bottom-space': isLast }">
  @if (isFirst) {
    @if (!isSecondaryButtonHidden) {
      <button
        [disabled]="isSecondaryButtonDisabled"
        (click)="handleSecondaryButtonClick()"
        class="browsing-button"
        mat-button
      >
        {{ 'ORDER_METHOD_MODAL.BUTTON.BROWSING' | translate }}
      </button>
    }
  } @else {
    <button
      [disabled]="isSecondaryButtonDisabled"
      (click)="handleSecondaryButtonClick()"
      class="back-button"
      mat-button
      matStepperPrevious
    >
      {{ 'ORDER_METHOD_MODAL.BUTTON.BACK' | translate }}
    </button>
  }

  @if (!isPrimaryButtonHidden) {
    @if (isLast) {
      <button
        [disabled]="isPrimaryButtonDisabled"
        (click)="handlePrimaryButtonClick()"
        class="done-button"
        mat-button
      >
        {{ 'ORDER_METHOD_MODAL.BUTTON.START_SHOPPING' | translate }}
      </button>
    } @else {
      <button
        [disabled]="isPrimaryButtonDisabled"
        (click)="handlePrimaryButtonClick()"
        class="next-button"
        mat-button
        matStepperNext
      >
        {{ 'ORDER_METHOD_MODAL.BUTTON.NEXT' | translate }}
      </button>
    }
  }
</div>
