import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MaterialFlag } from '../../../../material-flag/material-flag';
import { LocalizationService } from '../../../../shared/services/translation/localization.service';
import { shareReplay } from 'rxjs/operators';
import { MaterialFlagComponent } from '../material-flag/material-flag.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-material-flag-container',
  templateUrl: './material-flag-container.component.html',
  styleUrls: ['./material-flag-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MaterialFlagComponent, AsyncPipe],
})
export class MaterialFlagContainerComponent {
  @Input() flags: MaterialFlag[];
  @Input() maximumNumberOfFlags: number;

  locale$ = this.localizationService.locale().pipe(shareReplay());

  constructor(private localizationService: LocalizationService) {}

  get materialFlagsToDisplay(): MaterialFlag[] {
    if (!this.flags?.length) {
      return [];
    }
    if (
      this.maximumNumberOfFlags !== undefined &&
      this.maximumNumberOfFlags !== null
    ) {
      return this.flags.slice(0, this.maximumNumberOfFlags);
    }
    return this.flags;
  }
}
