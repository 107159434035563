import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrderGuide } from 'src/app/core/store/order-guide/models/order-guide';
import { MaterialListComponent } from 'src/app/material-list/material-list.component';
import {
  MaterialListStyle,
  MaterialRowContext,
} from 'src/app/core/store/material-row/models/material-row';
import { SortByType } from '../../shared/guides';
import { Observable } from 'rxjs';
import { HeaderHeightService } from '../../../shared/services/header-height/header-height.service';
import { OrderGuideFacade } from '../../../core/store/order-guide/order-guide.facade';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { AnalyticsEventInfo } from '../../../shared/analytics/analytics-event-info';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { NaooConstants } from '../../../shared/NaooConstants';
import { NaooLocalStorage } from '../../../shared/storage/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { GuidesMobileActionsListComponent } from '../../shared/guides-mobile-actions-list/guides-mobile-actions-list.component';
import {
  MatDrawer,
  MatDrawerContainer,
  MatDrawerContent,
} from '@angular/material/sidenav';
import { AsyncPipe, NgClass } from '@angular/common';
import { GuidesSideBarComponent } from '../../shared/guides-side-bar/guides-side-bar.component';
import { MatIcon } from '@angular/material/icon';
import { GuidesHeaderComponent } from '../../shared/guides-header/guides-header.component';
import { NoResultsComponent } from '../../../shared/no-results/no-results.component';
import { TranslateModule } from '@ngx-translate/core';
import { NaooStringDefaulterPipe } from '../../../shared/string-defaulter/naoo-string-defaulter.pipe';

@Component({
  selector: 'naoo-order-guide-content',
  templateUrl: './order-guide-content.component.html',
  styleUrls: ['./order-guide-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuidesMobileActionsListComponent,
    MatDrawerContainer,
    MatDrawer,
    NgClass,
    GuidesSideBarComponent,
    MatDrawerContent,
    MatIcon,
    GuidesHeaderComponent,
    MaterialListComponent,
    NoResultsComponent,
    AsyncPipe,
    TranslateModule,
    NaooStringDefaulterPipe,
  ],
})
export class OrderGuideContentComponent implements OnInit {
  @Input() orderGuide: OrderGuide;
  @Input() isMobile: boolean;

  sideNavOpen = true;
  // changes after the drawer completes an open/closed animation
  drawerClosedAndFinishedAnimating = true;
  isShowingMobileActions = false;
  headerHeight$: Observable<number>;
  printInput$: Observable<ExportMaterialsInput>;
  exportInput$: Observable<ExportMaterialsInput>;
  isEditOrderGuidePageAccessible$: Observable<boolean>;

  readonly listContext = MaterialRowContext.OrderGuide;

  @ViewChild('materialList')
  materialList: MaterialListComponent;

  constructor(
    private orderGuideFacade: OrderGuideFacade,
    private headerHeightService: HeaderHeightService,
    private customDialogService: CustomDialogService,
    private analytics: NaooAnalyticsManager,
    private localStorage: NaooLocalStorage,
    private router: Router,
  ) {}

  ngOnInit() {
    this.headerHeight$ = this.headerHeightService.observeHeaderHeight();
    this.printInput$ = this.orderGuideFacade.getExportMaterialsInput(true);
    this.exportInput$ = this.orderGuideFacade.getExportMaterialsInput(false);
    this.sideNavOpen = this.getSavedMenuState();
    this.getSortByOrder();
    this.isEditOrderGuidePageAccessible$ =
      this.orderGuideFacade.isEditOrderGuidePageAccessible();
  }

  get shouldRenderOrderGuideChanges(): boolean {
    return this.orderGuide.changes?.length > 0;
  }

  get isFiltering() {
    return this.orderGuide.header.actionBar.searchText.length !== 0;
  }

  get shouldRenderList(): boolean {
    return (
      (this.isFiltering && this.orderGuide.materialListRows.length > 0) ||
      this.orderGuide.materialListRows.length > 0
    );
  }

  toggleDrawer() {
    this.sideNavOpen = !this.sideNavOpen;
    this.localStorage.setItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
      '' + this.sideNavOpen,
    );
  }

  drawerStateChangeCompleted() {
    this.drawerClosedAndFinishedAnimating = !this.sideNavOpen;
  }

  editOrderGuide() {
    this.router.navigate(['guides', 'order-guide', 'organize']);
  }

  openGuideChangesModal() {
    this.customDialogService.orderGuideChangesModal(this.orderGuide.changes);

    this.analytics.trackAnalyticsEvent({
      action: 'Click',
      category: 'Order Guide',
      label: 'View changes',
    });
  }

  toggleMobileActions() {
    this.isShowingMobileActions = !this.isShowingMobileActions;
  }

  handleScrollToCategory(vsIndex: number) {
    this.materialList.scrollToIndex(vsIndex);
  }

  handleUpdateSearchText(searchText: string) {
    this.orderGuideFacade.updateSearchText(searchText);
  }

  handleUpdateMaterialView(listStyle: MaterialListStyle) {
    const eventInfo: AnalyticsEventInfo = {
      category: ListsAnalyticsConstants.orderGuideCategory,
      action: ListsAnalyticsConstants.clickAction,
      label: `${listStyle} view`,
    };

    this.analytics.trackAnalyticsEvent(eventInfo);
  }

  handleSortByChanged(sortByType: SortByType) {
    this.orderGuideFacade.updateSortBy(sortByType);
    this.localStorage.setItem(
      NaooConstants.ORDER_GUIDE_SORTBY_STATE_KEY,
      sortByType.valueOf(),
    );
    this.analytics.trackAnalyticsEvent({
      action: 'Click',
      category: 'Order Guide',
      label: `sort by ${sortByType}`,
    });
  }

  get expansionAriaText(): string {
    return this.sideNavOpen
      ? 'LISTS.FILTERS.COLLAPSE_MENU'
      : 'LISTS.FILTERS.EXPAND_MENU';
  }

  private getSavedMenuState(): boolean {
    const savedState = this.localStorage.getItem(
      NaooConstants.SIDE_MENU_EXPAND_STATE_KEY,
    );
    return savedState == null ? true : savedState === 'true';
  }

  private getSortByOrder() {
    const savedSortByOrder = this.localStorage.getItem(
      NaooConstants.ORDER_GUIDE_SORTBY_STATE_KEY,
    );
    const sortBy = Object.values(SortByType).find(
      (val) => val === savedSortByOrder,
    );
    sortBy
      ? this.orderGuideFacade.updateSortBy(sortBy)
      : this.orderGuideFacade.updateSortBy(SortByType.Description);
  }
}
