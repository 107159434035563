import { Routes } from '@angular/router';
import { customerUnitSelectionGuard } from './shared/guards/customer-unit-selection.guard';
import { lockedToOrderGuideGuard } from './shared/guards/locked-to-order-guide.guard';
import { scheduledMaintenanceGuard } from './shared/guards/scheduled-maintenance.guard';
import { HomePageComponent } from './home-page/home-page/home-page.component';
import { redirectGuard } from './shared/guards/redirect.guard';
import { CustomerUnitSelectionComponent } from './customer-unit-selection/customer-unit-selection/customer-unit-selection.component';
import { CategoriesComponent } from './categories/categories/categories.component';
import {
  coordinateMatcher,
  resultsCoordinateMatcher,
} from './categories/categories-route-matcher/categories-route-matcher';
import { CategoryDetailsComponent } from './categories/category-details/category-details.component';
import { SearchResultsContainerComponent } from './search/search-results-container/search-results-container.component';
import { MarketingGuideContainerComponent } from './marketing-guide/marketing-guide-container/marketing-guide-container.component';
import { marketingGuideResolver } from './marketing-guide/marketing-guide-resolver/marketing-guide.resolver';
import { MobileHamburgerMenuV1Component } from './mobile-hamburger-menu/mobile-hamburger-menu-v1/mobile-hamburger-menu-v1.component';
import { cartLoadedGuard } from './shared/guards/cart-loaded-guard';
import { CartSummaryContainerComponent } from './cart/cart-summary/cart-summary-container.component';
import { cartRedirectGuard } from './shared/guards/cart-redirect.guard';
import { CartTransferComponent } from './cart/cart-transfer/cart-transfer.component';
import { storeCartContentGuard } from './shared/guards/store-cart-content.guard';
import { cartTransferGuard } from './shared/guards/cart-transfer.guard';
import { ProductDetailsMainComponent } from './product-details/product-details-main/product-details-main.component';
import { productDetailsResolver } from './product-details/product-details-resolver/product-details-resolver';
import { MyIdCardComponent } from './my-id-card/my-id-card/my-id-card.component';
import { DashboardComponent } from './lists/dashboard/dashboard.component';
import { OrderGuideContainerComponent } from './guides/order-guide/order-guide-container/order-guide-container.component';
import { EditOrderGuideComponent } from './lists/edit-order-guide/edit-order-guide.component';
import { editOrderGuideGuard } from './shared/guards/edit-order-guide.guard';
import { editOrderGuideRedirectGuard } from './shared/guards/edit-order-guide-redirect.guard';
import { CustomGuideContainerComponent } from './guides/custom-guide/custom-guide-container/custom-guide-container.component';
import { EditCustomGuideComponent } from './lists/edit-custom-guide/edit-custom-guide.component';
import { CriticalItemsGuideContainerComponent } from './guides/critical-items-guide/critical-items-guide-container/critical-items-guide-container.component';
import { OrdersComponent } from './orders/orders/orders.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { NaooConstants } from './shared/NaooConstants';
import { StorePurchaseDetailsContainerComponent } from './orders/orders/store-purchase/details/store-purchase-details-container.component';
import { expressSchedulesValidGuard } from './shared/guards/express-schedules-valid.guard';
import { CartReviewContainerComponent } from './cart/cart-review/cart-review-container.component';
import { orderConfirmationResolver } from './order-confirmation/shared/order-confirmation-resolver';
import { OrderConfirmationContainerComponent } from './order-confirmation/order-confirmation-container.component';
import { PreferencesContainerComponent } from './account/preferences/preferences-container.component';
import { unsavedPreferencesGuard } from './account/preferences/unsaved-preferences.guard';
import { NotFoundComponent } from './not-found/not-found/not-found.component';
import { InfoUnavailableComponent } from './info-unavailable/info-unavailable/info-unavailable.component';
import { ScheduledMaintenanceComponent } from './scheduled-maintenance/scheduled-maintenance/scheduled-maintenance.component';
import { MaterialComparisonComponent } from './material-comparison/material-comparison.component';
import { OfflineErrorComponent } from './offline/oflline-error.component';
import { punchOutGuard } from './shared/guards/punch-out.guard';
import { PunchOutComponent } from './punch-out/punch-out/punch-out.component';
import { SiteComponent } from './site/site.component';
import { featureFlagEnabledGuard } from './shared/guards/feature-flag-enabled.guard';
import { FeatureFlag } from './core/services/session/models/session-record';
import { MobileHamburgerMenuV2Component } from './mobile-hamburger-menu/mobile-hamburger-menu-v2/mobile-hamburger-menu-v2.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [
      customerUnitSelectionGuard,
      lockedToOrderGuideGuard,
      scheduledMaintenanceGuard,
    ],
  },
  {
    path: 'customer-unit-selection',
    component: CustomerUnitSelectionComponent,
    canActivate: [customerUnitSelectionGuard],
    canDeactivate: [redirectGuard],
  },
  {
    path: 'categories',
    children: [
      {
        path: '',
        component: CategoriesComponent,
      },
      {
        matcher: coordinateMatcher,
        component: CategoryDetailsComponent,
      },
      {
        matcher: resultsCoordinateMatcher,
        component: SearchResultsContainerComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'collections',
    children: [
      {
        path: ':pathUrl',
        component: MarketingGuideContainerComponent,
        resolve: {
          pathUrl: marketingGuideResolver,
        },
      },
    ],
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'mobile-hamburger-menu',
    canMatch: [featureFlagEnabledGuard(FeatureFlag.BAYMARD_NAVIGATION)],
    component: MobileHamburgerMenuV2Component,
  },
  {
    path: 'mobile-hamburger-menu',
    component: MobileHamburgerMenuV1Component,
  },
  {
    path: 'search',
    component: SearchResultsContainerComponent,
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'cart',
    children: [
      {
        path: '',
        component: CartSummaryContainerComponent,
        canDeactivate: [cartRedirectGuard],
      },
      {
        path: 'transfer/:data',
        component: CartTransferComponent,
        canActivate: [storeCartContentGuard],
      },
      {
        path: 'transfer',
        component: CartTransferComponent,
        canActivate: [cartTransferGuard],
      },
    ],
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      cartLoadedGuard,
    ],
  },
  {
    path: 'product',
    children: [
      {
        path: ':materialNumber',
        component: ProductDetailsMainComponent,
        resolve: {
          detailsData: productDetailsResolver,
        },
      },
    ],
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'my-id-card',
    component: MyIdCardComponent,
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'guides',
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'order-guide',
        component: OrderGuideContainerComponent,
      },
      {
        path: 'order-guide/organize',
        component: EditOrderGuideComponent,
        canActivate: [editOrderGuideGuard],
        canDeactivate: [editOrderGuideRedirectGuard],
      },
      {
        path: 'custom-guide/:id',
        component: CustomGuideContainerComponent,
      },
      {
        path: 'custom-guide/:id/organize',
        component: EditCustomGuideComponent,
      },
      {
        path: 'critical-items-guide',
        component: CriticalItemsGuideContainerComponent,
      },
    ],
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'orders',
    children: [
      {
        path: '',
        component: OrdersComponent,
      },
      {
        path: ':id/details/:orderType',
        component: OrderDetailsComponent,
      },
      {
        path: ':id/details/:orderType/:groupNumber',
        component: OrderDetailsComponent,
      },
      {
        path: `${NaooConstants.STORE_PURCHASE_DETAILS_PATH_PREFIX}/:storeId/:saleDate/:laneNumber/:saleNumber`,
        component: StorePurchaseDetailsContainerComponent,
      },
    ],
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'cart-review',
    component: CartReviewContainerComponent,
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      cartLoadedGuard,
      expressSchedulesValidGuard,
    ],
  },
  {
    path: 'order-confirmation',
    component: OrderConfirmationContainerComponent,
    canActivate: [
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
      expressSchedulesValidGuard,
    ],
    resolve: {
      orderConfirmation: orderConfirmationResolver,
    },
  },
  {
    path: 'preferences',
    children: [
      {
        path: '',
        component: PreferencesContainerComponent,
        canDeactivate: [unsavedPreferencesGuard],
        children: [
          {
            path: 'email',
            component: PreferencesContainerComponent,
          },
          {
            path: 'sms',
            component: PreferencesContainerComponent,
          },
          {
            path: 'privacy',
            component: PreferencesContainerComponent,
          },
        ],
      },
    ],
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent,
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'info-unavailable',
    component: InfoUnavailableComponent,
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'scheduled-maintenance',
    component: ScheduledMaintenanceComponent,
    canActivate: [customerUnitSelectionGuard],
  },
  {
    path: 'product-comparison',
    component: MaterialComparisonComponent,
    canActivate: [customerUnitSelectionGuard, scheduledMaintenanceGuard],
  },
  {
    path: 'offline-error',
    component: OfflineErrorComponent,
  },
  {
    path: 'punch-out',
    component: PunchOutComponent,
    canActivate: [
      punchOutGuard,
      customerUnitSelectionGuard,
      scheduledMaintenanceGuard,
    ],
  },
  {
    path: 'site',
    children: [
      {
        path: '',
        children: [
          {
            path: '**',
            component: SiteComponent,
          },
        ],
      },
    ],
    canActivate: [customerUnitSelectionGuard],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];
