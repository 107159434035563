import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import moment, { Moment } from 'moment';
import { MatCalendar } from '@angular/material/datepicker';
import { FulfillmentType } from '../../../../../../../core/services/cart/models/cart-record';
import {
  getDefaultDate,
  momentExistsInAvailableDates,
} from '../../../../util/order-method-modal.utils';

@Component({
  selector: 'naoo-select-date-step-calendar',
  templateUrl: './select-date-step-calendar.component.html',
  styleUrls: ['./select-date-step-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCalendar],
})
export class SelectDateStepCalendarComponent implements AfterContentChecked {
  @Input() fulfillmentType: FulfillmentType;
  @Input() availableDates: string[];
  @Input() selectedDate: Moment;

  @Output() dateChangeEmitter = new EventEmitter<Moment>();
  @Output() tabPressedEmitter = new EventEmitter<void>();

  @ViewChild('calendar') calendar: MatCalendar<Moment>;
  @ViewChild('dateFocus') dateFocus: ElementRef<HTMLDivElement>;

  get calendarMinDate(): Moment {
    return moment(this.availableDates?.[0]);
  }

  get calendarMaxDate(): Moment {
    return moment(this.availableDates?.[this.availableDates.length - 1]);
  }

  get calendarSelectedDate(): Moment {
    if (!this.isSelectedDateAvailable && !!this.defaultDate()) {
      this.dateChangeEmitter.emit(this.defaultDate());
    }
    return this.selectedDate;
  }

  get isSelectedDateAvailable(): boolean {
    return momentExistsInAvailableDates(this.selectedDate, this.availableDates);
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    const matCalendarPeriodButton = this.dateFocus?.nativeElement.querySelector(
      '.mat-calendar-period-button',
    );
    const matCalendarArrow = this.dateFocus?.nativeElement.querySelector(
      '.mat-calendar-arrow',
    );
    if (FulfillmentType.TRUCK === this.fulfillmentType) {
      matCalendarPeriodButton?.removeAttribute('disabled');
      matCalendarArrow?.removeAttribute('visibility');
      this.changeDetectorRef.markForCheck();
    } else {
      matCalendarPeriodButton?.setAttribute('disabled', 'disabled');
      matCalendarArrow?.setAttribute('visibility', 'hidden');
      this.changeDetectorRef.markForCheck();
    }
  }

  dateFilter = (calendarMoment: Moment): boolean => {
    return this.availableDates?.includes(calendarMoment.format('YYYY-MM-DD'));
  };

  selectDate(event: Moment): void {
    const local = event.clone().local(true);
    this.dateChangeEmitter.emit(local);
  }

  dateClass = (d: Date) => {
    if (!this.selectedDate) {
      return undefined;
    }
    return !this.isSelectedDateAvailable &&
      moment(d).isSame(this.selectedDate, 'day')
      ? 'invalid-date'
      : undefined;
  };

  dateFocusKeydown(event: KeyboardEvent): void {
    const maxIdx = this.availableDates?.length - 1;
    const curIdx = this.availableDates?.findIndex(
      (date) => this.selectedDate?.format('YYYY-MM-DD') === date,
    );
    let newIdx;
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        newIdx = curIdx - 1 < 0 ? maxIdx : curIdx - 1;
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        newIdx = curIdx + 1 < this.availableDates?.length ? curIdx + 1 : 0;
        break;
      case 'Tab':
        this.tabPressedEmitter.emit();
        return;
      default:
        return;
    }
    this.goToDate(this.availableDates?.[newIdx]);
  }

  private goToDate(dateString: string): void {
    if (dateString) {
      const date = moment(dateString);
      this.calendar._goToDateInView(date, 'month');
      this.selectDate(date);
      setTimeout(() => this.dateFocus.nativeElement.focus());
    }
  }

  private defaultDate(): Moment {
    return getDefaultDate(this.availableDates);
  }
}
