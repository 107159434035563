import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  MatSlideToggleChange,
  MatSlideToggle,
} from '@angular/material/slide-toggle';

@Component({
  selector: 'naoo-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatSlideToggle],
})
export class SlideToggleComponent {
  @Input() isToggled: boolean;
  @Input() name: string;
  @Input() isDisabled = false;
  @Output() onToggle = new EventEmitter<MatSlideToggleChange>();

  toggleChange(event: MatSlideToggleChange): void {
    this.onToggle.emit(event);
  }
}
