import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { CompareDocketContainerComponent } from '../compare-docket/compare-docket-container.component';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { first, takeUntil } from 'rxjs/operators';
import { MaterialComparisonFacade } from '../../core/store/material-comparison/material-comparison.facade';
import { Subject } from 'rxjs';

@Component({
  selector: 'naoo-compare-docket-overlay',
  template: ` <naoo-compare-docket-container></naoo-compare-docket-container> `,
  styleUrls: ['./compare-docket-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CompareDocketContainerComponent],
})
export class CompareDocketOverlayComponent implements OnInit, OnDestroy {
  componentPortalRef: ComponentPortal<CompareDocketContainerComponent>;
  overlayRef: OverlayRef;
  private destroyed$ = new Subject<void>();

  constructor(
    private materialComparisonFacade: MaterialComparisonFacade,
    private overlay: Overlay,
  ) {}

  ngOnInit() {
    this.materialComparisonFacade
      .isMaterialComparisonCollapsed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isCollapsed) => this.handleDocketExpansion(isCollapsed));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private handleDocketExpansion(collapsed: boolean) {
    if (!this.componentPortalRef) {
      this.componentPortalRef = new ComponentPortal(
        CompareDocketContainerComponent,
      );
    }

    if (!this.overlayRef) {
      const overlayConfig = new OverlayConfig({
        hasBackdrop: true,
        positionStrategy: this.overlay.position().global().bottom('0'),
      });
      this.overlayRef = this.overlay.create(overlayConfig);
    }

    if (collapsed) {
      this.overlayRef.detach();
    } else {
      this.overlayRef.attach(this.componentPortalRef);
      this.overlayRef
        .backdropClick()
        .pipe(first())
        .subscribe(() => {
          this.materialComparisonFacade.toggleCollapseMaterialComparison(true);
        });
    }
  }
}
