@if (sessionInfo) {
  <div class="customer-unit-selection">
    <div class="customer-unit-heading">
      <h1 class="naoo-heading">
        {{ 'CUSTOMER_UNIT.SELECT_A_LOCATION' | translate }}
      </h1>
      @if (!hasOnlyOneCustomer) {
        <naoo-search-field
          (searchTextEmitter)="updateFilter($event)"
          [searchPlaceholderText]="'CUSTOMER_UNIT.SEARCH'"
          [searchHintText]="'CUSTOMER_UNIT.SEARCH_HINT'"
        ></naoo-search-field>
      }
    </div>
    <div class="cards-wrapper">
      @if (!isMobile && hasCustomersResults) {
        <naoo-customer-unit-header
          [isOrderSubmission]="isOrderSubmission"
          [hasPunchedOutCarts]="hasPunchedOutCarts"
        ></naoo-customer-unit-header>
      }
      <virtual-scroller
        #scroll
        [parentScroll]="parentScrollElement"
        [items]="filteredResults"
      >
        @for (
          searchCustomer of scroll.viewPortItems;
          track searchCustomer.customer.naooCustomerId;
          let isLast = $last
        ) {
          <naoo-customer-unit-card
            class="total"
            [active]="isActiveCustomer(searchCustomer.customer.naooCustomerId)"
            [cartTotal]="
              customerCartCountRecords[searchCustomer.customer.naooCustomerId]
            "
            [customer]="searchCustomer.customer"
            [isOrderSubmission]="isOrderSubmission"
            [hasPunchedOutCarts]="hasPunchedOutCarts"
            [isMobile]="isMobile"
            [isLast]="isLast"
          ></naoo-customer-unit-card>
        }
      </virtual-scroller>
    </div>
    @if (!hasOnlyOneCustomer && !hasCustomersResults) {
      <naoo-no-results
        [searchText]="searchText"
        [isMobile]="isMobile"
      ></naoo-no-results>
    }
  </div>
}
