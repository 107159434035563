<div class="date-selector">
  <mat-form-field [floatLabel]="'always'">
    <mat-label>{{ 'DATE_SELECTOR.DATE_RANGE' | translate }}</mat-label>
    <mat-icon svgIcon="calendar-icon" matPrefix></mat-icon>
    <mat-select
      panelClass="date-range-select"
      (selectionChange)="dateRangeEmitter.emit(selectedDateRange)"
      [(value)]="selectedDateRange"
      [attr.aria-label]="
        selectedDateRange.ariaLabel
          | translate
            : (selectedDateRange.year ? { year: selectedDateRange.year } : {})
      "
      data-test-id="reused-date-range-selector"
      disableOptionCentering
    >
      @for (dateRange of dateRanges; track trackByDateRange(dateRange)) {
        <mat-option
          [value]="dateRange"
          [attr.aria-label]="
            dateRange.ariaLabel
              | translate: (dateRange.year ? { year: dateRange.year } : {})
          "
        >
          {{ dateRange.label | translate: { year: dateRange.year } }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
