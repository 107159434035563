<naoo-material-compare-checkbox
  [ngClass]="{ 'checkbox-hidden': hideCheckbox }"
  [compareCheckbox]="materialRowFooter.compareCheckbox"
  [materialRowOptions]="materialRowOptions"
>
</naoo-material-compare-checkbox>

<div
  class="footer-details"
  [ngClass]="{ 'display-commodity-allocation': displayMaxAllocation }"
>
  @if (displayMaxAllocation) {
    <span class="commodity-available">
      <button (click)="viewCommodityDetailsClicked.emit()">
        {{
          materialRowOptions.materialCommodityInfo.maxAllocationDisplayKey
            | translate
              : {
                  count:
                    materialRowOptions.materialCommodityInfo
                      .maxAllocationDisplay,
                }
        }}
      </button>
    </span>
  }
  @if (!!materialRowFooter.lastOrdered) {
    <div class="last-order">
      {{ 'PRODUCT.ORDERED' | translate }}:
      <span class="date">{{
        materialRowFooter.lastOrdered.date | naooDate: 'shortYearDate' : ''
      }}</span>
      @if (!!materialRowFooter.lastOrdered.line) {
        <span class="quantity"
          >,
          {{
            {
              uomCode: materialRowFooter.lastOrdered.line.displayCode,
              quantity: materialRowFooter.lastOrdered.line.quantity,
            } | materialunits: true
          }}</span
        >
      }
    </div>
  }
</div>
