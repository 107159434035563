<div class="purchase-details">
  <div class="regular-header">
    <div class="button-wrapper">
      <naoo-back-button
        [navigationLink]="backNavigationLink"
      ></naoo-back-button>
      @if (!isMobileWidth && !isTabletWidth) {
        <naoo-action-icon
          actionIcon="print_v2"
          [iconCurrentState]="printIconState"
          (clickAction)="printEmitter.emit()"
        ></naoo-action-icon>
      }
    </div>
    <hr />
    <h1 class="naoo-heading">
      {{ 'PURCHASE_DETAILS.TITLE' | translate }}
    </h1>
  </div>
  @if (viewModel?.isLoading) {
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  }
  @if (viewModel?.purchaseDetails) {
    <ng-container [ngTemplateOutlet]="details"></ng-container>
  }
  @if (viewModel?.shouldDisplayErrorPage) {
    <ng-container [ngTemplateOutlet]="errorPage"></ng-container>
  }
</div>

<ng-template #details>
  <div class="print-header">
    <div class="logo">
      <img
        src="{{
          'LOGO.URL'
            | naoobrand: viewModel.purchaseDetails.customerDetails.customerBrand
            | translate
        }}"
        alt="{{
          'LOGO.TEXT'
            | naoobrand: viewModel.purchaseDetails.customerDetails.customerBrand
            | translate
        }}"
      />
    </div>
    <div class="title">
      <div class="page">
        {{ 'PURCHASE_DETAILS.TITLE' | translate }}
      </div>
    </div>
  </div>
  <div class="header-details">
    <naoo-store-purchase-details-header
      [viewModel]="viewModel"
      [isMobileWidth]="isMobileWidth"
    ></naoo-store-purchase-details-header>
  </div>
  @if (viewModel.purchaseDetails.itemDetails.length) {
    <article class="purchase-detail-header">
      <div class="purchase-detail-cell item">
        {{ 'PURCHASE_DETAILS.ITEM' | translate }}
      </div>
      <div class="purchase-detail-cell points">
        <naoo-go-points-logo
          class="go-points"
          [context]="'purchase-history-detail'"
        ></naoo-go-points-logo>
      </div>
      <div class="purchase-detail-cell unit-size">
        {{ 'PURCHASE_DETAILS.UNIT_SIZE' | translate }}
      </div>
      <div class="purchase-detail-cell quantity">
        {{ 'PURCHASE_DETAILS.QUANTITY' | translate }}
      </div>
      <div class="purchase-detail-cell total">
        {{ 'PURCHASE_DETAILS.TOTAL' | translate }}
      </div>
    </article>
  }
  @if (!shouldRenderPrint) {
    <virtual-scroller
      #scroll
      [scrollAnimationTime]="0"
      [compareItems]="comparePurchaseMaterial"
      [items]="viewModel.purchaseDetails.itemDetails"
      [parentScroll]="parentScrollElement"
    >
      @for (
        purchaseMaterial of scroll.viewPortItems;
        track trackByMaterialUom(purchaseMaterial);
        let isLast = $last
      ) {
        <article class="store-purchase-detail-row">
          <naoo-store-purchase-details-row-container
            [purchaseMaterial]="purchaseMaterial"
            [fulfillmentType]="viewModel.fulfillmentType"
            [isMobileWidth]="isMobileWidth"
            [isTabletWidth]="isTabletWidth"
            [isLast]="isLast"
          ></naoo-store-purchase-details-row-container>
        </article>
      }
    </virtual-scroller>
  } @else {
    @for (
      purchaseMaterial of viewModel.purchaseDetails?.itemDetails;
      track trackByMaterialUom(purchaseMaterial);
      let isLast = $last
    ) {
      <article class="store-purchase-detail-row">
        <naoo-store-purchase-details-row-container
          [purchaseMaterial]="purchaseMaterial"
          [fulfillmentType]="viewModel.fulfillmentType"
          [isMobileWidth]="isMobileWidth"
          [isTabletWidth]="isTabletWidth"
          [isLast]="isLast"
        ></naoo-store-purchase-details-row-container>
      </article>
    }
  }
</ng-template>

<ng-template #errorPage>
  <naoo-error-state
    alt="{{ 'PURCHASE_HISTORY.ERROR.ALT' | translate }}"
    errorImageSource="assets/images/full-error.svg"
    errorTitle="{{ 'PURCHASE_HISTORY.ERROR.TITLE' | translate }}"
    errorSubtitle="{{ 'PURCHASE_HISTORY.ERROR.SUBTITLE' | translate }}"
    [showErrorButton]="false"
  >
  </naoo-error-state>
</ng-template>
